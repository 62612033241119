import React from "react";
import { Bar, Pie, Line } from "react-chartjs-2";

const Chart = (props) => {
  const data = {
    labels: props.chartLabels, // expects an array like ['January', 'February'] same length as chartData
    datasets: [
      {
        label: props.chartTitle,
        data: props.chartData, // expects an array like [100, 155] same length as chartMonths
        backgroundColor: [
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
          "rgba(54, 162, 235, 0.8)",
        ],
      },
    ],
  };

  const options = {
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            // max: props.winGoal,
            beginAtZero: true,
          },
        },
      ],
    },
    responsive: props.responsive,
    maintainAspectRatio: props.aspectRatio,
  };

  switch (props.chartType) {
    case "Pie":
      return <Pie data={data} options={options} />;
    case "Bar":
      return <Bar data={data} options={options} />;
    case "Line":
      return <Line data={data} options={options} />;
    default:
      return null;
  }
};

export default Chart;
