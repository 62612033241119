/* eslint-disable */
import React, { Fragment } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
///import PoppinsRegular from '../../../../fonts/Poppins/Poppins-Regular.ttf';
//import PoppinsBold from '../../../../fonts/Poppins/Poppins-Bold.ttf';

// Font.register({
//   family: 'Poppins',
//   fonts: [
//     {
//       src: PoppinsRegular,
//     },
//     {
//       src: PoppinsBold,
//       fontWeight: 'bold',
//     },
//   ],
// });



function SyllabusReport({ data, className, usersLength, documentTitle }) {
  return (
    <Document title={documentTitle} style={styles.documentStyle}>
      <Page size="A4" orientation="portrait">
        <View style={styles.page}>
          <Text
            style={{
              ...styles.textBold,
              fontSize: 16,
              marginBottom: 15
            }}
          >
            {className} Syllabus
          </Text>
          {data &&
            data.map((val, index) => {
              return (
                <View key={index} style={{ paddingBottom: 10 }}>
                  <Text style={{
                    ...styles.textBold,
                    paddingBottom: 3
                  }}>{val?.value}</Text>
                  <Text>{val?.description}</Text>
                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  documentStyle: {
    fontFamily: "Helvetica",
    backgroundColor: "#ffffff",
    color: "#000000",
  },

  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Helvetica",

    fontSize: 10,
    padding: "30px 40px",
    color: "#000000",
  },

  pdf_page_title: {
    fontWeight: "bold",
    fontSize: 18,
    fontFamily: "Helvetica",
  },

  pdf_page_subtitle: {
    fontWeight: "bold",
    fontSize: 14,
  },

  pdf_normal_space: {
    marginTop: 10,
  },

  pdf_big_space: {
    marginTop: 20,
  },

  pdf_bold: {
    fontWeight: "bold",
    color: "#000000",
  },

  redTitle: {
    fontSize: 14,
    color: "#FFFFFF",
  },

  redContainer: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#d94645",
    width: 220,
    height: 40,
    padding: 10,
    fontFamily: "Helvetica",
  },

  textBold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  textItalic: {
    fontFamily: "Helvetica-Oblique",
    fontStyle: "italic",
  }
});

export default SyllabusReport;
