import React from "react";
import { Loading } from "../../../Loading";
import SwitchItemOrderButtons from "../shared/SwitchItemOrderButtons";

import "./index.css";

import Slider from "antd/es/slider";

export const Image = ({
  index,
  item,
  editSection,
  uploading,
  manageImage,
  removeContentAtIndex,
  _moveSectionContentDown,
  _moveSectionContentUp,
}) => {
  return (
    <div className="image-video-edit" key={index}>
      <div className="editcontent__title">Add Image</div>
      <input
        placeholder="Enter image url here..."
        className="imageinput__text"
        value={item.url}
        onChange={(e) => editSection(e, index)}
      />
      {uploading ? (
        <Loading />
      ) : (
        <input
          type="file"
          className="imageinput__uploadbutton"
          onChange={(e) => manageImage(e, index)}
          placeholder="Select file"
          name="Select File"
        />
      )}
      <Slider
        defaultValue={item.imageWidth ? item.imageWidth : 50}
        onChange={(e) =>
          editSection({ target: { value: e } }, index, "image-width")
        }
      />
      <img
        src={item.url}
        width={item.imageWidth ? item.imageWidth * 9 : 200}
        height={"auto"}
      />

      <button
        className="btn btn-danger"
        onClick={(e) => removeContentAtIndex(e, index)}
      >
        X
      </button>
      <SwitchItemOrderButtons
        index={index}
        _moveSectionContentDown={_moveSectionContentDown}
        _moveSectionContentUp={_moveSectionContentUp}
      />
    </div>
  );
};

export default Image;
