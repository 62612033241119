export const news = [
    {
        date: '1999-12-31',
        title: 'A Great End of the Century',
        description: 'The century ended with strong economic growth and solid corporate profits. The Nasdaq composite, the main technology index, rose 85.6 percent.',
        recommendation: 'A mix of optimism and caution. Many believe tech stocks reached unsustainable levels and will face a correction on 2000.',
        img: '/assets/images/tech-stocks.jpg'
    },
    {
        date: '2000-12-29',
        title: 'A Miserable Year for US stocks',
        description: 'The year ended on a sell-off, resulting in the worst annual performance for Technology stocks since its inception in 1971 and the first loss in a decade for the Dow Jones industrial average. This decline deeply impacted investor confidence, wiping out about $2.5 trillion in market capitalization.',
        recommendation: 'Even though you might just want to cry, analysts say that investors should not run away from the stock market: value and profits can still be found. Or so they say.',
            
    },
    {
        date: '2006-06-30',
        title: 'Rate Hikes Worry Markets',
        description: 'The markets just experienced a selloff caused by rate hikes from the Fed and other central banks.',
        recommendation: 'Rate hikes happen when the economy is seen to be accelerating too fast. There might be a correction coming up!'

    },
    {
        date: '2006-07-31',
        title: 'Second Quarter Earnings are all Strong!',
        description: 'Corporate porfits came is strong. Cash is abundant in companies, givin them flexibility to manage their businesses or invest in new ones. ',
        recommendation: 'Maybe the rate hike worries are wrong. If rate hikes are accompanied by a strong economy, then everything should be alright!   '

    },
    {
        date: '2008-07-31',
        title: 'The end of Lehman Brothers',
        description: 'Lehman Brothers, one of America\'s most important investment banks, just went broke. Another victim of the subprime mortgage financial crisis. Panic spreads in the markets.',
        recommendation: 'Usually, in financial crisis situations, most assets go down. One asset that goes against the market in crises, however, is Gold. Because it feels tangible, people run towards it during bad times.',
        img: '/assets/images/bankrupt.jpg'
    
    },
    {
        date: '2009-11-30',
        title: 'Crisis Avoided',
        description: 'After the American Recovery and Reinvestment act of February, the economy is at full speed back to recovery. ',
        recommendation: 'These are still uncertain times. Analysts recommend index funds like the S&P 500 to ride the upside with the best assets in the market combined.',
        img: '/assets/images/reinvestment-act.jpg'
        
    },
    {
        date: '2010-01-29',
        title: 'Investments pouring from everywhere!',
        description: 'Stocks are skyrocketing after the crisis! It seems to be a chance to buy!',
        img: '/assets/images/investments-going-up.jpg'
        
    },
    
]