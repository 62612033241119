import React from "react";
import EQAssessmentCard from "./EQAssessmentCard";
import JoinClassCard from "./JoinClassCard";
import LSICard from "./LSICard";
import "./index.css";

const InitialAssessments = ({
  authUser,
  classData,
  getClassData,
  finishedInitialAssessments,
}) => {
  const hasClassId = authUser?.classId ? true : false;
  const hasLSI =
    authUser?.lsiDone || classData?.settings?.lsiAvailable === false
      ? true
      : false;
  const hasEqTest = authUser?.eqTestComplete ? true : false;
  if (!finishedInitialAssessments || authUser.isAdmin || authUser.isViewer) {
    return (
      <div className="initialassessments_maincontainer">
        <JoinClassCard
          enabled={true}
          done={hasClassId}
          userId={authUser?.uid}
          isMentor={authUser?.isMentor}
          getClassData={getClassData}
          isAdmin={authUser?.isAdmin}
          email={authUser?.email}
          calendarId={authUser?.calendarId}
          authUser={authUser}
        />

        {hasClassId && (
          <>
            <LSICard
              available={classData?.settings?.lsiAvailable ? true : false}
              enabled={hasClassId}
              done={hasLSI}
              isAdmin={authUser?.isAdmin}
              isMentor={authUser?.isMentor}
            />
            <EQAssessmentCard
              available={classData?.settings?.eqAvailable ? true : false}
              enabled={hasLSI}
              done={hasEqTest}
              isAdmin={authUser?.isAdmin}
              isMentor={authUser?.isMentor}
            />
          </>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default InitialAssessments;
