import React, { useEffect } from "react";

const OptionButton = (props) => {
  const {
    optionCategory,
    option,
    happyPoints,
    imgSrc,
    imgAlt,
    selectedOption,
    _updateOption,
    levelNum,
  } = props;
  const handleRadioClick = (_changeEvent) => {
    _updateOption(optionCategory, option);
  };
  const text = option.text();

  const parentsKickYouOut = () => {
    return (
      optionCategory === "housing" &&
      levelNum >= 1 &&
      option.title === "Live with your parents"
    );
  };

  useEffect(() => {
    console.log(
      "OptionButton.js 20 | choosing options",
      optionCategory,
      levelNum,
      option
    );

    if (
      optionCategory === "housing" &&
      levelNum === 1 &&
      option.title === "Live with your parents"
    ) {
      console.log(
        "OptionButton.js 28 | Your parents kicked you out of the house!"
      );
    }
  });

  if (parentsKickYouOut())
    return (
      <div className="radio-parents">
        <label className="option">
          Your parents kicked you out of the house!
        </label>
      </div>
    );

  return (
    <div className="radio">
      <label className="option">
        <h5>{option.title}</h5>
        <p className="option-text">{text}</p>
        <p className="option-text">{happyPoints}</p>
        {/* <img className="job-image" src={imgSrc} alt={imgAlt} /> */}
        <br />
        <input
          type="radio"
          value={option.title}
          checked={selectedOption === option.title}
          onChange={handleRadioClick}
        />
      </label>
    </div>
  );
};

export default OptionButton;
