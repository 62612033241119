import thunk from "redux-thunk";
import { applyMiddleware, createStore, compose } from "redux";

import { allReducers } from "./combinedReducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  allReducers,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
