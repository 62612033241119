import React from "react";

const Delete = ({ removeContentAtIndex, index }) => {
  return (
    <button
      type="button"
      className="btn btn-danger"
      onClick={(e) => removeContentAtIndex(e, index)}
    >
      X
    </button>
  );
};

export default Delete;
