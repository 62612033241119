import React, { useEffect } from "react";
import CarouselImage from "./CarouselImage";
import CarouselButton from "./CarouselButton";

import "./LandingFeaturesCarousel.css";

import { useState } from "react";
export const LandingFeaturesCarousel = ({
  description = "Complete Lessons, Certificates, Analytics, Interactive lessons, and more!",
  title = "Complete Learning Management System",
}) => {
  const [currImage, setCurrImage] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const isDiversion = window.location.pathname.includes("diversion");

  const baseImageFeatures = [
    {
      feature: "Education to Reduce Recidivism",
      description:
        "Complete Lessons, Certificates, Analytics, Interactive lessons, and more!",
    },
    {
      feature: "Full Mobile Experience",
      description:
        "Download our mobile app and experience the full RED experience on the Go!",
    },
    {
      feature: "Interactive Lessons & Games",
      description:
        "Learn with real life finance with games and simulations, and more!",
    },
    {
      feature: "Emotional Intelligence Assessments",
      description:
        "Understand EQ capabilities, how to improve them, and see results after our program!",
    },
    {
      feature: "Complete with Tracking & Notifications",
      description: "Send emails, text, and push notification reminders",
    },
  ];

  const diversionFeature = {
    feature: "Community",
    description: "Reach out to us, to one another, to everybody",
  };

  const imageFeatures = isDiversion
    ? baseImageFeatures
    : [...baseImageFeatures, diversionFeature];

  useEffect(() => {
    // Set up the interval
    const id = setInterval(() => {
      setCurrImage((prevImage) => (prevImage + 1) % imageFeatures.length);
    }, 3000);
    setIntervalId(id);

    // Clear the interval on component unmount
    return () => clearInterval(id);
  }, []);

  const handleClick = (index) => {
    // Clear existing interval when a user clicks a feature
    if (intervalId) clearInterval(intervalId);
    setCurrImage(index);
  };
  return (
    <div className="landingfeaturescarousel">
      <div className="carouselcontentcontainer">
        <div className="carouselheader">
          <div className="carouseltitle">
            {imageFeatures[currImage].feature}
          </div>
          <div className="carouseldescription">{description}</div>
        </div>
        <CarouselImage src={currImage} />
        <div className="buttons">
          {/* <div className="buttonactive">
            <div className="label1">Complete LMS</div>
          </div> */}
          {imageFeatures &&
            imageFeatures.map((feature, index) => {
              return (
                <CarouselButton
                  key={index}
                  title={feature.feature}
                  currentIndex={currImage}
                  index={index}
                  handleClick={() => handleClick(index)}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default LandingFeaturesCarousel;
