import React, { useState } from "react";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";

const categories = [
  { name: "EQ", value: "emotionalIntelligence" },
  { name: "Physical", value: "Physical" },
  { name: "Social/Cultural", value: "socialCultural" },
  { name: "Academic/Career", value: "academicCareer" },
  { name: "Spiritual", value: "spiritual" },
  { name: "Environmental", value: "environmental" },
  { name: "Financial", value: "financial" },
  { name: "Emotional", value: "emotional" },
  { name: "Intellectual", value: "intellectual" },
];

//
export const DropDown = (props) => {
  const [selectedCategory, setSelectedCategory] = useState("Select  Category");
  const onClick = ({ key }) => {
    props.onClick(categories[key]);
  };

  return (
    <Dropdown
      overlay={
        <Menu onClick={onClick}>
          {categories.map((item, index) => {
            return (
              <Menu.Item key={index}>
                <a onClick={() => setSelectedCategory(item.name)}>
                  {item.name}
                </a>
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        {categories.filter((category) => category.value === props.name).length >
        0
          ? categories.filter((category) => category.value === props.name)[0]
              .name
          : "Select Category"}
        {/* <Icon type="down" /> */}
      </a>
    </Dropdown>
  );
};
