const initialState = { loginType: "user" };

export const loginTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "loginType/set":
      return { ...state, loginType: action.payload };

    default:
      return state;
  }
};
