import React from "react";
import GameResultNotice from "./GameResultNotice";
import SpendingChartsContainer from "./SpendingChartsContainer";
import "./ResultsOverview.css";
import {
  BudgetHappinessIndicator,
  BudgetMoneyIndicator,
} from "./MoneyAndHappiness";
import Button from "src/components/shared/Button";
export const ResultsOverview = ({ onRestartGame, state, handleNext }) => {
  return (
    <div className="resultsoverview">
      <GameResultNotice state={state} />
      <div className="container">
        <div className="budget-happy-balance">
          <BudgetMoneyIndicator
            title={"Money"}
            balance={state.balance}
            income={state.job.salary}
          />
          <BudgetMoneyIndicator
            title={"Savings"}
            balance={state.savings}
            income={state.job.salary}
          />
          <BudgetHappinessIndicator
            width={state.happiness}
            isHappy={state.happiness >= 15}
          />
        </div>
        <SpendingChartsContainer state={state} />
        <div className="bottom-unlock-button">
          <Button
            title={
              state.happiness >= 15 && state.balance >= 0
                ? "Unlock Next Level"
                : "Try Again"
            }
            onClick={onRestartGame}
          />
          {state.level >= 4 && (
            <Button title="Next Lesson" onClick={handleNext} />
          )}
        </div>
      </div>
    </div>
  );
};
