import React, { useState } from "react";
import "./index.css";
import Icon from "../../../../shared/Icon";
import { ConfirmLegalNameModal } from "../ConfirmLegalName";
import { toast } from "react-toastify";

const CertificateCard = ({
  title,
  description,
  complete,
  handleCertificate,
  completionDate,
  firebase,
  authUser,
}) => {
  const [legalNameConfirmOpen, setLegalNameConfirmOpen] = useState(false);
  const onConfirmLegalNameUpdate = async (newDisplayName) => {
    if (!complete) {
      toast.error("Please complete the module to download the certificate");
    } else {
      try {
        await firebase
          .user(authUser.uid)
          .update({ displayName: newDisplayName });
        console.log("handling certificate", completionDate);
        await handleCertificate(false, title, completionDate, newDisplayName);
        toast.success("Download Successful!", {
          position: "top-center",
        });

        setLegalNameConfirmOpen(false);
      } catch (error) {
        console.log(
          "CertificateCard 31 | issue with submission",
          error.message
        );
      }
    }
  };

  return (
    <>
      <ConfirmLegalNameModal
        open={legalNameConfirmOpen}
        displayName={authUser.displayName}
        firebase={firebase}
        uid={authUser.uid}
        onConfirm={onConfirmLegalNameUpdate}
        setLegalNameConfirmOpen={setLegalNameConfirmOpen}
      />
      <div
        className={`${
          complete
            ? "certificatecard__container"
            : "certificatecard__containerincomplete"
        }`}
      >
        <div className="certificatecard__title">
          <div className="card__title">{title}</div>
          <div className="card__badge">
            <Icon name={complete ? "badge-active" : "badge-inactive"} />
          </div>
        </div>
        <div className="certificatecard__info">{description}</div>
        <div className="certificatecard__download">
          <div
            className={
              complete
                ? "certificatecard__button"
                : "certificatecard__buttonincomplete"
            }
            onClick={() => {
              // if (complete) handleCertificate(false, title, completionDate);
              setLegalNameConfirmOpen(true);
            }}
          >
            Download
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificateCard;
