import React from "react";
import "./BudgetResultSlider.css";
import { capitalizeFirstLetter } from "src/utils/utils";

export const BudgetSlider = ({
  categoryTitle,
  spent,
  budgeted,
  max,
  state,
}) => {
  const spentPercentage = (spent / max) * 100;
  console.log(
    "BudgetResultSlider.js 13 | spend percentage",
    spent,
    max,
    budgeted
  );

  const budgetedPosition = (budgeted / max) * 100
  const budgetedPositionString = `${budgetedPosition}`;

  console.log("BudgetResultSlider.js 14 | budgeted", budgeted);

  const color = state.expenses[categoryTitle]?.color || "#00000";

  return (
    <div
      className="slider-wrapper"
      style={{ backgroundColor: hexToRGBA(color, 0.08) }}
    >
      <div className="title-budget-month">
        {capitalizeFirstLetter(categoryTitle)}
      </div>
      <div
        className="slider-container"
        style={{ backgroundColor: hexToRGBA(color, 0.3) }}
      >
        <div
          className="spent"
          style={{
            width: `${spentPercentage}%`,
            backgroundColor: hexToRGBA(color, 1),
          }}
        >
          ${spent}
        </div>
        <div
          className="budgeted-line"
          style={{
            left: `${budgetedPosition > 100 ? '100' : budgetedPositionString}%`,
            backgroundColor: categoryTitle === "Total Budget" ? "white" : "",
          }}
        >
          <span
            className="budget-line-label"
            style={{ color: spent / budgeted > 1 ? "red" : "green" }}
          >
            {spent / budgeted > 1 ? "Over Budget" : "Under Budget"}
          </span>
        </div>
        <div
          className="budgeted-amount"
          style={{ left: `${budgetedPosition > 100 ? '100' : budgetedPositionString}%` }}
        >
          ${budgeted}
        </div>
      </div>
    </div>
  );
};

function hexToRGBA(hexValue, opacity) {
  const r = parseInt(hexValue.slice(1, 3), 16);
  const g = parseInt(hexValue.slice(3, 5), 16);
  const b = parseInt(hexValue.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
