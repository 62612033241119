import React, { memo } from "react";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import renderHTML from "react-render-html";
import "./index.css";

// import { shuffleArray } from "../../../../utils/utils";
const DefinitionsContainer = ({
  definitions,
  dragAndDropComplete,
  lessonComplete,
}) => {
  return (
    <div className="definitions__container">
      {definitions
        ? definitions.map((definition, index) => {
            if (definition.word === "") return null;
            return (
              <MemoDefinitionDroppable
                key={index}
                index={index}
                definition={definition.definition}
                word={definition.word}
                show={definition.show}
                dragAndDropComplete={dragAndDropComplete}
                lessonComplete={lessonComplete}
              />
            );
          })
        : "No definitions"}
    </div>
  );
};

const DefinitionDroppable = ({
  definition,
  word,
  show,
  index,
  dragAndDropComplete,
  lessonComplete,
}) => {
  return (
    <Droppable droppableId={`${index}`} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            className="correctdefinition__container"
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            <div className="correctdefinitiontext__container">
              {renderHTML(definition)}
            </div>

            {show === false ||
            dragAndDropComplete === true ||
            lessonComplete === true ? (
              <div className="correctwordindefinition__container">
                <span className="correctwordindefinition_content">
                  {renderHTML(word)}
                </span>
              </div>
            ) : (
              <div
                className={`definition__placeholder  ${
                  snapshot.isDraggingOver && "draggingover__definition"
                }`}
              >
                drag & drop
                {!dragAndDropComplete && !lessonComplete && (
                  <Placeholder show={show}>{provided.placeholder}</Placeholder>
                )}
              </div>
            )}
          </div>
        );
      }}
    </Droppable>
  );
};

const MemoDefinitionDroppable = memo(DefinitionDroppable);

const Placeholder = styled.div`
  padding: 10px;
  color: white;
  margin: 10px;
  background-color: lightblue;
  font-weight: bold;
  width: 150px;
  height: 50px;

  display: ${(props) => (props.show ? "none" : "none")};
`;

export default DefinitionsContainer;
