import React from "react";

const UpdateSectionsOrderButton = ({ updateOrder, isVisible, updating }) => {
  if (isVisible) {
    return (
      <div
        onClick={() => updateOrder()}
        style={{ marginLeft: 200 }}
        className="container"
      >
        {<button className="btn btn-primary">Update Order</button>}
      </div>
    );
  } else {
    return null;
  }
};
export default UpdateSectionsOrderButton;
