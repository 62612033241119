import { API_URL } from "./apiIUrl";

export const sendOneEmail = (to, subject, message, emailRecipientName) => {
  console.log(
    "emails.js 3 | to, subject, message, emailRecipientName",
    to,
    subject,
    message,
    emailRecipientName
  );
  try {
    const response = fetch(`${API_URL}/sendEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to,
        subject,
        message,
        emailRecipientName,
      }),
    });
    console.log("emails.js 16 | email sent", response);
    return response;
  } catch (error) {
    console.log("emails.js 19 | error", error);
  }
};

export const sendAttachmentEmail = (subject, attachment) => {
  console.log(
    "emails.js 3 | to, subject, message, emailRecipientName",
    subject,
    attachment
  );
  try {
    const response = fetch(`${API_URL}/sendEmailWithAttachment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subject,
        attachment,
      }),
    });
    console.log("emails.js 16 | email sent", response);
    return response;
  } catch (error) {
    console.log("emails.js 19 | error", error);
  }
};


export const sendTemplateEmail = (
  to,
  subject,
  message,
  emailRecipientName,
  templateId
) => {
  console.log(
    "emails.js 3 | to, subject, message, emailRecipientName",
    to,
    subject,
    message,
    emailRecipientName
  );
  try {
    const response = fetch(`${API_URL}/sendTemplateEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to,
        subject,
        message,
        emailRecipientName,
        templateId,
      }),
    });
    console.log("emails.js 16 | email sent", response);
    return response;
  } catch (error) {
    console.log("emails.js 19 | error", error);
  }
};
