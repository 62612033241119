import React from "react";
import Hint from "./Hint/Hint";
import "./Checker.css";
import Spin from "antd/es/spin";

const Checker = (props) => {
  let {
    correct,
    nextLesson,
    checkSheetInput,
    showHint,
    hint,
    checkerLoading,
    answer,
    range,
  } = props;

  const handleCheckClick = () => {
    checkSheetInput();
  };

  let hintVisible = showHint ? (
    <Hint hint={hint} correct={correct} answer={answer} range={range} />
  ) : null;

  if (correct) {
    return (
      <div>
        <button className="continue btn btn-success" onClick={nextLesson}>
          Continue!
        </button>
        <p className="success-intro-container">Great job! Keep going.</p>
      </div>
    );
  } else {
    return (
      <div>
        <button
          className="btn btn-primary btn-block"
          onClick={handleCheckClick}
        >
          {checkerLoading ? <Spin /> : "Check"}
        </button>
        {hintVisible}
      </div>
    );
  }
};

export default Checker;
