import React from "react";
import "../index.css";

export const Situation = ({ situation, takeAction, bank }) => {
  const { cost, revenue, progress } = situation;
  return (
    <div
      className="situation__container"
      onClick={() => takeAction(situation, revenue, progress, cost)}
    >
      {/* <div className="decisiontree__datacontainer"> */}
      <p className="decisiontree__actiontext">{situation.actionText}</p>
      <p className="decisiontree__situationcost">
        <span className="descisiontreecost__text">Cost</span>: ${" "}
        {situation.cost}
      </p>
    </div>
  );
};
