import React from "react";
import "./FinishModal.css";

const FinishModal = (props) => {
  return (
    <div className="intro-modal-background">
      <div className="finish-modal">
        <div className="modal-content">
          <h3>Congratulations! You've finished {props.name}.</h3>

          <button onClick={props.handleNext} className="btn btn-success">
            Next Lesson!
          </button>
          <br />
          <a
            href={`https://docs.google.com/spreadsheets/d/${props.sheetId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="use-spreadsheet"
          >
            Use Spreadsheet
          </a>
        </div>
      </div>
    </div>
  );
};

export default FinishModal;
