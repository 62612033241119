import React from "react";
// import "odometer/themes/odometer-theme-default.css";
import "./index.css";

const DebtTracker = ({ totalDebt, debtData, monthsLeftToPay }) => {
  return (
    <div className="debt-tracker">
      <h5>
        Total Debt: <strong>${totalDebt}</strong>
      </h5>
      <h5>
        Monthly Savings: <strong>$ {debtData}</strong>{" "}
      </h5>
      <h5>
        Months Left to Pay: <strong>{monthsLeftToPay} months</strong>{" "}
      </h5>
    </div>
  );
};

export default DebtTracker;
