import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "src/components/shared/Button";
import { newFinanceSpreadsheets } from "./data";
import "./index.css";

const NewFinanceLesson = ({ item, handleNext }) => {
  const currentLesson = useSelector((state) => state.currentLesson);
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const [currentStep, setCurrentStep] = useState(0);
  const [cellText, setCellText] = useState("");
  const [rightAnswer, setRightAnswer] = useState(null);
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState(null);
  const [steps, setSteps] = useState(newFinanceSpreadsheets[item.value]);
  const [blurred, setBlured] = useState(false);

  const incorrectAnswerRef = useRef(null);

  const checkAnswer = () => {
    let isAnswerCorrect = false;

    const expectedAnswer = steps[currentStep].answer;
    const isNumericAnswer =
      !isNaN(parseFloat(expectedAnswer)) && isFinite(expectedAnswer);

    if (isNumericAnswer) {
      const normalizedCellText = cellText.replace(/[^0-9.-]+/g, "");
      const userNumber = parseFloat(normalizedCellText);
      const expectedNumber = parseFloat(expectedAnswer);
      isAnswerCorrect = Math.abs(userNumber - expectedNumber) < 0.01;
    } else {
      const normalizedCellText = cellText.trim().toLowerCase();
      const normalizedExpectedAnswer = expectedAnswer.trim().toLowerCase();
      isAnswerCorrect = normalizedCellText === normalizedExpectedAnswer;
    }

    if (steps[currentStep].isDefault || isAnswerCorrect) {
      setRightAnswer(true);
    } else {
      setRightAnswer(false);
      if (incorrectAnswerRef.current) {
        incorrectAnswerRef.current.classList.remove("shake");
        // Trigger reflow to restart the animation
        void incorrectAnswerRef.current.offsetWidth;
        incorrectAnswerRef.current.classList.add("shake");
      }
    }
  };

  const handleFinishedModule = () => {
    setSelectedSpreadsheet(null);
  };

  const getPaid = () => {
    setCurrentStep(currentStep + 1);
  };

  const incrementIndex = () => {
    if (rightAnswer) {
      setCurrentStep(currentStep + 1);
      setRightAnswer(null);
    }
  };

  const decrementIndex = () => {
    if (rightAnswer) {
      setCurrentStep(currentStep - 1);
      setRightAnswer(null);
    }
  };

  if (!steps) {
    return <div style={styles.loader}>Loading...</div>;
  } else {
    return (
      <div className="financelesson__container" style={styles.container}>
        <div style={styles.scrollView}>
          <div style={styles.topContainer}>
            <h2 style={{ ...styles.title, ...styles.titleDynamic }}>
              {steps[currentStep].title}
            </h2>
            <div style={{ marginBottom: 20 }}>
              {rightAnswer === null ? null : rightAnswer === true ? (
                <div className="cellcorrectanswer__container">
                  Correct answer, click <strong>Next</strong> to continue
                </div>
              ) : (
                <span
                  className="financeincorrect__container"
                  ref={incorrectAnswerRef}
                >
                  Incorrect answer, try again.
                </span>
              )}
            </div>
            <div className="step__content">
              <div cstyle={styles.stepText}>{steps[currentStep].text}</div>
              {!steps[currentStep].finished ? (
                <div className="financecontent__container">
                  {steps[currentStep].content(setCellText, getPaid, setBlured)}
                </div>
              ) : (
                <Button
                  title="Next Section"
                  style={{ marginTop: 20 }}
                  onClick={handleNext}
                />
              )}
            </div>
          </div>
          <div style={{ width: 100 }}>
            {!steps[currentStep].finished && (
              <div>
                {!rightAnswer && !steps[currentStep].button ? (
                  <Button title="Check" isAnimated onClick={checkAnswer} />
                ) : !steps[currentStep].button ? (
                  <Button title="Next" isAnimated onClick={incrementIndex} />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

const styles = {
  container: {
    flex: 1,
    alignItems: "flex-start",
    paddingBottom: 50,
  },
  topContainer: {
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 15,
    marginTop: 10,
    alignSelf: "flex-start",
  },
  titleDynamic: {
    alignSelf: "flex-start",
    fontSize: 26,
    marginTop: 10,
    marginBottom: 20,
    fontWeight: "bold",
  },
};

export default NewFinanceLesson;
