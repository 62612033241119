import React from "react";
import { downloadPDF } from "../components/DataTable/components/GetReactPDF";
import LikertReport from "../components/PDFReports/LikertReport";
import SyllabusReport from "../components/PDFReports";
import { ClassStudentsReport } from "../components/PDFReports/ClassStudentsReport";
import AttendanceReport from "../components/PDFReports/AttendanceReport";
import UserTranscriptReport from "../components/PDFReports/UserTranscriptReport";
import NewUserTranscriptReport from "../components/PDFReports/NewUserTranscriptReport";

export const downloadPerformanceReportPDF = async (
  users,
  firebase,
  classData
) => {
  console.log("ViewClassroom 294 | downloading performance report");

  try {
    let likertDataPromises = [];
    users
      .filter((user) => !user.isAdmin || !user.isMentor)
      .forEach(async (user) => {
        if (!user.lessons) return;
        Object.keys(user.lessons).forEach((lessonId) => {
          likertDataPromises.push(
            firebase.getUserLikertData(`${user.uid}_${lessonId}`).once("value")
          );
        });
      });

    const result = await Promise.all(likertDataPromises);

    let likertData = [];

    result.forEach((likert) => {
      likertData.push(likert.val());
    });

    await downloadPDF({
      document: (
        <LikertReport
          data={likertData}
          className={classData.className}
          usersLength={users.length}
          documentTitle={`${classData.className} Performance Report.pdf`}
        />
      ),
      fileName: `${classData.className} Performance Report.pdf`,
    });
  } catch (error) {
    console.log("ViewClassroom 302 | error", error.message);
  }
};

export const downloadClassSyllabusPDF = (classroomData, modules) => {
  let pdfData = [];
  const {
    lsiAvailable,
    lsiInitialAssessment,
    lsiFinalAssessment,
    finalAssessmentAvailable,
    eqAvailable,
    eqInitialAssessment,
    eqFinalAssessment,
  } = classroomData.settings || {};

  console.log("reports.js 60 | classroom data", classroomData);
  if (lsiInitialAssessment || lsiAvailable) {
    pdfData.push({
      value: "LSI Initial Assessment",
      description:
        "General Assessment to evaluate the current state of the student",
    });
  }
  if (eqAvailable || eqInitialAssessment) {
    pdfData.push({
      value: "Emotional Intelligence Assessment",
      description:
        "Measures Emotional Intelligence to help students identify what areas they're good at, and what they could improve.",
    });
  }
  pdfData = [
    ...pdfData,
    ...Object.values(classroomData.settings.modules)
      .filter((mod) => mod.available)
      .sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1))
      .map((module, index) => {
        return {
          value: `${index + 1}. ${module.name}`,
          description: modules.find((m) => m.uid == module.uid).description,
        };
      }),
  ];

  if (eqFinalAssessment) {
    pdfData.push({
      value: "Emotional Intelligence Assessment POST Test",
      description:
        "Measures Emotional Intelligence to help students identify what areas they're good at, and what they could improve.",
    });
  }

  if (lsiFinalAssessment || finalAssessmentAvailable) {
    pdfData.push({
      value: "LSI Final Assessment",
      description:
        "Final Assessment to measure student improvment over the year",
    });
  }

  downloadPDF({
    document: (
      <SyllabusReport
        data={pdfData}
        className={classroomData.className}
        usersLength={10}
        documentTitle={`${classroomData.className}-Syllabus.pdf`}
      />
    ),
    fileName: `${classroomData.className} Syllabus.pdf`,
  });
};

const getDisplayNameFromId = (id, users) => {
  const user = users.find((user) => user.uid === id);
  return user ? user.displayName : undefined;
};

const getAttendanceData = (events, users) => {
  let eventAttendance = [];
  let attendeeSummary = {};

  events.forEach(([eventId, event]) => {
    console.log("event.attendees", event.attendees);
    let eventAttendees = Object.entries(event.attendees || {});
    let totalAttendees = eventAttendees.filter(([id, data]) =>
      getDisplayNameFromId(id, users)
    ).length;
    let confirmedAttendees = 0;

    eventAttendees.forEach(([id, data]) => {
      const displayName = getDisplayNameFromId(id, users);

      if (displayName) {
        console.log("displayName", displayName);

        // Initialize attendeeSummary for the attendee only if not already done
        if (!attendeeSummary[displayName]) {
          attendeeSummary[displayName] = {
            totalEvents: 0,
            attended: 0,
            confirmed: 0,
          };
        }

        attendeeSummary[displayName].totalEvents++;
        if (data.attendance) {
          console.log("this is happening attendance");
          attendeeSummary[displayName].attended++;
        }
        if (
          data.reminderConfirmed &&
          Object.values(data.reminderConfirmed).some((r) => r.confirmed)
        ) {
          console.log("this is happening confirmed");
          attendeeSummary[displayName].confirmed++;
        }
      }

      if (data.attendance) {
        confirmedAttendees++;
      }
    });

    eventAttendance.push({
      title: event.title,
      totalAttendees,
      confirmedAttendees,
    });
  });

  console.log("attendeeSummary", attendeeSummary);

  return { eventAttendance, attendeeSummary };
};

export const downloadAttendanceReportPDF = (users, firebase, classData) => {
  console.log("ViewClassroom 13 | downloading attendance report", classData);
  console.log("users", users);

  firebase
    .events()
    .orderByChild("classId")
    .equalTo(classData.className)
    .once("value")
    .then((eventObject) => {
      if (eventObject.val()) {
        console.log("eventObject");
        const events = Object.entries(eventObject.val());
        const { eventAttendance, attendeeSummary } = getAttendanceData(
          events,
          users
        );
        console.log(
          "eventAttendance",
          eventAttendance,
          "attendeeSummary",
          attendeeSummary
        );

        // Generate PDF
        downloadPDF({
          document: (
            <AttendanceReport
              users={users}
              eventAttendance={eventAttendance}
              attendeeSummary={attendeeSummary}
              className={classData.className}
              documentTitle={`${classData.className} Attendance Report.pdf`}
            />
          ),
          fileName: `${classData.className} Attendance Report.pdf`,
        });
      } else {
        console.log("No events found for the specified class");
      }
    })
    .catch((error) => {
      console.log("ViewClassroom 302 | error", error.message);
    });
};
export const downloadUsersReport = (classroomData, users) => {
  console.log("reports.js 108 | downloading useres", users);
  downloadPDF({
    document: (
      <ClassStudentsReport
        users={users}
        className={classroomData.className}
        documentTitle={`${classroomData.className}-Users-Report.pdf`}
      />
    ),
    fileName: `${classroomData.className}-Users-Report.pdf`,
  });
};
export const downloadUserTranscript = (userData) => {
  console.log("reports.js 108 | downloading userData", userData);
  downloadPDF({
    document: <UserTranscriptReport userData={userData} />,
    fileName: `${userData.displayName}-Transcript.pdf`,
  });
};

export const downloadNewUserTranscript = (userData) => {
  console.log("reports.js 108 | downloading userData", userData);
  downloadPDF({
    document: <NewUserTranscriptReport userData={userData} />,
    fileName: `${userData.displayName}-Transcript.pdf`,
  });
};
