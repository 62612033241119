import React from "react";
// import {compose} from 'recompose';

export const TextInput = () => {
  return (
    <input
      type="text"
      placeholder="Enter question here..."
      name="textInput"
      // onChange=
    />
  );
};
