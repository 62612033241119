import React, { useState, useEffect } from "react";
import "./index.css";
import { compose } from "recompose";
import { withFirebase } from "../../../Firebase";

import { AnsweredQuiz } from "./AnsweredQuiz";
import { QuizOption } from "./QuizOption";

export const Quiz = (props) => {
  const { checkQuizAnswer, quizzesDone, type, index, item } = props;
  const [quizAnswered, toggleQuizAnswered] = useState(false);

  const { question, answer, options, category } = item;

  if (quizzesDone || props.lessonComplete) {
    return (
      <AnsweredQuiz
        key={index}
        options={options}
        itemAnswer={answer}
        question={question}
        type={type}
      />
    );
  } else {
    return (
      <div className="quiz-container">
        <h5 className="quiz-question">{question}</h5>
        {/* {process.env.NODE_ENV === "development" ? answer : null} */}

        {options.map((quizData, index) => {
          if (quizData !== "") {
            return (
              <QuizOption
                key={index}
                option={quizData}
                itemAnswer={answer}
                checkQuizAnswer={checkQuizAnswer}
                category={category}
                quizAnswered={quizAnswered}
                toggleQuizAnswered={toggleQuizAnswered}
                type={type}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }
};

export default compose(withFirebase)(Quiz);
