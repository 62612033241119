import React from "react";
import "./DebtPaidModal.css";

const DebtPaidModal = (props) => {
  if (props.debtPaidModalVisible && !props.winModalVisible) {
    return (
      <div>
        <h1>Month Paid</h1>
        <p>Great! You've paid some of your loans.</p>
        <p>See how your debts were reduced below.</p>
        <p>Now the interest will kick in for Feburary!</p>
        <button className="add-interest-button" onClick={props._debtPaid}>
          Add Interest
        </button>
      </div>
    );
  } else {
    return null;
  }
};

export default DebtPaidModal;
