import React, { useEffect } from "react";
import "./index.css";
import { useState } from "react";
import moment from "moment";
import SingleChatOpen from "./components/SingleChatOpen";
import ChatMessagePreview from "./components/ChatMessagePreview";
import { generateTeacherUsers } from "./utils";
import { ChatBubbleButton } from "./components/ChatBubbleButton";
import ChatSearch from "./components/ChatSearch";
import { useSelector } from "react-redux";

export const ClassroomChat = ({
  firebase,
  authUser,
  classId,
  currentClassroomData,
}) => {
  const [chatOpen, setChatOpen] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [users, setUsers] = useState({});
  const [activeChat, setActiveChat] = useState(false);
  const [currentChatContact, setCurrentChatContact] = useState(null);
  const [currentSearch, setCurrentSearch] = useState("");
  const [groupChatActive, setGroupChatActive] = useState(false);

  const chats = useSelector((state) => state.chats);

  useEffect(() => {
    const unsubscribe = fetchClassChatroomRealtime();
    return () => {
      unsubscribe();
    };
  }, []);

  const fetchClassChatroomRealtime = () => {
    const chatroomRef = firebase.chatroom(classId);
    const unsubscribe = chatroomRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        const { rooms, users } = snapshot.val();
        const { teacherUsers } = generateTeacherUsers(
          currentClassroomData?.teachers
        );

        let usersToSet = { ...users, ...teacherUsers };

        rooms && setRooms(rooms);
        users && setUsers(usersToSet);
      } else {
        console.log("Chat 18 | no data from chatroom");
      }
    });

    return () => chatroomRef.off("value", unsubscribe);
  };

  const handleSearch = (e) => {
    setCurrentSearch(e.target.value);
  };

  if (!chats.chatBubbleOpen) return <ChatBubbleButton />;

  return (
    <>
      {activeChat && (
        <SingleChatOpen
          chatId={chats.currentChatId}
          users={users}
          rooms={rooms}
          contactName={currentChatContact}
          firebase={firebase}
          classId={classId}
          setActiveChat={setActiveChat}
          isGroupChat={groupChatActive}
        />
      )}
      <div className="chatmessages__container">
        <div className="chatmessages__tile">
          {currentClassroomData.className}
        </div>

        <div className="chatallmessages__container">
          <ChatMessagePreview
            key={123}
            user={classId} // Pass user as an array with [contactId, userDetails, chatData]
            authUser={authUser}
            rooms={rooms}
            setActiveChat={setActiveChat}
            setCurrentChatContact={setCurrentChatContact}
            classId={classId}
            firebase={firebase}
            isGroupChat={true}
            setGroupChatActive={setGroupChatActive}
          />
          {users &&
            Object.entries(users)
              .map(([userContactId, userDetails]) => {
                const chatId = [authUser.uid, userContactId].sort().join("-");
                const chatData = rooms[chatId] || {};
                return [userContactId, userDetails, chatData]; // Preserve the expected array structure
              })
              .sort((userA, userB) => {
                // Check if either date is undefined and adjust sort to move undefined dates to the end
                if (!userA[2]?.date && !userB[2]?.date) return 0; // Both dates are undefined, keep original order
                if (!userA[2]?.date) return 1; // a has no date, move it towards the end
                if (!userB[2]?.date) return -1; // b has no date, move it towards the end

                // Both dates are defined, proceed with normal sorting, latest first
                return (
                  moment(userB[2]?.date).unix() - moment(userA[2]?.date).unix()
                );
              })
              .filter((searchedUser) => {
                if (currentSearch.trim() === "") {
                  return true;
                }
                return searchedUser[1]?.includes(currentSearch);
              })
              .map((user, index) => (
                <ChatMessagePreview
                  key={index}
                  user={user} // Pass user as an array with [contactId, userDetails, chatData]
                  authUser={authUser}
                  rooms={rooms}
                  setActiveChat={setActiveChat}
                  setCurrentChatContact={setCurrentChatContact}
                  classId={classId}
                  firebase={firebase}
                  isGroupChat={false}
                  setGroupChatActive={setGroupChatActive}
                />
              ))}
        </div>
        <ChatSearch handleSearch={handleSearch} />
      </div>
      <ChatBubbleButton setChatOpen={setChatOpen} chatOpen={chatOpen} />
    </>
  );
};
