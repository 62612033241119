import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleChatBubbleOpen } from "src/store/chats/chatActions";

export const ChatBubbleButton = () => {
  const chats = useSelector((state) => state.chats);
  const dispatch = useDispatch();
  return (
    <div
      className="chatclosed__container"
      onClick={() => {
        console.log("openning chats bubble", chats);
        dispatch(toggleChatBubbleOpen(chats.chatBubbleOpen));
      }}
    >
      <img
        className="chatclosedicon__container"
        alt=""
        src="/images/chat-icon.png"
      />
    </div>
  );
};
