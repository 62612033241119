import React from 'react';
import './PulsatingButton.css'; // Import the CSS file

const PulsatingButton = ({ onClick, text, color = "red" }) => {
  return (
    <div
      onClick={onClick}
      className="pulsating-button-finance"
      style={{
        backgroundColor: color,
        color: 'white',
        borderRadius: 10,
        marginTop: 15,
        padding: 10,
        cursor: 'pointer',
        textAlign: 'center',
        display: 'inline-block'
      }}
    >
      <span>{text}</span>
    </div>
  );
};

export default PulsatingButton;