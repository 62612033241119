import React from "react";
import Icon from "src/components/shared/Icon";
import { Link } from "react-router-dom";

import "./index.css";

const NavLink = ({
  title,
  to,
  iconName = "email-white",
  onClick,
  adminAccessRequired,
  isAdmin,
}) => {
  if (adminAccessRequired && !isAdmin) return null;
  return (
    <Link
      onClick={() => onClick && onClick()}
      className="link__container"
      to={to}
    >
      <Icon name={iconName} width={20} height={20} />
      <p>{title}</p>
    </Link>
  );
};

export default NavLink;
