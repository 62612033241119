
const setUser = (user) => {
  return {
    action: "user/set",
    payload: user,
  };
};

const errorGettingUser = (error) => {
  return { action: "user/errorGettingUser", payload: error };
};

const setModalState = (openModal) => {
  return { type: 'user/toggleModal', payload: openModal };
};

export const getUser = () => {
  return async function dispatch() {
    try {
      dispatch(setUser({ user: "test user" }));
    } catch (error) {
      dispatch(errorGettingUser(error.message));
    }
  };
};

export const moduleStarted = (authUser, lessonId) => {
  // if the user already has the lesson, return
  if (authUser.lessons && authUser.lessons[lessonId]) {
    return { action: "user/allgood" };
  } else {
  }
  // if the user does not have the lesson, update start dateTime on DB
};

export const toggleSignupModal = (boolean) => {
  return async (dispatch) => {
    return dispatch(setModalState(boolean));
  }
};

export const updateUserProperty = () => {};
