import React, { useState, useEffect } from "react";
import { dayToDayDecisions } from "../../gameData";
import { Spin } from "antd"; // Importing Spin from Ant Design
import ExpenseChoice from "./ExpenseChoice";
import EmergencyExpense from "./EmergencyExpense";
import { BudgetSlider } from "../GameResult/BudgetResultSlider";
import "./BudgetingMonthSimulation.css";
import MonthTopHeader from "./MonthTopHeader";

const BudgetingMonthSimulation = ({ state, makeDecision }) => {
  const { emergency, insuranceType } = state;

  const [currentDecisionIndex, setCurrentDecisionIndex] = useState(0);
  const [shuffledDecisions, setShuffledDecisions] = useState([]);
  const [choiceLoading, setChoiceLoading] = useState(null);

  useEffect(() => {
    const shuffled = [...dayToDayDecisions].sort(() => Math.random() - 0.5);
    setShuffledDecisions(shuffled);
  }, []);

  const currentCategory = emergency
    ? "insurance"
    : shuffledDecisions[currentDecisionIndex]?.category;

  const handleDecision = (decision) => {
    let newDecision = null;

    newDecision = {
      ...decision.option,
      category: currentCategory,
    };

    // handles the choice of the user on a certain day
    makeDecision(newDecision, currentDecisionIndex);
    setChoiceLoading(decision.option.text);

    // timeout is for user to see consequence of actions
    setTimeout(() => {
      setChoiceLoading(null);
      setCurrentDecisionIndex((prev) => prev + 1);
    }, 1500);
  };

  if (currentDecisionIndex < shuffledDecisions.length) {
    return (
      <div className="budgetingmonthsimulation">
        <div className="simulationcontainer">
          <div className="budget-simulation">
            <MonthTopHeader
              state={state}
              currentDecisionIndex={currentDecisionIndex}
            />

            <div className="choicescontainermain">
              {emergency ? (
                <EmergencyExpense
                  emergencyTitle={emergency.description}
                  emergencyCost={emergency.deductibles[insuranceType]}
                  emergencyHappiness={emergency.happiness}
                  handleEmergencyPayment={handleDecision}
                  choiceLoading={choiceLoading}
                />
              ) : (
                <>
                  <div className="your-friends-are">
                    {shuffledDecisions[currentDecisionIndex].text}
                  </div>
                  <div className="choicescontainer">
                    {/* {choiceLoading ? (
                      <Spin style={{ marginTop: 40 }} size="large" />
                    ) : ( */}
                    {console.log("choiceLoading", choiceLoading, shuffledDecisions[currentDecisionIndex])}
                    {shuffledDecisions[currentDecisionIndex].options.map(
                      (option, index) => (
                        <ExpenseChoice
                          key={index}
                          option={option}
                          handleChoice={handleDecision}
                          choiceLoading={choiceLoading === option.text}
                          loading={choiceLoading}
                        />
                      )
                    )}
                    {/* )} */}
                  </div>
                </>
              )}
              <BudgetSlider
                categoryTitle={currentCategory}
                budgeted={state.budget[currentCategory]}
                spent={state.expenses[currentCategory].cost}
                max={1000}
                state={state}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h3>Month completed!</h3>
        <button onClick={() => setCurrentDecisionIndex(0)}>
          Start next month
        </button>
      </div>
    );
  }
};

export default BudgetingMonthSimulation;
