import { toast } from "react-toastify";

const apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/red-platform/us-central1"
    : "https://us-central1-red-platform.cloudfunctions.net";
// const apiUrl = "https://us-central1-red-platform.cloudfunctions.net";
// https://us-central1-red-platform.cloudfunctions.net/questSubmit

export const submitQuestData = async (questData) => {
  toast("Submitting. Please wait...");
  console.log("quest.js 8 | sending quest data to firebase functions", apiUrl);
  try {
    const request = await fetch(`${apiUrl}/questSubmit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ questData }),
    });
    const data = await request.json();
    console.log("quest.js 0", data);
    return data;
  } catch (error) {
    console.log("quest.js 21 | error", error.message);
    throw new Error(error);
  }
};

export const createQuestCohort = async (questData) => {
  console.log("quest.js 8 | sending quest data to heroku backend");
  const request = await fetch(`${apiUrl}/questCreateCohort`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await request.json();
  console.log("quest.js 0", data);
  return data;
};
