const apiUrl = "";

const isTokenExpired = () => {
  const expirationDate = localStorage.getItem("expirationDate");
  const currentTime = new Date().getTime();
  const expirationTime = new Date(expirationDate).getTime();

  if (expirationDate === "undefined") {
    console.log("token.js 6 | expiration is undefined");
    return true;
  }
  if (currentTime > expirationTime) {
    return true;
  }

  return false;
};

export const getToken = async () => {
  // if token has expired, return false
  const refreshToken = localStorage.getItem("refreshToken");
  const token = localStorage.getItem("token");
  // console.log("token.js 15 | refresh token", refreshToken);

  try {
    if (isTokenExpired()) {
      const url =
        "https://us-central1-red-platform.cloudfunctions.net/getNewAccessToken";
      const data = {
        refresh_token: refreshToken,
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(url, options);
      const json = await response.json();

      if (json.error) {
        throw new Error("issue with token request");
      }

      if (json.token) {
        const expirationDate = new Date(new Date().getTime() + 3600000);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("token", json.token);
        return json.token;
      } else {
        throw new Error("No token received");
      }
    } else {
      // console.log("token.js 42 | token not expired", token);
      // console.log("token.js 26 | token expired", isTokenExpired());
      return localStorage.getItem("token");
    }
  } catch (error) {
    console.log("token.js 39 | error getting token", error);
  }

  return localStorage.getItem("token");
};
