/* eslint-disable */
import React, { Fragment } from "react";
import { Document, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";

import PoppinsRegular from '../../../../fonts/Poppins/Poppins-Regular.ttf';
import PoppinsBold from '../../../../fonts/Poppins/Poppins-Bold.ttf';

const renderCell = (index, header, value) => (
  <Text
    style={index === 0 ? styles.cellBoldStyle : styles.cellStyle}
  >
    {index === 0 ? header : value}
  </Text>
);
export function ClassStudentsReport({ users, className, documentTitle }) {
  const totalStudents = users.length;
  const activeStudents = users.filter((user) => !user.inactive).length;
  const activeStudentsRate = ((activeStudents / totalStudents) * 100).toFixed(
    2
  );
  return (
    <Document title={documentTitle} style={styles.documentStyle}>
      <Page size="A4" orientation="portrait">
        <View style={styles.page}>
          <Text style={[styles.titleStyle, styles.boldStyle]}>{className}</Text>
          <Text style={{ paddingBottom: 8 }}>
            Student names, emails and phone numbers.
          </Text>
          <Text>Active Students Rate</Text>
          <Text
            style={[styles.boldStyle, { paddingBottom: 16 }]}
          >
            {activeStudentsRate}%
          </Text>
          {users &&
            users.map((user, index) => (
              <View
                wrap={false}
                key={index}
                style={
                  user.inactive ? styles.inactiveRowStyle : styles.rowStyle
                }
              >
                <Text
                  style={index === 0 ? styles.cellBoldIndexStyle : styles.cellIndexStyle}
                >
                  {index === 0 ? "No" : index}
                </Text>
                {renderCell(index, "Students", user.displayName)}
                {renderCell(index, "Email", user.email)}
                {renderCell(index, "Phone", user.phoneNumber)}
              </View>
            ))}
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  documentStyle: {
    fontFamily: "Helvetica",
    backgroundColor: "#ffffff",
    color: "#000000",
  },
  titleStyle: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    fontSize: 18,
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 2,
    width: "100%",
  },
  inactiveRowStyle: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 2,
    width: "100%",
    backgroundColor: "lightcoral",
  },
  cellIndexStyle: {
    width: 50,
    borderBottomWidth: 1,
    borderBottomColor: "#CECECE",
    borderBottomStyle: "solid",
    padding: 10,
    fontSize: 8,
  },
  cellBoldIndexStyle: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    width: 50,
    borderBottomWidth: 1,
    borderBottomColor: "#CECECE",
    borderBottomStyle: "solid",
    padding: 10,
    fontSize: 8,
  },

  cellStyle: {
    width: 200,
    borderBottomWidth: 1,
    borderBottomColor: "#CECECE",
    borderBottomStyle: "solid",
    padding: 10,
    fontSize: 8,
  },
  cellBoldStyle: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    width: 200,
    borderBottomWidth: 1,
    borderBottomColor: "#CECECE",
    borderBottomStyle: "solid",
    padding: 10,
    fontSize: 8,
  },

  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Helvetica",
    fontSize: 10,
    padding: "30px 40px",
    color: "#000000",
  },
  pdf_page_title: {
    fontWeight: "bold",
    fontSize: 18,
    fontFamily: "Helvetica-Bold",
  },
  pdf_page_subtitle: {
    fontWeight: "bold",
    fontSize: 14,
  },
  pdf_normal_space: {
    marginTop: 10,
  },
  pdf_big_space: {
    marginTop: 20,
  },
  pdf_bold: {
    fontWeight: "bold",
    color: "#000000",
  },
  redTitle: {
    fontSize: 14,
    color: "#FFFFFF",
  },
  redContainer: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#d94645",
    width: 220,
    height: 40,
    padding: 10,
    //   fontFamily: "Poppins",
  },
  boldStyle: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
});
