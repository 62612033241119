import React from "react";
import "./DebtWinModal.css";

const DebtWinModal = (props) => {
  if (props.winModalVisible) {
    return (
      <div className="debt-win">
        <h1>You successfully paid off your Debts!</h1>
        <button
          className="btn btn-success"
          onClick={() => props.handleNextButtonPressed()}
        >
          Next Lesson
        </button>
        <button onClick={() => props.restart()}>Play Again</button>
      </div>
    );
  } else {
    return null;
  }
};

export default DebtWinModal;
