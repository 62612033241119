const initialState = {
  authUser: null,
  error: null,
  openLoginModal:false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "user/set":
      return { ...state, user: payload };
    case "user/errorGettingUser":
      return { ...state, error: payload };
    case "user/toggleModal":
      return { ...state, openLoginModal: payload };
    default:
      return state;
  }
};
