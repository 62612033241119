import React, { useEffect, useState } from "react";
import "./index.css";
import Task from "./Task";

const CourseTasks = ({
  calendarEvents,
  classroomModules,
  classroomEvents,
  userData,
}) => {
  const [allTasks, setAllTasks] = useState([]);

  const handleAllTasks = () => {
    let standardizedTasks = [];

    if (calendarEvents) {
      calendarEvents.forEach((event) => {
        standardizedTasks.push({
          name: event.summary,
          dueDate: event.start.dateTime,
          // status
          status: userData.events
            ? userData.events[event.id].attended
              ? "done"
              : "notDone"
            : "notDone",
        });
      });
    }
    if (classroomModules) {
      classroomModules.forEach((module) => {
        standardizedTasks.push({
          name: module.name,
          dueDate: module.dueDate,
          // status
          status: userData?.lessons[module?.uid]?.lessonComplete
            ? "done"
            : "notDone",
        });
      });
    }
    if (classroomEvents) {
      // still need to develop classroom manager
    }
    standardizedTasks.sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1));
    console.log("CourseTasks.js 31 | standardized tasks", standardizedTasks);
    setAllTasks(standardizedTasks);
  };

  useEffect(() => {
    handleAllTasks();
  }, []);

  return (
    <div className="tasklist__container">
      {allTasks &&
        allTasks.map(({ name, dueDate, status }, index) => {
          return (
            <Task key={index} name={name} dueDate={dueDate} status={status} />
          );
        })}
    </div>
  );
};

export default CourseTasks;
