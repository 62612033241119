export const joinedProgram = () => {
  return {
    subject: "Welcome to RED (Rehabilitation Enables Dreams)",
    message: `
    <div>
    
    <strong>Welcome to the RED Restorative Justice Program. We are glad that you have decided to join our program and we look forward to getting to know you over the next 12 months.</strong>

<br/>
Here are a few things to keep in mind:
<ol>
   <li>
    You were provided with a checklist during orientation. Please make sure you attend and complete all required events highlighted in yellow. Here is a summary of the required monthly activities:
     <ul>
        <li>Please make sure you study & complete your online course for the month</li>
        <li>Attend and participate in the in-court classroom session</li>
        <li>Attend and participate in the monthly Pro-Community Outing</li>
        <li>Meet with your mentor to discuss your goals, progress, and guidance for any next-steps</li>
     </ul> 
   </li>
   <li>
    David Windecher and Zeus Luby spoke during program orientation. If you’re interested in learning more about David’s story or about the RED program, please visit www.stoprecidivism.org. 
   </li>
   <li>
    If you have any technical questions or get stuck at any point in the online curriculum, please contact Ignacio Aguirre: Ignacio@stoprecidivism.org
   </li>
   <li>
    If you do not have your GED, you will be enrolled into the GEDWorks program and will receive an email to schedule an appointment with your online advisor. This advisor is separate from your RED Mentor. If you do not receive this email by month 2, please reach out to your mentor or the RED support staff. You must complete your GED in order to graduate from the RED program. 
   </li>

    <li>It is important that you communicate your RED schedule with your employers, teachers, and family members to inform them of your absence. If you need to make any travel arrangements, please do so.</li>

   <li>Upon completion of this program, your case will be dismissed, your record will be restricted and your case sealed. You will also be awarded the rights to the laptop RED lent you. We will assist you in connecting with employment opportunities, housing, vocational training, and entrepreneurship support. After graduation, you will be given an opportunity to join the RED Alumni Group. We look forward to your success and achievements.</li>
</ol>
<div>RED Team</div>
<img src="https://firebasestorage.googleapis.com/v0/b/red-platform.appspot.com/o/images%2Fred-email-logo.svg?alt=media&token=f89b686c-dd24-4858-a72e-ebd717c9361f" width="200px" height="200px"/>
</div> 
`,
  };
};

export const gedWelcome = () => {
  return {
    subject: "",
    message: "",
  };
};

export const completedModule = (moduleName) => {
  return {
    subject: "RED Course Completion Certificate",
    message: `Congratulations! You have successfully completed the ${moduleName} module. Your Certificate of Completion can be found on the MyREDEd platform or by clicking this link: myreded.com/certificates . You will receive an email notification when the next module is open for study. - RED Team`,
  };
};

export const moduleIsAvailable = () => {
  return {
    subject: "",
    message: "",
  };
};

export const mentorTextNotification = () => {
  return "Your RED mentee is trying to reach you on the RED platform. Please login to myreded.com to respond at your earliest convenience. Thank you!";
};

export const joinedProgramTemplate = () => {
  return `<!DOCTYPE html>
<html>
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title></title>

    <!--[if !mso]><!-->
    <style type="text/css">
        @import url('https://fonts.googleapis.com/css?family=Poppins:400,600');
    </style>
    <!--<![endif]-->

    <style type="text/css" rel="stylesheet" media="all">
        @media only screen and (max-width: 640px) {

            .ms-header {
                display: none !important;
            }
            .ms-content {
                width: 100% !important;
                border-radius: 0;
            }
            .ms-content-body {
                padding: 30px !important;
            }
            .ms-footer {
                width: 100% !important;
            }
            .mobile-wide {
                width: 100% !important;
            }
            .info-lg {
                padding: 30px;
            }
        }
    </style>
</head>
<body style="font-family:'Poppins', Helvetica, Arial, sans-serif; width: 100% !important; height: 100%; margin: 0; padding: 0; -webkit-text-size-adjust: none; background-color: #f4f7fa; color: #4a5566;">

<div class="preheader" style="display:none !important;visibility:hidden;mso-hide:all;font-size:1px;line-height:1px;max-height:0;max-width:0;opacity:0;overflow:hidden;"></div>

<table class="ms-body" width="100%" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;background-color:#f4f7fa;width:100%;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;">
    <tr>
        <td align="center" style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;">

            <table class="ms-container" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse;width:100%;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;">
                <tr>
                    <td align="center" style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;">

                        <table class="ms-header" width="100%" cellpadding="0" cellspacing="0" style="border-collapse:collapse;">
                            <tr>
                                <td height="40" style="font-size:0px;line-height:0px;word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;">
                                     
                                </td>
                            </tr>
                        </table>

                    </td>
                </tr>
                <tr>
                    <td align="center" style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;">

                        <table class="ms-content" width="640" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;width:640px;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;background-color:#FFFFFF;border-radius:6px;box-shadow:0 3px 6px 0 rgba(0,0,0,.05);">
                            <tr>
                                <td class="ms-content-body" style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;padding-top:40px;padding-bottom:40px;padding-right:50px;padding-left:50px;">
<img width="100px" src="https://bucket.mailersendapp.com/0r83ql3eyx4zw1jm/zr6ke4n1xdygon12/images/958cf049-ee24-42de-8426-a56e00c5f035.png">

                                    <h1 style="margin-top:0;color:#111111;font-size:24px;line-height:36px;font-weight:600;margin-bottom:24px;">Welcome, {$name}!</h1>

                                    <p style="color:#4a5566;margin-top:20px;margin-bottom:20px;margin-right:0;margin-left:0;font-size:16px;line-height:28px;">Thanks for trying {$account.name}. We’re thrilled to have you on board. To get the most out of {$account.name}, do this next step:</p>

                                    <table width="100%" align="center" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;">
                                        <tr>
                                            <td align="center" style="padding-top:20px;padding-bottom:20px;padding-right:0;padding-left:0;word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;">

                                                <table class="mobile-wide" border="0" cellspacing="0" cellpadding="0" role="presentation" style="border-collapse:collapse;">
                                                    <tr>
                                                        <td align="center" class="btn" style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;background-color:#0052e2;box-shadow:0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);border-radius:3px;">
                                                            <a href="{$action_url}" target="_blank" style="background-color:#0052e2;padding-top:14px;padding-bottom:14px;padding-right:30px;padding-left:30px;display:inline-block;color:#FFF;text-decoration:none;border-radius:3px;-webkit-text-size-adjust:none;box-sizing:border-box;border-width:0px;border-style:solid;border-color:#0052e2;font-weight:600;font-size:15px;line-height:21px;letter-spacing:0.25px;">Next step</a>
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>

                                    <p style="color:#4a5566;margin-top:20px;margin-bottom:20px;margin-right:0;margin-left:0;font-size:16px;line-height:28px;">For reference, here's your login information:</p>

                                    <table width="100%" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;">
                                        <tr>
                                            <td class="info" style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;padding-top:20px;padding-bottom:20px;padding-right:20px;padding-left:20px;border-radius:4px;background-color:#f4f7fa;">

                                                <table width="100%" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;">
                                                    <tr>
                                                        <td style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;">
                                                            <strong style="font-weight:600;">Login Page:</strong> {$login_url}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;">
                                                            <strong style="font-weight:600;">Username:</strong> {$username}
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>

                                    <p style="color:#4a5566;margin-top:20px;margin-bottom:20px;margin-right:0;margin-left:0;font-size:16px;line-height:28px;">You've started a {$trial_length} day trial. You can upgrade to a paying account or cancel any time.</p>

                                    <table width="100%" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;">
                                        <tr>
                                            <td class="info" style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;padding-top:20px;padding-bottom:20px;padding-right:20px;padding-left:20px;border-radius:4px;background-color:#f4f7fa;">

                                                <table width="100%" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;">
                                                    <tr>
                                                        <td style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;">
                                                            <strong style="font-weight:600;">Trial Start Date:</strong> {$trial_start_date}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;">
                                                            <strong style="font-weight:600;">Trial End Date:</strong> {$trial_end_date}
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>

                                    <p style="color:#4a5566;margin-top:20px;margin-bottom:20px;margin-right:0;margin-left:0;font-size:16px;line-height:28px;">If you have any questions about this invoice, simply reply to this email or reach out to our <a href="{$support_url}" style="color:#0052e2;">support team</a> for help.</p>

                                    <p style="color:#4a5566;margin-top:20px;margin-bottom:20px;margin-right:0;margin-left:0;font-size:16px;line-height:28px;">Cheers,
                                        <br>{$from.name} and the {$account.name} Team</p>

                                    <p style="color:#4a5566;margin-top:20px;margin-bottom:20px;margin-right:0;margin-left:0;font-size:16px;line-height:28px;"><strong style="font-weight:600;">P.S.</strong> Need help getting started? Check out our <a href="{$help_url}" style="color:#0052e2;">help documentation</a>. Or, just reply to this email, the {$account.name} support team is always ready to help!</p>

                                    <table width="100%" style="border-collapse:collapse;">
                                        <tr>
                                            <td height="20" style="font-size:0px;line-height:0px;word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;">
                                                 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td height="20" style="font-size:0px;line-height:0px;border-top-width:1px;border-top-style:solid;border-top-color:#e2e8f0;word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;">
                                                 
                                            </td>
                                        </tr>
                                    </table>

                                    <p class="small" style="color:#4a5566;margin-top:20px;margin-bottom:20px;margin-right:0;margin-left:0;font-size:14px;line-height:21px;">If you’re having trouble with the button above, copy and paste the URL below into your web browser.</p>
                                    <p class="small" style="color:#4a5566;margin-top:20px;margin-bottom:20px;margin-right:0;margin-left:0;font-size:14px;line-height:21px;">{$action_url}</p>

                                </td>
                            </tr>
                        </table>

                    </td>
                </tr>
                <tr>
                    <td align="center" style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;">

                        <table class="ms-footer" width="640" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;width:640px;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;">
                            <tr>
                                <td class="ms-content-body" align="center" style="word-break:break-word;font-family:'Inter', Helvetica, Arial, sans-serif;font-size:16px;line-height:24px;padding-top:40px;padding-bottom:40px;padding-right:50px;padding-left:50px;">
                                    <p class="small" style="margin-top:20px;margin-bottom:20px;margin-right:0;margin-left:0;color:#96a2b3;font-size:14px;line-height:21px;">© 2020 {$account.name}. All rights reserved.</p>
                                    <p class="small" style="margin-top:20px;margin-bottom:20px;margin-right:0;margin-left:0;color:#96a2b3;font-size:14px;line-height:21px;">
                                        1234 Street Rd.
                                        <br>Suite 1234
                                        <br>City, State, ZIP Code
                                    </p>
                                </td>
                            </tr>
                        </table>

                    </td>
                </tr>
            </table>

        </td>
    </tr>
</table>


<table style="width: 640px; min-width: 640px;" class="mlContentTable ml-hide-branding" width="640" cellspacing="0" cellpadding="0" border="0" align="center">
    <tbody>
    <tr>
        <td align="center">
            <a target="_blank" href="https://www.mailersend.com">
                <img alt="Delivered by MailerSend" src="https://assets.mailersendapp.com/images/logo/delivered_by_mailersend.png" style="display: block; border: 0;" height="32" border="0">
            </a>
        </td>
    </tr>
    <tr>
        <td class="spacingHeight-20" height="40"></td>
    </tr>
    </tbody>
</table>
</body>
</html>`;
};
