import React from "react";
import { Bar, Pie, Line } from "react-chartjs-2";

const DebtChart = ({
  chartTitle,
  chartData,
  chartLabels,
  chartType = "Bar",
  responsive = true,
  aspectRatio = false,
}) => {
  const data = {
    labels: chartLabels, // expects an array like ['January', 'February'] same length as chartData

    datasets: [
      {
        label: chartTitle,
        data: chartData, // expects an array like [100, 155] same length as chartMonths
        backgroundColor: [
          "rgba(54, 162, 235, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(54, 162, 235, 0.8)",
        ],
      },
    ],
  };

  const options = {
    layout: {
      padding: {
        // top: 5,
        // left: 15,
        // right: 15,
        // bottom: 15,
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    bezierCurve: false,
    scales: {
      // y: { beginAtZero: true },
      yAxes: [
        {
          ticks: {
            max: 3000,
            beginAtZero: true,
            stepSize: 100,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
    },
    responsive: responsive,
    maintainAspectRatio: aspectRatio,
  };

  switch (chartType) {
    case "Pie":
      return <Pie data={data} options={options} />;
    case "Bar":
      return <Bar data={data} options={options} />;
    case "Line":
      return <Line data={data} options={options} />;
    default:
      return null;
  }
};

export default DebtChart;
