import React from "react";
import RangeInput from "./RangeInput";
import { capitalizeFirstLetter } from "src/utils/utils";

const BudgetCategoryAdjust = ({
  title,
  amount,
  state,
  onAdjustBudget,
  totalBudget,
}) => {
  return (
    <div className="categorybudget">
      <div className="titlemoney">
        <div className="title2">
          <img className="devicesjoystick-icon" alt="" src="/fun-icon.svg" />
          <div className="adjust-income">
            {capitalizeFirstLetter(title)}:{" "}
            <span className="expense-chosen" style={{ color: "gray" }}>
              {state.expenses[title].name}
            </span>
          </div>
        </div>
        <div className="adjust-income-amount">$ {amount}</div>
      </div>

      <RangeInput
        title={title}
        category={title}
        amount={amount}
        state={state}
        onAdjustBudget={onAdjustBudget}
        maxValue={500}
        totalBudget={totalBudget}
      />
    </div>
  );
};

export default BudgetCategoryAdjust;
