import React, { useContext } from "react";
import "./EIResultsContainer.css";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PreTestTab from "./PreTest";
import PostTestTab from "./PostTest";

export const EIResultsContainer = ({ preTestResults, postTestResults }) => {
  return (
    <div className="resultscontainer">
      <b className="eq-assessment">EQ Assessment</b>
      <div className="explore-your-emotional">
        Explore Your Emotional Intelligence across Three Categories:
      </div>

      <Tabs>
        <TabList>
          <Tab>Pre-Test</Tab>
          <Tab>Post-Test {`${!postTestResults ? "(not taken)" : ""}`}</Tab>
        </TabList>
        <TabPanel>
          <PreTestTab preTestResults={preTestResults} />
        </TabPanel>
        <TabPanel>
          <PostTestTab
            preTestResults={preTestResults}
            postTestResults={postTestResults}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};
