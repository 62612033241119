import React, { useEffect, memo } from "react";

export const CheckboxesContainer = ({
  label,
  checkboxes,
  name,
  handleCheckboxClick,
  index,
  category,
  required,
  defaultChecked,
  id,
  type,
  checked,
}) => {
  let checkedValues = [];

  if (defaultChecked) {
    let checksArray = Object.values(defaultChecked);
    checksArray.map((e) => checkedValues.push(e.name));
  }

  useEffect(() => {}, [checkboxes]); // This effect will run when 'checkboxes' prop changes

  return (
    <div className="checkboxes__container">
      <p className="checkboxescontainer__text">
        {index + 1}. {label} (Check all that apply){" "}
        <span
          style={{
            color: required ? "red" : "black",
            verticalAlign: "middle",
            fontSize: 12,
          }}
        >
          {required ? "(required)" : "(optional)"}
        </span>
      </p>
      <div className="checkboxes-container">
        {checkboxes
          .filter((checkbox) => checkbox.checked)
          .map((checkbox) => (
            <div>{checkbox.label}</div>
          ))}
        <div className="checkbox-inputs-container">
          {checkboxes.map((checkbox, checkboxIndex) => {
            return (
              <label key={checkboxIndex} index={checkboxIndex}>
                <Checkboxes
                  name={checkbox.label}
                  checkboxIndex={checkboxIndex}
                  placeholder={checkbox.label}
                  checked={checked}
                  onChange={(e) =>
                    handleCheckboxClick(
                      e,
                      index,
                      name,
                      checkboxIndex,
                      category,
                      id
                    )
                  }
                />
                <span> </span>
                {checkbox.label}
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Checkboxes = memo(
  ({ name, checked, onChange, label, id, checkboxIndex }) => {
    useEffect(() => {}, [name, checked, onChange, label]); // This effect will run when any of these props change

    return (
      <input
        type="checkbox"
        name={name}
        required
        placeholder={label}
        onChange={onChange}
        checked={checked[checkboxIndex]?.checked || false}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.name === nextProps.name &&
      prevProps.checked === nextProps.checked &&
      prevProps.onChange === nextProps.onChange &&
      prevProps.label === nextProps.label
    );
  }
);
