import React, { useState } from "react";
import "./DiversionPaymentCard.css";
import { FinancialAidRequestModal } from "./PreAdjudication/components/financialAidRequestModal";
import { StripePaymentLinkButton } from "./StripePaymentButton";
import Icon from "./shared/Icon";

const DiversionPaymentCard = ({ authUser, isNonprofit }) => {
  const [financialAidModal, setFinancialAidModal] = React.useState(false);

  return (
    <div className="diversionpaymentcard">
      <div className="div11">
        <span className="span1">$</span>
        <b className="b4">500</b>
      </div>
      <div className="get-your-record-container">
        <p className="get-your-record">Access all the modules</p>
        <p className="get-your-record">Certificate Upon Completion</p>
        <p className="get-your-record">Technical Support</p>
      </div>
      <div className="button-parent-payment">
        <StripePaymentLinkButton userId={authUser.uid} isNonprofit={isNonprofit} />

        {/* <div className="afford">
          <div className="info-outline">
            <img className="vector-icon" alt="" src="/vector.svg" />
            <img className="vector-icon1" alt="" src="/vector1.svg" /> 
            <img className="vector-icon" alt="" src="/vector2.svg" /> 
            <div className="tooltip">
              <img
                className="arrow-position-icon"
                alt=""
                src="/arrow-position.svg"
              />
              <div className="body">
                <div className="tooltip-message-will">
                  You apply if you make under $30,000 a year. Send us your tax
                  returns.
                </div>
              </div>
            </div>
          </div>
          <div className="cant-afford-it-container">
            <span>{`Cant afford it? `}</span>
            <span className="apply" onClick={() => setFinancialAidModal(true)}>
              Apply
            </span>
            <span> to our indigents program</span>
          </div>
        </div> */}
      </div>
      {financialAidModal && (
        <FinancialAidRequestModal setFinancialAidModal={setFinancialAidModal} />
      )}
      <div className="poweredby__stripe">
        Secure checkout powered by Stripe
        <Icon name="lock" width={20} height={20} style={{ marginLeft: 5 }} />
      </div>
    </div>
  );
};

export default DiversionPaymentCard;
