import React from "react";
import "./index.css";

export const NextButton = ({ handleNext, disabled }) => {
  return (
    <div
      className={!disabled ? "next__button" : "nextbutton__disabled"}
      onClick={() => !disabled && handleNext()}
    >
      Next
    </div>
  );
};
