const { API_URL } = require("./apiIUrl");

export const sendTextMessage = async (to, body) => {
  console.log("texts.js 3 | sending text message", to, body);
  try {
    const text = await fetch(`${API_URL}/sendTextMessage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ to, body }),
    });
    const response = await text.json();
    return response;
  } catch (error) {
    throw new Error("Error sending text", error);
  }
};
