/* eslint-disable */
import React, { Fragment } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { sampleLikertData } from "./sampleLikertData";
import { dataLength } from "src/utils/dataLength";


function LikertReport({ data, className, documentTitle }) {

  const reportData = generateReportData(data);

  return (
    <Document title={documentTitle} style={styles.documentStyle}>
      <Page size="A4" orientation="portrait">
        <View style={styles.page}>
          <Text style={styles.pdf_page_title}>{className} Likert Report</Text>
          <View>
            {Object.entries(reportData).map(([name, scores]) => {
              if (
                !scores.averagePreTestScorePercent ||
                !scores.averagePostTestScorePercent
              ) {
                return null;
              }
              return (
                <View style={styles.lessonContainer}>
                  <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold", paddingRight: 20 }}>
                    {name}{" "}
                    {(
                      scores.averagePostTestScorePercent -
                      scores.averagePreTestScorePercent
                    ).toFixed(0)}
                    %
                  </Text>
                  {
                    <DeltaBar
                      preTest={scores.averagePreTestScorePercent}
                      postTest={scores.averagePostTestScorePercent}
                    />
                  }
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
}

const DeltaBar = ({ preTest, postTest }) => {
  const delta = postTest - preTest;
  const preTestWidth = (preTest / 100) * 100;
  const deltaWidth = (delta / 100) * 100;
  return (
    <View style={styles.deltaBarContainer}>
      <View style={[styles.preTestBar, { width: `${preTestWidth}%` }]} />
      <View style={[styles.deltaBar, { width: `${deltaWidth}%` }]} />
      <Text style={styles.deltaText}>
        {delta > 0 ? `+${delta.toFixed(0)} %` : `${delta.toFixed(0)} %`}
      </Text>
    </View>
  );
};

const generateReportData = (data) => {
  console.log("LikertReport.js 78 | generating report with data", data);
  const result = {};

  data
    .filter((lesson) => lesson && lesson.preTest && lesson.postTest) // filter out null values and lessons without tests
    .forEach((lesson) => {
      const preTestScores = lesson.preTest
        .filter((item) => item)
        .map((item) => item.value);

      const postTestScores = lesson.postTest
        .filter((item) => item)
        .map((item) => item.value);

      const totalPreScore = preTestScores.reduce(
        (sum, value) => sum + value,
        0
      );
      const totalPostScore = postTestScores.reduce(
        (sum, value) => sum + value,
        0
      );

      const averagePreScorePercent =
        (totalPreScore / (preTestScores.length * 5)) * 100;
      const averagePostScorePercent =
        (totalPostScore / (postTestScores.length * 5)) * 100;

      result[lesson.name] = {
        averagePreTestScorePercent: averagePreScorePercent.toFixed(2),
        averagePostTestScorePercent: averagePostScorePercent.toFixed(2),
      };
    });

  console.log("LikertReport.js 111 | result", result);

  return result;
};

const styles = StyleSheet.create({
  lessonContainer: {
    marginBottom: 10,
  },
  deltaBarContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: 20,
    // backgroundColor: "rgba(217, 70, 69, 0.2)", //red
    backgroundColor: "rgba(0, 186, 136, 0.2)", //green
    borderRadius: 5,
    overflow: "hidden",
    marginTop: 10,
    position: "relative",
  },
  deltaBarContainerPositive: {
    backgroundColor: "rgba(0, 186, 136, 0.2)", //green
  },
  deltaBarContainerNegative: {
    backgroundColor: "rgba(217, 70, 69, 0.2)", //red
  },
  deltaBarPositive: {
    backgroundColor: "rgba(0, 186, 136, 0.2)", //green
  },
  deltaBarNegative: {
    backgroundColor: "rgba(217, 70, 69, 0.2)", //red
  },
  preTestBar: {
    backgroundColor: "rgba(0, 186, 136, 1)",
    height: "100%",
  },
  deltaBar: {
    backgroundColor: "rgba(0, 229, 167, 1)",
    height: "100%",
    zIndex: 10,
  },
  deltaText: {
    position: "absolute",
    right: 40,
    top: 3,
    color: "#000",
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  documentStyle: {
    fontFamily: "Helvetica",
    backgroundColor: "#ffffff",
    color: "#000000",
  },

  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Helvetica",
    fontSize: 10,
    padding: "30px 40px",
    color: "#000000",
  },

  pdf_page_title: {
    fontWeight: "bold",
    fontSize: 18,
    fontFamily: "Helvetica-Bold",
    marginBottom: 32,
  },

  pdf_page_subtitle: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    fontSize: 14,
    paddingBottom: 5,
  },

  pdf_normal_space: {
    marginTop: 10,
  },

  pdf_big_space: {
    marginTop: 20,
  },

  pdf_bold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    color: "#000000",
  },

  redTitle: {
    fontSize: 14,
    color: "#FFFFFF",
  },

  greenTitle: {
    fontSize: 14,
    color: "#FFFFFF",
  },

  redContainer: {
    marginTop: 10,
    marginBottom: 20,
    backgroundColor: "#d94645",
    width: 220,
    height: 40,
    padding: 10,
    fontFamily: "Helvetica-Bold",
  },

  greenContainer: {
    marginTop: 10,
    marginBottom: 20,
    backgroundColor: "#4CAF50",
    width: 220,
    height: 40,
    padding: 10,
    fontFamily: "Helvetica-Bold",
  },

  entryContainer: {
    paddingBottom: 10,
  },
});

export default LikertReport;
