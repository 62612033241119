import React, { Component } from "react";

import ReactSpeedometer from "react-d3-speedometer";

export class DangerLevel extends Component {
  render() {
    return (
      <div className="danger-level-container">
        <p>
          <strong>Danger Level</strong>
        </p>
        <ReactSpeedometer
          maxValue={100}
          value={+this.props.dangerLevel}
          needleColor="black"
          startColor="green"
          segments={4}
          endColor="red"
        />
      </div>
    );
  }
}

export default DangerLevel;
