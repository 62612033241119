import { toast } from "react-toastify";

export const winNotification = (attempts, numSteps, resetGame) => {
  toast.success(
    `CONGRATULATIONS! You win after ${attempts} attempts! This attempt, you took ${numSteps} steps. Click the NEXT button to continue.`,
    {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClick: () => resetGame(),
    }
  );
};
