import React from "react";
import { Draggable } from "react-beautiful-dnd";

const Definition = () => {
  return (
    <Draggable draggableId={`234`} index={0}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className="styled-word-drag-drop"
        >
          definition
        </div>
      )}
    </Draggable>
  );
};

export default Definition;
