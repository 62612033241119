import React, { Component } from "react";
import "./AssetChoices.css";
import Asset from "./Asset/Asset";
import TradeModal from "./TradeModal/TradeModal";

class InvestingChoices extends Component {
  state = {
    currentTicker: null,
    currentPrice: null,
    currentAsset: null,
    currentRange: null,
    tradingModalVisible: false,
    extraStocksTracker: [],
  };

  setTradeValues = (name, ticker, price, range) => {
    this.setState({
      currentTicker: ticker,
      currentPrice: price,
      currentAsset: name,
      currentRange: range,
      tradingModalVisible: true,
    });
  };

  _closeModal = () => {
    this.setState({
      tradingModalVisible: false,
    });
  };

  render() {
    const {
      currentPeriod,
      transactionInProgress,
      _buyAsset,
      portfolio,
      stocksData,
      currentDate,
    } = this.props;
    let assets;
    let stocksCategories = [
      "tech",
      "banks",
      "bonds",
      "telecom",
      "retail",
      "indexes",
      "cars",
      "commodities",
    ];

    let categories;

    if (stocksData) {
      categories = stocksCategories.map((category, i) => {
        let foundStocksWithCategory = stocksData.filter(
          (stock) => stock.category === category
        );

        return (
          <div className="category-container">
            <h4 className="category-container-name">{category}</h4>
            <div className="category-container-assets">
              {foundStocksWithCategory &&
                foundStocksWithCategory.map((asset, i) => {
                  if (
                    asset.prices[currentPeriod] &&
                    asset.dates[currentPeriod] == currentDate
                  ) {
                    return (
                      <Asset
                        key={i}
                        name={asset.name}
                        ticker={asset.ticker}
                        description={asset.description}
                        price={asset.prices[currentPeriod]}
                        prevPrice={asset.prices[currentPeriod - 1]}
                        range={i}
                        transactionInProgress={transactionInProgress}
                        setTradeValues={this.setTradeValues}
                        portfolio={
                          this.props.portfolio.length > 0 ? true : false
                        }
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
        );
      });
    }

    if (this.props.stocksData) {
      assets = stocksData.map((asset, i) => {
        if (
          asset.prices[currentPeriod] &&
          asset.dates[currentPeriod] == currentDate
        ) {
          return (
            <Asset
              key={i}
              name={asset.name}
              ticker={asset.ticker}
              description={asset.description}
              price={asset.prices[currentPeriod]}
              prevPrice={asset.prices[currentPeriod - 1]}
              range={i}
              transactionInProgress={transactionInProgress}
              setTradeValues={this.setTradeValues}
              portfolio={this.props.portfolio.length > 0 ? true : false}
            />
          );
        } else {
          return null;
        }
      });
    }

    return (
      <div className="assets-container">
        <TradeModal
          tradingModalVisible={this.state.tradingModalVisible}
          name={this.state.currentAsset}
          ticker={this.state.currentTicker}
          price={this.state.currentPrice}
          portfolio={portfolio}
          _buyAsset={_buyAsset}
          _closeModal={this._closeModal}
          _buyAssetModal={this.props._buyAssetModal}
          _sellAssetModal={this.props._sellAssetModal}
          cash={this.props.cashValue}
        />
        {/* {assets} */}
        {categories}
      </div>
    );
  }
}

export default InvestingChoices;
