import React from "react";
import "./index.css";

const ProgramClosedCard = () => {
  return (
    <div className="programclosed__container">
      <div className="programclosed__title">This program has finished.</div>
      <div className="programclosed__subtitle">
        Please reach out to us at jessica@stoprecidivism.org if you'd like to
        reopen it or to create a new classroom.
      </div>
    </div>
  );
};

export default ProgramClosedCard;
