import React from "react";
import { withFirebase } from "../Firebase";
import "./index.css";

const SignOutButton = ({ firebase }) => (
  <button type="button" className="signout-button" onClick={firebase.signOut}>
    Sign Out
  </button>
);

export default withFirebase(SignOutButton);
