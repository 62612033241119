import React from "react";
import Icon from "src/components/shared/Icon";

export const AnsweredQuiz = ({ options, question, itemAnswer }) => {
  // const [modalVisible, triggerModal] = useState(true);
  return (
    <div className="quiz-container">
      <h5 className="quiz-question">{question}</h5>
      {options.map((option, index) => {
        if (option !== "") {
          return (
            <div key={index} className="quizoption__container">
              <span className="quizoptionicon__container">
                {option === itemAnswer ? (
                  <Icon name="quizoption-success" width={24} height={24} />
                ) : (
                  <Icon name="quizoption-error" width={24} height={24} />
                )}
              </span>
              {option}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
