import React from "react";

function ChatSearch({ handleSearch }) {
  return (
    <div className="chatsearch__container">
      <img
        className="chatclosedicon__container"
        alt=""
        src="/images/search-icon.png"
      />
      <input placeholder="Search..." onChange={handleSearch} />
    </div>
  );
}

export default ChatSearch;
