import React, { useState } from "react";
import "./index.css"; // Import the CSS file
import { withFirebase } from "src/components/Firebase";
import Button from "src/components/shared/Button";
import { toast } from "react-toastify";

const UploadDocuments = ({ firebase, authUser }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loadingData, setLoadingData] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setImageUrl(URL.createObjectURL(file));
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setImageUrl("");
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setMessage("Please select a file to upload.");
      return;
    }

    setLoadingData(true);

    await firebase.uploadId(authUser.uid).put(selectedFile);
    await firebase.user(authUser.uid).update({ uploadedId: true });
    toast.success("File uploaded successfully!");

    setLoadingData(false);
    setSelectedFile(null); // Clear the file input
  };

  if (authUser?.uploadedId) {
    return null;
  }

  return (
    <div>
      <div className="upload__title">Upload Important Documents</div>
      <div className="upload_documents">
        <div className="file_input_wrapper">
          {!selectedFile && (
            <div>
              <input
                type="file"
                onChange={handleFileChange}
                id="file-input"
                className="file__input"
              />
              <label htmlFor="file-input" className="upload_button">
                Upload State Issued ID
              </label>
              {selectedFile && (
                <span className="file_name">{selectedFile.name}</span>
              )}
            </div>
          )}

          <img
            src={selectedFile ? imageUrl : ""}
            alt=""
            className="file__preview"
          />
        </div>
        {selectedFile && (
          <Button
            onClick={handleUpload}
            type={loadingData ? "disabled" : "primary"}
            title={loadingData ? "Uploading..." : "Upload"}
          />
        )}
        {imageUrl && (
          <button onClick={handleRemoveImage} className="remove_file">
            X
          </button>
        )}
        {message && <p className="message">{message}</p>}
        <p className="hint-text">Please upload your State Issued ID here</p>
      </div>
    </div>
  );
};

export default withFirebase(UploadDocuments);
