import React, { useEffect } from "react";
import "./index.css";
import compose from "recompose/compose";

import { withRouter } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../Firebase";
import { useState } from "react";
import { generateAuthLink } from "src/api/google";
import { toggleSignupModal } from "../../store/authUser/authUserActions";
import ToggleSwitch from "../Landing/components/Mentor-StudentSwitch";
import { setLoginType } from "src/store/loginType/loginTypeActions";
import { Spin } from "antd";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

const data = [
  {
    title: "Record Restriction System",
    description: "After the program, your record is clean. ",
  },
  {
    title: "Life Skills",
    description:
      "You only learn by doing, so get ready to go through some simulations. ",
  },
  {
    title: "Real Outcomes",
    description: "Our students get jobs follow their dreams afterwards. ",
  },
];

export const LoginModal = ({ isOpen, fromDiversion, firebase, classId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // http://localhost:3000/diversion?classid=Diversion

  // get query param classId from url
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const classIdFromUrl = params.get("classid");
  console.log("classIdFromUrl", classIdFromUrl);

  const { loginType } = useSelector((state) => state.loginType);
  const [error, setError] = useState(null);
  const [classNames, setClassNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [classesLoading, setClassesLoading] = useState(false);

  useEffect(() => {
    getClassesData();
  }, []);

  const getClassesData = async () => {
    setClassesLoading(true);
    await firebase
      .classrooms()
      .orderByChild("isDiversion")
      .equalTo(true)
      .once("value", (snap) => {
        const res = snap.val();
        if (res) {
          let classNames = Object.keys(res).map((key) => res[key].className);
          setClassNames(classNames);
          setClassesLoading(false);
        }
      });
  };

  const generateLoginLink = async (classIdFromUrl) => {
    console.log("classIdFromUrl", classIdFromUrl);
    console.log("fromDiversion", fromDiversion);
    if (
      fromDiversion &&
      !localStorage.getItem("courtName") &&
      !classIdFromUrl
    ) {
      toast.error("Please select an option");
      return;
    }
    const classId = classIdFromUrl || localStorage.getItem("courtName");
    setLoading(true);
    try {
      const link = await generateAuthLink(loginType, classId);
      window.location.replace(link.authLink);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);
      toast.error("Error with Login", error.message);
    }
  };

  const onSelectCourt = (e) => {
    localStorage.setItem("courtName", e.target.value);
  };

  //const handleMentorLogin = (link) => {
  //};

  useEffect(() => {
    // used to disable the scrolling on opening the signup modal
    const element = document.querySelector("body");
    element.classList.add("stop-scroll");
    return () => {
      const element = document.querySelector("body");
      element.classList.remove("stop-scroll");
    };
  }, [isOpen]);

  return (
    <>
      <div className="modal__mask" />
      <div
        className="modal__container"
        style={{ top: fromDiversion ? "60px" : "20%" }}
      >
        <div
          className="modal__container__left"
          style={{ background: loginType === "mentor" ? "#FB7C23" : "#D94645" }}
        >
          <div className="text__container__left">
            <div className="text__container__left__title">
              Online Restorative Justice
            </div>
            <div className="listItems__container">
              {data.map(({ title, description }, index) => {
                return (
                  <InfoItem
                    key={index}
                    title={title}
                    description={description}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div
          className="close__icon__button"
          onClick={() => dispatch(toggleSignupModal(false))}
        >
          <img src={"/assets/images/close_icon.svg"} alt="google icon" />
        </div>
        <div className="modal__container__right">
          <div className="modal__text__container__right">
            <div className="text__container__right">
              {!fromDiversion && (
                <ToggleSwitch
                  userType={loginType}
                  toggleUser={(type) => dispatch(setLoginType(type))}
                />
              )}
              <div className="text__container__right__title">
                Sign in to RED Ed <span className="tm__container">®️</span>
              </div>
              <div className="text__container__right__subtitle">
                Get an account and start now!
              </div>
              {fromDiversion && (
                <div style={{ width: "100%", margin: 10, marginTop: 0 }}>
                  <div className="select-ant-container">
                    {classIdFromUrl ? (
                      <div className="classId__container">{classIdFromUrl}</div>
                    ) : (
                      <>
                        {classesLoading ? (
                          <Spin />
                        ) : (
                          <select
                            required
                            onChange={(e) => onSelectCourt(e)}
                            placeholder={"e.g Florida Court"}
                            className="lsi__selectoptioncontainer"
                            style={{
                              borderRadius: 0,
                              paddingLeft: 10,
                              borderColor: "#D9D9D9",
                            }}
                          >
                            <option
                              disabled
                              selected
                              value
                              className="lsioption"
                            >
                              Select your classroom{" "}
                            </option>
                            {classNames.map((option, index) => (
                              <option
                                className="lsioption"
                                key={index}
                                value={option}
                              >
                                {option}
                              </option>
                            ))}
                          </select>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="button__container__right">
              <button
                className="button__container__google__button"
                style={{
                  background: loginType === "mentor" ? "#FB7C23" : "#D94645",
                }}
                onClick={() => generateLoginLink(classIdFromUrl)}
              >
                <div className="google__button__inner">
                  <img
                    src={"/assets/images/google_icon.svg"}
                    alt="google icon"
                  />
                  <div className="google__button__inner__text">
                    {loading ? "Loading..." : "Sign in with Google"}
                  </div>
                </div>
              </button>
              <div className="button__container__warning__sign">
                <img
                  src={"/assets/images/information_icon.svg"}
                  alt="information_icon"
                />
                <div className="button__container__warning__sign__inner">
                  Warning! Google account ONLY
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const InfoItem = ({ title, description }) => {
  return (
    <div className="listItem">
      <div className="listItem__textGroup">
        <span className="listItem__check__text">{title}</span>
        <p className="listItem__text__description">{description}</p>
      </div>
    </div>
  );
};

export default compose(withFirebase, withRouter)(LoginModal);
