import React, { useEffect, useState } from "react";
import Situation from "./components/Situation";
import SwitchItemOrderButtons from "../shared/SwitchItemOrderButtons";
import "./index.css";

const ChatGame = ({
  editSection,
  item,
  removeContentAtIndex,
  index,
  _addSituation,
  _removeSituation,
  _addActionResponseDanger,
  _removeActionResponseDanger,
  _moveSectionContentDown,
  _moveSectionContentUp,
}) => {
  const handleEditChatGameSituation = (e, situationIndex) => {
    console.log(
      "ChatGame 12 | handling editing chat game",
      e.target.value,
      situationIndex
    );
    editSection(e, index, "chatGame", situationIndex);
  };

  const handleActionChange = (e, situationIndex, actionIndex) => {
    console.log(
      "ChatGame 17 | changing action",
      e.target.value,
      situationIndex,
      actionIndex
    );
    editSection(
      e,
      index,
      "chatGame",
      situationIndex,
      null,
      null,
      null,
      "action",
      actionIndex
    );
  };

  const handleResultChange = (e, situationIndex, resultIndex) => {
    editSection(
      e,
      index,
      "chatGame",
      situationIndex,
      null,
      null,
      null,
      "response",
      resultIndex
    );
  };

  const handleDangerChange = (e, situationIndex, resultIndex) => {
    editSection(
      e,
      index,
      "chatGame",
      situationIndex,
      null,
      null,
      null,
      "danger",
      resultIndex
    );
  };

  const addSituation = (index) => {
    _addSituation(index);
  };

  const removeSituation = (index) => {
    _removeSituation(index);
  };

  const addActionResponseDanger = (index, situationIndex) => {
    console.log("ChatGame 83 | adding action response danger");
    _addActionResponseDanger(index, situationIndex);
  };

  const removeActionResponseDanger = (index, situationIndex) => {
    if (item.situations[situationIndex].actions.length === 1) {
      return;
    } else {
      _removeActionResponseDanger(index, situationIndex);
    }
  };

  return (
    <div className="chatGame-container">
      <h3>Chat Game Edit</h3>

      {item &&
        item.situations &&
        item.situations.map((situation, situationIndex) => {
          return (
            <div key={situationIndex} className="edit-situation-container">
              <h5>
                <strong>Situation Part {situationIndex + 1}</strong>
              </h5>
              <input
                className="edit-situation-text"
                onChange={(e) => handleEditChatGameSituation(e, situationIndex)}
                value={situation.situation}
              />

              <div className="action-response-danger-container"></div>
              {situation &&
                situation.actions &&
                situation.actions.map((action, actionIndex) => {
                  return (
                    <div key={actionIndex} className="action-response-danger">
                      <p>Action</p>
                      <input
                        onChange={(e) =>
                          handleActionChange(e, situationIndex, actionIndex)
                        }
                        placeholder="Action"
                        value={action.action}
                      />
                      <p>Response</p>
                      <input
                        onChange={(e) =>
                          handleResultChange(e, situationIndex, actionIndex)
                        }
                        placeholder="Response"
                        value={action.response}
                      />
                      <p>Danger</p>
                      <input
                        className="edit-danger"
                        onChange={(e) =>
                          handleDangerChange(e, situationIndex, actionIndex)
                        }
                        placeholder="Danger"
                        value={action.danger}
                      />
                    </div>
                  );
                })}
              <button
                onClick={() => addActionResponseDanger(index, situationIndex)}
                type="button"
              >
                Add Action/Response
              </button>
              <button
                onClick={() =>
                  removeActionResponseDanger(index, situationIndex)
                }
                type="button"
              >
                Remove Action / Response
              </button>
              <br />
              <hr />
            </div>
          );
        })}

      <button type="button" onClick={() => addSituation(index)}>
        Add Situation
      </button>
      <button type="button" onClick={() => removeSituation(index)}>
        Remove Last Situation
      </button>

      <button
        className="btn btn-danger"
        onClick={(e) => removeContentAtIndex(e, index)}
      >
        X
      </button>
      <SwitchItemOrderButtons
        index={index}
        _moveSectionContentDown={_moveSectionContentDown}
        _moveSectionContentUp={_moveSectionContentUp}
      />
    </div>
  );
};

export default ChatGame;
