// gameLogic.js
import { useState } from "react";
import { emergencies, initialState } from "./gameData";

const DECISIONS_PER_MONTH = 10;
const TIME_DELAY_MS = 1500;
const MAX_EMERGENCIES = 3;

const useGameLogic = () => {
  const [state, setState] = useState(initialState);

  const selectJob = (job) => setState({ ...state, job, balance: job.salary });

  const selectExpense = (expense, category) => {
    const stateCopy = {
      ...state,
      expenses: {
        ...state.expenses,
        [category]: {
          ...state.expenses[category],
          cost: expense.cost,
          name: expense.name,
        },
      },
      fixedExpenses: {
        ...state.fixedExpenses,
        [category]: expense.cost,
      },
    };

    if (category === "insurance") {
      stateCopy.insuranceType = expense.name;
    }

    setState(stateCopy);
  };

  const submitExpenses = () => {
    // Calculate the new balance by subtracting expenses from the current balance
    const newBalance =
      state.balance -
      Object.values(state.expenses).reduce((total, expense) => {
        return total + (expense.cost || 0); // Use 0 if the expense is null
      }, 0);

    const date = calculateDate(state.level, 0);

    setState((prev) => ({
      ...prev,
      gameState: "budgetAdjustment",
      balance: newBalance,
      currentDate: date,
    }));
  };

  const totalFixedExpenses = () => {
    return Object.values(state.fixedExpenses).reduce((total, expense) => {
      return total + (expense || 0); // Use 0 if the expense is null
    }, 0);
  };

  const adjustMonthToSaveChoice = (type) => {
    setState((prev) => {
      return {
        ...prev,
        saveMonthToCheckingOrSavings: type,
      };
    });
  };

  const adjustBudget = (category, amount) =>
    setState({ ...state, budget: { ...state.budget, [category]: amount } });

  const checkForEmergency = (insuranceType) => {
    if (state.emergencyCount >= MAX_EMERGENCIES) return null; // Maximum 3 emergencies allowed

    const availableEmergencies = emergencies.filter(
      (emergency) => !state.emergenciesLog.includes(emergency.id)
    );

    if (availableEmergencies.length === 0) return null;

    const randomIndex = Math.floor(Math.random() * availableEmergencies.length);
    const chosenEmergency = availableEmergencies[randomIndex];

    return {
      ...chosenEmergency,
      deductible: chosenEmergency.deductibles[insuranceType],
    };
  };

  const updateBudget = (category, newBudgetAmount) => {
    setState((prev) => ({
      ...prev,
      budget: { ...prev.budget, [category]: newBudgetAmount },
    }));
  };

  const makeDecision = (decision, decisionIndex) => {
    const {
      emergencyThisMonth,
      insuranceType,
      happiness,
      expenses,
      decisionsMade,
    } = state;

    const checkForEmergencyIfNeeded = () => {
      if (!emergencyThisMonth && Math.random() < 0.1) {
        return checkForEmergency(insuranceType);
      }
      return null;
    };

    const emergencyOccurred = checkForEmergencyIfNeeded();
    const currentCategory = emergencyOccurred ? "insurance" : decision.category;

    const newDecision = {
      ...decision,
      text: `${decision.text}${emergencyOccurred ? ` (${emergencyOccurred.description})` : ""
        }`,
      category: currentCategory,
    };

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        currentDate: date,
        emergency: emergencyOccurred,
        emergencyThisMonth: !!emergencyOccurred,
        emergencyCount: emergencyOccurred
          ? prev.emergencyCount + 1
          : prev.emergencyCount,
        emergenciesLog: emergencyOccurred
          ? [...prev.emergenciesLog, emergencyOccurred.id]
          : prev.emergenciesLog,
      }));
    }, TIME_DELAY_MS);

    // limit happiness to 0
    const newHappiness = Math.max(0, happiness + decision.happiness);

    const date = calculateDate(state.level, decisionIndex);

    const calculateNewSavings = (prev) => {
      const newSavings = prev.savings - decision.cost;

      if (emergencyThisMonth) {
        if (newSavings < 0) {
          return 0;
        }
        return prev.savings - decision.cost;
      } else {
        return prev.savings;
      }
    };

    const calculateNewBalance = (prev) => {
      const newSavings = prev.savings - decision.cost;
      if (emergencyThisMonth) {
        if (newSavings < 0) {
          return prev.balance - decision.cost;
        } else {
          return prev.balance;
        }
      } else {
        return prev.balance - newDecision.cost;
      }
    };

    setState((prev) => ({
      ...prev,
      balance: calculateNewBalance(prev),
      happiness: newHappiness,
      savings: calculateNewSavings(prev),
      expenses: {
        ...expenses,
        [currentCategory]: {
          ...expenses[currentCategory],
          cost: expenses[currentCategory].cost + decision.cost,
        },
      },
      decisionsMade: decisionsMade + 1,
      decisionsLog: [...prev.decisionsLog, newDecision],
    }));

    if (decisionsMade >= DECISIONS_PER_MONTH) {
      endMonth();
    }
  };

  const endMonth = () => {
    if (state.balance <= 0 || state.happiness < 15 || state.currentMonth >= 3) {
      setState((prev) => ({ ...prev, gameState: "gameResult" }));
    } else {
      const updatedBudgetHistory = [
        ...state.budgetHistory,
        {
          month: state.currentMonth,
          budget: { ...state.budget },
          expenses: { ...state.expenses },
        },
      ];

      setState((prev) => ({
        ...prev,
        decisionsMade: 0,
        currentMonth: prev.currentMonth + 1,
        gameState: "budgetAdjustment",
        emergencyThisMonth: false,
        budgetHistory: updatedBudgetHistory,
      }));
    }
  };

  const nextMonth = () => {
    console.log("gameLogic.js 188 | going to next month");
    setState((prev) => ({
      ...prev,
      gameState: "monthSimulation",
      // balance: prev.balance + state.job.salary,
      expenses: {
        housing: { name: null, cost: 0, color: "#F27117" },
        transportation: { name: null, cost: 0, color: "#2258E3" },
        insurance: { name: null, cost: 0, color: "#0DB487" },
        food: { name: null, cost: 0, color: "#FF6250" },
      },
    }));
  };

  const budgetReady = () => {
    const { balance, saveMonthToCheckingOrSavings, currentMonth } = state;
    const monthChoiceIsCheckings = saveMonthToCheckingOrSavings === "checkings";

    const calculateNewCheckings = (prev) =>
      currentMonth === 1
        ? state.balance
        : monthChoiceIsCheckings
          ? state.job.salary + balance - totalFixedExpenses()
          : state.job.salary - totalFixedExpenses();

    const calculateNewSavings = (prev) =>
      currentMonth === 1
        ? 0
        : !monthChoiceIsCheckings
          ? prev.savings + state.balance
          : prev.savings;

    const expensesCopy = { ...state.expenses };

    Object.entries(state.fixedExpenses).forEach((fixedExpense) => {
      expensesCopy[fixedExpense[0]].cost = fixedExpense[1];
    });

    setState((prev) => ({
      ...prev,
      gameState: "monthSimulation",
      balance: calculateNewCheckings(prev),
      savings: calculateNewSavings(prev),
      expenses: expensesCopy,
    }));
  };

  const resetGame = () => {
    console.log('llegando', initialState)
    setState({
      ...initialState,
    });
  };

  const setCurrentLevel = (currentLevel) => {
    setState({ ...state, level: currentLevel });
  };

  const getInitialLevel = async (firebase, uid) => {
    try {
      const userRef = firebase.user(uid);
      const snapshot = await userRef.once("value");
      const userData = snapshot.val();
      return userData && userData?.snowball?.budgetingLevel
        ? userData?.snowball?.budgetingLevel
        : 1;
    } catch (error) {
      console.error("Error fetching user level from Firebase:", error);
      return 1; // Default to level 1 if there's an error fetching data
    }
  };

  return {
    state,
    selectJob,
    selectExpense,
    submitExpenses,
    adjustBudget,
    budgetReady,
    makeDecision,
    endMonth,
    resetGame,
    nextMonth,
    updateBudget,
    adjustMonthToSaveChoice,
    setCurrentLevel,
  };
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "January",
  "February",
  "March",
  "April",
  "May",
];

const calculateDate = (level, currentDecisionIndex) => {
  const monthRange = 4; // 4 months in each quarter
  const dayStart = Math.max(1 + (currentDecisionIndex - 1) * 3, 1);

  // Determine the quarter based on level
  const month = months[(level - 1) * monthRange];

  return `${month} ${dayStart}, 2023`;
};

// const currentDate = calculateDate(level, currentDecisionIndex);

export default useGameLogic;
