import { getToken } from "src/api/token";

export const _lookForSheet = async (title, _startSetup) => {
  // console.log("Does Sheet Exist?")
  const token = await getToken();
  const drive = `https://www.googleapis.com/drive/v3/files?q=name='${title}'&pageSize=1`;

  return fetch(drive, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((info) => {
      if (info.files.length === 0) {
        // No file, create file with that name
        // Creates new sheet
        _createSheet(title, _startSetup);
      } else {
        // Sheet in Drive, add it to session storage
        localStorage.setItem(
          "investingFileId",
          JSON.stringify(info.files[0].id)
        );
        _startSetup(info.files[0].id);
      }
    })
    .catch((err) => {
      return err;
    });
};

export const _createSheet = async (title, _startSetup) => {
  const token = await getToken();
  const sheets = "https://sheets.googleapis.com/v4/spreadsheets/";

  fetch(sheets, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      properties: {
        title: title,
      },
      sheets: [
        {
          properties: {
            sheetId: 0,
            gridProperties: {
              columnCount: 100,
              // rowCount: 50,
              hideGridlines: true,
            },
          },
        },
      ],
    }),
  })
    .then((response) => response.json())
    .then((info) => {
      // saves sheet ID in state
      console.log("Created spreadsheet", info);
      _startSetup(info.spreadsheetId);
    })
    .catch((err) => console.log(err));
};

// This is working
export const _checkSingleCell = async (token, id, range) => {
  console.log("Check single cell");
  const request = await fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/${range}?valueRenderOption=FORMULA`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = request.json();
  return data;
};

export const _updateValuesRange = async (token, sheetId, range, values) => {
  const request = await fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values:batchUpdate`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        valueInputOption: "RAW",
        data: [
          {
            range: range, // String 'A1:B3'
            majorDimension: "ROWS",
            values: [
              values, //Array of values: [ "Ticker",  "Price", "Number of shares" ],
            ],
          },
        ],
      }),
    }
  );
  const data = await request.json();
  return data;
};

// updates current prices
export const _updateColumnRange = async (token, sheetId, range, values) => {
  console.log("Col range values", values);
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values:batchUpdate`;
  let body = {
    valueInputOption: "RAW",
    data: {
      range: range,
      majorDimension: "COLUMNS",
      values: [
        values, //must be column array [100, 200, '', 20]
      ],
    },
  };

  const request = await fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  const data = await request.json();
  return data;
};

export const _updateFrozenRow = async (token, sheetId) => {
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values:batchUpdate`;
  let body = {
    updateSheetProperties: {
      properties: {
        gridProperties: {
          frozenColumnCount: 4,
        },
        fields: "*",
      },
    },
  };

  const request = await fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  const data = await request.json();
  return data;
};

export const _formatValue = async (token, sheetId, range) => {
  console.log("Format range", token);
  let myRange = characterToRangeConverter(range); //converts A1:B2 into what this parameter needs
  const repeatCell = {
    range: myRange,
    cell: {
      userEnteredFormat: {
        horizontalAlignment: "CENTER",
        numberFormat: {
          type: "CURRENCY",
        },
        backgroundColor: {
          red: 0,
          green: 0,
          blue: 0,
        },
        textFormat: {
          foregroundColor: {
            blue: 0,
            red: 0,
            green: 0,
          },
          bold: true,
        },
      },
    },
    fields: "*",
  };

  try {
    await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}:batchUpdate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          requests: [
            {
              repeatCell,
            },
          ],
        }),
      }
    );
  } catch (error) {
    console.log("sheetsFunctions.js.js 207 | error updating format", error);
  }
};

export const _formatWrongRange = async (token, sheetId, range) => {
  let myRange = characterToRangeConverter(range);
  console.log("sheetsFunctions.js.js 214 | updating range", myRange);
  const body = {
    requests: [
      {
        repeatCell: {
          range: myRange,
          cell: {
            userEnteredFormat: {
              backgroundColor: {
                red: 1,
                green: 0.0,
                blue: 0.0,
              },
              horizontalAlignment: "CENTER",
              textFormat: {
                // foregroundColor: {
                //   red: 1.0,
                //   green: 0,
                //   blue: 0
                // },
                // fontSize: 15,
                // bold: true
              },
            },
          },
          fields:
            "userEnteredFormat(backgroundColor,textFormat,horizontalAlignment)",
        },
      },
    ],
  };

  try {
    await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}:batchUpdate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    );
  } catch (error) {
    console.log("sheetsFunctions.js.js 263 | error updating wrong field color");
  }
};

export const _formatRange = async (token, sheetId, range) => {
  let myRange = characterToRangeConverter(range);
  console.log("sheetsFunctions.js.js 214 | updating range", myRange);
  const body = {
    requests: [
      {
        repeatCell: {
          range: myRange,
          cell: {
            userEnteredFormat: {
              backgroundColor: {
                red: 1,
                green: 1,
                blue: 1,
              },
              horizontalAlignment: "CENTER",
              textFormat: {
                foregroundColor: {
                  red: 0,
                  green: 0,
                  blue: 0,
                },
              },
            },
          },
          fields:
            "userEnteredFormat(backgroundColor,textFormat,horizontalAlignment)",
        },
      },
    ],
  };

  try {
    await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}:batchUpdate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    );
  } catch (error) {
    console.log("sheetsFunctions.js.js 263 | error updating wrong field color");
  }
};

// updates tutorial values
export const _batchUpdateSingleValue = async (token, sheetId, range, value) => {
  const currentRange = characterToRangeConverter(range);
  fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}:batchUpdate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        requests: [
          {
            updateCells: {
              range: currentRange,
              fields: "*",
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        formulaValue: value,
                      },
                      userEnteredFormat: {
                        textFormat: {
                          foregroundColor: {
                            blue: 0,
                            red: 0,
                            green: 0,
                          },
                          bold: true,
                        },
                        horizontalAlignment: "CENTER",
                        numberFormat: {
                          type: "CURRENCY",
                        },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      }),
    }
  );
};

export const _clearSheetValues = async (token, sheetId, range) => {
  console.log("Clear sheet values");
  const request = await fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values:batchClear`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ranges: [range],
      }),
    }
  );

  const data = await request.json();
  return data;
};

// Takes a string range 'A1' or 'A1:B2' returns a number range object
export const characterToRangeConverter = (range) => {
  console.log("range", range);
  // console.log("Converting single range");
  let letter = range[0];
  let letterToNumber = letter.charCodeAt(0) - 65; //gives 0 for A, 1 for B
  let number = parseInt(range[1]);

  if (range.length < 5) {
    //checking for single range e.g A3

    let checkForSplit = range.split(":");
    // console.log("checkForSplit", checkForSplit);
    if (checkForSplit[0].length == 3) {
      // A11
      let firstLetter = checkForSplit[0][0];
      letterToNumber = firstLetter.charCodeAt(0) - 65;

      number = parseInt(checkForSplit[0].slice(1));
    }

    return {
      //number range object returned for google sheet
      startColumnIndex: letterToNumber,
      endColumnIndex: letterToNumber + 1,
      startRowIndex: number - 1,
      endRowIndex: number,
      sheetId: 0,
    };
  } else {
    //checking for multiple range e.g A1:B11
    // console.log("More than 3!", range);

    let splitRange = range.split(":");
    console.log(splitRange);

    let firstLetter = splitRange[0][0].toUpperCase();
    let colStart = firstLetter.charCodeAt(0) - 65;

    let secondLetter = splitRange[1][0].toUpperCase(); //gets the second first letter
    let colEnd = secondLetter.charCodeAt(0) - 65; //gives 0 for A, 1 for B

    let rowStart = parseInt(splitRange[0].slice(1));

    let rowEnd = parseInt(splitRange[1].slice(1));

    let secondNumber = range[4];

    return {
      startColumnIndex: colStart,
      endColumnIndex: colEnd,
      startRowIndex: rowStart,
      endRowIndex: rowEnd,
      sheetId: 0,
    };
  }
};
