import React from "react";
import { NavSectionAdminOptions } from "./NavSectionAdminOptions";
import NavSectionIconActions from "./NavSectionIconActions";
import { NavSectionItemTitle } from "./NavSectionItemTitle";

export const NavSectionItem = (
  {
    name,
    currentSectionName,
    toggleEdit,
    toggleView,
    userMode,
    remove,
    uid,
    number,
    section,
    addBelow,
    newSectionAnimationIndex,
    provided,
  },
  index
) => {
  const animate = number === newSectionAnimationIndex && "animate-nav-item";

  return (
    <div
      key={index}
      className={`section-nav ${
        userMode === "ADMIN" ? "editing" : null
      } ${animate}`}
    >
      <NavSectionItemTitle
        name={name}
        currentSectionName={currentSectionName}
        toggleView={toggleView}
        number={number}
        section={section}
      />
      {/* Add logic for seeing navbar admin options if user */}
      {/* <NavSectionAdminOptions
        isAdmin={userMode === "ADMIN"}
        number={number}
        section={section}
        toggleEdit={toggleEdit}
        remove={remove}
        switchUp={switchUp}
        switchDown={switchDown}
        uid={uid}
        addBelow={addBelow}
      /> */}
      <NavSectionIconActions
        userMode={userMode}
        toggleEdit={toggleEdit}
        number={number}
        section={section}
        addBelow={addBelow}
        uid={uid}
        confirmDelete={() => remove(uid)}
        provided={provided}
      />
    </div>
  );
};
