import React from "react";
import "./Table.css";

const Table = (props) => {
  const { income, food, transportation, health, housing } = props;

  const currentIncome = income === undefined ? 0 : income;
  const currentFood = food === undefined ? 0 : food;
  const currentTransportation =
    transportation === undefined ? 0 : transportation;
  const currentHealth = health === undefined ? 0 : health;
  const currentHousing = housing === undefined ? 0 : housing;

  return (
    <div className="my-table">
      <h5>Monthly Fixed Expenses</h5>
      <tbody>
        <table>
          <tr>
            <th>Income</th>
          </tr>
          <tr>
            <td>Intern at Startup</td>
            <td>$ {income || 0}</td>
          </tr>
          <tr>
            <th>Expenses</th>
          </tr>
          <tr>
            <td>Housing</td>
            <td>$ {housing || 0}</td>
          </tr>
          <tr>
            <td>Health Insurance</td>
            <td>$ {health || 0}</td>
          </tr>
          <tr>
            <td>Food</td>
            <td>$ {food || 0}</td>
          </tr>
          <tr>
            <td>Transportation</td>
            <td>$ {0 || transportation}</td>
          </tr>
          <tr>
            <th>Left to spend</th>
          </tr>
          <tr>
            <td>Total</td>
            <td>
              ${" "}
              <strong>
                {currentIncome -
                  currentHealth -
                  currentTransportation -
                  currentHousing -
                  currentFood}
              </strong>
            </td>
          </tr>
        </table>
      </tbody>
    </div>
  );
};

export default Table;
