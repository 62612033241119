import React from "react";
import "./index.css";

const AddSectionForm = ({ addSection, onChange, type }) => {
  return (
    <form className="add-section-form" onSubmit={addSection}>
      <label>
        Section Name
        <input name="name" onChange={onChange} />
      </label>
      <button className="btn btn-primary">Add</button>
    </form>
  );
};

export default AddSectionForm;
