import React from "react";
import "./DebtGame.css";
import { AntModal } from "../AntModal/AntModal";

class DebtGame extends React.Component {
  componentDidMount() {
    console.log("DebtGame.js 7 | state", this.props.currentDebt);
  }

  render() {
    const start = () => {
      this.props._selectDebts();
    };

    const notEnoughMinPayments = !this.props.enoughMinPaymentVisible ? null : (
      <div className="not-enough-paid-warning">
        You're not paying{" "}
        <strong>the minimum on {this.props.currentMonth}.</strong> Edit the
        amount in the spreadsheet.{" "}
      </div>
    );

    if (this.props.startButtonVisible) {
      console.log("start button visible", this.props.startButtonVisible);
      return (
        <AntModal
          functionAfterModalToggle={start}
          title="Welcome to the Debt Game!"
          footer={true}
        >
          <p>
            Understand how to <strong>pay off debt!</strong>{" "}
          </p>
          {/* <div>Your savings from the Budgeting game have been passed on.</div> */}
        </AntModal>
      );
    } else if (this.props.payButtonVisible) {
      return (
        <>
          <div className="pay-button">
            <p className="debt-pay-instructions">
              Pay the minimum payment <strong>OR MORE</strong>
            </p>
            <button
              className="debt-pay-button"
              onClick={() => this.props.pay()}
            >
              Pay
            </button>
          </div>
          {notEnoughMinPayments}
        </>
      );
    } else if (this.props.monthStartButtonVisible) {
      return (
        <div>
          <button onClick={this.props._addInterest}>Start Paying Back!</button>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default DebtGame;
