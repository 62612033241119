import React from "react";
import "./EmergencyFund.css";
import DoughnutChart from "../DoughnutChart/DoughnutChart";

const EmergencyFund = (props) => {
  // const amount = (props.emergencyFundSavings >= 0) ? `$${props.emergencyFundSavings}` : `-$${(-1 * props.emergencyFundSavings)}`;

  return (
    <div className="emergency-container">
      <DoughnutChart
        happyLevel={props.happyLevel}
        choiceButtonsVisible={props.choiceButtonsVisible}
      />
    </div>
  );
};

export default EmergencyFund;
