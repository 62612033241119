import React from "react";
import "../EIResultsContainer.css";
import { assessmentCategoryData } from "../../assessmentInfoData";
import { Competency } from "../Competency";
import Category from "../Category";
import ResultContainer from "../ResultContainer";
import PreTestInfo from "./PreTestInfo";

export const PreTestTab = ({ preTestResults }) => {
  console.log("PreTest 7 | pretest results", preTestResults);

  if (!preTestResults)
    return <div className="resultareacontainer1">Loading...</div>;

  return (
    <div>
      {Object.entries(preTestResults.results).map((category) => {
        return (
          <ResultContainer categoryName={category[0]} category={category} />
        );
      })}
      <PreTestInfo />
    </div>
  );
};

export default PreTestTab;
