import React from "react";
import { useCallback } from "react";
import "./SectionOverviewInfo.css";
const SectionOverviewInfo = () => {
  const onButtonContainerClick = useCallback(() => {
    window.open(
      "https://www.animaapp.com/?utm_source=figma-samples&utm_campaign=figma-lp-ui-kit&utm_medium=figma-samples"
    );
  }, []);

  const onButtonContainer1Click = useCallback(() => {
    window.open(
      "https://www.animaapp.com/?utm_source=figma-samples&utm_campaign=figma-lp-ui-kit&utm_medium=figma-samples"
    );
  }, []);

  const onButtonContainer2Click = useCallback(() => {
    window.open(
      "https://www.animaapp.com/?utm_source=figma-samples&utm_campaign=figma-lp-ui-kit&utm_medium=figma-samples"
    );
  }, []);

  return (
    <div className="features">
      <div className="headline-subhead">
        <div className="what-is-red">What is RED PTD?</div>
        <div className="red-ptd-is">
          RED PTD is an evidence-based cost effective alternative to traditional
          adjudication.
        </div>
      </div>
      <div className="cards-row">
        <div className="feature-card">
          <div className="card-info">
            <img className="icon2" alt="" src="/icon.svg" />
            <div className="text">
              <div className="prevent-crime">{`Prevent Crime `}</div>
              <div className="data-driven-approaches">
                Evidence based approaches combined with rehabilitative measures
                that reduces the propensity for criminogenic behaviors
              </div>
            </div>
          </div>
          <div className="button2" onClick={onButtonContainerClick}>
            <img
              className="arrowright-icon3"
              alt=""
              src="/ptd-images/arrowleft.svg"
            />
            <div className="label4">Learn More</div>
            <img
              className="arrowright-icon4"
              alt=""
              src="/ptd-images/arrowleft.svg"
            />
          </div>
        </div>
        <div className="feature-card1">
          <div className="card-info">
            <img className="icon2" alt="" src="/icon1.svg" />
            <div className="text">
              <div className="prevent-crime">{`Stop Recidivism `}</div>
              <div className="data-driven-approaches">
                The program offers a simplified path for case dismissal and
                record restriction, restoring an individual’s earning potential
                and their ability to contribute to society.
              </div>
            </div>
          </div>
          <div className="button2" onClick={onButtonContainer1Click}>
            <img
              className="arrowright-icon3"
              alt=""
              src="/ptd-images/arrowleft.svg"
            />
            <div className="label4">Learn More</div>
            <img
              className="arrowright-icon4"
              alt=""
              src="/ptd-images/arrowleft.svg"
            />
          </div>
        </div>
        <div className="feature-card">
          <div className="card-info">
            <img className="icon2" alt="" src="/icon2.svg" />
            <div className="text">
              <div className="prevent-crime">{`Streamlined Procedures `}</div>
              <div className="data-driven-approaches">
                The program's standardized design minimizes confusion and
                encourages utilization by providing a straightforward path for
                participants and court officials.
              </div>
            </div>
          </div>
          <div className="button2" onClick={onButtonContainer2Click}>
            <img
              className="arrowright-icon3"
              alt=""
              src="/ptd-images/arrowleft.svg"
            />
            <div className="label4">Learn More</div>
            <img
              className="arrowright-icon4"
              alt=""
              src="/ptd-images/arrowleft.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOverviewInfo;
