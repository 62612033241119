import React from "react";
import "./Asset.css";
import Buy from "./Trade/Buy";

// import Odometer from "react-odometerjs";
import { PopOver } from "../../../../../../../PopOver";

const Asset = (props) => {
  const {
    name,
    prevPrice,
    price,
    ticker,
    range,
    _buyAsset,
    transactionInProgress,
    description,
    portfolio,
  } = props;

  let change = price - prevPrice;
  // let plusOrMinus = change >= 0 ? <strong className="positive">+{change}</strong> : <strong className="negative">{change}</strong>;

  const setTradeValues = (name, ticker, price) => {
    // props.setTradeValues(name, price, ticker);
    console.log("Setting trade values", name, ticker, price);
    props.setTradeValues(name, ticker, price, range);
  };

  return (
    <div className="asset">
      <h5 className="asset-ticker">
        <strong>{ticker}</strong>
      </h5>

      <h5 className="asset-price">
        <strong>
          {/* $ <Odometer format="(.ddd).d" duration={5} value={price} /> */}${" "}
          {price}
        </strong>
      </h5>
      <p className="asset-description">
        <strong>
          {name} <PopOver title="Description" info={description} />
        </strong>
      </p>

      <h5 className="asset-price-change">
        <strong>{Math.floor(change)}</strong>
      </h5>
      {/* <hr /> */}
      <Buy
        _buyAsset={_buyAsset}
        name={name}
        price={price}
        ticker={ticker}
        range={range}
        transactionInProgress={transactionInProgress}
        setTradeValues={setTradeValues}
        portfolio={portfolio}
      />
    </div>
  );
};

export default Asset;
