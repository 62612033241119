import React from "react";
import { hexToRGBA } from "src/utils/utils";

export const BudgetMoneyIndicator = ({
  balance,
  incomePositive = true,
  income,
  title,
}) => {
  const color = incomePositive ? "#00BA88" : "#D94645";

  return (
    <div className="budgetmoneyindicator">
      <div className="budget-money-title">{title}</div>
      <div
        className="budget-money-indicator"
        style={{ backgroundColor: hexToRGBA(color, 0.25) }}
      >
        <div
          className="happiness"
          style={{
            width: `${balance >= 0 ? parseInt((balance / income) * 100) : "0"
              }%`,
            backgroundColor: color,
          }}
        />
        <div className="budget-money-type-parent">
          <div className="budget-money-type">$</div>
          <div className="budget-available-money">{balance}</div>
        </div>
      </div>
    </div>
  );
};

export const BudgetHappinessIndicator = ({ width, isHappy }) => {
  const color = isHappy ? "#00BA88" : "#D94645";

  return (
    <div className="budgethappinessindicator">
      <div className="budget-happiness-title">Happiness</div>
      <div
        className="budget-money-indicator1"
        style={{ backgroundColor: hexToRGBA(color, 0.25) }}
      >
        <div
          className="happiness"
          style={{
            width: `${parseInt((width / 30) * 100)}%`,
            backgroundColor: hexToRGBA(color, 1),
          }}
        />
        <div className="totalamount1">
          <div className="budget-sad-emoji-icon">{isHappy ? "😀" : "😟"}</div>
          <div className="budget-percent-score">{width}</div>
        </div>
      </div>
    </div>
  );
};
