import React from 'react';
import './LoadingModal.css';

const LoadingModal = (props) => { 
      
    if(props.loading){
        return (
            <div className='loading'>
                <div className="load-wrapp">
                    <div className="load-1">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default LoadingModal;