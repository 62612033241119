// AttendanceTable.js

import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { matchUserProperty, checkReminderConfirmed, checkStudentProgress } from '../eventManagerUtils';

const AttendanceTable = ({
  eventData,
  users,
  handleCellClick,
  attendanceMode,
  totalRemindersConfirmed,
  totalUsersAttendance
}) => {

  const greenCheck = (attendance) => (
    <img
      style={{ cursor: attendance && attendanceMode ? "pointer" : "initial" }}
      src={`/assets/images/green_check_icon.svg`}
      className="evaluation_icons_glosary"
      alt="green check icon"
    />
  );
  const redCross = (attendance) => (
    <img
      style={{ cursor: attendance && attendanceMode ? "pointer" : "initial" }}
      src={"/assets/images/red_cross_icon.svg"}
      className="evaluation_icons_glosary"
      alt="red cross icon"
    />
  );
  const mailIcon = (
    <img
      style={{ cursor: "pointer" }}
      src={"/assets/images/mail_icon.svg"}
      className="mail_icon"
      alt="mail icon"
    />
  );


  const renderBalls = (remindersObject) => {
    console.log("remindersObject render balls", remindersObject);
    let ballsArray = [];
    if (remindersObject) {
      Object.values(remindersObject).forEach((e) => {
        if (e.format === "sms") {
          ballsArray.push("orange__ball");
        } else if (e.format === "mail") {
          ballsArray.push("blue__ball");
        } else if (e.format === "other") {
          ballsArray.push("yellow__ball");
        } else if (e.format === "push") {
          ballsArray.push("red__ball");
        }
      });
      return ballsArray.map((e) => <div className={e} />);
    }
  };


  const realDataObj = eventData.attendeesList
    .filter((e) => matchUserProperty(users, e.uid, "displayName"))
    .map((e) => {
      let userConfirmedAttendance = checkReminderConfirmed(e.reminderConfirmed);
      userConfirmedAttendance && totalRemindersConfirmed++;
      e.attendance && totalUsersAttendance++;
      return {
        col1: matchUserProperty(users, e.uid, "username") || matchUserProperty(users, e.uid, "displayName"),
        col2: checkStudentProgress(e.uid, eventData, users),
        col3: userConfirmedAttendance ? greenCheck() : redCross(),
        col4: e.attendance ? greenCheck("attendance") : redCross("attendance"),
        col5: mailIcon,
        uid: e.uid,
        balls: renderBalls(e.reminderConfirmed),
      };
    });

  const data = useMemo(() => realDataObj, [realDataObj]);

  const columns = useMemo(
    () => [
      { Header: "Name", accessor: "col1", sortType: "basic", maxSize: 200 },
      { Header: "Progress", accessor: "col2", sortType: "basic" },
      { Header: "Confirmed RSVP", accessor: "col3", sortType: (a, b) => a.original.col3.props.src.includes("red") },
      { Header: "Attendance", accessor: "col4", sortType: "basic" },
      { Header: "Reminder", accessor: "col5", sortType: "basic" }
    ],
    []
  );

  const tableInstance = useTable({ columns, data, autoResetSortBy: false, autoResetPage: false }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div>
      <table {...getTableProps()} className="event__react__table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="react-table-headers" {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.Header !== "Reminder" && (
                    <span className="arrows_container_span">
                      <div className="arrows_container_div">
                        <img src={"/assets/images/icon_up.svg"} alt="icon up" />
                        <img src={"/assets/images/icon_down.svg"} alt="icon down" />
                      </div>
                    </span>
                  )}
                  {column.Header === "Confirmed Attendance" && (
                    <div style={{ marginLeft: 5 }}>
                      {totalRemindersConfirmed} / {users.length}
                    </div>
                  )}
                  {column.Header === "Attendance" && (
                    <div style={{ marginLeft: 5 }}>
                      {totalUsersAttendance} / {users.length}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td onClick={() => handleCellClick(cell)} {...cell.getCellProps()}>
                    <>
                      {cell.render("Cell")}
                      {cell.column.Header === "Name" && (
                        <div>{cell.row.original.balls}</div>
                      )}
                    </>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AttendanceTable;
