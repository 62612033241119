import React from "react";
import { Link } from "react-router-dom";
import Icon from "src/components/shared/Icon";
import "./index.css";

const LSICard = ({
  enabled = false,
  done = false,
  available = false,
  isMentor,
}) => {
  if (isMentor) return null;
  if (!available) return null;
  return (
    <div className="initialassessments__container">
      <div className="initialassessment__cardtop">
        <div className="initialassessment__topleftcontainer">
          <Icon name="time" width={15} height={15} />
          <p className="topleftcontainer__time">1h</p>
        </div>
        <div className="initialassessment__toprightcontainer">
          <Icon name="assessment-number" width={15} height={15} />
          <p className="toprightcontainer__number">2/3</p>
        </div>
      </div>
      <div className="initialassessment__cardmid">
        <div className="initialassessment__midtitle">LSI Assessment</div>
        <div className="initialassessment__midsubtitle">
          This is a general assessment to give you a more personalized
          experience.
        </div>
      </div>
      <div className="initialassessment__cardbottom">
        {!done ? (
          <Link
            className={`initialassessment__button ${
              !enabled ? "initialassessment__disabled" : null
            }`}
            disabled={!enabled}
            to="/lsi"
          >
            Take Assessment
          </Link>
        ) : (
          <div className="initialassessment__complete">
            <Icon name="complete" /> <p>Done!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LSICard;
