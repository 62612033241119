import React from "react";
import "./ExpenseChoice.css";

const ExpenseChoice = ({ option, handleChoice, choiceLoading, loading }) => {
  const { text, happiness, cost } = option;

  const handleClick = () => {
    if (loading) return;
    console.log("ExpenseChoice.js 6 | handling choice", option);
    handleChoice({
      option,
    });
  };

  return (
    <div className={`expensechoice ${choiceLoading ? "disabled" : ""}`} onClick={handleClick}>
      <div className="happy-points-container">
        <div className="image-2-icon1">{happiness >= 0 ? "😄" : "☹️"}</div>
        <div className="x31">{happiness}</div>
      </div>
      <div className="parent">
        <div className="div21">$</div>
        <div className="div22">{cost}</div>
      </div>
      <div className="sounds-amazing-lets">{text}</div>
    </div>
  );
};

export default ExpenseChoice;