import React, { useContext, useEffect, useState } from "react";
import { AuthUserContext } from "../Session";
import { EIResultsContainer } from "./components/EIResultsContainer";
import compose from "recompose/compose";
import { withFirebase } from "../Firebase";

const EIResultsPage = ({ firebase }) => {
  const user = useContext(AuthUserContext);
  const [preTestResults, setPreTestResults] = useState(null);
  const [postTestResults, setPostTestResults] = useState(null);
  useEffect(() => {
    getEQDataPreAndPost();
  }, []);

  const getEQDataPreAndPost = async () => {
    try {
      const preTestResultsData = await firebase
        .eiGhystUserDataPreTest(user.uid)
        .once("value");
      const postTestResultsData = await firebase
        .eiGhystUserDataPostTest(user.uid)
        .once("value");
      if (preTestResultsData) {
        setPreTestResults(preTestResultsData.val());
      }
      if (postTestResultsData) {
        setPostTestResults(postTestResultsData.val());
      }
    } catch (error) {
      console.log("EIGhyst 77 | error sending assessment data", error.message);
    }
  };

  return (
    <div>
      <EIResultsContainer
        preTestResults={preTestResults}
        postTestResults={postTestResults}
      />
    </div>
  );
};

export default compose(withFirebase)(EIResultsPage);
