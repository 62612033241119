import React from "react";
import "./CarouselImage.css";
const CarouselImage = ({ src }) => {
  return (
    <img
      className="mockup-icon"
      alt=""
      src={`/landing/landing-carousel-img-${src}.png`}
    />
  );
};

export default CarouselImage;
