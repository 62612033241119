export const initialState = {
  debts: {
    creditCard: { amount: 2000, minPayment: 50, name: "Credit Card" },
  },
  currentDebt: 2000,
  debtsPaidForMonth: false,
  interestAdded: false,
  savings: 500,
  timeLeft: 6,
  interestRate: 1.2,
  payments: [],
  modal: "welcome",
  currentMonth: 0,
  paymentError: false,
};
