import React from "react";

import "./SpendingChartsContainer.css";
import { BudgetSlider } from "./BudgetResultSlider";

const SpendingChartsContainer = ({ state }) => {
  const totalBudget = () => {
    // calculate total budget
    let totalBudget = 0;
    Object.values(state.budget).forEach((val) => {
      totalBudget += val;
    });
    return totalBudget;
  };

  const totalExpenses = () => {
    let totalExpenses = 0;
    Object.values(state.expenses).forEach((exp) => {
      totalExpenses += exp.cost;
    });
    return totalExpenses;
  };

  return (
    <div className="spendingchartscontainer">
      {/* Total Budget Slider */}
      <BudgetSlider
        categoryTitle={"Total Budget"}
        budgeted={totalBudget()}
        spent={totalExpenses()}
        max={state.job.salary}
        state={state}
      />
      {/* Categories */}
      <div style={{ paddingBottom: 30 }} />
      <div style={{ fontWeight: "bold", fontSize: 16 }}>Categories</div>
      {Object.entries(state.budget).map((data, index) => {
        const category = data[0];
        const budgetedAmount = data[1];
        const spentOnCategory = state.expenses[category].cost;

        return (
          <BudgetSlider
            key={index}
            categoryTitle={category}
            budgeted={budgetedAmount}
            spent={spentOnCategory}
            max={state.job.salary}
            state={state}
          />
        );
      })}
    </div>
  );
};

export default SpendingChartsContainer;
