import React from "react";
import Icon from "antd/es/icon";
import Popover from "antd/es/popover";

export const PopOver = ({ title, info }) => {
  const content = (
    <div>
      <p>{info ? info : null}</p>
    </div>
  );
  if (content && info) {
    return (
      <Popover
        // style={{ width: 500 }}
        overlayStyle={{ maxWidth: 500 }}
        content={content}
        title={title}
        placement="right"
      >
        {/* <Button></Button> */}
        <Icon type="info-circle" />
      </Popover>
    );
  }
  return null;
};
