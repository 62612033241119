import React, { useState, useEffect } from "react";
import Button from "antd/es/button";
import Modal from "antd/es/modal";

export const AntModal = (props) => {
  const [modalVisible, toggleModalVisible] = useState(true);

  useEffect(() => {
    console.log("Modal mounted", modalVisible);
    toggleModalVisible(true);
  });

  const handleModalOk = () => {
    props.functionAfterModalToggle(); //Function you want to trigger
    toggleModalVisible();
  };

  return (
    <div>
      <Modal
        title={props.title}
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalOk}
        footer={
          props.footer
            ? [
                <Button key="submit" type="primary" onClick={handleModalOk}>
                  Continue
                </Button>,
              ]
            : null
        }
      >
        {props.children}
      </Modal>
    </div>
  );
};
