import React from "react";
import FeaturesContainerRow from "./FeaturesContainerRow";
import "./FeaturesSection.css";

import { useDispatch } from "react-redux";
import { toggleSignupModal } from "../../../store/authUser/authUserActions";

const FeaturesSection = () => {
  const dispatch = useDispatch();

  return (
    <div className="features2">
      <div className="header1">
        <div className="header2">
          <div className="our-program">Our program</div>
          <div className="learn-more-about">
            Learn more about our unique capabilities.
          </div>
        </div>
        <div className="cardloco">
          <div className="features1">
            <div className="card-info3">
              <img className="image-1-icon" alt="" src="/image-1@2x.png" />
              <div className="simple-sign-up-with">
                Simple sign-up with county-specific QR code
              </div>
            </div>
            <div className="card-info3">
              <img className="image-1-icon" alt="" src="/image-2@2x.png" />
              <div className="two-classrooms-drug">
                Two classrooms: drug offenses & others
              </div>
            </div>
          </div>
          <div className="features1">
            <div className="card-info3">
              <img
                className="image-1-icon"
                alt=""
                src="/assets/images/program-image-modules.png"
              />
              <div className="simple-sign-up-with">
                Modules: EQ, finance, substance disorder
              </div>
            </div>
            <div className="card-info3">
              <img
                className="image-1-icon"
                alt=""
                src="/assets/images/program-image-certificates.png"
              />
              <div className="two-classrooms-drug">
                Completion results in a program certificate that enables case
                dismissal and record restriction.
              </div>
            </div>
          </div>
          {/* <FeaturesContainerRow
            moduleDescription="Simple sign-up with county-specific QR code"
            classroomDescription={`Two classrooms: drug offenses & others`}
          />
          <FeaturesContainerRow
            moduleDescription="Modules: EQ, finance, substance disorder"
            classroomDescription="Completion results in a program certificate that enables case dismissal and record restriction."
          /> */}
        </div>
      </div>
      <img
        className="graduation-ceremony-1-1-icon"
        alt=""
        src="/red-students-classroom.jpg"
      />
    </div>
  );
};

export default FeaturesSection;
