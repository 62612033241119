import React, { useState } from "react";
import { API_URL } from "src/api/apiIUrl";

// userPaidForDiversion is changed to true after the user completes the Stripe payment

export function StripePaymentLinkButton({ userId, isNonprofit }) {
  const [loadingUrl, setLoadingUrl] = useState(false);
  const generatePaymentLinkAndRedirectToStripe = async () => {
    console.log("API_URL", API_URL);
    setLoadingUrl(true);
    try {
      const requestPaymentLink = await fetch(
        `${API_URL}/paymentStripe?isNonprofit=${isNonprofit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(
        "StripePaymentButton 19 | requestPaymentLink",
        requestPaymentLink
      );
      const data = await requestPaymentLink.json();
      console.log("dataaa", data);
      if (data.error) {
        console.error("Stripe API Error:", data.error);
        throw new Error(data.error);
      }

      if (!data.paymentLink?.url) {
        throw new Error("Invalid payment link response");
      }
      window.open(
        `${data.paymentLink.url}?client_reference_id=${userId}`,
        "_blank"
      );
    } catch (error) {
      console.error("Payment link generation failed:", error);
    } finally {
      setLoadingUrl(false);
    }
  };

  return (
    <div>
      <button
        className="button12"
        onClick={generatePaymentLinkAndRedirectToStripe}
      >
        <div className="pay-100">
          {!loadingUrl ? "Enroll Now" : "Loading..."}
        </div>
      </button>
    </div>
  );
}
