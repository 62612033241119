import React, { useState } from "react";
import "./index.css";
import Input from "antd/es/input";
import Situation from "./components/Situation";

export const DecisionTree = (props) => {
  const { editSection } = props;

  const [situationsHorizontal, setSituationsHorizontal] = useState([
    // decisionTreeData,
    props.item.tree,
  ]);

  const [chosenChildrenTracker, setChosenChildrenTracker] = useState([]);

  const chosenChildrenIndexes = (index) => {
    setChosenChildrenTracker((prevArray) => [...prevArray, index]);
  };

  const addToHorizontalEditor = (situation, treeIndex, choiceIndex) => {
    if (situationsHorizontal.length > 1) {
      const horizontalLength = situationsHorizontal.length;
      if (treeIndex + 1 === horizontalLength) {
        updateSituationsByIndex(situation, horizontalLength);
        chosenChildrenIndexes(choiceIndex);
      } else {
        //not in correct tree index, slide forwards
        const slicedSituations = situationsHorizontal.slice(0, treeIndex + 1);
        const slicedIndexes = chosenChildrenTracker.slice(0, treeIndex);
        setSituationsHorizontal([...slicedSituations, situation]);
        setChosenChildrenTracker([...slicedIndexes, choiceIndex]);
      }
    } else {
      setSituationsHorizontal([...situationsHorizontal, situation]);
      chosenChildrenIndexes(choiceIndex);
    }
  };

  const updateSituationsByIndex = (situation, index) => {
    let updatedSituations = [...situationsHorizontal];
    updatedSituations[index] = situation;
    setSituationsHorizontal(updatedSituations);
  };

  const addChildSituation = (situation, treeIndex) => {
    console.log(
      "DecisionTree 49 | situations horizontal",
      situationsHorizontal
    );
  };

  const handleTreeChange = (
    e,
    treeIndex,
    type,
    responseIndex,
    childSituationIndex
  ) => {
    //treeLevel (treeIndex) being edited
    //treeDecisions array
    //variable being edited

    editSection(
      e,
      props.index,
      "decisionTree",
      null,
      null,
      null,
      null,
      null,
      null,
      {
        treeIndex,
        type,
        responseIndex,
        chosenChildrenTracker,
        childSituationIndex,
      }
    );
  };

  return (
    <>
      <div className="game-goal-container">
        <p className="game-goal-text">Game Goal</p>
        <Input
          className="game-goal-input"
          value={props.item.gameGoal}
          onChange={(e) => handleTreeChange(e, null, "goal")}
          placeholder="Game Goal"
        />
      </div>
      <div className="game-goal-container">
        <p className="game-goal-text">Initial Bank</p>
        <Input
          className="game-goal-input"
          value={props.item.initialBank}
          onChange={(e) => handleTreeChange(e, null, "initialBank")}
          placeholder="Initial Bank Account"
        />
      </div>
      <div className="situations-edit-container">
        {situationsHorizontal &&
          situationsHorizontal.map((situation, index) => {
            return (
              <Situation
                key={index}
                situationIndex={index}
                contentIndex={props.index}
                situation={situation}
                addToHorizontal={addToHorizontalEditor}
                addChildSituation={addChildSituation}
                handleTreeChange={handleTreeChange}
                editSection={editSection}
                chosenChildrenIndexes={chosenChildrenIndexes}
                copiedTreeBranch={props.copiedTreeBranch}
                chosenChildrenTracker={chosenChildrenTracker}
              />
            );
          })}
      </div>
      <button
        onClick={(e) => props.removeContentAtIndex(e, props.index)}
        type="button"
      >
        Delete Tree
      </button>
    </>
  );
};
