import React, { useState, useEffect, useMemo } from "react";
import { withFirebase } from "src/components/Firebase";
import ContinueModule from "./components/ContinueModule";
import Module from "./components/Module";
import "./index.css";

import moment from "moment";

// Utility functions
const getCurrentDateFormatted = () => moment().format("YYYY-MM-DD");

const isAfterDueDate = (dueDate) => {
  console.log("Modules 12 | duedate", dueDate);
  return moment(getCurrentDateFormatted()).isAfter(dueDate, "day");
};

const isBeforeUnlockDate = (unlockDate) =>
  moment(getCurrentDateFormatted()).isBefore(unlockDate, "day");

const prevLesson = (modules, index) => modules[index - 1] || null;

const hasUnlockDatePassed = (modules, index) => {
  if (index === 0) return false;

  const previous = prevLesson(modules, index);
  if (previous?.name === "GED Preparation") {
    return false; // Assuming GED Preparation is always unlocked.
  }

  const currentDateIsAfterUnlockDate = !isBeforeUnlockDate(
    modules[index].unlockDate
  );

  return !currentDateIsAfterUnlockDate;
};

const moduleUnlocked = (
  modules,
  index,
  authUser,
  finishedInitialAssessments
) => {
  const previous = prevLesson(modules, index);
  return (
    (index === 0 && finishedInitialAssessments) ||
    authUser?.isAdmin ||
    authUser?.isMentor ||
    (previous &&
      typeof previous.progress !== "undefined" &&
      previous.progress === previous.sectionLength) ||
    modules[index].name === "GED Preparation" ||
    previous?.name === "GED Preparation"
  );
};

const moduleComplete = (module) => {
  const { progress, sectionLength } = module;
  return typeof progress !== "undefined" && progress === sectionLength;
};

const Modules = ({
  firebase,
  authUser,
  finishedInitialAssessments,
  modules,
  handleModuleStart,
}) => {
  // Derived state with useMemo
  const currentModule = useMemo(
    () => modules.filter((module) => module.uid === authUser.currentLessonId),
    [modules, authUser.currentLessonId]
  );

  if (!finishedInitialAssessments && !authUser?.isMentor) {
    return null;
  }

  return (
    <>
      <div className="modules__container">
        {authUser?.currentLessonId && (
          <ContinueModule currentModule={currentModule} />
        )}
        {modules ? (
          modules.map((module, index) => (
            <Module
              key={index}
              module={module}
              available={moduleUnlocked(
                modules,
                index,
                authUser,
                finishedInitialAssessments
              )}
              complete={moduleComplete(module)}
              authUser={authUser}
              lockedByDate={hasUnlockDatePassed(modules, index)}
              firebase={firebase}
              handleModuleStart={handleModuleStart}
            />
          ))
        ) : (
          <div className="modules__loading">Loading</div>
        )}
      </div>
    </>
  );
};

export default withFirebase(Modules);
