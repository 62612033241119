import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import { Situation } from "./Situation";

export const CarouselChoices = ({
  handleChange,
  decisionIndex = 0,
  currentNode,
  takeAction,
  bank,
}) => {
  useEffect(() => {
    console.log("DecisionTreeGame 178 | re-rendering carousel", decisionIndex);
  }, [decisionIndex, currentNode]);

  return (
    <div className="situations__container">
      {currentNode.childrenSituations &&
        currentNode.childrenSituations.map((situation, index) => {
          if (situation) {
            return (
              <Situation
                key={index}
                index={index}
                situation={situation}
                takeAction={takeAction}
                bank={bank}
              />
            );
          } else {
            return null;
          }
        })}
    </div>
  );
};
