import React from "react";
import SaveToCard from "../../../../../../SaveToCard";
import "./MoneySavedDecisionContainer.css";

export const MoneySavedDecisionContainer = ({
  adjustMonthToSaveChoice,
  state,
}) => {
  return (
    <div className="moneysaveddecisioncontainer">
      <div className="savedbudget-title">
        <div className="titlesaved">Money Leftover</div>
        <div className="titlesaved">$ {state.balance}</div>
      </div>
      <div className="budgetsaved-subtitle">
        <div className="subtitle-budget">Where would you like to put it?</div>
      </div>
      <div className="budgetsavedcards-container">
        <SaveToCard
          budgetsavedAccountType="Checking account"
          amountDecision={state.balance}
          budgetSavedInfo="Use the money for next month’s expenses."
          type="checkings"
          handleDecision={() =>
            adjustMonthToSaveChoice("checkings", state.balance)
          }
          state={state}
        />
        <SaveToCard
          budgetsavedAccountType="Savings Account"
          amountDecision={state.balance}
          budgetSavedInfo="Save the money"
          type="savings"
          handleDecision={() =>
            adjustMonthToSaveChoice("savings", state.balance)
          }
          state={state}
        />
      </div>
    </div>
  );
};

export default MoneySavedDecisionContainer;
