import React, { Component, PureComponent } from "react";
import "./Buy.css";

class Buy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buying: true,
      currentTransaction: this.props.transactionInProgress,
    };
  }

  _switch = () => {
    this.setState({
      buying: false,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.transactionInProgress !== this.props.transactionInProgress) {
      this.setState({ buying: false });
    }
  }

  _switchBuying = () => {
    const { setTradeValues } = this.props;
    console.log("This should work");
    const { name, price, ticker, range, _buyAsset } = this.props;

    // This is for the trade modal
    setTradeValues(name, ticker, price);
  };

  render() {
    if (this.state.buying && this.props.transactionInProgress) {
      return (
        <div className="buying-button">
          <button className="btn btn-default btn-xs">
            <i className="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
          </button>
        </div>
      );
    } else {
      console.log("Buy.js 43 | portfolio", this.props.portfolio);
      return (
        <div
          className={`buy-button ${
            this.props.portfolio ? null : "trade-button-pulse"
          }`}
        >
          <button
            onClick={this._switchBuying}
            // className="btn btn-default btn-xs"
          >
            {" "}
            <strong>Trade</strong>{" "}
          </button>
        </div>
      );
    }
  }
}

export default Buy;
