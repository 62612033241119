import React from "react";

export const AdminOptions = ({
  userMode,
  role,
  toggleAdminAndUserViews,
  toggleAdd,
}) => {
  return (
    <div className="admin-options">
      {/* Shows buttons. Toggles between seeing the Delete and Edit modes.  */}
      {/* {userMode === "ADMIN" ? (
        <button className="btn btn-primary" onClick={toggleAdd}>
          Add Section
        </button>
      ) : null} */}
      {role === "ADMIN" ? (
        userMode === "user" ? (
          <button
            className="btn btn-warning btn-sm"
            onClick={toggleAdminAndUserViews}
          >
            Enable Admin Mode
          </button>
        ) : (
          <button
            className="btn btn-success btn-sm"
            onClick={toggleAdminAndUserViews}
          >
            Enable User Mode
          </button>
        )
      ) : null}
    </div>
  );
};
