import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { useSortBy, useTable } from "react-table";
import "./index.css";

const timeFrameDaysArray = [
  { label: "All", value: null },
  { label: "Last 7 days", value: 7 },
  { label: "Last 30 days", value: 30 },
  { label: "Last 90 days", value: 90 },
];

const ModuleReviews = ({ firebase }) => {
  const [modules, setModules] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [moduleReviewsName, setModuleReviewsName] = useState(null);
  const [reviewTimeFrame, setReviewTimeFrame] = useState(null);

  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = async () => {
    const reviewsRequest = await firebase.moduleReviews().once("value");
    const modulesInfoRequest = await firebase
      .getModulesOverview()
      .once("value");
    const moduleData = reviewsRequest.val();
    const modulesOverview = modulesInfoRequest.val();

    if (moduleData && modulesOverview) {
      const moduleIds = Object.keys(moduleData);

      const moduleReviews = moduleIds.map((moduleId) => {
        let reviews = Object.values(moduleData[moduleId]);

        const ratings = reviews.map((review) => review.rating);

        const name = modulesOverview[moduleId]?.name;
        const averageRating =
          ratings.reduce((acc, curr) => acc + curr, 0) / ratings.length;

        return {
          moduleId,
          averageRating,
          name,
          reviews,
        };
      });

      setModules(moduleReviews);
    } else {
      console.log("No data available");
    }
  };

  const data = React.useMemo(
    () =>
      modules.map((module) => {
        return {
          name: module.name,
          averageRating: module.averageRating.toFixed(1),
          totalReviews: module.reviews.length,
          reviews: module.reviews,
        };
      }),
    [modules]
  );

  const columns = React.useMemo(
    () => [
      { Header: "Module Name", accessor: "name" },
      { Header: "Average Rating", accessor: "averageRating" },
      { Header: "Total Reviews", accessor: "totalReviews" },
    ],
    []
  );

  const tableInstance = useTable({ columns, data }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div className="reviewspage__container">
      <h3>Module Ratings</h3>
      <div className="reviewstable__container">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="reviews__row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        onClick={() => {
                          console.log("Reviews 101 | row", row);
                          const { name, reviews } = row.original;
                          setReviews(reviews);
                          setModuleReviewsName(name);
                        }}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <br />
      {moduleReviewsName && moduleReviewsName}
      {/* {timeFrameDaysArray && (
        <select
          onChange={(e) => {
            setReviewTimeFrame(e.target.value);
          }}
        >
          {timeFrameDaysArray.map((timeFrame) => (
            <option key={timeFrame.value} value={timeFrame.value}>
              {timeFrame.label}
            </option>
          ))}
        </select>
      )} */}
      <br />

      <div className="reviews__container">
        {reviews &&
          reviews
            .sort((a, b) => {
              // check if date is undefined and send it to the end of the array
              if (!a.date) return 1;
              if (!b.date) return -1;

              return new Date(b.date) - new Date(a.date);
            })
            .map((review, index) => {
              return (
                <div key={index} className="review__container">
                  <div className="review__text">
                    {review.textFeedback || "No text"}
                  </div>
                  <div className="review__rating">{review.rating}</div>
                  <div className="review__date">
                    {isNaN(new Date(review.date))
                      ? "No date"
                      : new Date(review.date).toDateString()}
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default compose(withFirebase)(ModuleReviews);
