import React, { useState } from "react";
import Task from "./Task";
import "./index.css";
import Icon from "../shared/Icon";
import { useSelector } from "react-redux";
import CourseTasks from "./CourseTasks";
import CourseTasksInfoHeader from "./CourseTasksInfoHeader";

const CourseTaskList = ({ tasks }) => {
  console.log("CourseTaskList 5 | tasks", tasks);
  const [allTasks, setAllTasks] = useState([]);
  // const events = useSelector((state) => state.events.items);

  return (
    <div className="coursetasklist__container">
      <CourseTasksInfoHeader />
      <CourseTasks tasks={allTasks} />
    </div>
  );
};

export default CourseTaskList;
