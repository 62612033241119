export const shuffleArray = (arr) => {
  let arrCopy = [...arr];
  for (let i = arrCopy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arrCopy[i], arrCopy[j]] = [arrCopy[j], arrCopy[i]];
  }
  return arr;
};

export const floor = (number) => {
  return Math.floor(number);
};

export const isDevelopment = () => {
  return process.env.NODE_ENV === "development";
};

export function capitalizeFirstLetter(string) {
  if (!string) return null;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const hexToRGBA = (hexValue, opacity) => {
  const r = parseInt(hexValue.slice(1, 3), 16);
  const g = parseInt(hexValue.slice(3, 5), 16);
  const b = parseInt(hexValue.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const toUpperCaseString = (inputString) => {
  return inputString.toUpperCase();
};
