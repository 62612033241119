
export const LSIReducer = (state = {lsiData: {}}, action) => {
  switch (action.type) {
    case "getLSIData":
      return { ...state, lsiData: action.payload };
    default:
      return state;
  }
};


export const FinalAssessmentReducer = (state = {faData: {}}, action) => {
  switch (action.type) {
    case "getFinalAssessmentData":
      return { ...state, faData: action.payload };
    default:
      return state;
  }
};

