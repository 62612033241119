import React, { Component } from "react";
import GoogleSheet from "./components/GooogleSheet/GoogleSheet";
import "./index.css";
import lesson from "./Data/lesson";
import Checker from "./components/Checker/Checker";
import {
  checkSingleCell,
  _lookForSheet,
  updateSingleCellValueAndFormat,
} from "./sheetFunctions/sheetFunctions";
import GameComponent from "./components/GameComponent/GameComponent";
import IntroModal from "./components/Modals/IntroModal/IntroModal";

import { withFirebase } from "../../../Firebase";

import FinishModal from "./components/Modals/FinishModal/FinishModal";

import ProgressBar from "../../../ProgressBar";
import { WidthWarning } from "../../../WidthWarning";
import { formatRangeColorAndBackground } from "../Snowball/Investing/sheetsFunctions/formatFunctions";
import { NeedPermisions } from "./components/NeedPermisions";
import { toast } from "react-toastify";

import { getBrowser } from "../../../../utils/checkBrowser";

import { BrowserWarning } from "./components/BrowserWarning/BrowserWarning";

class SpreadsheetsTutorialContainer extends Component {
  state = {
    title: this.props.name ? this.props.name : null,
    sheetId: process.env.NODE_ENV === "development" ? this.props.sheetId : "",
    lesson: this.props.data ? this.props.data : lesson,
    lessonNumber: 0,
    correct: false,
    hint: false,
    showHint: false,
    introModalVisible: true,
    finishModalVisible: false,
    spreadsheetsLevel: 0,
    checkerLoading: false,
    formatsDone: false,
    levelUpdated: false,
    needPermision: false,
  };

  async componentDidMount() {
    console.log("container 41 | name, data", this.props.name, this.props.data);
    let token = localStorage.getItem("token");

    // if (token) {
    this.handleSheetCreation(token);
    this.getLevelNumber();
    // } else {
    // }
  }

  handleSheetCreation = async (token) => {
    if (localStorage.getItem(this.props.name)) {
      const sheetId = localStorage
        .getItem(this.props.name)
        .substr(1)
        .slice(0, -1);
      console.log("container 86 | sheetId", sheetId);

      this.setState(
        {
          sheetId: sheetId,
        },
        () => {
          this.addInitialFormatting();
        }
      );
    } else {
      return _lookForSheet(this.props.name, this._startSetup)
        .then(async () => {
          this.addInitialFormatting();
        })
        .catch((error) => {
          console.log("container | 86", error.message);
        });
    }
  };

  getLevelNumber = async () => {
    console.log("container 87 | getting level number");
    const { userId, firebase, sectionId, item } = this.props;
    console.log("container 93 | getting user data", userId, sectionId, item);
    try {
      const levelNum = await firebase
        .userProgressRef(this.props.userId, this.props.name)
        .get();
      console.log("container 117 | levelNum", levelNum.data());

      if (levelNum.data() && !this.state.levelUpdated) {
        this.setState({
          lessonNumber: levelNum.data().levelNum,
          levelUpdated: true,
        });
      } else {
        console.log("container 96 | no level yet");
      }
    } catch (error) {
      console.log("container 97 | error", error);
    }
  };

  addInitialFormatting = async () => {
    let formats = this.props.item.formats;

    formats &&
      formats.forEach(async (mainFormat) => {
        try {
          const format = await formatRangeColorAndBackground(
            this.state.sheetId,
            mainFormat.formatRange,
            mainFormat.fontColor,
            mainFormat.backgroundColor,
            mainFormat.value
          );
          console.log("container 146 | format complete", format);
          if (
            format.error?.message ===
            "Request had insufficient authentication scopes."
          ) {
            this.setState({ needPermision: true });
          }
        } catch (error) {
          console.log("container 123 | error adding format", error);
        }
      });

    console.log("container 147 | error updating sheet formats");
  };

  // #b7e1cd

  unlockBudgeting = () => {
    this.props.history.push("/");
  };

  hideIntroModal = () => {
    this.setState({
      introModalVisible: false,
    });
  };

  nextLesson = () => {
    const { lessonNumber } = this.state;
    const { data } = this.props;
    if (lessonNumber < data.length - 1) {
      this.setState(
        {
          lessonNumber: lessonNumber + 1,
          correct: false,
        },
        () => {
          this._updateSheetsLevel(lessonNumber);
        }
      );
    } else {
      // console.log("Finish Modal here");
      this.setState({
        finishModalVisible: true,
      });
    }
  };

  prevLesson = () => {
    const { lessonNumber } = this.state;
    if (lessonNumber > 0) {
      this.setState({
        lessonNumber: lessonNumber - 1,
        correct: false,
      });
    }
  };

  _updateSheetsLevel = async (level) => {
    const { firebase } = this.props;
    this.setState(
      {
        lessonNumber: level + 1,
      },
      async () => {
        console.log(
          "container.js 65 | updating sheets level",
          this.props.userId,
          this.props.name
        );
        firebase
          .userProgressRef(this.props.userId, this.props.name)
          .set({ levelNum: level + 1 })
          .then(() => {
            console.log("container | 233", "updated sheets level");
          })
          .catch((error) => {
            console.log(" | 233", "error updating sheets level", error);
          });
        // This updates the current level in the database if the level is different than 0
      }
    );
  };

  // Last work was done here, you have to keep working on this!!
  checkSheetInput = () => {
    //token, range, value
    const { token } = this.props;
    const { range, answer, color } = this.props.data[this.state.lessonNumber];

    this.setState({ checkerLoading: true, showHint: false });

    checkSingleCell(token, this.state.sheetId, range)
      .then((info) => {
        if (info.error) {
          this.handleTokenExpired();
        }

        if (info.values) {
          console.log("There are values!", info.values[0]);
          let googleValue;
          let lessonAnswer;
          console.log("info values", info.values);
          if (typeof info.values[0][0] === "number") {
            console.log("container 239 | it is a number");
            googleValue = String(info.values[0][0]).trim();
            lessonAnswer = String(answer[0]).toLowerCase().trim();
            console.log(
              "container 244 | googlevalue vs lesson value",
              googleValue,
              lessonAnswer
            );
          } else {
            console.log("container 243 | it is a string");
            googleValue = info.values[0][0].toLowerCase().trim();

            lessonAnswer = answer[0].toLowerCase().trim();
            console.log(
              "container 244 | answer comparison",
              typeof googleValue,
              lessonAnswer,
              googleValue
            );
          }

          console.log(
            "container 252 | google value vs lesson value",
            googleValue,
            lessonAnswer
          );

          if (googleValue === lessonAnswer) {
            this.handleCorrectAnswer();
          } else {
            this.handleIncorrectAnswer();
          }
        } else {
          this.handleEmptyAnswer(range);
        }
      })
      .catch((error) => {
        console.log("container.js 150 | ", "ERROR", error);
      });
  };

  handleTokenExpired = () => {
    console.log("container.js 109 | theres an error with the request");
    this.props.logOut();
    // localStorage.clear();
  };

  handleCorrectAnswer = () => {
    this.setState({
      correct: true,
      showHint: false,
      checkerLoading: false,
    });
  };

  handleIncorrectAnswer = () => {
    this.setState({
      showHint: true,
      checkerLoading: false,
    });
  };

  handleEmptyAnswer = (range) => {
    console.log("container.js 208 | handling empty answer");
    toast.error(
      "Empty Values Received! Make sure to press ENTER after you input a value",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

    this.setState({
      showHint: true,
      checkerLoading: false,
    });
    this.updateSheetWithHint(range);
  };

  updateSheetWithHint = (range) => {
    console.log("container.js 221 | updating sheet with hint", range);
    // updateSingleCellValueAndFormat(
    //   this.props.token,
    //   this.state.sheetId,
    //   null,
    //   range,
    //   "green"
    // );
  };

  _startSetup = (userFileId) => {
    this.setState(
      {
        sheetId: userFileId,
      },
      () => {
        console.log("container 325 | set sheet id", userFileId);
      }
    );
  };

  _sendToSheet = (amount, range, color) => {
    updateSingleCellValueAndFormat(this.state.sheetId, amount, range, color);
  };

  highlightParagraph = (paragraph, answer) => {
    const boldArray = paragraph.split("//");

    const sentence = boldArray.map((sentence, index) => {
      var searchingCell = sentence.search("cell");
      var searchingAnswer = sentence.search(answer);
      if (searchingCell === 1) {
        return (
          <span key={index} className="cell">
            <strong>{sentence}</strong>
          </span>
        );
      } else if (searchingAnswer === 1) {
        return (
          <span key={index} className="highlight-answer">
            <strong>{sentence}</strong>
          </span>
        );
      }
      return <span key={index}>{sentence}</span>;
    });
    return sentence;
  };

  render() {
    const { correct, needPermision } = this.state;
    let lessonNum = this.state.lessonNumber ? this.state.lessonNumber : 0;
    console.log("container 394 | lessonNum", lessonNum);
    console.log(
      "container 394 | props.data typeof",
      typeof this.props.data[lessonNum]
    );
    const {
      title,
      text,
      image,
      answer,
      range,
      hint,
      button,
      color,
      instructions,
    } = this.props.data[lessonNum]
      ? this.props.data[lessonNum]
      : this.props.data[0];

    const textInstructions = text
      ? this.highlightParagraph(text, answer)
      : null;
    const instructionsHighlighted = instructions
      ? this.highlightParagraph(instructions, answer)
      : null;
    console.log("this.props.data", this.props.data);
    if (!this.props.data) return <div>Loading</div>;
    if (this.state.needPermision) return <NeedPermisions category={"drive"} />;
    return (
      <>
        <WidthWarning />
        <div className="intro-container">
          {process.env.NODE_ENV === "development" && (
            <IntroModal
              introModalVisible={this.state.introModalVisible}
              hideIntro={this.hideIntroModal}
              name={this.props.name}
            />
          )}
          {this.state.finishModalVisible ? (
            <FinishModal
              unlockBudgeting={this.unlockBudgeting}
              sheetId={this.state.sheetId}
              name={this.props.name}
              handleNext={this.props.handleNext}
            />
          ) : null}
          <div className="instructions">
            <ProgressBar
              progress={this.state.lessonNumber}
              total={this.props.data.length}
              title={this.props.name}
              progressPercentage={
                (this.state.lessonNumber / this.props.data.length) * 100
              }
            />

            <h5>
              <strong>{title && title}</strong>
            </h5>
            <p className="intro-instructions-top">{textInstructions}</p>
            <p className="intro-instructions-title">
              {instructions ? "INSTRUCTIONS" : null}
            </p>
            <p className="intro-instructions-action">
              {instructions ? instructionsHighlighted : null}
            </p>
            <img
              className="intro-container-image"
              src={image}
              width="250px"
              alt=""
            />
            {process.env.NODE_ENV === "development" || this.props.isAdmin ? (
              <div>
                <button onClick={this.prevLesson}>Back</button>
                <button onClick={this.nextLesson}>Next</button>
              </div>
            ) : null}
          </div>
          <div className="spreadsheet">
            {getBrowser() === "Google Chrome" ? (
              <>
                <GameComponent
                  answer={answer}
                  range={range}
                  button={button}
                  color={color}
                  lessonNum={this.state.lessonNumber}
                  _sendToSheet={this._sendToSheet}
                />
                <GoogleSheet sheetId={this.state.sheetId} />
                <Checker
                  correct={correct}
                  nextLesson={this.nextLesson}
                  checkSheetInput={this.checkSheetInput}
                  showHint={this.state.showHint}
                  hint={hint}
                  checkerLoading={this.state.checkerLoading}
                  answer={answer}
                  range={range}
                />
              </>
            ) : (
              <BrowserWarning />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withFirebase(SpreadsheetsTutorialContainer);
