import React from "react";
import "./modals.css";

const ResultsModal = (props) => {
  // let sum = 0;
  // const data = props.chartData;

  // for (const num in data) {
  //     sum += data[num];

  // }

  let title = `Congrats!`;

  let message = `Way to go! You survived three months happy and under budget!`;

  let buttonMessage = `Unlock New Job`;

  const handleReset = () => {
    props._startGame();
    props.toggleNext();
    console.log("Savings data", props.savingsData);
    props._getAverageSavings(props.savingsData);
  };

  // const handleClick = () => window.location.assign("https://docs.google.com/forms/d/e/1FAIpQLSd521jngLVf_troafgPbElegy7AfKnA8ypZiqDN52rqxem8lQ/viewform?usp=sf_link");

  return (
    <div className="snowball-modal">
      <div className="modal-top">
        <h1>{title}</h1>
      </div>
      <div className="modal-options">
        <h5>{message}</h5>
        <hr />
        <button
          onClick={handleReset}
          type="button"
          className="btn btn-primary btn-lg"
        >
          {buttonMessage}
        </button>
        <button className="btn btn-success btn-lg" onClick={props.handleNext}>
          Go to the next lesson
        </button>
      </div>
      {/* <button onClick={handleClick} type="button" className="btn btn-success btn-lg">
        Take Survey
      </button> */}
    </div>
  );
};

export default ResultsModal;
