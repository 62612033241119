import { PDFDocument } from "pdf-lib";

export const generateTermsAndConditionsDoc = async (
  studentName = "Sample Name",
  signatureImageBase64
) => {
  if (!signatureImageBase64) {
    return;
  }
  try {
    const termsAndConditionsUrl =
      "https://firebasestorage.googleapis.com/v0/b/red-platform.appspot.com/o/pdfs%2FParticipant%20Waivers%20_%20Releases%20to%20Include%20in%20LSI.docx.pdf?alt=media&token=95c4faf1-33d8-4076-9ae7-2f5b1f0d28f0";

    const formPdfBytes = await fetch(termsAndConditionsUrl).then((res) =>
      res.arrayBuffer()
    );

    const pdfDoc = await PDFDocument.load(formPdfBytes);

    const signatureImageBytes = await fetch(signatureImageBase64).then((res) =>
      res.arrayBuffer()
    );

    const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

    const form = pdfDoc.getForm();

    const name = form.getTextField("Name");
    const signature = form.getButton("Signature");

    name.setText(studentName);
    signature.setImage(signatureImage);

    const pdf = await pdfDoc.save();
    var blob = new Blob([pdf], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(blob);
    return fileUrl;
    // const w = window.open(fileUrl, "_blank");
    // w && w.focus();

    // send pdf to user as a blob, process that in the client
  } catch (error) {
    console.log("generate-pdf.js 28 | error creating pdf", error);
    throw new Error("Error generating certificate", error);
  }
};
