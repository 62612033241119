import React from "react";
import "./TradeModal.css";
import AssetCompanyInfo from "../../AssetCompanyInfo";

class TradeModal extends React.Component {
  state = {
    numberOfShares: 0,
    tooExpensive: false,
    disabledButton: false,
  };

  handleChange = (event) => {
    this.setState({
      numberOfShares: event.target.value,
    });
  };

  render() {
    const {
      name,
      ticker,
      price,
      _buyAsset,
      range,
      portfolio,
      cash,
    } = this.props;
    const _buyMax = () => {
      const max = Math.floor(cash / price);
      this.setState({
        numberOfShares: max,
      });
    };

    const assetInPortfolio = portfolio.find((asset) => {
      //look for asset in portfolio
      return asset.ticker === ticker;
    });
    let currentAmount;

    if (assetInPortfolio) {
      currentAmount = (
        <div>
          Current amount owned:{" "}
          <h5 style={{ textAlign: "left" }}>{assetInPortfolio.amount}</h5>
        </div>
      );
    } else {
      currentAmount = <span>No shares owned, buy some!</span>;
    }

    const displayTooExpensive = this.state.tooExpensive ? (
      <p className="expensive">Too expensive!</p>
    ) : null;

    const submitBuy = () => {
      if (price * this.state.numberOfShares > cash) {
        this.setState({ tooExpensive: true });
      } else {
        this.props._buyAssetModal(
          name,
          price,
          this.state.numberOfShares,
          ticker
        );
        this.setState({ tooExpensive: false });
      }
    };
    const submitSell = () => {
      this.props._sellAssetModal(
        name,
        price,
        this.state.numberOfShares,
        ticker
      );
    };

    if (this.props.tradingModalVisible) {
      return (
        <div className="trading-modal">
          <i
            onClick={this.props._closeModal}
            className="fa fa-times x-click"
            aria-hidden="true"
          ></i>
          <div className="modal-asset-info">
            <h3>
              <strong>{name}</strong>
            </h3>
            <h5>
              <strong>{ticker}</strong>
            </h5>
            <h2>
              <strong style={{ color: "#73b173" }}>$ {price}</strong>
            </h2>
            {/* <AssetCompanyInfo ticker={ticker} /> */}
          </div>

          {currentAmount}

          <input
            className="input-amount"
            onChange={this.handleChange}
            type="text"
            placeholder="number of stocks"
            value={this.state.numberOfShares}
          />

          <div className="button-amounts-container">
            <button
              onClick={() => {
                this.setState({ numberOfShares: 1 });
              }}
              className="amount-options-button"
            >
              1
            </button>
            <button
              onClick={() => {
                this.setState({ numberOfShares: 5 });
              }}
              className="amount-options-button"
            >
              5
            </button>
            <button
              onClick={() => {
                this.setState({ numberOfShares: 10 });
              }}
              className="amount-options-button"
            >
              10
            </button>
            <button onClick={_buyMax} className="amount-options-button">
              Max
            </button>
          </div>

          {displayTooExpensive}

          <div className="buy-sell">
            <button onClick={submitSell}>Sell</button>
            <button onClick={submitBuy}>Buy</button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default TradeModal;
