import React, { useState } from "react";
import "./RangeInput.css";

function RangeInput({ amount, category, state, onAdjustBudget, title }) {
  const [value, setValue] = useState(((amount || 0) * 100) / 2500); // Adjusted value calculation
  const [prevValue, setPrevValue] = useState(
    (state.expenses[title].cost * 100) / state.job.salary
  ); // Adjusted prevValue calculation

  const generateBackground = () => {
    if (value >= prevValue) {
      return `linear-gradient(90deg, #00BA88 ${prevValue}%, #50D9A7 ${value}%, rgba(0, 186, 136, 0.3) ${value}%)`;
    } else {
      return `linear-gradient(90deg, #00BA88 ${value}%, red ${value}%, red ${prevValue}%, rgba(0, 186, 136, 0.3) ${prevValue}%)`;
    }
  };

  const adjustBudgetCategory = (category, amount) => {
    setValue((amount * 100) / state.job?.salary); // Adjusted value calculation
    onAdjustBudget(category, amount);
    console.log("RangeInput.js 16 | category", category);
  };

  const background = generateBackground();

  return (
    <div style={{ width: "100%" }}>
      <input
        type="range"
        className={`${
          amount < state.expenses[title].cost
            ? "rangeinput-slider-warning"
            : "rangeinput-slider"
        }`}
        min="0"
        max="100" // Adjusted max value
        value={value}
        onChange={(e) => {
          adjustBudgetCategory(
            category,
            (Number(e.target.value) * state.job?.salary) / 100
          ); // Adjusted value calculation
        }}
        step={0.5}
        style={{
          appearance: "none",
          width: "100%",
          height: "15px",
          borderRadius: "10px",
          background: background,
          outline: "none",
          transition: "background .2s",
        }}
      />
    </div>
  );
}

export default RangeInput;
