import React from "react";
import "./IncomeRange.css";
import { hexToRGBA } from "src/utils/utils";
import Button from "src/components/shared/Button";
import { toast } from "react-toastify";


const IncomeRange = ({ budget, income, onRestartGame }) => {
  // Set a maximum width (e.g., 100%) to ensure it doesn't exceed the container

  const maxWidth = income;

  // Calculate the actual width based on the budget
  // const width = Math.min(budget, maxWidth);

  return (
    <div className="income-container">
      <BudgetMoneyIndicator
        budget={budget}
        income={income}
        maxWidth={maxWidth}
      />
      {
        budget > income && (
          <Button title="Declare Bankrupcy" style={{ marginTop: 30 }} onClick={() => {
            toast.error("You declared bankrupcy and lost! Start over!");
            ; onRestartGame()
          }} />
        )

      }
    </div>
  );
};

const BudgetMoneyIndicator = ({ budget, maxWidth, income }) => {
  const color = "#00BA88";

  return (
    <div className="budgetmoneyindicator" style={{ width: "100%" }}>
      <div
        className=""
        style={{ textAlign: "left", width: "100%", fontWeight: "bold" }}
      >
        Income Left to Spend
      </div>
      <div
        className="budget-money-indicator"
        style={{
          backgroundColor:
            budget > income
              ? hexToRGBA("#FF0000", 0.25)
              : hexToRGBA(color, 0.25),
        }}
      >
        <div
          className="happiness"
          style={{
            width: `${parseInt((budget / maxWidth) * 100)}%`,
            backgroundColor: income > budget ? color : "red",
          }}
        />
        <div className="budget-money-type-parent">
          <div className="budget-money-type">$</div>
          <div className="budget-available-money">{maxWidth - budget}</div>
        </div>
      </div>
    </div>
  );
};

export default IncomeRange;
