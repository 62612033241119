import React from "react";

const GoogleSheet = ({ sheetId }) => {
  const start = "https://docs.google.com/spreadsheets/d/";

  var newSheetId = sheetId;
  const end = "/view?rm=minimal&embedded=true";
  const url = start + newSheetId + end;

  if (sheetId) {
    return (
      <iframe
        title="sheet"
        width="500px"
        height="400px"
        src={url}
        frameBorder="0"
      ></iframe>
    );
  } else {
    return (
      <div className="sheet-loading">
        <h1>Loading...</h1>;
      </div>
    );
  }
};

export default GoogleSheet;
