import React, { useState, useEffect } from "react";
import { ActionButton } from "./ActionButton";
import "./index.css";

const Situation = ({
  situation,
  situationIndex,
  setSituationIndex,
  dangerLevel,
  setDangerLevel,
  gameWon,
  gameLost,
}) => {
  useEffect(() => {
    // console.log("Situation.js 12 | danger level", dangerLevel);
  }, []);

  const [situationTriggered, setSituationTriggered] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {}, [gameLost, situationTriggered, selectedIndex]);

  const increaseSituationIndex = (chosenIndex, dangerLevel, danger) => {
    const newDanger = +dangerLevel + +danger;
    console.log("Situation.js 32 | new danger", newDanger);
    if (newDanger < 100) {
      setSituationIndex(situationIndex + 1);
    }
    setSituationTriggered(true);
    setSelectedIndex(chosenIndex);
  };

  if (!situation) return;
  if (gameWon) return;

  return (
    <div className="situation-container">
      <p className="situation-text">{situation.situation}</p>
      <div className="responses-container">
        {situation.actions.map((action, chosenIndex) => {
          if (situationTriggered && selectedIndex === chosenIndex) {
            return (
              <div key={chosenIndex} className="answered-action-button">
                {action.action}
              </div>
            );
          } else if (!situationTriggered) {
            return (
              <ActionButton
                key={chosenIndex}
                increaseSituationIndex={() =>
                  increaseSituationIndex(
                    chosenIndex,
                    action.danger,
                    dangerLevel
                  )
                }
                chosenIndex={chosenIndex}
                action={action.action}
                danger={action.danger}
                dangerLevel={dangerLevel}
                setDangerLevel={setDangerLevel}
              />
            );
          } else {
            return null;
          }
        })}
      </div>

      {situation.actions.map((action, index) => {
        if (index !== selectedIndex) return null;
        return (
          <p className="answered-text" key={index}>
            <strong>Response: </strong>
            {action.response}
          </p>
        );
      })}
    </div>
  );
};

export default Situation;
