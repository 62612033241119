import React from "react";
import "./Advisor.css";

class Advisor extends React.Component {
  state = {
    advisorVisible: false,
    chat: [
      {
        text: [
          "Welcome!",
          "I am Bob, your Financial Advisor",
          "My role is to make you understand investing, are you ready?",
        ],
        response: ["Yes!", "No!"],
        answer: [["Great, lets do it!"], ["So sad, we will do it anyways!"]],
      },
    ],
    currentAnswer: null,
  };

  componentDidMount() {
    this.setState({
      currentText: this.state.chat[0].text[this.state.currentChat],
    });
  }

  toggleAdvisor = () => {
    this.setState({ advisorVisible: !this.state.advisorVisible });
  };

  toggleText = () => {};

  showAnswers = (answers) => {
    let answersToPush = [];
    answers.map((answer, index) => {
      setTimeout(() => {
        answersToPush.push(answer);
        this.setState({
          currentAnswer: answersToPush,
        });
      }, 1500 * index);
    });
  };

  render() {
    const { chat } = this.state;

    const questionsArray = [
      {
        question: "What is a stock ?",
        answer: [
          "A stock is a piece of the value of a company.",
          "If you buy 1 Apple stock for $100, you own part of the company!",
          "Apple's valuation is currently at around 1 trillion.",
          "If you buy $100 worth of Apple, you would own around 0.00000001% of the company.",
        ],
      },
      {
        question: "What is the stocks market ?",
        answer: [
          "A stock is a piece of a company",
          "A market is a place where people trade goods or services",
          "Combined: It is a market where people buy and sell pieces of companies",
        ],
      },
      {
        question: "Can I make a living out of trading ?",
        answer: [
          "The short answer is no.",
          "The long answer is a recommended no as well",
          "Predicting the future is near impossible, but this is why there is Index Investing",
        ],
      },
      {
        question: "What is Index Investing ?",
        answer: [
          "Imagine you could trust your final Biology test to the top 3 people in your class.",
          "Odds are, they will do a good job",
          "Now, what if one of the members of this group underperfomed?",
          "Then we replace him with the next best candidate",
          "Index funds do the same thing, but tracking the best companies, or a specific group of companies in an industry.",
        ],
      },
      {
        question: "What should I buy first ?",
        answer: [
          "We have 2 index funds in this simulator:",
          "The S&P 500 (500 biggest and best companies in the US)",
          "The Dow Jones Industrial average (the 30 biggest companies in the US)",
          "If a stock rises a lot in the next few years, like Apple for example, it WILL enter either of these indexes and benefit you.",
        ],
      },
    ];

    const askMe =
      this.state.currentAnswer == null ? (
        <div>
          <h5>
            {" "}
            <strong>
              Hi! I'm your personal stocks Advisor. Ask me a question!
            </strong>{" "}
          </h5>{" "}
          <hr />
        </div>
      ) : null;

    const questions = questionsArray.map((question) => {
      return (
        <a onClick={() => this.showAnswers(question.answer)}>
          {question.question}
        </a>
      );
    });

    const answers = this.state.currentAnswer
      ? this.state.currentAnswer.map((answer) => {
          return <p className="answer-slide-in">{answer}</p>;
        })
      : null;

    const advisor = this.state.advisorVisible ? (
      <div onClick={this.toggleText} className="advisor">
        {askMe}
        <div className="answers">{answers}</div>

        <div className="questions">{questions}</div>
      </div>
    ) : null;

    return (
      <div>
        {advisor}
        <div className="advisor-toggle">
          <i
            onClick={this.toggleAdvisor}
            className="fa fa-lg fa-comment"
            aria-hidden="true"
          ></i>
        </div>
      </div>
    );
  }
}

export default Advisor;
