import React, { useState, useEffect } from "react";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";

const EnhancedModal = (props) => {
  const [modalVisible, toggleModalVisible] = useState(props.visible || true);
  const [loading, toggleLoading] = useState(false);

  const handleCancel = () => {
    if (props.isAdmin) {
      toggleModalVisible(false);
      if (props.onCancel) {
        props.onCancel();
      }
    }
  };

  const handleOk = async () => {
    toggleLoading(true);
    try {
      await props.modalFunction();
      toggleModalVisible(false);
      if (props.onCancel) {
        props.onCancel();
      }
    } finally {
      toggleLoading(false);
    }
  };

  return (
    <div>
      {props.secondTitle ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type="primary"
            onClick={() => toggleModalVisible(!modalVisible)}
          >
            {props.secondTitle}
          </Button>
        </div>
      ) : null}

      <Modal
        visible={modalVisible}
        title={props.title}
        width={props.width}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            disabled={props.disabled}
            onClick={handleOk}
          >
            {props.title}
          </Button>,
        ]}
      >
        {props.children}
      </Modal>
    </div>
  );
};

export default compose(withFirebase)(EnhancedModal);
