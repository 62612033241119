import React, { useState, useEffect, useContext } from "react";
// import statements from "./assessment.json";
import "./index.css";
import "./components/StartingScreen/EQStart.css";

import { compose } from "recompose";

import { withFirebase } from "../Firebase";

import { ViewAssessment } from "./components/ViewAssessment";

import { AuthUserContext } from "../Session";

import { EditStatements } from "./components/EditStatements";
import EIGhystResults from "./components/EIGhystResults";
import { toast } from "react-toastify";
import { analyzeCompetencyResults } from "./helpers";
import EQStart from "./components/StartingScreen";
import Button from "../shared/Button";

const EIGhyst = ({ firebase }) => {
  const [responses, setResponses] = useState([]);
  const [statements, setStatements] = useState([]);
  const [results, setResults] = useState(null);

  const [startingScreen, setStartingScreen] = useState(true);

  const [postTest, setPostTest] = useState(false);

  const [assessmentSubmitted, setAssessmentSubmitted] = useState(false);

  const user = useContext(AuthUserContext);

  const [viewMode, setViewMode] = useState(true);

  const handleResponseChange = (statementIndex, value) => {
    const updatedResponses = [...responses];
    updatedResponses[statementIndex] = {
      statementIndex,
      statement: statements[statementIndex].statement,
      competency: statements[statementIndex].competency,
      category: statements[statementIndex].category,
      value,
    };
    setResponses(updatedResponses);
    console.log("EIGhyst 27 | updated responses", updatedResponses);
  };

  const getQuestions = async () => {
    try {
      const data = await firebase.eiGhyst().once("value");
      if (data.val()) {
        setStatements(data.val());
      }

      console.log("EIGhyst 26 | data", data.val());
    } catch (error) {
      console.log("EIGhyst 28 | no data");
    }
  };

  const submitAssessment = async () => {
    try {
      // check if all answers have been submitted
      console.log("EIGhyst 53 | user is admin?", user);
      let allAnswersComplete =
        (responses.length > 0 &&
          responses.length === statements.length &&
          responses.every((responseObj) => responseObj.response !== null)) ||
        user.isAdmin;

      // let allAnswersComplete = true;

      if (allAnswersComplete) {
        console.log(
          "EIGhyst 59 | all answers complete",
          statements.length,
          responses.length
        );
        await sendAssessmentDataToUser();
        setAssessmentSubmitted(true);
        window.scroll(0, 0);
      } else {
        console.log(
          "EIGhyst 59 | not all answers complete",
          statements.length,
          responses.length
        );
        console.log("EIGhyst 63 | not all answers complete");
        toast.error("Not all questions complete");
      }

      // console.log("EIGhyst 44 | submitting user data", user);
    } catch (error) {
      toast.error("Not all questions complete");
      console.log("EIGhyst 41 | error with user data", error);
    }
  };

  const sendAssessmentDataToUser = async () => {
    const resultsObject = analyzeCompetencyResults(responses);
    console.log("EIGhyst 80 | results object", resultsObject);
    try {
      if (!user.eqTestComplete) {
        console.log("EIGhyst 112 | submitting to PRE test");
        await firebase.eiGhystUserDataPreTest(user.uid).set({
          responses,
          results: resultsObject,
        });
        await firebase.user(user.uid).update({
          eqTestComplete: true,
        });
      } else {
        console.log("EIGhyst 112 | submitting to POST test");
        await firebase.eiGhystUserDataPostTest(user.uid).set({
          responses,
          results: resultsObject,
        });
        await firebase
          .user(user.uid)
          .update({
            eqPostTestComplete: true,
          })
          .catch((err) => {
            console.log("EIGhyst 119 | error sending data", err.message);
          });
      }
      setResults(resultsObject);
      console.log("EIGhyst 77 | sent assessment data successfully", user.uid);
    } catch (error) {
      console.log("EIGhyst 77 | error sending assessment data", error.message);
    }
  };

  const toggleAllResults = () => {
    const allResponses = [...statements].map((statement, index) => ({
      statementIndex: index,
      statement: statement.statement,
      competency: statement.competency,
      category: statement.category,
      value: !postTest ? 2 : 4, // Set the default or testing value here
    }));

    const resultsObject = analyzeCompetencyResults(allResponses);

    setResponses(allResponses);
    setResults(resultsObject);
    console.log("EIGhyst | All responses toggled", allResponses);
  };

  useEffect(() => {
    getQuestions();
  }, []);

  if (assessmentSubmitted) {
    return (
      <>
        <EIGhystResults results={results} />

        {user?.isAdmin && (
          <button
            onClick={() => {
              setAssessmentSubmitted(!assessmentSubmitted);
            }}
          >
            Toggle assessment complete
          </button>
        )}
      </>
    );
  }

  const toggleStartScreen = () => {
    setStartingScreen(!startingScreen);
  };

  if (!startingScreen) {
    return <EQStart toggleStartScreen={toggleStartScreen} />;
  }

  return (
    <div className="eqstart" style={{ marginLeft: 100 }}>
      <EQStart user={user} />
      {user?.isAdmin && (
        <div className="admin-tools-eq">
          <h4>Admin tools</h4>
          <button onClick={() => setViewMode(!viewMode)}>
            {viewMode ? "Edit Mode" : "View Mode"}
          </button>
          <button onClick={toggleAllResults}>Toggle All Results</button>
          {viewMode && (
            <button
              onClick={() => {
                submitAssessment();
                console.log(responses);
              }}
            >
              Submit
            </button>
          )}
          <div style={{ flex: 1, flexDirection: "row" }}>
            <div>Post Test</div>
            <input
              onChange={() => {
                console.log("EIGhyst 159 | post test", postTest);
                setPostTest(!postTest);
              }}
              checked={postTest}
              type="checkbox"
            />
          </div>
        </div>
      )}
      {viewMode ? (
        <div className="viewassessmentcontainer">
          <ViewAssessment
            statements={statements}
            handleResponseChange={handleResponseChange}
            responses={responses}
          />
        </div>
      ) : (
        <EditStatements
          statements={statements}
          firebase={firebase}
          getQuestions={getQuestions}
        />
      )}
      <div className="eqsubmitbutton_container">
        <Button title="Submit Assessment" onClick={submitAssessment} />
      </div>
    </div>
  );
};

export default compose(withFirebase)(EIGhyst);
