import React from 'react';
import Cell from '../Cell.js'

const styles = {
  container: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 20,

  },
  specialCharacter: {
    fontWeight: 'bold',
    color: 'orange'
  }
};

export const taxesIISteps = [
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>It is the end of the year and you are filing your taxes. You want to know how much taxes that you may get back in your refund. For this exercise, you will be assumed a Floridian, therefore no state taxes! Type <span style={styles.specialCharacter}>Tax with deductions</span> on cell <span style={styles.specialCharacter}>B2</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <Cell cellName={"B2"} onTextChange={setCellText} />
      </div>
    ),
    answer: "tax with deductions",
    range: "B2",
  },
  {
    title: "Tax with Deductions I",
    text: <span style={{ fontSize: 16 }}>You'll calculate taxes yourself now. Start by typing <span style={styles.specialCharacter}>Income</span> on cell <span style={styles.specialCharacter}>B3</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        <Cell cellName={"B3"} onTextChange={setCellText} />
      </div>
    ),
    answer: "income",
    range: "B2",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Your income is $45,000. Type <span style={styles.specialCharacter}>45000</span> on cell <span style={styles.specialCharacter}>C3</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "45000",
    range: "C3",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Now you'll calculate deductions on a charitable donation that you made. First, type <span style={styles.specialCharacter}>Deductions</span> on cell <span style={styles.specialCharacter}>B4</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B4"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "deductions",
    range: "B4",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>You made a $10,000 charitable donation to the Foundation for Cancer. You can now use the $10,000 as a deduction to your income taxes! Type <span style={styles.specialCharacter}>10000</span> on cell <span style={styles.specialCharacter}>C4</span> </span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "10000",
    range: "C4",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Great job! Now calculate your taxable income. Type <span style={styles.specialCharacter}>Taxable Income</span> on cell <span style={styles.specialCharacter}>B5</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} value={"$10,000"} editable={false} />
        </div>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B5"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "taxable income",
    range: "B5",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Now, subtract your Deductions from your Income on cell <span style={styles.specialCharacter}>C5</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} value={"$10,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B5"} value={"Taxable Income"} editable={false} />
          <Cell cellName={"C5"} onTextChange={setCellText} />

        </div>
      </div>
    ),
    answer: "35000",
    range: "C5",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Now you'll use the chart below to calculate your Income Taxes. Let's start with the first bracket. Type <span style={styles.specialCharacter}>Taxable at 10%</span> on cell <span style={styles.specialCharacter}>B7</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} value={"$10,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B5"} value={"Taxable Income"} editable={false} />
          <Cell cellName={"C5"} value={"$35,000"} editable={false} />
        </div>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B7"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "taxable at 10%",
    range: "B7",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Now you'll input the first bracket for your total taxable income. Type <span style={styles.specialCharacter}>9950</span> on cell <span style={styles.specialCharacter}>C7</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} value={"$10,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B5"} value={"Taxable Income"} editable={false} />
          <Cell cellName={"C5"} value={"$35,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B7"} value={"Taxable at 10%"} editable={false} />
          <Cell cellName={"C7"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "9950",
    range: "C7",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Great! Now you'll calculate the next bracket. Type <span style={styles.specialCharacter}>Taxable at 12%</span> on cell <span style={styles.specialCharacter}>B8</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} value={"$10,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B5"} value={"Taxable Income"} editable={false} />
          <Cell cellName={"C5"} value={"$35,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B7"} value={"Taxable at 10%"} editable={false} />
          <Cell cellName={"C7"} value={"$9,950"} editable={false} />
        </div>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B8"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "taxable at 12%",
    range: "B8",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>As you can see in the image below, income 9951-40525 is taxed at 12%. We have already identified the first portion of our income at 10%.  Let's identify the rest by subtracting the income that is taxable at 12%. Type <span style={styles.specialCharacter}>25050</span> on cell <span style={styles.specialCharacter}>C8</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} value={"$10,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B5"} value={"Taxable Income"} editable={false} />
          <Cell cellName={"C5"} value={"$35,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B7"} value={"Taxable at 10%"} editable={false} />
          <Cell cellName={"C7"} value={"$9,950"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B8"} value={"Taxable at 12%"} editable={false} />
          <Cell cellName={"C8"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "25050",
    range: "C8",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Great! As you can see, your Taxable income of $35,000 is not taxed at the same percentage, but it's spread out. $9950 is taxed at 10%, and $25,050 is taxed at 12%. Remember that if you add $9,950 and $25,050 you'll get your total taxable income, which is $35,000. Let's calculate total taxes now. Type <span style={styles.specialCharacter}>Taxes due at 10%</span> on cell <span style={styles.specialCharacter}>B10</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} value={"$10,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B5"} value={"Taxable Income"} editable={false} />
          <Cell cellName={"C5"} value={"$35,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B7"} value={"Taxable at 10%"} editable={false} />
          <Cell cellName={"C7"} value={"$9,950"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B8"} value={"Taxable at 12%"} editable={false} />
          <Cell cellName={"C8"} value={"$25,050"} editable={false} />
        </div>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B10"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "taxes due at 10%",
    range: "B10",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Now on cell <span style={styles.specialCharacter}>C10</span> multiply your 9950 by 10%.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} value={"$10,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B5"} value={"Taxable Income"} editable={false} />
          <Cell cellName={"C5"} value={"$35,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B7"} value={"Taxable at 10%"} editable={false} />
          <Cell cellName={"C7"} value={"$9,950"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B8"} value={"Taxable at 12%"} editable={false} />
          <Cell cellName={"C8"} value={"$25,050"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B10"} value={"Taxes due at 10%"} editable={false} />
          <Cell cellName={"C10"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "995",
    range: "C10",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Now type <span style={styles.specialCharacter}>Taxes due at 12%</span> on cell <span style={styles.specialCharacter}>B11</span>. This is your income in between $9,951 and $40,525. You don't reach the upper limit, so you only get taxes on $25,050.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} value={"$10,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B5"} value={"Taxable Income"} editable={false} />
          <Cell cellName={"C5"} value={"$35,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B7"} value={"Taxable at 10%"} editable={false} />
          <Cell cellName={"C7"} value={"$9,950"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B8"} value={"Taxable at 12%"} editable={false} />
          <Cell cellName={"C8"} value={"$25,050"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B10"} value={"Taxes due at 10%"} editable={false} />
          <Cell cellName={"C10"} value={"$995"} editable={false} />
        </div>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B11"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "taxes due at 12%",
    range: "B11",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Now do the same as Taxes Due at 10% but with Taxes Due at 12% on cell <span style={styles.specialCharacter}>C11</span>. Use the same formula as cell C10 but with the correct range and % values</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} value={"$10,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B5"} value={"Taxable Income"} editable={false} />
          <Cell cellName={"C5"} value={"$35,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B7"} value={"Taxable at 10%"} editable={false} />
          <Cell cellName={"C7"} value={"$9,950"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B8"} value={"Taxable at 12%"} editable={false} />
          <Cell cellName={"C8"} value={"$25,050"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B10"} value={"Taxes due at 10%"} editable={false} />
          <Cell cellName={"C10"} value={"$995"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B11"} value={"Taxes due at 12%"} editable={false} />
          <Cell cellName={"C11"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "3006",
    range: "C11",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Awesome job, now you have a headache and also have identified how much money you're sending over to the government in taxes. Let's calculate total taxes. Type <span style={styles.specialCharacter}>Total taxes due</span> on cell <span style={styles.specialCharacter}>B13</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} value={"$10,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B5"} value={"Taxable Income"} editable={false} />
          <Cell cellName={"C5"} value={"$35,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B7"} value={"Taxable at 10%"} editable={false} />
          <Cell cellName={"C7"} value={"$9,950"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B8"} value={"Taxable at 12%"} editable={false} />
          <Cell cellName={"C8"} value={"$25,050"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B10"} value={"Taxes due at 10%"} editable={false} />
          <Cell cellName={"C10"} value={"$995"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B11"} value={"Taxes due at 12%"} editable={false} />
          <Cell cellName={"C11"} value={"$3,006"} editable={false} />
        </div>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B13"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "total taxes due",
    range: "B13",
  },
  {
    title: "Tax with Deductions",
    text: <span style={{ fontSize: 16 }}>Finally, add your total taxes on cell <span style={styles.specialCharacter}>C13</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"B2"} value={"Tax with Deductions"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B3"} value={"Income"} editable={false} />
          <Cell cellName={"C3"} value={"$45,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B4"} value={"Deductions"} editable={false} />
          <Cell cellName={"C4"} value={"$10,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B5"} value={"Taxable Income"} editable={false} />
          <Cell cellName={"C5"} value={"$35,000"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B7"} value={"Taxable at 10%"} editable={false} />
          <Cell cellName={"C7"} value={"$9,950"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B8"} value={"Taxable at 12%"} editable={false} />
          <Cell cellName={"C8"} value={"$25,050"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B10"} value={"Taxes due at 10%"} editable={false} />
          <Cell cellName={"C10"} value={"$995"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B11"} value={"Taxes due at 12%"} editable={false} />
          <Cell cellName={"C11"} value={"$3,006"} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"B13"} value={"Total taxes due"} editable={false} />
          <Cell cellName={"C13"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "4001",
    range: "C13",
  },
  {
    title: "Congratulations!",
    finished: true,
    text: <span style={{ fontSize: 16 }}>You finished the Taxes II interactive lesson!</span>,
  }
]

