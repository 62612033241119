import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthUserContext } from "../../Session";
import Spin from "antd/es/spin";
import "../index.css";
import { toast } from "react-toastify";
import { sendOneEmail } from "src/api/emails";
import Compressor from "compressorjs";
import { auth } from "firebase";
import firebase from "firebase/app";

export const FinancialAidRequestModal = ({ setFinancialAidModal }) => {
  const getBlobFromImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const blob = new Blob([reader.result], { type: file.type });
        resolve(blob);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const [loader, setLoader] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const authUser = useContext(AuthUserContext);
  const history = useHistory();

  const uploadAndGetFileUrl = async (imageFile) => {
    // Send the compressed image file to server with XMLHttpRequest.
    return firebase
      .storage()
      .ref(`diversionAidRequest/${authUser.uid}/${imageFile.name}`)
      .put(imageFile)
      .then(() => {
        console.log("Text 27 | uploaded successfully");
        return firebase
          .storage()
          .ref(`diversionAidRequest/${authUser.uid}/${imageFile.name}`)
          .getDownloadURL()
          .then((url) => {
            console.log("Text 31 | got url", url);
            return url;
          })
          .catch((error) => {
            console.log("Text 54 | ", "ERROR", error);
            throw new Error("Unable to get file url", error.message);
          });
      })
      .catch((error) => {
        throw new Error("Unable to get file url", error.message);
      });
  };

  const handleRequest = async () => {
    setLoader(true);
    if (selectedImage) {
      new Compressor(selectedImage, {
        quality: 0.8,
        success(result) {
          console.log("Text 59 | handling image upload");
          const formData = new FormData();
          formData.append("image", result);
          uploadAndGetFileUrl(result)
            .then((url) => {
              setImageUrl(url);
              sendOneEmail(
                "sven@stoprecidivism.org",
                "New Financial Aid Request",
                authUser.displayName +
                  " has requested financial aid, here is the link to the file attached:" +
                  url,
                "Sven"
              );
              setLoader(false);
              setFinancialAidModal(false);
              toast.success("Final assessment Updated Successfully!", {
                position: "top-center",
              });
            })
            .catch((error) => {
              console.log("Text 57 | ", "ERROR", error);
              toast("Issue with image upload, contact support.");
              setLoader(false);
              setFinancialAidModal(false);
            });
        },
        error(err) {
          console.log(err.message);
          setFinancialAidModal(false);
        },
      });
    } else {
      toast("No image selected, please select an image to continue");
      setLoader(false);
      return;
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  return (
    <div className="preAdjudication__modal">
      <div
        className="preAdjudication__modal__container"
        style={{ minHeight: 0 }}
      >
        <div style={{ textAlign: "center" }}>
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 10,
                zIndex: 1000,
                cursor: "pointer",
              }}
              onClick={() => setFinancialAidModal(false)}
            >
              X
            </div>
          </div>
          <div style={{ fontWeight: "bold" }}>Request for financial aid</div>
          <div>
            <div style={{ fontSize: 16, marginTop: 20, marginBottom: 20 }}>
              {" "}
              Please attach an image showing that you need financial aid{" "}
            </div>
            <input type="file" accept="image/*" onChange={handleImageChange} />
          </div>
        </div>
        <div className="preAdjudicationButton__container">
          <button onClick={handleRequest}>
            {loader ? "... Loading" : "Request"}
          </button>
        </div>
      </div>
    </div>
  );
};
