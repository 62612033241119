import React, { useEffect } from "react";
import "./index.css";

export const ProgressBarCompare = ({
  progress,
  progressBefore,
  total,
  backgroundColor = "",
  height = 20,
  showLabel,
}) => {
  useEffect(() => {}, [progress, progressBefore, total, showLabel]);

  const difference = progress - progressBefore;
  const plusOrMinus = difference > 0 ? "+" : "-";

  return (
    <div
      className="progress md-progress"
      style={{
        height: `${height}px`,
        width: "100%",
        backgroundColor,
      }}
    >
      <ProgressLine
        width={progress}
        title={
          showLabel
            ? `${plusOrMinus} ${parseFloat(progress - progressBefore).toFixed(
                0
              )}`
            : ""
        }
        showLabel={showLabel}
      />
    </div>
  );
};

const ProgressLine = ({ title, width, showLabel }) => {
  const color = () => {
    if (width < 35) return "red";
    if (width >= 35 && width < 59) return "yellow";
    return "green";
  };

  useEffect(() => {}, [showLabel]);

  return (
    <div
      className="progress-bar"
      role="progressbar"
      style={{
        width: `${width}%`,
        height: `20px`,
        backgroundColor: color(),
        opacity: 0.7,
        border: "1px solid lightgray",
      }}
      aria-valuemin="1"
      aria-valuemax={100}
    >
      {showLabel ? title : ""}
    </div>
  );
};

export default ProgressBarCompare;
