import React, { Component } from 'react';
import Button from '../shared/Button';
import { withFirebase } from "../Firebase";
import { compose } from "recompose";

class ValidateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      validationMessage: null,
    };
  }

  // Placeholder for your validation logic
  validateUser = async () => {
    const { inputValue } = this.state;

    try {
      const userData = await this.props.firebase.user(inputValue).once("value");
      console.log("userData", userData.val());

      const userExists = !!userData.val(); // Check if user data exists

      if (userExists) {
        this.setState({
          validationMessage: { message: `User ${userData.val().displayName} validated successfully!`, isSuccess: true },
        });
      } else {
        this.setState({
          validationMessage: { message: 'User not found!', isSuccess: false },
        });
      }
    } catch (error) {
      console.error("Error validating user:", error);
      this.setState({
        validationMessage: { message: 'Error validating user.', isSuccess: false },
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  render() {
    const { inputValue, validationMessage } = this.state;

    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h3>Validate the user's transcript here</h3>
        <input
          type="text"
          placeholder="Enter the Student ID"
          value={inputValue}
          onChange={this.handleInputChange}
          style={{ padding: '10px', marginRight: '10px', fontSize: '10px', minWidth: 150 }}
        />
        <Button title={"Validate"} onClick={this.validateUser} />

        {validationMessage && (
          <p
            style={{
              marginTop: '20px',
              color: validationMessage.isSuccess ? 'green' : 'red',
              fontSize: '18px',
              fontWeight: 'bold'
            }}
          >
            {validationMessage.message}
          </p>
        )}
      </div>
    );
  }
}

export default compose(
  withFirebase
  // withAuthorization(condition),
  // withAuthentication
)(ValidateUser);
