// Initial State
const initialState = {
  step: 0,
  newInputName: '',
  selectedQuestions: [],
  preFilledRecords: [],
  selectedPreFilledQuestion: null,
  editingIndex: null,
};

// Reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "preFillModal/setStep":
      return { ...state, step: payload };
    case "preFillModal/setNewInputName":
      return { ...state, newInputName: payload };
    case "preFillModal/setSelectedQuestions":
      return { ...state, selectedQuestions: Array.isArray(payload) ? payload : [] };
    case "preFillModal/setPreFilledRecords":
      return { ...state, preFilledRecords: payload };
    case "preFillModal/setSelectedPreFilledQuestion":
      return { ...state, selectedPreFilledQuestion: payload };
    case "preFillModal/setEditingIndex":
      return { ...state, editingIndex: payload };
    default:
      return state;
  }
};
