import React from "react";
import "./index.css";

const DebtInitialModal = (props) => {
  if (props.debtInitialModalVisible) {
    return (
      <div className="debt-next-month-instructions">
        <h5>Start the month by adding the interest</h5>
        <button className="add-interest-button" onClick={props._debtPaid}>
          Add Interest
        </button>
      </div>
    );
  } else {
    return null;
  }
};

export default DebtInitialModal;
