import React, { useState } from "react";
import "./index.css";
import Input from "src/components/shared/Input";
import Button from "src/components/shared/Button";
import { toUpperCaseString } from "src/utils/utils";

export const ConfirmLegalNameModal = ({
  open,
  displayName,
  firebase,
  uid,
  onConfirm,
  setLegalNameConfirmOpen,
}) => {
  const [editedName, setEditedName] = useState(displayName);
  const [editing, setEditing] = useState(false);

  const handleConfirm = () => {
    console.log("ConfirmLegalName 16 | handling confirmation");
    onConfirm(editedName);
  };

  if (!open) return null;

  return (
    <div className="confirmmodal__container">
      <div className="confirmodal__modal">
        <div
          className="closelegalname__x"
          onClick={() => {
            setLegalNameConfirmOpen(false);
          }}
        >
          X
        </div>
        <div>
          Is the following your <strong>legal name</strong>?
        </div>
        <div className="legalname__name">{editedName}</div>
        <div className="legalname__warning">
          <strong>Legal Validity relies on your name being correct.</strong>
        </div>
        {editing && (
          <div className="editlegalname__container">
            <div className="legalname__info">Legal Name</div>
            <Input
              value={editedName}
              onChange={(e) => {
                setEditedName(e.target.value);
              }}
            />
          </div>
        )}
        <div className="legalnamebuttons__container">
          <Button
            type="outline"
            title={editing ? "Cancel" : "Edit"}
            onClick={() => (!editing ? setEditing(true) : setEditing(false))}
          />
          <Button title="Confirm" onClick={handleConfirm} />
        </div>
      </div>
    </div>
  );
};
