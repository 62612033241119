import React from "react";

const InsuranceSurprise = (props) => {
  const price = 3000;
  const amount =
    props.insurancePlan.deductible > 0 ? props.insurancePlan.deductible : price;
  // const amount = 8000
  const _handleClick = () => {
    props._startEvent(amount, -3);
  };
  return (
    <button className="surprise" onClick={_handleClick}>
      <div className="surprise-text">
        <div>
          <p>
            <strong>EMERGENCY!</strong>
          </p>
          <p>You broke your arm!</p>
          <p>
            Total cost is ${`${price}`}. Based on your insurance plan, you have
            to pay: ${amount}
          </p>
        </div>
        <div>
          <h4>{`$${amount}`}</h4>
          {"☹️ ☹️ ☹️ ️"}
        </div>
      </div>
      <img
        className="surprise-image"
        src="/assets/images/surprise_broken.png"
        alt="broken arm"
      />
    </button>
  );
};

export default InsuranceSurprise;
