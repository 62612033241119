import React from "react";
import "./CreditTask.css";
const CreditTask = ({ taskName }) => {
  return (
    <div className="credittask">
      <div className="task-add-the-container">
        <b>{`Task: `}</b>
        <span style={{ marginLeft: 3 }}>{taskName}</span>
      </div>
    </div>
  );
};

export default CreditTask;
