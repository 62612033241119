import { toast } from "react-toastify";

export const generateTeacherUsers = (teachers) => {
  let teacherUsers = {};
  if (!teachers) return teacherUsers;

  if (teachers) {
    Object.values(teachers).forEach((teacher) => {
      teacherUsers[teacher.uid] = teacher.name;
    });
  }

  return { teacherUsers };
};

export const createMessageObject = (message, senderId, chatId) => {
  return {
    message,
    sender: senderId,
    date: Date.now(),
    chatId,
    read: false,
  };
};

export const createPushMessageBody = (authUser, newMessage, expoPushToken) => {
  return {
    to: expoPushToken,
    sound: "default",
    title: `${authUser.displayName || "RED"} sent you a message`,
    body: newMessage,
    data: { screen: "ChatMenu" },
  };
};

export const sendPushMessage = async (messageBody) => {
  await fetch("https://exp.host/--/api/v2/push/send", {
    method: "POST",
    mode: "no-cors",
    headers: {
      Accept: "application/json",
      "Accept-encoding": "gzip, deflate",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(messageBody),
  });
};

export const sendPushNotificationToUserDevice = async (
  userId,
  firebase,
  authUser,
  newMessage,
  contactId
) => {
  try {
    const request = await firebase
      .getPushNotificationTokens(contactId)
      .once("value");
    if (request.val()) {
      const messageBody = {
        to: request.val().expoPushToken,
        sound: "default",
        title: `${
          authUser.displayName || authUser.displayName || "User"
        } sent you a message`,
        body: newMessage,
        data: { screen: "ChatMenu" },
      };

      return fetch("https://exp.host/--/api/v2/push/send", {
        method: "POST",
        mode: "no-cors",
        headers: {
          Accept: "application/json",
          "Accept-encoding": "gzip, deflate",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageBody),
      });
    } else {
      console.log("SingleChatOpen.js 67 | no tokens");
    }
  } catch (error) {
    console.log("SingleChatOpen.js 98 | error sending", error.message);
  }
  console.log("SingleChatOpen.js 63 | sending push notification", userId);
};
