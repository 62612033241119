import React from "react";
import { Doughnut } from "react-chartjs-2";

const Category = ({ categoryName, categoryDescription, categoryScore = 0 }) => {
  const color =
    categoryScore >= 50
      ? ["rgba(55, 239, 55, 1)", "rgba(228, 232, 235, 1)"]
      : ["rgba(239, 55, 55, 1)", "rgba(228, 232, 235, 1)"];
  return (
    <div className="self-awareness-parent">
      <div className="self-awareness">{categoryName}</div>
      <div className="frame-container">
        <div className="frame-icon" style={{ maxWidth: 350 }}>
          <Doughnut
            data={{
              datasets: [
                {
                  label: "Score: ",
                  data: [categoryScore, 100 - categoryScore],
                  backgroundColor: color,
                },
              ],
            }}
            options={{
              rotation: 1 * Math.PI,
              circumference: 1 * Math.PI,
              // responsive: true,
              maintainAspectRatio: false,
              tooltips: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    let label =
                      data.datasets[tooltipItem.datasetIndex].label || "";
                    if (label) {
                      label += ": ";
                    }
                    label += Math.round(tooltipItem.yLabel * 100) / 100;
                    return Math.floor(categoryScore);
                  },
                },
              },
            }}
          />
        </div>
        <div className="notification1">
          <div className="modal-header1">
            <div className="lorem-ipsum-dolor1">{`${categoryDescription}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
