import React from "react";

const CarSurprise = (props) => {
  // Gets number between min and max
  const amount = Math.floor(Math.random() * 200 + 100);
  const _handleClick = () => {
    props._startEvent(amount, -3);
  };

  return (
    <button className="surprise" onClick={_handleClick}>
      <div className="surprise-text">
        <div>
          <p>
            <strong>EMERGENCY!</strong>
          </p>
          <p>You crashed your car!</p>
          <p>You must pay the mechanic:</p>
        </div>
        <div>
          <h4>{`$${amount}`}</h4>
          <h3>{"☹️ ☹️ ☹️ ️"}</h3>
        </div>
      </div>
      <img
        className="surprise-image"
        src="/assets/images/surprise_crash.png"
        alt="car crash"
      />
    </button>
  );
};

export default CarSurprise;
