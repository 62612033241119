import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { WordsContainer } from "./WordsContainer";
import DefinitionsContainer from "./DefinitionsContainer";

import "./index.css";

function shuffle(array) {
  // console.log("DragDrop 8 | shuffling");
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

class DragDrop extends React.Component {
  state = {
    data: { ...this.props.data },
    words: [],
    definitions: [],
  };

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidMount() {
    console.log("DragDrop 30 | this.props.data", this.state.data);
    this.shuffleWordsAndDefs();
  }

  shuffleWordsAndDefs = () => {
    // console.log("DragDrop 64 | shuffling words");
    let words = [];
    let definitions = [];

    this.state.data.wordsAndDefs.forEach((wordAndDef, index) => {
      const { word, definition, enabled } = wordAndDef;
      words.push({ word, index });
      definitions.push({ definition, index, word, show: true });
    });

    let shuffledWords = shuffle([...words]);
    this.setState({ words: shuffledWords, definitions });
  };

  onDragEnd = (result) => {
    //Checks initial word and droppable, if the words match then disable the word in the draggable and enable it on the droppable

    const { words, definitions } = this.state;
    const { draggableId, destination } = result;

    if (
      !draggableId ||
      !destination ||
      destination.droppableId === "words-id"
    ) {
      console.log("DragDrop 59 | incorrect match");
      return;
    }

    const sourceIndex = parseInt(draggableId);
    const destinationIndex = parseInt(destination.droppableId);

    const sourceWord = words[sourceIndex].word;
    let destinationWord = definitions[destinationIndex].word;

    if (sourceWord === destinationWord) {
      words[sourceIndex].show = false;

      definitions[destinationIndex].show = false;

      this.setState(
        {
          words,
          definitions,
        },
        () => this.handleComplete()
      );
    } else {
      return;
    }
  };

  handleComplete = () => {
    const numberOfShows = this.state.definitions.filter(
      (definition) => definition.show === false
    ).length;

    const totalShows = this.state.definitions.filter(
      (definition) => definition.word !== ""
    ).length;

    this.props.checkDragDrop(numberOfShows, totalShows);
  };

  onDragUpdate = (result) => {
    // const { destination, source, draggableId, type } = result;
  };

  render() {
    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragUpdate={this.onDragUpdate}
      >
        <div className="drag-drop-context-container">
          <DefinitionsContainer
            definitions={this.state.definitions}
            dragAndDropComplete={this.props.dragAndDropComplete}
            lessonComplete={this.props.lessonComplete}
          />
          <WordsContainer
            words={this.state.words}
            dragAndDropComplete={this.props.dragAndDropComplete}
            lessonComplete={this.props.lessonComplete}
          />
        </div>
      </DragDropContext>
    );
  }
}

export { DragDrop };
