const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/red-platform/us-central1"
    : "https://us-central1-red-platform.cloudfunctions.net";

export const generateAuthLink = async (loginType, classId) => {
  try {
    const request = await fetch(`${API_URL}/authLink`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ loginType, classId }),
    });
    const data = await request.json();
    console.log("google.js 16 | data on client", data);
    return data;
  } catch (error) {
    console.log("google.js 19 | error", error.message);
    throw new Error(error);
  }
};

export const getTokens = async (code) => {
  try {
    const request = await fetch(`${API_URL}/getTokens`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: code,
      }),
    });
    const data = await request.json();

    return data;
  } catch (error) {
    console.log("quest.js 21 | error", error.message);
    throw new Error(error);
  }
};

export const getNewAccessToken = async () => {};
