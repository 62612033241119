import React from "react";
import DebtSheet from "../DebtComponents/DebtSheet/DebtSheet";

import {
  _lookForSheet,
  // _createSheet,
  _checkSingleCell,
  _clearSheetValues,
  // _updateFrozenRow,
  _updateValuesRange,
  _formatValue,
  _updateColumnRange,
  _batchUpdateSingleValue,
  _formatWrongRange,
  _formatRange,
} from "../sheetsFunctions/sheetsFunctions";
import "./DebtContainer.css";
// import { debts } from "../Data/DebtData";
import DebtMonthModal from "../DebtComponents/DebtMonthModal/DebtMonthModal";
import DebtPaidModal from "../DebtComponents/DebtPaidModal/DebtPaidModal";
import DebtWinModal from "../DebtComponents/DebtWinModal/DebtWinModal";
import DebtChart from "../DebtComponents/DebtChart/DebtChart";

import DebtGame from "../DebtComponents/DebtGame/DebtGame";
import DebtSelectionModal from "../DebtComponents/DebtSelectionModal/DebtSelectionModal";
import DebtInitialModal from "../DebtComponents/DebtInitialModal/DebtInitialModal";

import { Helmet } from "react-helmet";
import {
  openNotEnoughPaidNotification,
  openNotification,
} from "../DebtComponents/DebtNotifications";
import DebtTracker from "../DebtComponents/DebtTracker";

class DebtContainer extends React.Component {
  state = {
    title: "Snowball Debt 1.31",
    sheetId: null,
    simulationIndex: 0,
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    currentDebt: [],

    totalDebt: 0,
    monthlySavings: null,
    chartData: [],
    chartLabels: [],
    interestOrPrincipal: 0,

    startButtonVisible: true,
    payButtonVisible: false,
    debtMonthModalVisible: false,
    monthStartButtonVisible: false,
    debtPaidModalVisible: false,
    enoughMinPaymentVisible: false,
    debtSelectionModalVisible: false,
    debtInitialModalVisible: false,

    maxMonthsToPay: 6,
  };

  async componentDidMount() {
    console.log("Debt Component did mount");

    this._createOrGetSheet();
    this._getMonthlySavings();
    // const totalDebt = this._getTotalDebt();
    console.log("user data DebtContainer 57", this.props.userData);
  }

  _getMonthlySavings = () => {
    // Get average monthly savings from BUDGETING GAME

    this.setState(
      {
        monthlySavings: this.props.debtData,
      },
      () => console.log("Monthly savings", this.state.monthlySavings)
    );
  };

  _createOrGetSheet = () => {
    //gets ID from localstorage or google, sets it to state
    console.log("DebtContainer.js 92 | creating or getting sheet");
    if (localStorage.getItem("debtFileId")) {
      const sheetId = localStorage.getItem("debtFileId").substr(1).slice(0, -1);
      this._startSetup(sheetId);
    } else {
      _lookForSheet(this.state.title, this._startSetup); //this looks for the sheet in google and creates it or gets it
    }
  };

  _startSetup = (sheetId) => {
    this.setState({ sheetId: sheetId }, () => {
      _clearSheetValues(this.props.token, this.state.sheetId, "A1:J15");
    });
  };

  _startGame = (debts) => {
    console.log("debts passed", debts);
    this.setState(
      {
        payButtonVisible: false,
        debtSelectionModalVisible: false,
        debtInitialModalVisible: true,
        debtMonthModalVisible: false,
        currentDebt: debts,
      },
      () => {
        this._setInitialTitleValues();
        this._getTotalDebt();
      }
    );
  };

  _getTotalDebt = () => {
    let total = 0;
    this.state.currentDebt.forEach((debt) => {
      total += debt.amount;
    });

    const chartData = this.state.chartData.concat(total);
    const chartLabels = this.state.chartLabels.concat(
      this.state.months[this.state.simulationIndex]
    );
    this.setState({
      totalDebt: Math.floor(total),
      chartData: chartData,
      chartLabels: chartLabels,
    });
    console.log("Total debt DebtContainer 103", total);
    if (total <= 1) {
      console.log("You won!");
      this.setState({
        winModalVisible: true,
      });
    }
  };

  _setInitialTitleValues = () => {
    const { token } = this.props;
    const { sheetId } = this.state;
    _updateValuesRange(token, sheetId, "A1:D1", [
      "Debt Type",
      "Monthly Interest",
      "Debt Amount",
      "Min Payment",
    ]);
    this._setInitialDebts();
  };

  _setInitialDebts = () => {
    const { token } = this.props;
    const { sheetId } = this.state;
    console.log(
      "DebtContainer.js 165 | current debt set",
      this.state.currentDebt
    );
    this.state.currentDebt.forEach((debt, index) => {
      const { title, interest, amount, minPayment } = debt;
      _updateValuesRange(token, sheetId, `A${index + 2}:D${index + 2}`, [
        title,
        interest,
        amount,
        minPayment,
      ]);
    });
  };

  _selectDebts = () => {
    this.setState({
      debtSelectionModalVisible: true,
      startButtonVisible: false,
    });
  };

  _addInterest = () => {
    const { token } = this.props;
    const { sheetId, simulationIndex } = this.state;

    let endRangeNumberToLetter = String.fromCharCode(
      this.state.simulationIndex + 101
    );
    let colValues = []; //array to update total debts in sheet

    let updatedLoans = []; //array to update state to the current loan value

    const debtsLength = this.state.currentDebt.length;

    _checkSingleCell(
      token,
      sheetId,
      `A2:${endRangeNumberToLetter}${debtsLength + 1}`
    ).then((data) => {
      if (data.values) {
        data.values.forEach((value) => {
          //access to each value returned from db
          const name = value[0];
          const minPayment = value[3];
          const debtAmount = value[2];
          const interest = value[1] / 12;
          console.log(
            "interest",
            interest,
            "value",
            value[2],
            interest * value[2]
          );
          const sheetAmount = value[simulationIndex + 4];
          console.log("sheet amount", sheetAmount);
          const amountToUpdate = debtAmount + interest * debtAmount;
          const totalLoan = value[2] + interest * debtAmount;

          updatedLoans.push({
            title: name,
            interest: value[1],
            amount: amountToUpdate,
            minPayment: minPayment,
            interestPaid: interest * debtAmount,
          });

          colValues.push(totalLoan);
        });
        this.setState(
          {
            currentDebt: updatedLoans,
          },
          () => console.log("updated state", this.state.currentDebt)
        );
        _updateColumnRange(token, sheetId, `C2:C${debtsLength + 1}`, colValues);
        this._getTotalDebt();
      }
    });

    this.setState({
      monthStartButtonVisible: false,
      debtMonthModalVisible: true,
    });
  };

  _setMonth = () => {
    const { token } = this.props;
    const { sheetId, simulationIndex } = this.state;

    _updateColumnRange(token, sheetId, `E1:E4`, ["January", 0, 0, 0, 0]);
    const SUM = "=SUM(E2:E4)";

    _batchUpdateSingleValue(token, sheetId, `E5`, SUM);
  };

  _initialModal = () => {
    this.setState({
      debtInitialModalVisible: true,
      startButtonVisible: false,
    });
  };

  _pay = () => {
    const { token } = this.props;
    const { sheetId, simulationIndex } = this.state;
    let allPaid = true;
    let totalSum = 0;

    let endRangeNumberToLetter = String.fromCharCode(
      this.state.simulationIndex + 101
    );

    function nextChar(c) {
      return String.fromCharCode(c.charCodeAt(0) + 1);
    }

    const debtsLength = this.state.currentDebt.length + 1;
    let updatedDebts = [];
    let updatedCols = [];

    this.setState({ enoughMinPaymentVisible: false });

    // get values from range E2:E4
    _checkSingleCell(
      token,
      sheetId,
      `A2:${endRangeNumberToLetter}${debtsLength}`
    ).then((data) => {
      if (data.values) {
        data.values.forEach((value, index) => {
          //access to each value returned from db
          const name = value[0];
          const currentDebt = value[2];
          const minPayment = value[3];
          const sheetAmount = value[simulationIndex + 4];

          // check that each value is greater than 0
          const currentStateDebt = this.state.currentDebt.filter(
            (portfolioDebt) => {
              return portfolioDebt.title === value[0];
            }
          );

          if (
            sheetAmount < currentStateDebt[0].minPayment &&
            currentStateDebt[0].amount > 0 &&
            currentStateDebt.amount - currentStateDebt !== 0
          ) {
            //not paying enough
            console.log("not paying enough!", currentStateDebt);
            openNotEnoughPaidNotification(name, minPayment);
            allPaid = false;
            this.setState({ enoughMinPaymentVisible: true });
            //update formats of January
            _formatWrongRange(
              token,
              sheetId,
              `${endRangeNumberToLetter}1:${nextChar(
                endRangeNumberToLetter
              )}${debtsLength}`
            );
          } else {
            // create array containing updated state
            let loanToUpdate = {
              title: name,
              interest: currentStateDebt[0].interest,
              amount: currentStateDebt[0].amount - sheetAmount,
              minPayment: 25,
            };
            totalSum += sheetAmount;
            updatedCols.push(currentDebt - sheetAmount);
            updatedDebts.push(loanToUpdate);
          }
        });

        console.log("DebtContainer.js 347 | ");

        if (allPaid && totalSum <= this.props.debtData) {
          console.log("Paid!");
          this.setState(
            {
              enoughMinPaymentVisible: false,
              currentDebt: updatedDebts,
              maxMonthsToPay: this.state.maxMonthsToPay - 1,
            },
            () => {
              console.log(
                "DebtContainer.js 360 | maxMonthsToPay",
                this.state.maxMonthsToPay
              );
              if (this.state.maxMonthsToPay < 1 && this.state.totalDebt >= 0) {
                console.log(
                  "DebtContainer.js 365 | game lost?",
                  this.state.maxMonthsToPay
                );
                this._handleGameLost();
              }
              this._getTotalDebt();
              openNotification("topLeft");
              _formatRange(
                token,
                sheetId,
                `${endRangeNumberToLetter}1:${nextChar(
                  endRangeNumberToLetter
                )}${debtsLength}`
              );
            }
          );
          // update the state with all the updated state values
          //update the sheet with the state values
          _updateColumnRange(token, sheetId, `C2:C${debtsLength}`, updatedCols);

          // console.log("all paid!", updatedDebts);
          this._nextMonth();
        } else {
          console.log(
            "You cant afford that! Sum: ",
            totalSum,
            this.props.debtData
          );
        }
      }
    });

    this._checkForWin();
  };

  _nextMonth = () => {
    this.setState({
      simulationIndex: this.state.simulationIndex + 1,
      debtPaidModalVisible: true,
      payButtonVisible: false,
    });
    this._getTotalDebt();
  };

  _addNextColumn = () => {
    console.log("Add next column");
    const { token } = this.props;
    const { sheetId, simulationIndex } = this.state;
    let endRangeNumberToLetter = String.fromCharCode(
      this.state.simulationIndex + 101
    ); //  e
    const debtsLength = this.state.currentDebt.length + 1;

    let arrayForSheets = [this.state.months[simulationIndex]];
    for (let index = 0; index < debtsLength - 1; index++) {
      arrayForSheets.push(0);
    }
    console.log("Debt Container 289");
    _updateColumnRange(
      token,
      sheetId,
      `${endRangeNumberToLetter}1:${endRangeNumberToLetter}${debtsLength}`,
      arrayForSheets
    );
    _formatValue(
      token,
      sheetId,
      `${endRangeNumberToLetter}1:${endRangeNumberToLetter}${debtsLength}`
    );
    this.setState({ debtMonthModalVisible: false, payButtonVisible: true });
  };

  _debtPaid = () => {
    this.setState({
      debtPaidModalVisible: false,
      debtMonthModalVisible: true,
      debtInitialModalVisible: false,
    });
    this._addInterest();
  };

  _checkForWin = () => {
    if (this.state.totalLoan <= 0) {
      this.setState({
        winModalVisible: true,
      });
    }
  };

  _handleGameLost = () => {
    this.setState({
      gameLost: true,
    });
  };

  _closeGameLostModal = () => {
    console.log("DebtContainer.js 468 | ");
    this.setState(
      {
        gameLost: false,
        totalDebt: 0,
        // monthlySavings: null,
        chartData: [],
        chartLabels: [],
        interestOrPrincipal: 0,
        simulationIndex: 0,
        currentDebt: [],
        maxMonthsToPay: 6,

        startButtonVisible: true,
        payButtonVisible: false,
        debtMonthModalVisible: false,
        monthStartButtonVisible: false,
        debtPaidModalVisible: false,
        enoughMinPaymentVisible: false,
        debtSelectionModalVisible: false,
        debtInitialModalVisible: false,
        winModalVisible: false,
      },
      async () => {
        return _clearSheetValues(this.props.token, this.state.sheetId, "A1:J15")
          .then(() => {
            console.log("DebtContainer.js 484 | cleared sheet");
          })
          .catch((error) => {
            console.log(
              "DebtContainer.js 490 | error clearing sheet",
              "ERROR",
              error
            );
          });
      }
    );
  };

  render() {
    return (
      <div className="debt-container">
        <Helmet>
          <title>Debt</title>
          <meta
            name="description"
            content="Understand how debt works in this interactive simulation."
          />
        </Helmet>
        <div className="info">
          <div className="game">
            <DebtTracker
              totalDebt={this.state.totalDebt}
              debtData={this.props.debtData}
              monthsLeftToPay={this.state.maxMonthsToPay}
            />
            <DebtWinModal
              winModalVisible={this.state.winModalVisible}
              handleNextButtonPressed={this.props.handleNextButtonPressed}
              restart={this._closeGameLostModal}
            />
            <DebtGame
              _initialModal={this._initialModal}
              _selectDebts={this._selectDebts}
              currentDebt={this.state}
              userData={this.props.userData}
              _startGame={this._startGame}
              _addInterest={this._addInterest}
              startButtonVisible={this.state.startButtonVisible}
              payButtonVisible={this.state.payButtonVisible}
              pay={this._pay}
              _debtPaid={this._debtPaid}
              currentMonth={this.state.months[this.state.simulationIndex]}
              debtPaidModalVisible={this.state.debtPaidModalVisible}
              monthStartButtonVisible={this.state.monthStartButtonVisible}
              enoughMinPaymentVisible={this.state.enoughMinPaymentVisible}
            />

            <DebtInitialModal
              debtInitialModalVisible={this.state.debtInitialModalVisible}
              _debtPaid={this._debtPaid}
            />
            <DebtPaidModal
              _debtPaid={this._debtPaid}
              debtPaidModalVisible={this.state.debtPaidModalVisible}
              winModalVisible={this.state.winModalVisible}
            />
            <DebtMonthModal
              debtMonthModalVisible={this.state.debtMonthModalVisible}
              addNextColumn={this._addNextColumn}
              currentMonth={this.state.months[this.state.simulationIndex]}
            />
            <DebtSelectionModal
              _startGame={this._startGame}
              debtSelectionModalVisible={this.state.debtSelectionModalVisible}
            />
            {this.state.gameLost ? (
              <div className="game-lost-modal">
                <h5>Game Lost! You didn't pay on time</h5>
                <button onClick={this._closeGameLostModal}>Restart Game</button>
              </div>
            ) : null}
          </div>
          <div className="debt-chart">
            <DebtChart
              chartType="Line"
              chartTitle="Debt Chart"
              chartLabels={this.state.chartLabels}
              chartData={this.state.chartData}
              responsive={true}
              aspectRatio={true}
            />
          </div>
        </div>

        <div className="sheet">
          <DebtSheet sheetId={this.state.sheetId} />
        </div>
      </div>
    );
  }
}

export default DebtContainer;
