import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import "./SignIn.css";
//import LoginModal from "../LoginModal";

const SignInPage = () => (
  <>
    {/* <LoginModal /> */}
  </>
);

class SignInWithGoogleBase extends React.Component {
  state = {
    user: null,
    error: null,
  };

  onSubmit = (event) => {
    console.log("SignIn 107 | submitting login");
    this.props.firebase
      .signInWithGoogle()
      .then((socialAuthUser) => {
        return this.props.firebase
          .user(socialAuthUser.user.uid)
          .update({
            username: socialAuthUser.user.displayName,
            googleSignIn: true,
            email: socialAuthUser.user.email,
            lastLogin: Date.now(),
          })
          .then(() => {
            this.setState({ error: null });
            console.log("SignIn 110 | social auth user", socialAuthUser);
            localStorage.setItem(
              "token",
              socialAuthUser.credential.accessToken
            );

            this.props.history.push(ROUTES.HOME);
          })
          .catch((error) => {
            console.log("SignIn 127 | ", "ERROR", error);
          });
      })
      .catch((error) => this.setState({ error }));
    event.preventDefault();
  };

  render() {
    const { error } = this.state;
    return (
      <form className="google-form" onSubmit={this.onSubmit}>
        <img
          className="google-img"
          onClick={this.onSubmit}
          src="./images/btn_google_signin_dark_pressed_web@2x.png"
          alt=""
        />
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInGoogle = compose(withFirebase, withRouter)(SignInWithGoogleBase);

export default SignInPage;

export { SignInGoogle };
