export const transcriptData = (userData) => {
  return {
    red_address: "75 Wieuca RD. NE, Atlanta, GA 30342",
    red_phone: "+1 4044879501",
    red_website: "www.stoprecidivism.org",
    user_email: userData?.email || "No user email",
    studentId: userData?.uid || "No user ID",
    user_dob: userData?.dob || "No user age",
    user_address: userData?.address || "No user address",
    user_enrollment_date: userData?.enrollmentDate || "No user enrollment date",
    degree: userData?.degree || "No degree",
    program_name: userData?.programName || "No program name",
    program_accreditation:
      "RED Ed. is accredited by COGNIA, the largest community of education professionals in the world. COGNIA is a non-profit, non-governmental organization that accredits primary and secondary schools, colleges, and universities.",
    program_academic_calendar_and_credits:
      "RED Ed. operates on a semester system. Each semester is 16 weeks long. RED Ed. offers a variety of programs, each with a different number of credits.",
    program_graduation_requirements:
      "To graduate from RED Ed., students must complete all required courses and maintain a minimum GPA of 3.0.",
    grading_system:
      "RED Ed. uses a 4.0 grading scale. The grades are as follows: A (4.0), B (3.0), C (2.0), D (1.0), F (0.0).",
    grades: {
      title: "Grades",
      grading: [
        {
          grade: "A",
          description:
            "High involvement, substantive participation, exceeded expectations.",
        },
        {
          grade: "B",
          description:
            "Good involvement, substantive participation, met expectations.",
        },
        {
          grade: "C",
          description:
            "Satisfactory involvement, minimal participation, met expectations.",
        },
        {
          grade: "D",
          description:
            "Minimal involvement, minimal participation, below expectations.",
        },
        {
          grade: "F",
          description:
            "No involvement, no participation, did not meet expectations.",
        },
      ],
    },
  };
};


export const quarters = [
  {
    title: "First Quarter 2024",
    courses: [
      {
        id: "-M9Ym0Bs7mtcVB70vhOp",
        name: "Emotional Intelligence Part 1",
        edd: "0001",
        score: "4.0",
        completion: "80%"
      }
    ],
    termTotal: "4.0",
    total: "4.0"
  },
  {
    title: "Second Quarter 2024",
    courses: [
      {
        id: "-Mamdy6L9w9dcl6fGQrc",
        name: "Emotional Intelligence Part 2",
        edd: "0002",
        score: "4.0",
        completion: "80%"
      },
      {
        id: "-M9Ym0Bs7mtcVB70vhOm",
        name: "Civic Education",
        edd: "0003",
        score: "4.0",
        completion: "80%"
      },
      {
        id: "-NMnWvBrzKZOdOVwmsis",
        name: "Entrepreneurship",
        edd: "0004",
        score: "4.0",
        completion: "80%"
      }
    ],
    termTotal: "12.0",
    total: "16.0"
  },
  {
    title: "Third Quarter 2024",
    courses: [
      {
        id: "-M9Ym0Bs7mtcVB70vhOu",
        name: "Career Readiness",
        edd: "0005",
        score: "4.0",
        completion: "80%"
      },
      {
        id: "-M9Ym0Bs7mtcVB70vhOt",
        name: "Finance Fundamentals",
        edd: "0006",
        score: "4.0",
        completion: "80%"
      }
    ],
    termTotal: "8.0",
    total: "24.0"
  },
  {
    title: "Fourth Quarter 2024",
    courses: [
      {
        id: "-M9Ym0Bs7mtcVB70vhOs",
        name: "Substance Use Disorder and Addiction",
        edd: "0007",
        score: "4.0",
        completion: "80%"
      },
      {
        id: "-M9Ym0Bs7mtcVB70vhOr",
        name: "Health & Fitness",
        edd: "0008",
        score: "4.0",
        completion: "80%"
      },
      {
        id: "-M9Ym0Bs7mtcVB70vhOn",
        name: "Bridge The Gap",
        edd: "0009",
        score: "4.0",
        completion: "80%"
      },
      {
        id: "-M9Ym0BtgXOUqnDqCdFg",
        name: "Record Restriction",
        edd: "0010",
        score: "4.0",
        completion: "80%"
      }
    ],
    termTotal: "16.0",
    total: "40.0"
  }
];
