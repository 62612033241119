import React from "react";
import { Offline, Online } from "react-detect-offline";
import { toast } from "react-toastify";
import ModalWrapper from "../shared/ModalWrapper";

const OfflineChecker = () => {
  return <Offline></Offline>;
};

export default OfflineChecker;
