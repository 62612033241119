import React, { useState, useEffect } from "react";
import Button from "src/components/shared/Button";
import { FirebaseContext } from "src/components/Firebase";  // Add this import

export const Event = ({ day, month, startHour, endHour, authUser, event }) => {
  const firebase = React.useContext(FirebaseContext);  // Add this line


  console.log("event nuevero", event);
  const { title, description, attendees } = event[1];
  const [eventConfirmationState, setEventConfirmationState] = useState('no_reminder');

  const handleConfirmToken = async (userUid, eventUid) => {
    try {
      const snapshot = await firebase
        .getEventData(eventUid)
        .child(`attendees/${userUid}/reminderConfirmed`)
        .once("value");

      if (snapshot.exists()) {
        const reminders = snapshot.val();
        console.log('Reminders:', reminders);
        let keyToUpdate = null;

        // Iterate over the keys to find the reminder with format 'push'
        for (const key in reminders) {
          console.log('Reminder:', key, reminders[key]);
          keyToUpdate = key;
          break;
        }

        console.log('Key to update:', keyToUpdate);
        if (keyToUpdate) {
          // Update the confirmed status to true
          const path = `attendees/${userUid}/reminderConfirmed/${keyToUpdate}`;
          await firebase
            .getEventData(eventUid)
            .update({
              [path]: { confirmed: true },
            });
          console.log('Token confirmed:', path);
          return { success: true, message: 'Token confirmed successfully' };
        } else {
          console.log('No matching token found');
          return { success: false, message: 'No matching token found' };
        }
      } else {
        console.log('No reminders found');
        return { success: false, message: 'No reminders found' };
      }
    } catch (error) {
      console.error('Error confirming token:', error);
      return { success: false, message: 'Error confirming token' };
    }
  };

  const checkReminderConfirmed = (remindersObject) => {
    let reminderConfirmed = false;
    if (!remindersObject) {
      reminderConfirmed = false;
    } else {
      Object.values(remindersObject).forEach((e) => {
        if (e.confirmed === true) {
          reminderConfirmed = true;
        }
      });
    }
    return reminderConfirmed;
  };


  useEffect(() => {
    if (!event) return; // Early return if there is no next event
    console.log("useffect", event[1]?.attendees, 'authUser?.dbData?.uid', authUser.uid);
    const reminderConfirmedObject = event[1]?.attendees?.[authUser.uid]?.reminderConfirmed;
    if (reminderConfirmedObject) {
      let userConfirmed = checkReminderConfirmed(reminderConfirmedObject);
      if (userConfirmed) {
        setEventConfirmationState('confirmed');
      } else {
        setEventConfirmationState('not_confirmed');
      }
    }
  }, [event]);

  if (!firebase) {
    console.error('Firebase instance is missing');
    return null;
  }

  if (attendees?.length > 0) {
    if (!attendees.includes((attendee) => attendee.email === authUser.email)) {
      return null;
    }
  }


  const handleConfirmEvent = async () => {
    await handleConfirmToken(authUser.uid, event[0]);
    setEventConfirmationState('confirmed');
  };

  return (
    <div className="upcomingevents__card__container">
      <div className="upcomingevents__content">
        <div className="upcomingevents__datecontainer">
          <div className="upcomingevents__day">{day}</div>
          <div className="upcomingevents__month">{month?.slice(0, 3)}</div>

        </div>
        <div className="upcomingevents__infocontainer">
          <div className="upcomingevents__name">{title}</div>
          <div className="upcomingevents__description">
            {description || event.location}
          </div>
        </div>
        <div className="upcomingevents__duedatecontainer">
          {startHour} - {endHour}
        </div>
        {eventConfirmationState === 'confirmed' && (
          <div
            style={{
              backgroundColor: '#00BA8830',
              marginLeft: '2px',
              padding: '5px',
              borderRadius: '10px',
              textAlign: 'center',
            }}
          >
            <span style={{ color: '#00BA88', fontSize: '12px' }}>Confirmed</span>
          </div>
        )}
      </div>
      {eventConfirmationState === 'not_confirmed' && (
        <div className="upcomingevents__button-container">
          <Button title="Confirm attendance" type="outline" onClick={handleConfirmEvent} />
        </div>
      )}
    </div>
  );
};

export default Event;
