import React, { useContext, useEffect, useState, useRef } from "react";
import "./index.css";
import { AuthUserContext } from "src/components/Session";
import { toast } from "react-toastify";
import {
  createMessageObject,
  createPushMessageBody,
  sendPushMessage,
  sendPushNotificationToUserDevice,
} from "../utils";

const SingleChatOpen = ({
  users,
  classId,
  chatId,
  contactName,
  firebase,
  setActiveChat,
  isGroupChat,
}) => {
  const [chats, setChats] = useState([]);
  const [newMessage, setNewMessage] = useState(""); // State to hold the new message input
  const authUser = useContext(AuthUserContext);
  const scrollViewRef = useRef();
  const [groupTokens, setGroupTokens] = useState(null);

  const [sendingUserPush, setSendingUserPush] = useState(false);
  const [userPushSentSuccessful, setUserPushSentSuccessful] = useState(false);

  const contactId = getContactId(chatId, authUser.uid, isGroupChat);

  useEffect(() => {
    console.log("SingleChatOpen.js 34 | There is a current chat id");
  }, [chatId]);

  // real time messages handler
  useEffect(() => {
    const chatRef = firebase.chatMessages(classId, chatId);
    const unsubscribe = chatRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        setChats(snapshot.val());
      } else {
        setChats([]);
      }
    });

    return () => chatRef.off("value", unsubscribe); // Clean up the listener when the component unmounts or dependencies change
  }, [chatId, firebase, classId]);

  // group tokens handler
  useEffect(() => {
    if (isGroupChat) {
      getGroupTokensForNotifications();
    }
  }, []);

  // scroll handler
  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
  }, [chats]);

  const getGroupTokensForNotifications = async () => {
    try {
      const groupTokens = await firebase
        .getAllPushNotificationTokens()
        .once("value");
      if (groupTokens.val()) {
        setGroupTokens(groupTokens.val());
      }
    } catch (error) {
      console.log(
        "SingleChatOpen.js 65 | error getting group tokens",
        error.message
      );
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    const messageObject = createMessageObject(newMessage, authUser.uid, chatId);

    setSendingUserPush(true);
    try {
      await sendChatMessage(classId, chatId, messageObject);
      await updateRoomInChatroom(classId, chatId, messageObject);
      setNewMessage("");

      if (isGroupChat) {
        notifyGroupUsers(users, groupTokens, authUser, newMessage);
      } else {
        sendPushNotificationToUserDevice(
          contactId,
          firebase,
          authUser,
          newMessage,
          contactId
        )
          .then(() => {
            setSendingUserPush(false);
            setUserPushSentSuccessful(true);
            setTimeout(() => {
              setUserPushSentSuccessful(false);
            }, 1500);
            toast.success("sent user notification successfully");
          })
          .catch((err) => {
            setSendingUserPush(false);
            setUserPushSentSuccessful(false);
            console.log("SingleChatOpen.js 98 | error", err.message);
          });
      }
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  const sendChatMessage = async (classId, chatId, messageObject) => {
    await firebase.chatMessages(classId, chatId).push(messageObject);
  };

  const updateRoomInChatroom = async (classId, chatId, messageObject) => {
    await firebase
      .roomsInChatroom(classId, chatId)
      .set({ ...messageObject, read: false });
  };

  const notifyGroupUsers = async (users, groupTokens, authUser, newMessage) => {
    for (const user of Object.keys(users)) {
      try {
        if (!groupTokens[user].expoPushToken) {
          toast.error(`User doesn't have an expo token ${users[user]}`);
          continue;
        }

        const messageBody = createPushMessageBody(
          authUser,
          newMessage,
          groupTokens[user].expoPushToken
        );
        await sendPushMessage(messageBody);
      } catch (error) {
        console.log(`Error sending token to ${error.message}`);
        toast.error(`Error sending token to ${users[user]} ${error.message}`);
      }
    }
  };

  console.log("SingleChatOpen.js 152 | is group chat", isGroupChat);

  if (!contactId) {
    console.log("SingleChatOpen.js 153 | no contact id");
    return null;
  }

  return (
    <div className="chatopen__modal">
      <div className="chatopen__containermain">
        <div className="chatopen__headercontainer">
          <div className="chatopen__username">{contactName}</div>
          {(authUser.isAdmin || process.env.NODE_ENV === "development") && (
            <div>
              <div>Chat ID: {chatId}</div>{" "}
            </div>
          )}
          <div
            className="chatclose__button"
            onClick={() => setActiveChat(false)}
          >
            X
          </div>
        </div>
        <div className="chatopen__chatcontainer" ref={scrollViewRef}>
          {chats &&
            Object.values(chats).map((chat, index) => (
              <div
                className={
                  chat.sender === authUser.uid
                    ? "chatmessage__wrapperuser"
                    : "chatmessage_wrappersender"
                }
              >
                {isGroupChat && (
                  <div className="groupchatuser__sender">
                    {users[chat.sender]}
                  </div>
                )}
                <div
                  className={
                    chat.sender === authUser.uid
                      ? "chatmessage__message"
                      : "chatmessage_sender"
                  }
                  key={index}
                >
                  <div>{chat.message}</div>
                </div>
              </div>
            ))}
        </div>
        <form className="chatopen__inputcontainer" onSubmit={sendMessage}>
          <input
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Message..."
          />
          <div onClick={sendMessage} className="chatopen__submitbutton">
            Send
          </div>
        </form>
        <div className="sendingpush__loader">
          {sendingUserPush ? "Sending push..." : ""}{" "}
          {userPushSentSuccessful ? "Push notification sent!" : ""}
        </div>
      </div>
    </div>
  );
};

function getContactId(chatId, authUserId, isGroupChat) {
  console.log(
    "SingleChatOpen.js 224 | chatid and authuseris",
    chatId,
    authUserId
  );
  if (isGroupChat) {
    console.log(
      "SingleChatOpen.js 230 | isgroupchat returning chat id",
      chatId
    );
    return chatId;
  }
  const contactId = chatId
    .split("-")
    .sort()
    .find((id) => id !== authUserId);
  console.log("SingleChatOpen.js 223 | contact id", contactId);

  return contactId;
}

export default SingleChatOpen;
