import React from "react";
import "./index.css";
import moment from "moment";
import Icon from "src/components/shared/Icon";
import { toast } from "react-toastify";

const ProgramCompletionCard = ({
  userModules,
  classroomModules,
  handleCertificate,
}) => {
  if (!userModules || !classroomModules) return null;

  const checkAllModulesComplete = () => {
    const modulesAvailable = classroomModules || {};
    if (!userModules) {
      return false;
    }

    if (!classroomModules) {
      console.log("CourseContent 118 | no modules");
      return false;
    }

    if (
      Object.values(userModules).length !==
      Object.values(classroomModules).filter((mod) => mod.available).length
    ) {
      console.log("CourseContent 123 | modules length is not the same");
      return false;
    }

    // Check if every available module is completed
    return Object.values(modulesAvailable).every((module) => {
      if (module.available) {
        const moduleComplete = userModules[module.uid]?.lessonComplete;
        if (!moduleComplete) {
          console.log("CourseContent 108 | module not complete");
          return false;
        }
        console.log("CourseContent 135 | module complete", moduleComplete);
        return moduleComplete;
      }
      // Consider modules that are not available as "complete" for this check
      return true;
    });
  };

  return (
    <div className="modulescomplete__container">
      <div className="modulescomplete__left">
        <div className="modulescomplete__title">
          Program Completion Certificate
        </div>
        <div className="modulescomplete__badges">
          {Object.values(classroomModules)
            .sort((a, b) => moment(a.dueDate) - moment(b.dueDate))
            .filter((mod) => mod.available)
            .map((classModule) => {
              const { lessonComplete } = userModules[classModule.uid] || {};
              return (
                <div>
                  <Icon
                    name={lessonComplete ? "badge-active" : "badge-inactive"}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div
        className="downloadmodulescomplete__button"
        onClick={() => {
          if (checkAllModulesComplete()) {
            handleCertificate(true);
          } else {
            toast.error("Please complete all modules", {
              position: "bottom-center",
            });
          }
        }}
      >
        Download
      </div>
    </div>
  );
};

export default ProgramCompletionCard;
