import React from "react";
import { useCallback } from "react";
import "./CurriculumOutline.css";

import { useDispatch } from "react-redux";
import { toggleSignupModal } from "../../../store/authUser/authUserActions";

export const CurriculumOutline = () => {
  const dispatch = useDispatch();

  return (
    <div className="features3">
      <div className="headline-subhead1">
        <div className="curriculum">Curriculum</div>
        {/* <div className="nemo-enim-ipsam">
          Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
          fugit, sed quia consequuntur ma
        </div> */}
      </div>
      <div className="cardsloco">
        <div className="stepsloco">
          <img
            className="graduation-hat-01-icon"
            alt=""
            src="/graduationhat01@2x.png"
          />
          <div className="lsi">LSI</div>
        </div>
        <div className="stepsloco">
          <img
            className="graduation-hat-01-icon"
            alt=""
            src="/bookopen01@2x.png"
          />
          <div className="lsi">EQ Assessment</div>
        </div>
        <div className="stepsloco">
          <img
            className="graduation-hat-01-icon"
            alt=""
            src="/hearthand@2x.png"
          />
          <div className="emotional-intelligence">Emotional Intelligence I</div>
        </div>
        <div className="stepsloco">
          <img
            className="graduation-hat-01-icon"
            alt=""
            src="/hearthand@2x.png"
          />
          <div className="emotional-intelligence">
            Emotional Intelligence II
          </div>
        </div>
        <div className="stepsloco">
          <img
            className="graduation-hat-01-icon"
            alt=""
            src="/minimize01@2x.png"
          />
          <div className="bridge-the-gap-wrapper">
            <div className="emotional-intelligence">Bridge the Gap</div>
          </div>
        </div>
        <div className="stepsloco">
          <img
            className="graduation-hat-01-icon"
            alt=""
            src="/award01@2x.png"
          />
          <div className="lsi">Civic Education</div>
        </div>
        <div className="stepsloco">
          <img
            className="graduation-hat-01-icon"
            alt=""
            src="/banknote02@2x.png"
          />
          <div className="lsi">Finance Fundamentals</div>
        </div>
        <div className="stepsloco">
          <img
            className="graduation-hat-01-icon"
            alt=""
            src="/streamline-fooddrinkscocktailglasscookalcoholfoodcocktaildrinkcookingalcoholicbeverageglass-1@2x.png"
          />
          <div className="substance-abuse-wrapper">
            <div className="substance-abuse">Substance Use Disorder</div>
          </div>
        </div>
      </div>
      <button className="navigation-menu">
        <div
          className="button5"
          onClick={() => {
            dispatch(toggleSignupModal(true));
          }}
        >
          <img className="arrowright-icon9" alt="" src="/arrowright.svg" />
          <div className="label13">Complete all courses</div>
          <img className="arrowright-icon9" alt="" src="/arrowright.svg" />
        </div>
      </button>
    </div>
  );
};

export default CurriculumOutline;
