// Actions
export const setLoadingEvent = (isLoading) => {
  return {
    type: "event/setLoadingEvent",
    payload: isLoading,
  };
};

export const setLoadingUsers = (isLoading) => {
  return {
    type: "event/setLoadingUsers",
    payload: isLoading,
  };
};

export const setUsersList = (users) => {
  return {
    type: "event/setUsersList",
    payload: users,
  };
};

export const setDatabaseEvent = (databaseEvent) => {
  return {
    type: "event/setDatabaseEvent",
    payload: databaseEvent,
  };
};

export const setClassroom = (classroom) => {
  return {
    type: "event/setClassroom",
    payload: classroom,
  };
};

export const setHasNoEventObject = (hasNoEvent) => {
  return {
    type: "event/setHasNoEventObject",
    payload: hasNoEvent,
  };
};

export const setAttendanceMode = (mode) => {
  return {
    type: "event/setAttendanceMode",
    payload: mode,
  };
};

export const setEditEventOpen = (isOpen) => {
  return {
    type: "event/setEditEventOpen",
    payload: isOpen,
  };
};

export const setTestReminderOpen = (isOpen) => {
  return {
    type: "event/setTestReminderOpen",
    payload: isOpen,
  };
};

export const setModuleName = (name) => {
  return {
    type: "event/setModuleName",
    payload: name,
  };
};

export const setEventData = (eventData) => {
  return {
    type: "event/setEventData",
    payload: eventData,
  };
};

export const setReminderModalOpen = (modalData) => {
  return {
    type: "event/setReminderModalOpen",
    payload: modalData,
  };
};
