import React from "react";
import "./ResultInfo.css";
import { Doughnut, Bar } from "react-chartjs-2";

import { CompetencyChart } from "./CompetencyChart";

const ResultInfo = ({
  resultAreaName,
  resultInfoText,
  categoryScore,
  positive,
  negative,
  competencies,
}) => {
  const color =
    categoryScore > 50
      ? ["#00BA88", "rgba(228, 232, 235, 1)"]
      : ["rgba(239, 55, 55, 1)", "rgba(228, 232, 235, 1)"];
  return (
    <div className="resultareacontainer">
      <div className="self-awareness">{resultAreaName}</div>
      <div className="congratulations-on-completing">{resultInfoText}</div>

      <div className="frame-parent1">
        <Doughnut
          data={{
            datasets: [
              {
                label: "Your score",
                data: [categoryScore, 100 - categoryScore],
                backgroundColor: color,
              },
            ],
          }}
          options={{
            rotation: 1 * Math.PI,
            circumference: 1 * Math.PI,
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  let label =
                    data.datasets[tooltipItem.datasetIndex].label || "";
                  if (label) {
                    label += ": ";
                  }
                  label += Math.round(tooltipItem.yLabel * 100) / 100;
                  return Math.floor(categoryScore);
                },
              },
            },
          }}
        />
        <div
          className="notification"
          style={{
            backgroundColor:
              categoryScore > 50
                ? "rgba(97, 200, 145, 0.1)"
                : "rgba(242, 82, 82, 0.1)",
          }}
        >
          <div className="modal-header">
            <div className="lorem-ipsum-dolor">
              {categoryScore > 50 ? positive : negative}
            </div>
          </div>

          <div style={{ width: "100%", height: "300px" }}>
            {console.log("ResultInfo.js 55 | competencies", competencies)}
            <CompetencyChart competencies={competencies} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultInfo;
