import React from "react";
import RateCircle from "./RateCircle";

export class Rater extends React.Component {
  state = {
    value: undefined,
    updating: false,
  };

  handleChange = (value) => {
    console.log("Likert 64 | handling change index", value);
    const { onLikertChange, index, question, handleUpdate, category } =
      this.props;
    this.setState({ updating: true }, () => this.setState({ value }));
    onLikertChange(index, value, question, category);
    handleUpdate();
  };

  componentDidUpdate(prevProps, prevState) {
    console.log("Likert 50 | child updated");
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.updating) return true;
    return false;
  }

  render() {
    const { value } = this.state;
    const rangeOptions = [1, 2, 3, 4, 5];
    const options = [
      "Strongly Disagree",
      "Disagree ",
      "Uncertain",
      "Agree",
      "Strongly Agree",
    ];

    return (
      <div className="likert-component">
        <div>
          {rangeOptions.map((option, index) => {
            return (
              <div key={index} className="quest-option-container">
                <RateCircle
                  index={index}
                  toggled={value + 1 === rangeOptions[index] ? true : false}
                  handleChange={this.handleChange}
                  value={value}
                />
                <p className="likertoption__text">{options[index]}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
