import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Definition from "./components/Definition";
import Word from "./components/Word";

const wordsAndDefinitions = {
  id: "words",
  data: [
    { word: "hello", definition: "says hello" },
    { word: "goodbye", definition: "says goodbye" },
  ],
};

const MultiDragDrop = () => {
  const dragEnd = (e) => {
    console.log("MultiDragDrop 10 | on drag end", e);
    return;
  };
  const dragUpdate = () => {
    console.log("MultiDragDrop 13 | drag update");
  };
  return (
    <div>
      <DragDropContext onDragEnd={dragEnd} onDragUpdate={dragUpdate}>
        <div className="drag-drop-context-container">
          <div className="words-container">
            {wordsAndDefinitions &&
              wordsAndDefinitions.data.map((wordAndDef, index) => {
                return (
                  <Droppable droppableId={"words-id"}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Word word={wordAndDef.word} />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                );
              })}
          </div>

          <div className="definitions-container">
            {wordsAndDefinitions &&
              wordsAndDefinitions.data.map((wordAndDef, index) => {
                return (
                  <Droppable droppableId={"123"} index={1}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          isDraggingOver={snapshot.isDraggingOver}
                          className="correct-word-in-definition-container"
                        >
                          <Definition definition={wordAndDef.definition} />
                          <div>{provided.placeholder}</div>
                        </div>
                      );
                    }}
                  </Droppable>
                );
              })}
          </div>
        </div>
      </DragDropContext>
    </div>
  );
};

export default MultiDragDrop;
