import React from "react";
import "../EIResultsContainer.css";
import ResultContainer from "../ResultContainer";
import PreTestInfo from "../PreTest/PreTestInfo";

export const PostTestTab = ({ preTestResults, postTestResults }) => {
  if (!postTestResults)
    return <div className="resultareacontainer1">Loading...</div>;

  console.log("PostTest 9 | pre test results on posttest  ", preTestResults);

  return (
    <div>
      {Object.entries(postTestResults.results).map((category, index) => {
        return (
          <ResultContainer
            index={index}
            categoryName={category[0]}
            category={category}
            preTestCompetencies={preTestResults?.results[category[0]]}
          />
        );
      })}
      <PreTestInfo />
    </div>
  );
};

export default PostTestTab;
