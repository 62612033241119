import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import AuthUserContext from "./context";
import * as ROUTES from "../../constants/routes";
import { getTokens } from "src/api/google";

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    state = { loading: false };
    async componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        (authUser) => {
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.LANDING);
          }
        },
        async () => {
          this.setState({ loading: true });
          const query = this.props.location.search?.split("?")[1];
          const idToken = query?.split("&")[0].split("=")[1];
          const accessToken = query?.split("&")[1].split("=")[1];
          const userId = query?.split("&")[2].split("=")[1];

          const expirationDate = new Date(new Date().getTime() + 3600000);
          localStorage.setItem("expirationDate", expirationDate);

          if (!query || !idToken || !accessToken || !userId) {
            this.props.history.push(ROUTES.LANDING);
          }
          localStorage.setItem("token", accessToken);

          if (idToken) {
            await this.props.firebase
              .signInWithTokenId(idToken)
              .then(async () => {
                const userData = await this.props.firebase
                  .user(userId)
                  .once("value");
                const user = userData.val();

                console.log(
                  "withAuthorization.js 44 | refersh token in user",
                  user.refresh_token
                );
                // set user refresh token in session storage
                localStorage.setItem("refreshToken", user.refresh_token);
                this.setState({ loading: false });

                this.props.history.push(ROUTES.HOME);
              })
              .catch((error) => {
                this.setState({ loading: false });
                this.props.history.push(ROUTES.LANDING);
                console.log(" | 27", "error with login", error);
              });
          } else {
            console.log("withAuthorization.js 27 | no code");
            this.setState({ loading: false });
            this.props.history.push(ROUTES.LANDING);
          }
        }
      );
    }

    handleToken = async (code) => {
      try {
        const tokens = await getTokens(code);
        console.log(
          "withAuthorization.js 38 | tokens to sign in with ",
          tokens
        );
        return tokens;
      } catch (error) {
        console.log("withAuthorization.js 39 | error getting token", error);
      }
    };

    shouldComponentUpdate(prevProps, prevState) {
      if (prevProps === this.props) {
        return false;
      } else {
        return true;
      }
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      if (this.state.loading) {
        return <div>Loading...</div>;
      } else {
        return (
          <AuthUserContext.Consumer>
            {(authUser) =>
              condition(authUser) ? <Component {...this.props} /> : null
            }
          </AuthUserContext.Consumer>
        );
      }
    }
  }

  return compose(withRouter, withFirebase)(WithAuthorization);
};

export default withAuthorization;
