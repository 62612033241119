import React from "react";

export const SnowballEditContent = ({
  index,
  content,
  editSection,
  optionIndex,
}) => {
  const { title, answer, range, text, hint, instructions } = content;

  return (
    <>
      <div className="snowball-edit-values-container">
        {optionIndex}
        <label>Title</label>
        <textarea
          name="title"
          value={title}
          onChange={(e) =>
            editSection(e, index, "snowball-spreadsheet", optionIndex)
          }
        />
        <label>Instructions</label>
        <textarea
          name="text"
          value={text}
          onChange={(e) =>
            editSection(e, index, "snowball-spreadsheet", optionIndex)
          }
        />
        <label>Instructions 2</label>
        <textarea
          name="instructions"
          value={instructions}
          onChange={(e) =>
            editSection(e, index, "snowball-spreadsheet", optionIndex)
          }
        />
        <label>Answer</label>
        <input
          name="answer"
          value={answer}
          onChange={(e) =>
            editSection(e, index, "snowball-spreadsheet", optionIndex)
          }
        />
        <label>Cell Position</label>
        <input
          name="range"
          value={range}
          onChange={(e) =>
            editSection(e, index, "snowball-spreadsheet", optionIndex)
          }
        />
        <label>Hint</label>
        <input
          name="hint"
          value={hint}
          onChange={(e) =>
            editSection(e, index, "snowball-spreadsheet", optionIndex)
          }
        />
      </div>
    </>
  );
};
