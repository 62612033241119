import React, { useState } from "react";
import { Link } from "react-router-dom";
import Icon from "src/components/shared/Icon";
import SignOut from "src/components/SignOut";
import { navLinks } from "../../navlinks";
import NavLink from "../Link";
import "./index.css";

const TopNavigation = ({ authUser }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="topnavigation__container">
      <Link to="/home" className="topnavigation__title">
        <strong>
          RED Ed <span style={{ fontWeight: "normal", fontSize: 10, top: 0 }}>™</span>
        </strong>
      </Link>
      <div className="arrow__toggle">
        <Icon
          name="scroll-down"
          width={20}
          height={20}
          onClick={() => setOpen(!open)}
        />
      </div>
      {open && (
        <div className="topnav__wrapper">
          <div className="topnav__areatop">
            {navLinks &&
              navLinks.map((link, index) => {
                return (
                  <NavLink
                    onClick={() => {
                      setOpen(false);
                    }}
                    title={link.title}
                    iconName={link.iconName}
                    to={link.to}
                    adminAccessRequired={link.requiresAdmin}
                    isAdmin={authUser.isAdmin}
                  />
                );
              })}
            <SignOut />
          </div>
        </div>
      )}
    </div>
  );
};

export default TopNavigation;
