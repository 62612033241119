import React from "react";
import "../modals.css";
import ModalContent from "../ModalContent/ModalContent";
import OptionButton from "../OptionButton/OptionButton";
import WelcomeModal from "../WelcomeModal/WelcomeModal";
import Table from "./Table/Table";

class SelectFixedExpensesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setupOptions: this.props.setupOptions,
      setupOptionKeys: Object.keys(this.props.setupOptions),
      currentIndex: -1,
      levelNum: props.levelNum,
      jobs: true,
      nextDisplayed: false,
    };
  }

  componentDidMount() {
    console.log("SelectFixedExpenses.js 24 | choosing fixed expenses");
    if (this.props.levelNum === 1) {
      // this.setState({
      //   currentIndex: 0,
      // });
      console.log(
        "SelectFixedExpenses.js 28 | your parents kick you out of the house!"
      );
    }
  }

  // Changes the  modal screen to the next set of options
  _toggleScreen = (event) => {
    event.preventDefault();

    const { setupOptionKeys, currentIndex } = this.state;
    const newIndex = currentIndex + 1;
    const currentScreen =
      currentIndex >= 0 ? setupOptionKeys[currentIndex] : "welcomeVisible";
    const newScreen = setupOptionKeys[newIndex];

    this.setState({
      currentIndex: newIndex,
      [currentScreen]: false,
      [newScreen]: true,
    });
  };

  _prevScreen = (event) => {
    event.preventDefault();

    const { setupOptionKeys, currentIndex } = this.state;
    const newIndex = currentIndex - 1;
    const currentScreen =
      currentIndex >= 0 ? setupOptionKeys[currentIndex] : "welcomeVisible";
    const newScreen = setupOptionKeys[newIndex];

    this.setState({
      currentIndex: newIndex,
      [currentScreen]: false,
      [newScreen]: true,
    });
  };

  _buildOption = (
    optionCategory,
    option,
    selectedOption,
    _updateOption,
    index
  ) => {
    let happyPoints = "";
    switch (option.happy) {
      case -3:
        happyPoints = "☹️ ☹️ ☹️";
        break;
      case -2:
        happyPoints = "☹️ ☹️";
        break;
      case -1:
        happyPoints = "☹️";
        break;
      case 1:
        happyPoints = "🙂";
        break;
      case 2:
        happyPoints = "🙂 🙂";
        break;
      case 3:
        happyPoints = "🙂 🙂 🙂";
        break;
      default:
        happyPoints = "";
    }

    return (
      <OptionButton
        key={index}
        levelNum={this.props.levelNum}
        optionCategory={optionCategory}
        option={option}
        happyPoints={happyPoints}
        imgSrc={option.imgSrc}
        imgAlt={option.imgAlt}
        selectedOption={selectedOption}
        _updateOption={_updateOption}
      />
    );
  };

  render() {
    const { food, housing, insurance, jobs, transport } =
      this.props.selectedOptions; //this goes to the table

    if (this.state.currentIndex === -1) {
      return (
        <WelcomeModal
          _toggleScreen={this._toggleScreen}
          username={this.props.username}
        />
      );

      // Show setup options
    } else {
      const key = this.state.setupOptionKeys[this.state.currentIndex];
      const screen = this.state.setupOptions[key]; //returns "job"
      // let isActive = screen;
      const currentScreen = this.props.selectedOptions[screen.id].title;

      // Uses current level number to pick unlocked job options
      const unlockedOptions =
        screen.id === "jobs"
          ? screen.options.slice(0, this.state.levelNum + 1)
          : screen.options;

      // Builds the JSX for each option
      const optionButtons = unlockedOptions.map((option, index) => {
        return this._buildOption(
          screen.id,
          option,
          this.props.selectedOptions[screen.id].title,
          this.props._updateOption,
          index
        );
      });

      const _nextButton =
        this.state.currentIndex === this.state.setupOptionKeys.length - 1
          ? this.props._startMonth
          : this._toggleScreen;
      const nextMessage =
        this.state.currentIndex === this.state.setupOptionKeys.length - 1
          ? "Start Game"
          : "Next";
      return (
        <div className="select-options-modal">
          <ModalContent
            title={screen.title}
            subtitle={screen.subtitle}
            _nextButton={_nextButton}
            nextMessage={nextMessage}
            nextDisplayed={this.state.nextDisplayed}
            currentScreen={currentScreen}
            _prevScreen={this._prevScreen}
          >
            {optionButtons}
          </ModalContent>
          <Table
            income={jobs.amount}
            housing={housing.amount}
            health={insurance.amount}
            food={food.amount}
            transportation={transport.amount}
          />
        </div>
      );
    }
  }
}

export default SelectFixedExpensesModal;
