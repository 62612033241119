import React from "react";
import { Link } from "react-router-dom";
import Icon from "src/components/shared/Icon";
import "./index.css";

const FinalAssessment = ({
  enabled = false,
  done = false,
  finalAssessment,
  authUser,
  finalAssessmentDone,
}) => {
  if (finalAssessmentDone) return null;
  if (finalAssessment || authUser?.isAdmin) {
    return (
      <div className="initialassessments__container">
        <div className="initialassessment__cardtop">
          <div className="initialassessment__topleftcontainer">
            <Icon width={15} height={15} />
            <p className="topleftcontainer__time">1h</p>
          </div>
          <div className="initialassessment__toprightcontainer">
            <Icon name="assessment-number" width={15} height={15} />
            <p className="toprightcontainer__number"></p>
          </div>
        </div>
        <div className="initialassessment__cardmid">
          <div className="initialassessment__midtitle">Final Assessment</div>
          <div className="initialassessment__midsubtitle">
            You're almost done with the program!
          </div>
        </div>
        <div className="initialassessment__cardbottom">
          {!done ? (
            <Link
              className={`initialassessment__button ${
                !enabled ? "initialassessment__disabled" : null
              }`}
              // disabled={!enabled}
              to={enabled ? "/finalAssessment" : '/home'}
            >
              Take Assessment
            </Link>
          ) : (
            <div className="initialassessment__complete">
              <Icon /> <p>Done!</p>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default FinalAssessment;
