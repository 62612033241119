import React from "react";
import ResultInfo from "./ResultInfo";
import "./EIGhystResults.css";
import { assessmentCategoryData } from "../data/assessmentInfoData";
import Button from "src/components/shared/Button";

import { useHistory } from "react-router-dom";

export const EIGhystResults = ({ results }) => {
  console.log("EIGhystResults.js 4 | results", results);

  const history = useHistory();
  return (
    <div className="resultscontainer">
      <b className="your-ghyst-ei">Your Ghyst EI Test Results</b>
      <div className="congratulations-on-completing">
        Congratulations on completing the Ghyst EI Test! Your emotional
        intelligence (EI) plays a crucial role in understanding and managing
        your emotions effectively. Below are your results:
      </div>

      {results &&
        Object.entries(results).map((result) => {
          console.log(
            "EIGhystResults.js 18 | assessmentCategoryData",
            assessmentCategoryData
          );
          const percentScore = result[1].percentageScore;
          const { intro, positive, negative } =
            assessmentCategoryData[result[0]];
          return (
            <ResultInfo
              resultAreaName={result[0]}
              resultInfoText={intro}
              positive={positive}
              negative={negative}
              categoryScore={percentScore}
              competencies={result[1]}
            />
          );
        })}

      <div className="infointerpreting">
        <div className="interpreting-your-scores">
          Interpreting Your Scores:
        </div>
        <div className="each-profile-in-container">
          <ul className="each-profile-in-the-graph-repr">
            <li className="each-profile-in">
              Each profile in the graph represents a different aspect of
              emotional intelligence.
            </li>
            <li className="each-profile-in">
              The profiles highlighted in RED are the main ones to focus on as
              they hold significant insights.
            </li>
            <li>
              Click on a profile to view interpretive guidelines for that
              specific aspect of EI.
            </li>
          </ul>
        </div>
      </div>
      <div className="infointerpreting">
        <div className="interpreting-your-scores">What's Next?</div>
        <div className="congratulations-on-completing">
          Understanding your emotional intelligence is a key step towards
          personal growth and development. Use the interpretive guidelines to
          explore how you can enhance your EI in various areas of life.
        </div>
      </div>
      <div className="infointerpreting">
        <div className="interpreting-your-scores">Remember:</div>
        <div className="congratulations-on-completing">
          The Ghyst EI Test is not designed to diagnose or treat any mental or
          physical condition. It is meant for personal development purposes
          only. If you have any questions or concerns about the results or the
          test, consider contacting Brent Darnell for access to the full
          versions of these tests. For mental or physical health issues, consult
          your mental healthcare provider or physician. In case of thoughts of
          suicide, please contact the Suicide Prevention Lifeline at
          1-800-273-8255 or 988.
        </div>
      </div>
      <div className="congratulations-on-completing">
        Start your journey to emotional intelligence and better self-awareness
        today!
      </div>
      <Button
        title="Return to Dashboard"
        onClick={() => history.push("/home")}
      />
    </div>
  );
};

export default EIGhystResults;
