import React from "react";

const ProgressBar = (props) => {
  const percent = ((props.eventNum + 1) * 100) / props.allEvents;
  console.log("ProgressBar.js 4 | months", props.months, props.monthNum);
  return (
    <div className="progress-bar-container">
      <h5 className="month-name">{`${
        props.months[props.monthNum].month
      } (month ${props.monthNum + 1} of ${props.months.length})`}</h5>
      <div class="progress md-progress">
        <div
          class="progress-bar"
          role="progressbar"
          style={{ width: `${percent}%`, height: "20px" }}
          aria-valuenow={props.eventNum}
          aria-valuemin="1"
          aria-valuemax={props.allEvents}
        >
          {props.months[props.monthNum].month}{" "}
          {Math.floor(((props.eventNum + 1) / props.allEvents) * 30)}
          {/* {`${props.eventNum + 1} of ${props.allEvents}`} */}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
