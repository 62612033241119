import React, { useState, useContext, useEffect } from "react";
import Icon from "src/components/shared/Icon";
import { generateCertificate } from "./certificateFunctions";
import { withFirebase } from "../../../Firebase";
import {
  AuthUserContext,
  withAuthentication,
  withAuthorization,
} from "../../../Session";

import moment from "moment";
import { compose } from "recompose";
import Spin from "antd/es/spin";
import "./index.css";

import "src/global.css";

import CertificateCard from "./CertificateCard";
import ProgramCompletionCard from "../ProgramCompletionCard";
import { ConfirmLegalNameModal } from "./ConfirmLegalName";
import { toast } from "react-toastify";

const CompletionCertificates = ({ firebase }) => {
  const authUser = useContext(AuthUserContext);
  const [classData, setClassData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [legalNameConfirmOpen, setLegalNameConfirmOpen] = useState(false);

  useEffect(() => {
    handleModules();
  }, []);

  const handleModules = async () => {
    setLoading(true);
    const classData = await firebase
      .mentorClassroom(authUser.classUID)
      .once("value");

    const responseData = classData.val();
    if (responseData) {
      setClassData(classData.val());
      setLoading(false);
    } else {
      setClassData(null);
      setLoading(false);
    }
  };

  const handleModulesData = (lesson) => {
    let lessonDataResponse = {
      isCompleted: false,
      progress: 0,
      description: "",
    };

    if (authUser?.lessons?.length > 0) {
      Object.values(authUser.lessons).forEach((userLesson) => {
        lessonDataResponse.isCompleted = userLesson.lessonComplete;
        lessonDataResponse.progress = Math.round(
          (userLesson.progress / userLesson.sectionLength) * 100
        );
        lessonDataResponse.description = userLesson.description;
      });
    }

    return lessonDataResponse;
  };

  const handleCertificate = async (
    isFinalCertificate,
    lessonName,
    lessonCompletionDate,
    displayName
  ) => {
    setLoading(true);
    try {
      await generateCertificate(
        displayName,
        isFinalCertificate
          ? "Rehabilitation Enables Dreams Course Completion"
          : lessonName,
        isFinalCertificate
          ? moment().format("MMM Do, YYYY")
          : moment(lessonCompletionDate).format("MMM Do, YYYY"),
        isFinalCertificate ? finalCompletionCertificateDescription() : "",
        isFinalCertificate
      );
      setLoading(false);
    } catch (error) {
      console.log(
        "CompletionCertificates 68 | error  generated certificate",
        error
      );
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="completioncertificates__container">
        <Spin />
      </div>
    );
  }

  if (classData === null) {
    return (
      <div className="completioncertificates__container">
        <Icon name="rewards" />
        <span>You need to be in a classroom to get the certificates</span>
      </div>
    );
  }

  return (
    <div className="completioncertificatescontaine">
      <div className="completion-certificates">Completion Certificates</div>
      <div className="badgesandcertificatescontainer">
        <div className="content">
          <div className="certificatecards__container">
            {/* <ConfirmLegalNameModal
              open={false}
              displayName={authUser.displayName}
              firebase={firebase}
              uid={authUser.uid}
              onConfirm={onConfirmLegalNameUpdate}
            /> */}
            {classData.settings.modules ? (
              Object.values(classData.settings.modules)
                .sort((a, b) => moment(a.dueDate) - moment(b.dueDate))
                .filter((module) => module.available)
                .map((lesson, index) => {
                  const { isCompleted, progress, description } =
                    handleModulesData(lesson);

                  const lessonComplete =
                    authUser.lessons &&
                      authUser.lessons[lesson.uid]?.lessonComplete
                      ? true
                      : false;

                  const completionDate =
                    authUser.lessons[lesson.uid]?.completionDate;

                  console.log("lesson", new Date(completionDate));

                  return (
                    <CertificateCard
                      key={index}
                      title={lesson.name}
                      description={description}
                      complete={lessonComplete}
                      completionDate={completionDate}
                      handleCertificate={handleCertificate}
                      firebase={firebase}
                      authUser={authUser}
                    />
                  );
                })
            ) : (
              <NoCertificates />
            )}
          </div>

          <ProgramCompletionCard
            userModules={authUser.lessons}
            classroomModules={classData.settings.modules}
            handleCertificate={handleCertificate}
          />
        </div>
      </div>
    </div>
  );
};

export const Certificate = ({
  name,
  description = "",
  fullName,
  completionDate,
  isFinalCertificate = false,
  fromViewClassRoom,
}) => {
  const [loading, setLoading] = useState(false);
  const authUser = useContext(AuthUserContext);
  const handleCertificate = async (isFinalCertificate) => {
    console.log(
      "handle certificate",
      name,
      fullName,
      isFinalCertificate,
      new Date(completionDate)
    );
    setLoading(true);
    let desc = isFinalCertificate
      ? finalCompletionCertificateDescription()
      : "";
    try {
      await generateCertificate(
        fullName,
        name,
        moment(completionDate).format("MMMM Do, YYYY"),
        desc,
        isFinalCertificate
      );
      setLoading(false);
    } catch (error) {
      console.log(
        "CompletionCertificates 68 | error  generated certificate",
        error
      );
      setLoading(false);
    }
  };
  if (isFinalCertificate && fromViewClassRoom) {
    return (
      <div
        onClick={() => handleCertificate(isFinalCertificate)}
        style={{ textAlign: "center" }}
      >
        <img
          src={"/assets/images/download_icon.png"}
          className="event__manager__edit__icon"
          alt="download icon"
        />
      </div>
    );
  } else {
    return (
      <div className="certificate__container">
        <div className="certificate__lessonname">{name}</div>
        <div className="certificate__descriptionanddownload">
          <div className="certificate__lessondescription">{description}</div>

          <div
            onClick={() => handleCertificate(isFinalCertificate)}
            className="certificate__download"
          >
            <span>{!loading ? "Download" : "Loading..."}</span>
            <Icon width={12} height={12} name="complete" />
          </div>
        </div>
      </div>
    );
  }
};

const finalCompletionCertificateDescription = (name) =>
  `Has successfully completed the Rehabilitation Enables Dreams 12-Month Pre-Adjudication Restorative Justice Program. RED's Restorative Justice Curriculum is an evidence-based digital curriculum designed to enhance the social, civic, financial, and digital literacy rates of its program participants. The certification is approved by Rehabilitation Enables Dreams and satisfies compliance standards as a qualified accountability program.`;

const NoCertificates = () => {
  return (
    <div className="nocertificates__container">
      No completion certificates available.
    </div>
  );
};

const condition = (authUser) => authUser;

export default compose(
  withAuthorization(condition),
  withAuthentication,
  withFirebase
)(CompletionCertificates);
