import React from "react";
import ContentLoader from "react-content-loader";

export const MyLoader = () => (
  <ContentLoader 
    speed={2}
    width={400}
    height={160}
    viewBox="0 0 400 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="41" y="31" rx="0" ry="0" width="166" height="96" /> 
    <rect x="16" y="45" rx="0" ry="0" width="4" height="77" /> 
    <circle cx="18" cy="33" r="8" /> 
    <circle cx="18" cy="135" r="7" /> 
    <rect x="223" y="40" rx="0" ry="0" width="115" height="7" /> 
    <rect x="224" y="56" rx="0" ry="0" width="96" height="8" /> 
    <rect x="224" y="71" rx="0" ry="0" width="80" height="7" />
  </ContentLoader>
)

export const ImageLoader = () => (
  <ContentLoader 
    speed={1}
    width={400}
    height={160}
    viewBox="0 0 400 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="53" y="14" rx="0" ry="0" width="268" height="134" />
  </ContentLoader>
);

export default MyLoader;
