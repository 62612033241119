import React from "react";
import "../index.css";

export const CheckboxRed = ({ isChecked }) => {

  return (
      <div className={isChecked ? "checkbox__red__checked" : "checkbox__red__unchecked"}>
        {isChecked && <img src={"/assets/images/check_icon.svg"} alt="check icon"/>}
      </div>
  );
};


export default CheckboxRed;