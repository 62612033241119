import React from "react";
import "./index.css";

const RateCircle = ({ toggled = false, title, handleChange, value, index }) => {
  const handlingChange = () => {
    console.log("RateCircle.js 5 | handling change", value);
    handleChange(index);
  };
  return (
    <div className="quest-option-container">
      <p
        className={
          toggled ? "quest-likert-selected" : "quest-likert-not-selected"
        }
        onClick={() => handlingChange()}
      ></p>
    </div>
  );
};

export default RateCircle;
