import React from "react";
import "./index.css";

const Table = ({ item }) => {
  if (!item) return "No Table Data";
  console.log("Table 5 | item", item);
  return (
    <table className="table-view">
      {item.columns &&
        item.columns.map((column, index) => {
          console.log("Table | 6", column);
          return <th key={index}>{column}</th>;
        })}

      {item.rows &&
        item.rows.map((row, index) => {
          console.log("Table | 12", row);
          return (
            <tr key={index}>
              {row &&
                row.map((rowValue, index) => {
                  console.log("Table | 15", rowValue);
                  return <td key={index}>{rowValue}</td>;
                })}
            </tr>
          );
        })}
    </table>
  );
};

export default Table;
