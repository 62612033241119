import { toast } from "react-toastify";

export const failureNotification = () => {
  toast.error(
    `You've lost :('. Time to start over. Click the Reset Game button to start over.`,
    {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};
