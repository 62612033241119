// Generates random integer in specified range
const randomIntFromRange = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

// // Generates a large set of events in different categories
// const createEvents = (numEvents, category, start, range) => {
//   // // Divides the categories evenly based on total numEvents of events
//   // let eventsPerCategory = Math.floor(numEvents / categoryArray.length)
//   let eventArray = [];
//   for (let i = 0; i < numEvents; i++) {
//     let min = randomIntFromRange(start, start+range);
//     let mid = randomIntFromRange(min+1, min+range);
//     let max = randomIntFromRange(mid+1, mid+range);

//     let minHappy = start < 100 ? randomIntFromRange(-2, 2) : randomIntFromRange(-2, 3);
//     let midHappy = start < 100 ? randomIntFromRange(minHappy+1, 2) : randomIntFromRange(minHappy+1, 3);
//     let maxHappy = start < 100 ? randomIntFromRange(midHappy+1, 2) : randomIntFromRange(minHappy+1, 3);

//     let numChoices = randomIntFromRange(2, 4);
//     let choices = [
//         {
//           action: "Pay option A",
//           amount: min,
//           happy: minHappy
//         },
//         {
//           action: "Pay option B",
//           amount: mid,
//           happy: midHappy
//         },
//       ]
//     if (numChoices === 3) {
//       choices = [...choices, 
//         {
//           action: "Pay option C",
//           amount: max,
//           happy: maxHappy
//         },
//       ]
//     }
//     let event = {
//         situation: "Choose between these options:",
//         type: "Expense",
//         category: {
//           name: category,
//         },
//         choices: choices
//       };
//     eventArray.push(event);  
//   }

//   return eventArray;
// };


// Generates a random set of events for a month, within specified expense ranges
const _buildMonthEvents = (income, events) => {

  const categoryNames = Object.keys(events);
  let usedEvents = [];
  let eventsToUse = {...events};

  // Calculate the maximum average monthly expense for each category based on income and allowed percentages
  categoryNames.forEach(category => {
    let possibleEvents = [...events[category].events];
    let categoryLimit = events[category].percentage * income * .005;
    let currentAmount = 0;
    while (true) {
      if (possibleEvents.length > 0) {
        let randomIndex = randomIntFromRange(0, possibleEvents.length);
        let nextIndex = randomIndex + 1;
        let prevIndex = randomIndex - 1;
        let randomEvent = possibleEvents.slice(randomIndex, nextIndex)[0] ?
                          possibleEvents.slice(randomIndex, nextIndex)[0] : 
                          possibleEvents.slice(prevIndex, randomIndex)[0];
        
        // Finds the average expense amount between all of events choices
        let allAmounts = 0;
        randomEvent.choices.forEach(choice => {
          allAmounts += choice.amount
        });
        let averageAmount =  Math.floor(allAmounts / randomEvent.choices.length);
        // Ends finding the average

        if (currentAmount + averageAmount < categoryLimit) {
          currentAmount+= averageAmount;
        } else {
          usedEvents.push(randomEvent);
          possibleEvents.splice(randomIndex, 1);
          eventsToUse = {
            ...eventsToUse,
            [category]: {
              ...eventsToUse[category],
              events: [...possibleEvents]
            }
          };
          break;
        }
        usedEvents.push(randomEvent);
        possibleEvents.splice(randomIndex, 1);
        eventsToUse = {
          ...eventsToUse,
          [category]: {
            ...eventsToUse[category],
            events: [...possibleEvents]
          }
        };
      } else {
        break;
      }
    }
  });
  return {
    remainingEvents: {...eventsToUse}, // an object of category keys with arrays of events
    usedEvents: usedEvents, // an array of events
  };
};

// Randomly shuffles array of chosen month events
export const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};


export const _findIdealMonth = (income, allEvents, currentMonth) => {
  let ideal = false;
  let shuffledEvents = [];
  // let idealEvents = [];
  let remainingEvents = {...allEvents};
  // console.log('first remaing', remainingEvents)
  // console.log('first all ', allEvents)

  while (true) {
    // console.log('current month ', currentMonth)
    // console.log('remaining ', remainingEvents)
    const newMonthEvents = _buildMonthEvents(income, remainingEvents); // a modified copy of the original events object, and an array of used events
    // console.log('new created month ', newMonthEvents, "Hello!");
    const usedEvents = [...newMonthEvents.usedEvents];
    // console.log('usedEvents ', usedEvents)
    shuffledEvents = shuffleArray(usedEvents);

    ideal = (shuffledEvents.length >= 0 && shuffledEvents.length <= 11) ? true : false;
    if (ideal) {
      remainingEvents = {...newMonthEvents.remainingEvents}
      break;
    } else {
      remainingEvents = {...allEvents}
    }
  }

  return {
    shuffledEvents: [...shuffledEvents],
    remainingEvents: {...remainingEvents}
  };
  
};

