const allEvents = {
  housingEvents: [
    {
      situation: "There’s going to be a long cold spell.",
      type: "Expense",
      category: {
        name: "Housing",
      },
      choices: [
        {
          action: "Turn up the thermostat",
          happiness: 3,
          amount: 50,
        },
        {
          action: "Buy an extra blanket and sweater",
          happiness: 1,
          amount: 20,
        },
        {
          action: "Tough it out",
          happiness: -3,
          amount: 0,
        },
      ],
    },
    {
      situation: "You need more seating in your living room for guests.",
      type: "Expense",
      category: {
        name: "Housing",
      },
      choices: [
        {
          action: "Find a nice used sofa",
          happiness: 3,
          amount: 200,
        },
        {
          action: "Get a bean bag chair and floor pillows",
          happiness: 1,
          amount: 80,
        },
        {
          action: "Buy some folding chairs",
          happiness: -1,
          amount: 30,
        },
      ],
    },
    {
      situation: "Oh no! Your dryer catches on fire",
      type: "Expense",
      category: {
        name: "Housing",
      },
      choices: [
        {
          action: "Take your wet clothes to a laundromat",
          happiness: -1,
          amount: 5,
        },
        {
          action: "Hang your clothes up to air dry",
          happiness: -2,
          amount: 0,
        },
        {
          action: "Call the repair guy",
          happiness: 2,
          amount: 40,
        },
      ],
    },
    {
      situation: "You discover a cockroach infestation!",
      type: "Expense",
      category: {
        name: "Housing",
      },
      choices: [
        {
          action: "Call the exterminator!",
          happiness: 1,
          amount: 150,
        },
        {
          action: "Buy some roach bait",
          happiness: 0,
          amount: 25,
        },
        {
          action: "Hunt them down yourself",
          happiness: -3,
          amount: 0,
        },
      ],
    },
    // // Events below are duplicates of above
    // {
    //     situation: "There’s going to be a long cold spell.",
    //     type: "Expense",
    //     category: {
    //         name: "Housing",
    //     },
    //     choices: [{
    //             action: "Turn up the thermostat",
    //             happiness: 3,
    //             amount: 50
    //         },
    //         {
    //             action: "Buy an extra blanket and sweater",
    //             happiness: 1,
    //             amount: 20
    //         },
    //         {
    //             action: "Tough it out",
    //             happiness: -3,
    //             amount: 0
    //         },
    //     ]
    // },
    // {
    //     situation: "You need more seating in your living room for guests.",
    //     type: "Expense",
    //     category: {
    //         name: "Housing",
    //     },
    //     choices: [{
    //             action: "Find a nice used sofa",
    //             happiness: 3,
    //             amount: 200
    //         },
    //         {
    //             action: "Get a bean bag chair and floor pillows",
    //             happiness: 1,
    //             amount: 80
    //         },
    //         {
    //             action: "Buy some folding chairs",
    //             happiness: -1,
    //             amount: 30
    //         }
    //     ]
    // },
    // {
    //     situation: "Oh no! Your dryer catches on fire",
    //     type: "Expense",
    //     category: {
    //         name: "Housing",
    //     },
    //     choices: [{
    //             action: "Take your wet clothes to a laundromat",
    //             happiness: -1,
    //             amount: 5
    //         },
    //         {
    //             action: "Hang your clothes up to air dry",
    //             happiness: -2,
    //             amount: 0
    //         },
    //         {
    //             action: "Call the repair guy",
    //             happiness: 2,
    //             amount: 40
    //         }
    //     ]
    // },
    // {
    //     situation: "You discover a cockroach infestation!",
    //     type: "Expense",
    //     category: {
    //         name: "Housing",
    //     },
    //     choices: [{
    //             action: "Call the exterminator!",
    //             happiness: 1,
    //             amount: 150
    //         },
    //         {
    //             action: "Buy some roach bait",
    //             happiness: 0,
    //             amount: 25
    //         },
    //         {
    //             action: "Hunt them down yourself",
    //             happiness: -3,
    //             amount: 0
    //         }
    //     ]
    // },
    // {
    //     situation: "There’s going to be a long cold spell.",
    //     type: "Expense",
    //     category: {
    //         name: "Housing",
    //     },
    //     choices: [{
    //             action: "Turn up the thermostat",
    //             happiness: 3,
    //             amount: 50
    //         },
    //         {
    //             action: "Buy an extra blanket and sweater",
    //             happiness: 1,
    //             amount: 20
    //         },
    //         {
    //             action: "Tough it out",
    //             happiness: -3,
    //             amount: 0
    //         },
    //     ]
    // },
    // {
    //     situation: "You need more seating in your living room for guests.",
    //     type: "Expense",
    //     category: {
    //         name: "Housing",
    //     },
    //     choices: [{
    //             action: "Find a nice used sofa",
    //             happiness: 3,
    //             amount: 200
    //         },
    //         {
    //             action: "Get a bean bag chair and floor pillows",
    //             happiness: 1,
    //             amount: 80
    //         },
    //         {
    //             action: "Buy some folding chairs",
    //             happiness: -1,
    //             amount: 30
    //         }
    //     ]
    // },
    // {
    //     situation: "Oh no! Your dryer catches on fire",
    //     type: "Expense",
    //     category: {
    //         name: "Housing",
    //     },
    //     choices: [{
    //             action: "Take your wet clothes to a laundromat",
    //             happiness: -1,
    //             amount: 5
    //         },
    //         {
    //             action: "Hang your clothes up to air dry",
    //             happiness: -2,
    //             amount: 0
    //         },
    //         {
    //             action: "Call the repair guy",
    //             happiness: 2,
    //             amount: 40
    //         }
    //     ]
    // },
    // {
    //     situation: "You discover a cockroach infestation!",
    //     type: "Expense",
    //     category: {
    //         name: "Housing",
    //     },
    //     choices: [{
    //             action: "Call the exterminator!",
    //             happiness: 1,
    //             amount: 150
    //         },
    //         {
    //             action: "Buy some roach bait",
    //             happiness: 0,
    //             amount: 25
    //         },
    //         {
    //             action: "Hunt them down yourself",
    //             happiness: -3,
    //             amount: 0
    //         }
    //     ]
    // },
  ],
  funEvents: [
    {
      //27
      situation: "Your favorite videogame just got released!",
      type: "Expense",
      category: {
        name: "Fun",
      },
      choices: [
        {
          action: "Get the Extended Edition",
          happiness: 3,
          amount: 100,
        },
        {
          action: "Get the normal version",
          happiness: 2,
          amount: 60,
        },
        {
          action: "Pass",
          happiness: -3,
          amount: 0,
        },
      ],
    },
    {
      //27
      situation: "You feel like learning an instrument",
      type: "Expense",
      category: {
        name: "Fun",
      },
      choices: [
        {
          action: "Buy an electric guitar",
          happiness: 3,
          amount: 150,
        },
        {
          action: "Get a ukulele",
          happiness: 1,
          amount: 30,
        },
      ],
    },
    {
      situation: "Your friends are all going to Six Flags this weekend",
      type: "Expense",
      category: {
        name: "Fun",
      },
      choices: [
        {
          action: "Sounds amazing. Let’s go!",
          happiness: 3,
          amount: 70,
        },
        {
          action: "Stay home and feel sad",
          happiness: -3,
          amount: 0,
        },
      ],
    },
    {
      situation: "You drop your phone and crack the screen!",
      type: "Expense",
      category: {
        name: "Fun",
      },
      choices: [
        {
          action: "Patch it with a screen protector",
          happiness: -2,
          amount: 10,
        },
        {
          action: "Get it repaired",
          happiness: 1,
          amount: 120,
        },
        {
          action: "Good excuse for an upgrade!",
          happiness: 3,
          amount: 500,
        },
      ],
    },
    {
      situation: "Awesome sale on a TV: 4K resolution, 50 inches!",
      type: "Expense",
      category: {
        name: "Fun",
      },
      choices: [
        {
          action: "It’s a great deal! Get it!!",
          happiness: 3,
          amount: 250,
        },
        {
          action: "Keep your old one",
          happiness: -2,
          amount: 0,
        },
      ],
    },
    {
      situation: "You're invited out but your best outfit is stained",
      type: "Expense",
      category: {
        name: "Fun",
      },
      choices: [
        {
          action: "Take it to a dry-cleaner",
          happiness: -1,
          amount: 20,
        },
        {
          action: "Go clothes shopping",
          happiness: 1,
          amount: 40,
        },
        {
          action: "Try to get it out yourself",
          happiness: -3,
          amount: 2,
        },
      ],
    },
    {
      situation: "It’s your brother's birthday",
      type: "Expense",
      category: {
        name: "Fun",
      },
      choices: [
        {
          action: "Buy him that new game he’s been wanting",
          happiness: 3,
          amount: 60,
        },
        {
          action: "Get him a balloon",
          happiness: -1,
          amount: 5,
        },
        {
          action: "Grab a gift card for his favorite store",
          happiness: 1,
          amount: 20,
        },
      ],
    },
    {
      situation: "Your favorite sports team is playing this weekend!",
      type: "Expense",
      category: {
        name: "Fun",
      },
      choices: [
        {
          action: "Order take-out and watch at home",
          happiness: 1,
          amount: 10,
        },
        {
          action: "Watch it at your favorite sports bar",
          happiness: 2,
          amount: 20,
        },
        {
          action: "Get tickets!",
          happiness: 3,
          amount: 100,
        },
      ],
    },
    {
      situation: "Your friends are going to the movies",
      type: "Expense",
      category: {
        name: "Fun",
      },
      choices: [
        {
          action: "Stay home and watch YouTube",
          happiness: -1,
          amount: 0,
        },
        {
          action: "Join them, but skip the popcorn",
          happiness: 1,
          amount: 8,
        },
        {
          action: "Go all out and enjoy yourself!",
          happiness: 2,
          amount: 15,
        },
      ],
    },
    {
      situation: "You need a haircut for your date tonight",
      type: "Expense",
      category: {
        name: "Fun",
      },
      choices: [
        {
          action: "Let a student practice on you",
          happiness: -1,
          amount: 15,
        },
        {
          action: "See your favorite stylist",
          happiness: 2,
          amount: 50,
        },
      ],
    },
    // Events below are 2x duplicates of above
    //     // { //27
    //     //     situation: "Super Smash Ultimate just got released! All your friends are getting it!",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Get the Extended Edition with Gamecube controllers",
    //     //             happiness: 3,
    //     //             amount: 100
    //     //         },
    //     //         {
    //     //             action: "Get the normal version",
    //     //             happiness: 2,
    //     //             amount: 60
    //     //         },
    //     //         {
    //     //             action: "Pass",
    //     //             happiness: -3,
    //     //             amount: 0
    //     //         }
    //     //     ]
    //     // },
    //     // { //27
    //     //     situation: "You feel like learning an instrument",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Buy an electric guitar",
    //     //             happiness: 3,
    //     //             amount: 150
    //     //         },
    //     //         {
    //     //             action: "Get a ukulele",
    //     //             happiness: 1,
    //     //             amount: 30
    //     //         },
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "Your friends are all going to Six Flags this weekend",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Sounds amazing. Let’s go!",
    //     //             happiness: 3,
    //     //             amount: 70
    //     //         },
    //     //         {
    //     //             action: "Stay home and feel sad",
    //     //             happiness: -3,
    //     //             amount: 0
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "You drop your phone and crack the screen!",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Patch it with a screen protector",
    //     //             happiness: -2,
    //     //             amount: 10
    //     //         },
    //     //         {
    //     //             action: "Get it repaired",
    //     //             happiness: 1,
    //     //             amount: 120
    //     //         },
    //     //         {
    //     //             action: "Good excuse for an upgrade!",
    //     //             happiness: 3,
    //     //             amount: 500
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "Awesome sale on a TV: 4K resolution, 50 inches!",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "It’s a great deal! Get it!!",
    //     //             happiness: 3,
    //     //             amount: 250
    //     //         },
    //     //         {
    //     //             action: "Keep your old one",
    //     //             happiness: -2,
    //     //             amount: 0
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "You're invited out but your best outfit is stained",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Take it to a dry-cleaner",
    //     //             happiness: -1,
    //     //             amount: 20
    //     //         },
    //     //         {
    //     //             action: "Go clothes shopping",
    //     //             happiness: 1,
    //     //             amount: 40
    //     //         },
    //     //         {
    //     //             action: "Try to get it out yourself",
    //     //             happiness: -3,
    //     //             amount: 2
    //     //         },
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "It’s your brother's birthday",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Buy him that new game he’s been wanting",
    //     //             happiness: 3,
    //     //             amount: 60
    //     //         },
    //     //         {
    //     //             action: "Get him a balloon",
    //     //             happiness: -1,
    //     //             amount: 5
    //     //         },
    //     //         {
    //     //             action: "Grab a gift card for his favorite store",
    //     //             happiness: 1,
    //     //             amount: 20
    //     //         },
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "Your favorite sports team is playing this weekend!",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Order take-out and watch at home",
    //     //             happiness: 1,
    //     //             amount: 10
    //     //         },
    //     //         {
    //     //             action: "Watch it at your favorite sports bar",
    //     //             happiness: 2,
    //     //             amount: 20
    //     //         },
    //     //         {
    //     //             action: "Get tickets!",
    //     //             happiness: 3,
    //     //             amount: 100
    //     //         },
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "Your friends are going to the movies",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Stay home and watch YouTube",
    //     //             happiness: -1,
    //     //             amount: 0
    //     //         },
    //     //         {
    //     //             action: "Join them, but skip the popcorn",
    //     //             happiness: 1,
    //     //             amount: 8
    //     //         },
    //     //         {
    //     //             action: "Go all out and enjoy yourself!",
    //     //             happiness: 2,
    //     //             amount: 15
    //     //         },
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "You need a haircut for your date tonight",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Let a student practice on you",
    //     //             happiness: -1,
    //     //             amount: 15
    //     //         },
    //     //         {
    //     //             action: "See your favorite stylist",
    //     //             happiness: 2,
    //     //             amount: 50
    //     //         },
    //     //     ]
    //     // },
    //     // { //27
    //     //     situation: "Super Smash Ultimate just got released! All your friends are getting it!",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Get the Extended Edition with Gamecube controllers",
    //     //             happiness: 3,
    //     //             amount: 100
    //     //         },
    //     //         {
    //     //             action: "Get the normal version",
    //     //             happiness: 2,
    //     //             amount: 60
    //     //         },
    //     //         {
    //     //             action: "Pass",
    //     //             happiness: -3,
    //     //             amount: 0
    //     //         }
    //     //     ]
    //     // },
    //     // { //27
    //     //     situation: "You feel like learning an instrument",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Buy an electric guitar",
    //     //             happiness: 3,
    //     //             amount: 150
    //     //         },
    //     //         {
    //     //             action: "Get a ukulele",
    //     //             happiness: 1,
    //     //             amount: 30
    //     //         },
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "Your friends are all going to Six Flags this weekend",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Sounds amazing. Let’s go!",
    //     //             happiness: 3,
    //     //             amount: 70
    //     //         },
    //     //         {
    //     //             action: "Stay home and feel sad",
    //     //             happiness: -3,
    //     //             amount: 0
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "You drop your phone and crack the screen!",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Patch it with a screen protector",
    //     //             happiness: -2,
    //     //             amount: 10
    //     //         },
    //     //         {
    //     //             action: "Get it repaired",
    //     //             happiness: 1,
    //     //             amount: 120
    //     //         },
    //     //         {
    //     //             action: "Good excuse for an upgrade!",
    //     //             happiness: 3,
    //     //             amount: 500
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "Awesome sale on a TV: 4K resolution, 50 inches!",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "It’s a great deal! Get it!!",
    //     //             happiness: 3,
    //     //             amount: 250
    //     //         },
    //     //         {
    //     //             action: "Keep your old one",
    //     //             happiness: -2,
    //     //             amount: 0
    //     //         }
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "You're invited out but your best outfit is stained",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Take it to a dry-cleaner",
    //     //             happiness: -1,
    //     //             amount: 20
    //     //         },
    //     //         {
    //     //             action: "Go clothes shopping",
    //     //             happiness: 1,
    //     //             amount: 40
    //     //         },
    //     //         {
    //     //             action: "Try to get it out yourself",
    //     //             happiness: -3,
    //     //             amount: 2
    //     //         },
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "It’s your brother's birthday",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Buy him that new game he’s been wanting",
    //     //             happiness: 3,
    //     //             amount: 60
    //     //         },
    //     //         {
    //     //             action: "Get him a balloon",
    //     //             happiness: -1,
    //     //             amount: 5
    //     //         },
    //     //         {
    //     //             action: "Grab a gift card for his favorite store",
    //     //             happiness: 1,
    //     //             amount: 20
    //     //         },
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "Your favorite sports team is playing this weekend!",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Order take-out and watch at home",
    //     //             happiness: 1,
    //     //             amount: 10
    //     //         },
    //     //         {
    //     //             action: "Watch it at your favorite sports bar",
    //     //             happiness: 2,
    //     //             amount: 20
    //     //         },
    //     //         {
    //     //             action: "Get tickets!",
    //     //             happiness: 3,
    //     //             amount: 100
    //     //         },
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "Your friends are going to the movies",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Stay home and watch YouTube",
    //     //             happiness: -1,
    //     //             amount: 0
    //     //         },
    //     //         {
    //     //             action: "Join them, but skip the popcorn",
    //     //             happiness: 1,
    //     //             amount: 8
    //     //         },
    //     //         {
    //     //             action: "Go all out and enjoy yourself!",
    //     //             happiness: 2,
    //     //             amount: 15
    //     //         },
    //     //     ]
    //     // },
    //     // {
    //     //     situation: "You need a haircut for your date tonight",
    //     //     type: "Expense",
    //     //     category: {
    //     //         name: "Fun",
    //     //     },
    //     //     choices: [{
    //     //             action: "Let a student practice on you",
    //     //             happiness: -1,
    //     //             amount: 15
    //     //         },
    //     //         {
    //     //             action: "See your favorite stylist",
    //     //             happiness: 2,
    //     //             amount: 50
    //     //         },
    //     //     ]
    //     // },
  ],
  transportationEvents: [
    {
      situation: "Your car is making weird noises",
      type: "Expense",
      category: {
        name: "Transportation",
      },
      choices: [
        {
          action: "Get it checked out",
          happiness: 2,
          amount: 50,
        },
        {
          action: "Pretend you can't hear them",
          happiness: -3,
          amount: 0,
        },
        {
          action: "Take the bus to work",
          happiness: 0,
          amount: 5,
        },
      ],
    },
    {
      situation: "Visit your family at the beach",
      type: "Expense",
      category: {
        name: "Transportation",
      },
      choices: [
        {
          action: "Drive your car",
          happiness: 1,
          amount: 100,
        },
        {
          action: "Use Lyft",
          happiness: 2,
          amount: 300,
        },
        {
          action: "Take public transportation",
          happiness: -1,
          amount: 55,
        },
      ],
    },
    {
      situation: "Your car needs an oil change",
      type: "Expense",
      category: {
        name: "Transportation",
      },
      choices: [
        {
          action: "Get the deluxe treatment",
          happiness: 2,
          amount: 75,
        },
        {
          action: "Go as cheap as possible",
          happiness: 0,
          amount: 40,
        },
      ],
    },
    {
      situation: "Your car is out of gas",
      type: "Expense",
      category: {
        name: "Transportation",
      },
      choices: [
        {
          action: "Fill up the tank",
          happiness: 2,
          amount: 40,
        },
        {
          action: "Use Lyft",
          happiness: 0,
          amount: 15,
        },
        {
          action: "Use public transportation",
          happiness: -1,
          amount: 0,
        },
      ],
    },
    //     // events below are 2x duplicates of above
    //     {
    //         situation: "Your car is making weird noises",
    //         type: "Expense",
    //         category: {
    //             name: "Transportation",
    //         },
    //         choices: [{
    //                 action: "Get it checked out",
    //                 happiness: 2,
    //                 amount: 50
    //             },
    //             {
    //                 action: "Pretend you can't hear them",
    //                 happiness: -3,
    //                 amount: 0
    //             },
    //             {
    //                 action: "Take the bus to work",
    //                 happiness: 0,
    //                 amount: 5
    //             }
    //         ]
    //     },
    //     {
    //         situation: "Visit your family at the beach",
    //         type: "Expense",
    //         category: {
    //             name: "Transportation",
    //         },
    //         choices: [{
    //                 action: "Drive your car",
    //                 happiness: 1,
    //                 amount: 100
    //             },
    //             {
    //                 action: "Use Lyft",
    //                 happiness: 2,
    //                 amount: 300
    //             },
    //             {
    //                 action: "Take public transportation",
    //                 happiness: -1,
    //                 amount: 55
    //             }
    //         ]
    //     },
    //     {
    //         situation: "Your car needs an oil change",
    //         type: "Expense",
    //         category: {
    //             name: "Transportation",
    //         },
    //         choices: [{
    //                 action: "Get the deluxe treatment",
    //                 happiness: 2,
    //                 amount: 75
    //             },
    //             {
    //                 action: "Go as cheap as possible",
    //                 happiness: 0,
    //                 amount: 40
    //             }
    //         ]
    //     },
    //     {
    //         situation: "Your car is out of gas",
    //         type: "Expense",
    //         category: {
    //             name: "Transportation",
    //         },
    //         choices: [{
    //                 action: "Fill up the tank",
    //                 happiness: 2,
    //                 amount: 40
    //             },
    //             {
    //                 action: "Use Lyft",
    //                 happiness: 0,
    //                 amount: 15
    //             },
    //             {
    //                 action: "Use public transportation",
    //                 happiness: -1,
    //                 amount: 0
    //             }
    //         ]
    //     },
    //     {
    //         situation: "Your car is making weird noises",
    //         type: "Expense",
    //         category: {
    //             name: "Transportation",
    //         },
    //         choices: [{
    //                 action: "Get it checked out",
    //                 happiness: 2,
    //                 amount: 50
    //             },
    //             {
    //                 action: "Pretend you can't hear them",
    //                 happiness: -3,
    //                 amount: 0
    //             },
    //             {
    //                 action: "Take the bus to work",
    //                 happiness: 0,
    //                 amount: 5
    //             }
    //         ]
    //     },
    //     {
    //         situation: "Visit your family at the beach",
    //         type: "Expense",
    //         category: {
    //             name: "Transportation",
    //         },
    //         choices: [{
    //                 action: "Drive your car",
    //                 happiness: 1,
    //                 amount: 100
    //             },
    //             {
    //                 action: "Use Lyft",
    //                 happiness: 2,
    //                 amount: 300
    //             },
    //             {
    //                 action: "Take public transportation",
    //                 happiness: -1,
    //                 amount: 55
    //             }
    //         ]
    //     },
    //     {
    //         situation: "Your car needs an oil change",
    //         type: "Expense",
    //         category: {
    //             name: "Transportation",
    //         },
    //         choices: [{
    //                 action: "Get the deluxe treatment",
    //                 happiness: 2,
    //                 amount: 75
    //             },
    //             {
    //                 action: "Go as cheap as possible",
    //                 happiness: 0,
    //                 amount: 40
    //             }
    //         ]
    //     },
    //     {
    //         situation: "Your car is out of gas",
    //         type: "Expense",
    //         category: {
    //             name: "Transportation",
    //         },
    //         choices: [{
    //                 action: "Fill up the tank",
    //                 happiness: 2,
    //                 amount: 40
    //             },
    //             {
    //                 action: "Use Lyft",
    //                 happiness: 0,
    //                 amount: 15
    //             },
    //             {
    //                 action: "Use public transportation",
    //                 happiness: -1,
    //                 amount: 0
    //             }
    //         ]
    //     },
  ],
  foodEvents: [
    {
      //9
      situation: "You're walking by a Starbucks. It smells amazing.",
      type: "Expense",
      category: {
        name: "Food",
      },
      choices: [
        {
          action: "Buy a scone and caramel frappuccino",
          happiness: 3,
          amount: 8,
        },
        {
          action: "Settle for a black coffee",
          happiness: 1,
          amount: 4,
        },
        {
          action: "Keep walking",
          happiness: -2,
          amount: 0,
        },
      ],
    },
    {
      situation: "You had to stay at work past supper time",
      type: "Expense",
      category: {
        name: "Food",
      },
      choices: [
        {
          action: "Stop by a 24-hour diner",
          happiness: 0,
          amount: 10,
        },
        {
          action: "Eat at Olive Garden",
          happiness: 2,
          amount: 25,
        },
      ],
    },
    {
      situation: "You're feeling tired on your way to work",
      type: "Expense",
      category: {
        name: "Food",
      },
      choices: [
        {
          action: "Swing by Starbucks",
          happiness: 2,
          amount: 6,
        },
        {
          action: "Go to a corner gas station",
          happiness: 0,
          amount: 2,
        },
        {
          action: "Pinch yourself to stay awake.",
          happiness: -2,
          amount: 0,
        },
      ],
    },
    {
      //23
      situation: "The power goes out and your refrigerated food spoils",
      type: "Expense",
      category: {
        name: "Food",
      },
      choices: [
        {
          action: "Go buy more groceries",
          happiness: 0,
          amount: 40,
        },
        {
          action: "Eat ramen noodles all week",
          happiness: -1,
          amount: 5,
        },
        {
          action: "Go out to eat every day",
          happiness: 2,
          amount: 60,
        },
      ],
    },
    {
      situation: "You’re going on a business trip",
      type: "Expense",
      category: {
        name: "Food",
      },
      choices: [
        {
          action: "Pack leftovers",
          happiness: -1,
          amount: 0,
        },
        {
          action: "Eat at Chick-fil-a",
          happiness: 2,
          amount: 10,
        },
        {
          action: "Swing by a taco truck",
          happiness: 0,
          amount: 6,
        },
      ],
    },
    {
      situation: "You forgot your lunch!",
      type: "Expense",
      category: {
        name: "Food",
      },
      choices: [
        {
          action: "Go to Chipotle",
          happiness: 2,
          amount: 8,
        },
        {
          action: "Find a vending machine",
          happiness: 0,
          amount: 2,
        },
        {
          action: "Skip lunch and be crabby",
          happiness: -2,
          amount: 0,
        },
      ],
    },
    {
      situation: "It’s your parents’ anniversary and you want to treat them.",
      type: "Expense",
      category: {
        name: "Food",
      },
      choices: [
        {
          action: "Go out for ice cream",
          happiness: 1,
          amount: 25,
        },
        {
          action: "Cook a nice dinner",
          happiness: -1,
          amount: 20,
        },
        {
          action: "Take them to their favorite restaurant",
          happiness: 3,
          amount: 60,
        },
      ],
    },
    {
      situation: "You're too tired to cook dinner tonight.",
      type: "Expense",
      category: {
        name: "Food",
      },
      choices: [
        {
          action: "Eat out.",
          happiness: 2,
          amount: 18,
        },
        {
          action: "Order pizza.",
          happiness: 1,
          amount: 12,
        },
        {
          action: "Scrounge in the cupboards",
          happiness: -1,
          amount: 0,
        },
      ],
    },
    {
      situation: "Your friends are planning a movie marathon",
      type: "Expense",
      category: {
        name: "Food",
      },
      choices: [
        {
          action: "Order a stack of pizzas to last the night!",
          happiness: 3,
          amount: 40,
        },
        {
          action: "Get frozen pizzas from Costco",
          happiness: 2,
          amount: 20,
        },
        {
          action: "Stick with cheese and crackers",
          happiness: -1,
          amount: 10,
        },
      ],
    },
    // Events below are 2x duplicates of above
    // { //9
    //     situation: "You're walking by a Starbucks. It smells amazing.",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Buy a scone and caramel frappuccino",
    //             happiness: 3,
    //             amount: 8
    //         },
    //         {
    //             action: "Settle for a black coffee",
    //             happiness: 1,
    //             amount: 4
    //         },
    //         {
    //             action: "Keep walking",
    //             happiness: -2,
    //             amount: 0
    //         }
    //     ]
    // },
    // {
    //     situation: "You had to stay at work past supper time",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Stop by a 24-hour diner",
    //             happiness: 0,
    //             amount: 10
    //         },
    //         {
    //             action: "Eat at Olive Garden",
    //             happiness: 2,
    //             amount: 25
    //         },

    //     ]
    // },
    // {
    //     situation: "You're feeling tired on your way to work",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Swing by Starbucks",
    //             happiness: 2,
    //             amount: 6
    //         },
    //         {
    //             action: "Go to a corner gas station",
    //             happiness: 0,
    //             amount: 2
    //         },
    //         {
    //             action: "Pinch yourself to stay awake.",
    //             happiness: -2,
    //             amount: 0
    //         }
    //     ]
    // },
    // { //23
    //     situation: "The power goes out and your refrigerated food spoils",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Go buy more groceries",
    //             happiness: 0,
    //             amount: 40
    //         },
    //         {
    //             action: "Eat ramen noodles all week",
    //             happiness: -1,
    //             amount: 5
    //         },
    //         {
    //             action: "Go out to eat every day",
    //             happiness: 2,
    //             amount: 60
    //         }
    //     ]
    // },
    // {
    //     situation: "You’re going on a business trip",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Pack leftovers",
    //             happiness: -1,
    //             amount: 0
    //         },
    //         {
    //             action: "Eat at Chick-fil-a",
    //             happiness: 2,
    //             amount: 10
    //         },
    //         {
    //             action: "Swing by a taco truck",
    //             happiness: 0,
    //             amount: 6
    //         }
    //     ]
    // },
    // {
    //     situation: "You forgot your lunch!",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Go to Chipotle",
    //             happiness: 2,
    //             amount: 8
    //         },
    //         {
    //             action: "Find a vending machine",
    //             happiness: 0,
    //             amount: 2
    //         },
    //         {
    //             action: "Skip lunch and be crabby",
    //             happiness: -2,
    //             amount: 0
    //         }
    //     ]
    // },
    // {
    //     situation: "It’s your parents’ anniversary and you want to treat them.",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Go out for ice cream",
    //             happiness: 1,
    //             amount: 25
    //         },
    //         {
    //             action: "Cook a nice dinner",
    //             happiness: -1,
    //             amount: 20
    //         },
    //         {
    //             action: "Take them to their favorite restaurant",
    //             happiness: 3,
    //             amount: 60
    //         }
    //     ]
    // },
    // {
    //     situation: "You're too tired to cook dinner tonight.",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Eat out.",
    //             happiness: 2,
    //             amount: 18
    //         },
    //         {
    //             action: "Order pizza.",
    //             happiness: 1,
    //             amount: 12
    //         },
    //         {
    //             action: "Scrounge in the cupboards",
    //             happiness: -1,
    //             amount: 0
    //         }
    //     ]
    // },
    // {
    //     situation: "Your friends are planning a movie marathon",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Order a stack of pizzas to last the night!",
    //             happiness: 3,
    //             amount: 40
    //         },
    //         {
    //             action: "Get frozen pizzas from Costco",
    //             happiness: 2,
    //             amount: 20
    //         },
    //         {
    //             action: "Stick with cheese and crackers",
    //             happiness: -1,
    //             amount: 10
    //         }
    //     ]
    // },
    // { //9
    //     situation: "You're walking by a Starbucks. It smells amazing.",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Buy a scone and caramel frappuccino",
    //             happiness: 3,
    //             amount: 8
    //         },
    //         {
    //             action: "Settle for a black coffee",
    //             happiness: 1,
    //             amount: 4
    //         },
    //         {
    //             action: "Keep walking",
    //             happiness: -2,
    //             amount: 0
    //         }
    //     ]
    // },
    // {
    //     situation: "You had to stay at work past supper time",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Stop by a 24-hour diner",
    //             happiness: 0,
    //             amount: 10
    //         },
    //         {
    //             action: "Eat at Olive Garden",
    //             happiness: 2,
    //             amount: 25
    //         },

    //     ]
    // },
    // {
    //     situation: "You're feeling tired on your way to work",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Swing by Starbucks",
    //             happiness: 2,
    //             amount: 6
    //         },
    //         {
    //             action: "Go to a corner gas station",
    //             happiness: 0,
    //             amount: 2
    //         },
    //         {
    //             action: "Pinch yourself to stay awake.",
    //             happiness: -2,
    //             amount: 0
    //         }
    //     ]
    // },
    // { //23
    //     situation: "The power goes out and your refrigerated food spoils",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Go buy more groceries",
    //             happiness: 0,
    //             amount: 40
    //         },
    //         {
    //             action: "Eat ramen noodles all week",
    //             happiness: -1,
    //             amount: 5
    //         },
    //         {
    //             action: "Go out to eat every day",
    //             happiness: 2,
    //             amount: 60
    //         }
    //     ]
    // },
    // {
    //     situation: "You’re going on a business trip",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Pack leftovers",
    //             happiness: -1,
    //             amount: 0
    //         },
    //         {
    //             action: "Eat at Chick-fil-a",
    //             happiness: 2,
    //             amount: 10
    //         },
    //         {
    //             action: "Swing by a taco truck",
    //             happiness: 0,
    //             amount: 6
    //         }
    //     ]
    // },
    // {
    //     situation: "You forgot your lunch!",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Go to Chipotle",
    //             happiness: 2,
    //             amount: 8
    //         },
    //         {
    //             action: "Find a vending machine",
    //             happiness: 0,
    //             amount: 2
    //         },
    //         {
    //             action: "Skip lunch and be crabby",
    //             happiness: -2,
    //             amount: 0
    //         }
    //     ]
    // },
    // {
    //     situation: "It’s your parents’ anniversary and you want to treat them.",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Go out for ice cream",
    //             happiness: 1,
    //             amount: 25
    //         },
    //         {
    //             action: "Cook a nice dinner",
    //             happiness: -1,
    //             amount: 20
    //         },
    //         {
    //             action: "Take them to their favorite restaurant",
    //             happiness: 3,
    //             amount: 60
    //         }
    //     ]
    // },
    // {
    //     situation: "You're too tired to cook dinner tonight.",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Eat out.",
    //             happiness: 2,
    //             amount: 18
    //         },
    //         {
    //             action: "Order pizza.",
    //             happiness: 1,
    //             amount: 12
    //         },
    //         {
    //             action: "Scrounge in the cupboards",
    //             happiness: -1,
    //             amount: 0
    //         }
    //     ]
    // },
    // {
    //     situation: "Your friends are planning a movie marathon",
    //     type: "Expense",
    //     category: {
    //         name: "Food",
    //     },
    //     choices: [{
    //             action: "Order a stack of pizzas to last the night!",
    //             happiness: 3,
    //             amount: 40
    //         },
    //         {
    //             action: "Get frozen pizzas from Costco",
    //             happiness: 2,
    //             amount: 20
    //         },
    //         {
    //             action: "Stick with cheese and crackers",
    //             happiness: -1,
    //             amount: 10
    //         }
    //     ]
    // },
  ],
  insuranceEvents: [],
};

export default allEvents;
