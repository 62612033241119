import firebase from "firebase/app";
import { toast } from "react-toastify";
import {
  insertExistingCalendarIntoUserCalendars,
  checkForGoogleDriveAccess,
} from "src/api/googleCalendar";

export const checkForUsersPermissions = (authUser) => {
  return async function (dispatch) {
    let calendarAccess = authUser.hasGoogleCalendarPermission;
    let driveAccess = authUser.hasGoogleDrivePermission;
    if (authUser.calendarId && !authUser.hasGoogleCalendarPermission) {
      // if the google has a calendarId and doesnt have calendar permissions, we make the request to check
      const calendarRequest = await insertExistingCalendarIntoUserCalendars(
        authUser.calendarId
      );
      if (calendarRequest.status === 403) {
        calendarAccess = false;
      } else {
        calendarAccess = true;
      }
    }

    if (!authUser.hasGoogleDrivePermission) {
      const googleDriveRequest = await checkForGoogleDriveAccess(); // if user doesnt have drive permissions, we make the request to check
      if (googleDriveRequest.status === 403) {
        driveAccess = false;
      } else {
        driveAccess = true;
      }
    }

    if (
      driveAccess !== authUser.hasGoogleDrivePermission ||
      calendarAccess !== authUser.hasGoogleCalendarPermission
    ) {
      //is nothing has changed, we dont call update the patient
      firebase.database().ref(`users/${authUser.uid}`).update({
        hasGoogleCalendarPermission: calendarAccess,
        hasGoogleDrivePermission: driveAccess,
      });
    }
  };
};

export const leaveClass = async (authUser, firebase) => {
  try {
    await firebase.user(authUser.uid).update({
      classroomId: null,
      classId: null,
      classUID: null,
      calendarId: null,
      hasGoogleCalendarPermission: false,
    });

    await firebase.userFromChatroom(authUser?.classUID, authUser?.uid).remove();
    console.log("Classroom 24 | left class");
    toast.success("Left Classroom");
  } catch (error) {
    console.log("Classroom 22 | error leaving classroom");
  }
};
