import React from 'react'
import Cell from '../Cell.js'
import PulsatingButton from '../PulsatingButton.js'

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,

  },
  specialCharacter: {
    fontWeight: 'bold',
    color: 'orange'
  }
}

export const creditSteps = [
  {
    title: 'Debt Management 101',
    text: (
      <span style={{ fontSize: 16 }}>
        Today you'll learn how to manage debt. Specifically, you'll learn how to pay off College
        Debt. Type <span style={styles.specialCharacter}>College Debt </span>in cell <span style={styles.specialCharacter}>A1</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <Cell cellName={'A1'} onTextChange={setCellText} />
      </div>
    ),
    answer: 'college debt',
    range: 'A1',
  },
  {
    title: 'Debts',
    button: 'Get Loan!',
    text: (
      <span style={{ fontSize: 16 }}>
        You want to go to college and don't have enought money to pay for it. You take a loan for
        $10,000.
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} onTextChange={setCellText} />
        </div>
        <PulsatingButton text={"Get loan!"} color={"green"} onClick={getPaid} />
      </div>
    ),
    answer: '10000',
    range: 'B1',
  },
  {
    title: 'Debts',
    text: (
      <span style={{ fontSize: 16 }}>
        "You want to go to college and don't have enought money to pay for it. You take a loan for
        $10,000.
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
      </div>
    ),
    answer: '10000',
    isDefault: true,
    range: 'B1',
  },
  {
    title: 'Interest',
    text: (
      <span style={{ fontSize: 16 }}>
        Every debt has interest. This is how much they charge you per year for owing money.
        Type <span style={styles.specialCharacter}>Interest</span> in cell <span style={styles.specialCharacter}>A2</span>
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: 'interest',
    range: 'A2',
  },
  {
    title: 'Interest II',
    text: <span style={{ fontSize: 16 }}>You'll get charged 12% for the debt. Type <span style={styles.specialCharacter}>0.12</span> on cell <span style={styles.specialCharacter}>B2</span> </span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: '0.12',
    range: 'B2',
  },
  {
    title: 'Step by Step Debt',
    text: (
      <span style={{ fontSize: 16 }}>
        We'll teach you each step on how debt works. Let's start with the years. Type <span style={styles.specialCharacter}>Year 1</span> in cell
        <span style={styles.specialCharacter}> B5</span>.
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: 'year 1',
    range: 'B5',
  },
  {
    title: 'Initial Debt',
    text: <span style={{ fontSize: 16 }}>Start with Initial Debt. Type <span style={styles.specialCharacter}>Initial Debt</span> in cell <span style={styles.specialCharacter}>A6</span></span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: 'initial debt',
    range: 'A6',
  },
  {
    title: 'College Debt',
    button: 'College Debt',
    text: <span style={{ fontSize: 16 }}>You have just taken the loan to pay for college.</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} onTextChange={setCellText} />
        </div>
        <PulsatingButton text={"Pay college debt"} onClick={getPaid} />

      </div>
    ),
    answer: '10000',
    range: 'B6',
  },
  {
    title: 'College Debt',
    text: <span style={{ fontSize: 16 }}>You have just taken the loan to pay for college.</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
      </div>
    ),
    answer: '10000',
    isDefault: true,
    range: 'B6',
  },
  {
    title: 'Interest',
    text: (
      <span style={{ fontSize: 16 }}>
        Every year, interest is added FIRST. Yes, you don't pay the debts as they are, you pay them
        after they GROW. Type <span style={styles.specialCharacter}>Interest</span> in cell <span style={styles.specialCharacter}>A7</span>{' '}
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: 'interest',
    range: 'A7',
  },
  {
    title: 'Interest to be paid',
    text: <span style={{ fontSize: 16 }}>Calculate your total interest to be paid on cell <span style={styles.specialCharacter}>B7. Multiply your initial debt by the interest</span>.</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: '1200',
    range: 'B7',
  },
  {
    title: 'Total Debt to Pay',
    text: (
      <span style={{ fontSize: 16 }}>Let's add the initial debt plus the interest. Type <span style={styles.specialCharacter}>Debt + Interest</span> in cell <span style={styles.specialCharacter}>A8</span></span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: 'debt + interest',
    range: 'A8',
  },
  {
    title: 'Add Debt Plus Interest',
    text: <span style={{ fontSize: 16 }}>Calculate Debt + Interest in cell <span style={styles.specialCharacter}>B8</span>. This is how much you currently owe</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'A3'} onTextChange={setCellText} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: '11200',
    range: 'B8',
  },
  {
    title: 'Minimum Payment',
    text: (
      <span style={{ fontSize: 16 }}>There's a minimum payment your bank has given you. Type <span style={styles.specialCharacter}>Min Payment</span> in cell <span style={styles.specialCharacter}>A3</span></span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} onTextChange={setCellText} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
        </div>
      </div>
    ),
    answer: 'min payment',
    range: 'A3',
  },
  {
    title: 'Min Payment II',
    button: 'Min Payment',
    text: (
      <span style={{ fontSize: 16 }}>
        You have to always pay AT LEAST the minimum payment each year. Press the button, then CHECK.
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} onTextChange={setCellText} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
        </div>
        <PulsatingButton text={"Pay min payment"} onClick={getPaid} />
      </div>
    ),
    answer: '1000',
    range: 'B3',
  },
  {
    title: 'Min Payment II',
    text: (
      <span style={{ fontSize: 16 }}>
        You have to always pay AT LEAST the minimum payment each year. Press the button, then CHECK.
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
        </div>
      </div>
    ),
    answer: '1000',
    isDefault: true,
    range: 'B3',
  },
  {
    title: 'Making Payments I',
    text: <span style={{ fontSize: 16 }}>Type <span style={styles.specialCharacter}>Payment</span> in cell <span style={styles.specialCharacter}>A9</span>. Then press CHECK.</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: 'payment',
    range: 'A9',
  },
  {
    title: 'Make the minimum payment',
    text: <span style={{ fontSize: 16 }}>Type the minimum payment dollar amount on cell B9.</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: '1000',
    range: 'B9',
  },
  {
    title: 'How much left?',
    text: <span style={{ fontSize: 16 }}>Let's see how much debt is left. Type <span style={styles.specialCharacter}>Total Debt</span> on cell <span style={styles.specialCharacter}>A10</span>. </span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: 'total debt',
    range: 'A10',
  },
  {
    title: 'Calculate total debt',
    text: <span style={{ fontSize: 16 }}>Subtract the Payment from the Debt + Interest on cell <span style={styles.specialCharacter}>B10</span>.</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{ marginLeft: 120 }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} value={'Total debt'} editable={false} />
          <Cell cellName={'B10'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: '10200',
    range: 'B10',
  },
  {
    title: 'WARNING: Do you notice the problem?',
    text: (
      <span style={{ fontSize: 16 }}>
        Look at the comparison between your Initial Debt and your Total Debt at the end. IT'S
        HIGHER. Yes, even though you paid off your debt, it actually INCREASED! Let's fix it on Year
        2. Type <span style={styles.specialCharacter}>Year 2</span> in cell <span style={styles.specialCharacter}>C5</span>{' '}
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 120,
            justifyContent: 'flex-start',
          }}
        >
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
          <Cell cellName={'C5'} onTextChange={setCellText} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} value={'Total debt'} editable={false} />
          <Cell cellName={'B10'} value={'$10,200'} editable={false} />
        </div>
      </div>
    ),
    answer: 'year 2',
    range: 'C5',
  },
  {
    title: 'Year 2',
    text: <span style={{ fontSize: 16 }}>Your initial debt on Year 2 is the total debt of Year 1. Type it on cell <span style={styles.specialCharacter}>C6</span></span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 120,
            justifyContent: 'flex-start',
          }}
        >
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
          <Cell cellName={'C5'} value={'Year 2'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
          <Cell cellName={'C6'} onTextChange={setCellText} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} value={'Total debt'} editable={false} />
          <Cell cellName={'B10'} value={'$10,200'} editable={false} />
        </div>
      </div>
    ),
    answer: '10200',
    range: 'C6',
  },
  {
    title: 'Interest',
    text: (
      <span style={{ fontSize: 16 }}>
        Calculate the interest. Multiply your Initial Debt on Year 2 times the interest on cell B2.
        Multiply Initial Debt in Year 2 times the interest on cell <span style={styles.specialCharacter}>B2</span>
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 120,
            justifyContent: 'flex-start',
          }}
        >
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
          <Cell cellName={'C5'} value={'Year 2'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
          <Cell cellName={'C6'} value={'$10,200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
          <Cell cellName={'C7'} onTextChange={setCellText} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} value={'Total debt'} editable={false} />
          <Cell cellName={'B10'} value={'$10,200'} editable={false} />
        </div>
      </div>
    ),
    answer: '1224',
    range: 'C7',
  },
  {
    title: 'Debt + Interest',
    text: <span style={{ fontSize: 16 }}>Add the Initial Debt on Year 2 with the interest on cell <span style={styles.specialCharacter}>C8</span></span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: 120,
          justifyContent: 'flex-start',
        }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
          <Cell cellName={'C5'} value={'Year 2'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
          <Cell cellName={'C6'} value={'$10,200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
          <Cell cellName={'C7'} value={'$1,224'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
          <Cell cellName={'C8'} onTextChange={setCellText} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} value={'Total debt'} editable={false} />
          <Cell cellName={'B10'} value={'$10,200'} editable={false} />
        </div>
      </div>
    ),
    answer: '11424',
    range: 'C8',
  },
  {
    title: 'Min Payment: CAREFUL',
    text: (
      <span style={{ fontSize: 16 }}>
        "As you can see, your debt is currently much higher than your Initial Debt. Let's pay more
        on the minimum payment this time to reduce debt. On cell <span style={styles.specialCharacter}>C9</span> make a payment that reduces your debt to
        $9,000.
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: 120,
          justifyContent: 'flex-start',
        }}>
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
          <Cell cellName={'C5'} value={'Year 2'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
          <Cell cellName={'C6'} value={'$10,200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
          <Cell cellName={'C7'} value={'$1,224'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
          <Cell cellName={'C8'} value={'$11,424'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
          <Cell cellName={'C9'} onTextChange={setCellText} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} value={'Total debt'} editable={false} />
          <Cell cellName={'B10'} value={'$10,200'} editable={false} />
        </div>
      </div>
    ),
    answer: '2424',
    range: 'C9',
  },
  {
    title: 'Total Debt',
    text: (
      <span style={{ fontSize: 16 }}>Now calculate total debt. Substract (Debt + Interest) - (Payment) on cell <span style={styles.specialCharacter}>C10</span> .</span>
    ),
    content: (setCellText, getPaid) => (
      <div>
        <div style={{ justifyContent: 'flex-start' }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Cell cellName={'A1'} value={'College Debt'} editable={false} />
            <Cell cellName={'B1'} value={'$10,000'} editable={false} />
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Cell cellName={'A2'} value={'Interest'} editable={false} />
            <Cell cellName={'B2'} value={'0.12'} editable={false} />
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
            <Cell cellName={'B3'} value={'$1000'} editable={false} />
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 120,
            justifyContent: 'flex-start',
          }}>
            <Cell cellName={'B5'} value={'Year 1'} editable={false} />
            <Cell cellName={'C5'} value={'Year 2'} editable={false} />
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
            <Cell cellName={'B6'} value={'$10,000'} editable={false} />
            <Cell cellName={'C6'} value={'$10,200'} editable={false} />
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Cell cellName={'A7'} value={'Interest'} editable={false} />
            <Cell cellName={'B7'} value={'1200'} editable={false} />
            <Cell cellName={'C7'} value={'$1,224'} editable={false} />
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
            <Cell cellName={'B8'} value={'$11,200'} editable={false} />
            <Cell cellName={'C8'} value={'$11,424'} editable={false} />
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Cell cellName={'A9'} value={'Payment'} editable={false} />
            <Cell cellName={'B9'} value={'$1000'} editable={false} />
            <Cell cellName={'C9'} value={'$2,424'} editable={false} />
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Cell cellName={'A10'} value={'Total debt'} editable={false} />
            <Cell cellName={'B10'} value={'$10,200'} editable={false} />
            <Cell cellName={'C10'} onTextChange={setCellText} />
          </div>
        </div>
      </div>
    ),
    answer: '9000',
    range: 'C10',
  },
  {
    title: 'Year 3',
    text: <span style={{ fontSize: 16 }}>Type <span style={styles.specialCharacter}>Year 3</span> on cell <span style={styles.specialCharacter}>D5</span>.</span>,
    content: (setCellText, getPaid) => (
      <div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A5'} value={''} editable={false} />
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
          <Cell cellName={'C5'} value={'Year 2'} editable={false} />
          <Cell cellName={'D5'} onTextChange={setCellText} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
          <Cell cellName={'C6'} value={'$10,200'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
          <Cell cellName={'C7'} value={'$1,224'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
          <Cell cellName={'C8'} value={'$11,424'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
          <Cell cellName={'C9'} value={'$2,424'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} value={'Total debt'} editable={false} />
          <Cell cellName={'B10'} value={'$10,200'} editable={false} />
          <Cell cellName={'C10'} value={'$9,000'} editable={false} />
        </div>
      </div>
    ),
    answer: 'year 3',
    range: 'D5',
  },
  /////////
  {
    title: 'Year 3 values',
    text: <span style={{ fontSize: 16 }}>We copied the values of year 3 to make your life easier, take a look and press check!.</span>,
    content: (setCellText, getPaid) => (
      <div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A5'} value={''} editable={false} />
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
          <Cell cellName={'C5'} value={'Year 2'} editable={false} />
          <Cell cellName={'D5'} value={'Year 3'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
          <Cell cellName={'C6'} value={'$10,200'} editable={false} />
          <Cell cellName={'C6'} value={'$9,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
          <Cell cellName={'C7'} value={'$1,224'} editable={false} />
          <Cell cellName={'C7'} value={'$1,080'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
          <Cell cellName={'C8'} value={'$11,424'} editable={false} />
          <Cell cellName={'C8'} value={'$10,080'} editable={false} />

        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
          <Cell cellName={'C9'} value={'$2,424'} editable={false} />
          <Cell cellName={'C9'} value={'$2,424'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} value={'Total debt'} editable={false} />
          <Cell cellName={'B10'} value={'$10,200'} editable={false} />
          <Cell cellName={'C10'} value={'$9,000'} editable={false} />
          <Cell cellName={'C10'} value={'$7,656'} editable={false} />
        </div>
      </div>
    ),
    answer: 'year 4',
    isDefault: true,
    range: 'E5',
  },
  {
    title: 'Finish Paying off debt',
    text: <span style={{ fontSize: 16 }}>On Year 4 we'll pay off all your debts, Type <span style={styles.specialCharacter}>Year 4</span> con cell <span style={styles.specialCharacter}>E5</span>.</span>,
    content: (setCellText, getPaid) => (
      <div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A5'} value={''} editable={false} />
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
          <Cell cellName={'C5'} value={'Year 2'} editable={false} />
          <Cell cellName={'D5'} value={'Year 3'} editable={false} />
          <Cell cellName={'E5'} onTextChange={setCellText} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
          <Cell cellName={'C6'} value={'$10,200'} editable={false} />
          <Cell cellName={'C6'} value={'$9,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
          <Cell cellName={'C7'} value={'$1,224'} editable={false} />
          <Cell cellName={'C7'} value={'$1,080'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
          <Cell cellName={'C8'} value={'$11,424'} editable={false} />
          <Cell cellName={'C8'} value={'$10,080'} editable={false} />

        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
          <Cell cellName={'C9'} value={'$2,424'} editable={false} />
          <Cell cellName={'C9'} value={'$2,424'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} value={'Total debt'} editable={false} />
          <Cell cellName={'B10'} value={'$10,200'} editable={false} />
          <Cell cellName={'C10'} value={'$9,000'} editable={false} />
          <Cell cellName={'C10'} value={'$7,656'} editable={false} />
        </div>
      </div>
    ),
    answer: 'year 4',
    range: 'E5',
  },
  {
    title: 'Year 4 values',
    text: <span style={{ fontSize: 16 }}>We have now made life easier for you and copied the values for year 4, take a good look. Now on cell <span style={styles.specialCharacter}>E9</span> type the amount that's left to pay it fully</span>,
    content: (setCellText, getPaid) => (
      <div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A5'} value={''} editable={false} />
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
          <Cell cellName={'C5'} value={'Year 2'} editable={false} />
          <Cell cellName={'D5'} value={'Year 3'} editable={false} />
          <Cell cellName={'E5'} value={'Year 4'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
          <Cell cellName={'C6'} value={'$10,200'} editable={false} />
          <Cell cellName={'D6'} value={'$9,000'} editable={false} />
          <Cell cellName={'E6'} value={'$7,656'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
          <Cell cellName={'C7'} value={'$1,224'} editable={false} />
          <Cell cellName={'D7'} value={'$1,080'} editable={false} />
          <Cell cellName={'E7'} value={'$918.72'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
          <Cell cellName={'C8'} value={'$11,424'} editable={false} />
          <Cell cellName={'D8'} value={'$10,080'} editable={false} />
          <Cell cellName={'E8'} value={'$8,574.72'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
          <Cell cellName={'C9'} value={'$2,424'} editable={false} />
          <Cell cellName={'D9'} value={'$2,424'} editable={false} />
          <Cell cellName={'E9'} onTextChange={setCellText} />

        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} value={'Total debt'} editable={false} />
          <Cell cellName={'B10'} value={'$10,200'} editable={false} />
          <Cell cellName={'C10'} value={'$9,000'} editable={false} />
          <Cell cellName={'D10'} value={'$7,656'} editable={false} />
        </div>
      </div>
    ),
    answer: '8574.72',
    range: 'E9',
  },
  {
    title: 'Total Interest',
    text: <span style={{ fontSize: 16 }}>Now let's see how much you actually paid for the total loan. Type <span style={styles.specialCharacter}>Total Interest</span> on cell <span style={styles.specialCharacter}>F6</span></span>,
    content: (setCellText, getPaid) => (
      <div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A5'} value={''} editable={false} />
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
          <Cell cellName={'C5'} value={'Year 2'} editable={false} />
          <Cell cellName={'D5'} value={'Year 3'} editable={false} />
          <Cell cellName={'E5'} value={'Year 4'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
          <Cell cellName={'C6'} value={'$10,200'} editable={false} />
          <Cell cellName={'D6'} value={'$9,000'} editable={false} />
          <Cell cellName={'E6'} value={'$7,656'} editable={false} />
          <Cell cellName={'F6'} onTextChange={setCellText} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
          <Cell cellName={'C7'} value={'$1,224'} editable={false} />
          <Cell cellName={'D7'} value={'$1,080'} editable={false} />
          <Cell cellName={'E7'} value={'$918.72'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
          <Cell cellName={'C8'} value={'$11,424'} editable={false} />
          <Cell cellName={'D8'} value={'$10,080'} editable={false} />
          <Cell cellName={'E8'} value={'$8,574.72'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
          <Cell cellName={'C9'} value={'$2,424'} editable={false} />
          <Cell cellName={'D9'} value={'$2,424'} editable={false} />
          <Cell cellName={'E9'} value={'$8,574.72'} editable={false} />

        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} value={'Total debt'} editable={false} />
          <Cell cellName={'B10'} value={'$10,200'} editable={false} />
          <Cell cellName={'C10'} value={'$9,000'} editable={false} />
          <Cell cellName={'D10'} value={'$7,656'} editable={false} />
        </div>
      </div>
    ),
    answer: 'total interest',
    range: 'F6',
  },
  {
    title: 'Total Interest II',
    text: <span style={{ fontSize: 16 }}>On cell <span style={styles.specialCharacter}>F7</span>, sum all the interest values from Year 1 to Year 4</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A1'} value={'College Debt'} editable={false} />
          <Cell cellName={'B1'} value={'$10,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A2'} value={'Interest'} editable={false} />
          <Cell cellName={'B2'} value={'0.12'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A3'} value={'Min Payment'} editable={false} />
          <Cell cellName={'B3'} value={'$1000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A5'} value={''} editable={false} />
          <Cell cellName={'B5'} value={'Year 1'} editable={false} />
          <Cell cellName={'C5'} value={'Year 2'} editable={false} />
          <Cell cellName={'D5'} value={'Year 3'} editable={false} />
          <Cell cellName={'E5'} value={'Year 4'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A6'} value={'Initial Debt'} editable={false} />
          <Cell cellName={'B6'} value={'$10,000'} editable={false} />
          <Cell cellName={'C6'} value={'$10,200'} editable={false} />
          <Cell cellName={'D6'} value={'$9,000'} editable={false} />
          <Cell cellName={'E6'} value={'$7,656'} editable={false} />
          <Cell cellName={'F6'} value={'Total Interest'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A7'} value={'Interest'} editable={false} />
          <Cell cellName={'B7'} value={'1200'} editable={false} />
          <Cell cellName={'C7'} value={'$1,224'} editable={false} />
          <Cell cellName={'D7'} value={'$1,080'} editable={false} />
          <Cell cellName={'E7'} value={'$918.72'} editable={false} />
          <Cell cellName={'F7'} onTextChange={setCellText} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A8'} value={'Debt + Interest'} editable={false} />
          <Cell cellName={'B8'} value={'$11,200'} editable={false} />
          <Cell cellName={'C8'} value={'$11,424'} editable={false} />
          <Cell cellName={'D8'} value={'$10,080'} editable={false} />
          <Cell cellName={'E8'} value={'$8,574.72'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A9'} value={'Payment'} editable={false} />
          <Cell cellName={'B9'} value={'$1000'} editable={false} />
          <Cell cellName={'C9'} value={'$2,424'} editable={false} />
          <Cell cellName={'D9'} value={'$2,424'} editable={false} />
          <Cell cellName={'E9'} value={'$8,574.72'} editable={false} />

        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'A10'} value={'Total debt'} editable={false} />
          <Cell cellName={'B10'} value={'$10,200'} editable={false} />
          <Cell cellName={'C10'} value={'$9,000'} editable={false} />
          <Cell cellName={'D10'} value={'$7,656'} editable={false} />
        </div>
      </div>
    ),
    answer: '4422.72',
    range: 'F7',
  },
  {
    title: "Congratulations!",
    finished: true,
    text: <span style={{ fontSize: 16 }}>You finished the credit interactive lesson! Now you know that The faster you pay your debts, the cheaper they will be. Be careful with interest and minimum payments</span>,
  }
]
