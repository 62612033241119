import React from "react";
import { useState, useCallback, useEffect } from "react";
import CreditGameWinModal from "./CreditGameWinModal";
import PortalPopup from "../../../../../PortalPopup";
import "./OpenWinModal.css";
export const OpenWinModal = ({ isOpen, resetGame, handleNext }) => {
  const [isCreditGameWinModalPopupOpen, setCreditGameWinModalPopupOpen] =
    useState(false);

  useEffect(() => {
    if (isOpen === "win") {
      setCreditGameWinModalPopupOpen(true);
    }
  }, [isOpen]);

  const closeCreditGameWinModalPopup = useCallback(() => {
    setCreditGameWinModalPopupOpen(false);
  }, []);

  return (
    <>
      {isCreditGameWinModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeCreditGameWinModalPopup}
        >
          <CreditGameWinModal
            onClose={closeCreditGameWinModalPopup}
            resetGame={resetGame}
            handleNext={handleNext}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default OpenWinModal;
