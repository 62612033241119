import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.css";
import { AuthUserContext } from "src/components/Session";

const CurrentChat = ({
  firebase,
  chatId,
  classId,
  userId,
  isGroupChat = false,
}) => {
  const [currentChat, setCurrentChat] = useState(null);
  const [currentMessage, setCurrentMessage] = useState("");

  const authUser = useContext(AuthUserContext);

  const scrollViewRef = useRef();

  useEffect(() => {
    getChatData();
  }, [chatId]);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
  }, [currentChat]);

  useEffect(() => {
    // Attach the listener to receive new messages in real-time
    const chatRef = firebase.chatMessages(classId, chatId);

    const unsubscribe = chatRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        setCurrentChat(snapshot.val());
      } else {
        setCurrentChat(null);
      }
    });

    return () => chatRef.off("value", unsubscribe); // Clean up the listener when the component unmounts or dependencies change
  }, [chatId, firebase, classId]);

  const getChatData = async () => {
    setCurrentChat(null);
    try {
      console.log("CurrentChat 11 | fetching", classId, chatId);
      const chatData = await firebase
        .chatMessages(classId, chatId)
        .once("value");
      if (chatData.val()) {
        console.log("CurrentChat 12 | chat data", chatData.val());
        setCurrentChat(chatData.val());
      } else {
        console.log("CurrentChat 14 | no data");
      }
    } catch (error) {
      console.log("CurrentChat 17 | error getting data", error.message);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault(); // Prevent the form from refreshing the page
    if (!currentMessage.trim()) return; // Ignore empty messages

    // Logic to send a message
    const messageObject = {
      message: currentMessage,
      sender: authUser.uid,
      date: Date.now(),
      chatId,
      read: false,
      // timestamp: firebase.database.ServerValue.TIMESTAMP, // Use server timestamp for consistency
      // Add additional fields if needed, like senderId, senderName, etc.
    };

    try {
      await firebase.chatMessages(classId, chatId).push(messageObject);
      await firebase
        .roomsInChatroom(classId, chatId)
        .set({ ...messageObject, read: false });
      setCurrentMessage(""); // Clear the input after sending
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  if (!chatId) return null;
  return (
    <div className="currentchatselected__container">
      <div className="classroomsinglechat__title">Student Name</div>
      <div className="chatroomchatmessages__container" ref={scrollViewRef}>
        {currentChat &&
          Object.values(currentChat).map((message) => {
            console.log("CurrentChat 34 | message", message.imageUrl);
            const hasImage = message.imageUrl ? true : false;
            if (message.sender === userId) {
              return (
                <div className="chatmessage__user">
                  {hasImage ? (
                    <div>
                      Image here
                      <img
                        alt="chat-img"
                        src={message.imageUrl}
                        className="classchat__image"
                        width={100}
                        height={100}
                      />
                    </div>
                  ) : (
                    message.message
                  )}
                </div>
              );
            } else {
              return (
                <div className="chatmessage__contact">
                  {hasImage ? (
                    <img
                      alt="chat bubble"
                      src={message.imageUrl}
                      className="classchat__image"
                      width={"100%"}
                      height={"auto"}
                    />
                  ) : (
                    message.message
                  )}
                </div>
              );
            }
          })}
      </div>
      <form
        className="chatmessagesinput__container chatopen__inputcontainer"
        onSubmit={sendMessage}
      >
        <input
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          placeholder="Message..."
        />
        <div onClick={sendMessage} className="chatopen__submitbutton">
          Send
        </div>
      </form>
    </div>
  );
};

export default CurrentChat;
