import React from "react";
import RemoveModal from "src/components/RemoveModal";
import Icon from "src/components/shared/Icon";

const NavSectionIconActions = ({
  toggleEdit,
  number,
  section,
  addBelow,
  uid,
  confirmDelete,
  provided,
  userMode,
}) => {
  if (userMode !== "ADMIN") return null;
  return (
    <div className="sectioniconactions__container">
      <div {...provided.dragHandleProps}>
        <Icon
          style={{ paddingLeft: 5 }}
          width={24}
          height={24}
          onClick={() => {}}
          name="menu"
        />
      </div>
      <Icon
        style={{ paddingLeft: 5 }}
        width={24}
        height={24}
        onClick={() => {
          toggleEdit(section);
        }}
        name="text-box-edit-outline"
      />
      <Icon
        style={{ paddingLeft: 5 }}
        width={24}
        height={24}
        onClick={() => {
          addBelow(number, section.uid);
        }}
        name="plus-circle-outline"
      />
      {/* <Icon
        style={{ paddingLeft: 5 }}
        width={24}
        height={24}
        onClick={() => {}}
        name="trash-can-outline"
      /> */}
      <RemoveModal uid={uid} confirmDelete={() => confirmDelete(uid)} />
    </div>
  );
};

export default NavSectionIconActions;
