import React, { useState, useEffect } from "react";
import "./index.css";
import { compose } from "recompose";
import { withFirebase } from "../../../Firebase";
import { toast } from "react-toastify";

export const UserInput = (props) => {
  const { setUserInput, quizzesDone, type, index, item } = props;
  const { prompt, placeholder, options, category } = item;
  console.log("item", item);
  const [answer, setAnswer] = useState("");


  const sendAnswer = (e) => {
    if (e.trim() !== "") {
      setUserInput({ prompt, answer: e })
      toast.success("Input received", {
        position: "top-center",
      });
    }
  }

  return (
    <div style={{ padding: 20, border: '2px solid #ff6868', alignItems: 'center', textAlign: 'center', borderRadius: 10 }}>
      <h5 className="quiz-question">{prompt}</h5>
      {/* {process.env.NODE_ENV === "development" ? answer : null} */}

      <div className="quiz-edit-question">
        <input
          className="imageinput__text"
          style={{ color: "gray" }}
          value={item.question}
          placeholder={placeholder}
          name="answer"
          onChange={(e) => setAnswer(e.target.value)}
          onBlur={(e) => sendAnswer(e.target.value)}
        />
      </div>

    </div>
  );
}

export default compose(withFirebase)(UserInput);
