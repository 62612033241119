import React from "react";
import "./index.css";
import { useDispatch } from "react-redux";
import { leaveClass } from "src/store/users/usersActions";

const AdminTools = ({ userId, isAdmin, authUser, firebase }) => {
  const dispatch = useDispatch();

  if (!userId || process.env.NODE_ENV !== "development") return null;

  const firebaseBaseUrl =
    "https://console.firebase.google.com/u/0/project/red-platform/database/red-platform/data";
  const userUrl = `${firebaseBaseUrl}/~2Fusers~2F${userId}`;
  const chatroomsUrl = `${firebaseBaseUrl}/~2Fchatrooms~2F`;
  const chatsUrl = `${firebaseBaseUrl}/~2FchatMessages~2F`;

  const updateUser = (property, value) => {
    let updateObject = { [property]: value };
    try {
      firebase.user(userId).update(updateObject);
    } catch (error) {
      console.log("AdminTools 16 | issue updating user");
    }
  };

  return (
    <>
      {(process.env.NODE_ENV === "development" || isAdmin) && (
        <div className="firebase__tool">
          <div>Admin Data Tools</div>
          <div className="admintools">
            <div>
              <div>UID: {userId}</div>
              <a target="_blank" href={userUrl}>
                Firebase Database User
              </a>
              <a target="_blank" href={userUrl}>
                Users Database Collection
              </a>
              <a target="_blank" href={chatroomsUrl}>
                Chatrooms
              </a>
              <a target="_blank" href={chatsUrl}>
                Chats
              </a>
            </div>
            <div>
              <label>Terms and Conditions Accepted</label>
              <input
                type="checkbox"
                checked={authUser.termsAndConditionsAccepted}
                onChange={() =>
                  updateUser(
                    "termsAndConditionsAccepted",
                    !authUser.termsAndConditionsAccepted
                  )
                }
              />
            </div>
            <div
              className="leaveclass__button"
              onClick={() => leaveClass(authUser, firebase)}
            >
              Leave classroom
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminTools;
