import React from "react";
import { debts } from "../../Data/DebtData";

import "./DebtSelectionModal.css";
import { AntModal } from "../AntModal/AntModal";
import Button from "antd/es/button";
import Card from "antd/es/card";

const { Meta } = Card;

class DebtSelectionModal extends React.Component {
  state = {
    debtToPass: debts,
  };

  render() {
    let debtLevel = 2;

    const debts = this.state.debtToPass.map((debt, index) => {
      if (index <= debtLevel) {
        return (
          <Card hoverable={true} style={{ width: 300, marginTop: 16 }}>
            <Meta
              //   avatar={
              //     <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
              //   }
              title={debt.title}
              //   description={`Amount: ${debt.amount} | Annual Interest ${debt.interest}`}
            />
            <div className="debt-info">
              Info
              <p>Amount: {debt.amount}</p>
              <p>
                Annual Interest <strong>{debt.interest * 100}%</strong>{" "}
              </p>
              <p>
                Monthly Interest{" "}
                <strong>{Math.floor((debt.interest * 100) / 12)}%</strong>{" "}
              </p>
            </div>
          </Card>
        );
      } else {
        return null;
      }
    });

    if (this.props.debtSelectionModalVisible) {
      console.log("DebtSelectionModal.js.js 31 | DebtSelectionModal Visible");
      return (
        <AntModal showTriggers={false}>
          <div className="debts-to-pay-container">
            <h5>You've been given the following debts to pay</h5>
            {debts}
            <div>
              <p className="warning-debt-months">
                IMPORTANT: You have 6 Months to pay off your debts
              </p>
            </div>
            <Button
              key="submit"
              type="primary"
              onClick={() => this.props._startGame(this.state.debtToPass)}
              style={{ margin: 20 }}
            >
              Start
            </Button>
          </div>
          {/* <button onClick={()=> this.props._startGame(this.state.debtToPass)}>Start</button> */}
        </AntModal>
      );
    } else {
      return null;
    }
  }
}

export default DebtSelectionModal;
