import React from "react";

const PreTestInfo = () => {
  return (
    <div>
      <div className="self-awareness-parent">
        <h6 className="interpreting-your-scores">Interpreting Your Scores:</h6>
        <p className="each-profile-in-container">
          <ul className="each-profile-in-the-graph-repr">
            <li className="each-profile-in">
              Each profile in the graph represents a different aspect of
              emotional intelligence.
            </li>
            <li className="each-profile-in">
              The profiles highlighted in RED are the main ones to focus on as
              they hold significant insights.
            </li>
            <li>
              Click on a profile to view interpretive guidelines for that
              specific aspect of EI.
            </li>
          </ul>
        </p>
      </div>
      <div className="self-awareness-parent">
        <h6 className="interpreting-your-scores">What's Next?</h6>
        <p className="understanding-your-emotional">
          Understanding your emotional intelligence is a key step towards
          personal growth and development. Use the interpretive guidelines to
          explore how you can enhance your EI in various areas of life.
        </p>
      </div>
      <div className="self-awareness-parent">
        <h6 className="interpreting-your-scores">Remember:</h6>
        <p className="understanding-your-emotional">
          The Ghyst EI Test is not designed to diagnose or treat any mental or
          physical condition. It is meant for personal development purposes
          only. If you have any questions or concerns about the results or the
          test, consider contacting Brent Darnell for access to the full
          versions of these tests. For mental or physical health issues, consult
          your mental healthcare provider or physician. In case of thoughts of
          suicide, please contact the Suicide Prevention Lifeline at
          1-800-273-8255 or 988.
        </p>
      </div>
      <button className="button8">
        <img
          className="buttonsecondaryinitial-inner"
          alt=""
          src="/rectangle-1563.svg"
        />
        <div className="button9">Return to Main Page</div>
      </button>
    </div>
  );
};

export default PreTestInfo;
