import React, { useEffect } from "react";
import "./index.css";

export const ProgressBar = ({
  progress,
  total,
  title,
  completeColor = "",
  backgroundColor = "",
  height = "20",
  showProgress = true,
}) => {
  const percent = (progress * 100) / total;

  useEffect(() => {}, [progress, total]);

  return (
    <div
      className="progress md-progress"
      style={{ height: `${height}px`, width: "100%", backgroundColor }}
    >
      <div
        className="progress-bar"
        role="progressbar"
        style={{
          width: `${progress}%`,
          height: `${height}px`,
          backgroundColor: completeColor,
          borderRadius: 10,
        }}
        aria-valuenow={title ? title : percent}
        aria-valuemin="1"
        aria-valuemax={1}
      >
        {showProgress && `${Math.floor((progress / 100) * 100)}%`}
      </div>
    </div>
  );
};

export default ProgressBar;
