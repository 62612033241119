import React from "react";
import { Link } from "react-router-dom";

const EditModuleOverview = ({ moduleId }) => {
  return (
    <Link to={`edit/${moduleId}`} className="modulestatus__buttoneditstart">
      Edit Overview
    </Link>
  );
};

export default EditModuleOverview;
