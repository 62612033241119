import React from "react";
import "./index.css";

import ModalWrapper from "../shared/ModalWrapper";
import Icon from "../shared/Icon";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "src/store/notifications/notificationsActions";
import { Link } from "react-router-dom";

const Notification = () => {
  const notification = useSelector((state) => state.notification);

  const dispatch = useDispatch();

  if (!notification.open) {
    return null;
  } else {
    return (
      <ModalWrapper>
        <div className="notification__container">
          <div
            className="closebutton__modal"
            onClick={() => dispatch(closeNotification())}
          >
            X
          </div>
          <div className="notificationcontent__container">
            <div className="modal__icon">
              <Icon
                width={45}
                height={45}
                name={notification.icon || "complete"}
              />
            </div>
            <div className="modal__message">{notification.title}</div>
            <div className="modal__text">{notification.info}</div>
            <Link
              onClick={() => {
                dispatch(closeNotification());
              }}
              to="/certificates"
              className="viewcertificates__button"
            >
              View Certificates
            </Link>
          </div>
        </div>
      </ModalWrapper>
    );
  }
};

export default Notification;
