import React, { useState } from "react";
import "./index.css";

const GEDPlatform = () => {
  const [portalOpen, setPortalOpen] = useState(false);

  if (!portalOpen) {
    return (
      <div className="gedportallink__container">
        Open GED Portal
        <a
          href="https://ged.com/RED"
          target="_blank"
          rel="noopener noreferrer"
          className="portal__button"
        >
          Click Here
        </a>
      </div>
    );
  }

  return (
    <div className="gedplatform__container">
      <iframe
        title="ged-portal"
        src={
          process.env.NODE_ENV === "development"
            ? "https://app.ged.com/portal/home"
            : "https://app.ged.com/gedworks/RED"
        }
        width={400}
        height={400}
      />
      <button onClick={() => setPortalOpen(false)}>Close</button>
      <a target="_blank" href="https://app.ged.com/gedworks/RED">
        Open in new tab
      </a>
    </div>
  );
};

export default GEDPlatform;
