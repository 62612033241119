import React from "react";
import "./EQPrompt.css";

const EQPrompt = ({
  promptQuestion,
  index,
  handleResponseChange,
  responses,
}) => {
  return (
    <div className="eqprompt">
      <div className="eqprompt-statement">{promptQuestion}</div>
      <div className="eqprompt-options-container">
        <div className="prompt-option-container">
          <input
            className="icon-left"
            type="checkbox"
            checked={responses[index]?.value === 5}
            onChange={() => handleResponseChange(index, 5)}
          />
          <div className="prompt-option">Strongly Agree (SA)</div>
        </div>
        <div className="prompt-option-container">
          <input
            className="icon-left"
            type="checkbox"
            checked={responses[index]?.value === 4}
            onChange={() => handleResponseChange(index, 4)}
          />
          <div className="prompt-option">Agree (A)</div>
        </div>
        <div className="prompt-option-container">
          <input
            className="icon-left"
            type="checkbox"
            checked={responses[index]?.value === 3}
            onChange={() => handleResponseChange(index, 3)}
          />
          <div className="prompt-option">Neutral (N)</div>
        </div>
        <div className="prompt-option-container">
          <input
            className="icon-left"
            type="checkbox"
            checked={responses[index]?.value === 2}
            onChange={() => handleResponseChange(index, 2)}
          />
          <div className="prompt-option">Disagree (D)</div>
        </div>
        <div className="prompt-option-container">
          <input
            className="icon-left"
            type="checkbox"
            checked={responses[index]?.value === 1}
            onChange={() => handleResponseChange(index, 1)}
          />
          <div className="prompt-option">Strongly Disagree (SD)</div>
        </div>
      </div>
    </div>
  );
};

export default EQPrompt;
