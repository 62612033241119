export const initialState = {
  balance: 0,
  happiness: 15,
  currentMonth: 1,
  job: { salary: 0 },
  level: 1,
  savings: 0,
  expenses: {
    housing: { name: null, cost: 0, color: "#F27117" },
    transportation: { name: null, cost: 0, color: "#2258E3" },
    insurance: { name: null, cost: 0, color: "#0DB487" },
    food: { name: null, cost: 0, color: "#FF6250" },
  },
  fixedExpenses: {
    housing: { name: null, cost: 0, color: "#F27117" },
    transportation: { name: null, cost: 0, color: "#2258E3" },
    insurance: { name: null, cost: 0, color: "#0DB487" },
    food: { name: null, cost: 0, color: "#FF6250" },
  },
  budget: {
    housing: 0,
    transportation: 0,
    insurance: 0,
    food: 0,
  },
  decisionsMade: 0,
  gameState: "jobSelection", // jobSelection expenseSelection, budgetAdjustment, monthSimulation, gameResult
  decisionsLog: [],
  emergencyCount: 0, // Added to count the number of emergencies
  emergenciesLog: [], // Store IDs of emergencies that have occurred
  emergency: null,
  insuranceType: null,
  emergencyThisMonth: false, // 1. New state property
  budgetHistory: [],
  saveMonthToCheckingOrSavings: null,
  currentDate: null,
};

export const emergencies = [
  {
    id: "brokenArm",
    happiness: -3,
    description: "You broke your arm!",
    deductibles: {
      "Cheap insurance with high premium": 500,
      "Expensive insurance with low premium": 100,
    },
  },
  {
    id: "carCrash",
    happiness: -3,
    description: "You crashed your car!",
    deductibles: {
      "Cheap insurance with high premium": 750,
      "Expensive insurance with low premium": 250,
    },
  },
  {
    id: "roofCollapse",
    happiness: -5,
    description: "Your roof collapsed!",
    deductibles: {
      "Cheap insurance with high premium": 1000,
      "Expensive insurance with low premium": 400,
    },
  },
  // ... Add more emergencies if needed
];

export const jobs = [
  {
    id: "intern",
    name: "Intern",
    salary: 1500,
    happiness: 1,
    unlocked: true,
    requiredLevel: 1,
    description:
      "As an intern, you are just starting your journey. Your salary is not high, but you are eager to learn and make your mark.",
    image: "path_to_intern_image.jpg", // Optional: If you'd like to use images for each job
  },
  {
    id: "supervisor",
    name: "Supervisor",
    salary: 2000,
    happiness: 2,
    requiredLevel: 2,
    unlocked: true,
    description:
      "As a supervisor, you manage a small team and have more responsibilities. Your hard work pays off with a higher salary.",
    image: "path_to_supervisor_image.jpg",
  },
  {
    id: "manager",
    name: "Manager",
    salary: 3000,
    requiredLevel: 3,
    happiness: 3,
    unlocked: false,
    description:
      "You have climbed the corporate ladder to become a manager. With more power comes a larger paycheck, but also greater responsibilities.",
    image: "path_to_manager_image.jpg",
  },
  {
    id: "ceo",
    name: "CEO",
    requiredLevel: 4,
    salary: 7000,
    happiness: 3,
    unlocked: false,
    description:
      "At the top of the hierarchy, as a CEO you have the highest earning potential. You oversee the entire company’s strategy and direction.",
    image: "path_to_ceo_image.jpg",
  },
];

export const fixedExpenses = {
  housing: [
    { name: "Live with parents", cost: 0, happiness: 3 },
    { name: "Live with roommates", cost: 500, happiness: 2 },
    { name: "Live on your own", cost: 1000, happiness: 1 },
  ],
  transportation: [
    { name: "Run to Work", cost: 0, happiness: 1 },
    { name: "Public Transportation", cost: 50, happiness: 2 },
    { name: "Toyota", cost: 200, happiness: 3 },
    { name: "Tesla", cost: 500, happiness: 3 },
  ],
  insurance: [
    { name: "Cheap insurance with high premium", cost: 150, happiness: 1 },
    { name: "Expensive insurance with low premium", cost: 300, happiness: 2 },
  ],
  food: [
    { name: "Home made food", cost: 200, happiness: 2 },
    { name: "Organic Food", cost: 400, happiness: 3 },
    { name: "Eat out every day", cost: 600, happiness: 1 },
  ],
};

export const dayToDayDecisions = [
  {
    category: "housing",
    text: "The heater broke in your apartment.",
    options: [
      {
        text: "Fix it immediately",
        cost: 150,
        happiness: 2,
      },
      {
        text: "Buy some blankets instead",
        cost: 50,
        happiness: 0,
      },
      {
        text: "Do nothing",
        cost: 0,
        happiness: -2,
      },
    ],
  },
  {
    category: "transportation",
    text: "Your car needs an oil change.",
    options: [
      {
        text: "Get it changed at a service station",
        cost: 40,
        happiness: 1,
      },
      {
        text: "Do it yourself",
        cost: 15,
        happiness: 0,
      },
      {
        text: "Ignore it for now",
        cost: 0,
        happiness: -1,
      },
    ],
  },
  {
    category: "insurance",
    text: "You have a headache.",
    options: [
      {
        text: "Take medicine",
        cost: 30,
        happiness: -2,
      },
      {
        text: "Suffer",
        cost: 0,
        happiness: -4,
      },
      {
        text: "Sleep it off",
        cost: 0,
        happiness: -3,
      },
    ],
  },
  {
    category: "insurance",
    text: "A health checkup is advised by a friend.",
    options: [
      {
        text: "Go for a complete checkup",
        cost: 100,
        happiness: 2,
      },
      {
        text: "Just visit a local clinic",
        cost: 30,
        happiness: 1,
      },
      {
        text: "Avoid it",
        cost: 0,
        happiness: -1,
      },
    ],
  },
  {
    category: "food",
    text: "Feeling hungry at midnight.",
    options: [
      {
        text: "Order pizza",
        cost: 20,
        happiness: 2,
      },
      {
        text: "Eat some leftovers",
        cost: 0,
        happiness: 1,
      },
      {
        text: "Drink water",
        cost: 0,
        happiness: 0,
      },
    ],
  },
  {
    category: "housing",
    text: "Your neighbor plays loud music at night.",
    options: [
      {
        text: "Talk to them politely",
        cost: 0,
        happiness: 2,
      },
      {
        text: "Buy noise-cancelling headphones",
        cost: 50,
        happiness: 1,
      },
      {
        text: "Endure it",
        cost: 0,
        happiness: -1,
      },
    ],
  },
  {
    category: "transportation",
    text: "Thinking of a weekend getaway.",
    options: [
      {
        text: "Take a flight",
        cost: 250,
        happiness: 3,
      },
      {
        text: "Drive there",
        cost: 50,
        happiness: 2,
      },
      {
        text: "Stay home",
        cost: 0,
        happiness: 0,
      },
    ],
  },
  {
    category: "insurance",
    text: "A pipe burst at home.",
    options: [
      {
        text: "Call the insurance company",
        cost: 50,
        happiness: 2,
      },
      {
        text: "Fix it yourself",
        cost: 100,
        happiness: 0,
      },
      {
        text: "Leave it be",
        cost: 0,
        happiness: -2,
      },
    ],
  },
  {
    category: "food",
    text: "Want to try a new cuisine.",
    options: [
      {
        text: "Visit a fancy restaurant",
        cost: 70,
        happiness: 3,
      },
      {
        text: "Order takeout",
        cost: 30,
        happiness: 2,
      },
      {
        text: "Cook at home",
        cost: 10,
        happiness: 1,
      },
    ],
  },
  {
    category: "housing",
    text: "Thinking of redecorating the living room.",
    options: [
      {
        text: "Hire an interior designer",
        cost: 500,
        happiness: 3,
      },
      {
        text: "DIY with some new furniture",
        cost: 200,
        happiness: 2,
      },
      {
        text: "Rearrange existing furniture",
        cost: 0,
        happiness: 1,
      },
    ],
  },
  {
    category: "transportation",
    text: "Car tire looks a bit deflated.",
    options: [
      {
        text: "Replace with a new tire",
        cost: 80,
        happiness: 2,
      },
      {
        text: "Get it patched",
        cost: 15,
        happiness: 1,
      },
      {
        text: "Ignore",
        cost: 0,
        happiness: -2,
      },
    ],
  },
  {
    category: "insurance",
    text: "You dropped your phone and the screen cracked.",
    options: [
      {
        text: "Replace with a new phone",
        cost: 700,
        happiness: 2,
      },
      {
        text: "Get it repaired",
        cost: 150,
        happiness: 1,
      },
      {
        text: "Use it as is",
        cost: 0,
        happiness: -1,
      },
    ],
  },
  {
    category: "food",
    text: "You ran out of coffee at home.",
    options: [
      {
        text: "Buy gourmet coffee beans",
        cost: 25,
        happiness: 2,
      },
      {
        text: "Get regular coffee",
        cost: 10,
        happiness: 1,
      },
      {
        text: "Switch to tea",
        cost: 5,
        happiness: 0,
      },
    ],
  },
  {
    category: "housing",
    text: "Your plants are looking a bit sad.",
    options: [
      {
        text: "Buy new plants",
        cost: 40,
        happiness: 2,
      },
      {
        text: "Try to nurse them back to health",
        cost: 0,
        happiness: 1,
      },
      {
        text: "Neglect them",
        cost: 0,
        happiness: -1,
      },
    ],
  },
  {
    category: "transportation",
    text: "Your vehicle is due for an annual service.",
    options: [
      {
        text: "Take it to the dealer",
        cost: 200,
        happiness: 2,
      },
      {
        text: "Use a local mechanic",
        cost: 100,
        happiness: 1,
      },
      {
        text: "Skip the service",
        cost: 0,
        happiness: -2,
      },
    ],
  },
  {
    category: "insurance",
    text: "Considering getting a pet.",
    options: [
      {
        text: "Adopt from a shelter",
        cost: 50,
        happiness: 3,
      },
      {
        text: "Buy from a pet store",
        cost: 300,
        happiness: 2,
      },
      {
        text: "Decide against it",
        cost: 0,
        happiness: 1,
      },
    ],
  },
  {
    category: "food",
    text: "You want some dessert after dinner.",
    options: [
      {
        text: "Buy an expensive cake",
        cost: 30,
        happiness: 3,
      },
      {
        text: "Get some ice cream",
        cost: 5,
        happiness: 2,
      },
      {
        text: "Eat fruit at home",
        cost: 0,
        happiness: 1,
      },
    ],
  },
  {
    category: "food",
    text: "You want some dessert after dinner.",
    options: [
      {
        text: "Buy an expensive cake",
        cost: 60,
        happiness: 1,
      },
      {
        text: "Get some ice cream",
        cost: 25,
        happiness: -1,
      },
      {
        text: "Skip dessert",
        cost: 0,
        happiness: -2,
      },
    ],
  },
  {
    category: "food",
    text: "You're hungry.",
    options: [
      {
        text: "Order pizza",
        cost: 20,
        happiness: -1,
      },
      {
        text: "Cook at home",
        cost: 10,
        happiness: -2,
      },
      {
        text: "Skip meal",
        cost: 0,
        happiness: -4,
      },
    ],
  },
  {
    category: "housing",
    text: "Your roof is leaking.",
    options: [
      {
        text: "Get it professionally repaired",
        cost: 300,
        happiness: 2,
      },
      {
        text: "Patch it up temporarily",
        cost: 50,
        happiness: 0,
      },
      {
        text: "Ignore the leak",
        cost: 0,
        happiness: -3,
      },
    ],
  },
  {
    category: "transportation",
    text: "Your bike needs new tires.",
    options: [
      {
        text: "Buy high-quality tires",
        cost: 80,
        happiness: 2,
      },
      {
        text: "Get budget tires",
        cost: 40,
        happiness: 1,
      },
      {
        text: "Continue with old tires",
        cost: 0,
        happiness: -1,
      },
    ],
  },
  {
    category: "insurance",
    text: "Your laptop stops working.",
    options: [
      {
        text: "Buy a new laptop",
        cost: 1000,
        happiness: 3,
      },
      {
        text: "Get it repaired",
        cost: 200,
        happiness: 1,
      },
      {
        text: "Do without it",
        cost: 0,
        happiness: -2,
      },
    ],
  },
  {
    category: "food",
    text: "You have guests coming over for dinner.",
    options: [
      {
        text: "Cook a gourmet meal",
        cost: 50,
        happiness: 3,
      },
      {
        text: "Order takeout",
        cost: 30,
        happiness: 2,
      },
      {
        text: "Serve simple snacks",
        cost: 10,
        happiness: 1,
      },
    ],
  },
  {
    category: "housing",
    text: "You find mold in your bathroom.",
    options: [
      {
        text: "Hire a professional cleaner",
        cost: 150,
        happiness: 2,
      },
      {
        text: "Clean it yourself",
        cost: 20,
        happiness: 0,
      },
      {
        text: "Ignore it",
        cost: 0,
        happiness: -3,
      },
    ],
  },
  {
    category: "transportation",
    text: "You are considering buying a new car.",
    options: [
      {
        text: "Buy a brand new car",
        cost: 20000,
        happiness: 3,
      },
      {
        text: "Buy a used car",
        cost: 10000,
        happiness: 2,
      },
      {
        text: "Keep your old car",
        cost: 0,
        happiness: 0,
      },
    ],
  },
  {
    category: "insurance",
    text: "Your pet needs a vet visit.",
    options: [
      {
        text: "Visit a high-end vet",
        cost: 150,
        happiness: 3,
      },
      {
        text: "Go to a regular vet",
        cost: 50,
        happiness: 2,
      },
      {
        text: "Skip the visit",
        cost: 0,
        happiness: -2,
      },
    ],
  },
  {
    category: "food",
    text: "You want to start eating healthier.",
    options: [
      {
        text: "Buy organic groceries",
        cost: 100,
        happiness: 3,
      },
      {
        text: "Get regular groceries",
        cost: 50,
        happiness: 2,
      },
      {
        text: "Stick with current diet",
        cost: 0,
        happiness: 0,
      },
    ],
  },
  {
    category: "housing",
    text: "You want to install a security system.",
    options: [
      {
        text: "Get a top-tier security system",
        cost: 500,
        happiness: 3,
      },
      {
        text: "Install basic security cameras",
        cost: 150,
        happiness: 2,
      },
      {
        text: "Rely on neighborhood watch",
        cost: 0,
        happiness: 0,
      },
    ],
  },
  {
    category: "transportation",
    text: "Your public transport pass has expired.",
    options: [
      {
        text: "Renew the pass",
        cost: 100,
        happiness: 2,
      },
      {
        text: "Buy single tickets as needed",
        cost: 20,
        happiness: 0,
      },
      {
        text: "Walk or bike instead",
        cost: 0,
        happiness: 1,
      },
    ],
  },
  {
    category: "food",
    text: "You're trying to cut down on sugar.",
    options: [
      {
        text: "Switch to sugar-free alternatives",
        cost: 30,
        happiness: 1,
      },
      {
        text: "Reduce sugar intake gradually",
        cost: 0,
        happiness: 1,
      },
      {
        text: "Continue with regular sugar",
        cost: 0,
        happiness: 0,
      },
    ],
  },
];
