import { ruleOf72Steps } from "./ruleOf72Data";
import { investingSteps } from "./investingData";
import { taxesSteps } from "./taxesData";
import { taxesIISteps } from "./taxesIIData";
import { budgetingSteps } from "./budgetingData";
import { creditSteps } from "./creditData";
import { interestSteps } from "./interestData";


export const newFinanceSpreadsheets = {
  'ruleOf72Steps': ruleOf72Steps,
  'investingSteps': investingSteps,
  'taxesSteps': taxesSteps,
  'taxesIISteps': taxesIISteps,
  'budgetingSteps': budgetingSteps,
  'creditSteps': creditSteps,
  'interestSteps': interestSteps,
}