import React from "react";
import "./LevelNumberTag.css";
export const LevelNumberTag = ({ level = 0 }) => {
  return (
    <div className="levelnumbertag">
      <div className="levelnumber">{`Game Level ${level}/4`}</div>
    </div>
  );
};

export default LevelNumberTag;
