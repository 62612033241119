import React, { useContext } from "react";
import { useCallback } from "react";

import { useDispatch } from "react-redux";
import { toggleSignupModal } from "../../../store/authUser/authUserActions";

import "./DiversionHeroNew.css";
import { AuthUserContext } from "src/components/Session";
import { useHistory } from "react-router-dom";

export const DiversionHeroNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const authUser = useContext(AuthUserContext);

  const onButtonContainerClick = useCallback(() => {
    console.log("authUser", authUser);
    if (authUser) {
      history.push("/home");
    } else {
      dispatch(toggleSignupModal(true));
    }
  }, []);

  const onButtonContainer1Click = useCallback(() => {
    dispatch(toggleSignupModal(true));
  }, []);

  return (
    <div className="diversionheronew">
      <div className="red-pre-trial-diversion-red-pt-parent">
        <div className="red-pre-trial-container">
          <p className="red-pre-trial">RED Pre Trial Diversion</p>
          <p className="red-ptd">RED PTD</p>
        </div>
        <div className="diversion-program-for">
          Diversion Program for First-Time Non-Violent Offenders
        </div>
        <button className="ctas">
          <div className="ctas1">
            <div className="button" onClick={onButtonContainerClick}>
              <img className="rocketlaunch-icon" alt="" src="/undefined2.png" />
              {
                <div className="label1">
                  {authUser ? "Dashboard" : "Enroll Now"}
                </div>
              }
              <img className="rocketlaunch-icon" alt="" src="/undefined3.png" />
            </div>
            <div className="button1" onClick={onButtonContainer1Click}>
              <img className="rocketlaunch-icon" alt="" src="/undefined3.png" />
              <div className="label2">How it works</div>
              <img className="rocketlaunch-icon" alt="" src="/undefined3.png" />
            </div>
          </div>
        </button>
      </div>
      <div className="image-14-parent">
        <img
          className="image-14-icon"
          alt=""
          src="/assets/images/diversion2-img.jpg"
        />
        {/* <img className="image-13-icon" alt="" src="/diversionlanding2.png" /> */}
        <img className="image-13-icon" alt="" src="/red-laptop-folder.jpg" />
        <img className="image-10-icon" alt="" src="/diversionlanding3.png" />
        {/* <img className="image-10-icon1" alt="" src="/diversionlanding4.png" /> */}
        <img
          className="image-10-icon1"
          alt=""
          src="/red-students-classroom.jpg"
        />
      </div>
    </div>
  );
};

export default DiversionHeroNew;
