import { toast } from "react-toastify";

export const openNotification = () => {
  toast.success("Monthly Debts Paid! Keep up the good work.", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const openNotEnoughPaidNotification = (debtName, minPayment) => {
  toast.error(
    `Not enough ${debtName} paid! Pay at least $ ${minPayment} on ${debtName} at the spreadsheet`,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};
