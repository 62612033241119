import React, { useContext, useEffect } from "react";
import { AuthUserContext } from "src/components/Session";
import "./index.css";
import { useSelector } from "react-redux";
import { UserChatCard } from "./UserChatCard";

const ChatsList = ({ isGroupChat = false }) => {
  const authUser = useContext(AuthUserContext);
  const {
    chatroomError,
    currentChatId,
    currentClassroomId,
    currentClassUsers,
  } = useSelector((state) => state.chats);
  const classrooms = useSelector((state) => state.chats.classrooms);

  const currentClassroom = classrooms[currentClassroomId];

  useEffect(() => {}, [currentClassroomId]);

  if (!currentClassroom) return <div>Select a classroom</div>;
  if (!currentClassUsers)
    return <NoUsers chatroomError={chatroomError} users={currentClassUsers} />;

  return (
    <div className="classroomchatscol__container">
      <ClassHeader title={currentClassroom.className} />
      <div className="classroomchat__students">
        {currentClassUsers &&
          Object.entries(currentClassUsers).map((student) => {
            const contactId = student[0];
            const chatId =
              isGroupChat === false
                ? [authUser.uid, contactId].sort().join("-")
                : authUser.classUID;

            return (
              <UserChatCard
                key={student[0]}
                student={student}
                chatId={chatId}
                isSelected={currentChatId === chatId}
                isGroupChat={isGroupChat}
                authUser={authUser}
              />
            );
          })}
      </div>
    </div>
  );
};

const ClassHeader = ({ title }) => {
  return <div className="classroomchatsselected__title">{title}</div>;
};

const NoUsers = ({ users, chatroomError }) => {
  return (
    <div className="classroomchatscol__container">
      <div className="chatloadingorerror__container">
        {chatroomError || `${!users ? "No users" : ""}`}
      </div>
    </div>
  );
};

export default ChatsList;
