import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

export const prodConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

export const devConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.analytics = firebase.analytics();
    this.auth = firebase.auth();
    this.db = firebase.database();
    this.fs = firebase.firestore();
    this.storage = firebase.storage();
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.googleProvider.addScope("https://www.googleapis.com/auth/drive.file");
    this.googleProvider.addScope("https://www.googleapis.com/auth/calendar");
    this.googleProvider.setCustomParameters({
      access_type: "offline",
      // approval_prompt: "force",
    });

    this.googleAuth = firebase.auth.GoogleAuthProvider;
  }
  // AUTH Handling
  createUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  signInWithTokenId = (token) => this.auth.signInWithCustomToken(token);

  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => {
    this.auth.signOut();
  };

  signInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  signInWithCredential = (idToken, accessToken) => {
    console.log("firebase.js 70 | signing in with credential", idToken);
    return this.auth.signInWithCredential(
      this.googleAuth.credential(idToken, accessToken)
    );
  };

  googleSignInPage = () => this.auth.signInWithRedirect(this.googleProvider);

  passwordReset = (email) => this.auth.sendPasswordResetEmail(email);

  passwordUpdate = (password) => this.auth.currentUser.updatePassword(password);

  userProgressRef = (userId, sectionId) =>
    this.fs
      .collection("userProgress")
      .doc(userId)
      .collection("sections")
      .doc(sectionId);

  // end of auth handling

  // DATABASE Functions

  assessmentQuestions = () => this.fs.collection("assessmentQuestions");
  customAssessmentQuestion = (questionId) =>
    this.fs.collection("assessmentQuestions").doc(questionId);

  classSpecificAssessmentQuestions = (classId) =>
    this.fs
      .collection("classSpecificAssessmentQuestions")
      .doc(classId)
      .collection("questions");

  getIgnoredClassSpecificAssessmentQuestions = (classId) =>
    this.fs
      .collection("classSpecificAssessmentQuestions")
      .doc(classId)
      .collection("ignored");

  ignoredClassSpecificAssessmentQuestion = (classId, questionId) =>
    this.fs
      .collection("classSpecificAssessmentQuestions")
      .doc(classId)
      .collection("ignored")
      .doc(questionId);

  classSpecificAssessmentQuestion = (classId, questionId) =>
    this.fs
      .collection("classSpecificAssessmentQuestions")
      .doc(classId)
      .collection("questions")
      .doc(questionId);

  classroomAssessmentCategories = (classId) => {
    console.log("firebase.js 90 | classId", classId);
    return this.fs.collection(`classroomAssessmentCategories`).doc(classId);
  };
  // .doc(classId)
  // .collection("questions");

  issues = (lessonId) => this.db.ref(`issues/${lessonId}`);

  announcements = (classId) => this.db.ref(`announcements/${classId}`);

  announcement = (classId, announcementId) =>
    this.db.ref(`announcements/${classId}/${announcementId}`);

  issue = (lessonId, issueId) => this.db.ref(`issues/${lessonId}/${issueId}`);

  allIssues = () => this.db.ref(`issues`);

  // USER
  user = (uid) => this.db.ref(`users/${uid}`);

  moduleReviews = () => this.db.ref("modulesFeedback");

  users = () => this.db.ref("users");

  users2 = () => this.db.ref("users2");

  getToken = () =>
    this.auth.currentUser && this.auth.currentUser.refreshToken();

  getNewToken = () => this.auth.currentUser.getIdToken();

  getPushNotificationTokens = (userId) => this.db.ref(`pushTokens/${userId}`);
  getAllPushNotificationTokens = () => this.db.ref(`pushTokens`);

  orderByChild = () =>
    this.db.ref("users").orderByChild("lessons/lessonComplete");

  // LSI Data

  lsiData = (userId) => this.db.ref(`lsiData/${userId}`);

  allLSIData = () => this.db.ref(`lsiData`);

  lsiDataByClassroom = (className) =>
    this.db.ref("lsiData").orderByChild("className").equalTo(className);

  lsiTest = () => this.db.ref(`lsiTest`);

  finalAssessmentUserData = (userId) =>
    this.db.ref(`finalAssessment/${userId}`);

  finalAssessmentQuestionsData = () =>
    this.db.ref(`finalAssessmentQuestionsData`);
  // Final Assessment Data

  allFinalAssessmentsData = () => this.db.ref(`finalAssessment`);

  userFromChatroom = (classId, userId) =>
    this.db.ref(`chatrooms/${classId}/users/${userId}`);

  roomsInChatroom = (classId, chatId) =>
    this.db.ref(`chatrooms/${classId}/rooms/${chatId}`);

  chatMessages = (classId, chatId) => {
    return this.db.ref(`chatMessages/${classId}/${chatId}`);
  };

  finalAssessmentDataByClassroom = (className) =>
    this.db.ref("finalAssessment").orderByChild("className").equalTo(className);

  // LSI ASSESSMENT (NEW)

  preLsiAssessmentResults = (userId) =>
    this.fs.collection("preLsiAssessmentResults").doc(userId);

  preLsiAssessmentResultsNew = (userId) =>
    this.fs.collection("preLsiAssessmentResultsNew").doc(userId);

  postLsiAssessmentResults = (userId) =>
    this.fs.collection("postLsiAssessmentResults").doc(userId);

  // QUEST Emotional Assessment data

  allEQData = (userId) => this.db.ref(`eqData/${userId}`);

  // -------------------------------------------- QUIZ DATA
  allQuizData = () => this.db.ref(`quizData`);

  userQuizData = (userId) => this.db.ref(`quizData/${userId}`);

  userPreModuleQuizData = (userId) =>
    this.db.ref(`preModuleQuizData/${userId}`);

  updateQuizData = (userId, lessonId, sectionId) =>
    this.db.ref(`quizData/${userId}/lessons/${lessonId}/sections/${sectionId}`);
  updateQuizDataName = (userId, lessonId) =>
    this.db.ref(`quizData/${userId}/lessons/${lessonId}`);

  quizAnalytics = () => this.fs.collection("quizAnalytics");

  firestoreRef = () => this.db();

  //Modules

  modules = () => this.db.ref(`modules2`);

  modulesFeedback = (moduleId) => this.db.ref(`modulesFeedback/${moduleId}`);
  getModulesFeedback = () => this.db.ref("modulesFeedback");

  getLesson = (lessonId) => this.db.ref(`modules2/${lessonId}`);
  updateLessonById = (lessonId) => this.db.ref(`modules2/${lessonId}`);

  removeModule = (uid) => this.db.ref(`modules2/${uid}`);
  removeModuleOverView = (uid) => this.db.ref(`modulesOverview/${uid}`);

  //New Modules
  getModulesOverview = () => this.db.ref(`modulesOverview`);

  getLessonOverview = (lessonId) => this.db.ref(`modulesOverview/${lessonId}`);

  updateModuleOverview = (lessonId) =>
    this.db.ref(`modulesOverview/${lessonId}`);

  removeLessonOverview = (uid) => this.db.ref(`modulesOverview/${uid}`);

  // sections = moduleId => this.db.ref(`modules2/${moduleId}/sections`);
  sections = (moduleId) => this.db.ref(`modules2/${moduleId}`);

  updateOrder = (moduleId) => this.db.ref(`modules2/${moduleId}`);

  updateModulesOrder = () => this.db.ref("modules2");

  updateModulesOrderWithKeys = () => this.db.ref("modules2");

  generalRef = () => this.db.ref();

  addSection = (name, sectionId) =>
    this.db.ref(`modules2/${name}/sections/${sectionId}`);

  pushSection = (name) => this.db.ref(`modules2/${name}/sections`);

  updateSection = (name, uid) =>
    this.db.ref(`modules2/${name}/sections/${uid}`);

  updateSectionContent = (name, uid) =>
    this.db.ref(`modules2/${name}/sections/${uid}`);

  removeSection = (lessonId, sectionId) =>
    this.db.ref(`modules2/${lessonId}/sections/${sectionId}`);

  classrooms = () => this.db.ref(`classrooms`);

  classroomTeachers = (classroomId) =>
    this.db.ref(`classrooms/${classroomId}/teachers`);

  classroomTeacher = (classroomId, teacherId) => {
    return this.db.ref(`classrooms/${classroomId}/teachers/${teacherId}`);
  };

  classroom = (classroomId) => this.db.ref(`classrooms/${classroomId}`);

  chatroomUser = (classroomId, userId) =>
    this.db.ref(`classrooms/${classroomId}/${userId}`);

  chatroom = (classroomId) => this.db.ref(`chatrooms/${classroomId}`);

  chatroomUsers = (classroomId) =>
    this.db.ref(`chatrooms/${classroomId}/users`);

  mentorClassroom = (classId) => this.db.ref(`classrooms/${classId}`);

  getModules = () => {
    return this.db.ref(`modules2`);
  };

  overviewModulesRef = () => this.db.ref(`modulesOverview`);

  getToken = () => this.auth.currentUser.getIdToken();

  setPrePostTestData = () => this.db.ref(`prePostTestData`);

  getLikertData = () => this.db.ref(`likertData`);

  getUserLikertData = (id) => this.db.ref(`likertData/${id}`);

  setLikertData = () => this.db.ref(`likertData`);

  updateLikertData = () => this.db.ref(`likertData`);

  userLessonInputs = (uid, lesson, camelInput) =>
    this.db.ref(`userLessonInputs/${uid}/${lesson}/${camelInput}`);

  updatePrePostTestData = (uid) => this.db.ref(`prePostTestData/${uid}`);

  queryData = (reference, field, query) => {
    return this.db.ref(reference).orderByChild(field).equalTo(query);
  };

  uploadFile = (uri) => this.storage.ref(`files/${uri}`);

  uploadId = (uid) => this.storage.ref(`stateIssuedIds/${uid}`);

  signatures = () => this.storage.ref(`signatures`);
  userSignature = (uid) => this.storage.ref(`signatures/${uid}`);

  userLSITermsAndConditions = (uid) =>
    this.storage.ref(`lsiTermsAndConditions/${uid}`);

  eiGhyst = () => this.db.ref("eiGhyst");
  eiGhystUserDataPreTest = (userId) =>
    this.db.ref(`eiGhystUserDataPreTest/${userId}`);
  eiGhystUserDataPostTest = (userId) =>
    this.db.ref(`eiGhystUserDataPostTest/${userId}`);

  mentorLSITermsAndConditions = (uid) =>
    this.storage.ref(`mentorLsiTermsAndConditions/${uid}`);

  diversionAidRequestUpload = (uid) =>
    this.storage.ref(`diversionAidRequest/${uid}`);

  updateUserLessonProgress = (userId, lessonId) =>
    this.db.ref(`users/${userId}/lessons/${lessonId}`);

  updateUserSectionProgress = (userId, lessonId, sectionId) =>
    this.db.ref(`users/${userId}/lessons/${lessonId}/sections/${sectionId}`);

  message = (messagesId) => this.db.ref(`messages/${messagesId}`);

  messages = () => this.db.ref("messages");

  storageRef = () => this.storage.ref("images");

  deleteClassroom = (classId) => this.db.ref(`/classrooms/${classId}`);
  updateClassroom = (classId) => this.db.ref(`/classrooms/${classId}`);

  currentUserId = () => this.auth.currentUser.uid;

  uploadInfo = (fileName) => this.storage.ref(`files/${fileName}`);

  platformEdits = () => this.db.ref(`platformEdits`);

  // events manager

  events = () => this.db.ref(`events`);
  getEventData = (eventId) => this.db.ref(`events/${eventId}`);

  tokens = () => this.db.ref(`tokens`);
  getTokenData = (tokenId) => this.db.ref(`tokens/${tokenId}`);

  addPreFilledRecordToDB = async (record) => {
    try {
      console.log("trying  addPreFilledRecordToDB", record);
      const ref = this.db.ref("preFillRecords");
      const newRecordRef = ref.push();
      await newRecordRef.set(record);
    } catch (error) {
      console.error("Error adding record: ", error);
    }
  };

  updatePreFilledRecordInDB = async (id, updatedRecord) => {
    try {
      const ref = this.db.ref("preFillRecords");
      const snapshot = await ref.orderByChild("id").equalTo(id).once("value");

      if (snapshot.exists()) {
        let updated = false;

        snapshot.forEach((childSnapshot) => {
          if (!updated) {
            childSnapshot.ref.update(updatedRecord); // Update the first matched record
            console.log(`Record with name ${id} updated.`);
            updated = true;
          }
        });

        if (!updated) {
          console.log(`No record found with name ${id}.`);
        }
      } else {
        console.log(`No record found with name ${id}.`);
      }
    } catch (error) {
      console.error("Error updating record: ", error);
    }
  };

  deletePreFilledRecordFromDB = async (id) => {
    try {
      const ref = this.db.ref("preFillRecords");
      const snapshot = await ref.orderByChild("id").equalTo(id).once("value");

      if (snapshot.exists()) {
        let deleted = false;

        snapshot.forEach((childSnapshot) => {
          if (!deleted) {
            childSnapshot.ref.remove(); // Remove the first matched record
            console.log(`Record with id ${id} deleted.`);
            deleted = true;
          }
        });

        if (!deleted) {
          console.log(`No record found with id ${id}.`);
        }
      } else {
        console.log(`No record found with id ${id}.`);
      }
    } catch (error) {
      console.error("Error deleting record: ", error);
    }
  };

  fetchPreFilledRecordsFromDB = async () => {
    try {
      const ref = this.db.ref("preFillRecords");
      const snapshot = await ref.once("value");
      const records = [];
      snapshot.forEach((childSnapshot) => {
        records.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      return records;
    } catch (error) {
      console.error("Error fetching records: ", error);
    }
  };

  // Merge Auth and DB User API
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid).on("value", (snapshot) => {
          if (snapshot.val()) {
            const dbUser = snapshot.val();
            // add refresh token to session storage

            localStorage.setItem("refreshToken", dbUser.refresh_token);

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and dbUser
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };

            next(authUser);
          }
        });
      } else {
        fallback();
      }
    });
}

export default Firebase;
