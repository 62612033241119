import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { compose } from "recompose";
import { insertExistingCalendarIntoUserCalendars } from "src/api/googleCalendar";
import { withFirebase } from "src/components/Firebase";
import Icon from "src/components/shared/Icon";
import {
  handleDbCalendarEvents,
  handleGoogleCalendarEvents,
} from "src/store/calendarEvents/calendarEventsActions";
import { getAllModules } from "src/store/modules/modulesActions";
import { sendOneEmail } from "src/api/emails";
import { toast } from "react-toastify";

import "./index.css";

const JoinClassCard = ({
  enabled = false,
  done = false,
  firebase,
  userId,
  isMentor,
  email,
  authUser,
}) => {
  const [loading, setLoading] = useState(false);
  const [className, setClassName] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [tooltip, setActiveTooltip] = useState(false);

  const splittedUrl = window.location.href.split("?");
  let params = splittedUrl[1];
  if (params && params.includes("%20")) {
    params = params.replace(/%20/g, " ");
  }

  const insertUserToExistingEventCollections = async (className, userId) => {
    firebase
      .events()
      .orderByChild("classId")
      .equalTo(className)
      .once("value")
      .then(async (eventObject) => {
        if (eventObject.val()) {
          let events = Object.entries(eventObject.val());

          let eventsWithoutStudent = events.filter((e) => {
            let isNotInEvent = true;
            const hasAttendees = e[1].attendees;
            if (!hasAttendees) return true;
            Object.values(hasAttendees).map((attendee) => {
              if (attendee === userId) {
                isNotInEvent = false;
              }
            });
            return isNotInEvent;
          });

          eventsWithoutStudent &&
            (await Promise.all(
              eventsWithoutStudent.map(async (e) => {
                let path = `/attendees/${userId}`;
                await firebase
                  .getEventData(e[0])
                  .update({ [path]: { attendance: false } });
              })
            ));
        }
      });
  };

  useEffect(() => {
    if (!done && params) {
      setClassName(params);
      setActiveTooltip(true);
    }
  }, []);

  const dispatch = useDispatch();

  const onSubmitJoinClassroom = async () => {
    setLoading(true);
    console.log("JoinClassCard.js 78 | joining classroom...", className);
    // change this to a firebase database query
    firebase
      .classrooms()
      .orderByChild("className")
      .equalTo(className)
      .once("value")
      .then(async (classrooms) => {
        if (classrooms.val()) {
          let classroomToJoin = Object.entries(classrooms.val())[0];
          if (classroomToJoin) {
            const { questId, calendarId, settings } = classroomToJoin[1];
            console.log(
              "JoinClassCard.js 90 | found class to join",
              classroomToJoin,
              "userId",
              userId
            );

            try {
              console.log("JoinClassCard.js 98 | joining...");
              await firebase.user(userId).update({
                classId: className,
                classUID: classroomToJoin[0],
                questClassId: questId ? questId : null,
                calendarId: calendarId || "",
                introVideoDone: settings.introVideoAvailable ? false : true,
              });

              await firebase.chatroomUsers(classroomToJoin[0]).update({
                [userId]:
                  authUser.username || authUser.displayName || "Red user",
              });

              // await insertExistingCalendarIntoUserCalendars(calendarId);

              await insertUserToExistingEventCollections(className, userId);

              if (!isMentor) {
                // sendWelcomeEmail();
                // sendTemplateWelcomeEmail();
              }
              setLoading(false);
              setError(null);

              if (settings.welcomeMail?.activated) {
                sendOneEmail(
                  authUser.email,
                  settings.welcomeMail.mailSubject,
                  settings.welcomeMail.mailBody,
                  authUser.displayName || authUser.username || "Red"
                )
                  .then((res) => {})
                  .catch((err) => {
                    toast.error(
                      `Issue sending email to ${authUser.displayName}`,
                      {
                        position: "top-center",
                      }
                    );
                  });
              }

              dispatch(handleDbCalendarEvents(className, firebase));
              dispatch(getAllModules(authUser, classroomToJoin[1]));
              // get classroom data
            } catch (error) {
              setLoading(false);
              console.log("JoinClassCard.js 148 | error joining class", error);
              setMessage("No classroom found this one", error.message);
            }
          }
        } else {
          setLoading(false);
          setMessage("No classroom found here");
        }
      });
  };

  if (done) {
    return null;
  }

  return (
    <div className="initialassessments__container">
      <div className="initialassessment__cardtop">
        <div className="initialassessment__topleftcontainer">
          <Icon name="time" width={15} height={15} />
          {/* <p className="topleftcontainer__time">2 min</p> */}
        </div>
        <div className="initialassessment__toprightcontainer">
          <Icon name="assessment-number" width={15} height={15} />
          {/* <p className="toprightcontainer__number">1/3</p> */}
        </div>
      </div>
      <div className="initialassessment__cardmid">
        <div className="initialassessment__midtitle">Join Class</div>
        <div className="initialassessment__midsubtitle">
          Ask for the class code to access the modules.
        </div>
      </div>
      <div className="initialassessment__cardbottom">
        {!done ? (
          <div style={{ position: "relative" }}>
            <input
              value={params ? className : undefined}
              onChange={(e) => setClassName(e.target.value)}
              className="initialassessments__joinclassinput"
              placeholder="Enter Class ID"
            />
            <button
              onClick={() => onSubmitJoinClassroom()}
              className="initialassessment__button"
              disabled={!enabled || loading}
            >
              Join Class
            </button>
            {message && <span>{message}</span>}
            {error && <p>{error.message}</p>}
            {tooltip && (
              <div className="initialassessment__tooltip">
                Click the "Join Class" button to join your new class!
              </div>
            )}
          </div>
        ) : (
          <div className="initialassessment__complete">
            <Icon name="complete" /> <p>Done!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default compose(withFirebase)(JoinClassCard);
