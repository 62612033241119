import React from "react";
import Button from "src/components/shared/Button";
import "./index.css";

export const ActionButton = ({
  chosenIndex,
  increaseSituationIndex,
  action,
  danger,
  dangerLevel,
  setDangerLevel,
}) => {
  return (
    <Button
      title={action}
      type="outline"
      key={chosenIndex}
      style={{
        fontSize: 16,
        fontWeight: "600",
        marginBottom: 10,
        marginLeft: 0,
      }}
      className="action-button"
      onClick={() => {
        increaseSituationIndex(chosenIndex, dangerLevel, danger);
        setDangerLevel(+dangerLevel + +danger);
      }}
    />
  );
};
