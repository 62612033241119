import React from "react";
import "./BudgetTips.css";
import budgetingGif from "./budgeting.gif";

class BudgetTips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
  }

  componentDidMount() {
    console.log(
      "BudgetTips.js 12 | selectedOptions",
      this.props.selectedOptions
    );
    console.log("BudgetTips.js 16 | month num", this.props.monthNum);
    let message =
      this.props.monthNum === 0 ? (
        <BudgetSetupMessage selectedOptions={this.props.selectedOptions} />
      ) : (
        <BudgetingAdjustMessage />
      );
    this.setState({
      message,
    });
  }

  render() {
    return (
      <div className="budget-box">
        <div className="budget-tips">
          <h3>Time to Budget!</h3>
          <h5>Month {this.props.monthNum + 1} of 3</h5>
          {this.props.monthNum === 0 ? (
            <BudgetSetupMessage selectedOptions={this.props.selectedOptions} />
          ) : (
            <BudgetingAdjustMessage />
          )}
          <button
            onClick={this.props._getSheetBudget}
            type="button"
            className="btn btn-primary btn-lg button-start-absolute"
          >
            START
          </button>
        </div>
        <img height="70%" src={budgetingGif} alt="budgeting gif" />
      </div>
    );
  }
}

const BudgetSetupMessage = ({ selectedOptions }) => {
  return (
    <div className="budgeting-instructions">
      <h5 className="instructions__title">Instructions</h5>
      <p>
        On cells <strong id="white-space"> C4:C8</strong> , the white spaces in
        the spreadsheet , make your budget with amounts higher than 0. If
        confused, look at the video on the right.
      </p>
      <p>
        Below are the amounts you chose previously. Use them to create a budget!
        You haven't paid your chosen expenses yet, but you're about to when the
        simulation starts.
      </p>
      <div className="fixed-expenses-selected">
        {Object.entries(selectedOptions).map((expense, index) => {
          return (
            <span key={index} className="budget-tips-fixed-amount">
              {expense[0] === "jobs"
                ? `Income  ${expense[1].amount}`
                : `${expense[0].toUpperCase()}  ${expense[1].amount}`}
            </span>
          );
        })}
      </div>
      <p>
        You'll make VARIABLE expenses decisions after this. Budget your fixed
        expenses PLUS your variable expenses.
      </p>
      <p>
        For example: If you're paying $500 for your house, Budget $600 to make
        sure you're not over budget!
      </p>
      <p>
        Press <span className="start-instructions-span">START</span> when ready
      </p>
    </div>
  );
};

const BudgetingAdjustMessage = () => {
  return (
    <div className="budgeting-instructions">
      <p>
        Adjust your <strong>Budget</strong>! If you spent more than you budgeted
        for, be sure to adjust it.
      </p>
      <p>
        For example: If you budgeted $100 for food, and you spent $200, adjust
        your budget to $200
      </p>
    </div>
  );
};

export default BudgetTips;
