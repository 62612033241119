export const assessmentCategoryData = {
  "Self-Awareness": {
    intro: `Gotta Be True focuses on two things:  YOU being true to yourself by knowing yourself and being aware of what is going on with you and your emotions at any given time in any situation.  This is the foundation of Emotional Intelligence.  You get this one down, the rest is easy!`,
    positive: `The key to any success in any area of life is to be aware and mature in these five (5) competencies or abilities.  These help you understand who you are and essentially how you "show up" to other people.  These abilities are like an anchor to steady you and keep you standing.  The 5 abilities in this category help us communicate what we feel; help us identify influences in our lives that move us to behave a specific way; aid us in stepping forward, standing still, or moving back depending on the situation;  Allow us to see yourself the way other people see you in a true light and care for yourself in the way that shows that you respect and love yourself for who you are as a person.`,
    negative: `The lack of immaturity in these areas shows up in our behavior and communication.  You can't figure out what you are feeling or why.  You can't see what is pushing you around to behave a certain way; We then react to situations instead of responding thoughtfully;  We lie to ourselves that we are one way when everyone else sees us in the way that we really show up and lastly we constantly disrespect ourselves by not taking care of our bodies, minds and spirits…and that reflects not only on you, but also, on the way we treat others.`,
  },
  "Emotional Management": {
    intro: `Be on Point means to watch what is going on with you and others.  To manage the way you feel and make sure that you feel powerful enough to change what needs to change.`,
    positive: `When we are mature in the area we are thinking clearly about:  If we do this, then that is going to happen; We can "use" emotions rather than just "have" them.  I can motivate myself when there is no one pushing me and I feel confident that I can do anything I put my mind and hand to;  You begin to control how you respond to specific situations and people, like you have not before because you can flex and adapt to situations that don't go the way you think they should.  Essentially you show up as your real self with no mask, no need to hide or pretend who you are (without shame), and you are driven to change the things that you need and want to change.  This is powerful!`,
    negative: `If this area is a struggle for you, don’t worry.  Everything changes when we put in a little effort.  How do you eat an elephant?  One bite at a time! Consider the fact that if this area is weaker than others you see it in your own thinking patterns;  You see it by putting on a mask so others can’t really get to know who you are; You remain inflexible and get mad when things don’t go the way you want them to go and they will see how displeased you are with that.  Not only should we be managing our emotions, but also we want to familiarize ourselves with this so that we can see it in others who might need a hand or help.`,
  },
  "Emotional Connection": {
    intro: `I Feel Ya: Emotional Connection, is the section expressing how we connect with one another to create lasting and important friendships and connections.`,
    positive: `If we are mature in this area we focus on other people and how they are, not what we are doing and what we have to say.  When you connect with someone around an experience that you have had in common it creates a bond that is difficult to separate;  When you talk to people you are thinking about what you want to leave behind with them or how you want to leave them feeling before you communicate;  And we realize that communication is not about us talking about what we did or who we are.  It is about listening and understanding what the other person has to say first before we give our opinion or fact.`,
    negative: `If we are not mature in this area, we will find ourselves alone a lot.  People mostly will not want to hang out with out cause of the way we make them feel and or we don’t care how they are when we stop the conversation.  If communication is just about you talking about you or another person?  That is going to likely leave you spending a lot of time alone and that is not the “healthy” alone time we are talking about.  This is a lack of relationship alone and despite what we say when we are hurt by others, we still need people on this planet.  So let’s make the most of our abilities to connect, learn, and grow ourselves and others.`,
  },
};

export const assessmentCompetencies = {
  "Emotional Literacy":
    "Accurately identifying and interpreting both simple and compound feelings.",
  "Identify Patterns":
    "Acknowledging frequently recurring reactions and behaviors. Like how you react when someone disrespects you. You know it is coming!",
  "Emotional Self awareness":
    "Be aware of your thoughts and feelings and know why you are experiencing them. To know how your feelings and thoughts are impacting your behavior. The awareness of the impact of your behavior on others. The ability to use the knowledge of thoughts, feelings and body sensations in making a decision and act in the best interest of a situation.",
  "Self-Assessment":
    "Your ability to assess your strengths and weaknesses. To objectively see how you show up rather than how you think you show up",
  "Self-Regard":
    "The ability to act in a decisive yet humble way. The ability to feel good about ourselves in order to be in relationship with others. Regard myself highly enough to take care of myself: Diet, exercise, good relationships, spirituality, kindness towards one’s self",
  "Consequential Thinking":
    "Evaluating the costs and benefits of your choices. E.G.  Like think about this:  If I do this, then that is going to happen",
  "Navigate Emotions":
    "Assessing, harnessing, and transforming emotions as a strategic resource. E.G. How can I use my emotions in this case to change the outcome of the situations",
  "Engage Intrinsic Motivation":
    "Gaining energy from personal values & commitments vs. being driven by external forces. The power and motivation to do things in this world come from inside of me, not from what someone says to me or if they appreciate me.",
  "Exercise Optimism":
    "Your ability to persist in the face of adversity. Your ability to maintain a positive attitude and outlook. Your ability to have a measure of hope in your approach to life",
  "Impulse Control":
    "Your ability to resist or delay an impulse. The ability to withstand adverse events and stressful situations. The ability to suppress our urge to act and react when angered. E.G. to keep your cool, be aware of and respond to stress appropriately.",
  "Flexibility and Adaptability":
    "Your ability to alter your feelings, thoughts, and actions under ambiguous or changing situations. The ability to remain flexible enough to see the “other” possibilities for solving problems.",
  Authenticity:
    "Your ability to show yourself as you truly are.  E.G. Be your real self without hiding or shame. The ability to act and express thoughts, feelings, and beliefs openly – even if it is emotionally difficult to do. Your ability to build trust based on your actions.",
  "Personal Drive":
    "The ability to have plentiful energy and be self-reliant to plan, act, and function independently. The ability to work powerfully toward a stated goal. Your ability to use your emotions to propel you toward your goals.",
  Empathy:
    "Recognizing and appropriately responding to others’ emotions. The ability to see and appreciate from their frame of reference. E.G. Walk a mile in someone else’s shoes.",
  "Leave a Legacy":
    "Connecting your daily choices with your overarching sense of purpose. E.G. Think about what you want to leave behind wherever you go and in whatever decision you make consider that.",
  Communication:
    "The ability to clearly express your thoughts and feelings. The ability to stand our ground and defend a position without intentionally hurting someone else. The capacity for non-­‐defensive listening. And to understand what type of listening to use for different situations. E.G. Listen to what others have to say before you share your own point.",
};
