import React from "react";
import "./index.css";

export const WidthWarning = () => {
  return (
    <div className="width-warning-container">
      <h2 className="width-warning-title">
        ¡Hello! This section uses Spreadsheets to teach Finance
      </h2>
      <h3 style={{fontWeight:'bold'}}>You need a bigger screen to be able to use this. </h3>
      <img alt={"sheets"} style={{height:70,width:50,marginBottom:20}} src={"/assets/images/sheets.png"}/>
      <div>
        On these lessons we teach you how to use spreadsheets, which is a required tool for getting jobs in multiple fields. 
        You are welcome to use any computer. If you do not have immediate access to a computer and cannot borrow one, 
        please reach out to your designated program advisor to get access to one, as this module cannot be completed without one.
      </div>
    </div>
  );
};
