import React from "react";
import RemoveModal from "../../../RemoveModal";

export const NavSectionAdminOptions = ({
  isAdmin,
  number,
  section,
  toggleEdit,
  remove,
  switchUp,
  switchDown,
  uid,
  addBelow,
}) => {
  if (isAdmin) {
    return (
      <span className="edit-options">
        <span>
          {/* <span
            className="btn btn-outline-primary btn-block"
            onClick={() => toggleEdit(section)}
          >
            Edit
          </span> */}
          {/* <RemoveModal uid={uid} confirmDelete={() => remove(uid)} /> */}
          {/* <DeleteModal confirmDelete={() => remove(uid)} /> */}
        </span>
        <div>
          <div>
            <button
              onClick={() => switchUp(number, section.uid)}
              className="btn btn-outline-info"
            >
              <i className="fas fa-arrow-up"></i>
            </button>
            <button
              onClick={() => switchDown(number, section.uid)}
              className="btn btn-outline-info"
            >
              <i className="fas fa-arrow-down"></i>
            </button>
          </div>
          <button
            onClick={() => {
              console.log("SectionsNavBar 168 | adding below");
              addBelow(number, section.uid);
            }}
            className="btn btn-sm btn-outline-info btn-block add-section-button"
          >
            add +
          </button>
        </div>
      </span>
    );
  }
  return null;
};
