/* eslint-disable */
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";
import { generateUniqueNumber } from "src/components/Settings";
import { transcriptData, quarters } from "./transcriptData";

// This component generates a sleek transcript for a user in a classroom based on the user's data
// Information on the transcript includes:
// 1. User's name
// 2. User's email
// 3. User's date of birth
// 4. User ID
// 5. Lesson modules
// 6. Quiz scores
// 7. Issue date
// 8. Signature of the program coordinator
// 9. Name of organization at the top of the page (e.g. "RED Ed.")

const gradeBelongsToScore = (score) => {
  if (score >= 90) return "A";
  if (score >= 80) return "B";
  if (score >= 70) return "C";
  if (score >= 66) return "D";
  return "F";
};

function UserTranscriptReport({ userData }) {


  const modulesGrades = {}

  Object.entries(userData.lessons).forEach(([moduleId, module]) => {
    let scores = [];
    let averageScore = 0;

    Object.values(module.sections).forEach((section) => {
      let score = section?.quizPerformance; // This is a percentage string (e.g., "0.90")
      if (!score) return;
      scores.push(parseFloat(score) * 100);
    });

    averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
    modulesGrades[moduleId] = gradeBelongsToScore(averageScore); // Use moduleId instead of module.name
  });


  const transcript = transcriptData();
  return (
    <Document title={"User Transcript"} style={styles.documentStyle}>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <Text style={styles.transcriptTitle}>Official Transcript</Text>
        <View style={{ borderColor: 'black', borderWidth: 2 }}>

          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderColor: 'black', borderBottomWidth: 2 }}>
            <View style={{ width: '50%', justifyContent: 'center', marginLeft: 5 }}>
              <Text style={{ fontSize: 8 }}>Prepared for: Charles Beard</Text>
              <Text style={{ fontSize: 8 }}>User ID: {generateUniqueNumber(userData?.uid)}</Text>
              <Text style={{ fontSize: 8 }}>Page 1 of 2</Text>
            </View>
            <View style={{ width: '50%', flexDirection: "row", justifyContent: 'flex-end' }}>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-end', marginLeft: 5, alignItems: 'flex-end' }}>
                <Image
                  src="https://firebasestorage.googleapis.com/v0/b/red-platform.appspot.com/o/images%2Fregistrar-seal.png?alt=media&token=0027cbf0-d712-4492-b19e-e33d18806fb7"
                  style={styles.seal}
                />
              </View>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderColor: 'black', borderBottomWidth: 2, padding: 10, marginBottom: 10 }}>

            <View style={{ width: '40%', borderColor: 'black', borderWidth: 1, marginLeft: 5 }}>
              <Text style={{ fontSize: 8, borderBottomWidth: 1, borderBottomColor: 'black' }}>Student name: {userData?.displayName} </Text>
              <Text style={{ fontSize: 8, borderBottomWidth: 1, borderBottomColor: 'black' }}>Student ID: {userData?.uid}</Text>
              <Text style={{ fontSize: 8, borderBottomWidth: 1, borderBottomColor: 'black' }}>Student email: {userData?.email} </Text>
              <Text style={{ fontSize: 8, borderBottomWidth: 1, borderBottomColor: 'black' }}>Date of birth: {userData?.dob}</Text>
              <Text style={{ fontSize: 8 }}>Student Address: {userData?.address}</Text>
            </View>
            <View style={{ width: '40%', borderColor: 'black', borderWidth: 1 }}>
              <Text style={{ fontSize: 8, borderBottomWidth: 1, borderBottomColor: 'black' }}>Rehabilitation Enables Dreams</Text>
              <Text style={{ fontSize: 8, borderBottomWidth: 1, borderBottomColor: 'black' }}>{transcriptData().red_address} </Text>
              <Text style={{ fontSize: 8 }}>{transcriptData().red_phone}</Text>
            </View>
          </View>
          <Text style={styles.transcriptTitle}>Student Record</Text>
          <View style={{ marginLeft: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, borderColor: 'black', borderTopWidth: 1, borderBottomWidth: 1 }}>
            <Text style={{ width: '15%', fontSize: 6 }}>Course Code</Text>
            <Text style={{ width: '40%', fontSize: 6 }}>Course Name</Text>
            <Text style={{ width: '10%', fontSize: 6 }}>Earned Credits</Text>
            <Text style={{ width: '10%', fontSize: 6 }}>Score</Text>
          </View>
          <View>
            {quarters.map((quarter, index) => (
              <View key={index} style={{ marginBottom: 15 }}>
                <Text style={styles.quarterTitle}>{quarter.title}</Text>
                <View style={{ marginLeft: 40, marginTop: 5, marginBottom: 5 }}>
                  <Text style={{ fontSize: 6 }}>Program: Rehabilitation Enables Dreams</Text>
                  <Text style={{ fontSize: 6 }}>Plan: Rehabilitation Program</Text>
                </View>
                {quarter.courses.map((course, index) => (
                  <View key={index} style={{ marginLeft: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ width: '15%', fontSize: 6 }}>EDD: {course.edd}</Text>
                    <Text style={{ width: '40%', fontSize: 6 }}>{course.name}</Text>
                    <Text style={{ width: '10%', fontSize: 6 }}>4.0</Text>
                    {/* <Text style={{ width: '10%', fontSize: 6 }}>{Math.random() < 0.5 ? "A" : "B"}</Text> */}
                    <Text style={{ width: '10%', fontSize: 6 }}>{modulesGrades[course.id] || '-'}</Text>
                  </View>
                ))}

                <View style={{ marginLeft: 40, display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                  <Text style={{ width: '10%', fontSize: 6 }}>Term Totals</Text>
                  <Text style={{ width: '10%', fontSize: 6 }}>{quarter.termTotal}</Text>
                </View>
                <View style={{ marginLeft: 40, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={{ width: '10%', fontSize: 6 }}>Cum Totals</Text>
                  <Text style={{ width: '10%', fontSize: 6 }}>{quarter.total}</Text>
                </View>

              </View>
            ))}
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 20, }}>
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Image src="https://firebasestorage.googleapis.com/v0/b/red-platform.appspot.com/o/images%2Fzeus_onlinesig.png?alt=media&token=54b4ea41-5215-41e6-80a4-e4ac9c3bf1fa" style={{ width: 80, height: 30, backgroundColor: 'white' }} />
                <View style={{ borderTopWidth: 1, borderColor: 'black', justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={{ fontSize: 6 }}>Zeus Luby</Text>
                  <Text style={{ fontSize: 6 }}>Program Coordinator</Text>
                </View>
              </View>
              <View style={{ justifyContent: 'center', alignItems: 'center', marginLeft: 20, }}>
                <Image src="https://firebasestorage.googleapis.com/v0/b/red-platform.appspot.com/o/images%2Fjessica_onlinesig.png?alt=media&token=e8ae4c22-b44a-4c5f-9c1d-7ae8eaa0aa10" style={{ width: 80, height: 30 }} />
                <View style={{ borderTopWidth: 1, borderColor: 'black', justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={{ fontSize: 6 }}>Jessica Manning</Text>
                  <Text style={{ fontSize: 6 }}>Registrar</Text>
                </View>
              </View>
            </View>
            <View style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <Text style={{ fontSize: 8, marginRight: 10 }}>
                Issued on: {new Date().toLocaleDateString()}
              </Text>
            </View>
          </View>
        </View>
        <View style={{ borderColor: 'black', borderWidth: 2, borderTopWidth: 0, fontSize: 8 }} >
          <Text>This transcript is official when downloaded directly from the Stop Recidivism website. To verify the validity of the transcript, go to https://myreded.com/validate</Text>
        </View>
      </Page>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <Text style={{ fontSize: 8 }}>Page 2 of 2</Text>
        <View style={{ padding: 10 }}>
          <View style={{ marginBottom: 20, alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ fontSize: 10 }}>RED Ed.</Text>
            <Text style={{ fontSize: 10, marginBottom: 2 }}>{transcript.red_address}</Text>
            <Text style={{ fontSize: 10, marginBottom: 2 }}>{transcript.red_phone}</Text>
            <Text style={{ fontSize: 10, marginBottom: 2 }}>{transcript.red_website}</Text>
          </View>

          <View style={{ marginBottom: 20 }}>
            <Text style={{ fontSize: 10, marginBottom: 5 }}>Accreditation</Text>
            <Text style={{ fontSize: 8, marginBottom: 2 }}>{transcript.program_accreditation}</Text>
          </View>

          <View style={{ marginBottom: 20 }}>
            <Text style={{ fontSize: 10, marginBottom: 5 }}>Academic Calendar and Credits</Text>
            <Text style={{ fontSize: 8, marginBottom: 2 }}>{transcript.program_academic_calendar_and_credits}</Text>
          </View>

          <View style={{ marginBottom: 20 }}>
            <Text style={{ fontSize: 10, marginBottom: 5 }}>Graduation Requirements</Text>
            <Text style={{ fontSize: 8, marginBottom: 2 }}>{transcript.program_graduation_requirements}</Text>
          </View>

          <View style={{ marginBottom: 20 }}>
            <Text style={{ fontSize: 10, marginBottom: 5 }}>Grading System</Text>
            <Text style={{ fontSize: 8, marginBottom: 2 }}>{transcript.grading_system}</Text>
          </View>

          <View style={{ marginBottom: 20 }}>
            <Text style={{ fontSize: 10, marginBottom: 5 }}>Grades</Text>
            <View style={{ marginTop: 10 }}>
              {transcript.grades.grading.map((grade, index) => (
                <View key={index} style={{ flexDirection: 'row', marginBottom: 3 }}>
                  <Text style={{ width: '10%', fontSize: 8 }}>{grade.grade}:</Text>
                  <Text style={{ width: '90%', fontSize: 6 }}>{grade.description}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  seal: {
    margin: "0px auto",
    width: 50,
    height: 50,
    justifyContent: 'flex-end'
  },
  documentStyle: {
    // fontFamily: "Poppins",
    backgroundColor: "#ffffff",
    color: "#000000",
  },

  page: {
    padding: 20,
    // fontFamily: "Poppins",
    lineHeight: 1.6,
    marginLeft: 8,
  },
  organizationName: {
    fontSize: 24,
    textAlign: "left",
    marginLeft: 8,
    // marginBottom: 16,
    // fontWeight: "bold",
    color: "#2E3A59",
  },
  transcriptTitle: {
    fontSize: 10,
    textAlign: "center",
    marginLeft: 8,
    fontStyle: 'italic',
    // fontWeight: 'bold',
    color: "#000000",
  },
  quarterTitle: {
    fontSize: 8,
    marginLeft: 20,
    // fontWeight: 'bold',
    color: "#000000",
  },
  userInfoSection: {
    marginBottom: 30,
    paddingHorizontal: 10,
  },
  userInfo: {
    fontSize: 12,
    marginBottom: 8,
    color: "#4A4A4A",
    //fontWeight: "bold",
  },
  label: {
    // fontWeight: "bold",
    color: "#2E3A59",
  },
  modulesSection: {
    marginBottom: 10,
    paddingHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 16,
    marginBottom: 12,
    //fontWeight: "bold",
    color: "#2E3A59",
    borderBottom: "2px solid #E0E0E0",
    paddingBottom: 8,
  },
  module: {
    marginBottom: 8,
  },
  moduleName: {
    fontSize: 14,
    //fontWeight: "bold",
    color: "#2E3A59",
  },
  moduleScore: {
    fontSize: 10,
    color: "#4A4A4A",
  },
  footer: {
    marginTop: 20,
    textAlign: "center",
  },
  issueDate: {
    fontSize: 12,
    marginBottom: 12,
    color: "#4A4A4A",
  },
  signatureLine: {
    fontSize: 12,
    marginBottom: 5,
    color: "#2E3A59",
  },
  signatureLabel: {
    fontSize: 12,
    color: "#4A4A4A",
  },
});

export default UserTranscriptReport;
