import React, { useEffect } from "react";

export function Options({
  name,
  index,
  value,
  onChange,
  placeholder,
  defaultValue,
  category,
  required,
  options = [{ value: "", label: "" }],
  id,
  type,
}) {
  useEffect(() => {
    console.log("Options.js 7 | updated");
  }, [value]); // Depend on 'value' to mimic 'shouldComponentUpdate' behavior

  return (
    <div className="lsiinput__container">
      <div className="inputfields__container">
        <div className="inputfield__label">
          <span>
            {index + 1}.{"  "}
          </span>
          <span>{name}</span>
          <span
            style={{
              color: required ? "red" : "black",
              verticalAlign: "middle",
              fontSize: 12,
            }}
          >
            {required ? "(required)" : "(optional)"}
          </span>
        </div>
        <div className="select-ant-container">
          <select
            required
            name={name}
            value={value}
            onChange={(e) => {
              console.log("Options 39 | id in  options", id, type);
              onChange(e, index, category, id, type);
            }}
            placeholder={placeholder}
            defaultValue={defaultValue}
            className="lsi__selectoptioncontainer"
          >
            <option disabled selected value className="lsioption">
              Nothing Selected
            </option>
            {options.map((option, index) => (
              <option className="lsioption" key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default Options;
