import React from "react";
import { dataLength } from "src/utils/dataLength";

export const NavSectionItemTitle = ({
  name,
  currentSectionName,
  toggleView,
  number,
  section,
}) => {
  // Function to format the name
  const formatName = (name) => {
    // Split the name by spaces
    const parts = name.split(" ");

    // Initialize an array to hold the formatted parts
    let formattedParts = [];

    // Loop through each part
    for (let i = 0; i < parts.length; i++) {
      if (parts[i] === "") {
        // Add a non-breaking space for empty parts
        formattedParts.push("\u00a0");
      } else {
        // Add the rest of the parts as is and break the loop
        formattedParts.push(parts.slice(i).join(" "));
        break;
      }
    }

    // Join the formatted parts and return the result
    return formattedParts.join("");
  };

  const formattedName = formatName(name);

  return (
    <div
      className={
        name === currentSectionName ? `selected-section` : `unselected-section`
      }
      onClick={() => {
        toggleView(number, section);
      }}
    >
      <div>{number + 1}.</div>{" "}
      <div className="sectionname__text">{formattedName}</div>
      {console.log("NavSectionItemTitle.js 23 | formatted name", formattedName)}
    </div>
  );
};
