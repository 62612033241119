import React from "react";
import EQPrompt from "./EQPrompt";
export const ViewAssessment = ({
  statements,
  handleResponseChange,
  responses,
}) => {
  return statements?.map((statement, index) => (
    <div key={index}>
      <EQPrompt
        index={index}
        statement={statement.statement}
        promptQuestion={statement.statement}
        handleResponseChange={handleResponseChange}
        responses={responses}
      />
    </div>
  ));
};
