import React, { useState, useEffect, useMemo } from "react";
import Situation from "./components/Situation";
import DangerLevel from "./components/DangerLevel";
import "./index.css";
import GameLostModal from "./components/GameLostModal";

const ChatGame = ({ data }) => {
  console.log("ChatGame 6 | chatgame data", data);
  const [situationIndex, setSituationIndex] = useState(0);
  // Gamification Variables
  // - Win-Lose conditional
  const [gameLost, setGameLost] = useState(false);
  // - Danger Level
  const [dangerLevel, setDangerLevel] = useState(30);
  const [gameWon, setGameWon] = useState(false);
  const [gameLostAnimate, setGameLostAnimate] = useState(false);
  console.log("ChatGame 14 | data situations", data.situations);

  useEffect(() => {
    console.log("ChatGame 16 | rerendering", data);
    checkWinOrLoss();
    window.scrollTo(0, document.body.scrollHeight);
    if (gameLost) {
      setTimeout(() => {
        setGameLostAnimate(true);
      }, 3000);
    }
  }, [situationIndex, gameLost, dangerLevel, gameWon]);

  // Gamification Functions
  // - Function that increases/decreases danger level each time user hits an answer
  // - Function that checks danger level every time user makes a decision -> Should go in useEffect
  const checkWinOrLoss = () => {
    if (dangerLevel > 100) {
      console.log("ChatGame 30 | setting game lost");
      setGameLost(true);
    }
    if (situationIndex === data.situations.length) {
      console.log("ChatGame 35 | game done!");
      setTimeout(() => {
        setGameWon(true);
      }, 3000);
    }
  };

  // - Function that resets the game
  const restartGame = () => {
    setGameLost(false);
    setGameWon(false);
    setGameLostAnimate(false);
    setSituationIndex(0);
    setDangerLevel(50);
  };

  return (
    <div className="chat-game-container">
      {gameLostAnimate ? (
        <div>
          <GameLostModal restartGame={restartGame} />
        </div>
      ) : null}
      {gameWon ? (
        <h3 className="game-won animate-left">You got away safely!</h3>
      ) : null}
      <div className="gameanddanger__container">
        <div className="chatgame__container">
          {data.situations &&
            !gameWon &&
            data.situations.map((situation, index) => {
              if (index > situationIndex) return null;
              if (gameLostAnimate) return null;
              if (gameWon) return null;
              // Check if Game Lost to trigger Modal
              return (
                <Situation
                  key={index}
                  index={index}
                  gameWon={gameWon}
                  gameLost={gameLost}
                  gameLostAnimate={gameLostAnimate}
                  situation={situation}
                  situationIndex={situationIndex}
                  setSituationIndex={setSituationIndex}
                  dangerLevel={dangerLevel}
                  setDangerLevel={setDangerLevel}
                />
              );
            })}
        </div>
        <div className="dangerlevel__container">
          {gameWon || gameLost ? null : (
            <DangerLevel dangerLevel={dangerLevel} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatGame;
