import React from "react";
import { Draggable } from "react-beautiful-dnd";

const Word = () => {
  return (
    <Draggable draggableId={`567`} index={1}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className=""
        >
          word
        </div>
      )}
    </Draggable>
  );
};

export default Word;
