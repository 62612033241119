// EventHeader.js

import React from 'react';
import moment from 'moment';
import Button from "src/components/shared/Button";
import { toast } from "react-toastify";

const EventHeader = ({ eventData, isPastEvent, setReminderModalOpen, setTestReminderOpen, setEditEventOpen }) => (
  <div className="event__manager__header">
    <div className="event__name">
      <span className="event__name__title">Events Manager: </span>
      {eventData.eventName}
    </div>
    <div>
      <Button
        style={{ display: "block" }}
        disabled={isPastEvent}
        onClick={() =>
          isPastEvent
            ? toast.error("This is a past event")
            : setReminderModalOpen({ type: "all", title: "Everyone" })
        }
        className="action-button"
        type="outline"
        title={"Send Reminder"}
      />
      <Button
        onClick={() => setTestReminderOpen(true)}
        className="action-button"
        type="primary"
        style={{
          minWidth: 0,
          minHeight: 0,
          borderRadius: 10,
          fontSize: "medium",
          fontWeight: 400,
          float: "right",
          marginTop: 10,
          display: "block",
        }}
        title={"Test"}
      />
    </div>
  </div>
);

export default EventHeader;
