const initialState = { open: false, title: "", icon: null, info: null };

export const notificationsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "notifications/open":
      return {
        ...state,
        open: true,
        title: payload.title,
        icon: payload.icon,
        info: payload.info,
      };
    case "notifications/close":
      return { ...state, open: false, title: "" };
    default:
      return state;
  }
};
