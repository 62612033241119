import {
  SET_CLASSROOMS,
  SET_CURRENT_CLASSROOM_ID,
  SET_CURRENT_CHAT_ID,
  SET_CURRENT_CLASSROOMS,
  SET_CURRENT_CLASS_USERS,
  SET_STATUS,
  SET_ERROR,
  FETCH_CHATROOM_STUDENT_SUCCESS,
  CLEAR_CLASSROOM,
  FETCH_CHATROOM_REQUEST,
  FETCH_CHATROOM_SUCCESS,
  FETCH_CHATROOM_FAILURE,
  SET_CHATROOM_DATA,
  TOGGLE_CHAT_BUBBLE,
} from "./chatActionTypes.js";

import {
  getLocalStorageData,
  setLocalStorageData,
} from "src/utils/localStorageUtils";

export const setSingleUserChat = (studentChat) => ({
  type: FETCH_CHATROOM_STUDENT_SUCCESS,
  payload: studentChat,
});

export const toggleChatBubbleOpen = (open) => {
  return {
    type: TOGGLE_CHAT_BUBBLE,
    payload: !open,
  };
};

export const setClassrooms = (classrooms) => ({
  type: SET_CLASSROOMS,
  payload: classrooms,
});

export const setCurrentClassroomId = (classroomId) => ({
  type: SET_CURRENT_CLASSROOM_ID,
  payload: classroomId,
});

export const setCurrentChatId = (chatId) => {
  console.log("chatActions.js 46 | setting current chat id", chatId);
  return {
    type: SET_CURRENT_CHAT_ID,
    payload: chatId,
  };
};

export const setCurrentClassRooms = (rooms) => ({
  type: SET_CURRENT_CLASSROOMS,
  payload: rooms,
});

export const setCurrentClassUsers = (users) => ({
  type: SET_CURRENT_CLASS_USERS,
  payload: users,
});

export const setStatus = (status) => ({
  type: SET_STATUS,
  payload: status,
});

export const setError = (error) => ({
  type: SET_ERROR,
  payload: error,
});

export const fetchChatroomRequest = () => ({
  type: FETCH_CHATROOM_REQUEST,
});

export const fetchChatroomSuccess = (rooms, users) => ({
  type: FETCH_CHATROOM_SUCCESS,
  payload: { rooms, users },
});

export const fetchChatroomFailure = (error) => ({
  type: FETCH_CHATROOM_FAILURE,
  payload: error,
});

export const setChatroomData = (rooms, users) => ({
  type: SET_CHATROOM_DATA,
  payload: { rooms, users },
});

export const clearPreviousClassroom = () => ({
  type: CLEAR_CLASSROOM,
});

export const setClassroom = (classId) => {
  return function (dispatch) {
    dispatch(clearPreviousClassroom());
    dispatch(setCurrentClassroomId(classId));
  };
};

export const fetchAllClassrooms = (firebase, authUser) => async (dispatch) => {
  dispatch(setStatus("loading"));
  try {
    const cachedClassrooms = getLocalStorageData("classroomsChatData");
    if (cachedClassrooms) {
      dispatch(setClassrooms(cachedClassrooms));
      dispatch(setStatus("succeeded"));
    }

    const classroomsSnapshot = await firebase.classrooms().once("value");
    const classroomsData = classroomsSnapshot.val();
    if (classroomsData) {
      dispatch(setClassrooms(classroomsData));
      setLocalStorageData("classroomsChatData", classroomsData);
      dispatch(setStatus("succeeded"));
    } else {
      dispatch(setError("No classrooms found"));
      dispatch(setStatus("failed"));
    }
  } catch (error) {
    dispatch(setError(error.message));
    dispatch(setStatus("failed"));
  }
};

export const fetchSelectedClassChatroom = (firebase, currentClassroomId) => {
  console.log("chatActions.js 126 | fetching class by id");
  return async (dispatch) => {
    dispatch(fetchChatroomRequest());
    try {
      const chatroomRef = firebase.chatroom(currentClassroomId);
      console.log("chatActions.js 135 | chatroom", currentClassroomId);

      const classCacheId = `classChatroom_${currentClassroomId}`;

      const cachedChatroom = getLocalStorageData(classCacheId);

      if (cachedChatroom) {
        const { rooms, users } = cachedChatroom;
        dispatch(setChatroomData(rooms, users));
      }

      const chatroomReq = await chatroomRef.once("value");

      if (chatroomReq.val()) {
        console.log("chatActions.js 171 | got classroom", chatroomReq.val());
        const { rooms, users } = chatroomReq.val();
        rooms && dispatch(fetchChatroomSuccess(rooms, users));
        users && dispatch(fetchChatroomSuccess(rooms, users));
        setLocalStorageData(classCacheId, {
          rooms,
          users,
        });
      } else {
        console.log("Chat 18 | no data from chatroom action");
        dispatch(fetchChatroomFailure("No student data available"));
      }
    } catch (error) {
      dispatch(fetchChatroomFailure(error.message));
    }
  };
};

export const fetchSingleStudentChat = (firebase, currentClassroomId) => {
  return async (dispatch) => {
    dispatch(fetchChatroomRequest());
    try {
      const chatroomRef = firebase.chatroom(currentClassroomId);

      const classCacheId = `classChatroom_${currentClassroomId}`;

      const cachedChatroom = getLocalStorageData(classCacheId);

      if (cachedChatroom) {
        const { rooms, users } = cachedChatroom;
        dispatch(setChatroomData(rooms, users));
      }

      const chatroomReq = await chatroomRef.once("value");

      if (chatroomRef.val()) {
        const { rooms, users } = chatroomReq.val();
        rooms && dispatch(fetchChatroomSuccess(rooms, users));
        users && dispatch(fetchChatroomSuccess(rooms, users));
        setLocalStorageData(classCacheId, {
          rooms,
          users,
        });
      } else {
        console.log("Chat 18 | no data from chatroom action");
        dispatch(fetchChatroomFailure("No student data available"));
      }
    } catch (error) {
      dispatch(fetchChatroomFailure(error));
    }
  };
};

export const getSingleStudentChatData = (firebase, classId, chatId) => {
  return async (dispatch) => {
    try {
      console.log("CurrentChat 11 | fetching", classId, chatId);
      const chatData = await firebase
        .chatMessages(classId, chatId)
        .once("value");
      if (chatData.val()) {
        console.log("CurrentChat 12 | single user chat data", chatData.val());
        dispatch(setSingleUserChat(chatData.val()));
      } else {
        console.log("CurrentChat 14 | no data");
      }
    } catch (error) {
      console.log("CurrentChat 17 | error getting data", error.message);
    }
  };
};
