import React from "react";
import "./CreditWelcomeModal.css";
import Button from "src/components/shared/Button";
const CreditWelcomeModal = ({ onClose }) => {
  return (
    <div className="creditwelcomemodal">
      <div className="creditwelcomemodal-title">
        <div className="welcome-to-the">Welcome to the Debt Game!</div>
        {/* <img className="close-icon" alt="" src="/undefined.png" /> */}
      </div>
      <div className="credit-info">
        <span>{`Understand how to `}</span>
        <b>pay off debt</b>
        <span>!</span>
      </div>
      <div className="creditdebt-title">
        <div className="youve-been-given">
          You've been given the following debts to pay. Make sure you pay them
          before the 6 months end!
        </div>
      </div>
      <div className="debtselectioncontent">
        <div className="debtselectiontitle">Credit Card:</div>
        <div className="debtselectionamount">
          <p className="amount">Amount:</p>
          <p className="p">$ 2000</p>
        </div>
        <div className="debtselectioninfo">
          <div className="credit-monthstopay">
            <p className="amount">Months to Pay</p>
            <p className="p">6</p>
          </div>
          <div className="credit-montlyinterest">
            <p className="amount">{`Monthly Interest `}</p>
            <p className="p">10%</p>
          </div>
        </div>
      </div>
      <Button title="Start Game" onClick={onClose} />
    </div>
  );
};

export default CreditWelcomeModal;
