import React from "react";
import "./index.css";

export const Checkbox = ({ isChecked, onClick }) => {
  return (
    <div
      onClick={() => onClick && onClick()}
      className={isChecked ? "checkbox__checked" : "checkbox__unchecked"}
    >
      {isChecked && (
        <img src={"/assets/images/check_icon.svg"} alt="check icon" />
      )}
    </div>
  );
};

export default Checkbox;
