import React from "react";
import "./Testimonials.css";

export const Testimonial = ({ name, text, stars, lesson }) => {
  const starIcons = [...Array(stars)].map((_, index) => (
    <img key={index} className="star-icon" alt="" src="/star-icon.svg" />
  ));

  return (
    <div className="testimonial-card1">
      <div className="testimonial-info">
        <div className="client-info">
          <div className="person-details1">
            <div className="john-doe1">"{text}"</div>
            <div className="vice-president1">Program Participant</div>
          </div>
        </div>
      </div>
      <div className="star-rating">{starIcons}</div>
      {lesson && <div className="lesson__name">{lesson} Lesson</div>}
    </div>
  );
};

export const Testimonials = ({ isDiversion }) => {
  // Group feedback objects into rows

  const selectedFeedback = isDiversion ? diversionFeedback : feedback;
  const rows = [];
  for (let i = 0; i < selectedFeedback.length; i += 3) {
    rows.push(selectedFeedback.slice(i, i + 3));
  }

  return (
    <div className="testimonials">
      <div className="headline-subhead2">
        <div className="reviews">Participant Reviews</div>
        <div className="see-what-others-container">
          <span>{`See what our graduates are saying about the program `}</span>
        </div>
      </div>
      {rows.map((row, rowIndex) => (
        <div className="cards-row1" key={rowIndex}>
          {row.map((item, index) => (
            <Testimonial
              key={index}
              name={item.name}
              text={item.text}
              stars={item.stars}
              lesson={item.lesson}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const feedback = [
  {
    name: "Program Participant",
    text: "Magnificent, Life changing, Going to miss it for sure.",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "My experience with Red was great from getting to meet everybody from staff and mentors and other participants in the program as well. RED has helped me become a better version of myself.",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "I'm extremely impressed with everything I've experienced in the RED program.",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "It was amazing and truly a blessing.",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "Life changing and saving experience. This program has truly turned my life around.",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "Absolutely incredible and life changing",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "Eventful and Breathtaking , Honestly didn't expect the warmth and hospitality.",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "Life changing and eye opening. Education that all citizens should obtain.",
    stars: 5,
  },
  // Add more feedback objects as needed
];

const diversionFeedback = [
  {
    text: "I thought the module helped me to understand better how my brain operates when dealing with emotions that can control my attitude. It definitely taught me how to get my emotions in check so that I can proceed to a better outcome.",
    lesson: "Emotional Intelligence",
    stars: 5,
  },
  {
    text: "I liked this lesson because it had interactive questions for understanding a spreadsheet and led me to apply for my first credit card.",
    lesson: "Finance Fundamentals",
    stars: 5,
  },
  {
    text: "I thought this lesson was great because many people question why they should vote when there are many other votes, and this lesson outlines why each vote is important.",
    lesson: "Civic Education",
    stars: 5,
  },
  {
    text: "I thought this lesson was great because it helped keep me aware of my rights, which everyone should know more about.",
    lesson: "Bridge the Gap",
    stars: 5,
  },
  {
    text: "This module helped me to understand what dopamine is and how it affects the brain. I also have a better understanding of how that feel-good feeling we experience is developed.",
    lesson: "Substance Use Disorder and Addiction",
    stars: 5,
  },
  {
    text: "I thought this lesson was great because the information can be used to recognize earlier on if a loved one has a substance use disorder. This is valuable because this will allow for more people to get the help they need.",
    lesson: "Substance Use Disorder and Addiction",
    stars: 5,
  },
];

export default Testimonials;
