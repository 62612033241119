import React, { useState } from "react";
import Button from "../shared/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

const range = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const FeedbackModal = ({
  finishLoading = false,
  formVisible = false,
  lessonName,
  handleFinishModule,
  lessonId,
}) => {
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [textfeedback, setTextfeedback] = useState("");

  const handleNumberClicked = (number) => {
    setSelectedNumber(number);
    setSaveEnabled(textfeedback.trim().length > 0);
  };

  const handleFeedbackChange = (e) => {
    setTextfeedback(e.target.value);
    setSaveEnabled(selectedNumber !== null && e.target.value.trim().length > 0);
  };

  const handleSaveClick = async () => {
    if (saveEnabled) {
      await handleFinishModule(selectedNumber, textfeedback);
    } else {
      toast.error("Please enter some feedback before saving.");
    }
  };

  if (!formVisible) return null;

  return (
    <div className="feedbackmodal__container">
      <div className="feedbackmodal__content">
        <div className="feedbackmodal__top">
          <div className="feedbackmodalheader">
            Rate the Module: {lessonName}
          </div>
          <div className="feedbackmodalheader">X</div>
        </div>
        <div className="feedbackmodal__info">
          How likely are you to recommend this course to friends and/or
          colleagues?
        </div>
        <div className="feedbackmodal__scalecontainer">
          {range &&
            range.map((number, index) => {
              console.log("FeedbackModal | 16", number);
              return (
                <div
                  onClick={() => {
                    handleNumberClicked(number);
                  }}
                  style={{
                    backgroundColor: `${
                      number === selectedNumber
                        ? "rgba(187, 65, 65, 1)"
                        : "white"
                    }`,
                    color: `${
                      number === selectedNumber
                        ? "white"
                        : "rgba(187, 65, 65, 1)"
                    }`,
                  }}
                  className="feedbackmodalscale__number"
                  key={index}
                >
                  {number}
                </div>
              );
            })}
        </div>
        {selectedNumber && (
          <div className="feedbackinput__container">
            <div className="feedbackinput__text">
              Describe what you liked/disliked about this lesson.
            </div>
            <textarea
              value={textfeedback}
              onChange={handleFeedbackChange}
              className="feedbackinput__input"
              placeholder="I thought this lesson was awesome/terrible because..."
            />
          </div>
        )}
        <div className="feedbackmodal__save">
          <Button
            title={finishLoading ? "Loading..." : "Save"}
            onClick={handleSaveClick}
            type={saveEnabled ? "primary" : "disabled"}
          />
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
