import React from "react";
import "./index.css";

import { Link } from "react-router-dom";
import Icon from "src/components/shared/Icon";

export const AssessmentLinkCard = ({
  title = "Level of Service Intake",
  description = "We gather background information to help you in your future endeavors. Please answer to the best of your ability.",
  active = true,
  shown = true,
  timeToComplete = "30min",
  destinationRoute,
}) => {
  if (!shown) return null;
  return (
    <div className="assessmentlink__container">
      <div className="assessmentlink__content">
        <div className="titleanddescription">
          <div className="assessmentlink__title">{title} </div>
          <div className="assessmentlink__description">{description}</div>
        </div>
        <div className="assessmentlinkstart__container">
          <div className="assessmentlink__time">
            <Icon
              name="access-time"
              width={20}
              height={20}
              style={{ color: "#b0acac" }}
            />
            <div>{timeToComplete}</div>
          </div>
          <Link
            className={
              active
                ? "assessmentlink__buttonactive"
                : "assessmentlink__buttoninactive"
            }
            to={active ? destinationRoute : "#"}
          >
            Start Assessment
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AssessmentLinkCard;
