
import React, {useEffect,useState } from "react";
import { useHistory } from "react-router-dom";
import "../index.css";
import Button from "src/components/shared/Button";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import moment from "moment/moment";
import { getCalendarEvent } from "src/api/googleCalendar";

export const ConfirmedEventPage = ({firebase}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [eventData, setEventData] = useState({})
  const [eventConfirmed, setEventConfirmed] = useState(false)
  const [validToken, setValidToken] = useState(true)
  const [tokenData, setTokenData] = useState({})

  const splittedUrl = window.location.href.split("/")
  let tokenId = splittedUrl[splittedUrl.length-2]
  let googleCalendarId = splittedUrl[splittedUrl.length-1]

  function getTimeZone() {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}


const fetchEventData = async (eventId) => {
  await firebase
  .getEventData(eventId)
  .once("value", (snap) => {
    const eventData = snap.val();
    setEventData({
      title: eventData.title,
      startDate: eventData.startDate,
      endDate:eventData.endDate
    })
  })
  setLoading(false);
}

  const loadTokenAndEventData = async () => {
    //2 we leave the loading message until its fetched
    let tokenObject;
    //3 we fetch the token data
    await firebase
    .getTokenData(tokenId)
    .once("value", (snap) => {
      const tokenData = snap.val();
      tokenObject = tokenData;
    })

    //4 it the token isnt found in the db, then we set a state to show an error page
    if(tokenObject == null){
      setLoading(false)
      setValidToken(false);
      return
    } else {
    // otherwise we set the state with the data
      setTokenData(tokenObject)
    }

    fetchEventData(tokenObject.eventId);
  }


 const confirmEvent = async () => {
    // 6 when user clicks the confirm button, we update tune nested property of this specifc reminder
   let path = `/attendees/${tokenData.attendeeId}/reminderConfirmed/${tokenId}/confirmed`;
   await firebase
  .getEventData(tokenData.eventId)
  .update({
    [path]:true
  })

  // 7 we set the confirmation page
  // 8 we remove the token from the db, (this doesnt delete data we need, its all on the event collection)
  await firebase
  .getTokenData(tokenId)
  .update({
    confirmed:true
  })

  setEventConfirmed(true);


}

  useEffect(()=>{
    setLoading(true);
    if(tokenId === 'test'){
      fetchEventData(splittedUrl[splittedUrl.length-1])  // for test purposes, we just fetch
    } else {
      loadTokenAndEventData()     // we fetch the token and event data on useeffect
    }
  },[])

  console.log('tokenData',tokenData)

  return (
    <div className="confirmedEvent__container">
      {loading ? <div>loading..</div>
        
        : !validToken ?
          <div className="confirmedEvent__content">
            <div className="confirmedEvent__body">
              <div className="confirmedEvent__message">
                <div>
                  Its seems that there is a problem with this link, 
                  if you think this is a mistake please contact us at ignacio@stoprecidivism.org
                </div>
              </div>
            </div>
          </div>
        : eventConfirmed || tokenData?.confirmed?
        <div className="confirmedEvent__content">
          <div className="confirmedEvent__header">
            <div className="confirmedEvent__title">Thank You!</div>
            {/* <div className="eventReminder__closeIcon"><img src={"/assets/images/close_icon.svg"} alt="close icon"/></div> */}
          </div>
          <div className="confirmedEvent__body">
            <div><img src={"/assets/images/confirmation_icon.svg"} alt="confirmation icon"/></div>
            <div className="confirmedEvent__message">
              <div>You have confirmed the event, it will be held</div>
              <div className="confirmedEvent__message__date">on {moment(eventData.startDate).format('MMMM DD [at] hh:mm A')} (GMT {getTimeZone()})</div>
            </div>
            <Button onClick={()=>history.push(`/home`)} className="action-button" title={"Return to home page"}/>
          </div>
        </div>
          :
        <div className="confirmedEvent__content">
          <div className="confirmedEvent__header">
            <div className="confirmedEvent__title"></div>
            {/* <div className="eventReminder__closeIcon"><img src={"/assets/images/close_icon.svg"} alt="close icon"/></div> */}
          </div>
          <div className="confirmedEvent__body">
            <div className="confirmedEvent__message">
              <div>Do you want to confirm your attendance to the event "{eventData.title}"? </div>
              <div>It will be held on {moment(eventData.startDate).format('MMMM DD [at] hh:mm A')} (GMT {getTimeZone()})</div>
            </div>
            <Button type={tokenId === 'test' ? 'disabled' : 'primary'} onClick={()=>confirmEvent()} className="action-button" title={"Confirm"}/>
            <div style={{fontSize:12,color:'gray',marginTop:20}}>If due to an emergency you cannot attend, please contact Zeus immediately.</div>
          </div>
        </div>
      }
    </div>
  );
};


export default compose(withFirebase)(ConfirmedEventPage);




