import React from "react";
import "./CarouselButton.css";
const CarouselButton = ({ title, handleClick, index, currentIndex }) => {
  return (
    <div
      onClick={handleClick}
      className={
        index === currentIndex ? "carouselbuttonactive" : "carouselbutton1"
      }
    >
      <div className="label20">{title}</div>
    </div>
  );
};

export default CarouselButton;
