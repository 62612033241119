export const navLinks = [
  { title: "Dashboard", iconName: "dashboard", to: "/home" },

  {
    title: "Calendar",
    iconName: "calendar-white",
    to: "/calendar",
    requiredAdmin: true,
  },
  { title: "Certificates", iconName: "certificates", to: "/certificates" },

  // {
  //   title: "Lesson Analytics",
  //   iconName: "lesson-analytics",
  //   to: "/lessonAnalytics",
  // },

  {
    title: "Classroom",
    iconName: "classroom",
    to: "/classroom",
    requiresAdmin: true,
  },

  {
    title: "Task List",
    to: "/taskList",
    iconName: "admin",
    requiresAdmin: true,
  },
  { title: "Admin", to: "/admin", iconName: "admin", requiresAdmin: true },

  {
    title: "Issues",
    to: "/issues",
    iconName: "scroll-down",
    requiresAdmin: true,
  },
  {
    title: "Reviews",
    to: "/moduleReviews",
    iconName: "scroll-down",
    requiresAdmin: true,
  },
  {
    title: "EQ Results",
    to: "/eqResults",
    iconName: "scroll-down",
    requiresAdmin: false,
  },
  {
    title: "Classroom Chats",
    to: "/classroomChats",
    iconName: "email",
    requiresAdmin: false,
  },
  {
    title: "Settings",
    to: "/settings",
    iconName: "settings",
    requiresAdmin: false,
  },
];
