

export const matchUserProperty = (users, uid, field) => {
  let property;
  users.forEach((e) => {
    if (uid === e.uid) {
      property = e[field];
    }
  });
  return property;
};

export const checkReminderConfirmed = (remindersObject) => {
  let reminderConfirmed = false;
  if (remindersObject) {
    Object.values(remindersObject).forEach((e) => {
      if (e.confirmed === true) {
        reminderConfirmed = true;
      }
    });
  }
  return reminderConfirmed;
};

export const checkStudentProgress = (uid, eventData, users) => {
  let lessons = matchUserProperty(users, uid, "lessons");
  if (!lessons) {
    return "--";
  } else if (!lessons[eventData.module]) {
    return "--";
  } else if (lessons[eventData.module].progress && lessons[eventData.module].sectionLength) {
    return `${Math.round(
      (lessons[eventData.module].progress / lessons[eventData.module].sectionLength) * 100
    )}%`;
  } else {
    return "--";
  }
};
