import React, { Component, memo } from "react";
import renderHTML from "react-render-html";

import "./index.css";
import { getWord } from "../../../../api/words";

class Text extends Component {
  state = {
    updated: false,
    wordsArray: [],
  };
  logs = () => {};

  componentDidMount() {
    // this.getATags();
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.value === this.props.value) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  // getATags = () => {
  //   // console.log("Text 39 | getting A Tags", this.props.value);
  //   let words = document.getElementsByClassName("word-tag-container");
  //   // console.log("Text 11 | words", typeof words, words[0], words[1]);

  //   let wordsArray = Object.values(words).map((word, index) => word);
  //   wordsArray.forEach(async (word) => {
  //     var node = document.createElement("span");
  //     node.className = "tooltiptext";
  //     const wordFromApi = await getWord(word.textContent);

  //     var textnode = document.createTextNode(wordFromApi.definition);
  //     node.appendChild(textnode);
  //     // console.log("Text 24 | word", word.textContent);
  //     word.appendChild(node);
  //   });
  // };

  render() {
    const { value, type, quizzesDone } = this.props;

    if (!this.props.value) {
      return null;
    }

    let replaceBefore = value.replace(
      /<a href/g,
      `<a class="word-tag-container" href`
    );

    return <div className="text-container">{renderHTML(replaceBefore)}</div>;
  }
}

export default Text;
