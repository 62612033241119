import React, { useState, useContext, useEffect } from "react";

import quest from "./questions.json";

import "./index.css";

import { submitQuestData } from "../../api/quest";
import { openNotification, errorNotification } from "../../utils/notifications";
import { AuthUserContext, withAuthentication } from "../Session";
import { withFirebase } from "../Firebase";
import compose from "recompose/compose";
import { Items } from "./Items";
import Button from "../shared/Button";
import moment from "moment";

const QuestAPI = ({ history, firebase }) => {
  const user = useContext(AuthUserContext);
  const [questData, setQuestData] = useState({ ...quest.Data });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [introScreen, setIntroScreen] = useState(true);
  const [time, setTime] = useState({ start: null });


  useEffect(()=>{
    getLSIData()
  },[])

  const getLSIData = () => {
    firebase
    .lsiData(user.uid)
    .once("value")
    .then((data) => {
      if (data.val()) {
        let age;
        let gender;
        let lsiObject = data.val();
        let lsi = lsiObject.lsi? lsiObject.lsi : lsiObject
        lsi.map(e => {
          if (e.name === "Date of Birth"){
            age = moment().diff(e.value, 'years');
          }
          if(e.name === "Gender"){
            gender = e.value === 'Female' ? 'F' : 'M'
          }
        })

        setQuestData(prevState => {
          prevState.demographicInfo.Age = isNaN(age) ? '34' : age;
          prevState.demographicInfo.Gender = gender;
          return prevState;
        })
        
      }})
    }

  const handleSubmit = async () => {

    console.log("QuestAPI 16 | user", user);
    setLoading(true);

    console.log("QuestAPI 30 | time taken string", time);

    var a = new Date();

    var b = time.start;
    var totalTime = (a - b) / 1000;

    console.log("You waited: " + Math.floor(totalTime) + " seconds");

    try {

      let unansweredQuestions = questData.Items.slice(
        2,
        questData.Items.length - 1
      ).filter((item) => item.Answer === 0 || (item.FreeText && item.FreeText.trim() === ''))

      if (unansweredQuestions.length === 0) {

        let requestData = {
          data: {
            ...questData,
            executionTime: Math.floor(totalTime),
            demographicInfo: {
              ...questData.demographicInfo,
              Email: user.email,
              Name: user.username || user.displayName || 'RED Student',
              Surname: user.classId,
            },
          }
        }


        await handleSavetoDB(requestData);
        const questDataSubmit = await submitQuestData(requestData);

        if (questDataSubmit.message === "Successful") {
          handleSuccess(questDataSubmit);
       
        if (questDataSubmit.questResponse === "415") {
          handleError();
        }
        if (questDataSubmit.questResponse !== "201") {
          handleSupportError();
        }
      } else {
        handleMissingInfoError(unansweredQuestions);
      }
      } 
    } catch (error) {
        setLoading(false);
        console.log("QuestAPI 12 | error with quest data", error.message);
        openNotification("Error submitting Quest Data", error.message);
      }
  }

  const handleSuccess = async (questDataSubmit) => {
    console.log("QuestAPI 50 | success api submission");
    await firebase
      .user(firebase.currentUserId())
      .update({ eqTestDeliveredToQuest: true });

    console.log("QuestAPI 16 | quest data submitted", questDataSubmit);
  };

 const handleSavetoDB = async (requestData) => {
      await firebase.allEQData(user.uid).push(requestData).then(res =>{
        //console.los('res',res)
      }).catch(err => console.log('err',err))

      await firebase
      .user(firebase.currentUserId())
      .update({ eqTestComplete: true });

      setLoading(false);
      setSuccess(true);
      openNotification(`Successfully submitted Emotional Assessment!`);
      history.push("/home");
      return requestData;
    }

  const handleError = () => {
    errorNotification(
      "Error submitting quest data",
      "Test already submitted from this email"
    );
    setLoading(false);
    return;
  };

  const handleSupportError = () => {
    errorNotification("Error submitting quest data", "Please contact support");
    setLoading(false);
    return;
  };

  const handleMissingInfoError = (unansweredQuestions) => {
    console.log("QuestAPI 16 | missing info", " ");
    setLoading(false);

    errorNotification(
      "Error submitting Quest Data",
      `Missing info, ${unansweredQuestions.length} answers missing. `
    );
  };

  const handleEdit = (index, answer) => {
    if (success) return;
    let questDataCopy = { ...questData };
    if (typeof answer === "number") {
      questDataCopy.Items[index].Answer = answer;
    } else {
      questDataCopy.Items[index].FreeText = answer;
    }

    setQuestData(questDataCopy);
  };

  const isDisabled =
    questData.Items[0].FreeText === "" ||
    questData.Items[1].FreeText === "" ||
    questData.Items[2].FreeText === "";

  if (introScreen) {
    return (
      <div className="introscreen__container">
        <div className="introscreen__content">
          <div className="eq__title">Emotional Assessment</div>
          <p className="eq__description">
            This Neural Network Report (NNR) will evaluate emotional awareness
            and provide a personalized and objective means by which we can pair
            you with an appropriate mentor and you can effectively navigate some
            of life’s challenges while enhancing your own well-being,
            relationships and overall quality of life.
          </p>
          <Button
            title="Start"
            className="submit__questbutton"
            onClick={() => {
              setTime({ start: new Date(), end: null });
              setIntroScreen(false);
            }}
          />
        </div>
      </div>
    );
  }

  const toggleAllQuestions = () => {
    let questDataCopy = { ...questData };
    questDataCopy.Items.forEach((item, index) => {
      if (index > 2) {
        item.Answer = 1;
      } else {
        // item. = 0;
      }
    });
    setQuestData(questDataCopy);
  };

  return (
    <div className="quest-container">
      <h1>Emotional Assessment 2.0</h1>

      {process.env.NODE_ENV === "development" && (
        <button onClick={toggleAllQuestions}>Toggle all questions</button>
      )}

      {/* <Timer /> */}
      <Items
        questData={questData.Items}
        setQuestData={setQuestData}
        handleEdit={handleEdit}
      />
      <hr />
      {!loading && !success && (
        <button
          // disabled={isDisabled}
          className="submit__questbutton"
          onClick={() => handleSubmit()}
        >
          Submit Quest Data
        </button>
      )}
      {isDisabled && (
        <p className="missingfields__text">
          Missing fields. Please fill in all the required information.
        </p>
      )}
      {loading && <p>Loading</p>}
      {success && (
        <>
          <p>Successully Submitted Quest Data.</p>
          <p>You'll get an email with your results.</p>
          <p>Please continue with the lesson by pressing NEXT</p>
        </>
      )}
    </div>
  );
};

export const Demographics = ({ demographics }) => {
  return <div>Info</div>;
};

export default compose(withFirebase, withAuthentication)(QuestAPI);
