import { combineReducers } from "redux";
import { calendarEventsReducer } from "./calendarEvents/calendarEventsReducer";
import { classroomsReducer } from "./classrooms/classroomsReducer";
import issuesReducer from "./issues/issuesReducer";
import lessonReducer from "./lesson/lessonReducer";
import { loginTypeReducer } from "./loginType/loginTypeReducer";
import { modulesReducer } from "./modules/modulesReducer";
import { notificationsReducer } from "./notifications/notificationsReducer";
import authUserReducer from "./authUser/authUserReducer";
import { usersReducer } from "./users/usersReducer";
import eventsManagerReducer from './eventsManager/eventsManagerReducer'
import { lsiAssessmentReducer } from "./lsiAssessment/lsiAssessmentSlice";
import preFillModalReducer from "./preFillModal/preFillModalReducer";

import {
  FinalAssessmentReducer,
  LSIReducer,
} from "./dataTables/dataTablesReducer";
import chatReducer from "./chats/chatReducer";

export const allReducers = combineReducers({
  modules: modulesReducer,
  notification: notificationsReducer,
  loginType: loginTypeReducer,
  events: calendarEventsReducer,
  classrooms: classroomsReducer,
  users: usersReducer,
  lesson: lessonReducer,
  issues: issuesReducer,
  authUser: authUserReducer,
  eventsManager: eventsManagerReducer,
  lsi: LSIReducer,
  finalAssessment: FinalAssessmentReducer,
  lsiAssessment: lsiAssessmentReducer,
  chats: chatReducer,
  preFillModal: preFillModalReducer
});
