export const SET_CLASSROOMS = "SET_CLASSROOMS";
export const SET_CURRENT_CLASSROOM_ID = "SET_CURRENT_CLASSROOM_ID";
export const SET_CURRENT_CHAT_ID = "SET_CURRENT_CHAT_ID";
export const SET_CURRENT_CLASSROOMS = "SET_CURRENT_CLASSROOMS";
export const SET_CURRENT_CLASS_USERS = "SET_CURRENT_CLASS_USERS";
export const SET_STATUS = "SET_STATUS";
export const SET_ERROR = "SET_ERROR";

export const CLEAR_CLASSROOM = "CLEAR_CLASSROOM";

export const FETCH_CHATROOM_REQUEST = "FETCH_CHATROOM_REQUEST";
export const FETCH_CHATROOM_SUCCESS = "FETCH_CHATROOM_SUCCESS";
export const FETCH_CHATROOM_FAILURE = "FETCH_CHATROOM_FAILURE";
export const SET_CHATROOM_DATA = "SET_CHATROOM_DATA";

export const FETCH_CLASSROOM_STUDENT_REQUEST = "FETCH_CLASSROOM_STUDENT";
export const FETCH_CHATROOM_STUDENT_SUCCESS = "FETCH_CHATROOM_STUDENT_SUCCESS";
export const FETCH_CHATROOM_STUDENT_FAILURE = "FETCH_CHATROOM_STUDENT_FAILURE";
export const SET_CHATROOM_STUDENT_DATA = "SET_CHATROOM_STUDENT_DATA";

export const TOGGLE_CHAT_BUBBLE = "TOGGLE_CHAT_BUBBLE";
