import React from "react";
import Modal from "antd/es/modal";
import Button from "antd/es/button";
import Icon from "../shared/Icon";

export default class RemoveModal extends React.Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    console.log("RemoveModal 16 | section id to delete", this.props.uid);
    this.setState({
      visible: false,
    });
    this.props.confirmDelete();
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div>
        {/* <Button type="danger" onClick={this.showModal}>
          Delete
        </Button> */}
        <Icon
          onClick={this.showModal}
          name="trash-can-outline"
          width={24}
          height={24}
          style={{ paddingLeft: 5 }}
        />
        <Modal
          title="Deleting"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <p>Are you sure you want to delete this?</p>
        </Modal>
      </div>
    );
  }
}
