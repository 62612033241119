import React, { useState, useEffect } from "react";
import "./index.css";

export const Input = ({
  name,
  type,
  placeholder,
  value,
  onChange,
  index,
  category,
  required,
  defaultValue,
  id,
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(
      {
        target: {
          value: newValue,
          id,
          name,
        },
      },
      index,
      category,
      id,
      type
    );
  };

  return (
    <div className="lsiinput__container">
      <div className="inputfields__container">
        <div className="inputfield__label">
          {index + 1}. {name}{" "}
          <span
            style={{
              color: required ? "red" : "black",
              verticalAlign: "middle",
              fontSize: 12,
            }}
          >
            {required ? "(required)" : "(optional)"}
          </span>
        </div>
        <div className="custom-ant-input-container">
          <input
            className="lsi__input"
            name={name}
            required
            value={inputValue}
            placeholder={"Answer here..."}
            defaultValue={defaultValue}
            type={type}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Input);
