import React from "react";
import "./DebtSheet.css";

const DebtSheet = ({ sheetId }) => {
  const start = "https://docs.google.com/spreadsheets/d/";
  // console.log("sheetId in googlesheet", sheetId);
  var newSheetId = sheetId;
  const end = "/view?rm=minimal&embedded=true";
  const url = start + newSheetId + end;

  if (sheetId) {
    return (
      <iframe
        className="debt-sheet"
        title="sheet"
        height="100%"
        src={url}
        frameBorder="0"
      ></iframe>
    );
  } else {
    return (
      <div>
        <h3>Loading...</h3>
      </div>
    );
  }
};

export default DebtSheet;
