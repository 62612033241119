import React, { useEffect, useState } from "react";
import { createGeneralCalendarEvent } from "src/api/googleCalendar";
import { openNotification, errorNotification } from "src/utils/notifications";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import {
  handleDbCalendarEvents,
  handleGoogleCalendarEvents,
} from "src/store/calendarEvents/calendarEventsActions";
import {
  getClassroomDataById,
  getUsersWithClassId,
} from "src/store/classrooms/classroomsActions";
import { getClassModules } from "./utils";
import "src/components/HomePage/homeComponents/Dashboard/components/MentorContent/index.css";

const _EventModal = ({ firebase, calendarId, classId, name }) => {
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [open, setOpen] = useState(false);
  const [modules, setModules] = useState(false);
  const dispatch = useDispatch();

  const currentClassData = useSelector(
    (state) => state.classrooms.currentClassroomData
  );

  const currentClassroomUsers = useSelector(
    (state) => state.classrooms.currentClassroomUsers
  );

  console.log("class data on ClassEventsManager", currentClassData);
  const [loading, setLoading] = useState(false);

  const [eventDetails, setEventDetails] = useState({
    summary: "",
    description: "",
    location: "",
  });

  const { summary, description, location, module } = eventDetails;

  useEffect(() => {
    dispatch(getClassroomDataById(firebase, classId));
    dispatch(getUsersWithClassId(firebase, classId));
  }, []);

  useEffect(() => {
    if (currentClassData) {
      getLessonsForProgress(firebase, currentClassData);
    }
  }, [currentClassData]);

  const getLessonsForProgress = async (firebase, classroomData) => {
    const modules = await getClassModules(firebase, classroomData);
    setModules(modules);
  };

  const createEvent = async (data, usersList) => {
    try {
      let attendeesObject = {};
      usersList.forEach((element) => {
        attendeesObject[element.uid] = {
          attendance: false,
          reminderConfirmed: {
            // uid : {type: phone/mail, confirmed: true/false, token:id}
          },
        };
      });

      const eventObject = {
        title: data.summary,
        attendees: attendeesObject,
        classId: currentClassData.className,
        startDate: data.start.dateTime,
        endDate: data.end.dateTime,
        ...(eventDetails.module && { module: eventDetails.module }),
        ...(data.location && { location: data.location }),
        ...(data.description && { description: data.description }),
      };
      await firebase.events().push(eventObject);
    } catch (error) {
      console.log("EventManager Modal 85 | error creating event", error);
      errorNotification("Error!", "Error creating event in database!");
    }
  };

  const handleCreateEvent = async () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let dateString = new Date(`${date}T${time}`);
    let endDateString = new Date(`${date}T${endTime}`);
    console.log(
      "CreatePrivateEventModal.js 16 | datestring",
      dateString,
      endDateString
    );

    if (dateString > endDateString) {
      errorNotification("Error!", "End time cannot be before start time!");
      return;
    }

    setLoading(true);

    try {
      const event = {
        summary,
        location,
        description,
        start: {
          dateTime: dateString.toISOString(),
          timeZone: userTimeZone,
        },
        end: {
          dateTime: endDateString.toISOString(),
          timeZone: userTimeZone,
        },
      };
      await createEvent(event, currentClassroomUsers);
      dispatch(handleDbCalendarEvents(currentClassData.className, firebase));
      setLoading(false);
      setOpen(false);
    } catch (error) {
      errorNotification("Error!", "Error creating event in google!");
      console.log("EventModal 43 | error creating event", error);
    }
  };

  const detailsIncomplete = () => {
    return !summary || !date || !time;
  };
  if (!open) {
    return <Button title="Create Event" onClick={() => setOpen(true)} />;
  }

  return (
    <>
      <button onClick={() => setOpen(true)}>Create Event</button>

      <div className="createprivateevent__modal">
        <div className="createprivateevent__container">
          <div className="mentorprivateeventinfo__header">
            Create Event for <strong>{name}</strong>
          </div>
          <div className="mentorprivateeventbody__container">
            <div className="mentorprivateeventinfo__container">
              <div className="mentorprivateevent__title">Event Name</div>
              <div className="mentorprivateevent_input">
                <input
                  placeholder="Call with John Smith"
                  value={summary}
                  onChange={(e) => {
                    setEventDetails({
                      ...eventDetails,
                      summary: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="mentorprivateeventinfo__container">
              <div className="mentorprivateevent__title">Module associated</div>
              <div className="mentorprivateevent_input">
                <select
                  onChange={(e) => {
                    setEventDetails({
                      ...eventDetails,
                      module: e.target.value,
                    });
                  }}
                  name="cars"
                  id="cars"
                >
                  <option disabled selected value>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {modules &&
                    modules.map((e, index) => {
                      return (
                        <option key={index} value={e.uid}>
                          {e.name}
                        </option>
                      );
                    })}
                  <option value={undefined}>No module</option>
                </select>
              </div>
            </div>
            <div className="mentorprivateeventinfo__container">
              <div className="mentorprivateevent__title">Event Address</div>
              <div className="mentorprivateevent_input">
                <input
                  placeholder="123 Some Road"
                  value={location}
                  onChange={(e) => {
                    setEventDetails({
                      ...eventDetails,
                      location: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="mentorprivateeventinfo__container">
              <div className="mentorprivateevent__title">Event Description</div>
              <div className="mentorprivateevent_input">
                <input
                  placeholder="Be ready 10 minutes early..."
                  value={description}
                  onChange={(e) => {
                    setEventDetails({
                      ...eventDetails,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="mentorprivateeventinfo__container">
              <div className="mentorprivateevent__title">Day and Time</div>
              <div className="mentorprivateevent_inputdate">
                <input
                  type="date"
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
                <input
                  type="time"
                  className="mentorprivateevent_inputdate"
                  onChange={(e) => {
                    setEventDetails({
                      ...eventDetails,
                      dateTime: e.target.value,
                    });
                    setTime(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="mentorprivateeventinfo__container">
              <div className="mentorprivateevent__title">End Time</div>
              <div className="mentorprivateevent_inputdate">
                <input
                  type="time"
                  className="mentorprivateevent_inputdate"
                  onChange={(e) => {
                    console.log(
                      "CreatePrivateEventModal.js 64 | end time",
                      e.target.value
                    );
                    setEndTime(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="mentorprivateeventbuttons__container">
              <button
                className="mentorprivateeventbuttons__button"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                onClick={handleCreateEvent}
                disabled={detailsIncomplete()}
                className="mentorprivateeventbuttons__button"
              >
                {!loading ? "Create" : "Creating..."}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const EventModal = compose(
  withFirebase
  // withAuthorization(condition),
  // withAuthentication
)(_EventModal);
