import React from "react";
import { Doughnut } from "react-chartjs-2";
import "./DoughnutChart.css";
import Icon from "antd/es/icon";

const DoughnutChart = (props) => {
  let happyLevel;
  let totalHappy;

  if (props.happyLevel < 0) {
    happyLevel = 0;
    totalHappy = 30;
  } else if (props.happyLevel > 30) {
    happyLevel = 30;
    totalHappy = 0;
  } else {
    happyLevel = props.happyLevel;
    totalHappy = 30 - props.happyLevel;
  }

  // const message = (props.happy > 50) ? 'Winning' : 'Losing';
  const message = "Happiness: " + happyLevel + " of 30";
  const color =
    props.happyLevel >= 15
      ? ["rgba(55, 239, 55, 1)", "rgba(228, 232, 235, 1)"]
      : ["rgba(239, 55, 55, 1)", "rgba(228, 232, 235, 1)"];
  // const labelMessage = (props.happyLevel >= 50) ? 'Winning' : 'Losing'
  return (
    <div className="doughnut-container">
      <p className="info-happy-level">{message}</p>
      <Icon type="caret-down" style={{ fontSize: 15 }} />
      <Doughnut
        data={{
          datasets: [
            {
              labels: ["Happiness must be at least 15"],
              data: [happyLevel, totalHappy],
              backgroundColor: color,
            },
          ],
        }}
        options={{
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          // responsive: true,
          maintainAspectRatio: false,
        }}
      />
      {props.choiceButtonsVisible ? (
        <>
          {props.happyLevel >= 15 ? null : (
            <p className="happy-warning">Too Sad! Get to 15 happy points!</p>
          )}
        </>
      ) : null}
    </div>
  );
};

export default DoughnutChart;
