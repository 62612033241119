import React from "react";
import Icon from "../shared/Icon";

const CourseTasksInfoHeader = () => {
  return (
    <div className="coursetasks__info">
      <div className="coursetaskinfo__icon">
        <Icon width={24} height={24} name="done_all" />
        Done Due{" "}
      </div>
      <div className="coursetaskinfo__icon">
        <Icon width={24} height={24} name="info" />
        This Week{" "}
      </div>
      <div className="coursetaskinfo__icon">
        <Icon width={24} height={24} name="history_toggle_off" />
        In Progress{" "}
      </div>
      <div className="coursetaskinfo__icon">
        <Icon width={24} height={24} name="not_done" />
        Not Done
      </div>
    </div>
  );
};

export default CourseTasksInfoHeader;
