import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../Firebase";
import SwitchItemOrderButtons from "../shared/SwitchItemOrderButtons";

import "./index.css";

const Video = ({
  index,
  removeContentAtIndex,
  editSection,
  item,
  _moveSectionContentDown,
  _moveSectionContentUp,
  firebase,
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const manageVideo = (e, index) => {
    console.log(
      "EditSectionForm 37 | handling video upload",
      e.target.files[0]
    );

    const file = e.target.files[0];
    setUploading(true);
    firebase
      .uploadFile(`files/${file.name}`)
      .put(file)
      .on(
        "state_changed",
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setUploadStatus(progress);
        },
        (error) => {
          console.log("Video 42 | error uploading");
        },
        () => {
          console.log("Video 48 | success upload");
          firebase
            .uploadFile(`files/${file.name}`)
            .getDownloadURL()
            .then((url) => {
              setUploading(false);
              setUploadSuccess(true);
              console.log("EditSectionForm 48 | url obtained");
              editSection(e, index, "file", null, url);
            })
            .catch((error) => {
              console.log("EditSectionForm 47 | upload error", "ERROR", error);
            });
        }
      );
  };

  return (
    <div className="image-video-edit">
      <div className="editcontent__title">Add Video</div>
      <div className="imagevideoeditinput__container">
        <input
          placeholder="Enter video url here..."
          className="imageinput__text"
          value={item.url}
          onChange={(e) => editSection(e, index)}
        />
        {uploading ? (
          <div>Uploading: {Math.floor(uploadStatus)} %</div>
        ) : (
          <input type="file" onChange={(e) => manageVideo(e, index)} />
        )}
        {uploadSuccess ? <div>Upload Successful!</div> : null}
      </div>

      <div className="movedeletecontent__container">
        <SwitchItemOrderButtons
          index={index}
          _moveSectionContentDown={_moveSectionContentDown}
          _moveSectionContentUp={_moveSectionContentUp}
        />
        <div
          className="deletebutton__button"
          onClick={(e) => removeContentAtIndex(e, index)}
        >
          X
        </div>
      </div>
    </div>
  );
};

export default withFirebase(Video);
