export function getBrowser() {
  const userAgent = navigator.userAgent;

  // If the user agent includes 'Chrome' but not 'Edge', we can assume the browser is Google Chrome
  if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edg/") === -1) {
    return "Google Chrome";
  }
  // Add more conditions for other browsers as needed
  else if (userAgent.indexOf("Firefox") > -1) {
    return "Mozilla Firefox";
  }
  // ... other browsers

  // If nothing matches, return an unknown string
  return "Unknown";
}
