import React, { useState, useEffect } from "react";
import "./index.css";
import { Carousel } from "react-responsive-carousel";
import { Situation } from "./components/Situation";
import { revenueNotification } from "./notifications/revenueNotification";
import { failureNotification } from "./notifications/failureNotification";
import { winNotification } from "./notifications/winNotification";
import { CarouselChoices } from "./components/CarouselChoices";

const DecisionTreeGame = ({ treeData, setTreeWon }) => {
  useEffect(() => {
    // console.log("DecisionTreeGame 52 | decision tree rerendered");
  }, []);
  const initialBank = treeData.initialBank;
  const goal = treeData.goal;
  // console.log(treeData.tree);
  // console.log(treeData.childrenSituations);

  const [totalProgress, setTotalProgress] = useState(0);
  const [currentNode, setCurrentNode] = useState(treeData.tree);
  const bankGoal = initialBank ? initialBank : 500;
  const [bank, setBank] = useState(bankGoal);
  const [gameWon, setGameWon] = useState(false);

  // TODO: retrieve coder pad with following changes
  // 2.) User sees revenue being added to bank - TEST
  // 3.) Add pop up to tell that user added this much revenue per action - TEST
  // 4.) If tree ends and youre under 90%, you need to start over - TEST
  // 6.) Wish list metrics:
  // 7.) make notifications replaceable
  // a. Times tried
  // b. Time spent on each step
  // c. Number of steps taken
  // 8.) trigger next button

  const [gameLost, setGameLost] = useState(false);
  const [attempts, setAttempts] = useState(1);
  const [numSteps, setNumSteps] = useState(0);

  const takeAction = (situation, revenue, progress, cost) => {
    console.log("DecisionTreeGame 41 | taking action");
    setNumSteps(numSteps + 1);
    setBank(Number(bank) - Number(cost) + Number(revenue));
    console.log("DecisionTreeGame 78 | total progress before", totalProgress);
    const newProgress = Number(totalProgress) + Number(progress);
    setTotalProgress(newProgress);
    console.log("DecisionTreeGame 78 | total progress after", totalProgress);
    setCurrentNode(situation);
    checkLoss(situation, newProgress);
  };

  const resetGame = () => {
    setCurrentNode(treeData.tree);
    setGameLost(false);
    setBank(500);
    setTotalProgress(0);
    setAttempts(attempts + 1);
  };

  useEffect(() => {
    console.log("DecisionTreeGame 66 | re-rendering");
  }, [currentNode]);

  const checkLoss = (situation, newProgress, revenue) => {
    // if you run out of money but there are still steps to go through
    if (
      situation.childrenSituations &&
      situation.childrenSituations.every((s) => s.cost > bank)
    ) {
      failureNotification();
      setGameLost(true);
    }
    // you still have money, but there are no more options
    else if (!situation.childrenSituations) {
      if (newProgress >= 90 && bank >= 0) {
        winNotification(attempts, numSteps);
        // this is the condition where the next button should pop up
        console.log("DecisionTreeGame 108 | game won, unlock next button");
        setGameWon(true);
        setTreeWon();
      } else {
        failureNotification();
        setGameLost(true);
      }
    } else if (revenue > 0) {
      revenueNotification(revenue);
    }
  };

  const handleChange = () => {
    console.log("DecisionTreeGame 143 | handling swipe change");
  };

  if (gameWon) {
    return (
      <div>
        <h3 className="btn btn-success">Game Won</h3>
      </div>
    );
  }

  return (
    <div className="decision-tree-container">
      {currentNode.resultText && (
        <h5 className="situation-description">{currentNode.resultText}</h5>
      )}
      <div className="game-variables">
        <p>Bank: ${bank}</p>
        <p>
          Progress:
          {totalProgress} %
        </p>
      </div>
      <CarouselChoices
        handleChange={handleChange}
        decisionIndex={0}
        currentNode={currentNode}
        takeAction={takeAction}
        bank={bank}
      />
      <div className="game-lost-container">
        {/* {gameLost ? <GameLost /> : null} */}
        {gameLost ? (
          <button className="resetgame__button" onClick={() => resetGame()}>
            Reset Game{" "}
          </button>
        ) : null}
      </div>
    </div>
  );
};

const GameLost = () => <h5>Game Lost</h5>;

export default DecisionTreeGame;
