export let stocks = [
  // {
  //     name: 'Apple',
  //     ticker: 'AAPL',
  //     prices: [],
  //     beta: 1.3,
  //     range: 'C4',
  // },
  // {
  //     name: 'Microsoft',
  //     ticker: 'MSFT',
  //     prices: [],
  //     beta: 1.6,
  //     range: 'C5'

  // },
  // {
  //     name: 'Dow Jones Index',
  //     ticker: 'DIA',
  //     prices: [],
  //     beta: 1
  // },
  {
    name: "Starbucks",
    ticker: "SBUX",
    prices: [],
    beta: 1
  },
  {
    name: "McDonald's",
    ticker: "MCD",
    prices: [],
    beta: 1
  }
];

export let simulationDates = [
  "1998-02-27",
  "1998-03-31",
  "1998-04-30",
  "1998-05-29",
  "1998-06-30",
  "1998-07-31",
  "1998-08-31",
  "1998-09-30",
  "1998-10-30",
  "1998-11-30",
  "1998-12-31",
  "1999-01-29",
  "1999-02-26",
  "1999-03-31",
  "1999-04-30",
  "1999-05-28",
  "1999-06-30",
  "1999-07-30",
  "1999-08-31",
  "1999-09-30",
  "1999-10-29",
  "1999-11-30",
  "1999-12-31",
  "2000-01-31",
  "2000-02-29",
  "2000-03-31",
  "2000-04-28",
  "2000-05-31",
  "2000-06-30",
  "2000-07-31",
  "2000-08-31",
  "2000-09-29",
  "2000-10-31",
  "2000-11-30",
  "2000-12-29",
  "2001-01-31",
  "2001-02-28",
  "2001-03-30",
  "2001-04-30",
  "2001-05-31",
  "2001-06-29",
  "2001-07-31",
  "2001-08-31",
  "2001-09-28",
  "2001-10-31",
  "2001-11-30",
  "2001-12-31",
  "2002-01-31",
  "2002-02-28",
  "2002-03-28",
  "2002-04-30",
  "2002-05-31",
  "2002-06-28",
  "2002-07-31",
  "2002-08-30",
  "2002-09-30",
  "2002-10-31",
  "2002-11-29",
  "2002-12-31",
  "2003-01-31",
  "2003-02-28",
  "2003-03-31",
  "2003-04-30",
  "2003-05-30",
  "2003-06-30",
  "2003-07-31",
  "2003-08-29",
  "2003-09-30",
  "2003-10-31",
  "2003-11-28",
  "2003-12-31",
  "2004-01-30",
  "2004-02-27",
  "2004-03-31",
  "2004-04-30",
  "2004-05-28",
  "2004-06-30",
  "2004-07-30",
  "2004-08-31",
  "2004-09-30",
  "2004-10-29",
  "2004-11-30",
  "2004-12-31",
  "2005-01-31",
  "2005-02-28",
  "2005-03-31",
  "2005-04-29",
  "2005-05-31",
  "2005-06-30",
  "2005-07-29",
  "2005-08-31",
  "2005-09-30",
  "2005-10-31",
  "2005-11-30",
  "2005-12-30",
  "2006-01-31",
  "2006-02-28",
  "2006-03-31",
  "2006-04-28",
  "2006-05-31",
  "2006-06-30",
  "2006-07-31",
  "2006-08-31",
  "2006-09-29",
  "2006-10-31",
  "2006-11-30",
  "2006-12-29",
  "2007-01-31",
  "2007-02-28",
  "2007-03-30",
  "2007-04-30",
  "2007-05-31",
  "2007-06-29",
  "2007-07-31",
  "2007-08-31",
  "2007-09-28",
  "2007-10-31",
  "2007-11-30",
  "2007-12-31",
  "2008-01-31",
  "2008-02-29",
  "2008-03-31",
  "2008-04-30",
  "2008-05-30",
  "2008-06-30",
  "2008-07-31",
  "2008-08-29",
  "2008-09-30",
  "2008-10-31",
  "2008-11-28",
  "2008-12-31",
  "2009-01-30",
  "2009-02-27",
  "2009-03-31",
  "2009-04-30",
  "2009-05-29",
  "2009-06-30",
  "2009-07-31",
  "2009-08-31",
  "2009-09-30",
  "2009-10-30",
  "2009-11-30",
  "2009-12-31",
  "2010-01-29",
  "2010-02-26",
  "2010-03-31",
  "2010-04-30",
  "2010-05-28",
  "2010-06-30",
  "2010-07-30",
  "2010-08-31",
  "2010-09-30",
  "2010-10-29",
  "2010-11-30",
  "2010-12-31",
  "2011-01-31",
  "2011-02-28",
  "2011-03-31",
  "2011-04-29",
  "2011-05-31",
  "2011-06-30",
  "2011-07-29",
  "2011-08-31",
  "2011-09-30",
  "2011-10-31",
  "2011-11-30",
  "2011-12-30",
  "2012-01-31",
  "2012-02-29",
  "2012-03-30",
  "2012-04-30",
  "2012-05-31",
  "2012-06-29",
  "2012-07-31",
  "2012-08-31",
  "2012-09-28",
  "2012-10-31",
  "2012-11-30",
  "2012-12-31",
  "2013-01-31",
  "2013-02-28",
  "2013-03-28",
  "2013-04-30",
  "2013-05-31",
  "2013-06-28",
  "2013-07-31",
  "2013-08-30",
  "2013-09-30",
  "2013-10-31",
  "2013-11-29",
  "2013-12-31",
  "2014-01-31",
  "2014-02-28",
  "2014-03-31",
  "2014-04-30",
  "2014-05-30",
  "2014-06-30",
  "2014-07-31",
  "2014-08-29",
  "2014-09-30",
  "2014-10-31",
  "2014-11-28",
  "2014-12-31",
  "2015-01-30",
  "2015-02-27",
  "2015-03-31",
  "2015-04-30",
  "2015-05-29",
  "2015-06-30",
  "2015-07-31",
  "2015-08-31",
  "2015-09-30",
  "2015-10-30",
  "2015-11-30",
  "2015-12-31",
  "2016-01-29",
  "2016-02-29",
  "2016-03-31",
  "2016-04-29",
  "2016-05-31",
  "2016-06-30",
  "2016-07-29",
  "2016-08-31",
  "2016-09-30",
  "2016-10-31",
  "2016-11-30",
  "2016-12-30",
  "2017-01-31",
  "2017-02-28",
  "2017-03-31",
  "2017-04-28",
  "2017-05-31",
  "2017-06-30",
  "2017-07-31",
  "2017-08-31",
  "2017-09-29",
  "2017-10-31",
  "2017-11-30",
  "2017-12-29",
  "2018-01-31",
  "2018-02-28",
  "2018-03-29",
  "2018-04-30",
  "2018-05-31",
  "2018-06-29",
  "2018-07-31",
  "2018-08-31",
  "2018-09-28",
  "2018-10-31",
  "2018-11-30",
  "2018-12-31",
  "2019-01-31",
  "2019-02-28"
];

export let stocksLocal = [
  // {
  //     name: 'Apple',
  //     ticker: 'AAPL',
  //     prices: [
  //         0, 0, 0, 0, 0, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 2, 3, 3, 3, 3, 4, 3, 2, 3, 3, 3, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 0,0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 4, 4, 4, 5, 5, 4, 4, 4, 5, 5, 6, 7, 8, 9, 9, 8, 7, 8, 7, 7, 8, 8, 9, 10, 11, 10, 10, 10, 11, 12, 15, 15, 16, 17, 19, 23, 22, 24, 17, 15, 18, 21, 23, 21, 19, 21, 14, 13, 11, 10, 11, 11, 13, 15, 17, 17, 20, 21, 23, 23, 25, 26, 24, 25, 29, 32, 32, 31, 32, 30, 35, 37, 39, 40, 42, 44, 43, 43, 43, 42, 49, 48, 47, 50, 48, 50, 57, 68, 75, 73, 72, 73, 76, 83, 84, 75, 74, 67, 57, 56, 56, 56, 57, 50, 58, 62, 61, 67, 72, 72, 65, 68, 70, 77, 83, 85, 87, 94, 93, 99, 110, 102, 108, 119, 116, 116, 121, 117, 113, 106, 103, 112, 111, 99, 91, 91, 103, 89, 95, 91, 99, 101, 108, 109, 106, 111, 117, 132, 139, 139, 148, 140, 144, 160, 150, 165, 168, 166, 164, 175, 165, 162, 184,183, 188, 225, 223, 217, 177, 157, 165, 173, 175
  //     ],
  //     beta: 1.3,
  //     range: 'C4'
  // },

  {
    name: "Dow Jones",
    ticker: "DIA",
    prices: [
      54,
      55,
      57,
      56,
      57,
      56,
      47,
      49,
      54,
      58,
      58,
      59,
      59,
      62,
      69,
      67,
      70,
      68,
      70,
      67,
      69,
      70,
      74,
      71,
      66,
      71,
      70,
      68,
      68,
      68,
      73,
      69,
      71,
      68,
      70,
      71,
      69,
      65,
      70,
      72,
      69,
      69,
      66,
      58,
      60,
      65,
      66,
      66,
      67,
      69,
      66,
      66,
      62,
      58,
      58,
      51,
      56,
      60,
      57,
      55,
      54,
      54,
      58,
      61,
      62,
      63,
      65,
      64,
      68,
      68,
      72,
      73,
      74,
      72,
      71,
      71,
      73,
      71,
      71,
      71,
      71,
      74,
      76,
      74,
      76,
      75,
      72,
      75,
      73,
      76,
      75,
      76,
      75,
      78,
      77,
      78,
      80,
      81,
      83,
      82,
      82,
      82,
      83,
      86,
      89,
      90,
      92,
      93,
      91,
      92,
      97,
      102,
      100,
      99,
      100,
      104,
      105,
      101,
      100,
      95,
      93,
      93,
      98,
      96,
      87,
      87,
      89,
      83,
      72,
      68,
      68,
      62,
      55,
      59,
      64,
      67,
      67,
      72,
      75,
      77,
      77,
      83,
      83,
      81,
      83,
      87,
      89,
      82,
      79,
      85,
      82,
      88,
      91,
      90,
      95,
      98,
      101,
      102,
      106,
      104,
      103,
      101,
      97,
      91,
      100,
      101,
      103,
      107,
      110,
      112,
      112,
      106,
      110,
      111,
      112,
      115,
      113,
      112,
      113,
      120,
      122,
      127,
      129,
      132,
      130,
      136,
      130,
      133,
      137,
      142,
      147,
      139,
      145,
      147,
      148,
      149,
      151,
      148,
      154,
      153,
      157,
      161,
      161,
      156,
      165,
      162,
      163,
      165,
      161,
      162,
      152,
      150,
      163,
      164,
      161,
      153,
      154,
      165,
      166,
      167,
      168,
      173,
      174,
      173,
      172,
      182,
      188,
      189,
      199,
      198,
      200,
      202,
      205,
      211,
      212,
      217,
      226,
      236,
      241,
      255,
      244,
      236,
      237,
      240,
      239,
      250,
      256,
      262,
      249,
      253,
      232,
      249,
      259,
      258
    ],
    beta: 1
  },
  {
    name: "S&P 500",
    ticker: "SPY",
    prices: [
      71,
      75,
      76,
      74,
      77,
      76,
      66,
      70,
      75,
      80,
      85,
      88,
      85,
      89,
      92,
      90,
      95,
      92,
      92,
      89,
      95,
      97,
      102,
      97,
      96,
      105,
      101,
      100,
      102,
      100,
      107,
      101,
      100,
      93,
      92,
      96,
      87,
      82,
      88,
      89,
      87,
      86,
      81,
      74,
      75,
      81,
      81,
      81,
      79,
      82,
      77,
      77,
      71,
      65,
      66,
      59,
      64,
      68,
      64,
      62,
      61,
      61,
      67,
      70,
      71,
      72,
      74,
      73,
      77,
      78,
      82,
      83,
      85,
      83,
      82,
      83,
      85,
      82,
      82,
      83,
      84,
      88,
      91,
      89,
      90,
      89,
      87,
      90,
      90,
      94,
      93,
      93,
      91,
      95,
      95,
      97,
      98,
      100,
      101,
      98,
      98,
      98,
      101,
      103,
      107,
      109,
      110,
      112,
      110,
      111,
      116,
      120,
      118,
      114,
      116,
      120,
      122,
      117,
      116,
      109,
      106,
      105,
      110,
      112,
      102,
      101,
      103,
      93,
      78,
      72,
      73,
      67,
      60,
      65,
      71,
      75,
      75,
      81,
      84,
      87,
      85,
      91,
      92,
      89,
      92,
      97,
      99,
      91,
      86,
      92,
      88,
      96,
      100,
      100,
      106,
      109,
      113,
      113,
      116,
      115,
      113,
      111,
      104,
      97,
      108,
      107,
      108,
      114,
      118,
      122,
      121,
      114,
      119,
      120,
      123,
      126,
      124,
      125,
      126,
      132,
      134,
      139,
      142,
      145,
      143,
      151,
      146,
      151,
      158,
      162,
      167,
      161,
      168,
      170,
      171,
      175,
      178,
      176,
      183,
      180,
      185,
      190,
      189,
      184,
      194,
      191,
      193,
      195,
      191,
      196,
      184,
      179,
      194,
      195,
      192,
      182,
      182,
      194,
      195,
      198,
      199,
      206,
      206,
      206,
      203,
      210,
      215,
      219,
      227,
      227,
      230,
      233,
      234,
      239,
      240,
      245,
      251,
      258,
      261,
      276,
      266,
      259,
      260,
      266,
      268,
      278,
      287,
      288,
      269,
      274,
      249,
      269,
      278,
      273
    ],
    beta: 1
  },
  {
    name: "Gold",
    ticker: "GOLD",
    prices: [
      15,
      16,
      17,
      15,
      15,
      12,
      10,
      15,
      16,
      15,
      15,
      15,
      13,
      13,
      15,
      13,
      15,
      14,
      15,
      17,
      14,
      14,
      14,
      13,
      13,
      12,
      13,
      14,
      14,
      12,
      12,
      12,
      10,
      12,
      13,
      12,
      13,
      11,
      13,
      13,
      12,
      12,
      13,
      14,
      12,
      12,
      13,
      14,
      14,
      15,
      16,
      18,
      15,
      12,
      13,
      12,
      12,
      12,
      12,
      13,
      13,
      12,
      12,
      14,
      15,
      14,
      16,
      15,
      16,
      18,
      19,
      16,
      17,
      20,
      16,
      17,
      16,
      16,
      16,
      17,
      19,
      20,
      20,
      18,
      21,
      20,
      18,
      19,
      21,
      20,
      22,
      24,
      21,
      22,
      23,
      27,
      23,
      23,
      26,
      26,
      25,
      26,
      28,
      26,
      26,
      27,
      26,
      25,
      25,
      24,
      24,
      25,
      25,
      28,
      28,
      35,
      38,
      35,
      36,
      44,
      45,
      37,
      33,
      35,
      39,
      37,
      30,
      32,
      19,
      26,
      32,
      33,
      26,
      28,
      25,
      33,
      29,
      30,
      30,
      33,
      31,
      38,
      35,
      31,
      33,
      34,
      38,
      37,
      40,
      36,
      42,
      41,
      43,
      46,
      47,
      42,
      47,
      46,
      46,
      43,
      40,
      43,
      46,
      42,
      44,
      48,
      41,
      44,
      43,
      39,
      36,
      35,
      34,
      30,
      35,
      38,
      37,
      32,
      32,
      29,
      28,
      27,
      18,
      19,
      14,
      15,
      18,
      17,
      18,
      15,
      16,
      18,
      19,
      16,
      16,
      15,
      17,
      17,
      17,
      14,
      11,
      11,
      10,
      12,
      12,
      10,
      12,
      11,
      10,
      6,
      6,
      6,
      7,
      7,
      7,
      9,
      13,
      13,
      18,
      16,
      20,
      21,
      16,
      17,
      17,
      14,
      15,
      18,
      18,
      18,
      16,
      16,
      15,
      16,
      17,
      15,
      14,
      13,
      14,
      14,
      11,
      12,
      13,
      13,
      12,
      11,
      10,
      10,
      12,
      12,
      13,
      13,
      12,
      13
    ],
    beta: 1.6,
    range: "C5"
  },

  {
    name: "Microsoft",
    ticker: "MSFT",
    prices: [
      13,
      14,
      14,
      13,
      17,
      17,
      15,
      17,
      17,
      19,
      22,
      28,
      24,
      29,
      26,
      26,
      29,
      27,
      30,
      29,
      30,
      29,
      38,
      31,
      29,
      34,
      22,
      20,
      26,
      22,
      22,
      19,
      22,
      18,
      14,
      19,
      19,
      17,
      22,
      22,
      23,
      21,
      18,
      16,
      18,
      20,
      21,
      20,
      19,
      19,
      17,
      16,
      17,
      15,
      15,
      14,
      17,
      18,
      16,
      15,
      15,
      15,
      16,
      16,
      16,
      17,
      17,
      18,
      17,
      16,
      17,
      18,
      17,
      16,
      17,
      17,
      18,
      18,
      17,
      18,
      18,
      19,
      19,
      19,
      18,
      17,
      18,
      19,
      18,
      18,
      20,
      19,
      19,
      20,
      19,
      20,
      20,
      20,
      17,
      16,
      17,
      17,
      19,
      20,
      21,
      22,
      22,
      23,
      21,
      21,
      22,
      23,
      22,
      21,
      21,
      22,
      27,
      25,
      27,
      24,
      20,
      21,
      21,
      21,
      21,
      19,
      21,
      20,
      17,
      15,
      15,
      13,
      12,
      14,
      15,
      16,
      18,
      18,
      19,
      20,
      21,
      23,
      24,
      22,
      22,
      23,
      24,
      20,
      18,
      20,
      18,
      19,
      21,
      20,
      22,
      22,
      21,
      20,
      21,
      20,
      21,
      22,
      22,
      20,
      22,
      21,
      21,
      24,
      26,
      27,
      26,
      24,
      25,
      24,
      26,
      25,
      24,
      22,
      22,
      23,
      24,
      24,
      28,
      30,
      30,
      27,
      29,
      29,
      31,
      33,
      33,
      33,
      34,
      36,
      35,
      36,
      37,
      38,
      40,
      41,
      42,
      43,
      42,
      36,
      40,
      37,
      44,
      43,
      40,
      42,
      40,
      40,
      48,
      50,
      51,
      51,
      47,
      51,
      46,
      50,
      48,
      53,
      54,
      54,
      56,
      57,
      59,
      61,
      61,
      63,
      65,
      67,
      66,
      70,
      72,
      72,
      81,
      82,
      83,
      92,
      92,
      89,
      91,
      97,
      97,
      104,
      111,
      113,
      105,
      110,
      101,
      103,
      112,
      111
    ],
    beta: 1.6,
    range: "C5"
  },
  {
    name: "Starbucks",
    ticker: "SBUX",
    prices: [
      2,
      2,
      2,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      3,
      2,
      2,
      3,
      3,
      3,
      4,
      2,
      2,
      2,
      2,
      2,
      2,
      3,
      3,
      4,
      3,
      3,
      4,
      4,
      3,
      4,
      4,
      4,
      4,
      5,
      5,
      4,
      4,
      4,
      4,
      3,
      3,
      3,
      3,
      3,
      4,
      5,
      4,
      4,
      4,
      5,
      5,
      4,
      4,
      4,
      5,
      4,
      4,
      4,
      5,
      5,
      5,
      5,
      5,
      5,
      6,
      6,
      6,
      6,
      7,
      7,
      8,
      8,
      8,
      8,
      9,
      10,
      9,
      9,
      11,
      12,
      13,
      11,
      11,
      11,
      10,
      11,
      11,
      11,
      10,
      10,
      12,
      13,
      12,
      13,
      15,
      16,
      16,
      15,
      16,
      14,
      13,
      14,
      16,
      15,
      15,
      15,
      13,
      13,
      13,
      12,
      11,
      11,
      11,
      11,
      11,
      10,
      8,
      8,
      7,
      7,
      7,
      7,
      6,
      6,
      6,
      6,
      5,
      3,
      4,
      4,
      3,
      4,
      6,
      6,
      6,
      7,
      8,
      8,
      8,
      9,
      9,
      9,
      9,
      10,
      11,
      11,
      10,
      10,
      10,
      11,
      12,
      13,
      14,
      13,
      14,
      16,
      15,
      16,
      17,
      17,
      17,
      16,
      18,
      19,
      20,
      21,
      21,
      24,
      25,
      24,
      23,
      20,
      22,
      22,
      20,
      23,
      24,
      25,
      24,
      25,
      27,
      28,
      29,
      32,
      32,
      35,
      36,
      37,
      35,
      32,
      32,
      33,
      32,
      33,
      35,
      35,
      35,
      34,
      34,
      37,
      38,
      40,
      43,
      44,
      46,
      48,
      50,
      54,
      51,
      53,
      58,
      57,
      56,
      57,
      54,
      56,
      53,
      51,
      54,
      54,
      53,
      51,
      50,
      55,
      52,
      52,
      54,
      55,
      57,
      61,
      56,
      51,
      53,
      51,
      53,
      56,
      55,
      55,
      55,
      56,
      56,
      55,
      48,
      51,
      52,
      56,
      57,
      66,
      64,
      67,
      70,
      71
    ],
    beta: 1.6,
    range: "C5"
  },
  {
    name: "McDonalds",
    ticker: "MCD",
    prices: [
      16,
      18,
      18,
      19,
      20,
      20,
      17,
      18,
      20,
      21,
      23,
      23,
      25,
      27,
      25,
      23,
      25,
      25,
      25,
      26,
      25,
      27,
      24,
      22,
      19,
      22,
      23,
      22,
      20,
      19,
      18,
      18,
      19,
      19,
      21,
      18,
      18,
      16,
      17,
      18,
      16,
      18,
      18,
      16,
      16,
      16,
      16,
      17,
      16,
      17,
      17,
      18,
      17,
      15,
      15,
      11,
      11,
      11,
      10,
      9,
      8,
      9,
      10,
      11,
      14,
      14,
      14,
      15,
      16,
      16,
      16,
      16,
      18,
      18,
      17,
      17,
      16,
      17,
      17,
      18,
      18,
      20,
      21,
      21,
      21,
      20,
      19,
      20,
      18,
      20,
      21,
      22,
      20,
      22,
      22,
      23,
      23,
      23,
      23,
      22,
      22,
      23,
      24,
      26,
      28,
      29,
      30,
      30,
      30,
      31,
      33,
      34,
      35,
      33,
      34,
      37,
      41,
      41,
      41,
      38,
      38,
      39,
      42,
      42,
      40,
      42,
      44,
      44,
      41,
      42,
      45,
      42,
      38,
      40,
      39,
      43,
      42,
      40,
      42,
      42,
      43,
      47,
      47,
      47,
      48,
      50,
      53,
      51,
      50,
      53,
      56,
      57,
      60,
      61,
      59,
      57,
      59,
      59,
      61,
      64,
      66,
      68,
      72,
      70,
      74,
      76,
      80,
      79,
      80,
      79,
      78,
      72,
      72,
      72,
      73,
      75,
      71,
      72,
      73,
      79,
      80,
      83,
      85,
      81,
      83,
      82,
      80,
      81,
      82,
      83,
      83,
      80,
      82,
      84,
      87,
      88,
      87,
      82,
      82,
      83,
      82,
      85,
      83,
      81,
      88,
      87,
      86,
      86,
      85,
      90,
      86,
      89,
      102,
      104,
      108,
      113,
      108,
      116,
      116,
      112,
      111,
      109,
      108,
      108,
      105,
      112,
      115,
      115,
      121,
      123,
      133,
      143,
      146,
      148,
      154,
      150,
      160,
      166,
      166,
      165,
      153,
      152,
      163,
      156,
      153,
      154,
      160,
      165,
      174,
      187,
      176,
      177,
      183,
      185
    ],
    beta: 1.6,
    range: "C5"
  },
  {
    name: "General Electric",
    ticker: "GE",
    prices: [
      13,
      14,
      14,
      14,
      15,
      15,
      13,
      13,
      14,
      15,
      17,
      17,
      17,
      18,
      18,
      17,
      19,
      18,
      19,
      20,
      23,
      22,
      26,
      23,
      22,
      26,
      27,
      27,
      27,
      26,
      30,
      30,
      28,
      25,
      25,
      24,
      24,
      22,
      25,
      25,
      25,
      22,
      21,
      19,
      19,
      20,
      21,
      19,
      20,
      20,
      16,
      16,
      15,
      17,
      16,
      13,
      13,
      14,
      13,
      12,
      13,
      14,
      16,
      15,
      15,
      15,
      16,
      16,
      16,
      15,
      17,
      18,
      18,
      17,
      16,
      17,
      18,
      18,
      18,
      19,
      19,
      20,
      20,
      20,
      20,
      20,
      20,
      21,
      20,
      20,
      19,
      19,
      19,
      20,
      20,
      19,
      19,
      20,
      20,
      20,
      19,
      19,
      20,
      21,
      21,
      21,
      22,
      21,
      21,
      21,
      22,
      23,
      23,
      23,
      23,
      25,
      25,
      23,
      23,
      22,
      20,
      23,
      20,
      19,
      17,
      18,
      17,
      16,
      12,
      11,
      10,
      8,
      5,
      6,
      8,
      9,
      8,
      9,
      9,
      11,
      9,
      11,
      10,
      11,
      11,
      12,
      13,
      11,
      10,
      11,
      10,
      11,
      11,
      11,
      13,
      14,
      15,
      14,
      14,
      14,
      13,
      12,
      11,
      11,
      12,
      11,
      13,
      13,
      14,
      14,
      14,
      14,
      15,
      15,
      15,
      17,
      15,
      15,
      16,
      17,
      17,
      17,
      17,
      17,
      17,
      18,
      17,
      18,
      20,
      20,
      22,
      19,
      20,
      20,
      21,
      21,
      21,
      20,
      20,
      20,
      20,
      21,
      20,
      19,
      21,
      20,
      22,
      22,
      22,
      21,
      20,
      21,
      24,
      25,
      26,
      24,
      24,
      27,
      26,
      25,
      26,
      26,
      26,
      25,
      25,
      26,
      27,
      25,
      26,
      26,
      25,
      24,
      23,
      22,
      21,
      21,
      18,
      16,
      15,
      14,
      12,
      12,
      12,
      12,
      12,
      12,
      11,
      10,
      9,
      6,
      7,
      9,
      10,
      9
    ],
    beta: 1
  },
  {
    name: "Gamestop",
    ticker: "GME",
    prices: [
      7,
      6,
      8,
      7,
      6,
      7,
      7,
      6,
      6,
      3,
      2,
      3,
      4,
      4,
      4,
      4,
      4,
      5,
      5,
      5,
      5,
      5,
      5,
      6,
      6,
      6,
      5,
      5,
      5,
      5,
      6,
      6,
      7,
      7,
      6,
      6,
      7,
      8,
      10,
      11,
      11,
      11,
      10,
      12,
      11,
      11,
      14,
      13,
      16,
      16,
      14,
      14,
      14,
      15,
      16,
      17,
      19,
      19,
      18,
      18,
      22,
      23,
      25,
      27,
      28,
      34,
      39,
      41,
      39,
      43,
      35,
      29,
      35,
      38,
      34,
      28,
      28,
      30,
      23,
      19,
      15,
      15,
      17,
      18,
      19,
      20,
      17,
      15,
      15,
      16,
      18,
      16,
      16,
      15,
      13,
      11,
      15,
      16,
      15,
      13,
      13,
      12,
      13,
      13,
      13,
      15,
      14,
      13,
      15,
      17,
      19,
      18,
      16,
      16,
      16,
      17,
      16,
      16,
      16,
      15,
      15,
      15,
      13,
      12,
      11,
      13,
      15,
      16,
      18,
      18,
      16,
      18,
      20,
      25,
      24,
      30,
      36,
      37,
      36,
      40,
      35,
      36,
      26,
      27,
      30,
      29,
      28,
      30,
      31,
      32,
      31,
      32,
      29,
      26,
      27,
      28,
      29,
      29,
      33,
      33,
      35,
      33,
      32,
      36,
      27,
      22,
      20,
      24,
      25,
      26,
      23,
      21,
      25,
      23,
      22,
      19,
      20,
      21,
      20,
      20,
      19,
      19,
      18,
      18,
      18,
      16,
      18,
      16,
      16,
      16,
      15,
      14,
      11,
      12,
      12,
      13,
      13,
      12,
      14,
      14,
      13,
      12,
      11,
      11,
      11
    ],
    beta: 1
  }
];

export const shortest = () => {
  //returns shortest stock
  let shortest;
  stocksLocal.forEach((stock, index) => {
    stocksLocal.forEach(secondStock => {
      if (stock.prices.length < secondStock.prices.length) {
        shortest = stock;
      }
    });
  });
  return shortest;
};
