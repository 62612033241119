import React from "react";

export const AddSectionContentButtons = ({
  addSectionContent,
  currentSectionNumber,
}) => {
  return (
    <div className="edit-buttons">
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("text")}
      >
        Add Text
      </button>
      {process.env.NODE_ENV === "development" && (
        <button
          className="btn btn-outline-primary"
          onClick={() => addSectionContent("text-slate")}
        >
          Add Text Slate (Alpha)
        </button>
      )}
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("image")}
      >
        Add Image
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("video")}
      >
        Add Video
      </button>
      <button
        className="btn btn-outline-primary"
        disabled={currentSectionNumber === 0}
        onClick={() => addSectionContent("quiz")}
      >
        Add Quiz{" "}
        {currentSectionNumber === 0 && `Cannot add quiz on first section`}
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("drag-drop")}
      >
        Add Drag Drop
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("likert")}
      >
        Likert Scale
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("carousel")}
      >
        Carousel
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("chatGame")}
      >
        Chat Game
      </button>

      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("decisionTree")}
      >
        Decision Tree
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("file")}
      >
        Add File
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("table")}
      >
        Table
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("multi-dragdrop")}
      >
        Multi Drag and Drop
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("snowball-spreadsheets")}
      >
        Snowball Spreadsheets
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("quest-api")}
      >
        Quest API Test
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("ged-platform")}
      >
        GED Module
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("user-input")}
      >
        User Input
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("budgetingGame")}
      >
        Budgeting Simulator
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("creditGame")}
      >
        Credit Simulator
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("newFinanceSpreadsheets-ruleOf72Steps")}
      >
        New Finance - Rule of 72
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("newFinanceSpreadsheets-investingSteps")}
      >
        New Finance - Investing
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("newFinanceSpreadsheets-taxesSteps")}
      >
        New Finance - Taxes
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("newFinanceSpreadsheets-taxesIISteps")}
      >
        New Finance - Taxes II
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("newFinanceSpreadsheets-budetingSteps")}>
        New Finance - Budgeting
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("newFinanceSpreadsheets-creditSteps")}>
        New Finance - Credit
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => addSectionContent("newFinanceSpreadsheets-interestSteps")}>
        New Finance - Interest
      </button>
    </div>
  );
};

export default AddSectionContentButtons;
