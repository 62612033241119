import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ReactQuill from "react-quill";
import "./index.css";

import "../../index.css";
import SwitchItemOrderButtons from "../shared/SwitchItemOrderButtons";

class DemoCarousel extends Component {
  render() {
    const {
      editSection,
      index,
      item,
      removeContentAtIndex,
      addSlide,
      removeSlide,
      _moveSectionContentDown,
      _moveSectionContentUp,
    } = this.props;
    return (
      <div className="carouseledit__container">
        <Carousel>
          {item &&
            item.slides &&
            item.slides.map((slide, slideIndex) => {
              return (
                <div key={slideIndex} className="carousel-wrapper">
                  <ReactQuill
                    value={slide.value || ""}
                    placeholder="Enter text or paste image here..."
                    onChange={(e) =>
                      editSection(e, index, "carousel", slideIndex)
                    }
                    modules={{
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                  />
                </div>
              );
            })}
        </Carousel>
        <button
          type="button"
          className="btn btn-outline-success"
          onClick={() => addSlide(index)}
        >
          Add Slide
        </button>
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={() => removeSlide(index)}
        >
          Remove Last Slide
        </button>
        <SwitchItemOrderButtons
          index={index}
          _moveSectionContentDown={_moveSectionContentDown}
          _moveSectionContentUp={_moveSectionContentUp}
        />
        <button
          className="btn btn-danger"
          onClick={(e) => removeContentAtIndex(e, index)}
        >
          Delete Carousel
        </button>
      </div>
    );
  }
}

export default DemoCarousel;
