import React from "react";
import "../modals.css";
import MonthBreakdown from "./MonthBreakdown";
import MonthChoice from "./MonthChoice";
import ResultsModal from "../ResultsModal";

const MonthModal = (props) => {
  const expenses = Object.values(props.monthBreakdownData);
  const spentAmounts = expenses.map((expense) => {
    return expense.spentAmount;
  });

  if (props.monthBreakdownVisible) {
    return (
      <MonthBreakdown
        monthBreakdownData={props.monthBreakdownData}
        monthSavings={props.monthSavings}
        _startMonthChoice={props._startMonthChoice}
        _startGame={props._startGame}
        happyLevel={props.happyLevel}
        spentAmounts={spentAmounts}
        sheetId={props.sheetId}
      />
    );
  } else if (props.resultsModalVisible) {
    return (
      <ResultsModal
        handleNext={props.handleNext}
        _getAverageSavings={props._getAverageSavings}
        savingsData={props.savingsData}
        userName={props.userName}
        _startGame={props._startGame}
        _startGameReview={props._startGameReview}
        monthSavings={props.monthSavings}
        emergencyFundSavings={props.emergencyFundSavings}
        happyLevel={props.happyLevel}
        sheetId={props.sheetId}
        toggleNext={props.toggleNext}
      />
    );
  } else {
    return (
      <MonthChoice
        monthSavings={props.monthSavings}
        _startMonth={props._startMonth}
        savingsChoice={props.savingsChoice}
        _selectSavingsChoice={props._selectSavingsChoice}
      />
    );
  }
};

export default MonthModal;
