import firebase from "firebase/app";

export const setModules = (modules) => {
  return { type: "modules/set", payload: modules };
};

export const getAllModules = (authUser, classData) => {
  if (!authUser || !classData) {
    return;
  }

  return async function (dispatch) {
    // const cacheKey = "cachedModulesOverview";
    // const cacheTimeKey = "cachedModulesTime";
    // const cacheTimeout = 3600000; // 1 hour in milliseconds

    // const cachedModulesData = localStorage.getItem(cacheKey);
    // const cachedTime = localStorage.getItem(cacheTimeKey);
    // const now = new Date().getTime();

    // Immediately start fetching new data from Firebase
    const modulesPromise = firebase
      .database()
      .ref("modulesOverview")
      .once("value");

    // // Check and use cached data if not expired
    // if (
    //   cachedModulesData &&
    //   cachedTime &&
    //   now - parseInt(cachedTime) < cacheTimeout
    // ) {
    //   const modulesValues = JSON.parse(cachedModulesData);
    //   console.log("modulesActions.js 29 | using cached modules");
    //   processAndDispatchModules(modulesValues, authUser, classData, dispatch);
    // }

    // Await the Firebase fetch operation
    const modulesRequest = await modulesPromise;
    if (modulesRequest.exists()) {
      const modulesValues = modulesRequest.val();
      // Store the new data and the current timestamp in localStorage
      // localStorage.setItem(cacheKey, JSON.stringify(modulesValues));
      // localStorage.setItem(cacheTimeKey, now.toString());
      // Dispatch the latest modules
      processAndDispatchModules(modulesValues, authUser, classData, dispatch);
    } else {
      console.log("modulesActions.js 51 | dispatching null modules");
      dispatch(setModules(null));
    }
  };
};

function processAndDispatchModules(
  modulesValues,
  authUser,
  classData,
  dispatch
) {
  let modulesList = Object.keys(modulesValues).map((key) => ({
    ...modulesValues[key],
    uid: key,
  }));

  // Filtering and dispatching logic based on user roles
  if (authUser?.isAdmin || authUser?.isViewer) {
    let modulesCopy = getModulesDataFromUserAndClassData(
      modulesList,
      classData,
      authUser
    );
    dispatch(setModules(modulesCopy));
  } else {
    let userModeFilter = filterAvailableModules(modulesList, classData);
    let updatedOrderCopy = getModulesDataFromUserAndClassData(
      userModeFilter,
      classData,
      authUser
    );
    dispatch(setModules(updatedOrderCopy));
  }
}

const getModulesDataFromUserAndClassData = (
  modulesList,
  classData,
  authUser
) => {
  let modulesCopy = [...modulesList];

  modulesCopy.forEach((module, index) => {
    if (authUser?.lessons && authUser?.lessons[module.uid]) {
      modulesCopy[index].progress = authUser?.lessons[module.uid]?.progress;
      modulesCopy[index].sectionLength =
        authUser.lessons[module.uid]?.sectionLength;
    }
    modulesCopy[index].dueDate =
      classData?.settings?.modules[module.uid]?.dueDate || null;

    modulesCopy[index].unlockDate =
      classData?.settings?.modules[module.uid]?.unlockDate || null;
  });

  modulesCopy.sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1));

  return modulesCopy;
};

const filterAvailableModules = (modulesList, classData) => {
  let moduleFilter = modulesList.filter((module) => {
    if (classData?.settings?.modules) {
      return classData?.settings?.modules[module.uid]?.available ? true : false;
    } else {
      return false;
    }
  });

  return moduleFilter;
};
