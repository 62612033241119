import { PDFDocument, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { toUpperCaseString } from "src/utils/utils";

export const generateCertificate = async (
  studentName,
  lessonName,
  completionDate,
  description = "",
  isFinalCertificate
) => {
  try {
    console.log("certificateFunctions.js 9 | generating certificate");
    // const certificateUrl =
    //   "https://firebasestorage.googleapis.com/v0/b/red-platform.appspot.com/o/pdfs%2FRED%20Completion%20Certificate.pdf?alt=media&token=6c719f1b-91e1-4653-b961-f88a640db3f4";
    const certificateUrl =
      "https://firebasestorage.googleapis.com/v0/b/red-platform.appspot.com/o/pdfs%2FRED%20Completion%20Certificate%20Final.pdf?alt=media&token=d83fc92a-083e-4894-ace4-e026f004fa7d";

    const formPdfBytes = await fetch(certificateUrl).then((res) =>
      res.arrayBuffer()
    );

    const pdfDoc = await PDFDocument.load(formPdfBytes);
    pdfDoc.registerFontkit(fontkit);

    //
    const signinFontUrl =
      "https://firebasestorage.googleapis.com/v0/b/red-platform.appspot.com/o/fonts%2FPinyonScript-Regular.ttf?alt=media&token=f792fea4-d4f4-4f27-9ce5-eaa2d799342e";

    const descriptionFontUrl =
      "https://firebasestorage.googleapis.com/v0/b/red-platform.appspot.com/o/fonts%2FCardo-Regular.ttf?alt=media&token=553db88c-84ca-4755-8aad-2a7e224c6a47";

    const fontBytes = await fetch(signinFontUrl).then((res) =>
      res.arrayBuffer()
    );
    const descriptionFontBytes = await fetch(descriptionFontUrl).then((res) =>
      res.arrayBuffer()
    );

    const cursiveFont = await pdfDoc.embedFont(fontBytes);
    const descriptionFont = await pdfDoc.embedFont(descriptionFontBytes);

    const form = pdfDoc.getForm();

    const name = form.getTextField("Name");
    // name.setText(studentName);
    name.setText(toUpperCaseString(studentName));
    name.setFontSize(26);

    const moduleName = form.getTextField("Description");
    const date = form.getTextField("Date");
    date.setText(completionDate);
    const certificateDescription =
      description !== ""
        ? description
        : `Has successfully completed the ${lessonName} module within the RED Ed Learning Management System. The Certification is approved by RED and satisfied compliance standards as a qualified accountability program.`;
    moduleName.setText(certificateDescription);

    name.updateAppearances(descriptionFont);
    moduleName.updateAppearances(descriptionFont);
    date.updateAppearances(descriptionFont);

    const pdf = await pdfDoc.save();
    var blob = new Blob([pdf], { type: "application/pdf" });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const fileUrl = URL.createObjectURL(blob);
    a.href = fileUrl;
    a.download = isFinalCertificate
      ? `${studentName}`
      : `Completion certificate - ${lessonName} `;
    a.click();
    // send pdf to user as a blob, process that in the client
  } catch (error) {
    console.log("generate-pdf.js 28 | error creating pdf", error);
    throw new Error("Error generating certificate", error);
  }
};

export const generateTermsAndConditions = async (
  studentName,
  lessonName,
  completionDate,
  className,
  program = "Stop Recidivism"
) => {
  try {
    console.log(
      "Achievements.js 29 | generating certificate",
      studentName,
      lessonName,
      completionDate
    );
    const certificateUrl =
      "https://firebasestorage.googleapis.com/v0/b/red-platform.appspot.com/o/pdfs%2FRED%20Latest%20Certificate.pdf?alt=media&token=761868e2-a321-451c-9c53-94b6d637ab12";

    const formPdfBytes = await fetch(certificateUrl).then((res) =>
      res.arrayBuffer()
    );

    const pdfDoc = await PDFDocument.load(formPdfBytes);

    const form = pdfDoc.getForm();

    const name = form.getTextField("Name");
    const moduleName = form.getTextField("Module Name");
    const date = form.getTextField("Date");
    const classId = form.getTextField("Classroom Name");

    name.setText(studentName);
    moduleName.setText(lessonName);
    date.setText(completionDate);
    classId.setText(className);

    const pdf = await pdfDoc.save();
    var blob = new Blob([pdf], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(blob);
    const w = window.open(fileUrl, "_blank");
    w && w.focus();

    // send pdf to user as a blob, process that in the client
  } catch (error) {
    console.log("generate-pdf.js 28 | error creating pdf", error);
    throw new Error("Error generating certificate", error);
  }
};
