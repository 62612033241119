import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  getCalendarEvents,
  deleteCalendarEvent,
  insertExistingCalendarIntoUserCalendars,
  getUserCalendarsList,
} from "../../api/googleCalendar";
import {
  AuthUserContext,
  withAuthentication,
  withAuthorization,
} from "../Session";
import "./index.css";
import { compose } from "recompose";

import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import EditEventModal from "../ManageEvents/components/EditEventModal";

import { RED_TEST_CALENDAR_ID } from "./constants";
import ClassEventsManager from "../ViewClassroom/components/ClassEventsManager";
import { connect } from "react-redux";
import { withFirebase } from "../Firebase";

const localizer = momentLocalizer(moment); // or globalizeLocalizer

//Calendar Instructions

export class GoogleCalendar extends Component {
  static contextType = AuthUserContext;

  state = {
    token: null,
    events: null,
    noToken: false,
    calendarName: null,
    displayEventModal: false,
    calendarView: "agenda",
    currentEvent: null,
    events: [
      // {
      //   start: moment().toDate(),
      //   end: moment().add(1, "days").toDate(),
      //   title: "Loading",
      // },
    ],
    selectedEvent: null,
    classroomData: null,
  };

  async componentDidMount() {
    this.getToken();
    // this.getCalendarAccess();
    this.getClassroomData();
  }

  getCalendarAccess = async () => {
    let data = await getUserCalendarsList(this.context.calendarId);
    this.setState({ calendarAccessRole: data.accessRole });
  };

  getToken = () => {
    const sessionToken = localStorage.getItem("token");
    if (sessionToken) {
      this.setState({ token: sessionToken }, () => {
        this.handleCalendar();
      });
    } else {
      this.setState({
        noToken: true,
      });
    }
  };

  createEventObjectFromCalendar = () => {
    ///
  };

  handleCalendar = async () => {
    try {
      const calendarEvents = await getCalendarEvents(this.context.calendarId);
      if (calendarEvents) {
        this.setCalendarEventsInCorrectFormat(calendarEvents.items);
      }
      if (calendarEvents.error) {
        this.handleCalendarNotFound();
      }
    } catch (error) {
      console.log("Calendar 58 | error handling calendar:", error);
    }
  };

  setCalendarEventsInCorrectFormat = (events) => {
    console.log("Calendar | Initial events:", events);
    let updatedFormats = [...events];
    events.forEach((event, index) => {
      let start = moment(event.start.dateTime).toDate();
      let end = moment(event.end.dateTime).toDate();
      updatedFormats[index].start = start;
      updatedFormats[index].end = end;
      updatedFormats[index].title = event.summary;
      updatedFormats[index].showEvent = true;

      if (event.attendees) {
        let included = event.attendees.filter(
          (attendee) => attendee.email === this.context.email
        );

        if (included.length > 0 || this.context.isAdmin) {
        } else {
          updatedFormats[index].showEvent = false;
        }
      }
    });

    let filteredPrivateEvents = updatedFormats.filter(
      (event) => event.showEvent === true
    );

    console.log("Calendar | Before date filtering:", filteredPrivateEvents);

    this.setState({ events: filteredPrivateEvents });
  };

  handleCalendarNotFound = async () => {
    console.log("Calendar 80 | handling calendar not found");
    const insertCalendar = await insertExistingCalendarIntoUserCalendars(
      RED_TEST_CALENDAR_ID
    );

    if (insertCalendar.error) {
      console.log(
        "Calendar 88 | error inserting calendar into user",
        insertCalendar.error
      );
    } else {
      console.log("Calendar 82 | inserting calendar into user", insertCalendar);
    }
  };

  deleteEvent = async (eventId) => {
    return deleteCalendarEvent(this.context.calendarId, eventId)
      .then(() => {
        // console.log("Calendar 68 | event successfully deleted!");
        this.handleCalendar();
        this.setState({ displayEventModal: false });
      })
      .catch((error) => {
        console.log("Calendar 74 | ", "ERROR", error);
      });
  };

  toggleEventModal = (event) => {
    console.log(event, "event");
    this.setState({
      displayEventModal: !this.state.displayEventModal,
      currentEvent: event,
    });
  };

  onSelectAgendaEvent = (event) => {
    this.setState({
      displayEventModal: !this.state.displayEventModal,
      currentEvent: event,
    });
  };

  redirectToEvent = (event) => {
    this.props.history.push(`/event/${event.event.id}`);
  };

  MyAgendaComponentWithProps = (event) => {
    return (
      <MyAgendaComponent
        event={event}
        calendarView={this.state.calendarView}
        onSelectAgendaEvent={() => this.onSelectAgendaEvent(event)}
        redirectToEvent={() => this.redirectToEvent(event)}
      />
    );
  };

  getClassroomData = async () => {
    const classroomData = await this.props.firebase
      .classroom(this.context.classUID)
      .once("value");

    if (classroomData.val()) {
      this.setState({ classroomData: classroomData.val() });
    } else {
      this.setState({ classroomData: null });
    }
  };

  render() {
    const { currentEvent, calendarAccessRole } = this.state;

    return (
      <div className="calendar-container">
        <h3>{this.state.calendarName}</h3>
        <p>Classroom: {this.context.classId}</p>

        <ClassEventsManager
          events={this.props.events}
          calendarId={this.state.classroomData?.calendarId}
          authUser={this.context}
          classId={this.context.classUID}
          className={this.context.classId}
          classData={this.state.classroomData}
          firebase={this.props.firebase}
        />

        {this.state.displayEventModal && this.context.isAdmin ? (
          <EditEventModal
            createPrivateEventOpen={this.state.displayEventModal}
            setCreatePrivateEventOpen={() =>
              this.setState({ displayEventModal: false })
            }
            eventName={currentEvent.event.summary}
            eventAddress={currentEvent.event.location}
            eventDescription={currentEvent.event.description}
            eventLocation={currentEvent.event.location}
            eventStartDate={currentEvent.event.start}
            eventEndDate={currentEvent.event.end}
            googleCalendarEventId={currentEvent.event.id}
            calendarId={this.context.calendarId}
            fetchCalendar={this.handleCalendar}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // authUser: state.sessionState.authUser,
  classroomDataState: state.classroomDataState,
  events: state.events,
});

const mapDispatchToProps = (dispatch) => ({
  // Add your dispatch actions here
});

const condition = (authUser) => !!authUser;

export default compose(
  withAuthentication,
  withFirebase,
  withAuthorization(condition),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GoogleCalendar);

export class MyAgendaComponent extends Component {
  render() {
    const { event, onSelectAgendaEvent, calendarView, redirectToEvent } =
      this.props;
    console.log(event, "event", onSelectAgendaEvent);
    return event ? (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <div
          onClick={
            calendarView === "agenda" ? redirectToEvent : onSelectAgendaEvent
          }
        >
          {event.title}
        </div>
        {calendarView === "agenda" && (
          <img
            onClick={onSelectAgendaEvent}
            src={"/assets/images/edit_icon.svg"}
            className="event__manager__edit__icon"
            alt="edit icon"
          />
        )}
      </div>
    ) : null;
  }
}
