import React from "react";
import AndroidQR from "src/android-qr.jpeg";
import IosQR from "src/ios-qr.jpeg";

import "./index.css";

export const DownloadApp = () => {
  return (
    <div>
      <div className="qrcodes__contentcontainer">
        <div className="scan__title">
          Scan the QR Codes and download the app!
        </div>
        <div className="qrcodes__container">
          <div className="qr__container">
            <img style={{ width: 200, height: 200 }} src={AndroidQR} />
            <div className="device__text">Android</div>
          </div>
          <div className="qr__container">
            <img style={{ width: 200, height: 200 }} src={IosQR} />
            <div className="device__text">Iphone</div>
          </div>
        </div>
      </div>
    </div>
  );
};
