import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { initialState } from "./utils";

function useCreditGame() {
  const [state, setState] = useState({
    debts: {
      creditCard: { amount: 2000, minPayment: 50, name: "Credit Card" },
    },
    currentDebt: 2000,
    debtsPaidForMonth: false,
    interestAdded: false,
    savings: 500,
    timeLeft: 6,
    interestRate: 1.2,
    payments: [],
    modal: "welcome",
    currentMonth: 0,
    paymentError: false,
    chartsModalOpen: false,
  });

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  useEffect(() => {
    if (state.timeLeft <= 0) {
      if (state.currentDebt > 0) {
        setState({ ...state, modal: "lose" });
      } else {
        setState({ ...state, modal: "win" });
      }
    } else {
      setState({ ...state, debts: { ...state.debts } });
    }
  }, [state.timeLeft]);

  const payDebt = (type, amount) => {
    const notPayingMin = amount < state.debts[type].minPayment;
    const insufficientSavings = amount > state.savings;
    if (notPayingMin || insufficientSavings) {
      setState({ ...state, modal: "declined" });
      toast.error(
        `Payment Declined: ${
          notPayingMin ? "Not Meeting Minimum Payment!" : ""
        } ${insufficientSavings ? "Insufficient Savings!" : ""}`,
        { position: "bottom-center" }
      );

      return;
    }

    const totalDebt =
      state.debts["creditCard"].amount -
      Object.values([...state.payments, { type, amount }]).reduce(
        (acc, value) => {
          return acc + +value.amount;
        },
        0
      );

    if (totalDebt <= 0) {
      console.log("gameLogic.js 60 | total debt is less than 0");
      return;
    }

    toast.success("Debt paid!", { position: "bottom-center" });

    setState((prev) => ({
      ...state,
      currentMonth: prev.currentMonth + 1,
      payments: [
        ...state.payments,
        { type, amount, debtAtTheTime: prev.currentDebt - amount },
      ],
      timeLeft: prev.timeLeft - 1,
      interestAdded: false,
      currentDebt: prev.currentDebt - amount,
      modal: prev.currentDebt - amount <= 0 ? "win" : "success",
      chartsModalOpen: false,
    }));
  };

  const resetGame = () => {
    setState(initialState);
  };

  const moveToNextMonth = () => {
    setState((prev) => {
      return {
        ...state,
        timeLeft: prev.timeLeft - 1,
        interestAdded: false,
        currentMonth: prev.currentMonth + 1,
        debtsPaidForMonth: false,
      };
    });
  };

  const addInterest = () => {
    const newDebts = { ...state.debts };

    // interest should be added on remaining amount

    for (let [key, value] of Object.entries(newDebts)) {
      newDebts[key].amount = value.amount * (1 + state.interestRate / 12);
    }

    setState((prev) => ({
      ...state,
      interestAdded: true,
      debts: newDebts,
      currentDebt: prev.currentDebt * (1 + state.interestRate / 12),
      chartsModalOpen: true,
    }));

    toast.success("Interest Added!", {
      position: "bottom-center",
    });
  };

  return {
    state,
    payDebt,
    addInterest,
    months,
    moveToNextMonth,
    resetGame,
  };
}

export default useCreditGame;
