import React, { useEffect, useState } from "react";
import "./Cell.css";

const WatermarkCell = ({
  cellName,
  setBlured,
  onTextChange,
  value,
  editable = true,
  highlighted,
}) => {
  const [focused, setFocused] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (highlighted) {
      setIsHighlighted(true);
      setTimeout(() => {
        setIsHighlighted(false);
      }, 3000);
    }
  }, [highlighted]);

  const styles = {
    cell: {
      width: 120,
      height: 40,
      backgroundColor: "white",
      // borderWidth: 1,
      justifyContent: "center",
    },
    input: {
      flex: 1,
      width: 120,
      paddingHorizontal: 10,
      color: "black",
      fontSize: 12,
      height: 40,
      borderWidth: focused ? 2 : 1,
      borderColor: focused ? "blue" : "#e0e0e0",
    },
  };

  return (
    <div style={styles.cell} cellName="cellstyle__container">
      <input
        className="finance__input"
        style={styles.input}
        onChange={(text) => {
          console.log("text", text.target.value);
          onTextChange(text.target.value);
        }}
        onClick={(text) => {
          setFocused(true);
        }}
        placeholder={cellName}
        placeholderTextColor="lightgray"
        onBlur={() => setFocused(false)}
        value={value}
        disabled={!editable}
      />
    </div>
  );
};

export default WatermarkCell;
