import React from "react";
import "./LifestyleOption.css";
import { capitalizeFirstLetter } from "src/utils/utils";
import { toast } from "react-toastify";

const LifestyleOption = ({
  option,
  handleChoice,
  type,
  category,
  state,
  index,
}) => {
  const unlocked = state.level > index;
  const isSelected =
    option.name === state.job?.name ||
    state.expenses[category]?.name === option.name;
  const isLocked = !unlocked && type !== "expense";

  const handleClick = () => {
    if (unlocked || type === "expense") {
      handleChoice(option, category);
    } else {
      toast.error("You haven’t unlocked this option yet");
    }
  };

  const getOptionClass = () => {
    if (isLocked) return "lifestyleoption-locked";
    if (isSelected) return "lifestyleoption-selected";
    return "lifestyleoption";
  };

  const displayPrice = type === "job" ? option.salary : option.cost;

  if (
    type === "expense" &&
    option.name === "Live with parents" &&
    state.level > 1
  ) {
    return <div className="kickedout">Your parents kick you out of the house!</div>;
  }

  return (
    <div className={getOptionClass()} onClick={handleClick}>
      <div className="option-1-heading1">
        {capitalizeFirstLetter(option.name)}
      </div>
      <div className="option-1-price-container1">
        <div className="option-1-price-value-container1">
          <div className="option-1-currency1">$</div>
          <div className="option-1-price2">{displayPrice}</div>
        </div>
        <div className="option-1-price3">per month</div>
      </div>
      <div className="happy-points-container">
        {/* <span role="img" aria-label="Happy">
          😀
        </span> */}
        {/* <div className="x31">+1</div> */}
      </div>
    </div>
  );
};

export default LifestyleOption;
