import React, { useState, useEffect } from "react";
import Icon from "../shared/Icon";
import moment from "moment";

const sampleImage =
  "https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/xd_microsite/undraw_design_thinking_xd.svg";

const Task = ({ name, image, dueDate, status = "notDone" }) => {
  const [shortenedName, setShortenedName] = useState(undefined);
  const statusData = {
    inProgress: { icon: "history_toggle_off", color: "#FB7C23" },
    done: { icon: "done_all", color: "#00BA88" },
    dueThisWeek: { icon: "info", color: "#D94645" },
    notDone: { icon: "not_done", color: "#C6C6C6" },
  };

  function daysRemaining() {
    var eventdate = moment(dueDate);
    var todaysdate = moment();
    return eventdate.diff(todaysdate, "days");
  }

  useEffect(() => {
    shortName();
  }, []);

  const shortName = () => {
    console.log("Task.js 22 | name length", name.length);
    if (name.length > 30) {
      console.log("Task.js 28 | shortenning name");
      setShortenedName(`${name.slice(0, 30)} ...`);
    }
  };

  return (
    <div
      className="task__container"
      style={{
        borderBottom: `3px solid ${
          status ? statusData[status]?.color : statusData["notDone"].icon
        }`,
      }}
    >
      {/* {daysRemaining() <= 7 && status !== "done" ? "dueThisWeek" : status} */}
      <div className="taskimage__container">
        <img src={sampleImage} width={56} height={56} alt="taskimage" />
      </div>
      <div className="taskinfo__container">
        <div className="tasktitle">{name}</div>
        <div className="taskduedate">{moment(dueDate).format("MM-DD-YY")}</div>
      </div>
      <div className="taskstatusicon">
        <Icon name={statusData[status]?.icon} width={24} height={24} />
      </div>
    </div>
  );
};

export default Task;
