// Functions for Displaying or Creating the user's Google Sheet

import { getToken } from "src/api/token";

// Looks for sheet with matching title. If it finds one it updates state with ID. If there is no sheet it creates a new one and updates state with ID.
export const _lookForSheet = async (title, _startSetUp) => {
  const token = await getToken();
  console.log("Does Sheet Exist?");
  const drive = `https://www.googleapis.com/drive/v3/files?q=name='${title}'&pageSize=1`;

  return fetch(drive, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((info) => {
      if (info.files.length === 0) {
        // No file, create file with that name
        // Creates new sheet
        _createSheet(token, title, _startSetUp);
      } else {
        console.log("Yes, sheet is here");
        // saves sheet ID in storage
        localStorage.setItem("userFileId", JSON.stringify(info.files[0].id));
        // saves sheet ID in state
        _startSetUp(info.files[0].id);
      }
    })
    .catch((err) => {
      throw new Error("cannot find sheet", err.message);
    });
};

// Creates a sheet, sets state of userFileId to the ID of the created sheet
const _createSheet = (token, title, _startSetUp) => {
  const sheets = "https://sheets.googleapis.com/v4/spreadsheets/";

  fetch(sheets, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      properties: {
        title: title,
      },
    }),
  })
    .then((response) => response.json())
    .then((info) => {
      // saves sheet ID in storage
      localStorage.setItem("userFileId", JSON.stringify(info.spreadsheetId));
      // saves sheet ID in state
      _startSetUp(info.spreadsheetId);
    })
    .catch((err) => console.log("sheetsFunctions.js 51 | error", err));
};

// Functions for Reading and Updating specific cells in the user's Google Sheet

// Used once to set the initial template values at start of game.
export const _updateInitialValues = async (range, value, userFileId) => {
  const token = await getToken();
  // Url of API
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${userFileId}/values/${range}?valueInputOption=USER_ENTERED`;
  return fetch(url, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      values:
        // A1
        value,
    }),
  })
    .then(() => {
      console.log("sheetsFunctions.js 70 | success");
    })
    .catch((error) => {
      console.log("sheetsFunctions.js 72 | ", "ERROR", error);
    });
};

// Used throughout the game to post any changes to sheet such as choice amounts and new month names
export const _updateCellValues = async (
  userFileId,
  currentCells,
  currentValues,
  dimension
) => {
  try {
    const token = await getToken();
    console.log("sheetsFunctions.js 93 | token", token);
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${userFileId}/values/:batchUpdate`;
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        valueInputOption: "USER_ENTERED",
        data: [
          {
            range: `${currentCells}`, // expects a string like: "A1:C1"
            majorDimension: dimension,
            values: currentValues, // accepts an array like this: [ ["Food", "$30", " + $30"] ]
          },
        ],
      }),
    });
  } catch (error) {
    console.log("sheetsFunctions.js 113 | error updating cell values");
  }
};

// Fetches cell data from sheet.
// Currently used to get the user-inputted budget information.

export const _readColumns = (id, token, columnStart, columnEnd, callback) => {
  fetch(`https://sheets.googleapis.com/v4/spreadsheets/${id}:getByDataFilter`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      dataFilters: [
        {
          gridRange: {
            startColumnIndex: columnStart,
            endColumnIndex: columnEnd,
            startRowIndex: 3,
            endRowIndex: 8,
          },
        },
      ],
      includeGridData: true,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((info) => {
      callback(info.sheets[0].data[0]);
    })
    .catch((error) => {
      console.log("sheetsFunctions.js 128 | ", "ERROR", error);
      return error.message;
    });
};

// Helper functions which builds the cell ranges for the following two format functions
const _buildRangeObject = (
  startColumnIndex,
  endColumnIndex,
  startRowIndex,
  endRowIndex
) => {
  return {
    startColumnIndex,
    endColumnIndex,
    startRowIndex,
    endRowIndex,
  };
};

// Used once at start of game to set default template format.
export const _updateInitialFormat = async (userFileId) => {
  const token = await getToken();
  const body = {
    requests: [
      {
        updateSheetProperties: {
          fields: "gridProperties(hideGridlines)",
          properties: {
            sheetId: 0,
            gridProperties: {
              hideGridlines: true,
            },
          },
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(0, 12, 0, 12),
          cell: {
            userEnteredFormat: {
              backgroundColor: {
                red: 1,
                green: 1,
                blue: 1,
              },
              borders: {
                bottom: {
                  style: "NONE",
                },
              },
            },
          },
          fields: "userEnteredFormat(borders, backgroundColor)",
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(0, 4, 0, 2),
          cell: {
            userEnteredFormat: {
              backgroundColor: {
                red: 1,
                green: 0.94,
                blue: 0.8,
              },
              textFormat: {
                foregroundColor: {
                  red: 0,
                  green: 0,
                  blue: 0,
                },
                fontSize: 12,
                fontFamily: "Ubuntu",
              },
              wrapStrategy: "WRAP",
            },
          },
          fields: "userEnteredFormat(textFormat,wrapStrategy, backgroundColor)",
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(0, 4, 2, 9),
          cell: {
            userEnteredFormat: {
              backgroundColor: {
                red: 0.71,
                green: 0.83,
                blue: 0.65,
              },
              textFormat: {
                foregroundColor: {
                  red: 0,
                  green: 0,
                  blue: 0,
                },
                fontSize: 12,
                fontFamily: "Ubuntu",
              },
              wrapStrategy: "WRAP",
            },
          },
          fields: "userEnteredFormat(textFormat,wrapStrategy, backgroundColor)",
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(2, 3, 3, 8),
          cell: {
            userEnteredFormat: {
              backgroundColor: {
                red: 1,
                green: 1,
                blue: 1,
              },
              borders: {
                bottom: {
                  style: "SOLID",
                },
              },
            },
          },
          fields: "userEnteredFormat(borders, backgroundColor)",
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(1, 4, 0, 9),
          cell: {
            userEnteredFormat: {
              horizontalAlignment: "CENTER",
            },
          },
          fields: "userEnteredFormat(horizontalAlignment)",
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(2, 3, 1, 2),
          cell: {
            userEnteredFormat: {
              textFormat: {
                fontSize: 12,
                fontFamily: "Ubuntu",
                bold: true,
              },
            },
          },
          fields: "userEnteredFormat(textFormat)",
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(3, 4, 3, 8),
          cell: {
            userEnteredFormat: {
              textFormat: {
                fontSize: 10,
                fontFamily: "Ubuntu",
                bold: true,
              },
            },
          },
          fields: "userEnteredFormat(textFormat)",
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(0, 4, 8, 9),
          cell: {
            userEnteredFormat: {
              backgroundColor: {
                red: 1,
                green: 0.94,
                blue: 0.8,
              },
            },
          },
          fields: "userEnteredFormat(backgroundColor)",
        },
      },
    ],
  };

  return fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${userFileId}:batchUpdate`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }
  );
};

// Used at the start of every month to move the three Expense, Budget and Difference columns to the right.
export const _updateMonthFormat = async (userFileId, monthNum) => {
  const token = await getToken();
  const body = {
    requests: [
      {
        repeatCell: {
          range: _buildRangeObject(monthNum + 1, monthNum + 4, 0, 2),
          cell: {
            userEnteredFormat: {
              horizontalAlignment: "CENTER",
              backgroundColor: {
                red: 1,
                green: 0.94,
                blue: 0.8,
              },
              textFormat: {
                foregroundColor: {
                  red: 0,
                  green: 0,
                  blue: 0,
                },
                fontSize: 12,
                fontFamily: "Ubuntu",
                bold: false,
              },
              wrapStrategy: "WRAP",
            },
          },
          fields:
            "userEnteredFormat(textFormat, horizontalAlignment, wrapStrategy, backgroundColor)",
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(monthNum + 1, monthNum + 4, 2, 8),
          cell: {
            userEnteredFormat: {
              backgroundColor: {
                red: 0.71,
                green: 0.83,
                blue: 0.65,
              },
              textFormat: {
                foregroundColor: {
                  red: 0,
                  green: 0,
                  blue: 0,
                },
                fontSize: 12,
                fontFamily: "Ubuntu",
                bold: false,
              },
              borders: {
                bottom: {
                  style: "NONE",
                },
              },
              wrapStrategy: "WRAP",
            },
          },
          fields:
            "userEnteredFormat(textFormat,wrapStrategy, backgroundColor, borders)",
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(monthNum + 2, monthNum + 3, 3, 8),
          cell: {
            userEnteredFormat: {
              backgroundColor: {
                red: 1,
                green: 1,
                blue: 1,
              },
              borders: {
                bottom: {
                  style: "SOLID",
                },
              },
            },
          },
          fields: "userEnteredFormat(borders, backgroundColor)",
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(monthNum + 2, monthNum + 3, 1, 2),
          cell: {
            userEnteredFormat: {
              textFormat: {
                fontSize: 12,
                fontFamily: "Ubuntu",
                bold: true,
              },
            },
          },
          fields: "userEnteredFormat(textFormat)",
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(monthNum + 3, monthNum + 4, 3, 8),
          cell: {
            userEnteredFormat: {
              textFormat: {
                fontSize: 10,
                fontFamily: "Ubuntu",
                bold: true,
              },
            },
          },
          fields: "userEnteredFormat(textFormat)",
        },
      },
      {
        repeatCell: {
          range: _buildRangeObject(monthNum + 2, monthNum + 4, 8, 9),
          cell: {
            userEnteredFormat: {
              backgroundColor: {
                red: 1,
                green: 0.94,
                blue: 0.8,
              },
              textFormat: {
                fontSize: 12,
                fontFamily: "Ubuntu",
                bold: false,
              },
            },
          },
          fields: "userEnteredFormat(backgroundColor, textFormat)",
        },
      },
    ],
  };

  fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${userFileId}:batchUpdate`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      // console.log('data', data)
    });
};

// Updates borders of budget cells
export const _updateCellFormat = async (cells, userFileId, monthNum) => {
  const token = await getToken();
  let cellArray = Object.keys(cells);
  const requests = cellArray.map((index) => {
    let cell = cells[index];
    let color =
      cell.reset === true
        ? {
            red: 1,
            green: 1,
            blue: 1,
          }
        : {
            red: 1,
            green: 0.5,
            blue: 0.5,
          };

    return {
      repeatCell: {
        range: _buildRangeObject(
          monthNum + 2,
          monthNum + 3,
          cell.row + 3,
          cell.row + 4
        ),
        cell: {
          userEnteredFormat: {
            horizontalAlignment: "CENTER",
            backgroundColor: color,
          },
        },
        fields: "userEnteredFormat(horizontalAlignment, backgroundColor)",
      },
    };
  });

  const body = {
    requests: requests,
  };

  fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${userFileId}:batchUpdate`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      // console.log('data', data)
    });
};
