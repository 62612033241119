import React from "react";
import "./index.css";

const ModalContent = (props) => {
  const { title, subtitle, _nextButton, nextMessage, children } = props;

  const nextButtonActive =
    props.currentScreen != undefined ? (
      <button type="submit" className="btn btn-primary btn-lg">
        {nextMessage}
      </button>
    ) : (
      <button type="submit" disabled className="btn btn-primary btn-lg">
        {nextMessage}
      </button>
    );
  const backButton = (
    <button
      type="submit"
      onClick={props._prevScreen}
      className="btn btn-secondary"
    >
      Back
    </button>
  );
  return (
    <div className="snowball-modal">
      <div className="modal-top">
        <h4>{title}</h4>
        {console.log("ModalContent.js 33 | title", title)}
        <p className="subtitle-job-options">{subtitle}</p>
        {title !== "Choose Your Job" ? (
          <p className="due">NOT PAID. Due at the beginning of the month!</p>
        ) : null}
      </div>
      <div className="modal-options">
        <form className="start-form" onSubmit={_nextButton}>
          <div className="job-options">{children}</div>
          <div className="next-back-buttons">
            {backButton}
            {nextButtonActive}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalContent;
