import React from "react";
import "./NewsModal.css";

const NewsModal = (props) => {
  const recommendation = props.newsRecommendation ? (
    <div>
      <strong>Recommendation:</strong>
      <p>{props.newsRecommendation}</p>
    </div>
  ) : null;
  // console.log("news recommendation", props.newsRecommendation);

  if (props.newsModalVisible) {
    return (
      <div className="news-modal">
        <i
          onClick={props.closeNews}
          className="close-fa fa fa-times-circle"
          aria-hidden="true"
        ></i>

        <h6>
          <p className="fa fa-newspaper-o" aria-hidden="true"></p> News{" "}
        </h6>
        <h5>
          <strong>{props.title}</strong>
        </h5>
        <img className="news-image" src={props.img} alt="" />
        <hr />
        <p>{props.message}</p>
        <hr />
        {recommendation}
      </div>
    );
  } else {
    return null;
  }
};

export default NewsModal;
