import React from "react";
import "../modals.css";
import "./index.css";
import Chart from "../../Chart/Chart";

const MonthBreakdown = (props) => {
  const { monthSavings, _startMonthChoice, _startGame, happyLevel } = props;
  const category = Object.keys(props.monthBreakdownData);

  let message = "";
  let recommendation = "";

  if (monthSavings >= 0 && happyLevel >= 15) {
    message = `You finished the month, keep going!`;
    recommendation = "You are on a good track to winning. Move forward!";
  } else if (monthSavings >= 0 && happyLevel < 15) {
    message = `YOU LOST! You saved enough, but you're too sad!`;
    recommendation = "Try spending on things that make you happier.";
  } else if (monthSavings < 0 && happyLevel >= 15) {
    message = `YOU LOST! You are happy but broke!`;
    recommendation = "Try spending a little less next time.";
  } else if (monthSavings < 0 && happyLevel < 15) {
    message = `YOU LOST! You are broke AND sad!`;
    recommendation =
      "Spend less and choose things that make you happier. Yes, it is possible!";
  }
  let buttonMessage =
    monthSavings >= 0 && happyLevel >= 15 ? `Next` : `Play Again`;
  let _buttonAction =
    monthSavings >= 0 && happyLevel >= 15 ? _startMonthChoice : _startGame;
  let barClass =
    monthSavings >= 0 && happyLevel >= 15 ? "winning-bar" : "losing-bar";

  return (
    <div className="snowball-modal">
      <div className={barClass}>
        <h5>{message}</h5>
        <p className="recommendation">
          Recommendation: <br />
          {recommendation}
        </p>
      </div>
      {/* <h5>Your Expenses Breakdown</h5> */}
      <div style={{ marginTop: 30 }}></div>
      <Chart
        chartLabels={category}
        chartData={props.spentAmounts}
        chartTitle="Expenses Breakdown"
        chartType="HorizontalBar"
        responsive={true}
        aspectRatio={true}
      />
      <div className="finish-month-modal-buttons">
        <button
          onClick={_buttonAction}
          type="button"
          className="btn btn-primary btn-lg"
        >
          {buttonMessage}
        </button>
        <a
          href={`https://docs.google.com/spreadsheets/d/${props.sheetId}`}
          className="use-spreadsheet"
          target="_blank"
        >
          Use Spreadsheet
        </a>
      </div>
    </div>
  );
};

export default MonthBreakdown;
