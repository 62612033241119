import React from "react";
import "./InvestingSpreadsheet.css";

const InvestingSpreadsheet = ({ sheetId }) => {
  // console.log("sheetId in googlesheet", sheetId);

  if (sheetId) {
    return (
      <iframe
        title="sheet"
        // width="500px"
        height="100%"
        // src={url}
        src={`https://docs.google.com/spreadsheets/d/${sheetId}/view?rm=minimal&embedded=true`}
        frameBorder="0"
      ></iframe>
    );
  } else {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }
};

export default InvestingSpreadsheet;
