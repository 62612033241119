import React from "react";
import Input from "antd/es/input";
import "./index.css";

const { TextArea } = Input;

const Situation = ({
  situation,
  addToHorizontal,
  situationIndex,
  handleTreeChange,
  copiedTreeBranch,
  chosenChildrenTracker,
}) => {
  const { resultText, cost, revenue, progress } = situation;
  return (
    <div className="single-situation">
      <div className="situation-prompt">
        <p>{situationIndex === 0 ? "Situation" : "Consequence"}</p>
        <TextArea
          className="textarea-chatgame"
          rows={2}
          placeholder="Edit situation here..."
          value={resultText}
          onChange={(e, index) =>
            handleTreeChange(e, situationIndex, "situation")
          }
        />
      </div>
      {/* <p>Action: {situation.resultText}</p> */}
      <div className="responses">
        <p>Actions</p>
        <div className="children-situations-container">
          {situation &&
            situation.childrenSituations &&
            situation.childrenSituations.map(
              (childSituation, childSituationIndex) => {
                const { cost, revenue, progress } = childSituation;
                const isHighlighted =
                  chosenChildrenTracker[situationIndex] === childSituationIndex
                    ? true
                    : false;
                return (
                  <div
                    className={`child-situation ${
                      isHighlighted ? "highlight" : null
                    }`}
                    key={childSituationIndex}
                  >
                    <p className={`child-situation-text`}>
                      Response {childSituationIndex + 1}
                      {console.log(
                        "DecisionTree 169 | chosen children tracker",
                        chosenChildrenTracker
                      )}
                    </p>
                    <TextArea
                      rows={2}
                      placeholder="Enter response here..."
                      value={childSituation.actionText}
                      onChange={(e) =>
                        handleTreeChange(
                          e,
                          situationIndex,
                          "response",
                          childSituationIndex
                        )
                      }
                    />
                    <div className="child-variables">
                      <p>
                        Cost:
                        <Input
                          placeholder="$"
                          onChange={(e) =>
                            handleTreeChange(
                              e,
                              situationIndex,
                              "cost",
                              childSituationIndex
                            )
                          }
                          value={cost}
                        />
                      </p>
                      <p>
                        Revenue:
                        <Input
                          placeholder="$"
                          onChange={(e, index) =>
                            handleTreeChange(
                              e,
                              situationIndex,
                              "revenue",
                              childSituationIndex
                            )
                          }
                          value={revenue}
                        />
                      </p>
                      <p>
                        Progress:
                        <Input
                          placeholder="%"
                          onChange={(e, index) =>
                            handleTreeChange(
                              e,
                              situationIndex,
                              "progress",
                              childSituationIndex
                            )
                          }
                          value={progress}
                        />
                      </p>
                    </div>
                    <button
                      type="button"
                      className="consequence"
                      onClick={() => {
                        // chosenChildrenIndexes(index);
                        addToHorizontal(
                          childSituation,
                          situationIndex,
                          childSituationIndex
                        );
                      }}
                    >
                      Consequence {">"}
                    </button>
                    <button
                      type="button"
                      className="copy-branch"
                      onClick={(e) =>
                        handleTreeChange(
                          e,
                          situationIndex,
                          "copyExistingBranch",
                          null,
                          childSituationIndex
                        )
                      }
                    >
                      Copy Branch
                    </button>
                  </div>
                );
              }
            )}
        </div>
        <button
          onClick={(e) =>
            handleTreeChange(e, situationIndex, "addSituation", null)
          }
          type="button"
        >
          Add Action +
        </button>
        {copiedTreeBranch ? (
          <button
            onClick={(e) =>
              handleTreeChange(e, situationIndex, "pasteExisting", null)
            }
            className="copy-branch"
            type="button"
          >
            Paste Tree Branch
          </button>
        ) : null}
        <button
          onClick={(e) =>
            handleTreeChange(e, situationIndex, "deleteLastSituation", null)
          }
          type="button"
        >
          Remove Last Action
        </button>
      </div>
    </div>
  );
};

export default Situation;
