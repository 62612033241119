import React, { Component } from "react";
import "./index.css";

import LandingPage from "./components/Landing";

const Landing = (props) => {
  return <LandingPage history={props.history} />;
};

export default Landing;
