import React from "react";
import "./index.css";

//TODO test this component with mocks

const SwitchItemOrderButtons = ({
  index,
  _moveSectionContentDown,
  _moveSectionContentUp,
}) => {
  return (
    <div className="switch-order-buttons">
      <button
        type="button"
        onClick={(e) => _moveSectionContentDown(e, index)}
        className="btn btn-outline-info"
      >
        <i className="fas fa-arrow-down"></i>
      </button>
      <button
        type="button"
        onClick={(e) => _moveSectionContentUp(e, index)}
        className="btn btn-outline-info"
      >
        <i className="fas fa-arrow-up"></i>
      </button>
    </div>
  );
};

export default SwitchItemOrderButtons;
