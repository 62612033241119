import React from "react";
import Button from "src/components/shared/Button";
import restartGameImage from "./restartgame-image.png";

const GameLostModal = ({ restartGame }) => {
  return (
    <div className="chatgamelost__modal">
      <div className="gamelostext">You Lost, Try Again</div>
      <div className="gamelostimage">
        <img src={restartGameImage} alt="gamelost" width={292} height={292} />
      </div>
      <Button
        type="outline"
        title="Try Again"
        onClick={() => {
          restartGame();
        }}
      />
    </div>
  );
};

export default GameLostModal;
