import { pdf, Font } from "@react-pdf/renderer";
import PoppinsRegular from "../../../../../fonts/Poppins/Poppins-Regular.ttf";

// Import and register the Poppins font
// Font.register({
//   family: "Poppins",
//   src: "https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap",
// });

export const downloadPDF = (props) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!props.document) {
        reject();
        return;
      }

      if (props.save === true) {
        const instance = pdf(props.document);
        const blob = await instance.toBlob();
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          // Listo
          resolve({
            base64: reader.result.split(",")[1],
            filename: props.fileName,
            type: blob.type,
            size: blob.size,
            blob: blob,
          });
        };
      } else {
        // Creamos el PDF con el documento de react-pdf
        const instance = pdf(props.document);
        const blob = await instance.toBlob();

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.href = data;
        link.target = "_blank";
        link.download = props.fileName;
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(() => window.URL.revokeObjectURL(data), 100);
        resolve();
      }
    } catch (error) {
      console.log("GetReactPDF.js 58 | error exporting", error.message);
      reject(error);
    }
  });
};
