import React from 'react';
import Cell from '../Cell.js'
import PulsatingButton from '../PulsatingButton.js'

const styles = {
  container: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 20,
  },
  specialCharacter: {
    fontWeight: 'bold',
    color: 'orange'
  }
};

export const investingSteps = [
  {
    title: "What is Investing?",
    text: <span style={{ fontSize: 16 }}>On the simplest terms, investing is spending money expecting to get more money back. On cell <span style={styles.specialCharacter}>A4</span> type <span style={styles.specialCharacter}>Investment</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <Cell cellName={"A4"} onTextChange={setCellText} />
      </div>
    ),
    answer: "investment",
    range: "A4",
  },
  {
    title: "Investment Example",
    text: <span style={{ fontSize: 16 }}>Say your friend Alex is a very talented guitar player and people are willing to pay money to see him. You will invest in Alex. Type <span style={styles.specialCharacter}>Alex</span> in cell <span style={styles.specialCharacter}>A5</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <Cell cellName={"A4"} value={'Investment'} editable={false} />
        <Cell cellName={"A5"} onTextChange={setCellText} />
      </div>
    ),
    answer: "alex",
    range: "A5",
    color: "#fafafa",
  },
  {
    title: "Tracking Amount",
    text: <span style={{ fontSize: 16 }}>Let's keep track of your money. The first thing you'll track is the amount. Type <span style={styles.specialCharacter}>Amount</span> in cell <span style={styles.specialCharacter}>B4</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} onTextChange={setCellText} />
        </div>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
        </div>
      </div>
    ),
    answer: "amount",
    range: "B4",
    color: "#fafafa",
  },
  {
    title: "Invest",
    text: <span style={{ fontSize: 16 }}>Now let's send the money to Alex! Click the Send Money button</span>,
    button: "Invest!",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} onTextChange={setCellText} />
        </div>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
        </div>
        <PulsatingButton text={"Send money!"} onClick={getPaid} />
      </div>
    ),
    answer: "amount",
    range: "B4",
    color: "#fafafa",
  },
  {
    title: "Invest",
    text: <span style={{ fontSize: 16 }}>Now click Next</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
        </div>
      </div>
    ),
    answer: "1000",
    isDefault: true,
    range: "B5",
    color: "#fafafa",
  },
  {
    title: "Return",
    text: <span style={{ fontSize: 16 }}>While Alex is playing in his concert, let's keep building our tracker, also called an Investments Portfolio. Type <span style={styles.specialCharacter}>Return</span> on cell <span style={styles.specialCharacter}>C4</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} onTextChange={setCellText} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignSelf: 'flex-start' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
        </div>
      </div>
    ),
    answer: "return",
    range: "C4",
    color: "#fafafa",
  },
  {
    title: "Money Back!",
    button: "Receive Money!",
    text: <span style={{ fontSize: 16 }}>Seems like Alex did very well on his concert and made $2,000! He'll keep $500 and give you $1,500 back!. Click the button to receive your money</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={''} editable={false} />
        </div>
        <PulsatingButton text={"Receive Money!"} color={"green"} onClick={getPaid} />
      </div>
    ),
    answer: "1500",
    range: "C5",
    color: "#fafafa",
  },
  {
    title: "Money Back!",
    text: <span style={{ fontSize: 16 }}>Seems like Alex did very well on his concert and made $2,000! He'll keep $500 and give you $1,500 back!. Click the button to receive your money.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={'1500'} editable={false} />
        </div>
      </div>
    ),
    answer: "1500",
    range: "C5",
    isDefault: true,
    color: "#fafafa",
  },
  {
    title: "Profit",
    text: <span style={{ fontSize: 16 }}>Now let's see how much money you made. Type <span style={styles.specialCharacter}>Profit</span> on cell <span style={styles.specialCharacter}>D4</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
          <Cell cellName={"D4"} onTextChange={setCellText} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignSelf: 'flex-start' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={'1500'} editable={false} />
        </div>
      </div>
    ),
    answer: "profit",
    range: "D4",
    color: "#fafafa",
  },
  {
    title: "Profit II",
    text: <span style={{ fontSize: 16 }}>Subtract your Return from your Amount to see how much money you actually made on cell <span style={styles.specialCharacter}>D5</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
          <Cell cellName={"D4"} value={'Profit'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={'1500'} editable={false} />
          <Cell cellName={"D5"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "500",
    range: "D5",
    color: "#fafafa",
  },
  {
    title: "Profit Percentages",
    text: <span style={{ fontSize: 16 }}>It's important to understand your percentage (%) gains in order to compare multiple investment returns (more on this later). Let's first see what our percentage gain was. Type <span style={styles.specialCharacter}>%</span> on cell <span style={styles.specialCharacter}>E4</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
          <Cell cellName={"D4"} value={'Profit'} editable={false} />
          <Cell cellName={"E4"} onTextChange={setCellText} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={'1500'} editable={false} />
          <Cell cellName={"D5"} value={'500'} editable={false} />
        </div>
      </div>
    ),
    answer: "%",
    range: "E4",
    color: "#fafafa",
  },
  {
    title: "Profit Percentages II",
    text: <span style={{ fontSize: 16 }}>Percentages are fractions of a total, or an amount in for each one hundred. Per cent means for every hundred. Of our original invested Amount, how much profit did we get out of it? We made $500 of $1000. Let's start our percentage calculation by dividing $500 by $1000 on cell <span style={styles.specialCharacter}>E5</span>. Your answer should be a percentage, don't forget to add the % symbol.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
          <Cell cellName={"D4"} value={'Profit'} editable={false} />
          <Cell cellName={"E4"} value={'%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={'1500'} editable={false} />
          <Cell cellName={"D5"} value={'500'} editable={false} />
          <Cell cellName={"E5"} onTextChange={setCellText} />

        </div>
      </div>

    ),
    answer: "50%",
    range: "E5",
    color: "#fafafa",
  },
  {
    title: "What is a good return?",
    text: <span style={{ fontSize: 16 }}>You can see we've made a 50% profit on our original investment. Is that good? Well, the AVERAGE returns on the BEST companies in the United States are around 10% annualy. So yes, 50% is a fantastic return! Let's add the returns on the BEST companies in the US to our portfolio. This is called the S&P 500. <span style={styles.specialCharacter}>Type SPY</span>, representing the 500 BEST companies in the US, on cell <span style={styles.specialCharacter}>A6</span>. SPY is a ticker, or a symbol used to represent an investment. </span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
          <Cell cellName={"D4"} value={'Profit'} editable={false} />
          <Cell cellName={"E4"} value={'%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={'1500'} editable={false} />
          <Cell cellName={"D5"} value={'500'} editable={false} />
          <Cell cellName={"E5"} value={'50%'} editable={false} />
        </div>
        <div style={{ marginRight: 100 }}>
          <Cell cellName={"A6"} onTextChange={setCellText} />
        </div>
      </div>

    ),
    answer: "spy",
    range: "A6",
    color: "#fafafa",
  },
  {
    title: "Invest on the S&P 500",
    button: "Invest on SPY!",
    text: <span style={{ fontSize: 16 }}>You decide to invest $10,000 on the S&P 500 for one year. Click the button on the right and press check to Invest.</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
          <Cell cellName={"D4"} value={'Profit'} editable={false} />
          <Cell cellName={"E4"} value={'%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={'1500'} editable={false} />
          <Cell cellName={"D5"} value={'500'} editable={false} />
          <Cell cellName={"E5"} value={'50%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A6"} value={'SPY'} editable={false} />
          <Cell cellName={"B6"} onTextChange={setCellText} />
        </div>
        <PulsatingButton text={"Invest on SPY!"} color={"green"} onClick={getPaid} />
      </div>
    ),
    answer: "10000",
    range: "B6",
    isDefault: true,
    color: "#fafafa",
  },
  {
    title: "Invest on the S&P 500",
    text: <span style={{ fontSize: 16 }}>You decide to invest $10,000 on the S&P 500 for one year. Click the button on the right and press check to Invest.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
          <Cell cellName={"D4"} value={'Profit'} editable={false} />
          <Cell cellName={"E4"} value={'%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={'1500'} editable={false} />
          <Cell cellName={"D5"} value={'500'} editable={false} />
          <Cell cellName={"E5"} value={'50%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A6"} value={'SPY'} editable={false} />
          <Cell cellName={"B6"} value={'10000'} editable={false} />
        </div>
      </div>
    ),
    answer: "10000",
    range: "B6",
    isDefault: true,
    color: "#fafafa",
  },
  {
    title: "Invest on the S&P 500",
    button: "Sell SPY!",
    text: <span style={{ fontSize: 16 }}>After 1 year, your investment is now worth $11,000!</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
          <Cell cellName={"D4"} value={'Profit'} editable={false} />
          <Cell cellName={"E4"} value={'%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={'1500'} editable={false} />
          <Cell cellName={"D5"} value={'500'} editable={false} />
          <Cell cellName={"E5"} value={'50%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A6"} value={'SPY'} editable={false} />
          <Cell cellName={"B6"} value={'10000'} editable={false} />
          <Cell cellName={"C6"} onTextChange={setCellText} />

        </div>
        <PulsatingButton text={"Sell SPY!"} onClick={getPaid} />
      </div>
    ),
    answer: "11000",
    range: "C6",
    color: "#fafafa",
  },
  {
    title: "Invest on the S&P 500",
    text: <span style={{ fontSize: 16 }}>You decide to invest $10,000 on the S&P 500 for one year. Click the button on the right and press check to Invest.</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
          <Cell cellName={"D4"} value={'Profit'} editable={false} />
          <Cell cellName={"E4"} value={'%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={'1500'} editable={false} />
          <Cell cellName={"D5"} value={'500'} editable={false} />
          <Cell cellName={"E5"} value={'50%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A6"} value={'SPY'} editable={false} />
          <Cell cellName={"B6"} value={'10000'} editable={false} />
          <Cell cellName={"C6"} value={'11000'} editable={false} />
        </div>
      </div>

    ),
    answer: "11000",
    range: "C6",
    isDefault: true,
    color: "#fafafa",
  },
  {
    title: "SPY Profits",
    text: <span style={{ fontSize: 16 }}>Now let's see how much money you made. On cell <span style={styles.specialCharacter}>D6</span>  substract the return with the initial amount to calculate profits. </span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
          <Cell cellName={"D4"} value={'Profit'} editable={false} />
          <Cell cellName={"E4"} value={'%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={'1500'} editable={false} />
          <Cell cellName={"D5"} value={'500'} editable={false} />
          <Cell cellName={"E5"} value={'50%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A6"} value={'SPY'} editable={false} />
          <Cell cellName={"B6"} value={'10000'} editable={false} />
          <Cell cellName={"C6"} value={'11000'} editable={false} />
          <Cell cellName={"D6"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "1000",
    range: "D6",
    color: "#fafafa",
  },
  {
    title: "Profit %",
    text: <span style={{ fontSize: 16 }}>Finally, let's calculate percentage returns. On cell <span style={styles.specialCharacter}>E6</span> type divide the return by the initial amount to calculate profits, you answer should be a percentage, don't forget to add the % symbol.</span>,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A4"} value={'Investment'} editable={false} />
          <Cell cellName={"B4"} value={'Amount'} editable={false} />
          <Cell cellName={"C4"} value={'Return'} editable={false} />
          <Cell cellName={"D4"} value={'Profit'} editable={false} />
          <Cell cellName={"E4"} value={'%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A5"} value={"Alex"} editable={false} />
          <Cell cellName={"B5"} value={'1000'} editable={false} />
          <Cell cellName={"C5"} value={'1500'} editable={false} />
          <Cell cellName={"D5"} value={'500'} editable={false} />
          <Cell cellName={"E5"} value={'50%'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={"A6"} value={'SPY'} editable={false} />
          <Cell cellName={"B6"} value={'10000'} editable={false} />
          <Cell cellName={"C6"} value={'11000'} editable={false} />
          <Cell cellName={"D6"} value={'1000'} editable={false} />
          <Cell cellName={"E6"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "10%",
    range: "E6",
    color: "#fafafa",
  },
  {
    title: "Congratulations!",
    finished: true,
    text: <span style={{ fontSize: 16 }}>You finished the investing interactive lesson!</span>,
    get hint() {
      return `Type ${this.answer} on ${this.range}.`
    }
  }
];
