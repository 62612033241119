import React from "react";
import { assessmentCompetencies } from "../assessmentInfoData";
// import ProgressBar from "src/components/ProgressBar";
import { ProgressBarCompare } from "../../ProgressBarCompare";

export const Competency = ({
  competencyName,
  competencyScore,
  preTestCompetency,
}) => {
  console.log("Competency.js 11 | pretest competency", preTestCompetency);

  const preTestCompetencyScore = preTestCompetency
    ? (preTestCompetency.totalValue / (5 * preTestCompetency.count)) * 100
    : null;

  const difference = competencyScore - preTestCompetencyScore;

  return (
    <div className="competency">
      <div className="leaving-a-legacy">
        <div>{competencyName}</div>
      </div>

      {preTestCompetencyScore && (
        <div className="pretest_score_container">
          <div className="pretest_score_title">Pre</div>
          <ProgressBarCompare
            progress={parseFloat(preTestCompetencyScore).toFixed(0)}
            total={100}
            completeColor={competencyScore > 50 ? "green" : "red"}
            showLabel={false}
          />
          <div className="competency_score">
            {parseFloat(preTestCompetencyScore).toFixed(0)}
          </div>
        </div>
      )}
      <div className="pretest_score_container">
        {preTestCompetencyScore && (
          <div className="pretest_score_title">
            {preTestCompetencyScore ? "Post" : ""}
          </div>
        )}
        <ProgressBarCompare
          progress={competencyScore}
          progressBefore={preTestCompetencyScore}
          total={100}
          showLabel={preTestCompetencyScore ? true : false}
        />
        <div className="competency_score">
          {parseFloat(competencyScore).toFixed(0)}
        </div>
      </div>

      <div className="connecting-your-daily">
        {assessmentCompetencies[competencyName]}
      </div>
    </div>
  );
};
