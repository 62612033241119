import React, { useState, useEffect } from "react";
import "./DebtModalChart.css";

import Button from "src/components/shared/Button";
import DebtChart from "../components/DebtChart";

export const DebtModalChart = ({ state, months, onClose }) => {
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);

  useEffect(() => {
    // Populate chart labels immediately
    const labels = months
      .slice(0, state.currentMonth + 1)
      .map((month) => month);
    setChartLabels(labels);

    // Clear the previous chart data
    setChartData([]);

    // Function to add data with delay
    const loadChartDataWithDelay = () => {
      months.forEach((month, index) => {
        if (index <= state.currentMonth) {
          setTimeout(() => {
            const monthValue =
              state.currentMonth === index
                ? state.currentDebt.toFixed(2)
                : state.payments[index].debtAtTheTime.toFixed(2);

            setChartData((prevChartData) => [...prevChartData, monthValue]);
          }, index * 1000); // Delay of 1 second for each item
        }
      });
    };

    loadChartDataWithDelay();
  }, [state, months]); // Dependencies on which the effect should re-run

  return (
    <div className="debtmodalchart">
      <div className="debtmodal-title">
        <div className="debt-title">Total Debt</div>
      </div>
      <div className="chartcontainer">
        <DebtChart
          chartTitle="Total Debts"
          chartData={chartData}
          chartLabels={chartLabels}
        />
      </div>
      {onClose && <Button title="Close" onClick={onClose} />}
    </div>
  );
};

export default DebtModalChart;
