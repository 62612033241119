import React, { useState } from "react";
import useCreditGame from "./gameLogic"; // Assuming this is the path to your updated game logic
import CreditGameInfoHeader from "./components/InfoHeader";
import Button from "src/components/shared/Button";

import "./index.css";
import CreditTask from "./components/CreditTask";
import OpenCreditModalButton from "./modals/OpenCreditButton";
import OpenLostModal from "./modals/OpenLostModal";
import OpenWinModal from "./modals/OpenWinModal";
import OpenDebtModalChart from "./modals/OpenChartModal";

function CreditSimulator({ handleNext }) {
  const { state, months, payDebt, addInterest, resetGame } = useCreditGame();

  const [inputValues, setInputValues] = useState({});
  const [monthlyPayments, setMonthlyPayments] = useState({});

  const handleInputChange = (type, value) => {
    setInputValues({ ...inputValues, [type]: value });
  };

  const handlePay = (type, amount) => {
    payDebt(type, amount);
    setInputValues({ ...inputValues, [type]: "" });
  };

  const currentTask = !state.interestAdded
    ? "Add Interest"
    : "Pay Monthly Debt";

  return (
    <>
      <div className="creditsim__container">
        <h2 style={{ margin: "0 auto", textAlign: "center", marginBottom: 10 }}>
          {months[state.currentMonth]}
        </h2>
        <CreditGameInfoHeader state={state} />
        <CreditTask taskName={currentTask} />
        {!state.interestAdded && (
          <Button title="Add Interest" animate={true} onClick={addInterest} />
        )}

        <DebtTable
          state={state}
          handleInputChange={handleInputChange}
          handlePay={handlePay}
          monthlyPayments={monthlyPayments}
          months={months}
        />

        <OpenDebtModalChart state={state} months={months} />
      </div>
      {/* modals */}
      <OpenCreditModalButton />
      <OpenLostModal
        state={state}
        months={months}
        isOpen={state.modal}
        resetGame={resetGame}
      />
      <OpenWinModal
        isOpen={state.modal}
        resetGame={resetGame}
        handleNext={handleNext}
      />
    </>
  );
}

export const DebtTable = ({ state, handleInputChange, handlePay, months }) => {
  const [currentMonthValue, setCurrentMonthValue] = useState(0);

  const { debts, currentMonth } = state;

  const { minPayment } = debts["creditCard"];

  return (
    <>
      <table>
        <thead>
          <tr className="debttable__row">
            <th className="debttable__header">Month</th>
            <th className="debttable__header">Amount</th>
            <th className="debttable__header">Min Payment</th>
            <th className="debttable__header">Pay</th>
          </tr>
        </thead>
        <tbody>
          {months.map((_, index) => {
            if (index <= currentMonth) {
              return (
                <tr className="debttable__row debttable__row__animated">
                  <td className="debttable__cell">{months[index]}</td>
                  <td className="debttable__cell">
                    {currentMonth === index
                      ? state.currentDebt.toFixed(2)
                      : state.payments[index].debtAtTheTime.toFixed(2)}
                  </td>
                  <td className="debttable__cell">{minPayment}</td>
                  <td className="debttable__cell__input__container">
                    {currentMonth === index && state.interestAdded ? (
                      <input
                        type="number"
                        onChange={(e) => {
                          handleInputChange("creditCard", e.target.value);
                          setCurrentMonthValue(e.target.value);
                        }}
                        className={`${!state.interestAdded
                          ? "debttable__cell__input__unselected"
                          : state.modal === "declined"
                            ? "debttable__cell__input__declined"
                            : "debttable__cell__input"
                          }`}
                        value={currentMonthValue}
                      />
                    ) : currentMonth === index && !state.interestAdded ? (
                      currentMonthValue
                    ) : (
                      state.payments[index].amount
                    )}
                  </td>
                </tr>
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
      {state.interestAdded && (
        <Button
          title="Pay"
          animate={true}
          onClick={() => handlePay("creditCard", currentMonthValue)}
        />
      )}
    </>
  );
};

export const ModalComponent = ({ modal }) => {
  const messages = {
    welcome: "Welcome to the Debt Game!",
    declined: "Payment Declined!",
    success: "Payment Successful!",
    win: "You Won!",
    lose: "You Lost!",
  };
  return <div>{messages[modal] || ""}</div>;
};

export default CreditSimulator;
