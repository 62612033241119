import React, { memo } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import renderHTML from "react-render-html";
import "./index.css";

export const WordsContainer = ({
  words,
  dragAndDropComplete,
  lessonComplete,
}) => {
  console.log("WordsContainer.js 7 | words ", words);
  if (lessonComplete) return null;
  return (
    <Droppable droppableId={"words-id"}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className="words__container"
          {...provided.droppableProps}
        >
          {words.map((word, index) => (
            <MemoWord
              key={index}
              word={word.word}
              index={index}
              show={word.show}
              dragAndDropComplete={dragAndDropComplete}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const Word = ({ word, show, index, dragAndDropComplete }) => {
  if (show === false || dragAndDropComplete === true) {
    return null;
  }

  if (!word) {
    return null;
  }

  return (
    <Draggable draggableId={`${index}`} index={index}>
      {(provided, snapshot) => (
        <div
          className="styledword__container"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {renderHTML(word.trim())}
        </div>
      )}
    </Draggable>
  );
};

const MemoWord = memo(Word);
