import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../Firebase";

import SwitchItemOrderButtons from "../shared/SwitchItemOrderButtons";

const FileDownloadEdit = ({
  index,
  removeContentAtIndex,
  editSection,
  item,
  _moveSectionContentDown,
  _moveSectionContentUp,
  firebase,
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const manageFileUpload = (e, index) => {
    console.log(
      "EditSectionForm 37 | handling video upload",
      e.target.files[0]
    );

    const file = e.target.files[0];
    setUploading(true);
    firebase
      .uploadFile(`files/${file.name}`)
      .put(file)
      .on(
        "state_changed",
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setUploadStatus(progress);
        },
        (error) => {
          console.log("Video 42 | error uploading");
        },
        () => {
          console.log("Video 48 | success upload");
          firebase
            .uploadFile(`files/${file.name}`)
            .getDownloadURL()
            .then((url) => {
              setUploading(false);
              setUploadSuccess(true);
              console.log("EditSectionForm 48 | url obtained");
              editSection(e, index, "file", null, url);
            })
            .catch((error) => {
              console.log("EditSectionForm 47 | upload error", "ERROR", error);
            });
        }
      );
  };

  return (
    <div className="image-video-edit">
      <div className="editcontent__title">Add File</div>
      <input
        placeholder="Enter video url here..."
        className="single-input"
        value={item.url}
        onChange={(e) => editSection(e, index)}
      />
      {uploading ? (
        <div>Uploading: {Math.floor(uploadStatus)} %</div>
      ) : (
        <input type="file" onChange={(e) => manageFileUpload(e, index)} />
      )}
      {uploadSuccess ? <div>Upload Successful!</div> : null}

      <button
        className="btn btn-danger"
        onClick={(e) => removeContentAtIndex(e, index)}
      >
        X
      </button>
      <SwitchItemOrderButtons
        index={index}
        _moveSectionContentDown={_moveSectionContentDown}
        _moveSectionContentUp={_moveSectionContentUp}
      />
    </div>
  );
};

export default withFirebase(FileDownloadEdit);
