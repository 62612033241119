// src/store/lsiAssessmentReducer.js

// Action Types
const SET_LSI_DATA = "SET_LSI_DATA";
const SET_LSI_ANSWERS = "SET_LSI_ANSWERS";
const UPDATE_LSI_ANSWERS = "UPDATE_LSI_ANSWERS";
const SET_LOADING_DATA = "SET_LOADING_DATA";

// Initial State
const initialState = {
  lsiData: [],
  lsiAnswers: {},
  loadingData: false,
};

// Reducer
export function lsiAssessmentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LSI_DATA:
      return { ...state, lsiData: action.payload };
    case SET_LSI_ANSWERS:
      return { ...state, lsiAnswers: action.payload };
    case UPDATE_LSI_ANSWERS:
      return {
        ...state,
        lsiAnswers: {
          ...state.lsiAnswers,
          [action.payload.id]: action.payload.value,
        },
      };
    case SET_LOADING_DATA:
      return { ...state, loadingData: action.payload };
    default:
      return state;
  }
}

// Action Creators
export const setLsiData = (data) => ({ type: SET_LSI_DATA, payload: data });
export const setLsiAnswers = (answers) => ({
  type: SET_LSI_ANSWERS,
  payload: answers,
});
export const updateLsiAnswers = (id, value) => ({
  type: UPDATE_LSI_ANSWERS,
  payload: { id, value },
});
export const setLoadingData = (isLoading) => ({
  type: SET_LOADING_DATA,
  payload: isLoading,
});
