import React from "react";
import "../modals.css";

const MonthChoice = (props) => {
  const {
    monthSavings,
    savingsChoice,
    _selectSavingsChoice,
    _startMonth,
  } = props;

  const message = (
    <div className="">
      <h5>You saved ${monthSavings}!</h5>
      <p>What would you like to do with it?</p>
    </div>
  );

  const nextMonth =
    monthSavings > 0 ? (
      <div className="radio">
        <label className="option">
          <strong>Checking Account</strong>
          <input
            type="radio"
            value={"next-month"}
            checked={savingsChoice === "next-month"}
            onChange={_selectSavingsChoice}
          />
          Use the money for next month's expenses.
        </label>
      </div>
    ) : null;

  return (
    <div className="snowball-modal">
      <div className="modal-top">{message}</div>
      <div className="modal-options">
        <form className="start-form" onSubmit={_startMonth}>
          <div className="job-options">
            {nextMonth}
            <div className="radio">
              <label className="option">
                <strong>Savings Account</strong>
                <input
                  type="radio"
                  value={"emergency-fund"}
                  checked={savingsChoice === "emergency-fund"}
                  onChange={_selectSavingsChoice}
                />
                Use the money only if there's an emergency.
              </label>
            </div>
          </div>
          <button type="submit" className="btn btn-primary btn-lg">
            Start Next Month!
          </button>
        </form>
      </div>
    </div>
  );
};

export default MonthChoice;
