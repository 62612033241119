import React from "react";

export const Formats = ({ editSection, item, index }) => {
  return (
    <>
      <div className="snowball-edit-format-container">
        <div className="snowball-format-row">
          <table>
            <tr>
              <th>Range</th>
              <th>Value Type</th>
              <th>Color</th>
              <th>Background</th>
              <th>Font Weight</th>
            </tr>
            {item.formats
              ? item.formats.map((format, formatIndex) => {
                  return (
                    <tr className="snowball-formats-table-data-row">
                      <td>
                        <input
                          name="formatRange"
                          value={format.formatRange}
                          placeholder="Range"
                          onChange={(e) =>
                            editSection(
                              e,
                              index,
                              "snowball-spreadsheet",
                              formatIndex
                            )
                          }
                        />
                      </td>
                      <td>
                        <select
                          value={format.value}
                          name="value"
                          onChange={(e) =>
                            editSection(
                              e,
                              index,
                              "snowball-spreadsheet",
                              formatIndex
                            )
                          }
                        >
                          <option value="CURRENCY">CURRENCY</option>
                          <option value="TEXT">TEXT</option>
                          <option value="PERCENT">PERCENTAGE</option>
                        </select>
                      </td>
                      <td>
                        <select
                          value={format.fontColor}
                          name="fontColor"
                          onChange={(e) =>
                            editSection(
                              e,
                              index,
                              "snowball-spreadsheet",
                              formatIndex
                            )
                          }
                        >
                          <option value="#FFFFFF">WHITE</option>
                          <option value="#000000">BLACK</option>
                        </select>
                      </td>
                      <td>
                        <select
                          value={format.backgroundColor}
                          name="backgroundColor"
                          onChange={(e) =>
                            editSection(
                              e,
                              index,
                              "snowball-spreadsheet",
                              formatIndex
                            )
                          }
                        >
                          <option value="#FFFFFF">WHITE</option>
                          <option value="#efefef">LIGHTGREY</option>
                          <option value="#B7E1CD">GREEN</option>
                        </select>
                      </td>
                      <td>
                        <select
                          value={format.fontWeight}
                          name="fontWeight"
                          onChange={(e) =>
                            editSection(
                              e,
                              index,
                              "snowball-spreadsheet",
                              formatIndex
                            )
                          }
                        >
                          <option>NORMAL</option>
                          <option>BOLD</option>
                        </select>
                      </td>
                    </tr>
                  );
                })
              : null}
          </table>
        </div>
        <button
          name="add-format"
          type="button"
          onClick={(e) => editSection(e, index, "snowball-spreadsheet")}
        >
          Add format
        </button>
      </div>
    </>
  );
};
