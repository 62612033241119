import React from "react";
import Icon from "src/components/shared/Icon";
import "../index.css"

const ModulesLockedBanner = () => {
  return (
    <div className="modulesLockedBannerContainer">
      <div>
        <Icon name="lock"/>
        <div style={{fontWeight:'bold'}}>Content will unlock once you pay</div>
      </div>
    </div>
  );
};

export default ModulesLockedBanner;
