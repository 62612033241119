export const _lookForSheet = async (token, title, _startSetup) => {
  console.log("Does Sheet Exist?");
  const drive = `https://www.googleapis.com/drive/v3/files?q=name='${title}'&pageSize=1`;

  return fetch(drive, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then(async (info) => {
      if (info.files.length === 0) {
        // No file, create file with that name
        // Creates new sheet
        return _createSheet(token, title, _startSetup)
          .then(() => {
            console.log("sheetsFunctions.js | 14", " sheet created ");
          })
          .catch((error) => {
            console.log("sheetsFunctions.js 19 | error creating");
            return error;
          });
      } else {
        // Sheet in Drive, add it to session storage
        localStorage.setItem(
          "investingFileId",
          JSON.stringify(info.files[0].id)
        );
        _startSetup(info.files[0].id);
      }
    })
    .catch((err) => {
      console.log("sheetsFunctions.js 32 | error ", err);
      return err;
    });
};

export const _createSheet = async (token, title, _startSetup) => {
  // console.log("Create sheet");
  const sheets = "https://sheets.googleapis.com/v4/spreadsheets/";

  return fetch(sheets, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      properties: {
        title: title,
      },
      sheets: [
        {
          properties: {
            sheetId: 0,
            gridProperties: {
              // columnCount: 10,
              // rowCount: 20,
              hideGridlines: true,
            },
          },
        },
      ],
    }),
  })
    .then((response) => response.json())
    .then((info) => {
      // saves sheet ID in state
      console.log("Created spreadsheet", info);
      _startSetup(info.spreadsheetId);
    })
    .catch((err) => console.log(err));
};

// This is working
export const _checkSingleCell = async (token, id, range) => {
  console.log("Check single cell");
  const request = await fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/${range}?valueRenderOption=FORMULA`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = request.json();
  return data;
};

export const _clearSheetValues = async (token, sheetId, range) => {
  console.log("Clear sheet values");
  const request = await fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values:batchClear`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ranges: [range],
      }),
    }
  );

  const data = await request.json();
  return data;
};

export const _updateValuesRange = async (token, sheetId, range, values) => {
  const request = await fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values:batchUpdate`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        valueInputOption: "RAW",
        data: [
          {
            range: range, // String 'A1:B3'
            majorDimension: "ROWS",
            values: [
              values, //Array of values: [ "Ticker",  "Price", "Number of shares" ],
            ],
          },
        ],
      }),
    }
  );
  const data = await request.json();
  return data;
};

// updates current prices
export const _updateColumnRange = async (token, sheetId, range, values) => {
  console.log("Update column range");
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values:batchUpdate`;
  let body = {
    valueInputOption: "RAW",
    data: {
      range: range,
      majorDimension: "COLUMNS",
      values: [
        values, //must be column array [100, 200, '', 20]
      ],
    },
  };

  const request = await fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  const data = await request.json();
  return data;
};
//Price paid || Calculate total invested || calculate total returns
export const _updateInitialValues = (
  token,
  id,
  price,
  tickerRange,
  ticker,
  amountRange,
  amount,
  range,
  formulaValue,
  formulaRange,
  secondFormulaValue,
  secondFormulaRange,
  thirdValue,
  thirdRange,
  fourthValue,
  fourthRange,
  moduloIndex,
  currentPrice
) => {
  let whiteOrBlue = moduloIndex % 2 === 0 ? true : false;

  let backgroundColor;

  if (!whiteOrBlue) {
    backgroundColor = {
      red: 1,
      green: 1,
      blue: 1,
    };
  } else {
    backgroundColor = {
      red: 0.78,
      green: 0.85,
      blue: 0.97,
    };
  }

  let currentPriceRange = characterToRangeConverter(currentPrice);
  let currentTickerRange = characterToRangeConverter(tickerRange);
  let currentAmountRange = characterToRangeConverter(amountRange);
  let rangeObject = characterToRangeConverter(range);
  let formulaObject = characterToRangeConverter(formulaRange);
  let secondFormulaRangeObject = characterToRangeConverter(secondFormulaRange);
  let thirdFormulaRangeObject = characterToRangeConverter(thirdRange);
  let fourthFormulaRangeObject = characterToRangeConverter(fourthRange);
  // let totalRange = characterToRangeConverter('E12');
  // let totalProfitRange = characterToRangeConverter('F12');

  fetch(`https://sheets.googleapis.com/v4/spreadsheets/${id}:batchUpdate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      requests: [
        {
          //
          repeatCell: {
            range: currentTickerRange,
            cell: {
              userEnteredValue: {
                stringValue: ticker,
              },
              userEnteredFormat: {
                horizontalAlignment: "CENTER",
                backgroundColor: backgroundColor,
                numberFormat: {
                  type: "CURRENCY",
                },
                textFormat: {
                  foregroundColor: {
                    blue: 0,
                    red: 0,
                    green: 0,
                  },
                  bold: true,
                },
              },
            },
            fields: "*",
          },
        },
        {
          //
          repeatCell: {
            range: currentAmountRange,
            cell: {
              userEnteredValue: {
                numberValue: amount,
              },
              userEnteredFormat: {
                horizontalAlignment: "CENTER",
                backgroundColor: backgroundColor,
                // numberFormat:{
                //   type: "CURRENCY"
                // },
                textFormat: {
                  foregroundColor: {
                    blue: 0,
                    red: 0,
                    green: 0,
                  },
                  bold: true,
                },
              },
            },
            fields: "*",
          },
        },
        {
          //
          repeatCell: {
            range: rangeObject,
            cell: {
              userEnteredValue: {
                numberValue: price,
              },
              userEnteredFormat: {
                horizontalAlignment: "CENTER",
                backgroundColor: backgroundColor,
                numberFormat: {
                  type: "CURRENCY",
                },
                textFormat: {
                  foregroundColor: {
                    blue: 0,
                    red: 0,
                    green: 0,
                  },
                  bold: true,
                },
              },
            },
            fields: "*",
          },
        },
        {
          repeatCell: {
            range: formulaObject,
            cell: {
              userEnteredValue: {
                formulaValue: formulaValue,
              },
              userEnteredFormat: {
                horizontalAlignment: "CENTER",
                numberFormat: {
                  type: "CURRENCY",
                },
                backgroundColor: backgroundColor,
                textFormat: {
                  foregroundColor: {
                    blue: 0,
                    red: 0,
                    green: 0,
                  },
                  bold: true,
                },
              },
            },
            fields: "*",
          },
        },
        {
          repeatCell: {
            range: secondFormulaRangeObject,
            cell: {
              userEnteredValue: {
                formulaValue: secondFormulaValue,
              },
              userEnteredFormat: {
                horizontalAlignment: "CENTER",
                numberFormat: {
                  type: "CURRENCY",
                },
                backgroundColor: backgroundColor,
                textFormat: {
                  foregroundColor: {
                    blue: 0,
                    red: 0,
                    green: 0,
                  },
                  bold: true,
                },
              },
            },
            fields: "*",
          },
        },
        {
          repeatCell: {
            range: thirdFormulaRangeObject,
            cell: {
              userEnteredValue: {
                formulaValue: thirdValue,
              },
              userEnteredFormat: {
                horizontalAlignment: "CENTER",
                backgroundColor: backgroundColor,
                textFormat: {
                  foregroundColor: {
                    blue: 0,
                    red: 0,
                    green: 0,
                  },
                  bold: true,
                },
              },
            },
            fields: "*",
          },
        },
        {
          repeatCell: {
            range: fourthFormulaRangeObject,
            cell: {
              userEnteredValue: {
                formulaValue: fourthValue,
              },
              userEnteredFormat: {
                horizontalAlignment: "CENTER",
                backgroundColor: backgroundColor,
                textFormat: {
                  foregroundColor: {
                    blue: 0,
                    red: 0,
                    green: 0,
                  },
                  bold: true,
                },
              },
            },
            fields: "*",
          },
        },
        {
          repeatCell: {
            range: {
              startColumnIndex: 4,
              endColumnIndex: 5,
              startRowIndex: 11,
              endRowIndex: 12,
              sheetId: 0,
            },
            cell: {
              userEnteredValue: {
                formulaValue: "=SUM(E4:E11)",
              },
            },
            fields: "*",
          },
        },
        {
          repeatCell: {
            range: {
              startColumnIndex: 5,
              endColumnIndex: 6,
              startRowIndex: 11,
              endRowIndex: 12,
              sheetId: 0,
            },
            cell: {
              userEnteredValue: {
                formulaValue: "=SUM(F4:F11)",
              },
            },
            fields: "*",
          },
        },
        {
          repeatCell: {
            range: {
              startColumnIndex: 6,
              endColumnIndex: 7,
              startRowIndex: 11,
              endRowIndex: 12,
              sheetId: 0,
            },
            cell: {
              userEnteredValue: {
                formulaValue: "=SUM(G4:G11)",
              },
            },
            fields: "*",
          },
        },
        {
          repeatCell: {
            range: currentPriceRange,
            cell: {
              userEnteredFormat: {
                horizontalAlignment: "CENTER",
                backgroundColor: backgroundColor,
                textFormat: {
                  foregroundColor: {
                    blue: 0,
                    red: 0,
                    green: 0,
                  },
                  bold: true,
                },
              },
            },
            fields: "*",
          },
        },
      ],
    }),
  });
};

export const _formatRange = (token, sheetId, range) => {
  let myRange = characterToRangeConverter(range);
  const repeatCell = {
    range: myRange,
    cell: {
      userEnteredFormat: {
        horizontalAlignment: "CENTER",
        numberFormat: {
          type: "CURRENCY",
        },
        // backgroundColor: {
        //   red: 0.16,
        //   green: 0.61,
        //   blue: 0.95
        // },
        textFormat: {
          foregroundColor: {
            blue: 1,
            red: 1,
            green: 1,
          },
          bold: true,
        },
      },
    },
    fields: "*",
  };

  fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}:batchUpdate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        requests: [
          {
            repeatCell,
          },
        ],
      }),
    }
  );
};

export const _formatPrices = (token, sheetId) => {
  console.log("Format prices");

  const pricesRange = {
    startColumnIndex: 2,
    endColumnIndex: 6,
    startRowIndex: 3,
    endRowIndex: 11,
    sheetId: 0,
  };

  const repeatCell = {
    range: pricesRange,
    cell: {
      userEnteredFormat: {
        horizontalAlignment: "CENTER",
        numberFormat: {
          type: "CURRENCY",
        },
        backgroundColor: {
          red: 0.16,
          green: 0.61,
          blue: 0.95,
        },
        textFormat: {
          foregroundColor: {
            blue: 1,
            red: 1,
            green: 1,
          },
          bold: true,
        },
      },
    },
    fields: "*",
  };

  fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}:batchUpdate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        requests: [
          {
            repeatCell,
          },
        ],
      }),
    }
  );
};

// updates tutorial values
export const _batchUpdateRange = async (
  token,
  sheetId,
  range,
  value,
  color
) => {
  console.log("updating range", range, value);

  let currentColor = {
    red: 1,
    green: 1,
    blue: 1,
    alpha: 1,
  };

  let foregroundColor = {
    red: 0,
    green: 0,
    blue: 0,
    alpha: 0,
  };

  if (color) {
    if (color === "red") {
      currentColor = {
        red: 1,
        green: 0,
        blue: 0,
        alpha: 1,
      };
      foregroundColor = {
        red: 1,
        green: 1,
        blue: 1,
        alpha: 1,
      };
    } else if (color === "green") {
      currentColor = {
        red: 0.52,
        green: 0.96,
        blue: 0.62,
        alpha: 1,
      };
    }
  }

  const currentRange = characterToRangeConverter(range);
  console.log("Range to update", currentRange);
  fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}:batchUpdate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        requests: [
          {
            updateCells: {
              range: currentRange,
              fields: "*",
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        numberValue: value,
                      },
                      userEnteredFormat: {
                        numberFormat: {
                          type: "CURRENCY",
                        },
                        backgroundColor: currentColor,
                        textFormat: {
                          foregroundColor: foregroundColor,
                        },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      }),
    }
  );
};

// updates tutorial values
export const _batchUpdateSingleValue = async (token, sheetId, range, value) => {
  console.log(
    "sheetsFunctions.js 665 | batch updating single value",
    sheetId,
    range,
    value
  );
  const currentRange = characterToRangeConverter(range);
  try {
    const request = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}:batchUpdate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          requests: [
            {
              updateCells: {
                range: currentRange,
                fields: "*",
                rows: [
                  {
                    values: [
                      {
                        userEnteredValue: {
                          numberValue: value,
                        },
                        userEnteredFormat: {
                          textFormat: {
                            foregroundColor: {
                              blue: 0,
                              red: 0,
                              green: 0,
                            },
                            bold: true,
                          },
                          horizontalAlignment: "CENTER",
                          // numberFormat:{
                          //   type: "CURRENCY"
                          // },
                        },
                      },
                    ],
                  },
                ],
              },
            },
          ],
        }),
      }
    );
    console.log("sheetsFunctions.js 718 | request successfull", request);
    const data = await request.json();
    console.log("sheetsFunctions.js 720 | data", data);
  } catch (error) {
    console.log("sheetsFunctions.js 714 | error batch updating", error);
  }
};

// Takes a string range 'A1' or 'A1:B2' returns a number range object
const characterToRangeConverter = (range) => {
  // console.log("range", range);
  // console.log("Converting single range");
  let letter = range[0];
  let letterToNumber = letter.charCodeAt(0) - 65; //gives 0 for A, 1 for B
  let number = parseInt(range[1]);

  if (range.length < 5) {
    //checking for single range e.g A3

    let checkForSplit = range.split(":");
    // console.log("checkForSplit", checkForSplit);
    if (checkForSplit[0].length === 3) {
      // A11
      let firstLetter = checkForSplit[0][0];
      letterToNumber = firstLetter.charCodeAt(0) - 65;

      number = parseInt(checkForSplit[0].slice(1));
    }

    return {
      //number range object returned for google sheet
      startColumnIndex: letterToNumber,
      endColumnIndex: letterToNumber + 1,
      startRowIndex: number - 1,
      endRowIndex: number,
      sheetId: 0,
    };
  } else {
    //checking for multiple range e.g A1:B11
    // console.log("More than 3!", range);

    let splitRange = range.split(":");
    console.log(splitRange);

    let firstLetter = splitRange[0][0];
    let colStart = firstLetter.charCodeAt(0) - 65;

    let secondLetter = splitRange[1][0]; //gets the second first letter
    let colEnd = secondLetter.charCodeAt(0) - 65; //gives 0 for A, 1 for B

    let rowStart = parseInt(splitRange[0].slice(1));

    let rowEnd = parseInt(splitRange[1].slice(1));

    let secondNumber = range[4];

    return {
      startColumnIndex: colStart,
      endColumnIndex: colEnd,
      startRowIndex: rowStart,
      endRowIndex: rowEnd,
      sheetId: 0,
    };
  }
};

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};
