import React, { useState } from "react";
import Icon from "src/components/shared/Icon";

export const QuizOption = ({
  option,
  itemAnswer,
  checkQuizAnswer,
  toggleQuizAnswered,
  quizAnswered,
  category,
}) => {
  const [optionClicked, triggerOptionClicked] = useState(false);
  return (
    <p
      className="quizoption__container"
      onClick={() => {
        if (!quizAnswered) {
          triggerOptionClicked(!optionClicked); //pure function that shows correct quiz answer
          toggleQuizAnswered(true);
          checkQuizAnswer(option, itemAnswer, category); //This is what I need to revise to make this work
        } else {
          return;
        }
      }}
    >
      <span className="quizoptionicon__container">
        {!optionClicked ? (
          <Icon name="quizoption-default" width={24} height={24} />
        ) : optionClicked && option === itemAnswer ? (
          <Icon name="quizoption-success" width={24} height={24} />
        ) : (
          <Icon name="quizoption-error" width={24} height={24} />
        )}
      </span>
      {option}
    </p>
  );
};
