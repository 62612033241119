const initialState = {
  issuesModalVisible: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "feedback/toggleIssuesModal":
      return { ...state, issuesModalVisible: payload };

    default:
      return state;
  }
};
