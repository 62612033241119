import React from 'react'
import Cell from '../Cell.js'



const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  specialCharacter: {
    fontWeight: 'bold',
    color: 'orange',
  },
}

export const taxesSteps = [
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>
        We'll run you through a similar example as the previous interactive activity.
        It is the end of the year and you are filing your taxes. You want to know how much taxes that you may get
        back in your refund. For this exercise, you will be assumed a Floridian, therefore no state
        taxes! Type <span style={styles.specialCharacter}>Tax with credits</span> on cell{' '}
        <span style={styles.specialCharacter}>B12</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <Cell cellName={'B2'} onTextChange={setCellText} />
      </div>
    ),
    answer: 'tax with credits',
    range: 'A4',
  },
  {
    title: 'Tax with Credits',
    text: <span style={{ fontSize: 16 }}>You'll calculate taxes yourself now. Start by typing <span style={styles.specialCharacter}>Income</span> on cell <span style={styles.specialCharacter}>B3</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: 'income',
    range: 'B3',
  },
  {
    title: 'Tax with Credits',
    text: <span style={{ fontSize: 16 }}>Your income is $55,000. Type <span style={styles.specialCharacter}>55000</span> on cell <span style={styles.specialCharacter}>C3</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: '55000',
    range: 'C3',
  },
  {
    title: 'Tax with Credits',
    text: <span style={{ fontSize: 16 }}>Now you'll calculate deductions. First, type <span style={styles.specialCharacter}>Deductions</span> on cell <span style={styles.specialCharacter}>B4</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: 'deductions',
    range: 'B4',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>
        This year you weren't feeling so generous, so you did not donate any money to charity.
        Type <span style={styles.specialCharacter}> 0 </span>on cell <span style={styles.specialCharacter}>C4</span>.{' '}
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: '0',
    range: 'C4',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>Great job! Now calculate your taxable income. Type <span style={styles.specialCharacter}>Taxable Income</span> on cell <span style={styles.specialCharacter}>B5</span></span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: 'taxable income',
    range: 'B5',
  },
  {
    title: 'Tax with Credits',
    text: <span style={{ fontSize: 16 }}>Now, subtract your Deductions from your Income. </span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: '55000',
    range: 'C5',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>
        Now you'll use the chart below to calculate your Income Taxes. Let's start with the first
        bracket. Type <span style={styles.specialCharacter}>Taxable at 10%</span> on cell <span style={styles.specialCharacter}>B7</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: 'taxable at 10%',
    range: 'B7',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>
        Now you'll input the first bracket for your total taxable income. Type <span style={styles.specialCharacter}>9950</span> on cell <span style={styles.specialCharacter}>C7</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: '9950',
    range: 'C7',
  },
  {
    title: 'Tax with Credits',
    text: <span style={{ fontSize: 16 }}>Great! Now you'll calculate the next bracket. Type <span style={styles.specialCharacter}>Taxable at 12% </span>on cell <span style={styles.specialCharacter}>B8</span></span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: 'taxable at 12%',
    range: 'B8',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>
        As you can see in the image below, income 9951-40525 is taxed at 12%. We have already
        identified the first portion of our income at 10%. Let's identify the rest by subtracting
        the income that is taxable at 12%. Type <span style={styles.specialCharacter}>30575</span> on cell <span style={styles.specialCharacter}>C8</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell
            cellName={'B8'}
            value={'Taxable at 12%'}
            editable={false}
            onTextChange={setCellText}
          />
          <Cell cellName={'C8'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: '30575',
    range: 'C8',
  },
  {
    title: 'Tax with Credits',
    text: <span style={{ fontSize: 16 }}>Now we'll get to the next bracket. Type <span style={styles.specialCharacter}>Taxable at 22% </span>on cell <span style={styles.specialCharacter}>B9</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: 'taxable at 22%',
    range: 'B9',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>
        We've already taxed $9,950 at 10%, $30,575 at 12% and $24,425 left in income to tax at the
        22% rate. We do not reach any higher tax bracket. Type <span style={styles.specialCharacter}>24425</span> on cell <span style={styles.specialCharacter}>C9</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} value={'Taxable at 22%'} editable={false} />
          <Cell cellName={'C9'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: '24425',
    range: 'C9',
  },
  {
    title: 'Tax with Credits',
    text: <span style={{ fontSize: 16 }}>Now we'll calculate your taxes due. Type <span style={styles.specialCharacter}>Taxes Due at 10%</span> on cell <span style={styles.specialCharacter}>B11</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} value={'Taxable at 22%'} editable={false} />
          <Cell cellName={'C9'} value={'$24,425'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B11'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: 'taxes due at 10%',
    range: 'B11',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>
        Time to calculate total taxes due for the 10% tax bracket. Multiply your taxable income at
        10% by 10% on cell <span style={styles.specialCharacter}>C11</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} value={'Taxable at 22%'} editable={false} />
          <Cell cellName={'C9'} value={'$24,425'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B11'} value={'Taxes Due at 10%'} editable={false} />
          <Cell cellName={'C11'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: '995',
    range: 'C11',
  },
  {
    title: 'Tax with Credits',
    text: <span style={{ fontSize: 16 }}>Type <span style={styles.specialCharacter}>Taxes Due at 12%</span> on cell <span style={styles.specialCharacter}>B12</span> for the next bracket.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} value={'Taxable at 22%'} editable={false} />
          <Cell cellName={'C9'} value={'$24,425'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B11'} value={'Taxes Due at 10%'} editable={false} />
          <Cell cellName={'C11'} value={'$995'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B12'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: 'taxes due at 12%',
    range: 'B12',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>
        Time to calculate total taxes due for the 12% tax bracket. Multiply your taxable income at
        12% by 12% on cell<span style={styles.specialCharacter}> C12</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} value={'Taxable at 22%'} editable={false} />
          <Cell cellName={'C9'} value={'$24,425'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B11'} value={'Taxes Due at 10%'} editable={false} />
          <Cell cellName={'C11'} value={'$995'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B12'} value={'Taxes Due at 12%'} editable={false} />
          <Cell cellName={'C12'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: '3669',
    range: 'C12',
  },
  {
    title: 'Tax with Credits',
    text: <span style={{ fontSize: 16 }}>Type <span style={styles.specialCharacter}>Taxes Due at 22%</span> on cell <span style={styles.specialCharacter}>B13</span> for the next bracket.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} value={'Taxable at 22%'} editable={false} />
          <Cell cellName={'C9'} value={'$24,425'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B11'} value={'Taxes Due at 10%'} editable={false} />
          <Cell cellName={'C11'} value={'$995'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B12'} value={'Taxes Due at 12%'} editable={false} />
          <Cell cellName={'C12'} value={'$3,669'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B13'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: 'taxes due at 22%',
    range: 'B13',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>
        Time to calculate total taxes due for the 22% tax bracket. Multiply your taxable income at
        22% by 22% on cell <span style={styles.specialCharacter}>C13</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} value={'Taxable at 22%'} editable={false} />
          <Cell cellName={'C9'} value={'$24,425'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B11'} value={'Taxes Due at 10%'} editable={false} />
          <Cell cellName={'C11'} value={'$995'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B12'} value={'Taxes Due at 12%'} editable={false} />
          <Cell cellName={'C12'} value={'$3,669'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B13'} value={'Taxes Due at 22%'} editable={false} />
          <Cell cellName={'C13'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: '5373.5',
    range: 'C13',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>Now let's calculate Taxes Before Credits. Type <span style={styles.specialCharacter}>Taxes Before Credits</span> on cell <span style={styles.specialCharacter}>B15</span>.</span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} value={'Taxable at 22%'} editable={false} />
          <Cell cellName={'C9'} value={'$24,425'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B11'} value={'Taxes Due at 10%'} editable={false} />
          <Cell cellName={'C11'} value={'$995'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B12'} value={'Taxes Due at 12%'} editable={false} />
          <Cell cellName={'C12'} value={'$3,669'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B13'} value={'Taxes Due at 22%'} editable={false} />
          <Cell cellName={'C13'} value={'$5,373.5'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B15'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: 'taxes before credits',
    range: 'B15',
  },
  {
    title: 'Tax with Credits',
    text: <span style={{ fontSize: 16 }}>Now we'll sum all our taxes due before credits on cell <span style={styles.specialCharacter}>C15</span>.</span>,
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} value={'Taxable at 22%'} editable={false} />
          <Cell cellName={'C9'} value={'$24,425'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B11'} value={'Taxes Due at 10%'} editable={false} />
          <Cell cellName={'C11'} value={'$995'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B12'} value={'Taxes Due at 12%'} editable={false} />
          <Cell cellName={'C12'} value={'$3,669'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B13'} value={'Taxes Due at 22%'} editable={false} />
          <Cell cellName={'C13'} value={'$5,373.5'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B15'} value={'Taxes Before Credits'} editable={false} />
          <Cell cellName={'C15'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: '10037.5',
    range: 'C15',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>
        "Now we'll add your tax credits. You decided to go to college and you earned a tax credit
        for pursuing a higher education. Type <span style={styles.specialCharacter}>Tax Credit</span> on cell <span style={styles.specialCharacter}>B16</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} value={'Taxable at 22%'} editable={false} />
          <Cell cellName={'C9'} value={'$24,425'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B11'} value={'Taxes Due at 10%'} editable={false} />
          <Cell cellName={'C11'} value={'$995'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B12'} value={'Taxes Due at 12%'} editable={false} />
          <Cell cellName={'C12'} value={'$3,669'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B13'} value={'Taxes Due at 22%'} editable={false} />
          <Cell cellName={'C13'} value={'$5,373.5'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B15'} value={'Taxes Before Credits'} editable={false} />
          <Cell cellName={'C15'} value={'$10,037.5'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B16'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: 'tax credit',
    range: 'B16',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>
        "For pursuing a higher education you earned a Tax Credit of $1500. Keep in mind, tax credits
        reduce the taxes that you owe dollar for dollar. Unlike tax deductions that reduce the
        amount of income that you are taxed on. Type <span style={styles.specialCharacter}>1500</span> on cell <span style={styles.specialCharacter}>C16</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} value={'Taxable at 22%'} editable={false} />
          <Cell cellName={'C9'} value={'$24,425'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B11'} value={'Taxes Due at 10%'} editable={false} />
          <Cell cellName={'C11'} value={'$995'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B12'} value={'Taxes Due at 12%'} editable={false} />
          <Cell cellName={'C12'} value={'$3,669'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B13'} value={'Taxes Due at 22%'} editable={false} />
          <Cell cellName={'C13'} value={'$5,373.5'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B15'} value={'Taxes Before Credits'} editable={false} />
          <Cell cellName={'C15'} value={'$10,037.5'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B16'} value={'Tax Credit'} editable={false} />
          <Cell cellName={'C16'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: '1500',
    range: 'C16',
  },
  {
    title: 'Tax with Credits',
    text: (
      <span style={{ fontSize: 16 }}>
        Now finally calculate total taxes. Subtract Tax Credits from Taxes Before Credits on cell
        <span style={styles.specialCharacter}> C17</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B2'} value={'Tax with credits'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B3'} value={'Income'} editable={false} />
          <Cell cellName={'C3'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B4'} value={'Deductions'} editable={false} />
          <Cell cellName={'C4'} value={'0'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B5'} value={'Taxable Income'} editable={false} />
          <Cell cellName={'C5'} value={'$55,000'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B7'} value={'Taxable at 10%'} editable={false} />
          <Cell cellName={'C7'} value={'$9,950'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B8'} value={'Taxable at 12%'} editable={false} />
          <Cell cellName={'C8'} value={'$30,575'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B9'} value={'Taxable at 22%'} editable={false} />
          <Cell cellName={'C9'} value={'$24,425'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B11'} value={'Taxes Due at 10%'} editable={false} />
          <Cell cellName={'C11'} value={'$995'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B12'} value={'Taxes Due at 12%'} editable={false} />
          <Cell cellName={'C12'} value={'$3,669'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B13'} value={'Taxes Due at 22%'} editable={false} />
          <Cell cellName={'C13'} value={'$5,373.5'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B15'} value={'Taxes Before Credits'} editable={false} />
          <Cell cellName={'C15'} value={'$10,037.5'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Cell cellName={'B16'} value={'Tax Credit'} editable={false} />
          <Cell cellName={'C16'} value={'$1,500'} editable={false} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginLeft: 120 }}>
          <Cell cellName={'B17'} value={'Total Due'} editable={false} />
          <Cell cellName={'C17'} onTextChange={setCellText} />
        </div>
      </div>),
    answer: '8537.5',
    range: 'C17',
  },
  {
    title: 'Congratulations!',
    finished: true,
    text: <span style={{ fontSize: 16 }}>You finished the taxes interactive lesson!</span>,
    get hint() {
      return `Type ${this.answer} on ${this.range}.`
    },
  },
]
