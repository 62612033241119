import React from "react";

const AlertModal = (props) => {
  return (
    <div className="budget-alert">
      <h4>ALERT:</h4>
      {props.choiceBudgetAlert}
      <button className="alert-close" onClick={props._hideAlertModal}>
        X
      </button>
    </div>
  );
};

export default AlertModal;
