import React from "react";
import "./index.css";
import SwitchItemOrderButtons from "../shared/SwitchItemOrderButtons";
import ReactQuill, { Quill } from "react-quill";
import firebase from "firebase/app";
import ImageUploader from "quill-image-uploader";
import Compressor from "compressorjs";
import { toast } from "react-toastify";

Quill.register("modules/imageUploader", ImageUploader);

const uploadAndGetFileUrl = async (imageFile) => {
  // Send the compressed image file to server with XMLHttpRequest.
  return firebase
    .storage()
    .ref(`files/${imageFile.name}`)
    .put(imageFile)
    .then(() => {
      console.log("Text 27 | uploaded successfully");
      return firebase
        .storage()
        .ref(`files/${imageFile.name}`)
        .getDownloadURL()
        .then((url) => {
          console.log("Text 31 | got url", url);
          return url;
        })
        .catch((error) => {
          console.log("Text 54 | ", "ERROR", error);
          throw new Error("Unable to get file url", error.message);
        });
    })
    .catch((error) => {
      throw new Error("Unable to get file url", error.message);
    });
};

const modules = {
  toolbar: [
    ["image"], // toggled buttons
  ],
  imageUploader: {
    upload: (file) => {
      // upload to firebase
      // resolve the image file url
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.8,
          success(result) {
            console.log("Text 59 | handling image upload");
            const formData = new FormData();
            formData.append("image", result);
            uploadAndGetFileUrl(result)
              .then((url) => {
                resolve(url);
              })
              .catch((error) => {
                console.log("Text 57 | ", "ERROR", error);
                toast("Issue with image upload, contact support.");
                reject(error.message);
              });
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
  },
};

export const DragDropEdit = ({
  editSection,
  item,
  index,
  removeContentAtIndex,
  _moveSectionContentDown,
  _moveSectionContentUp,
}) => {
  console.log("DragDropEdit 79 | drag drop item", item);
  return (
    <>
      <h3>Drag and Drop</h3>
      <div className="dnd-edit-word-container">
        {item.wordsAndDefs.map((wordAndDef, optionIndex) => (
          <div className="quiz-edit-question" key={optionIndex}>
            <div className="" key={index}>
              <h5>Word {optionIndex + 1}</h5>
              <ReactQuill
                value={wordAndDef.word}
                placeholder="Enter text here or paste image here..."
                name="option"
                theme={"snow"}
                style={{
                  height: 170,
                  width: 200,
                  marginRight: 20,
                }}
                onChange={(e) =>
                  editSection(e, index, "drag-drop", optionIndex, null, "word")
                }
                modules={modules}
              />
            </div>
            <div className="dnd-edit-definition-container">
              <h5>Definition {optionIndex + 1}</h5>
              <ReactQuill
                value={wordAndDef.definition}
                placeholder="Enter text or paste image here..."
                onChange={(e) =>
                  editSection(
                    e,
                    index,
                    "drag-drop",
                    optionIndex,
                    null,
                    "definition"
                  )
                }
                theme={"snow"}
                style={{
                  height: 170,
                  width: 200,
                  marginRight: 20,
                }}
                modules={modules}
              />
            </div>
          </div>
        ))}
        <br />
        <button
          className="btn btn-danger"
          onClick={(e) => removeContentAtIndex(e, index)}
        >
          Delete Drag-Drop
        </button>
        <SwitchItemOrderButtons
          index={index}
          _moveSectionContentDown={_moveSectionContentDown}
          _moveSectionContentUp={_moveSectionContentUp}
        />
      </div>
    </>
  );
};
