import React from "react";

const GoogleSheet = ({ userFileId }) => {
  return (
    <iframe
      title="sheet"
      width="100%"
      height="100%"
      src={`https://docs.google.com/spreadsheets/d/${userFileId}/view?rm=minimal&embedded=true`}
      frameBorder="0"
    ></iframe>
  );
};

export default GoogleSheet;
