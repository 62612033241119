import React, { useContext, useEffect } from "react";
import "./index.css";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import ClassroomsList from "./components/ClassroomsList";
import ChatsList from "./components/ChatsList";
import CurrentChat from "./components/CurrentChat";
import { setLocalStorageData } from "src/utils/localStorageUtils";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatroomSuccess,
  fetchSelectedClassChatroom,
  fetchAllClassrooms,
} from "src/store/chats/chatActions";

const ClassroomChats = ({ firebase }) => {
  const dispatch = useDispatch();

  const authUser = useContext(AuthUserContext);
  const classrooms = useSelector((state) => state.chats.classrooms);
  const { currentClassroomId, currentChatId } = useSelector(
    (state) => state.chats
  );

  // Fetches classrooms for Admins and Mentors only
  useEffect(() => {
    if (authUser?.isAdmin || authUser?.isMentor) {
      dispatch(fetchAllClassrooms(firebase));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchSelectedClassChatroom(firebase, currentClassroomId));
  }, [currentClassroomId]);

  // Real Time Listener
  useEffect(() => {
    const currentSelectedClassroomRef = firebase.chatroom(currentClassroomId);
    const classCacheId = `classChatroom_${currentClassroomId}`;

    const unsubscribe = currentSelectedClassroomRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        const { rooms, users } = snapshot.val();
        rooms && dispatch(fetchChatroomSuccess(rooms, users));
        users && dispatch(fetchChatroomSuccess(rooms, users));
        setLocalStorageData(classCacheId, {
          rooms,
          users,
        });
      } else {
        console.log("Chat 18 | no data from chatroom");
      }
    });
    return () => currentSelectedClassroomRef.off("value", unsubscribe);
  }, []);

  if (!classrooms) return <div>Loading...</div>;

  return (
    <div className="classroomchats__container">
      <ClassroomsList />
      <ChatsList />
      <CurrentChat
        chatId={currentChatId}
        classId={currentClassroomId}
        firebase={firebase}
        userId={authUser.uid}
      />
    </div>
  );
};

export default withFirebase(ClassroomChats);
