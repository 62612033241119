import React from "react";
import "./GoalModal.css";

const GoalModal = (props) => {
  const { goalModalVisible } = props;
  const startButton = props.sheetId ? (
    <button onClick={props._startGame} className="start-goal-modal">
      Start!
    </button>
  ) : null;

  if (goalModalVisible) {
    return (
      <div className="goal-modal">
        <div className="modal-contents">
          <h3>
            <strong>Goal</strong>
          </h3>
          <h3>
            Get to <strong>$2500</strong> by investing
          </h3>
          {startButton}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default GoalModal;
