import React from "react";

import "./BudgetingGamePickLifestyle.css";
import Button from "src/components/shared/Button";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "src/utils/utils";

const LifestyleExpensesTable = ({ state, submitExpenses }) => {
  const availableBalance = Object.values(state.expenses).reduce(
    (total, expense) => {
      return total + (expense.cost || 0); // Use 0 if the expense is null
    },
    0
  );

  const checkIncomeHigherThanExpenses = () => {
    return state.job.salary - availableBalance >= 0;
  };

  function checkExpenses(expenses) {
    for (const category in expenses) {
      if (!expenses[category].name || expenses[category].cost === null) {
        return false;
      }
    }
    return true;
  }

  return (
    <div className="lifestyleexpensestable">
      <div className="welcome-back-talha">Lifestyle Expenses</div>

      <div className="frame-parent">
        <div className="income-parent">
          <div className="income">Income</div>
          <div className="intern-in-startup-0-parent">
            <div className="intern-in-startup">
              {state.job?.name || "Not selected"}
            </div>
            <div className="salary-div">$ {state.job?.salary}</div>
          </div>
        </div>
        {state.expenses &&
          Object.entries(state.expenses).map((expense, index) => {
            const category = expense[0];
            const name = expense[1]?.name;
            const total = expense[1]?.cost;
            return (
              <div className="income-parent" key={index}>
                <div className="income">{capitalizeFirstLetter(category)}</div>
                <div className="intern-in-startup-0-parent">
                  <div className="intern-in-startup">
                    {capitalizeFirstLetter(name) || "Not Selected"}
                  </div>
                  <div className="salary-div">$ {total}</div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="income-parent">
        <div className="income">Left to spend</div>
        <div className="intern-in-startup-0-parent">
          <div className="intern-in-startup">Total</div>
          <div className="salary-div">
            ${state.job?.salary - availableBalance || 0}
          </div>
        </div>
      </div>
      <Button
        title="Start Game"
        onClick={() => {
          // check if income and expenses all have a value
          const expensesChosen = checkExpenses(state.expenses);
          if (expensesChosen) {
            // move forward
            console.log("LifestyleExpensesTable.js 71 | submitting expenses");
            if (checkIncomeHigherThanExpenses()) {
              submitExpenses();
            } else {
              toast.error("Your fixed expenses are higher than your income!");
            }
          } else {
            toast.error("Make sure you've selected all your expenses");
          }
        }}
      />
    </div>
  );
};

export default LifestyleExpensesTable;
