import React, { useState, useEffect } from "react";
import ProgressBar from "../ProgressBar/ProgressBar";
// import ResultsButton from '../buttons/ResultsButton';
import ContinueButton from "../buttons/ContinueButton";
import InsuranceSurprise from "../InsuranceSurprise/InsuranceSurprise";
import CarSurprise from "../CarSurprise/CarSurprise";
import BudgetTips from "../BudgetTips/BudgetTips";
import "./Choices.css";
import "../buttons/buttons.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Icon from "antd/es/icon";

const numBudgetCategory = (category) => {
  if (category === "Housing") return 0;
  if (category === "Food") return 1;
  if (category === "Transportation") return 2;
  if (category === "Fun") return 3;
  if (category === "Insurance") return 4;
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
// Displays buttons dinamically
// Loops through decision making object
const Choices = (props) => {
  const months = props.months;
  const events = months[props.monthNum].events;
  const { selectedOptions } = props;

  const [animatingSmile, setAnimatingSmile] = useState(false);

  // Creates buttons for choices of current month and current event
  if (events[props.eventNum]) {
    const choiceButtons = events[props.eventNum].choices.map(
      (choice, index) => {
        const category =
          props.months[props.monthNum].events[props.eventNum].category.name;
        const amount = choice.amount;
        const happiness = choice.happiness;

        const handleChoice = () => {
          props._startEvent(amount, happiness, category);
          setAnimatingSmile(true);
        };

        let happyPoints = "";

        switch (happiness) {
          case -3:
            happyPoints = "☹️ ☹️ ☹️";
            break;
          case -2:
            happyPoints = "☹️ ☹️";
            break;
          case -1:
            happyPoints = "☹️";
            break;
          case 1:
            happyPoints = "🙂";
            break;
          case 2:
            happyPoints = "🙂 🙂";
            break;
          case 3:
            happyPoints = "🙂 🙂 🙂";
            break;
          default:
            happyPoints = "😐";
        }

        const iconType = () => {
          if (category === "Housing") return "home";
          if (category === "Food") return "apple";
          if (category === "Transportation") return "car";
          if (category === "Fun") return "smile";
          if (category === "Insurance") return "heart";
        };

        return (
          <button
            key={index}
            onClick={handleChoice}
            className={`choice-button`}
          >
            <div className="choice-action">
              <p>{choice.action}</p>
            </div>
            <div className="single-choice-container">
              <p className="choice-amount">{`$${choice.amount}`}</p>
              <p className="choice-category">
                <Icon style={{ fontWeight: "bold" }} type={iconType()} />
              </p>
              <p className="happy-amount">{happyPoints}</p>
            </div>
          </button>
        );
      }
    );

    if (props.continueButtonVisible) {
      return (
        <div className="button-container">
          <ContinueButton _startMonthReview={props._startMonthReview} />
        </div>
      );
    } else if (props.choiceButtonsVisible) {
      const category =
        months[props.monthNum].events[props.eventNum].category.name;
      const categorySpentAmount =
        props.categories[
          capitalizeFirstLetter(
            months[props.monthNum].events[props.eventNum].category.name
          )
        ].spentAmount;
      const leftToSpend =
        props.budgetRows[numBudgetCategory(category)] - categorySpentAmount;

      const categoryBudgetedAmount =
        props.budgetRows[
          numBudgetCategory(
            months[props.monthNum].events[props.eventNum].category.name
          )
        ];

      return (
        <div>
          <ProgressBar
            eventNum={props.eventNum}
            allEvents={events.length}
            monthNum={props.monthNum}
            months={months}
          />

          <TransitionGroup>
            <CSSTransition
              key={Math.random(10)}
              timeout={1000}
              classNames="fade"
              exit={false}
            >
              <div>
                <p className="event-title">
                  {months[props.monthNum].events[props.eventNum].situation}
                </p>

                <div className="choice-container">
                  <div className="choices">{choiceButtons}</div>
                </div>
                <p className="decision-comparison">
                  Budgeted Amount for {category} is $ {categoryBudgetedAmount}
                  <br />
                  {leftToSpend > 0 ? (
                    <p className="under-budget">
                      You have{" "}
                      <span className="left-to-spend-amount">
                        ${leftToSpend}
                      </span>{" "}
                      left to spend this month.
                    </p>
                  ) : (
                    <p className="over-budget">
                      You are OVERBUDGET by ${leftToSpend}
                    </p>
                  )}
                </p>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      );
    } else if (props.insuranceSurpriseVisible) {
      return (
        <div className="button-container">
          <InsuranceSurprise
            insurancePlan={props.insurancePlan}
            _startEvent={props._startEvent}
          />
        </div>
      );
    } else if (props.carSurpriseVisible) {
      return (
        <div className="button-container">
          <CarSurprise _startEvent={props._startEvent} />
        </div>
      );
    } else {
      return (
        <div className="button-container">
          <BudgetTips
            _getSheetBudget={props._getSheetBudget}
            choiceBudgetAlert={props.choiceBudgetAlert}
            monthNum={props.monthNum}
            userName={props.userName}
            selectedOptions={selectedOptions}
          />
        </div>
      );
    }
  } else {
    return null;
  }
};

export default Choices;
