export const questQuestions = {
  demographicInfo: {
    Name: "Ignacio",
    Surname: "Youth Test Monday",
    Email: "ignacio@test.com",
    Age: 24,
    Gender: "M",
    Ethnicity: "test",
    PrivacyAuthorization: true,
  },
  Items: [
    {
      Number: 1,
      ExternalId: 59,
      Text: "The purpose of this assessment is …",
      FreeText: "",
    },
    {
      Number: 2,
      ExternalId: 60,
      Text: "Emotional intelligence is …",
      FreeText: "",
    },
    {
      Number: 3,
      ExternalId: 61,
      Text: "Emotional intelligence is important because",
      FreeText: "",
    },
    {
      // 1
      Number: 1,
      ExternalId: 1,
      Text: "I solve my fights with friends.",
      Answer: 0,
    },
    {
      // 2
      Number: 1,
      ExternalId: 2,
      Text: "I am good.",
      Answer: 0,
    },
    {
      // 3
      Number: 1,
      ExternalId: 3,
      Text: "I understand what makes me happy",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 4,
      Text: "I share my feelings with others.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 5,
      Text: "I change my behavior when I need to.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 6,
      Text: "I do as I am told.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 7,
      Text: "I listen carefully to my friends.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 8,
      Text: "I offer to help.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 9,
      Text: "I do what is right.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 10,
      Text: "I can change a bad habit.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 11,
      Text: "I share my things to help people feel better.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 12,
      Text: "I have lots of words to describe how I feel.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 13,
      Text: "I can tell by people's faces how they are feeling.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 14,
      Text: "I can calm down an angry friend.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 15,
      Text: "I do things without thinking.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 16,
      Text: "I can think of many ways to solve my problems.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 17,
      Text: "I choose what I will do next.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 18,
      Text: "I know what happiness feels like.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 19,
      Text: "I build good habits.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 20,
      Text: "Before I decide, I think about what would be good or bad.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 21,
      Text: "I get good grades.",
      Answer: 0,
    },
    {
      // 4
      Number: 1,
      ExternalId: 22,
      Text: "I can help with world problems.",
      Answer: 0,
    },
    {
      // 5
      Number: 1,
      ExternalId: 23,
      Text: "I am bad.",
      Answer: 0,
    },
    {
      // 6
      Number: 1,
      ExternalId: 24,
      Text: "I solve my problems.",
      Answer: 0,
    },
    {
      // 10
      Number: 1,
      ExternalId: 25,
      Text: "I can cheer myself up.",
      Answer: 0,
    },
    {
      // 11
      Number: 1,
      ExternalId: 26,
      Text: "Before I do something, I think of how my family will feel.",
      Answer: 0,
    },
    {
      // 12
      Number: 1,
      ExternalId: 27,
      Text: "I work well with others.",
      Answer: 0,
    },
    {
      // 16
      Number: 1,
      ExternalId: 28,
      Text: "I know when I need help.",
      Answer: 0,
    },
    {
      // 17
      Number: 1,
      ExternalId: 29,
      Text: "My friends look up to me.",
      Answer: 0,
    },
    {
      // 18
      Number: 1,
      ExternalId: 30,
      Text: "I ask for what I need.",
      Answer: 0,
    },
    {
      // 22
      Number: 1,
      ExternalId: 31,
      Text: "I focus on what I want to do.",
      Answer: 0,
    },
    {
      // 23
      Number: 1,
      ExternalId: 32,
      Text: "I make people feel safe.",
      Answer: 0,
    },
    {
      // 24
      Number: 1,
      ExternalId: 33,
      Text: "I have more than one feeling at a time.",
      Answer: 0,
    },
    {
      // 28
      Number: 1,
      ExternalId: 34,
      Text: "I learn new things from problems.",
      Answer: 0,
    },
    {
      // 29
      Number: 1,
      ExternalId: 35,
      Text: "I care about people's feelings.",
      Answer: 0,
    },
    {
      // 30
      Number: 1,
      ExternalId: 36,
      Text: "I can guess how I might feel in a new place.",
      Answer: 0,
    },
    {
      // 26
      Number: 1,
      ExternalId: 37,
      Text: "I calm myself down when I am upset.",
      Answer: 0,
    },
    {
      //
      Number: 1,
      ExternalId: 38,
      Text: "I try until I get it right.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 39,
      Text: "The way I do things works.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 40,
      Text: "I like the choices I make.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 41,
      Text: "I try to avoid junk food.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 42,
      Text: "My teachers praise me.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 43,
      Text: "I can count on my family.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 44,
      Text: "I live for positive excitement.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 45,
      Text: "My family praises me.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 46,
      Text: "I am good at sports.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 47,
      Text: "I like to exercise.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 48,
      Text: "I finish my work before relaxing.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 49,
      Text: "I make school fun for myself.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 50,
      Text: "I do well at school.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 51,
      Text: "If you have any other thoughts or feelings, please write them here:",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 52,
      Text: "I do the same things as someone good I know.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 53,
      Text: "I eat healthier than my friends.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 54,
      Text: "I get along with my friends.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 55,
      Text: "Adults like me.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 56,
      Text: "Life is a lot of fun.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 57,
      Text: "I avoid things that are a little hard for me.",
      Answer: 0,
    },
    {
      Number: 1,
      ExternalId: 58,
      Text: "I save money.",
      Answer: 0,
    },
    // {
    //   Number: 1,
    //   ExternalId: 59,
    //   Text: "I solve my struggles with people.",
    //   Answer: 0,
    // },
    {
      Number: 2,
      ExternalId: 62,
      Text: "",
      FreeText:
        "If you have any other thoughts or feelings, please write them here",
    },
  ],
};
// Items: [
//   {
//     Text: "The purpose of this assessment is",
//     Answer: 0,
//     FreeText: "",
//   },
//   { Text: "Emotional Intelligence is", Answer: 0, FreeText: "" },
//   {
//     Text: "Emotional Intelligence is important because",
//     Answer: 0,
//     FreeText: "",
//   },
//   { Text: "I solve my fights with friends.", Answer: 0 },
//   { Text: "I am good.", Answer: 0 },
//   { Text: "I understand what makes me happy", Answer: 0 },
//   { Text: "I share my feelings with others.", Answer: 0 },
//   { Text: "I change my behavior when I need to.", Answer: 0 },
//   { Text: "I do as I am told.", Answer: 0 },
//   { Text: "I listen carefully to my friends.", Answer: 0 },
//   { Text: "I offer to help.", Answer: 0 },
//   { Text: "I do what is right.", Answer: 0 },
//   { Text: "I can change a bad habit.", Answer: 0 },
//   {
//     Text: "I share my things to help people feel better.",
//     Answer: 0,
//   },
//   {
//     Text: "I have lots of words to describe how I feel.",
//     Answer: 0,
//   },
//   {
//     Text: "I can tell by people's faces how they are feeling.",

//     Answer: 0,
//   },
//   { Text: "I can calm down an angry friend.", Answer: 0 },
//   { Text: "I do things without thinking.", Answer: 0 },
//   {
//     Text: "I can think of many ways to solve my problems.",

//     Answer: 0,
//   },
//   { Text: "I choose what I will do next.", Answer: 0 },
//   { Text: "I know what happiness feels like.", Answer: 0 },
//   { Text: "I build good habits.", Answer: 0 },
//   {
//     Text: "Before I decide, I think about what would be good or bad.",

//     Answer: 0,
//   },
//   { Text: "I get good grades.", Answer: 0 },
//   { Text: "I can help with world problems.", Answer: 0 },
//   { Text: "I am bad.", Answer: 0 },
//   { Text: "I solve my problems.", Answer: 0 },
//   { Text: "I can cheer myself up.", Answer: 0 },
//   {
//     Text: "Before I do something, I think of how my family will feel.",

//     Answer: 0,
//   },
//   { Text: "I work well with others.", Answer: 0 },
//   { Text: "I know when I need help.", Answer: 0 },
//   { Text: "My friends look up to me.", Answer: 0 },
//   { Text: "I ask for what I need.", Answer: 0 },
//   { Text: "I focus on what I want to do.", Answer: 0 },
//   { Text: "I make people feel safe.", Answer: 0 },
//   {
//     Text: "I have more than one feeling at a time.",

//     Answer: 0,
//   },
//   { Text: "I learn new things from problems.", Answer: 0 },
//   { Text: "I care about people's feelings.", Answer: 0 },
//   {
//     Text: "I can guess how I might feel in a new place.",

//     Answer: 0,
//   },
//   { Text: "I calm myself down when I am upset.", Answer: 0 },
//   { Text: "I try until I get it right.", Answer: 0 },
//   { Text: "The way I do things works.", Answer: 0 },
//   { Text: "I like the choices I make.", Answer: 0 },
//   { Text: "I try to avoid junk food.", Answer: 0 },
//   { Text: "My teachers praise me.", Answer: 0 },
//   { Text: "I can count on my family.", Answer: 0 },
//   { Text: "I live for positive excitement.", Answer: 0 },
//   { Text: "My family praises me.", Answer: 0 },
//   { Text: "I am good at sports.", Answer: 0 },
//   { Text: "I like to exercise.", Answer: 0 },
//   { Text: "I finish my work before relaxing.", Answer: 0 },
//   { Text: "I make school fun for myself.", Answer: 0 },
//   { Text: "I do well at school.", Answer: 0 },
//   {
//     Text: "If you have any other thoughts or feelings, please write them here:",
//     Answer: 0,
//   },
//   {
//     Text: "I do the same things as someone good I know.",
//     Answer: 0,
//   },
//   { Text: "I eat healthier than my friends.", Answer: 0 },
//   { Text: "I get along with my friends.", Answer: 0 },
//   { Text: "Adults like me.", Answer: 0 },
//   { Text: "Life is a lot of fun.", Answer: 0 },
//   {
//     Text: "I avoid things that are a little hard for me.",
//     Answer: 0,
//   },
//   { Text: "I save money.", Answer: 0 },
//   { Text: "I solve my struggles with people.", Answer: 0 },
// ],
