import React, { useEffect } from "react";
import { useState, useCallback } from "react";
import DebtModalChart from "./DebtModalChart";
import PortalPopup from "../../../../../PortalPopup";
import "./OpenDebtModalChart.css";
const OpenDebtModalChart = ({ state, months }) => {
  const [isDebtModalChartPopupOpen, setDebtModalChartPopupOpen] =
    useState(false);

  useEffect(() => {
    if (state.chartsModalOpen) {
      setDebtModalChartPopupOpen(true);
    }
  }, [state.chartsModalOpen]);

  const closeDebtModalChartPopup = useCallback(() => {
    setDebtModalChartPopupOpen(false);
  }, []);

  return (
    <>
      {isDebtModalChartPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
        >
          <DebtModalChart
            state={state}
            months={months}
            onClose={closeDebtModalChartPopup}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default OpenDebtModalChart;
