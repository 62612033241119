import React from "react";
import "./buttons.css";

const ContinueButton = (props) => {
  return (
    <button
      onClick={props._startMonthReview}
      type="button"
      className="btn btn-primary btn-lg continue-button"
    >
      Continue
    </button>
  );
};

export default ContinueButton;
