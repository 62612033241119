import React from "react";
import Button from "src/components/shared/Button";
import "./index.css";

export const FinishModuleButton = ({ finishLoading, toggleFeedbackModal }) => {
  if (finishLoading) {
    return <Button title="Please wait..." />;
  }

  return (
    <Button
      title="Finish Module"
      onClick={async () => {
        toggleFeedbackModal();
      }}
    />
  );
};
