import React from "react";
import "./HighScore.css";

const HighScores = (props) => {
  const _openHighscore = () => {
    props._openHighscores();
  };
  const _closeHighscore = () => {
    props._closeHighscore();
  };

  if (props.highscores && props.highscoreModalVisible) {
    props.highscores.sort(function (a, b) {
      return b.highscore - a.highscore;
    });

    const scores = props.highscores.map((score) => (
      <li>
        {" "}
        <strong>
          <span className="score">$ {Math.floor(score.highscore)}</span> -{" "}
          {score.user}
        </strong>
      </li>
    ));
    return (
      <div className="highscores-container">
        <h1>Leaderboard </h1>
        <a onClick={_closeHighscore}>
          <i class="fa fa-times-circle close-button" aria-hidden="true"></i>
        </a>

        <ol>{scores}</ol>
        <p>Get over 3000 to get into the highscores list!</p>
      </div>
    );
  } else if (props.highscores && !props.highscoreModalVisible) {
    return (
      <button onClick={_openHighscore} className="highscores-hidden">
        Leaderboard
      </button>
    );
  } else {
    return null;
  }
};

export default HighScores;
