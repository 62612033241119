import React from "react";
import Icon from "src/components/shared/Icon";
import "./index.css";

import ProgressBar from "../../../../../../../../../ProgressBar";
import { Link } from "react-router-dom";

import EditModuleOverview from "./components/EditModuleOverview";

import moment from "moment";

const Module = ({
  module,
  available = false,
  complete = false,
  authUser,
  lockedByDate,
  firebase,
}) => {
  const {
    name,
    description,
    est,
    dueDate,
    uid,
    progress,
    sectionLength,
    unlockDate,
  } = module || {};

  // console.log("Module 16 | complete", complete);

  const moduleProgress = progress
    ? Math.floor((progress / sectionLength) * 100)
    : 0;

  // console.log("Module 20 | module progress", progress);

  if (name === "GED Preparation") {
    if (authUser?.hasCompletedGED && !authUser?.isAdmin) {
      return null;
    }
  }
  const handleLessonStart = (lessonId) => {
    // check if current user has started this lesson
    const { lessons } = authUser;
    console.log("Module 39 | auth user lessons", lessons);
    console.log("Module 40 | lesson id", lessonId);
    if (lessons && lessons[lessonId]?.startDate) {
      // if started, don't do anything
      return;
    } else {
      let lessonsCopy = { ...authUser.lessons };

      lessonsCopy[lessonId] = { startDate: Date.now() };

      console.log("Module 51 | user copy", lessonsCopy);
      firebase.user(authUser.uid).update({ lessons: lessonsCopy });
    }
    // if not started, set a startTime on database
  };

  return (
    <div className="module__container">
      <div className="module__image">
        <img
          src={module.image}
          alt="module__image"
          width={"70%"}
          height={"auto"}
        />
      </div>
      <div className="module__title">{name}</div>
      <div className="module__description">{description}</div>
      <div className="module__info">
        <div className="moduleinfo__left">
          <Icon name="time" width={15} height={15} /> <p>{est || "3h"}</p>
        </div>
        <div className="moduleinfo__right">
          <span className="moduleinforight__duedate">Due Date: </span>{" "}
          {dueDate ? moment(dueDate).format("MM-DD") : "None"}
        </div>
      </div>
      <div className="module__progress">
        <ProgressBar
          height={5}
          showProgress={false}
          backgroundColor="#FFEBEB"
          completeColor="#D94645"
          progress={moduleProgress}
        />
      </div>
      <div className="module__status">
        {authUser?.isViewer || authUser?.isAdmin || authUser?.isMentor ? (
          <Link
            className="modulestatus__button"
            to={`/lesson/${uid}`}
            onClick={(e) => {
              handleLessonStart(uid);
            }}
          >
            Explore
          </Link>
        ) : available && !complete && !lockedByDate ? (
          <Link
            className="modulestatus__button"
            onClick={(e) => {
              handleLessonStart(uid);
            }}
            to={`/lesson/${uid}`}
          >
            Start
          </Link>
        ) : complete ? (
          <Link className="moduledone__button" to={`/lesson/${uid}`}>
            Done - Review
          </Link>
        ) : (
          <div className="module__locked">
            <Icon name="disabled" width={9} height={12} />{" "}
            <span>{lockedByDate ? "Date Locked" : "Locked"}</span>
          </div>
        )}

        {authUser?.isAdmin && <EditModuleOverview moduleId={uid} />}
      </div>
    </div>
  );
};

export default Module;
