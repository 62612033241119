import React, { Component } from "react";
import "./index.css";

class Image extends Component {
  render() {
    const { source, imageWidth, item } = this.props;

    return (
      <>
        {/* Image width {item.imageWidth} */}
        <img
          alt="edit"
          className="image-view"
          src={`${source}?unique=${Math.random()}`}
          width={item.imageWidth * 9}
          height={"auto"}
        />
      </>
    );
  }
}

export default Image;
