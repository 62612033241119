const initialState = { users: null };

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "users/set":
      return { ...state, users: action.payload };
    default:
      return state;
  }
};
