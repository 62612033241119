import React, { useState, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import { toast } from "react-toastify";

export const EditStatements = ({ statements, firebase, getQuestions }) => {
  const [data, setData] = useState(statements);

  const handleCategoryChange = (index, event) => {
    const updatedData = [...data];
    updatedData[index].category = event.target.value;
    setData(updatedData);
  };

  const handleStatementChange = (index, event) => {
    const updatedData = [...data];
    updatedData[index].statement = event.target.value;
    setData(updatedData);
  };
  const handleAreaChange = (index, event) => {
    const updatedData = [...data];
    updatedData[index].area = event.target.value;
    setData(updatedData);
  };

  const handleStatementsSave = async () => {
    try {
      await firebase.eiGhyst().set(data);
      await getQuestions();
      toast.success("Submitted updates");

      console.log("EditStatements.js 22 | success update");
    } catch (error) {
      toast.error("Error submitting EQ Assessment Update");
      console.log("EditStatements.js 23 | issue with update");
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ row }) => (
          <input
            type="text"
            style={{ border: "none", width: "100%" }}
            value={row.original.category}
            onChange={(e) => handleCategoryChange(row.index, e)}
          />
        ),
      },
      {
        Header: "Statement",
        accessor: "statement",
        Cell: ({ row }) => (
          <input
            type="text"
            style={{ border: "none", width: "100%" }}
            value={row.original.statement}
            onChange={(e) => handleStatementChange(row.index, e)}
          />
        ),
      },
      {
        Header: "Area",
        accessor: "area",
        Cell: ({ row }) => (
          <input
            type="text"
            style={{ border: "none", width: "100%" }}
            value={row.original.area}
            onChange={(e) => handleAreaChange(row.index, e)}
          />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    console.log("EditStatements.js 80 | statements", statements);
  }, [statements]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <>
      <button
        onClick={() => {
          handleStatementsSave();
        }}
      >
        Save
      </button>
      <table {...getTableProps()} style={{ width: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : " 🔼"}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default EditStatements;
