import React from "react";
import "./SelectTimeModal.css";

import { times } from "./times";
import TimeCarousel from "./TimeCaroussel";
import Popover from "antd/es/popover";

const SelectTimeModal = (props) => {
  let scenarios;
  const { playerLevel } = props;

  scenarios = times.map((time, index) => {
    if (playerLevel >= index) {
      return (
        <div key={index} className="time-option">
          <h4>{time.title}</h4>
          <Popover
            placement="right"
            content={<div>{time.info}</div>}
            title={time.title}
            overlayStyle={{ maxWidth: 300 }}
          >
            <button
              onClick={() => {
                props._chooseTime(time.range[0]);
              }}
              className="select-time-investing-button"
            >
              Start
            </button>
          </Popover>
        </div>
      );
    } else {
      return (
        <div key={index} className="time-option">
          <h4>??????</h4>
          {/* <img width="120px" height="80px" src={time.img} alt="" srcSet=""/> */}
          <button className="btn btn-danger floating">
            <i class="fa fa-lock lock" aria-hidden="false"></i>
          </button>
        </div>
      );
    }
  });

  if (props.timeModalVisible) {
    return (
      <div className="blur-background">
        <div className="time-modal">
          <h3>
            <strong>Choose Investing Time Frame</strong>
          </h3>
          {scenarios}
          <br />
          <hr />
          <p className="win-investing-instructions">
            Win each level to unlock the next time period!
          </p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default SelectTimeModal;
