
import Cell from '../Cell.js'
import React from 'react';

const styles = {
  container: {
    justifyContent: 'center',
    marginTop: 20,
    alignItems: 'center',
  },
  specialCharacter: {
    fontWeight: 'bold',
    color: 'orange'
  }
};

export const ruleOf72Steps = [{
  title: "The Rule of 72: Doubling Your Money",
  text: <span style={{ fontSize: 16 }}>One awesome mind tool to calculate how long it will take for your investment to DOUBLE is the Rule of 72. Type <span style={styles.specialCharacter}>Rule of 72 </span>in cell <span style={styles.specialCharacter}>A1</span>.</span>,
  content: (setCellText) => (
    <div style={styles.container}>
      <Cell cellName={"A1"} onTextChange={setCellText} />
    </div>
  ),
  answer: "rule of 72",
  range: "A1",
},
{
  title: "Using the Rule of 72",
  text: <span style={{ fontSize: 16 }}>It says that if you divide 72 by the return percentage, you'll get the number of years it will take for your money to double. Type <span style={styles.specialCharacter}>Return %</span> on cell <span style={styles.specialCharacter}>A3</span></span>,
  content: (setCellText) => (
    <div style={styles.container}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A1"} value={'Rule of 72'} editable={false} />
      </div>
      <div>
        <Cell cellName={"A3"} onTextChange={setCellText} />
      </div>
    </div>
  ),
  answer: "return %",
  range: "A3",
},
{
  title: "Making things easy",
  text: <span style={{ fontSize: 16 }}>Say you're getting a 7.2% return. Type <span style={styles.specialCharacter}>7.2</span> on cell <span style={styles.specialCharacter}>A4</span>.</span>,
  content: (setCellText) => (
    <div style={styles.container}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A1"} value={'Rule of 72'} editable={false} />
      </div>
      <div style={{ marginRight: 120 }}>
        <Cell cellName={"A3"} value={'Return %'} editable={false} />
      </div>
      <div style={{ marginRight: 120 }}>
        <Cell cellName={"A4"} onTextChange={setCellText} />
      </div>
    </div>
  ),
  answer: "7.2",
  range: "A4",
},
{
  title: "Years to Double",
  text: <span style={{ fontSize: 16 }}>Now add a Years column. Type <span style={styles.specialCharacter}>Years to Double</span> in cell <span style={styles.specialCharacter}>B3</span>.</span>,
  content: (setCellText) => (
    <div style={styles.container}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A1"} value={'Rule of 72'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A3"} value={'Return %'} editable={false} />
        <Cell cellName={"B3"} onTextChange={setCellText} />
      </div>
      <div style={{ marginRight: 120 }}>
        <Cell cellName={"A4"} value={'7.2'} editable={false} />
      </div>
    </div>
  ),
  answer: "years to double",
  range: "B3",
},
{
  title: "Calculate Years to Double",
  text: <span style={{ fontSize: 16 }}>Now you'll divide 72 by the return % on cell <span style={styles.specialCharacter}>B4</span>.</span>,
  content: (setCellText) => (
    <div style={styles.container}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A1"} value={'Rule of 72'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A3"} value={'Return %'} editable={false} />
        <Cell cellName={"B3"} value={'Years to double'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A4"} value={'7.2'} editable={false} />
        <Cell cellName={"B4"} onTextChange={setCellText} />
      </div>
    </div>
  ),
  answer: "10",
  range: "B4",
},
{
  title: "10% Returns",
  text: <span style={{ fontSize: 16 }}>Now let's see how long it'll take for an investment gaining 10% per year to double. Type <span style={styles.specialCharacter}>10</span> on cell <span style={styles.specialCharacter}>A5</span>.</span>,
  content: (setCellText) => (
    <div style={styles.container}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A1"} value={'Rule of 72'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A3"} value={'Return %'} editable={false} />
        <Cell cellName={"B3"} value={'Years to double'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A4"} value={'7.2'} editable={false} />
        <Cell cellName={"B4"} value={'10'} editable={false} />
      </div>
      <div style={{ marginRight: 120 }}>
        <Cell cellName={"A5"} onTextChange={setCellText} />
      </div>
    </div>
  ),
  answer: "10",
  range: "A5",
},
{
  title: "10% Returns",
  text: <span style={{ fontSize: 16 }}>Calculate the years it would take to double. On cell <span style={styles.specialCharacter}>B5</span> type the formula to double like we did on the previous exercise.</span>,
  content: (setCellText) => (
    <div style={styles.container}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A1"} value={'Rule of 72'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A3"} value={'Return %'} editable={false} />
        <Cell cellName={"B3"} value={'Years to double'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A4"} value={'7.2'} editable={false} />
        <Cell cellName={"B4"} value={'10'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A5"} value={'10'} editable={false} />
        <Cell cellName={"B5"} onTextChange={setCellText} />

      </div>
    </div>
  ),
  answer: "7.2",
  range: "B5",
},
{
  title: "30% returns",
  text: <span style={{ fontSize: 16 }}>Let's do it one more time so you're confident in your ability to calculate the Rule of 72. On cell <span style={styles.specialCharacter}>A6</span> type <span style={styles.specialCharacter}>30</span>. Remember these are percentage returns.</span>,
  content: (setCellText) => (
    <div style={styles.container}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A1"} value={'Rule of 72'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A3"} value={'Return %'} editable={false} />
        <Cell cellName={"B3"} value={'Years to double'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A4"} value={'7.2'} editable={false} />
        <Cell cellName={"B4"} value={'10'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A5"} value={'10'} editable={false} />
        <Cell cellName={"B5"} value={'7.2'} editable={false} />
      </div>
      <div style={{ marginRight: 120 }}>
        <Cell cellName={"A6"} onTextChange={setCellText} />
      </div>
    </div>
  ),
  answer: "30",
  range: "A6",
},
{
  title: "Calculate Year to Double",
  text: <span style={{ fontSize: 16 }}>Use the same formula you used previously for 30% returns. See cell B5 to see the formula and add the same formula on cell <span style={styles.specialCharacter}>B6</span>.</span>,
  content: (setCellText) => (
    <div style={styles.container}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A1"} value={'Rule of 72'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A3"} value={'Return %'} editable={false} />
        <Cell cellName={"B3"} value={'Years to double'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A4"} value={'7.2'} editable={false} />
        <Cell cellName={"B4"} value={'10'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A5"} value={'10'} editable={false} />
        <Cell cellName={"B5"} value={'7.2'} editable={false} />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Cell cellName={"A6"} value={'30'} editable={false} />
        <Cell cellName={"B6"} onTextChange={setCellText} />
      </div>
    </div>
  ),
  answer: "2.4",
  range: "B6",
},
{
  title: "Congratulations!",
  finished: true,
  text: <span style={{ fontSize: 16 }}>You finished the Rule of 72 interactive lesson!</span>,
}

]