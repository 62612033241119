export const times = [
  {
    title: "After 2009 Crisis",
    time: "Start",
    range: [2010, 2015],
    info:
      "Obama approves the American Reinvestment Act. Things are looking good ahead in the future.",
    // img: "/assets/images/investing-up.png"
  },
  {
    title: "2000s Dot-Com Bubble",
    time: "Start",
    range: [1999, 2005],
    info:
      "Complicated times. It seems like tech stocks are reaching all time highs. Be very careful with what you invest in.",
    // img: "/assets/images/dotcom.png"
  },
  {
    title: "2008 Financial Crisis",
    time: "Start",
    range: [2007, 2012],
    info:
      "You might have heard about the housing bubble. Get ready for some all time lows",
    // img: "/assets/images/crisis.png"
  },
  {
    title: "20 years 98-18",
    time: "Start",
    range: [0, 1999],
    info: "Try out all the investment periods!",
    // img: "assets/images/random.png"
  },
];
