import React from "react";
import { useState, useCallback, useEffect } from "react";
import CreditSimLoseModal from "./CreditSimLoseModal";
import PortalPopup from "../../../../../PortalPopup";
import "./OpenLostModal.css";
const OpenLostModal = ({ isOpen, resetGame, state, months }) => {
  const [isCreditSimLoseModalPopupOpen, setCreditSimLoseModalPopupOpen] =
    useState(false);

  useEffect(() => {
    setCreditSimLoseModalPopupOpen(isOpen === "lose");
  }, [isOpen]);

  const closeCreditSimLoseModalPopup = useCallback(() => {
    setCreditSimLoseModalPopupOpen(false);
  }, []);

  return (
    <>
      {isCreditSimLoseModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          // onOutsideClick={closeCreditSimLoseModalPopup}
        >
          <CreditSimLoseModal
            onClose={closeCreditSimLoseModalPopup}
            resetGame={resetGame}
            state={state}
            months={months}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default OpenLostModal;
