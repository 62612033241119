import React from "react";
import { questQuestions } from "./questions";

export const Item = ({ Text, itemIndex, Answer, FreeText, handleEdit }) => {
  if (questQuestions.Items[itemIndex].hasOwnProperty("FreeText")) {
    return (
      <div className="quest-freeanswer-container">
        <div className="questanswer__question">{Text}</div>
        <textarea
          value={FreeText}
          className="questanswer__textarea"
          placeholder="Please answer here..."
          onChange={(e) => handleEdit(itemIndex, e.target.value)}
        />
      </div>
    );
  } else {
    const rangeOptions = [1, 2, 3, 4, 5];
    const options = [
      "Almost Never",
      "Seldom ",
      "Sometimes ",
      "Often",
      "Almost Always",
    ];

    return (
      <div className="quest-likert-main-container">
        <div className="questquestion__text">
          {itemIndex - 2}.{"  "} {Text}
        </div>
        <div className="quest-likert-container">
          {rangeOptions.map((option, index) => {
            return (
              <div className="quest-option-container">
                <p
                  className={
                    Answer === option
                      ? "quest-likert-selected"
                      : "quest-likert-not-selected"
                  }
                  onClick={() => handleEdit(itemIndex, option)}
                  key={index}
                ></p>
                <p>{options[index]}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};
