import React, {useState} from "react";
import "../index.css";
import Button from "src/components/shared/Button";
import { CheckboxRed } from "./CheckboxRed";
import { sendOneEmail } from "src/api/emails";
import { sendTextMessage } from "src/api/texts";

export const TestEventReminderModal = ({authUser,closeModal,eventId}) => {

  const [smsChecked,setSMSCheckState] = useState(false);
  const [mailChecked,setMailCheckState] = useState(false);
  const [mailMessage,setMailMessage] = useState('Hi! Please confirm your RED event attendance in the following link {https://myreded.com/event-confirmation/token}');
  const [smsMessage,setSmsMessage] = useState('Hi! Please confirm your RED event attendance in the following link {https://myreded.com/event-confirmation/token}');
  const [mailSubject,setMailSubject] = useState('RED Reminder');
  const [phone, setPhone] = useState('')
  const [mail, setMail] = useState('')

  const handleInsertText = (format) => {
    const link = "{https://myreded.com/event-confirmation/token}";
    if(format === 'mail'){
      setMailMessage(prevState => prevState + link)
    }
    if(format === 'sms'){
      setSmsMessage(prevState => prevState + link)
    }
  }

  const handleSendReminder = (smsChecked,mailChecked) => {

    const link = process.env.NODE_ENV === 'development' ? `http://localhost:3000/event-confirmation/test/${eventId}` : `https://myreded.com/event-confirmation/test/${eventId}` 
    let finalMailMessage = mailMessage.replace("{https://myreded.com/event-confirmation/token}",link)
    let finalSMSMessage = smsMessage.replace("{https://myreded.com/event-confirmation/token}",link)

      if(mailChecked){
        //to,subject,finalMessage,emailRecipientName
        sendOneEmail(mail,mailSubject,finalMailMessage)
       // handleSendOneMail(mail,mailSubject,mailMessage, title)
      }
      if(smsChecked){
        sendTextMessage(phone,finalSMSMessage)
      }
      closeModal(false)
  }

  return (
    <div  className="eventReminder__wrapper">
      <div className="eventReminder__container">
        <div className="eventReminder__header">
          <div className="eventReminder__title">Test message</div>
          <div className="eventReminder__closeIcon" onClick={()=>closeModal(false)}><img src={"/assets/images/close_icon.svg"} alt="close icon"/></div>
        </div>
        <div className="reminderModal__actions__container">
          <div className="reminderModal__checkbox__container">
            <div onClick={()=>{setMailCheckState(prevState =>{ return !prevState})}}>
              <CheckboxRed isChecked={mailChecked} />
            </div>
            <div> Send mail Notification</div>
          </div>
          <div className="reminderModal__checkbox__container">
            <div onClick={()=>{setSMSCheckState(prevState =>{ return !prevState})}}>
              <CheckboxRed isChecked={smsChecked} />
            </div>
            <div> Send SMS Notification</div>
          </div>
        </div>
        {mailChecked && (
        <div>
          <input className="eventReminder__input" onChange={(e)=>{setMail(e.target.value)}} value={mail} placeholder="Recipient.."/>
          <input className="eventReminder__input" onChange={(e)=>{setMailSubject(e.target.value)}} value={mailSubject} placeholder="This is the subject.."/>
          <textarea className="eventReminder__textArea" onChange={(e)=>{setMailMessage(e.target.value)}} rows={3} placeholder="This is the body.." value={mailMessage}/>
          <div style={{textAlign:'right'}}>
            <button 
              className="reminderModal__displayLink-button"
              onClick={()=>handleInsertText('mail')}
            >
              Display Link
            </button>
          </div>
        </div>
        )}
        {smsChecked && (
          <div>
            <input className="eventReminder__input" onChange={(e)=>{setPhone(e.target.value)}} value={phone} placeholder="Recipient phone (with area code).."/>
            <textarea className="eventReminder__textArea" rows={3} onChange={(e)=>{setSmsMessage(e.target.value)}} placeholder="Here goes the sms.." value={smsMessage}/>
            <div style={{textAlign:'right'}}>
              <button 
                  className="reminderModal__displayLink-button"
                  onClick={()=>handleInsertText('sms')}
                >
                  Display Link
              </button>
            </div>
          </div>
        )}
        <div className="eventReminder__actionButtonContainer">
          <Button 
            className="action-button" 
            onClick={()=>{handleSendReminder(smsChecked,mailChecked)}} 
            type={!smsChecked && !mailChecked ? 'disabled' : 'primary'}
            style={{cursor:!smsChecked && !mailChecked ? 'not-allowed':'pointer'}}
            title={"Send Test"}
          />
        </div>
    </div>
  </div>
  );
};


export default TestEventReminderModal;