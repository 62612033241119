import React from "react";
import "./EmergencyExpense.css";
import Button from "src/components/shared/Button";
import Spin from "antd/es/spin";

const EmergencyExpense = ({
  emergencyTitle = "You crashed your car! You have to pay the insurance premium!",
  emergencyHappiness = "-3",
  emergencyCost = 0,
  handleEmergencyPayment,
  choiceLoading,
}) => {

  const handleEmergency = () => {
    let option = {
      text: emergencyTitle,
      cost: emergencyCost,
      happiness: emergencyHappiness,
      category: "insurance",
    };

    handleEmergencyPayment({ option }, true);
  };
  return (
    <div className="emergencyexpense">
      <div className="emergencytitle">{emergencyTitle}</div>
      <div className="emergencyinfocontainer">
        <div className="emergencyhappiness">
          <div className="image-92-icon">☹️</div>
          {/* <img className="image-92-icon" alt="" src="/image-92@2x.png" /> */}
          <div className="x3">{emergencyHappiness}</div>
        </div>
        <div className="emergency-parent">
          <div className="emergency-div">$</div>
          <div className="emergency-div1">{emergencyCost}</div>
        </div>
      </div>
      <div className="emergencybutton">
        {choiceLoading ? (<Spin />) : (<Button title="Pay Insurance Premium" onClick={handleEmergency} />)}
      </div>
    </div>
  );
};

export default EmergencyExpense;
