// Initial State
const initialState = {
  loadingEvent: false,
  loadingUsers: false,
  users: [],
  databaseEvent: null,
  classroom: null,
  hasNoEventObject: false,
  attendanceMode: false,
  editEventOpen: false,
  testReminderOpen: false,
  moduleName: null,
  eventData: {
    attendeesList: [],
  },
  reminderModalOpen: {},
};

// Reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "event/setLoadingEvent":
      return { ...state, loadingEvent: payload };
    case "event/setLoadingUsers":
      return { ...state, loadingUsers: payload };
    case "event/setUsersList":
      return { ...state, users: payload };
    case "event/setDatabaseEvent":
      return { ...state, databaseEvent: payload };
    case "event/setClassroom":
      return { ...state, classroom: payload };
    case "event/setHasNoEventObject":
      return { ...state, hasNoEventObject: payload };
    case "event/setAttendanceMode":
      return { ...state, attendanceMode: payload };
    case "event/setEditEventOpen":
      return { ...state, editEventOpen: payload };
    case "event/setTestReminderOpen":
      return { ...state, testReminderOpen: payload };
    case "event/setModuleName":
      return { ...state, moduleName: payload };
    case "event/setEventData":
      return { ...state, eventData: payload };
    case "event/setReminderModalOpen":
      return { ...state, reminderModalOpen: payload };
    default:
      return state;
  }
};
