var lesson = [
  {
    title: "Simple Interest",
    text:
      "Now you'll see how money grows over time. Say you were to give money again to Alex.",
    instructions: "On // cell A1 // type // Interest //",
    color: "#fafafa",
    answer: ["Interest"],
    range: "A1",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "Simple Interest II",
    text:
      "With Simple Interest, your money grows by the SAME AMOUNT each period (month, year). It's easy because your returns are ALWAYS THE SAME. ",
    instructions:
      "On // cell B1 // type // 0.5 // (0.5 is the same as 50%) . This is your interest from Alex. ",
    // button: "Sell",
    // image: "/assets/images/percent.png",
    color: "#fafafa",
    answer: [0.5],
    range: "B1",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "Simple Interest III",
    text: "Let's add the initial amount",
    instructions:
      "On // cell A4 // type // Initial Amount // . Press ENTER and then CHECK. ",
    // button: "Add Periods",
    // image: "/assets/images/percent.png",
    color: "#fafafa",
    answer: ["Initial"],
    range: "A4",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "Simple Interest IV",
    text: "Type the original amount given to Alex..",
    instructions:
      "On // cell A5 // type // $10,000 // . Press ENTER and then CHECK. ",
    // button: "Add Periods",
    // image: "/assets/images/percent.png",
    color: "#fafafa",
    answer: [10000],
    range: "A5",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "Simple Interest Period 1",
    text: "Let's add periods",
    instructions:
      "On // cell B4 // type // Year 1 // . Press ENTER and then CHECK. ",
    // button: "Add Periods",
    // image: "/assets/images/percent.png",
    color: "#fafafa",
    answer: ["Year"],
    range: "B4",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "Simple Interest Calculation Year 1: IMPORTANT",
    text:
      "Pay close attention. You will calculate the growth based on the INTEREST of the INITIAL AMOUNT ONLY (50% of $10,000) for EVERY period. You will add the previous period's amount plus the interest of the initial amount. $10,000 (original amount) + 50% of that amount ($5,000). The final result will be $10,000 + $5,000",
    instructions:
      "On // cell B5 // type // =A5+A5*B1 // . Press ENTER and then CHECK. ",
    // button: "Add Periods",
    // image: "/assets/images/percent.png",
    color: "#fafafa",
    answer: ["=A5+A5*B1"],
    range: "B5",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "Simple Interest Year 2",
    text: "Let's add the next period.",
    instructions:
      "On // cell C4 // type // Year 2 // . Press ENTER and then CHECK. ",
    // button: "Add Periods",
    // image: "/assets/images/percent.png",
    color: "#fafafa",
    answer: ["Year"],
    range: "C4",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "Simple Interest Calculation Year 2",
    text:
      "Period 2 will increase by the SAME amount of simple interest of the INITIAL AMOUNT (50% of $10,000). The first period's interest was 50% of $10,000, or $5,000. So in Year 2 it will increase by the same amount.",
    instructions:
      "On // cell C5 // type // =B5+A5*B1 // . This means A5: $10,000 + A5: $10,000 * 0.5 . Press ENTER and then CHECK. ",
    // button: "Add Periods",
    // image: "/assets/images/percent.png",
    color: "#fafafa",
    answer: ["=B5+A5*B1"],
    range: "C5",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "Simple Interest Year 3",
    text: "Let's add the next year.",
    instructions:
      "On // cell D4 // type // Year 3 // . Press ENTER and then CHECK. ",
    // button: "Add Periods",
    // image: "/assets/images/percent.png",
    color: "#fafafa",
    answer: ["Year"],
    range: "D4",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "Year 3",
    text:
      "Now you have to calculate Year 3 on your own. Remember, you have to add the previous year, plus the Initial Amount multiplied by the interest. The formula should be =PreviousYearAmount+InitialAmount*Interest (obviously in cell positions).",
    instructions:
      "On // cell D5 // type the formula needed to calculate simple interest for Year 3. Press ENTER and then CHECK. If confused, check the previous year's formulas by double clicking them.",
    // button: "Add Periods",
    // image: "/assets/images/percent.png",
    color: "#fafafa",
    answer: ["=C5+A5*B1"],
    range: "D5",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "Simple Interest Year 4",
    text: "Let's add the next year.",
    instructions:
      "On // cell E4 // type // Year 4 // . Press ENTER and then CHECK. ",
    // button: "Add Periods",
    // image: "/assets/images/percent.png",
    color: "#fafafa",
    answer: ["Year"],
    range: "E4",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "Year 4",
    text: "Finally, calculate year 4 on your own as well.",
    instructions:
      "On // cell E5 // type the formula needed to calculate simple interest for Year 4. Press ENTER and then CHECK. If confused, check the previous year's formulas by double clicking them.",
    // button: "Add Periods",
    // image: "/assets/images/percent.png",
    color: "#fafafa",
    answer: ["=D5+A5*B1"],
    range: "E5",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
];

export default lesson;
