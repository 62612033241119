import React from "react";
import Icon from "src/components/shared/Icon";

export const NoEvents = () => {
  return (
    <div className="noevents__container" style={{width:'100%',maxWidth:335}}>
      <Icon name="calendar" />
      <p>There are no upcoming events yet.</p>
    </div>
  );
};
