import React from "react";
import "./InvestingInfo.css";
import ProgressBar from "../ProgressBar/ProgressBar";
import Chart from "../Chart/Chart";
import Advisor from "../Advisor/Advisor";
import { PopOver } from "../../../../../../PopOver";

const InvestingInfo = (props) => {
  return (
    <div className="info-container">
      <div className="info-col-1">
        <h6>
          <strong>Portfolio Summary</strong>
        </h6>
        <div className="portfolio-cash-investments">
          <div className="cash-value-container">
            <h6 style={{ color: "black" }}>Cash Account: </h6>
            <h3>
              $ <strong>{Math.floor(props.cashValue)}</strong>
            </h3>
          </div>
          <div className="investments-value-container">
            <h6 style={{ color: "black" }}>Investments Value: </h6>
            <h3>
              $ <strong>{Math.floor(props.portfolioValue)}</strong>
            </h3>
          </div>
        </div>
        <h6>
          Net Worth{" "}
          <PopOver
            title="Net Worth"
            info="The sum of everything you own. In this case, Cash + Investments."
          />
        </h6>
        <h2>
          ${" "}
          <strong>{Math.floor(props.cashValue + props.portfolioValue)}</strong>
        </h2>

        <div className="net-worth-goal-container">
          <h4>
            Goal: $ <strong>{props.winGoal}</strong>
          </h4>

          <div className="progress-div">
            <ProgressBar
              progressPercentage={
                ((props.cashValue + props.portfolioValue) / props.winGoal) * 100
              }
            />
          </div>
        </div>
      </div>
      <div className="info-col-2">
        {/* <div className="chart-pie">
          <p>Portfolio Distribution</p>
          <Chart
            chartType={"Pie"}
            chartLabels={["Cash", "Investments"]}
            chartData={[props.cashValue, props.portfolioValue]}
          />
        </div> */}
      </div>

      <Advisor />
    </div>
  );
};

export default InvestingInfo;
