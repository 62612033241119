import React from "react";
import { Competency } from "./Competency";
import Category from "./Category";
import { assessmentCategoryData } from "../assessmentInfoData";

const ResultContainer = ({ category, index, preTestCompetencies }) => {
  const categoryName = category[0];
  const categoryDescription = assessmentCategoryData[category[0]].intro;
  const categoryScore = category[1].percentageScore;
  const categoryCompetencies = Object.entries(category[1]);

  return (
    <div className="resultareacontainer1">
      <Category
        categoryName={categoryName}
        categoryDescription={categoryDescription}
        categoryScore={categoryScore}
      />
      <div className="competency-parent">
        {categoryCompetencies?.map((competency, index) => {
          const competencyName = competency[0];
          const competencyScore = parseFloat(
            (competency[1].totalValue / (5 * competency[1].count)) * 100
          ).toFixed(2);

          if (Object.keys(competency[1]).length > 1)
            return (
              <Competency
                competencyName={competencyName}
                competencyScore={competencyScore}
                preTestCompetency={
                  preTestCompetencies && preTestCompetencies[competencyName]
                }
              />
            );
        })}
      </div>
    </div>
  );
};

export default ResultContainer;
