import React from "react";
import { Formats } from "./Formats";

import "./index.css";
import { SnowballEditContent } from "./SnowballEditContent";

const SnowballTutorial = ({ index, item, editSection }) => {
  console.log("SnowballTutorial 3 | Snowball item to edit", item);
  return (
    <div className="snowball-edit-container">
      <div className="snowball-lesson-edit">
        <h5>Content</h5>
        {item &&
          item.data &&
          item.data.map((content, optionIndex) => {
            return (
              <SnowballEditContent
                key={optionIndex}
                index={index}
                content={content}
                editSection={editSection}
                optionIndex={optionIndex}
              />
            );
          })}
        <button
          type="button"
          name="add-content"
          onClick={(e) => editSection(e, index, "snowball-spreadsheet")}
        >
          Add Content
        </button>
      </div>

      <Formats editSection={editSection} index={index} item={item} />
    </div>
  );
};

export default SnowballTutorial;
