import React from "react";

const Table = ({
  item,
  index,
  editSection,
  _moveSectionContentDown,
  _moveSectionContentUp,
  removeContentAtIndex,
}) => {
  console.log("Table 9 | item", item);

  const handleEdit = (e, actionType, columnIndex, rowIndex, rowValueIndex) => {
    editSection(
      e,
      index,
      "table",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      actionType,
      columnIndex,
      rowIndex,
      rowValueIndex
    );
  };

  if (!item.columns) return "No Table Data";

  return (
    <div>
      <table>
        <tr>
          {item.columns &&
            item.columns.map((column, columnIndex) => {
              return (
                <th>
                  <input
                    onChange={(e) => handleEdit(e, "editColumn", columnIndex)}
                    value={column}
                  />
                </th>
              );
            })}
        </tr>
        <div>
          <button type="button" onClick={(e) => handleEdit(e, "addColumn")}>
            + Col
          </button>
          <button type="button" onClick={(e) => handleEdit(e, "removeColumn")}>
            - Col
          </button>
        </div>
        {item.rows &&
          item.rows.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {row.map((rowVal, rowValueIndex) => {
                  return (
                    <td key={rowValueIndex}>
                      <input
                        value={rowVal}
                        onChange={(e) =>
                          handleEdit(
                            e,
                            "editRow",
                            null,
                            rowIndex,
                            rowValueIndex
                          )
                        }
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        <button type="button" onClick={(e) => handleEdit(e, "addRow")}>
          + row
        </button>
        <button type="button" onClick={(e) => handleEdit(e, "removeRow")}>
          - row
        </button>
      </table>
      <button
        onClick={(e) => removeContentAtIndex(e, index)}
        className="btn btn-danger btn-sm"
      >
        Delete Table
      </button>
    </div>
  );
};

export default Table;
