import React from "react";
import "./BudgetingWinModal.css";
export const BudgetingWinModal = () => {
  return (
    <div className="budgetingwinmodal">
      <div className="youwin-title">
        <div className="congratulations-you-win">
          Congratulations! You beat 3 months!
        </div>
      </div>
      <div className="youwin-image">
        <img className="image-105-icon" alt="" src="/wintrophy.png" />
      </div>
      <div className="youve-successfully-won">
        Unlock all 4 levels to move forward!
      </div>
    </div>
  );
};
