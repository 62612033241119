import React, { useState } from 'react';
import { Modal, Button, Rate, Input, Spin, Form } from 'antd';
import { StarOutlined } from '@ant-design/icons';
import { Checkbox } from '../../../shared/Checkbox';
import { API_URL } from "src/api/apiIUrl";

const AiQuizModal = ({ visible, onClose, content, sections, item, addSectionContent, editSection }) => {
  const [difficulty, setDifficulty] = useState(3);
  const [loading, setLoading] = useState(false);
  const [selectedSections, setSelectedSections] = useState([]);
  const [generatedQuestion, setGeneratedQuestion] = useState(null);
  const [form] = Form.useForm();

  console.log('item', item);
  console.log('content', content);

  const generateQuestion = async () => {
    setLoading(true);
    try {
      const textSections = selectedSections
        .flatMap(section => section.content)
        .filter(item => item.type === "text")
        .map(item => item.value)
        .join("\n\n");

      const existingQuestions = content
        .filter(item => item.type === "quiz")
        .map(item => ({
          question: item.question,
          options: item.options
        }));

      const response = await fetch(`${API_URL}/generateQuizQuestion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          textSections,
          difficulty,
          existingQuestions,
        }),
      });

      const result = await response.json();
      if (result.success) {
        onQuestionGenerated(result.data);
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Error generating question:', error);
    } finally {
      setLoading(false);
    }
  };

  const onQuestionGenerated = (questionData) => {
    setGeneratedQuestion(questionData);
    form.setFieldsValue({
      question: questionData.question,
      options: questionData.options,
      answer: questionData.answer
    });
  };

  const handleSave = () => {
    const values = form.getFieldsValue();
    // Find the index of the correct answer in the options array
    const answerIndex = values.options.findIndex(option => option === values.answer);

    const updatedQuestion = {
      question: values.question,
      options: values.options,
      answer: values.options[answerIndex]
    };

    // First create the empty quiz content

    onClose(updatedQuestion);
  };

  const handleSectionsChange = (section) => {
    const isCurrentlyChecked = selectedSections.some(s => s.name === section.name);
    if (!isCurrentlyChecked) {
      setSelectedSections([...selectedSections, section]);
    } else {
      setSelectedSections(selectedSections.filter(s => s.name !== section.name));
    }
  };

  return (
    <Modal
      visible={visible}
      title="Generate AI Question"
      onCancel={() => onClose(null)}
      width={800}
      footer={[
        <Button key="cancel" onClick={() => onClose(null)}>
          Cancel
        </Button>,
        <Button
          key="generate"
          type="primary"
          onClick={generateQuestion}
          disabled={selectedSections.length === 0 || loading}
        >
          Generate Question
        </Button>,
        generatedQuestion && (
          <Button key="save" type="primary" onClick={handleSave}>
            Save Question
          </Button>
        ),
      ]}
    >
      <div style={{ marginBottom: 16 }}>
        <h4>Select Difficulty:</h4>
        <Rate
          character={<StarOutlined />}
          value={difficulty}
          onChange={setDifficulty}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <h4>Select sections included:</h4>
        {sections.map((section, index) => (
          <div key={index} style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
            <Checkbox
              isChecked={selectedSections.some(s => s.name === section.name)}
              onClick={() => handleSectionsChange(section)}
            />
            <span style={{ marginLeft: 8 }}>{section.name}</span>
          </div>
        ))}
      </div>
      {loading && (
        <div style={{ textAlign: 'center', marginTop: 16 }}>
          <Spin size="large" />
          <p>Generating question...</p>
        </div>
      )}
      {generatedQuestion && (
        <Form form={form} layout="vertical">
          <Form.Item name="question" label="Question">
            <Input.TextArea />
          </Form.Item>

          {[0, 1, 2, 3].map((index) => (
            <Form.Item
              key={index}
              name={['options', index]}
              label={`Option ${index + 1}`}
            >
              <Input />
            </Form.Item>
          ))}

          <Form.Item name="answer" label="Correct Answer">
            <Input />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AiQuizModal;
