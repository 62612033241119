import React from "react";
import "./index.css";

import { toast } from "react-toastify";

export const quizSuccessNotification = (isPreModuleQuizSection) => {
  toast.success(isPreModuleQuizSection ? "👏! Click Next to Continue." : "👏 Great job! You passed the quiz. Click Next to Continue.", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const copiedCurrentTreeBranch = (copiedBranchName) => {
  toast.success(`Copied Tree Branch ${copiedBranchName}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export const pastedCopiedTreeBranch = () => {
  toast.success("Pasted Tree Branch", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const quizFailedNotification = (correctQuizAnswerCount, quizCount) => {
  toast.error(
    `You failed the quiz. You got ${Math.floor(
      (correctQuizAnswerCount / quizCount) * 100
    )} % . You need at least 75% to pass.`,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};

export const missingFields = (missingFieldsArray) => {
  toast.error(
    <div className="missingFields-notifications-container">
      <h3>Incomplete Form</h3>
      {missingFieldsArray &&
        missingFieldsArray.map((field, index) => (
          <p key={index}>{`${field.index} ${field.label}`}</p>
        ))}
    </div>,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};

export const termsAndConditions = () => {
  toast.warn(
    "Terms and Conditions not Signed. Please accept terms and conditions before updating the form.",
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};

export const videoFailedWarning = () => {
  toast.warn(
    "Pay attention to videos! If you fail the quizes 2 times after the video, you will have to re-watch them!",
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};

export const openNotification = (title, description) => {
  toast(`💰 ${title} ${description || ""}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export const errorNotification = (title, description) => {
  toast.error(`${title} ${description}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const openBottomRightNotification = (title, description) => {
  toast(`💰 ${title} ${description}`, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const sessionExpiredNotification = (title, description) => {
  toast(
    <div>
      <div>
        {title} {description}
      </div>{" "}
      <button
        className="btn btn-success"
        onClick={() => window.location.reload()}
      >
        Reload
      </button>
    </div>,
    {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};
