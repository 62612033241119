import React from "react";
import "./CreditGameWinModal.css";
import Button from "../../../../../shared/Button";
const CreditGameWinModal = ({ onClose, resetGame, handleNext }) => {
  return (
    <div className="creditgamewinmodal">
      <div className="title">
        <div className="congratulations-you-win">Congratulations! You win!</div>
      </div>
      <div className="youve-successfully-paid">
        You've successfully paid off all your debts.
      </div>
      <div className="button-parent">
        <Button
          title="Next Lesson"
          onClick={handleNext}
          style={{ width: "100%" }}
        />
        <Button
          title="Play Again"
          style={{ width: "100%" }}
          onClick={() => {
            onClose();
            resetGame();
          }}
        />
      </div>
    </div>
  );
};

export default CreditGameWinModal;
