import React, { useState } from "react";
import "./DebtMonthModal.css";

const DebtMonthModal = (props) => {
  const handlePayment = () => {
    props.addNextColumn();
  };

  if (props.debtMonthModalVisible) {
    return (
      <div className="month-modal">
        <h3>
          <strong>Interest added!</strong>
        </h3>
        <button onClick={handlePayment}>
          Next Month: <strong>{props.currentMonth}</strong>
        </button>
      </div>
    );
  } else {
    return null;
  }
};

export default DebtMonthModal;
