import React from "react";
import SignOut from "src/components/SignOut";
import { navLinks } from "../../navlinks";
import NavLink from "../Link";

import "./index.css";

const SideNavigation = ({ authUser }) => {
  return (
    <div className="sidenav__container">
      <div className="sidenav__wrapper">
        <div className="sidenav__areatop">
          <h5>
            RED Ed <span style={{ fontWeight: "normal", fontSize: 10 }}>™</span>
          </h5>
          {navLinks &&
            navLinks.map((link, index) => {
              return (
                <NavLink
                  key={index}
                  title={link.title}
                  iconName={link.iconName}
                  to={link.to}
                  adminAccessRequired={link.requiresAdmin}
                  isAdmin={authUser.isAdmin}
                />
              );
            })}
        </div>
        <SignOut />
      </div>
    </div>
  );
};

export default SideNavigation;
