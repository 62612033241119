import { toast } from "react-toastify";

// import { data } from "./data";
// https://ant.design/components/notification/
export const revenueNotification = (revenue) => {
  toast.success(
    `💰 You earned some revenue! You now have $${revenue} in the bank.`,
    {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};
