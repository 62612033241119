import React from "react";
import "./IntroModal.css";

const IntroModal = (props) => {
  const { introModalVisible } = props;
  const startButton = props.sheetId ? (
    <button
      onClick={props._hideIntroModal}
      className="investing-continue-button"
    >
      Continue
    </button>
  ) : null;

  if (introModalVisible) {
    const imageStyle = {
      alignSelf: "center",
    };

    return (
      <div className="blur-background">
        <div className="intro-modal-investing">
          <div className="investing-modal-contents">
            <h3>
              <strong>Welcome to the Investing Simulator!</strong>
            </h3>
            <img
              width="200px"
              height="auto"
              style={imageStyle}
              src="/assets/images/investing-intro.png"
              alt=""
            />
            <ul>
              <li>Trade Stocks</li>
              <li>Get into the Leaderboard ($3000 minimum!)</li>
              <li>Read Real News</li>
            </ul>
            {startButton}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default IntroModal;
