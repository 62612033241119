import React from "react";
import { Item } from "./Item";

export const Items = ({ questData, handleEdit }) => {
  return (
    <div className="quest-questions-container">
      {questData &&
        questData.map((questItem, index) => {
          return (
            <Item
              key={index}
              itemIndex={index}
              Text={questItem.Text}
              Answer={questItem.Answer}
              FreeText={questItem.FreeText}
              handleEdit={handleEdit}
            />
          );
        })}
    </div>
  );
};
