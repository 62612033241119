import React from "react";
import "./SaveToCard.css";
const SaveToCard = ({
  budgetsavedAccountType,
  amountDecision,
  budgetSavedInfo,
  type,
  handleDecision,
  state,
}) => {
  return (
    <div
      className="budgetsavedcard-decision"
      style={{
        backgroundColor:
          type === state.saveMonthToCheckingOrSavings ? "lightgray" : "white",
      }}
      onClick={() => {
        // calculate balance minus total expenses
        handleDecision(type, 540);
      }}
    >
      <div className="budgetsaved-accounttype">{budgetsavedAccountType}</div>
      <div className="budgetsaved-account">
        <div className="account-total">
          <div className="currency-title">$</div>
          <div className="total-budget-decision">{amountDecision}</div>
        </div>
        <div className="budget-saved-info">{budgetSavedInfo}</div>
      </div>
    </div>
  );
};

export default SaveToCard;
