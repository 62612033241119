import React, { memo } from "react";
import { Loading } from "../../../Loading";
import AddSectionForm from "../../../AddSectionForm";

import "./index.css";
import { NavSectionItem } from "./NavSectionItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const SectionsNavBar = ({
  lessonName,
  sections,
  userMode,
  remove,
  currentSection,
  toggleView,
  toggleEdit,
  onChange,
  addSection,
  currentSectionNumber,
  switchDown,
  switchUp,
  type,
  addBelow,
  handleDragDropReorder,
  newSectionAnimationIndex,
}) => {
  const onDragging = (result) => {
    if (!result.destination === null) return;
    handleDragDropReorder(result);
  };

  if (!sections)
    return (
      <div>
        {userMode === "ADMIN" ? (
          <AddSectionForm onChange={onChange} addSection={addSection} />
        ) : null}
      </div>
    );

  if (sections.length > 0) {
    return (
      <>
        <h4>{lessonName}</h4>
        <DragDropContext onDragUpdate={onDragging}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sections.sort(compare).map((section) => {
                  let { index } = section;
                  return (
                    <Draggable
                      key={index}
                      draggableId={`section-${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <NavSectionItem
                            key={index}
                            number={index}
                            provided={provided}
                            name={section.name}
                            currentSectionName={currentSection.name}
                            toggleView={toggleView}
                            toggleEdit={toggleEdit}
                            userMode={userMode}
                            remove={remove}
                            uid={section.uid}
                            section={section}
                            currentSectionNumber={currentSectionNumber}
                            switchUp={switchUp}
                            switchDown={switchDown}
                            type={type}
                            addBelow={addBelow}
                            newSectionAnimationIndex={newSectionAnimationIndex}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {userMode === "ADMIN" ? (
          <AddSectionForm
            onChange={onChange}
            addSection={addSection}
            type={type}
          />
        ) : null}
      </>
    );
  } else {
    return (
      <>
        {userMode === "ADMIN" ? (
          <AddSectionForm onChange={onChange} addSection={addSection} />
        ) : null}
        <Loading />
      </>
    );
  }
};

const compare = (a, b) => {
  if (a.index > b.index) return 1;
  if (a.index < b.index) return -1;
};

export default SectionsNavBar;
