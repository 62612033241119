import React from "react";
import "./Hint.css";

class Hint extends React.Component {
  state = {
    hint: this.props.hint,
    show: false,
  };

  toggleHint = () => {
    this.setState({
      show: true,
    });
  };

  render() {
    const hint = (
      <div className="hint-container">
        {this.state.hint
          ? this.state.hint
          : `Type ${this.props.answer} in cell ${this.props.range}`}
      </div>
    );
    return (
      <div>
        <div
          onClick={this.toggleHint}
          className="continue btn btn-danger btn-center"
        >
          Hint?
        </div>
        {this.state.show ? hint : null}
      </div>
    );
  }
}

export default Hint;
