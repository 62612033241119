import React, { useState, useEffect } from "react";
import "./GameComponent.css";

const GameComponent = (props) => {
  const [leave, setLeave] = useState(false);

  useEffect(() => {
    setLeave(false);
  }, [props.answer]);

  const sendToSheet = () => {
    console.log(
      "GameComponent.js 13 | send amount to sheet",
      typeof props.answer,
      props.answer,
      props.range,
      props.color
    );
    let answer = props.answer[0];
    console.log("GameComponent.js 19 | answer before being sent", answer);

    props._sendToSheet(props.answer[0], props.range, props.color);
    setLeave(true);
  };

  if (props.button) {
    return (
      <div className="game-container">
        <div className={leave ? "game-situation-leave" : "game-situation"}>
          <div onClick={sendToSheet} className="choice-container2">
            <h4>{props.button}</h4>
            <p>$ {props.answer}</p>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default GameComponent;
