import React, { useState } from "react";
import "./index.css";

import ModalWrapper from "src/components/shared/ModalWrapper";

import { Loading } from "src/components/Loading";
import { withFirebase } from "src/components/Firebase";
import { sendOneEmail } from "src/api/emails";
import { sendTextMessage } from "src/api/texts";
import { toast } from "react-toastify";

export const IssueFeedbackModal = ({
  firebase,
  lessonName,
  sectionName,
  lessonId,
  authUser,
}) => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [feedbackText, setFeedbackText] = useState("");

  const handleNotifyAdmins = (user, issue, section, lesson, lessonId) => {
    let message = `${user}, with email ${authUser.email}: issue on Lesson: ${lesson}, Section: ${section}. "${issue}". Access issue location: https://myreded.com/lesson/${lessonId}`;
    sendTextMessage("+51949350399", message);
    sendOneEmail(
      "sven@stoprecidivism.org",
      "New RED Issue Notification",
      message,
      "Sven"
    );
    sendOneEmail(
      "ignacio@stoprecidivism.org",
      "New RED Issue Notification",
      message,
      "Ignacio"
    );
    toast.success(
      `Thank you for the feedback and the information on the issue, we'll be reaching out within 24 hours to asisst you.`,
      {
        position: "top-center",
      }
    );
  };

  const handleSendFeedback = async () => {
    console.log(
      "FeedbackModal 12 | handle send feedback",
      lessonId,
      authUser.username,
      authUser.displayName,
      authUser.email
    );
    setLoading(true);
    try {
      firebase
        .issues(lessonId)
        .push({
          issue: feedbackText,
          lesson: lessonName,
          section: sectionName,
          lessonId,
          user: authUser.username || authUser.displayName || authUser.email,
          date: Date.now(),
          solved: false,
        })
        .then(() => {
          handleNotifyAdmins(
            authUser.username || authUser.displayName || authUser.email,
            feedbackText,
            sectionName,
            lessonName,
            lessonId
          );
          setLoading(false);
          setFeedbackOpen(false);
          setFeedbackText("");
        })
        .catch((err) => {
          console.log("FeedbackModal 36 | error sending feedback", err);
          setFeedbackText("");
          setLoading(false);
        });
    } catch (error) {
      console.log("FeedbackModal 36 | error sending feedback", error);
      setFeedbackText("");
      setLoading(false);
    }
  };

  const isDisabled = feedbackText === "";

  if (feedbackOpen === false) {
    return (
      <div className="feedback__toggler" onClick={() => setFeedbackOpen(true)}>
        Issues? Tell us about it!
      </div>
    );
  } else {
    return (
      <ModalWrapper>
        <div className="notificationfeedback__container">
          <div
            className="closebutton__modal"
            onClick={() => setFeedbackOpen(false)}
          >
            X
          </div>
          <div className="notificationcontent__container">
            <h5>Feedback</h5>
            <div className="feedback__specifics">
              Module: <span>{lessonName}</span>
            </div>
            <div className="feedback__specifics">
              Section: <span>{sectionName}</span>
            </div>
            <textarea
              type="text"
              placeholder="Kindly ensure that you describe the issue and its location in detail."
              className="form-control feedback__textarea"
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            />

            {!loading ? (
              <button
                className="feedback__button"
                onClick={() => {
                  handleSendFeedback();
                }}
                disabled={isDisabled}
              >
                Send
              </button>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </ModalWrapper>
    );
  }
};

export default withFirebase(IssueFeedbackModal);
