import React from "react";
import "./StepsDiv.css";
const StepsDiv = ({ actionText, text }) => {
  return (
    <div className="steps7">
      <div className="div-step">{actionText}</div>
      <div className="register-to-the-online-classro-wrapper">
        <div className="register-to-the">{text}</div>
      </div>
    </div>
  );
};

export default StepsDiv;
