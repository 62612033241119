import React from "react";
import LifestyleChoicesForm from "./LifestyleChoicesForm";
import "./BudgetingGamePickLifestyle.css";
import LifestyleExpensesTable from "./LifestyleExpensesTable";
import { fixedExpenses, jobs } from "../../gameData";
import LevelNumberTag from "./LevelNumberTag";

const BudgetingGamePickLifestyle = ({
  onSelectJob,
  onSelectExpense,
  state,
  submitExpenses,
}) => {
  return (
    <div className="budgetinggamepicklifestyle">
      <div className="lifestylecontainer">
        <div className="chooselifestylecol">
          <div className="picklifestyletitle">
            <LevelNumberTag level={state.level} />
            <div className="lifestyleoptionstitle">Choose your Lifestyle</div>
          </div>

          <div className="budgetinggame-optionscontainer">
            {/* Income Choice */}
            <LifestyleChoicesForm
              image2={`https://d1xzdqg8s8ggsr.cloudfront.net/64ff7c35accc976fec01ef63/38731a2b-e23e-4307-8c75-e8c27f44fa09_1696458627069853023?Expires=-62135596800&Signature=sNmoLK1R1ETyTW7s4iVt9bEf6WLw2KjuvBiWhBF-EOzuYkeNvDD71x1O3-XDahuwlV17CMtEfJ5cEWjn4T~wYShM-p4amudlY7HAn~X-fWu0Dk~xmX~trSYvABwHlP94ss1UMKTS9xpJmW~ac23g2BF-k0AeCX1kCE8ylIL6Cl8ncMrBQrM3k0qTrE6jzvoYU6wsG026xHM3qHKP5b9wnORdps52ZU8iZjDeUTbr8OQ40ycLeGAxiX6gBMxAt0tcsscT3yYk-uMxFd4ilFuaI8ohGbY7-VwevEO8tI2oZ6z0GbGPaAvgt5O6uEYaTETtDJwjxSukjiZPLm2i3Z6jCA__&Key-Pair-Id=K1P54FZWCHCL6J`}
              lifestyleTitle={"Job Selection"}
              options={jobs}
              onSelectJob={onSelectJob}
              type="job"
              state={state}
            />
            {/* Fixed Expenses */}
            {fixedExpenses &&
              Object.entries(fixedExpenses).map((fixedExpense, index) => {
                const category = fixedExpense[0];
                const options = fixedExpense[1];
                return (
                  <LifestyleChoicesForm
                    key={index}
                    lifestyleTitle={category}
                    options={options}
                    onSelectExpense={onSelectExpense}
                    type="expense"
                    state={state}
                  />
                );
              })}
          </div>
        </div>
        <LifestyleExpensesTable state={state} submitExpenses={submitExpenses} />
      </div>
    </div>
  );
};

export default BudgetingGamePickLifestyle;
