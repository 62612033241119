const initialState = { modules: [], feedbackModalOpen: false };

export const modulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "modules/set":
      return { ...state, modules: action.payload };
    case "modules/feedbackModalOpen":
      return { ...state, feedbackModalOpen: action.payload };

    default:
      return state;
  }
};
