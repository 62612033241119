import React from "react";

import "./CreditSimLoseModal.css";
import Button from "../../../../../shared/Button";
import DebtModalChart from "./DebtModalChart";
const CreditSimLoseModal = ({ onClose, resetGame, state, months }) => {
  return (
    <div className="creditsimlosemodal">
      <div className="title">
        <div className="you-lost">You Lost!</div>
      </div>
      <div className="you-didnt-pay">You didn’t pay your debts in time.</div>
      <h3 className="pending__debt">
        Pending Debt: ${" "}
        {state.currentDebt >= 0 ? state.currentDebt.toFixed(2) : 0}
      </h3>
      <DebtModalChart state={state} months={months} />
      <Button title="Restart" onClick={resetGame} />
    </div>
  );
};

export default CreditSimLoseModal;
