import React from "react";
import LifestyleOption from "./LifestyleOption";
import "./LifestyleChoicesForm.css";
import { jobs } from "../../gameData";
import { capitalizeFirstLetter } from "src/utils/utils";

const LifestyleChoicesForm = ({
  state,
  lifestyleTitle,
  options,
  type,
  onSelectExpense,
  onSelectJob,
  category,
}) => {
  const handleChoice = (option, category) => {
    if (type === "job") {
      onSelectJob(option);
    }
    if (type === "expense") {
      onSelectExpense(option, lifestyleTitle);
    }
  };

  return (
    <div className="lifestylechoicescontainer">
      <div className="income-heading">
        {capitalizeFirstLetter(lifestyleTitle)}
      </div>
      <div className="income-options-container">
        {options &&
          options.map((option, index) => {
            return (
              <LifestyleOption
                key={index}
                index={index}
                option={option}
                handleChoice={handleChoice}
                type={type}
                category={lifestyleTitle}
                state={state}
              />
            );
          })}
      </div>
    </div>
  );
};

export default LifestyleChoicesForm;
