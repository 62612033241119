import React from "react";
import Delete from "./components/Delete";

const MultiDrapDropEdit = ({ index, removeContentAtIndex }) => {
  return (
    <div>
      <p>MultiDragDropEdit</p>
      <Delete removeContentAtIndex={removeContentAtIndex} index={index} />
    </div>
  );
};

export default MultiDrapDropEdit;
