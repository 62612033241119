export const getClassModules = async (firebase, classroomData) => {
  let modulesAvailable = classroomData.settings.modules;
  let modulesInClass;
  if (modulesAvailable) {
    const modulesReq = await firebase.getModulesOverview().once("value");
    if (modulesReq.val()) {
      modulesInClass = Object.values(modulesReq.val()).filter(
        (moduleFromDb) => {
          return modulesAvailable[moduleFromDb.uid];
        }
      );
      return modulesInClass;
    } else {
      return [];
    }
  } else {
    return [];
  }
};
