import {
  SET_CLASSROOMS,
  SET_CURRENT_CLASSROOM_ID,
  SET_CURRENT_CHAT_ID,
  SET_CURRENT_CLASSROOMS,
  SET_CURRENT_CLASS_USERS,
  SET_STATUS,
  SET_ERROR,
  FETCH_CHATROOM_STUDENT_SUCCESS,
  CLEAR_CLASSROOM,
  FETCH_CHATROOM_REQUEST,
  FETCH_CHATROOM_SUCCESS,
  FETCH_CHATROOM_FAILURE,
  SET_CHATROOM_DATA,
  TOGGLE_CHAT_BUBBLE,
} from "./chatActionTypes";

const initialState = {
  classrooms: null,
  currentClassroomId: null,
  currentChatId: null,
  currentClassRooms: null,
  currentClassUsers: null,
  status: "idle",

  currentChatroomLoading: false,
  currentChatroomError: null,
  error: null,

  currentStudentChat: null,
  currentStudentChatError: null,
  currentStudentChatLoading: false,

  chatBubbleOpen: false,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLASSROOMS:
      return { ...state, classrooms: action.payload };
    case SET_CURRENT_CLASSROOM_ID:
      return { ...state, currentClassroomId: action.payload };
    case SET_CURRENT_CHAT_ID:
      console.log("chatReducer.js 44 | chat id set", action.payload);
      return { ...state, currentChatId: action.payload };
    case SET_CURRENT_CLASSROOMS:
      return { ...state, currentClassRooms: action.payload };
    case SET_CURRENT_CLASS_USERS:
      return { ...state, currentClassUsers: action.payload };
    case SET_STATUS:
      return { ...state, status: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };

    case CLEAR_CLASSROOM:
      return {
        ...state,
        currentClassRooms: null,
        currentClassUsers: null,
        currentClassroomId: null,
      };

    case FETCH_CHATROOM_STUDENT_SUCCESS: {
      return { ...state, currentStudentChat: action.payload };
    }
    case FETCH_CHATROOM_REQUEST:
      return {
        ...state,
        currentChatroomLoading: true,
        currentChatroomError: null,
      };
    case FETCH_CHATROOM_SUCCESS:
      return {
        ...state,
        currentChatroomLoading: false,
        currentClassRooms: action.payload.rooms,
        currentClassUsers: action.payload.users,
      };
    case FETCH_CHATROOM_FAILURE:
      return {
        ...state,
        currentChatroomLoading: false,
        currentChatroomError: action.payload,
      };
    case SET_CHATROOM_DATA:
      return {
        ...state,
        currentClassRooms: action.payload.rooms,
        currentClassUsers: action.payload.users,
      };
    case TOGGLE_CHAT_BUBBLE:
      return {
        ...state,
        chatBubbleOpen: action.payload,
      };
    default:
      return state;
  }
};

export default chatReducer;
