import React, { useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import Compressor from "compressorjs";

// #1 import quill-image-uploader

// #2 register module
import "./index.css";

import SwitchItemOrderButtons from "../shared/SwitchItemOrderButtons";

import firebase from "firebase/app";
import ImageUploader from "quill-image-uploader";
import { toast } from "react-toastify";
import "react-quill/dist/quill.snow.css";

Quill.register("modules/imageUploader", ImageUploader);

export const Text = ({
  editSection,
  index,
  removeContentAtIndex,
  value,
  _moveSectionContentDown,
  _moveSectionContentUp,
}) => {
  const handleEdit = (e) => {
    editSection(e, index, "text");
  };

  return (
    <div className="text-edit">
      <div className="editcontent__title">Add Text</div>

      <ReactQuill
        value={value}
        placeholder="Enter text or paste image here..."
        onChange={(e) => handleEdit(e)}
      />

      <div className="movedeletecontent__container">
        <SwitchItemOrderButtons
          index={index}
          _moveSectionContentDown={_moveSectionContentDown}
          _moveSectionContentUp={_moveSectionContentUp}
        />
        <div
          className="deletebutton__button"
          onClick={(e) => removeContentAtIndex(e, index)}
        >
          X
        </div>
      </div>
    </div>
  );
};

const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-font">
      <option value="arial" selected>
        Arial
      </option>
      <option value="comic-sans">Comic Sans</option>
      <option value="courier-new">Courier New</option>
      <option value="georgia">Georgia</option>
      <option value="helvetica">Helvetica</option>
      <option value="lucida">Lucida</option>
    </select>
    <select className="ql-size">
      <option value="extra-small">Size 1</option>
      <option value="small">Size 2</option>
      <option value="medium" selected>
        Size 3
      </option>
      <option value="large">Size 4</option>
    </select>
    <select className="ql-align" />
    <select className="ql-color" />
    <select className="ql-background" />
    <button className="ql-clean" />
    {/* <button className="ql-insertHeart">
      <CustomHeart />
    </button> */}
  </div>
);

const uploadAndGetFileUrl = async (imageFile) => {
  return firebase
    .storage()
    .ref(`files/${imageFile.name}`)
    .put(imageFile)
    .then(() => {
      console.log("Text 27 | uploaded successfully");
      return firebase
        .storage()
        .ref(`files/${imageFile.name}`)
        .getDownloadURL()
        .then((url) => {
          console.log("Text 31 | got url", url);
          return url;
        })
        .catch((error) => {
          console.log("Text 54 | ", "ERROR", error);
          throw new Error("Unable to get file url", error.message);
        });
    })
    .catch((error) => {
      throw new Error("Unable to get file url", error.message);
    });
};

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "image", "link"], // toggled buttons
    [{ header: [4, 5, 6, false] }],
    ["blockquote"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ align: [] }],
  ],
  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.8,
          success(result) {
            const formData = new FormData();
            formData.append("image", result);
            uploadAndGetFileUrl(result)
              .then((url) => {
                resolve(url);
              })
              .catch((error) => {
                toast("Issue with image upload, contact support.");
                reject(error.message);
              });
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
  },
};
