import React from "react";
import { Modal } from "antd";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom"; // Changed from useNavigate
import { Button } from "../../shared/Button";
import '../index.css';


const DeleteEventModal = ({ isOpen, closeModal, eventId, firebase, onSuccess }) => {
  const [confirmText, setConfirmText] = React.useState("");
  const history = useHistory(); // Changed from navigate


  const handleDelete = async () => {
    if (confirmText.toLowerCase() !== "delete") {
      toast.error("Please type 'delete' to confirm");
      return;
    }

    try {
      await firebase.getEventData(eventId).remove();
      toast.success("Event deleted successfully");
      onSuccess();
      history.push('/home'); // Changed from history.goBack()
    } catch (error) {
      console.error("Error deleting event:", error);
      toast.error("Error deleting event");
    }
    closeModal();
  };

  return (
    <Modal
      title="Delete Event"
      open={isOpen}
      onCancel={closeModal}
      footer={null}
    >
      <div className="delete-event-modal">
        <p>Are you sure you want to delete this event? This action cannot be undone.</p>
        <p>Type "delete" to confirm:</p>
        <input
          type="text"
          value={confirmText}
          onChange={(e) => setConfirmText(e.target.value)}
          className="form-input delete-confirm-input"
          placeholder="Type 'delete' to confirm"
          autoComplete="off"
        />
        <div className="delete-button-container">
          <Button
            title="Delete"
            onClick={handleDelete}
            type={confirmText.toLowerCase() !== "delete" ? "disabled" : "primary"}
            className="delete-button"
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteEventModal; 