import React, { Component } from "react";
import "./index.css";
import ReactPlayer from "react-player/lazy";
import { compose } from "recompose";
import { withFirebase } from "../../../Firebase";
import { AuthUserContext } from "../../../Session";
import Button from "src/components/shared/Button";

class Video extends Component {
  static contextType = AuthUserContext;
  state = {
    progress: 0,
    playing: false,
  };

  componentDidMount() {}

  videoEnds = (override = false) => {
    this.props.videoDone(override);
  };

  handleProgress = (e) => {
    this.setState({ progress: e.played });
  };

  handleDuration = (e) => {
    if (!this.context.isAdmin) return;
    if (this.props.item.duration) {
    } else {
      this.props.updateVideoDuration(e, this.props.index);
    }
  };

  render() {
    const { source } = this.props;

    return (
      <>
        <ReactPlayer
          title="video"
          className="video-watch"
          url={source}
          onDuration={(e) => this.handleDuration(e)}
          width={"70%"}
          controls={
            this.props.videosDone || process.env.NODE_ENV === "development"
              ? true
              : false
          }
          onClickPreview={() => {
            console.log("Video 39 | logging on click");
          }}
          onProgress={(e) => {
            this.handleProgress(e);
          }}
          style={{ marginBottom: 20 }}
          config={{
            file: {
              attributes: {
                controlsList: "noremoteplayback",
                // controlsList: "nodownload",
              },
            },
          }}
          playing={this.state.playing}
          height={400}
          onEnded={() => this.videoEnds()}
        />
        <Button
          title={this.state.playing ? "Pause" : "Play"}
          type={"outline"}
          onClick={() => {
            this.setState({ playing: !this.state.playing });
          }}
        />
        <div>{Math.floor(this.state.progress * 100)} %</div>
        {/* <button
          onClick={() => {
            this.setState({ playing: !this.state.playing });
          }}
        >
          {this.state.playing ? "Pause" : "Play"}
        </button> */}

        {/* {this.context.isAdmin || isDevelopment() ? (
          <button onClick={() => this.videoEnds()}>Override Video</button>
        ) : null} */}
      </>
    );
  }
}

export default compose(withFirebase)(Video);
