export const assessmentCategoryData = {
  "Self-Awareness": {
    intro: `Gotta Be True focuses on two things:  YOU being true to yourself by knowing yourself and being aware of what is going on with you and your emotions at any given time in any situation.  This is the foundation of Emotional Intelligence.  You get this one down, the rest is easy!`,
    positive: `The key to any success in any area of life is to be aware and mature in these five (5) competencies or abilities.  These help you understand who you are and essentially how you “show up” to other people.  These abilities are like an anchor to steady you and keep you standing.  The 5 abilities in this category help us communicate what we feel; help us identify influences in your life that move you to behave a specific way; aid us in stepping forward, standing still or moving back depending on the situation;  Allow you to see yourself the way other people see you in a true light and care for yourself in the way that shows that you respect and love yourself for who you are as a person.
`,
    negative: `The lack or immaturity in these areas show up in our behave and communication.  You can’t figure out what you are feeling or why.  You can’t see what is pushing your around to behave a certain way; We then react to situations instead of responding in a thoughtful way;  We lie to ourselves that we are one way when everyone else sees us in the way that we really show up and lastly we constantly disrespect ourselves by not taking care of our bodies, minds and spirits…and that reflects not only on you, but also, on the way we treat others.`,
  },
  "Emotional Management": {
    intro: `Be on Point means to watch what is going on with you and others.  To manage the way you feel and make sure that you feel powerful enough to change what needs to change.`,
    positive: `When we are mature in area we are thinking clearly about:  If I do this, then that is going to happen; We can “use” emotions rather than just “have” them.  I am able to motivate myself when there is no one pushing me and I feel confident that you are able to do anything you put your mind and hand to;  You begin to control how you respond to specific situations and people, like you have not before because you are able to flex and adapt to situations that don’t go the way you think they should.  Essentially you show up as your real self with no mask, no need to hide or pretend who you are (without shame) and you are driven to change the things that you need and want to change.  This is powerful!`,
    negative: `If this area is a struggle for you, don’t worry.  Everything changes when we put in a little effort.  How doe you eat an elephant?  One bite at a time!  

Consider the fact that is this area is weaker than others you see it in your own thinking patterns;  You see it by putting on a mask so others can’t really get to know who you are; You remain inflexible and get mad when things don’t go the way you want them to go and they will see how displeased you are with that.  Not only should we be managing our emotions, but also we want to familiarize ourselves with this so that we can see it in others who might need a hand or help.`,
  },
  "Emotional Connection": {
    intro: `I Feel Ya: Emotional Connection, is the section expressing how we connect with one another to create lasting and important friendships and connections.`,
    positive: `If we are mature in this area we focus on other people and how they are, not what we are doing and what we have to say.  When you connect with someone around an experience that you have had in common it creates a bond that is difficult to separate;  When you talk to people you are thinking about what you want to leave behind with them or how you want to leave them feeling before you communicate;  And we realize that communication is not about us talking about what we did or who we are.  It is about listening and understanding what the other person has to say first before we give our opinion or fact.`,
    negative: `If we are not mature in this area, we will find ourselves alone a lot.  People mostly will not want to hang out with out cause of the way we make them feel and or we don’t care how they are when we stop the conversation.  If communication is just about you talking about you or another person?  That is going to likely leave you spending a lot of time alone and that is not the “healthy” alone time we are talking about.  This is a lack of relationship alone and despite what we say when we are hurt by others, we still need people on this planet.  So let’s make the most of our abilities to connect, learn, and grow ourselves and others.`,
  },
};
