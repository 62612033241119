import React from "react";
import HeaderInfoContent from "./HeaderInfoContent";
import "./CreditGameInfoHeader.css";
const CreditGameInfoHeader = ({ state }) => {
  const { timeLeft, debts, savings, interestRate, payments, currentMonth } =
    state;
  return (
    <div className="creditgameinfoheader">
      <HeaderInfoContent
        infoTitle="Total Debt"
        infoAmount={parseFloat(state.currentDebt).toFixed(2)}
      />
      <HeaderInfoContent infoTitle="Monthly Savings" infoAmount={savings} />
      <HeaderInfoContent
        infoTitle="Months Left to Pay"
        infoAmount={`${timeLeft} months`}
      />
      <HeaderInfoContent
        infoTitle="Interest"
        infoAmount={`${(interestRate * 100) / 12} %`}
      />
    </div>
  );
};

export default CreditGameInfoHeader;
