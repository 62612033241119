import React from "react";
import "./index.css";

const ToggleSwitch = ({userType, toggleUser}) => {

    const containerClassName = `loginModal-toggle-component ${ userType === 'mentor' ? ' active' : ''}`
    return (
      <div
        className={containerClassName}
        onClick={() => toggleUser(userType === 'mentor' ? 'user' : 'mentor')}>
        <div className="loginModal-toggle-button" style={{left: userType === 'mentor' ? '75px' : '4px'}}>
          <div className='loginModal-toggle-text-button' style={{color: userType === 'mentor' ? '#FB7C23' : '#D94645'}}>{ userType === 'mentor' ? 'Mentor' : 'Student'}</div>
        </div>
        <div className="loginModal-text-outside-button " style={{right: userType === 'mentor' ? '95px' : '14px'}}>{ userType === 'mentor' ? 'Student' : 'Mentor'}</div>
      </div>
    )
  }


export default ToggleSwitch;