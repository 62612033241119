import React from "react";
import "./index.css";

export const UserInput = ({
  editSection,
  item,
  index,
  removeContentAtIndex,
}) => {
  return (
    <div className="quiz-edit" key={index}>
      <h3 className="editcontent__title">User Input</h3>
      <div>Question</div>
      <div className="quiz-edit-question">
        <input
          className="imageinput__text"
          value={item.question}
          placeholder="Question"
          name="question"
          onChange={(e) => editSection(e, index, "user-input")}
        />
      </div>
      <div>Answer placeholder</div>
      <div className="quiz-edit-question">
        <input
          className="imageinput__text"
          style={{ color: "gray" }}
          value={item.question}
          placeholder="placeholder"
          name="placeholder"
          onChange={(e) => editSection(e, index, "user-input")}
        />
      </div>

      <button
        className="btn btn-danger"
        onClick={(e) => removeContentAtIndex(e, index)}
      >
        Delete User Input
      </button>
    </div>
  );
};
