import React from "react";

const FileDownload = ({ item }) => {
  return (
    <div>
      <a className="btn btn-success btn-sm" target="blank" href={item.url}>
        Download
      </a>
    </div>
  );
};

export default FileDownload;
