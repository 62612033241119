import React from "react";
import "./InstructionsModal.css";

const InstructionsModal = (props) => {
  const { instructionsModalVisible } = props;
  const startButton = (
    <button onClick={props._hideInstructionsModal} className="btn btn-primary">
      Set a Goal
    </button>
  );

  if (instructionsModalVisible) {
    return (
      <div className="instructions-modal">
        <div className="modal-contents">
          <h3>
            {" "}
            <strong>Instructions</strong>
          </h3>
          <h5>
            You will play a 5 year <strong>market simulation</strong> with{" "}
            <strong>real prices.</strong>
          </h5>
          <hr />
          <h5>
            Reach your <strong>Net Worth</strong> goal
          </h5>

          {startButton}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default InstructionsModal;
