export function analyzeCompetencyResults(results) {
  const competencyResults = {};

  results.forEach((item) => {
    const competency = item.competency;
    const category = item.category;
    const value = item.value;

    if (!competencyResults[category]) {
      competencyResults[category] = {
        totalCategoryScore: 0,
        totalPossibleScore: 0,
      };
    }

    competencyResults[category].totalCategoryScore += value;

    if (competencyResults[category][competency]) {
      competencyResults[category][competency].totalValue += value;
      competencyResults[category][competency].count += 1;
    } else {
      competencyResults[category][competency] = { totalValue: value, count: 1 };
    }

    // Increment the total possible score for this category by 5 for each new competency question
    competencyResults[category].totalPossibleScore += 5;
  });

  // Calculate the average and percentage score for each competency within each category
  for (const category in competencyResults) {
    for (const competency in competencyResults[category]) {
      if (
        competency !== "totalCategoryScore" &&
        competency !== "totalPossibleScore"
      ) {
        competencyResults[category][competency].average =
          competencyResults[category][competency].totalValue /
          competencyResults[category][competency].count;
      }
    }

    // Calculate the percentage score for this category
    competencyResults[category].percentageScore =
      (competencyResults[category].totalCategoryScore /
        competencyResults[category].totalPossibleScore) *
      100;
  }

  return competencyResults;
}
