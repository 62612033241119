import React from "react";

export const SectionTitle = ({ name, onChange }) => {
  return (
    <>
      <input
        type="text"
        name="name"
        className="title-name"
        value={name}
        onChange={onChange}
        placeholder="Section Name..."
      />
    </>
  );
};
