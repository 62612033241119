import React from "react";
import moment from "moment"; // Make sure you have moment installed for date formatting
import "./index.css";
import { useDispatch } from "react-redux";
import { setCurrentChatId } from "src/store/chats/chatActions";

const ChatMessagePreview = ({
  user,
  authUser,
  rooms,
  setActiveChat,
  setCurrentChatContact,
  firebase,
  classId,
  isGroupChat = false,
  setGroupChatActive,
}) => {
  const contactId = user[0] || {};
  const dispatch = useDispatch();
  const chatId =
    isGroupChat === false
      ? [authUser.uid, contactId].sort().join("-")
      : classId;
  const userChatData = rooms[chatId];
  const { date, message, sender, read } = userChatData || {};
  const showLastMessage = contactId === sender || authUser.uid === sender;

  if (contactId === authUser.uid) {
    return null;
  }

  return (
    <div
      onClick={() => {
        dispatch(setCurrentChatId(chatId));
        setActiveChat(true);
        if (isGroupChat) {
          setActiveChat(true);
          setGroupChatActive(true);
        } else {
          setGroupChatActive(false);
        }

        setCurrentChatContact(
          isGroupChat === false ? user[1] : "RED Group Chat"
        );
        firebase.roomsInChatroom(classId, chatId).update({ read: true });
      }}
      className="chatmessagepreview__container"
    >
      <div className="chatmessage__top">
        <div className="chatmessage__name">
          {isGroupChat ? "RED Group" : user[1]}
        </div>
        <div className="chatmessage__date">
          <div>{date && moment(date).format("DD-MM")}</div>
          {/* <div>{read ? "" : <div className="unread__ball" />}</div> */}
        </div>
      </div>
      <div className="chatmessage__lastmessage">
        {showLastMessage ? (
          <div
            className={
              !read
                ? sender === authUser.uid
                  ? "chatmessage__read"
                  : "chatmessage__unread"
                : "chatmessage__read"
            }
          >
            {`${message?.length > 62 ? message.slice(0, 62) : message}`}{" "}
            {message?.length > 62 ? "..." : ""}
          </div>
        ) : (
          "No messages yet..."
        )}
      </div>
    </div>
  );
};

export default ChatMessagePreview;
