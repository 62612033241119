const setupOptions = {
  jobs: {
    id: "jobs",
    title: "Choose Your Job",
    subtitle: "Win the game to unlock more job options!",
    options: [
      {
        title: "Intern at Startup",
        amount: 2000,
        happy: 0,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/store_manager.png",
        imgAlt: "store_manager",
      },
      {
        title: "Account Manager",
        amount: 2300,
        happy: 0,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/budgeting.png",
        imgAlt: "store_manager",
      },
      {
        title: "Stocks Trader",
        amount: 2700,
        happy: 0,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/stocks_trader.png",
        imgAlt: "stock_trade",
      },
      {
        title: "Video Game Designer",
        amount: 2500,
        happy: 0,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/videogame_designer.png",
        imgAlt: "video_game_designer",
      },
      {
        title: "Music Producer",
        amount: 2100,
        happy: 0,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/music.png",
        imgAlt: "music",
      },
    ],
  },
  housing: {
    id: "housing",
    title: "Housing Options",
    subtitle: "How would you like to live?",
    options: [
      {
        title: "Live with your parents",
        amount: 150,
        happy: 1,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/live-with-parents.png",
        imgAlt: "family",
      },
      {
        title: "Rent with friends",
        amount: 500,
        happy: 2,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/live-with-friends.png",
        imgAlt: "store manager",
      },
      {
        title: "Rent alone",
        amount: 1000,
        happy: 3,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/live-alone.png",
        imgAlt: "store manager",
      },
    ],
  },
  insurance: {
    id: "insurance",
    title: "Your Health Insurance Options",
    warning:
      "Careful. If you get an emergency and chose the cheap insurance, you/'ll have to pay more.",
    subtitle:
      "Imagine that you get a hospital bill for $10,000. If you have health insurance you only have to pay the deductible.",
    options: [
      {
        title: "No Insurance",
        amount: 0,
        deductible: 3000,
        happy: 4,
        text: function () {
          return `$${this.amount} / month. You pay for the full emergency`;
        },
        imgSrc: "/assets/images/cheap-insurance.png",
        imgAlt: "store manager",
      },
      {
        title: "Cheap",
        amount: 200,
        deductible: 900,
        happy: 3,
        text: function () {
          return `$${this.amount} / month and $${this.deductible} deductible`;
        },
        imgSrc: "/assets/images/cheap-insurance.png",
        imgAlt: "store manager",
      },
      {
        title: "Expensive",
        amount: 300,
        deductible: 500,
        happy: 2,
        text: function () {
          return `$${this.amount} / month and $${this.deductible} deductible`;
        },
        imgSrc: "/assets/images/fancy-insurance.png",
        imgAlt: "store manager",
      },
    ],
  },
  food: {
    id: "food",
    title: "Your Food Options",
    subtitle: "How would you like to eat?",
    options: [
      {
        title: "Just the basics",
        amount: 150,
        happy: 2,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/normal-food.png",
        imgAlt: "store manager",
      },
      {
        title: "Half good, half McDonalds",
        amount: 250,
        happy: 2,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/normal-food.png",
        imgAlt: "store manager",
      },
      {
        title: "Buy all organic",
        amount: 350,
        happy: 3,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/organic.png",
        imgAlt: "teacher",
      },
    ],
  },
  transport: {
    id: "transport",
    title: "Your Transportation Options",
    subtitle: "How would you like to move around?",
    options: [
      {
        title: "Public Transportation",
        amount: 100,
        happy: 1,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/subway-option.png",
        imgAlt: "public transportation",
      },
      {
        title: "Drive a Toyota",
        amount: 200,
        happy: 2,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/cheap-car.png",
        imgAlt: "cheap car",
      },
      {
        title: "Drive a Tesla",
        amount: 500,
        happy: 3,
        text: function () {
          return `$${this.amount} per month`;
        },
        imgSrc: "/assets/images/fancy-car.png",
        imgAlt: "fancy car",
      },
    ],
  },
};

export default setupOptions;
