import React from "react";
import Cell from "../Cell.js";

const styles = {
  container: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  specialCharacter: {
    fontWeight: "bold",
    color: "orange",
  },
};

export const interestSteps = [
  {
    title: "Simple Interest",
    text: (
      <span style={{ fontSize: 16 }}>
        Now you'll see how money grows over time. Say you were to give money
        again to Alex. On cell <span style={styles.specialCharacter}>A1</span>{" "}
        type <span style={styles.specialCharacter}>Interest</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <Cell cellName={"A1"} onTextChange={setCellText} />
      </div>
    ),
    answer: "interest",
    range: "A1",
  },
  {
    title: "Simple Interest II",
    text: (
      <span style={{ fontSize: 16 }}>
        With Simple Interest, your money grows by the SAME AMOUNT each period
        (month, year). It's easy because your returns are ALWAYS THE SAME. Type{" "}
        <span style={styles.specialCharacter}>0.5</span> on{" "}
        <span style={styles.specialCharacter}>B1</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "0.5",
    range: "B1",
  },
  {
    title: "Simple Interest III",
    text: (
      <span style={{ fontSize: 16 }}>
        Let's add the initial amount. On cell{" "}
        <span style={styles.specialCharacter}>A4</span> type{" "}
        <span style={styles.specialCharacter}>Initial Amount</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ marginRight: 120 }}>
          <Cell cellName={"A4"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "initial amount",
    range: "A4",
  },
  {
    title: "Simple Interest IV",
    text: (
      <span style={{ fontSize: 16 }}>
        Type the original amount given to Alex. On cell{" "}
        <span style={styles.specialCharacter}>A5</span> type{" "}
        <span style={styles.specialCharacter}>$10,000</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ marginRight: 120 }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
        </div>
        <div style={{ marginRight: 120 }}>
          <Cell cellName={"A5"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "10000",
    range: "A5",
  },
  {
    title: "Simple Interest Period 1",
    text: (
      <span style={{ fontSize: 16 }}>
        Let's add periods. On cell{" "}
        <span style={styles.specialCharacter}>B4</span> type{" "}
        <span style={styles.specialCharacter}>Year 1</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} onTextChange={setCellText} />
        </div>
        <div style={{ marginRight: 120 }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
        </div>
      </div>
    ),
    answer: "year 1",
    range: "B4",
  },
  {
    title: "Simple Interest Calculation Year 1: IMPORTANT",
    text: (
      <span style={{ fontSize: 16 }}>
        Pay close attention. You will calculate the growth based on the INTEREST
        of the INITIAL AMOUNT ONLY (50% of $10,000) for EVERY period. You will
        add the previous period's amount plus the interest of the initial
        amount. $10,000 (original amount) + 50% of that amount ($5,000). The
        final result will be $10,000 + $5,000. On cell{" "}
        <span style={styles.specialCharacter}>B5</span> type{" "}
        <span style={styles.specialCharacter}>15000</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "15000",
    range: "B5",
  },
  {
    title: "Simple Interest Year 2",
    text: (
      <span style={{ fontSize: 16 }}>
        Let's add the next period. On cell{" "}
        <span style={styles.specialCharacter}>C4</span> type{" "}
        <span style={styles.specialCharacter}>Year 2</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
        </div>
      </div>
    ),
    answer: "year 2",
    range: "C4",
  },
  {
    title: "Simple Interest Calculation Year 2",
    text: (
      <span style={{ fontSize: 16 }}>
        Period 2 will increase by the SAME amount of simple interest of the
        INITIAL AMOUNT (50% of $10,000). The first period's interest was 50% of
        $10,000, or $5,000. So in Year 2 it will increase by the same amount,
        type <span style={styles.specialCharacter}>20000</span> on cell{" "}
        <span style={styles.specialCharacter}>C5</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "20000",
    range: "C5",
  },
  {
    title: "Simple Interest Year 3",
    text: (
      <span style={{ fontSize: 16 }}>
        Let's add the next year. On cell{" "}
        <span style={styles.specialCharacter}>D4</span> type{" "}
        <span style={styles.specialCharacter}>Year 3</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
        </div>
      </div>
    ),
    answer: "year 3",
    range: "D4",
  },
  {
    title: "Simple Interest Year 3",
    text: (
      <span style={{ fontSize: 16 }}>
        Now you have to calculate Year 3 on your own. Remember, you have to add
        the previous year, plus the Initial Amount multiplied by the interest.
        The formula should be ={" "}
        <span style={styles.specialCharacter}>
          (InitialAmount x Interest) + PreviousYearAmount
        </span>
        . On cell <span style={styles.specialCharacter}>D5</span> calculate
        simple interest for Year 3.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} value={"Year 3"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
          <Cell cellName={"D5"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "25000",
    range: "D5",
  },
  {
    title: "Simple Interest Year 4",
    text: (
      <span style={{ fontSize: 16 }}>
        Let's add the next year. On cell{" "}
        <span style={styles.specialCharacter}>E4</span> type{" "}
        <span style={styles.specialCharacter}>Year 4</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} value={"Year 3"} editable={false} />
          <Cell cellName={"E4"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
          <Cell cellName={"D5"} value={"$25,000"} editable={false} />
        </div>
      </div>
    ),
    answer: "year 4",
    range: "E4",
  },
  {
    title: "Simple Interest Year 4",
    text: (
      <span style={{ fontSize: 16 }}>
        Finally, calculate year 4 on your own as well.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} value={"Year 3"} editable={false} />
          <Cell cellName={"E4"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
          <Cell cellName={"D5"} value={"$25,000"} editable={false} />
          <Cell cellName={"E5"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "30000",
    range: "E5",
  },
  {
    title: "Year 4",
    text: (
      <span style={{ fontSize: 16 }}>
        Type <span style={styles.specialCharacter}>Initial Amount</span> on cell{" "}
        <span style={styles.specialCharacter}>A7</span>
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} value={"Year 3"} editable={false} />
          <Cell cellName={"E4"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
          <Cell cellName={"D5"} value={"$25,000"} editable={false} />
          <Cell cellName={"E5"} value={"$30,000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A7"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "initial amount",
    range: "A7",
  },
  {
    title: "Initial Amount",
    text: (
      <span style={{ fontSize: 16 }}>
        Let's invest another $10,000. Type{" "}
        <span style={styles.specialCharacter}>10000</span> on{" "}
        <span style={styles.specialCharacter}>A8</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} value={"Year 3"} editable={false} />
          <Cell cellName={"E4"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
          <Cell cellName={"D5"} value={"$25,000"} editable={false} />
          <Cell cellName={"E5"} value={"$30,000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A7"} value={"Initial amount"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A8"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "10000",
    range: "A8",
  },
  {
    title: "Growth on Growth",
    text: (
      <span style={{ fontSize: 16 }}>
        Compound interest grows based on the PREVIOUS period. You will calculate
        it now. Calculate interest on your initial amount on cell{" "}
        <span style={styles.specialCharacter}>B8</span>. This is the Initial
        Amount + Initial Amount x Interest
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} value={"Year 3"} editable={false} />
          <Cell cellName={"E4"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
          <Cell cellName={"D5"} value={"$25,000"} editable={false} />
          <Cell cellName={"E5"} value={"$30,000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A7"} value={"Initial amount"} editable={false} />
          <Cell cellName={"B7"} value={"Year 1"} editable={false} />
          <Cell cellName={"C7"} value={"Year 2"} editable={false} />
          <Cell cellName={"D7"} value={"Year 3"} editable={false} />
          <Cell cellName={"E7"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A8"} value={"$10,000"} editable={false} />
          <Cell cellName={"B8"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "15000",
    range: "B8",
  },
  {
    title: "Growth on Growth on Growth",
    text: (
      <span style={{ fontSize: 16 }}>
        You might notice the first growth period is the same as previously (Look
        at B5 & B8). This is about to change. Make the amount grow on Year 2,
        but add the interest to YEAR 1 instead of the Initial Amount on{" "}
        <span style={styles.specialCharacter}>C8</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} value={"Year 3"} editable={false} />
          <Cell cellName={"E4"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
          <Cell cellName={"D5"} value={"$25,000"} editable={false} />
          <Cell cellName={"E5"} value={"$30,000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A7"} value={"Initial amount"} editable={false} />
          <Cell cellName={"B7"} value={"Year 1"} editable={false} />
          <Cell cellName={"C7"} value={"Year 2"} editable={false} />
          <Cell cellName={"D7"} value={"Year 3"} editable={false} />
          <Cell cellName={"E7"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A8"} value={"$10,000"} editable={false} />
          <Cell cellName={"B8"} value={"$15,000"} editable={false} />
          <Cell cellName={"C8"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "22500",
    range: "C8",
  },
  {
    title: "Growth on Growth on Growth on Growth",
    text: (
      <span style={{ fontSize: 16 }}>
        Now it gets interesting. Instead of having $20,000, you now have $22,500
        . This is the magic of compound interest. Calculate the next year.
        Calculate interest on YEAR 3 based on your YEAR 2 ($22,500) amount on
        cell <span style={styles.specialCharacter}>D8</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} value={"Year 3"} editable={false} />
          <Cell cellName={"E4"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
          <Cell cellName={"D5"} value={"$25,000"} editable={false} />
          <Cell cellName={"E5"} value={"$30,000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A7"} value={"Initial amount"} editable={false} />
          <Cell cellName={"B7"} value={"Year 1"} editable={false} />
          <Cell cellName={"C7"} value={"Year 2"} editable={false} />
          <Cell cellName={"D7"} value={"Year 3"} editable={false} />
          <Cell cellName={"E7"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A8"} value={"$10,000"} editable={false} />
          <Cell cellName={"B8"} value={"$15,000"} editable={false} />
          <Cell cellName={"C8"} value={"$22,500"} editable={false} />
          <Cell cellName={"D8"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "33750",
    range: "D8",
  },
  {
    title: "Growth on Growth on Growth on Growth on Growth",
    text: (
      <span style={{ fontSize: 16 }}>
        Look how much more we have than with simple interest on Year 3!
        Calculate Year 4 now. Calculate compound interest on YEAR 4 based on
        your YEAR 3 ($33,750) amount. Figure it out yourself if you can!
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} value={"Year 3"} editable={false} />
          <Cell cellName={"E4"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
          <Cell cellName={"D5"} value={"$25,000"} editable={false} />
          <Cell cellName={"E5"} value={"$30,000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A7"} value={"Initial amount"} editable={false} />
          <Cell cellName={"B7"} value={"Year 1"} editable={false} />
          <Cell cellName={"C7"} value={"Year 2"} editable={false} />
          <Cell cellName={"D7"} value={"Year 3"} editable={false} />
          <Cell cellName={"E7"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A8"} value={"$10,000"} editable={false} />
          <Cell cellName={"B8"} value={"$15,000"} editable={false} />
          <Cell cellName={"C8"} value={"$22,500"} editable={false} />
          <Cell cellName={"D8"} value={"$33,750"} editable={false} />
          <Cell cellName={"E8"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "50625",
    range: "E8",
  },
  {
    title: "Gains Comparison",
    text: (
      <span style={{ fontSize: 16 }}>
        When money grows the same each year, it's called simple growth because
        the amount by which it grows is constant. When money grows faster every
        year and by bigger amounts, it's called exponential growth. Let's find
        out how much you made. On Cell{" "}
        <span style={styles.specialCharacter}>F4</span>, type{" "}
        <span style={styles.specialCharacter}>Total Gains</span>.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} value={"Year 3"} editable={false} />
          <Cell cellName={"E4"} value={"Year 4"} editable={false} />
          <Cell cellName={"F4"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
          <Cell cellName={"D5"} value={"$25,000"} editable={false} />
          <Cell cellName={"E5"} value={"$30,000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A7"} value={"Initial amount"} editable={false} />
          <Cell cellName={"B7"} value={"Year 1"} editable={false} />
          <Cell cellName={"C7"} value={"Year 2"} editable={false} />
          <Cell cellName={"D7"} value={"Year 3"} editable={false} />
          <Cell cellName={"E7"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A8"} value={"$10,000"} editable={false} />
          <Cell cellName={"B8"} value={"$15,000"} editable={false} />
          <Cell cellName={"C8"} value={"$22,500"} editable={false} />
          <Cell cellName={"D8"} value={"$33,750"} editable={false} />
          <Cell cellName={"E8"} value={"$50,625"} editable={false} />
        </div>
      </div>
    ),
    answer: "total gains",
    range: "F4",
  },
  {
    title: "Simple Growth Gains",
    text: (
      <span style={{ fontSize: 16 }}>
        Subtract YEAR 4 amount on simple interest with the Initial Amount. On
        cell <span style={styles.specialCharacter}>F5</span> Subtract YEAR 4 -
        Initial Amount to calculate returns on Simple Interest.
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} value={"Year 3"} editable={false} />
          <Cell cellName={"E4"} value={"Year 4"} editable={false} />
          <Cell cellName={"F4"} value={"Total gains"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
          <Cell cellName={"D5"} value={"$25,000"} editable={false} />
          <Cell cellName={"E5"} value={"$30,000"} editable={false} />
          <Cell cellName={"F5"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A7"} value={"Initial amount"} editable={false} />
          <Cell cellName={"B7"} value={"Year 1"} editable={false} />
          <Cell cellName={"C7"} value={"Year 2"} editable={false} />
          <Cell cellName={"D7"} value={"Year 3"} editable={false} />
          <Cell cellName={"E7"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A8"} value={"$10,000"} editable={false} />
          <Cell cellName={"B8"} value={"$15,000"} editable={false} />
          <Cell cellName={"C8"} value={"$22,500"} editable={false} />
          <Cell cellName={"D8"} value={"$33,750"} editable={false} />
          <Cell cellName={"E8"} value={"$50,625"} editable={false} />
        </div>
      </div>
    ),
    answer: "20000",
    range: "F5",
  },
  {
    title: "Compound Interest Growth Gains",
    text: (
      <span style={{ fontSize: 16 }}>
        Subtract YEAR 4 amount on Compound interest with the Initial Amount. On
        cell <span style={styles.specialCharacter}>F8</span> Subtract YEAR 4 -
        Initial Amount to calculate returns on Simple Interest
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A1"} value={"Interest"} editable={false} />
          <Cell cellName={"B1"} value={"0.5"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Initial Amount"} editable={false} />
          <Cell cellName={"B4"} value={"Year 1"} editable={false} />
          <Cell cellName={"C4"} value={"Year 2"} editable={false} />
          <Cell cellName={"D4"} value={"Year 3"} editable={false} />
          <Cell cellName={"E4"} value={"Year 4"} editable={false} />
          <Cell cellName={"F4"} value={"Total gains"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"$10,000"} editable={false} />
          <Cell cellName={"B5"} value={"$15,000"} editable={false} />
          <Cell cellName={"C5"} value={"$20,000"} editable={false} />
          <Cell cellName={"D5"} value={"$25,000"} editable={false} />
          <Cell cellName={"E5"} value={"$30,000"} editable={false} />
          <Cell cellName={"F5"} value={"$20,000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A7"} value={"Initial amount"} editable={false} />
          <Cell cellName={"B7"} value={"Year 1"} editable={false} />
          <Cell cellName={"C7"} value={"Year 2"} editable={false} />
          <Cell cellName={"D7"} value={"Year 3"} editable={false} />
          <Cell cellName={"E7"} value={"Year 4"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A8"} value={"$10,000"} editable={false} />
          <Cell cellName={"B8"} value={"$15,000"} editable={false} />
          <Cell cellName={"C8"} value={"$22,500"} editable={false} />
          <Cell cellName={"D8"} value={"$33,750"} editable={false} />
          <Cell cellName={"E8"} value={"$50,625"} editable={false} />
          <Cell cellName={"F8"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "40625",
    range: "F5",
  },
  {
    title: "Congratulations!",
    finished: true,
    text: (
      <span style={{ fontSize: 16 }}>
        You finished the interest interactive lesson!{" "}
      </span>
    ),
  },
];
