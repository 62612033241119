import React, { Component } from "react";

import { DropDown } from "../../../Dropdown";
import SwitchItemOrderButtons from "../shared/SwitchItemOrderButtons";

import "./index.css";

export class Likert extends Component {
  editLikert = (e) => {
    // console.log("Likert.js 6 | editing likert", e);
  };

  componentDidUpdate(prevProps, prevState) {
    // console.log("Likert.js 9 | Likert Parent Component Updated");
  }

  render() {
    const {
      index,
      removeContentAtIndex,
      item,
      editSection,
      _moveSectionContentDown,
      _moveSectionContentUp,
    } = this.props;
    return (
      <div className="likert__edit">
        <h3 className="editcontent__title">{index}</h3>

        <input
          name="likert"
          value={item.question}
          className="optioninput__text"
          onChange={(e) =>
            editSection(e, index, "likert", null, null, null, "question")
          }
          placeholder="1-5 likert input"
        />
        <div className="likertrangeinputs__container">
          {/* <div className="likertinput__container">
            1.
            <input
              className="likertrangeinput"
              placeholder="Strongly Disagree"
            />
          </div>
          <div className="likertinput__container">
            2.
            <input className="likertrangeinput" placeholder="Strongly Agree" />
          </div> */}
        </div>
        {/* <Rater editLikert={this.editLikert} /> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "50%",
          }}
        >
          <DropDown
            name={item.category}
            value={item.category}
            onClick={(value) => {
              editSection(
                value.value,
                index,
                "likert",
                null,
                null,
                null,
                "category"
              );
              console.log("Likert.js 33 CATEGORY WORKING | ", value);
            }}
          />
          <button
            className="btn btn-danger"
            onClick={(e) => removeContentAtIndex(e, index)}
          >
            Delete
          </button>
        </div>
        <SwitchItemOrderButtons
          index={index}
          _moveSectionContentDown={_moveSectionContentDown}
          _moveSectionContentUp={_moveSectionContentUp}
        />
      </div>
    );
  }
}

export default Likert;
