import React from "react";
import Cell from "../Cell.js";
import PulsatingButton from "../PulsatingButton.js";

const styles = {
  container: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  specialCharacter: {
    fontWeight: "bold",
    color: "orange",
  },
};

export const budgetingSteps = [
  {
    title: "Income",
    text: (
      <span style={{ fontSize: 16 }}>
        Type <span style={styles.specialCharacter}>Income</span> in cell{" "}
        <span style={styles.specialCharacter}></span>A4
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <Cell cellName={"A4"} onTextChange={setCellText} />
      </div>
    ),
    answer: "income",
    range: "A4",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "First Month",
    text: (
      <span style={{ fontSize: 16 }}>
        We'll create a January column to record your Income and Expenses. Type{" "}
        <span style={styles.specialCharacter}>January</span> in cell{" "}
        <span style={styles.specialCharacter}>B3</span>
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
        </div>
      </div>
    ),
    answer: "january",
    range: "B3",
    get hint() {
      return `Type ${this.answer} on ${this.range}, hit ENTER and CHECK again.`;
    },
  },
  {
    title: "Income",
    text: (
      <span style={{ fontSize: 16 }}>
        Good! Press the button to get paid. After you click, see how the app is
        updated
      </span>
    ),
    button: "Get paid!",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} highlighted onTextChange={setCellText} />
        </div>
        <PulsatingButton
          text={"Pay bills!"}
          color={"green"}
          onClick={getPaid}
        />
      </div>
    ),
    answer: "2000",
    range: "B4",
    get hint() {
      return `Click the Income button!`;
    },
  },
  {
    title: "Income",
    text: (
      <span style={{ fontSize: 16 }}>
        Good! Press the button to get paid. After you click, see how the app is
        updated
      </span>
    ),
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "2000",
    isDefault: true,
    range: "B4",
    get hint() {
      return `Click the Income button!`;
    },
  },
  {
    title: "Expenses",
    text: (
      <span style={{ fontSize: 16 }}>
        Type <span style={styles.specialCharacter}>Expenses</span> in cell{" "}
        <span style={styles.specialCharacter}>A5</span>
      </span>
    ),
    answer: "expenses",
    content: (setCellText) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    range: "A5",
    get hint() {
      return `Type ${this.answer} on ${this.range}!`;
    },
  },
  {
    title: "Expenses 2",
    text: (
      <span style={{ fontSize: 16 }}>
        Now lets pay those expenses! You'll see how paying these updates the
        values
      </span>
    ),
    button: "Pay Bills!",
    answer: 1500,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} onTextChange={setCellText} />
        </div>
        <PulsatingButton text={"Pay bills!"} onClick={getPaid} />
      </div>
    ),
    range: "B5",
    get hint() {
      return `Type ${this.answer} on ${this.range}!`;
    },
  },
  {
    title: "Expenses 2",
    text: (
      <span style={{ fontSize: 16 }}>
        Now lets pay those expenses! You'll see how paying these updates the
        values
      </span>
    ),
    answer: "1500",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    range: "B5",
    isDefault: true,
    get hint() {
      return `Type ${this.answer} on ${this.range}!`;
    },
  },
  {
    title: "Savings",
    text: (
      <span style={{ fontSize: 16 }}>
        Savings is what's left at the end of the month. Type{" "}
        <span style={styles.specialCharacter}>Savings</span> in cell{" "}
        <span style={styles.specialCharacter}>A6</span>.
      </span>
    ),
    answer: "savings",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    range: "A6",
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "Savings",
    text: (
      <span style={{ fontSize: 16 }}>
        Now, lets calculate Savings. In cell{" "}
        <span style={styles.specialCharacter}>
          B6 , subtract your expenses from your income and add the final value on A6
        </span>
      </span>
    ),
    answer: "500",
    range: "B6",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "February",
    text: (
      <span style={{ fontSize: 16 }}>
        Great job! You've finished January without a Budget. Now, you're about
        to learn how to Budget for Feburary. On cell{" "}
        <span style={styles.specialCharacter}>C3</span> type{" "}
        <span style={styles.specialCharacter}>February</span>.
      </span>
    ),
    answer: "february",
    range: "C3",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
        </div>
      </div>
    ),
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "February Budget",
    text: (
      <span style={{ fontSize: 16 }}>
        February has not started yet, but lets start making a Budget for it.
        Type <span style={styles.specialCharacter}>February Budget</span> in
        cell <span style={styles.specialCharacter}>D3</span>
      </span>
    ),
    answer: "february budget",
    range: "D3",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
        </div>
      </div>
    ),
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "Budgeting Income",
    text: (
      <span style={{ fontSize: 16 }}>
        A budget is an aproximation of income and expenses; a guess of how much
        you'll spend. Copy your income from January{" "}
        <span style={styles.specialCharacter}>(2000)</span> in cell{" "}
        <span style={styles.specialCharacter}>D4</span>. Income is usually the
        same as last month.
      </span>
    ),
    answer: "2000",
    range: "D4",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} value={"February budget"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
          <Cell cellName={"C4"} value={""} editable={false} />
          <Cell cellName={"D4"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
        </div>
      </div>
    ),
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "Budgeting Expenses",
    text: (
      <span style={{ fontSize: 16 }}>
        Just like Income, copy January's Expenses{" "}
        <span style={styles.specialCharacter}>(1500)</span> and put them in cell{" "}
        <span style={styles.specialCharacter}>D5</span>. Expenses are key to
        financial stability.
      </span>
    ),
    answer: "1500",
    range: "D5",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} value={"February budget"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
          <Cell cellName={"C4"} value={""} editable={false} />
          <Cell cellName={"D4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
          <Cell cellName={"C5"} value={""} editable={false} />
          <Cell cellName={"D5"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
        </div>
      </div>
    ),
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "Budgeting Savings",
    text: (
      <span style={{ fontSize: 16 }}>
        Finally, subtract{" "}
        <span style={styles.specialCharacter}>Income - Expenses </span>on cell{" "}
        <span style={styles.specialCharacter}>D6</span>. Be sure to press Check
      </span>
    ),
    answer: "500",
    range: "D6",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} value={"February budget"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
          <Cell cellName={"C4"} value={""} editable={false} />
          <Cell cellName={"D4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
          <Cell cellName={"C5"} value={""} editable={false} />
          <Cell cellName={"D5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
          <Cell cellName={"C6"} value={""} editable={false} />
          <Cell cellName={"D6"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "February Actual Income!",
    text: (
      <span style={{ fontSize: 16 }}>
        Now we'll see what actually happened in February. Receive your income!
      </span>
    ),
    button: "Get paid!",
    answer: "2000",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} value={"February budget"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
          <Cell cellName={"C4"} value={""} editable={false} />
          <Cell cellName={"D4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
          <Cell cellName={"C5"} value={""} editable={false} />
          <Cell cellName={"D5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
          <Cell cellName={"C6"} value={""} editable={false} />
          <Cell cellName={"D6"} value={"500"} editable={false} />
        </div>
        <PulsatingButton text={"Get paid!"} color={"green"} onClick={getPaid} />
      </div>
    ),
    range: "C4",
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "February Actual Income!",
    text: (
      <span style={{ fontSize: 16 }}>
        Now we'll see what actually happened in February. Receive your income!
      </span>
    ),
    answer: "2000",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} value={"February budget"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
          <Cell cellName={"C4"} value={"2000"} editable={false} />
          <Cell cellName={"D4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
          <Cell cellName={"C5"} value={""} editable={false} />
          <Cell cellName={"D5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
          <Cell cellName={"C6"} value={""} editable={false} />
          <Cell cellName={"D6"} value={"500"} editable={false} />
        </div>
      </div>
    ),
    isDefault: true,
    range: "C4",
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "February Actual Expenses!",
    text: (
      <span style={{ fontSize: 16 }}>Now pay the bills you had to pay!</span>
    ),
    button: "Pay bills!",
    answer: "1200",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} value={"February budget"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
          <Cell cellName={"C4"} value={"2000"} editable={false} />
          <Cell cellName={"D4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
          <Cell cellName={"C5"} value={""} editable={false} />
          <Cell cellName={"D5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
          <Cell cellName={"C6"} value={""} editable={false} />
          <Cell cellName={"D6"} value={"500"} editable={false} />
        </div>

        <PulsatingButton text={"Pay bills!"} onClick={getPaid} />
      </div>
    ),
    range: "C5",
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "February Actual Expenses!",
    text: (
      <span style={{ fontSize: 16 }}>Now pay the bills you had to pay!</span>
    ),
    answer: "1200",
    isDefault: true,
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} value={"February budget"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
          <Cell cellName={"C4"} value={"2000"} editable={false} />
          <Cell cellName={"D4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
          <Cell cellName={"C5"} value={"1200"} editable={false} />
          <Cell cellName={"D5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
          <Cell cellName={"C6"} value={""} editable={false} />
          <Cell cellName={"D6"} value={"500"} editable={false} />
        </div>
      </div>
    ),
    range: "C5",
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "See savings!",
    text: (
      <span style={{ fontSize: 16 }}>
        Now subtract{" "}
        <span style={styles.specialCharacter}>Income - Expenses</span> in
        February on cell <span style={styles.specialCharacter}>C6</span>.
      </span>
    ),
    answer: "800",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} value={"February budget"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
          <Cell cellName={"C4"} value={"2000"} editable={false} />
          <Cell cellName={"D4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
          <Cell cellName={"C5"} value={"1200"} editable={false} />
          <Cell cellName={"D5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
          <Cell cellName={"C6"} onTextChange={setCellText} />
          <Cell cellName={"D6"} value={"500"} editable={false} />
        </div>
      </div>
    ),
    range: "C6",
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "Compare",
    text: (
      <span style={{ fontSize: 16 }}>
        Look at your February income and expenses. Now compare them to your
        February Budget. Type{" "}
        <span style={styles.specialCharacter}>Compare</span> in cell{" "}
        <span style={styles.specialCharacter}>E3</span>
      </span>
    ),
    answer: "compare",
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} value={"February budget"} editable={false} />
          <Cell cellName={"E3"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
          <Cell cellName={"C4"} value={"2000"} editable={false} />
          <Cell cellName={"D4"} value={"2000"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
          <Cell cellName={"C5"} value={"1200"} editable={false} />
          <Cell cellName={"D5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
          <Cell cellName={"C6"} value={"800"} editable={false} />
          <Cell cellName={"D6"} value={"500"} editable={false} />
        </div>
      </div>
    ),
    range: "E3",
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "Compare Income",
    text: (
      <span style={{ fontSize: 16 }}>
        See how much more/less you earned! On the compare column, subtract your
        February actual Income from your February Budgeted Income to see the
        difference: February Budget Income - February Income
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} value={"February budget"} editable={false} />
          <Cell cellName={"E3"} value={"Compare"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
          <Cell cellName={"C4"} value={"2000"} editable={false} />
          <Cell cellName={"D4"} value={"2000"} editable={false} />
          <Cell cellName={"E4"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
          <Cell cellName={"C5"} value={"1200"} editable={false} />
          <Cell cellName={"D5"} value={"1500"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
          <Cell cellName={"C6"} value={"800"} editable={false} />
          <Cell cellName={"D6"} value={"500"} editable={false} />
        </div>
      </div>
    ),
    answer: "0",
    range: "E4",
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "Compare Expenses",
    text: (
      <span style={{ fontSize: 16 }}>
        Now lets compare your budgeted expenses with the actual amounts you
        spent! On the compare column,<span style={styles.specialCharacter}>
          subtract your February actual Expenses
          from your February Budgeted Expenses to see the difference: February Budget
          Expenses - February Expenses</span>. Note: If the result is negative,
        add the "-".{" "}
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} value={"February budget"} editable={false} />
          <Cell cellName={"E3"} value={"Compare"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
          <Cell cellName={"C4"} value={"2000"} editable={false} />
          <Cell cellName={"D4"} value={"2000"} editable={false} />
          <Cell cellName={"E4"} value={"0"} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
          <Cell cellName={"C5"} value={"1200"} editable={false} />
          <Cell cellName={"D5"} value={"1500"} editable={false} />
          <Cell cellName={"E5"} onTextChange={setCellText} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
          <Cell cellName={"C6"} value={"800"} editable={false} />
          <Cell cellName={"D6"} value={"500"} editable={false} />
        </div>
      </div>
    ),
    // image: "/assets/images/tutorial-1.png",
    answer: "-300",
    range: "E5",
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "Compare Savings",
    text: (
      <span style={{ fontSize: 16 }}>
        Savings is the most important part of a Budget. It's not about what you
        earn, but about what you keep. Compare February Budget Savings with
        February Actual Savings like you did in the past 2 cells.
      </span>
    ),
    content: (setCellText, getPaid) => (
      <div style={styles.container}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A3"} value={""} editable={false} />
          <Cell cellName={"B3"} value={"January"} editable={false} />
          <Cell cellName={"C3"} value={"February"} editable={false} />
          <Cell cellName={"D3"} value={"February budget"} editable={false} />
          <Cell cellName={"E3"} value={"Compare"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A4"} value={"Income"} editable={false} />
          <Cell cellName={"B4"} value={"2000"} editable={false} />
          <Cell cellName={"C4"} value={"2000"} editable={false} />
          <Cell cellName={"D4"} value={"2000"} editable={false} />
          <Cell cellName={"E4"} value={"0"} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A5"} value={"Expenses"} editable={false} />
          <Cell cellName={"B5"} value={"1500"} editable={false} />
          <Cell cellName={"C5"} value={"1200"} editable={false} />
          <Cell cellName={"D5"} value={"1500"} editable={false} />
          <Cell cellName={"E5"} value={"-300"} editable={false} />
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Cell cellName={"A6"} value={"Savings"} editable={false} />
          <Cell cellName={"B6"} value={"500"} editable={false} />
          <Cell cellName={"C6"} value={"800"} editable={false} />
          <Cell cellName={"D6"} value={"500"} editable={false} />
          <Cell cellName={"E6"} onTextChange={setCellText} />
        </div>
      </div>
    ),
    answer: "300",
    range: "E6",
    get hint() {
      return `Type ${this.answer} on ${this.range}.`;
    },
  },
  {
    title: "Congratulations!",
    finished: true,
    text: (
      <span style={{ fontSize: 16 }}>
        You finished the budgeting interactive lesson!
      </span>
    ),
  },
];
