import React from "react";
import simulation from "../Data/simulation-months";
import allEvents from "../Data/events";
import setupOptions from "../Data/setupOptions";
import Choices from "../sheetsComponents/Choices/Choices";
import EmergencyFund from "../sheetsComponents/EmergencyFund/EmergencyFund";
import GoogleSheet from "../sheetsComponents/GoogleSheet/GoogleSheet";
import Chart from "../sheetsComponents/Chart/Chart";
import LoadingModal from "../sheetsComponents/modals/LoadingModal";
import SelectFixedExpenses from "../sheetsComponents/modals/Modal/SelectFixedExpenses";
import AlertModal from "../sheetsComponents/modals/AlertModal/AlertModal";
import MonthModal from "../sheetsComponents/modals/MonthModal/MonthModal";
import StartButton from "../sheetsComponents/buttons/StartButton";
import { _findIdealMonth } from "../Data/storyGenerator";
import {
  _lookForSheet,
  _updateInitialValues,
  _updateInitialFormat,
  _updateMonthFormat,
  _updateCellValues,
  _updateCellFormat,
  _readColumns,
} from "../sheetsFunctions/sheetsFunctions";
import "./SheetsContainer.css";

import { WidthWarning } from "../../../../../WidthWarning";

import { compose } from "recompose";

import { Helmet } from "react-helmet";
import { withFirebase } from "../../../../../Firebase";

// READ FIRST:
// This is a Budgeting Simulator with Google Spreadsheets

class Sheets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Hiding and Showing Components
      startButtonVisible: false,
      loadingModalVisible: false,
      simulationVisible: false,
      setupModalVisible: false,
      choiceButtonsVisible: false,
      continueButtonVisible: false,
      monthModalVisible: false,
      monthBreakdownVisible: false,
      resultsButtonVisible: false,
      resultsModalVisible: false,
      // alertModalVisible: true,
      gotData: false,

      // Initial Data
      levelNum: 0,
      userName: null,
      months: simulation,
      events: allEvents,
      categories: {
        Housing: {
          spentAmount: 0,
          row: 4,
        },
        Food: {
          spentAmount: 0,
          row: 5,
        },
        Transportation: {
          spentAmount: 0,
          row: 6,
        },
        Fun: {
          spentAmount: 0,
          row: 7,
        },
        Insurance: {
          spentAmount: 0,
          row: 8,
        },
      },

      setupOptions: setupOptions,
      // Default User Selections

      selectedOptions: {
        jobs: 0,
        housing: 0,
        insurance: 0,
        food: 0,
        transport: 0,
        // jobs: setupOptions.jobs.options[0],
        // housing: setupOptions.housing.options[0],
        // insurance: setupOptions.insurance.options[0],
        // food: setupOptions.food.options[0],
        // transport: setupOptions.transport.options[0],
      },

      savingsChoice: "emergency-fund",
      averageSavings: 0,

      // Time Trackers
      // levelNum: 1,
      playNum: 0,
      eventNum: 0,
      monthNum: 0,
      insuranceSurpriseDate: {
        month: 0,
        event: 0,
      },
      insuranceSurpriseVisible: false,
      chartMonths: [],

      // Money Stats
      emergencyFundSavings: 0,
      monthSavings: 0,
      moneyToBudget: 0,
      chartData: [],
      monthDifference: 0,

      // Sheet Data
      userFileId: "",
      spreadsheetTitle: "Snowball Budgeting Intro 2.0",
      previousBudgetRows: [],
      budgetRows: [], // user-entered amounts fetched from Google sheet
      expenseColumn: "A",
      budgetColumn: "B",
      differenceColumn: "C",

      // Other In-Game Data
      choiceBudgetAlert: "",
      happyLevel: 0,
      defaultHappy: 0,
    };
  }

  componentDidMount() {
    this.getLessonNumberFromDatabase();

    console.log("SheetsContainer.js 145 | this.props", this.props);
  }

  // Start of simulation
  _startGame = async (progress) => {
    // const userName = this.props.user.name
    //   ? this.props.user.name.split(" ")[0]
    //   : null;
    const userName = "User";

    // Reset budgetRows to '$0'. Gets array length from categories.
    const categories = Object.keys(this.state.categories);
    let budgetRows = [];
    let previousBudgetRows = [];
    categories.forEach(() => {
      budgetRows.push("$0");
      previousBudgetRows.push(0);
    });

    const levelUporSame =
      this.state.monthSavings >= 0 && this.state.happyLevel >= 15
        ? this.state.levelNum + 1
        : this.state.levelNum;
    console.log("SheetsContainer.js 177 | level up or same", levelUporSame);
    const playNum = this.state.playNum + 1;

    this.setState(
      {
        // Changes component visibilty
        startButtonVisible: false,
        loadingModalVisible: true,
        alertModalVisible: false,

        // Resets all changes from last game
        levelNum: levelUporSame,
        userName: userName,
        budgetRows: budgetRows,
        previousBudgetRows: previousBudgetRows,
        resultsButtonVisible: false,
        resultsModalVisible: false,
        choiceButtonsVisible: false,
        setupModalVisible: false,
        monthModalVisible: false,
        monthBreakdownVisible: false,
        monthSavings: 0,
        playNum: playNum,
        monthNum: 0,
        eventNum: 0,
        emergencyFundSavings: 0,
        chartMonths: [],
        chartData: [],
        happyLevel: 0,
        defaultHappy: 0,
        monthDifference: 0,
      },
      () => {
        // These two functions operate concurrently
        this._getSheet();
        this._updateLevelNum();

        setTimeout(this._hideLoadingModal, 3000);
      }
    );
  };

  _getAverageSavings = (arr) => {
    let totalSavings = 0;
    arr.forEach((value) => {
      totalSavings += value;
    });
    this.setState(
      {
        averageSavings: totalSavings / arr.length,
      },
      () => {
        console.log(
          "returning average savings | SheetsContainer.js",
          this.state.averageSavings
        );
      }
    );
  };

  _updateLevelNum = () => {
    this._updateSheetsLevel(this.state.levelNum);
    // this.props._updateLevel(this.state.levelNum, this.state.averageSavings);
  };

  getLessonNumberFromDatabase = async () => {
    const { firebase, userId } = this.props;
    const data = await firebase.user(userId).once("value");
    if (!this.state.gotData) {
      this.setState({ gotData: true });
      if (data.val()) {
        console.log("SheetsContainer.js 101 | data", data.val());
        if (data.val().snowball) {
          console.log(
            "SheetsContainer.js 103 | snowball data",
            data.val().snowball,
            userId
          );
          if (data.val().snowball.budgetingLevel) {
            this.setState(
              {
                levelNum: data.val().snowball.budgetingLevel,
              },
              () => {
                console.log("SheetsContainer.js 264 | set level num");
                this._startGame();
              }
            );
          } else {
            this._startGame();
          }
        } else {
          console.log("IntroContainer.js 108 | no snowball data");
          this._startGame();
        }
      } else {
        console.log("IntroContainer.js 111 | no snowball registered data");
        this._startGame();
      }
    } else {
      this._startGame();
    }
  };

  _updateSheetsLevel = async (level) => {
    const { firebase, userId } = this.props;
    this.setState(
      {
        levelNum: level,
      },
      () => {
        firebase
          .user(`${userId}/snowball`)
          .update({
            budgetingLevel: level,
          })
          .then(() => {
            console.log("IntroContainer.js 154 | updated user successfully");
          })
          .catch((error) => {
            console.log("IntroContainer.js 159 | ", "ERROR", error);
          });
      }
    );
  };

  // Chooses a random event in a random month.
  // When the gameplay reaches that event it will run the surprise before the event.
  _generateSurpriseTime = () => {
    const surpriseMonth = Math.floor(
      Math.random() * (this.state.months.length - 1)
    );
    const surpriseEvent = Math.floor(
      Math.random() * (this.state.months[surpriseMonth].events.length - 1)
    );
    const surpriseDate = {
      month: surpriseMonth,
      event: surpriseEvent,
    };
    // console.log("Date", surpriseDate);
    return surpriseDate;
  };

  _generateFixedExpenses = () => {
    const housing = {
      situation: "Pay your rent!",
      type: "Expense",
      category: {
        name: "Housing",
      },
      choices: [
        {
          action: "Pay now",
          happiness: this.state.selectedOptions.housing.happy,
          amount: this.state.selectedOptions.housing.amount,
        },
      ],
    };
    const food = {
      situation: "Pay your grocery bill!",
      type: "Expense",
      category: {
        name: "Food",
      },
      choices: [
        {
          action: "Pay now",
          happiness: this.state.selectedOptions.food.happy,
          amount: this.state.selectedOptions.food.amount,
        },
      ],
    };
    const insurance = {
      situation: "Pay your health insurance!",
      type: "Expense",
      category: {
        name: "Insurance",
      },
      choices: [
        {
          action: "Pay now",
          happiness: this.state.selectedOptions.insurance.happy,
          amount: this.state.selectedOptions.insurance.amount,
        },
      ],
    };
    const transport = {
      situation: "Pay your car payment!",
      type: "Expense",
      category: {
        name: "Transportation",
      },
      choices: [
        {
          action: "Pay now",
          happiness: this.state.selectedOptions.transport.happy,
          amount: this.state.selectedOptions.transport.amount,
        },
      ],
    };
    // change the months object to push fixed expenses
    const monthsWithFixedExpenses = simulation.map((month) => {
      // Push each rentExpense to the beginning of each events array inside simulation
      const newEvents = [housing, food, insurance, transport, ...month.events];
      const newMonth = { ...month, events: newEvents };
      return newMonth;
    });

    return monthsWithFixedExpenses;
  };

  // Uses current month index (monthNum) to generate the correct column letter.
  // e.g. If monthNum is 0 and num is 0, it returns 'A'. If monthNum is 2 and num is 3, it returns 'F'.
  _generateLetterFromNumber = (num) => {
    return String.fromCharCode(65 + this.state.monthNum + num);
  };

  // Finds or Creates a Google Spreadsheet.
  _getSheet = async () => {
    // If the sheet is in session storage, we save it to state.
    // Otherwise we use the Google token to look for it the user's Drive.
    // If we find it there we save that id to state.
    // If not we create a new sheet with the passed in title and save that sheet's id to state.
    const sessionUserFileId = JSON.parse(localStorage.getItem("userFileId"));

    if (sessionUserFileId) {
      this._startSetup(sessionUserFileId);
    } else {
      try {
        return _lookForSheet(this.state.spreadsheetTitle, this._startSetup)
          .then(() => {
            console.log("SheetsContainer.js 354 | got sheet");
          })
          .catch((error) => {
            console.log(
              "SheetsContainer.js 355 | error finding sheet",
              "ERROR",
              error
            );
          });
      } catch (error) {
        console.log("SheetsContainer.js 425 | issue loading sheet");
      }
    }
  };

  // Starts timer. When time is up it hides the loading modal.
  _hideLoadingModal = () => {
    setTimeout(() => {
      this.setState({
        // Changes component visibilty.
        // Reveals setup modal and simulation components behind it.
        loadingModalVisible: false,
      });
    }, 100);
  };

  // _startSetup is the only place where userFileId is added to state.
  // It allows the four main simulation components and setup modal to be rendered while hidden by loading modal.
  // It is called when a sheet ID is found in session storage, in Google drive, or when a new sheet is created.
  _startSetup = (userFileId) => {
    this.setState(
      {
        userFileId: userFileId,
        simulationVisible: true,
        setupModalVisible: true,
      },
      () => {
        this._setSheetGame();
      }
    );
  };

  // Sends first POST to Google sheet with the tempate cell values and formatting.
  _setSheetGame = async () => {
    const categories = Object.keys(this.state.categories);
    const sortedCategories = categories.sort((a, b) => a.row - b.row);

    const initialData = [
      ["INCOME", "", "", "", "", "", "", "", "", "", "", "", ""],
      ["Job", "", "", "", "", "", "", "", "", "", "", "", ""],
      ["EXPENSES", "", "", "", "", "", "", "", "", "", "", "", ""],
    ];

    sortedCategories.forEach((category) => {
      initialData.push([
        `'  ${category}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);
    });

    initialData.push([
      "SAVINGS",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ]);

    try {
      return _updateInitialFormat(this.state.userFileId)
        .then(() => {
          return _updateInitialValues("A1", initialData, this.state.userFileId);
        })
        .catch((error) => {
          console.log(
            "SheetsContainer.js 452 | error updating initial format",
            "ERROR",
            error
          );
        });
    } catch (error) {
      console.log("SheetsContainer.js 462 | error");
    }
  };

  // Now we switch back to the main Game path. Last place we left it was showing the setup modal.
  // The next action happens when the user selects a job option.

  _updateOption = (optionCategory, selectedOption) => {
    const selected = this.state.setupOptions[optionCategory].options.find(
      (option) => {
        return option.title === selectedOption.title;
      }
    );
    this.setState({
      selectedOptions: {
        ...this.state.selectedOptions,
        [optionCategory]: selected,
      },
    });
  };

  _hideAlertModal = () => {
    this.setState({
      alertModalVisible: false,
    });
  };
  // MonthChoice submit calls this function:
  _startMonth = (formSubmitEvent) => {
    console.log("SheetsContainer.js 454 | start month button");
    if (formSubmitEvent) {
      formSubmitEvent.preventDefault();
    }

    // Generate random events for the next four months!!!
    if (this.state.monthNum === 0) {
      // Runs generateFixedExpenses and returns an array of months with selected fixed expenses
      const monthsWithFixedExpenses = this._generateFixedExpenses();

      const income = this.state.selectedOptions.jobs.amount;

      let availableEvents = {
        housing: {
          percentage: 25,
          events: this.state.events.housingEvents,
        },
        food: {
          percentage: 12,
          events: this.state.events.foodEvents,
        },
        transport: {
          percentage: 10,
          events: this.state.events.transportationEvents,
        },
        fun: {
          percentage: 10,
          events: this.state.events.funEvents,
        },
        insurance: {
          percentage: 15,
          events: this.state.events.insuranceEvents,
        },
      };
      // let fixedExpenses = 0;
      const catKeys = Object.keys(this.state.selectedOptions);
      catKeys.forEach((key) => {
        if (key !== "jobs") {
          const category = this.state.selectedOptions[key];
          const amount = category.amount;
          const fixedExpensePercent = Math.floor((amount * 100) / income);
          const variableExpensePercent =
            availableEvents[key].percentage - fixedExpensePercent;
          availableEvents[key].percentage =
            variableExpensePercent > 0 ? variableExpensePercent : 0;
          // fixedExpenses += amount;
        }
      });
      // const leftToSpend = this.state.selectedOptions.jobs.amount - fixedExpenses;

      const newGame = monthsWithFixedExpenses.map((month) => {
        const results = _findIdealMonth(income, availableEvents, month.month);
        // console.log('results ', results);
        availableEvents = { ...results.remainingEvents };
        const newMonth = {
          ...month,
          events: [...month.events, ...results.shuffledEvents],
        };
        return newMonth;
      });

      // Generates when to run the surprises

      this.setState(
        {
          months: newGame,
          // Sets time trackers for surprises
        },
        () => {
          const carSurpriseDate = this._generateSurpriseTime();
          const insuranceSurpriseDate = this._generateSurpriseTime();
          this.setState({
            insuranceSurpriseDate: insuranceSurpriseDate,
            carSurpriseDate: carSurpriseDate,
          });
        }
      );

      // console.log('new game ', newGame);
    }

    const moneyLeftOver = this.state.monthSavings;
    const spendingAmount =
      this.state.savingsChoice === "next-month"
        ? moneyLeftOver
        : this.state.monthDifference;
    const moneyToBudget =
      this.state.selectedOptions.jobs.amount + spendingAmount;
    const emergencyAmount =
      this.state.savingsChoice === "next-month"
        ? this.state.emergencyFundSavings
        : this.state.emergencyFundSavings + moneyLeftOver;

    let newChartMonths = [
      ...this.state.chartMonths,
      this.state.months[this.state.monthNum].month,
    ];
    let newChartData = [...this.state.chartData, moneyToBudget];

    const expenseColumn = this._generateLetterFromNumber(1);
    const budgetColumn = this._generateLetterFromNumber(2);
    const differenceColumn = this._generateLetterFromNumber(3);

    let happyLevel = emergencyAmount > 0 ? 1 : 0;

    this.setState(
      {
        emergencyFundSavings: emergencyAmount,
        chartMonths: newChartMonths,
        chartData: newChartData,
        monthModalVisible: false,
        setupModalVisible: false,
        moneyToBudget: moneyToBudget,
        expenseColumn: expenseColumn,
        budgetColumn: budgetColumn,
        differenceColumn: differenceColumn,
        choiceBudgetAlert: null,
        happyLevel: happyLevel,
        monthDifference: 0,
      },
      () => this._setSheetMonth()
    );
  };

  // If no components set to visible BudgetTips is rendered by default.
  // Called by GO button in BudgetTips in Choices
  _getSheetBudget = () => {
    const columnStart = this.state.monthNum + 2;
    const columnEnd = this.state.monthNum + 3;
    _readColumns(
      this.state.userFileId,
      this.props.token,
      columnStart,
      columnEnd,
      this._startSpending // Callback
    );
  };

  // Takes action from MonthChoice about where to put savings
  // changeEvent.target.value = 'emergency' or 'next-month'
  _selectSavingsChoice = (changeEvent) => {
    this.setState({
      savingsChoice: changeEvent.target.value,
    });
  };

  // Does what _setSheetGame does, except per-month rather than per-game
  _setSheetMonth = () => {
    const expenseFormula = this._generateSheetFormula(this.state.expenseColumn);
    const savingsFormula = this._generateSheetFormula(this.state.budgetColumn);
    const month = this.state.months[this.state.monthNum].month;

    const expenses = [
      month,
      this.state.moneyToBudget,
      "",
      "",
      "",
      "",
      "",
      "",
      expenseFormula,
    ];
    const budgets = [
      "",
      "Budget",
      "",
      ...this.state.budgetRows,
      savingsFormula,
    ];
    const differences = ["", "'+/-", ""];

    for (let i = 4; i < 9; i++) {
      const differenceFormula = `=${this.state.budgetColumn}${i}-${this.state.expenseColumn}${i}`;
      differences.push(differenceFormula);
    }

    const range = `${this.state.expenseColumn}1:${this.state.differenceColumn}10`;
    const content = [expenses, budgets, differences];

    // TO DO: call this _updateSheetValues
    _updateCellValues(
      this.state.userFileId,
      range, // 'A6:C6'
      content, // ["Food", "$30"]
      "Columns"
    );
    // TO DO: call this _updateSheetMonthFormat etc.
    _updateMonthFormat(this.state.userFileId, this.state.monthNum);
  };

  _generateSheetFormula = (column) => {
    return `=${this.state.expenseColumn}2-SUM(${column}4:${column}8)`;
  };

  // Checkts to see if Budget is ready for month.
  _startSpending = (budgetCells) => {
    console.log("SheetsContainer.js 660 | budgetCells from sheet", budgetCells);
    const categoryNames = Object.keys(this.state.categories);
    let budgetedCategories = {};
    let newCategories = {};
    categoryNames.forEach((category) => {
      newCategories[category] = {
        ...this.state.categories[category],
        spentAmount: 0,
      };
    });

    if (
      budgetCells.rowData &&
      budgetCells.rowData.length === categoryNames.length
    ) {
      let moneyToBudget = this.state.moneyToBudget;
      let budgetRows = [];
      let emptyCell = false;
      let cellsToHighlight = {};

      budgetCells.rowData.forEach((cell, index) => {
        if (
          cell.values[0].effectiveValue &&
          cell.values[0].effectiveValue.numberValue
        ) {
          const amount = cell.values[0].effectiveValue.numberValue;
          moneyToBudget -= amount;
          budgetRows.push(amount);
          cellsToHighlight[index.toString()] = { reset: true, row: index };
        } else {
          emptyCell = true;
          cellsToHighlight[index.toString()] = { reset: false, row: index };
        }
      });

      if (emptyCell) {
        this.setState({
          choiceBudgetAlert: `Budget amounts must be greater than zero. Make sure you press ENTER after editing a CELL`,
          alertModalVisible: true,
        });
      } else if (moneyToBudget < 0) {
        this.setState({
          choiceBudgetAlert: `You are $${-1 * moneyToBudget} over budget!`,
          alertModalVisible: true,
        });
        // If every cell has numbers than check to see if all above last months expenses
      } else {
        let allAboveBudget = true;
        if (this.state.monthNum !== 0) {
          categoryNames.forEach((category, index) => {
            const spentAmount = this.state.categories[category].spentAmount;
            console.log("SheetsContainer.js 711 | category", category);
            if (category === "Insurance") return;
            if (spentAmount <= budgetRows[index]) {
              cellsToHighlight[index.toString()] = { reset: true, row: index };
            } else {
              cellsToHighlight[index.toString()] = { reset: false, row: index };
              allAboveBudget = false;
            }
          });
        }
        if (!allAboveBudget) {
          this.setState({
            choiceBudgetAlert: `Adjust your BUDGET to be the same or more as last month expenses. Be sure to press ENTER after editing the cell.`,
            alertModalVisible: true,
          });
        } else {
          this.setState(
            {
              budgetRows: budgetRows,
              choiceButtonsVisible: true,
              categories: newCategories,
            },
            () => {
              console.log(
                "SheetsContainer.js 729 | bugdet rows",
                this.state.budgetRows
              );
            }
          );
        }
      }
      _updateCellFormat(
        cellsToHighlight,
        this.state.userFileId,
        this.state.monthNum
      );
    } else {
      this.setState({
        choiceBudgetAlert: `ERROR: Please try again.`,
      });
    }
  };

  // The most important function of the game.
  // It is called when a choice button is clicked.
  // Handles loading the next event and passing the spent amount to state, the Sheet and the Chart.
  // Also checks to see if it is time for a surprise event, the end of the month or end of the game.
  _startEvent = (amount, happiness, eventCategory) => {
    const events = this.state.months[this.state.monthNum].events;
    // Checks to see if it is time for a surprise.
    const insuranceSurpriseVisible =
      this.state.monthNum === this.state.insuranceSurpriseDate.month &&
      this.state.eventNum === this.state.insuranceSurpriseDate.event
        ? true
        : false;

    const carSurpriseVisible =
      this.state.monthNum === this.state.carSurpriseDate.month &&
      this.state.eventNum === this.state.carSurpriseDate.event
        ? true
        : false;

    // Non-random surprise times:
    // const insuranceSurpriseVisible =
    //   (this.state.monthNum === 0 &&
    //   this.state.eventNum === 2 ) ? true : false;

    // const carSurpriseVisible =
    //   (this.state.monthNum === 0 &&
    //   this.state.eventNum === 4 ) ? true : false;

    const choiceButtonsVisible =
      insuranceSurpriseVisible || carSurpriseVisible ? false : true;

    // Sets category of expense based on whether a Surpise is scheduled
    let category = eventCategory;
    switch (true) {
      case this.state.insuranceSurpriseVisible:
        category = "Insurance";
        break;
      case this.state.carSurpriseVisible:
        category = "Transportation";
        break;
      default:
        break;
      // category = category;
    }

    let amountFromSavings = 0;
    if (this.state.insuranceSurpriseVisible || this.state.carSurpriseVisible) {
      if (amount < this.state.emergencyFundSavings) {
        amountFromSavings = amount;
        amount = 0;
      } else {
        amountFromSavings = this.state.emergencyFundSavings;
        amount = amount - this.state.emergencyFundSavings;
      }
    }
    const emergencyFundSavings =
      this.state.emergencyFundSavings - amountFromSavings;

    const row = this.state.categories[category].row; // e.g. 5
    const range = `${this.state.expenseColumn}${row}`; // e.g. B5

    const spentAmount = this.state.categories[category].spentAmount;

    // Updates spreadsheet and chart
    this._setSheetEvent(range, amount + spentAmount);
    this._addChartData(amount);

    let happyLevel = 0;
    if (this.state.happyLevel + happiness < 0) {
      happyLevel = 0;
    } else if (this.state.happyLevel + happiness > 100) {
      happyLevel = 100;
    } else {
      happyLevel = this.state.happyLevel + happiness;
    }

    // Checks to see if event is the last in month. If not, it increments event
    if (this.state.eventNum < events.length - 1) {
      this.setState({
        emergencyFundSavings: emergencyFundSavings,
        happyLevel: happyLevel,
        choiceButtonsVisible: choiceButtonsVisible,
        carSurpriseVisible: carSurpriseVisible,
        insuranceSurpriseVisible: insuranceSurpriseVisible,
        eventNum: this.state.eventNum + 1,
        categories: {
          ...this.state.categories,
          [category]: {
            ...this.state.categories[category],
            spentAmount: amount + spentAmount,
          },
        },
      });
    } else {
      this.setState({
        emergencyFundSavings: emergencyFundSavings,
        happyLevel: happyLevel,
        choiceButtonsVisible: false,
        carSurpriseVisible: false,
        insuranceSurpriseVisible: false,
        continueButtonVisible: true,
        categories: {
          ...this.state.categories,
          [category]: {
            ...this.state.categories[category],
            spentAmount: amount + spentAmount,
          },
        },
      });
    }
  };

  _setSheetEvent = (range, amount) => {
    _updateCellValues(
      this.state.userFileId,
      range, // 'A6'
      [[amount]], // [30]
      "ROWS"
    );
  };

  // Updates chart (change update to be either google sheet or chart)
  // Sometimes receives callback of changeMonthNum
  _addChartData = (amount) => {
    let newChartData = [...this.state.chartData];
    let oldValue = newChartData.pop();
    let newValue = oldValue - amount;
    newChartData.push(newValue);
    this.setState({
      chartData: newChartData,
    });
  };

  // Opens Month Modal and shows Expense Breakdown
  _startMonthReview = () => {
    console.log("SheetsContainer.js 875 | start month review");
    const expenses = Object.values(this.state.categories);
    let sumExpenses = 0;
    expenses.forEach((expense) => {
      sumExpenses += expense.spentAmount;
    });
    const baseIncome = this.state.selectedOptions.jobs.amount;
    const monthDifference =
      baseIncome - sumExpenses < 0 ? baseIncome - sumExpenses : 0;
    const monthSavings =
      this.state.chartData[this.state.monthNum] + monthDifference;
    this.setState({
      continueButtonVisible: false,
      resultsButtonVisible: false,
      choiceButtonsVisible: false,
      monthBreakdownVisible: true,
      monthModalVisible: true,
      monthDifference: monthDifference * -1,
      monthSavings: monthSavings,
      monthNum:
        this.state.monthNum === this.state.months.length - 1
          ? this.state.monthNum
          : this.state.monthNum + 1,
      eventNum: 0,
      lastMonth:
        this.state.monthNum === this.state.months.length - 1 ? true : false,
    });
  };

  // In Month Modal, switches from Expense Breakdown to savings choice
  _startMonthChoice = () => {
    if (this.state.lastMonth) {
      this.setState({
        monthBreakdownVisible: false,
        resultsModalVisible: true,
      });
    } else {
      this.setState({
        monthBreakdownVisible: false,
      });
    }
  };

  _startGameReview = () => {
    this.setState({
      resultsModalVisible: true,
      monthBreakdownVisible: false,
      monthModalVisible: false,
    });
  };

  // Decide which modal to render. e.g. After start button is clicked, modal will be LoadingModal
  _renderModal = () => {
    switch (true) {
      case this.state.alertModalVisible:
        return (
          <AlertModal
            _hideAlertModal={this._hideAlertModal}
            choiceBudgetAlert={this.state.choiceBudgetAlert}
          />
        );
      case this.state.loadingModalVisible:
        return <LoadingModal />;
      case this.state.setupModalVisible:
        return (
          <SelectFixedExpenses
            _startMonth={this._startMonth}
            _updateOption={this._updateOption}
            userName={this.state.userName}
            levelNum={this.state.levelNum}
            setupOptions={this.state.setupOptions}
            selectedOptions={this.state.selectedOptions}
          />
        );
      case this.state.monthModalVisible:
        return (
          <MonthModal
            _getAverageSavings={this._getAverageSavings}
            handleNext={this.props.handleNext}
            userName={this.state.userName}
            monthBreakdownData={this.state.categories}
            monthBreakdownVisible={this.state.monthBreakdownVisible}
            _startMonthChoice={this._startMonthChoice}
            _startMonth={this._startMonth}
            _startGame={this._startGame}
            monthSavings={this.state.monthSavings}
            savingsChoice={this.state.savingsChoice}
            _selectSavingsChoice={this._selectSavingsChoice}
            happyLevel={this.state.happyLevel}
            _startGameReview={this._startGameReview}
            monthNum={this.state.monthNum}
            months={this.state.months}
            savingsData={this.state.chartData}
            emergencyFundSavings={this.state.emergencyFundSavings}
            resultsModalVisible={this.state.resultsModalVisible}
            toggleNext={this.props.toggleNext}
            sheetId={this.state.userFileId}
          />
        );
      default:
        return null;
    }
  };

  render() {
    switch (true) {
      case this.state.startButtonVisible:
        return (
          <>
            <div className="start-screen">
              <StartButton
                _startGame={this._startGame}
                startButtonVisible={this.state.startButtonVisible}
              />
            </div>
          </>
        );
      case this.state.simulationVisible:
        return (
          <>
            <Helmet>
              <title>Budgeting</title>
              <meta
                name="Budgeting"
                content="Learn to Budget with a real simulation."
              />
            </Helmet>
            <WidthWarning />
            <div className="sheets">
              <div className="interactive">
                <div className="events-container">
                  <Choices
                    userName={this.state.userName}
                    months={this.state.months}
                    eventNum={this.state.eventNum}
                    monthNum={this.state.monthNum}
                    _startEvent={this._startEvent}
                    token={this.props.token}
                    userFileId={this.state.userFileId}
                    resultsButtonVisible={this.state.resultsButtonVisible}
                    continueButtonVisible={this.state.continueButtonVisible}
                    choiceButtonsVisible={this.state.choiceButtonsVisible}
                    _startGameReview={this._startGameReview}
                    _startMonthReview={this._startMonthReview}
                    _addChartData={this._addChartData}
                    insurancePlan={this.state.selectedOptions.insurance}
                    carSurpriseVisible={this.state.carSurpriseVisible}
                    insuranceSurpriseVisible={
                      this.state.insuranceSurpriseVisible
                    }
                    _getSheetBudget={this._getSheetBudget}
                    expenseColumn={this.state.expenseColumn}
                    choiceBudgetAlert={this.state.choiceBudgetAlert}
                    selectedOptions={this.state.selectedOptions}
                    budgetRows={this.state.budgetRows}
                    categories={this.state.categories}
                  />
                </div>
                <div className="google-sheet">
                  <GoogleSheet userFileId={this.state.userFileId} />
                </div>
              </div>
              <div className="dashboard">
                <div className="emergency-fund">
                  <EmergencyFund
                    emergencyFundSavings={this.state.emergencyFundSavings}
                    months={this.state.chartMonths}
                    amount={this.state.chartData}
                    happyLevel={this.state.happyLevel}
                    choiceButtonsVisible={this.state.choiceButtonsVisible}
                  />
                </div>
                <div className="savings-chart">
                  <h4 className="account-label">
                    Savings: $ {this.state.emergencyFundSavings}
                    {/* <Odometer
                      format="(d,ddd).d"
                      duration={300}
                      value={this.state.emergencyFundSavings}
                    /> */}
                    {/* Savings: {`$${this.state.emergencyFundSavings}`} */}
                  </h4>
                  <h4 className="account-label">
                    Checkings: ${" "}
                    {/* <Odometer
                      format="(d,ddd).d"
                      duration={300}
                      value={this.state.chartData[this.state.monthNum] || 0}
                    /> */}
                    {`$${this.state.chartData[this.state.monthNum] || 0}`}
                  </h4>
                  <div className="chart-container">
                    <Chart
                      chartTitle="Available funds"
                      chartLabels={this.state.chartMonths}
                      chartData={this.state.chartData}
                      chartType="Bar"
                      responsive={true}
                      aspectRatio={false}
                    />
                  </div>
                  <div>Play Number: {this.state.playNum}</div>
                </div>
              </div>
              {this._renderModal()}
            </div>
          </>
        );
      default:
        return null;
    }
  }
}

export default compose(withFirebase)(Sheets);
