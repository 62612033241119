import React from "react";
import "./index.css";

const ToggleSwitch = ({isActive, func}) => {

    const className = `toggle-component ${ isActive ? ' active' : ''}`
    
    return (
      <div
        className={className}
        onClick={() => func(!isActive)}>
        <div className='toggle-button' />
      </div>
    )
  }


export default ToggleSwitch;