import React from "react";
import "./GameResultNotice.css";
import { BudgetingWinModal } from "./BudgetingWinModal";
const GameResultNotice = ({ state }) => {
  // check if too sad
  const generateResultLoss = () => {
    const balance = state.balance;
    const happiness = state.happiness;

    if (balance >= 0 && happiness <= 14) {
      return "You lost. You have enough money, but not enough happiness. Spend on things that make you happier!";
    }

    if (balance <= 0 && happiness >= 15) {
      return "You lost. You are happy, but broke. Manage your spending better, and stay happy.";
    }

    if (balance < 0 && happiness <= 14) {
      return "You lost. You are both unhappy and broke! Try spending less, and balance it with things that also make you happier!";
    }

    return null;
  };
  // check if broke

  if (!generateResultLoss()) {
    return <BudgetingWinModal />;
  } else {
    return (
      <div className="budgetgameresult-notification">
        <div className="you-lost-you">{generateResultLoss()}</div>
      </div>
    );
  }
};

export default GameResultNotice;
