import React from "react";

import "./HeaderInfoContent.css";
const HeaderInfoContent = ({ infoAmount, infoTitle }) => {
  return (
    <div className="totaldebt">
      <div className="info-title">{infoTitle}</div>
      <div className="info-amount">
        {/* <Odometer value={infoAmount} /> */}
        {infoAmount}
      </div>
    </div>
  );
};

export default HeaderInfoContent;
