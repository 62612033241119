import React from "react";
import { useState, useCallback } from "react";
import CreditWelcomeModal from "./CreditWelcomeModal";
import PortalPopup from "../../../../../PortalPopup";
import "./OpenCreditModalButton.css";

export const OpenCreditModalButton = () => {
  const [isCreditWelcomeModalPopupOpen, setCreditWelcomeModalPopupOpen] =
    useState(true);

  const closeCreditWelcomeModalPopup = useCallback(() => {
    setCreditWelcomeModalPopupOpen(false);
  }, []);

  return (
    <>
      {isCreditWelcomeModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
        >
          <CreditWelcomeModal onClose={closeCreditWelcomeModalPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default OpenCreditModalButton;
