import React from "react";
import "./EQStart.css";
const EQStart = ({ user }) => {
  return (
    <div className="eqstart">
      <section className="eqcontainer">
        <div className="eqtitle">
          Enhance Your Emotional Intelligence: Ghyst EI Test{" "}
          {user.eqTestComplete ? "Post Assessment" : ""}
        </div>
        <div className="eqdescription">
          <span>{`Welcome to the `}</span>
          <span className="ghyst-ei-test">Ghyst EI Test</span>
          <span>
            - a tool for personal development and enhancing emotional
            intelligence.
          </span>
        </div>
        <div className="eqinstructions">
          <h6 className="eqinstructionstitle">Instructions:</h6>
          <p className="press-the-start-container">
            <ul className="press-the-start-button-to-be">
              <li className="read-each-statement-carefully">
                <span>Read each statement carefully.</span>
              </li>
              <li className="read-each-statement-carefully">
                <span>
                  Choose the answer that best reflects your gut level, first
                  reaction to the statement.
                </span>
              </li>
              <li className="read-each-statement-carefully">
                <span>You must answer every question.</span>
              </li>
              <li className="read-each-statement-carefully">
                <span>
                  Use the checkbox to make your selection. Each question allows
                  only one choice.
                </span>
              </li>
              <li>
                <span>Make strong choices to provide more accurate data.</span>
              </li>
            </ul>
          </p>
        </div>
        <div className="eqwarning">
          <p className="modal-header">
            <div className="remember-the-ghyst-container">
              <span>{`Remember, the Ghyst EI Test is not for diagnosing or treating any mental or physical disease. It is intended for personal development purposes. If you have any concerns about the results or the test itself, please contact Brent Darnell to access the full versions of these tests. For mental or physical health issues, consult your mental healthcare provider or physician. In case of thoughts of suicide, please contact the Suicide Prevention Lifeline at `}</span>
              <span className="ghyst-ei-test">1-800-273-8255</span>
              <span>{` or `}</span>
              <span className="ghyst-ei-test">988</span>
              <span>.</span>
            </div>
          </p>
        </div>
        <p className="eqinfoadditional">
          Let's get started on your journey to improving your emotional
          intelligence!
        </p>
        {/* <div className="eqbutton" onClick={toggleStartScreen}>
          <div className="eqbuttontext">Start</div>
        </div> */}
      </section>
    </div>
  );
};

export default EQStart;
