import React, { useState, useContext, useEffect } from "react";
import "./index.css";
import { AuthUserContext, withAuthentication } from "../Session";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { toast } from "react-toastify";
import Button from "../shared/Button";
import { downloadClassSyllabusPDF } from "../ViewClassroom/utils/reports";
import { useDispatch, useSelector } from "react-redux";
import { getClassroomDataById } from "src/store/classrooms/classroomsActions";
import { getAllModules } from "src/store/modules/modulesActions";

const UserSettings = ({ firebase }) => {
  const authUser = useContext(AuthUserContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [userData, setUserData] = useState({
    displayName: authUser.displayName,
    phoneNumber: authUser.phoneNumber,
    email: authUser.email,
  });

  const dispatch = useDispatch();

  const { currentClassroomData } = useSelector((state) => state.classrooms);
  const { modules } = useSelector((state) => state.modules);

  useEffect(() => {
    handleUserID();
    dispatch(getClassroomDataById(firebase, authUser.classUID));
    if (currentClassroomData) {
      dispatch(getAllModules(authUser, currentClassroomData));
    }
  }, []);

  const handleSaveChanges = async () => {
    try {
      await firebase.user(authUser.uid).update({
        displayName: userData.displayName,
        phoneNumber: userData.phoneNumber,
        // email: userData.email,
      });
      setIsEditMode(false);
      toast.success("Updated Profile Info");
    } catch (error) {
      console.log("Error saving user data:", error.message);
    }
  };

  const handleUserID = async () => {
    if (authUser.uniqueId) return;
    const userUniqueId = generateUniqueNumber(authUser.uid);
    try {
      await firebase.user(authUser.uid).update({
        uniqueId: userUniqueId,
      });
      console.log("Settings 43 | user now has unique id");
    } catch (error) {
      console.log("Settings 40 | error updating unique id");
    }
  };

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const downloadClassSyllabus = () => {};

  return (
    <div className="usersettings__container">
      <div className="userprofileinfo__container">
        <div className="userprofile__title">Profile</div>
        <div className="profileimage__container">
          <img
            src={
              authUser.photoURL
                ? authUser.photoURL
                : "https://static.vecteezy.com/system/resources/thumbnails/005/129/844/small_2x/profile-user-icon-isolated-on-white-background-eps10-free-vector.jpg"
            }
            width={120}
            height={120}
            className="profile__image"
          />
        </div>
        {isEditMode ? (
          <>
            <div>
              <div className="settings__title">Legal Name</div>
              <input
                name="displayName"
                className="settings__value__edit"
                type="text"
                value={userData.displayName}
                onChange={handleChange}
              />
            </div>
            <div>
              <div className="settings__title">Phone Number</div>
              <input
                name="phoneNumber"
                className="settings__value__edit"
                type="text"
                value={userData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="savecancel__container">
              <div
                className="cancel__button"
                onClick={() => setIsEditMode(false)}
              >
                Cancel
              </div>
              <div className="save__button" onClick={handleSaveChanges}>
                Save Changes
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="settings__title">Legal Name</div>
              <div className="settings__value">{authUser.displayName}</div>
            </div>
            <div>
              <div className="settings__title">Phone Number</div>
              <div className="settings__value">{authUser.phoneNumber}</div>
            </div>
            <div>
              <div className="settings__title">Email</div>
              <div className="settings__value">{authUser.email}</div>
            </div>
          </>
        )}
      </div>
      <div className="settingsdefault__container">
        <div>
          <div className="settings__title">Classroom ID</div>
          <div className="settings__value">
            {authUser.classId || "No classroom joined"}
          </div>
        </div>
        <div>
          <div className="settings__title">User ID</div>
          <div className="settings__value">{authUser.uid}</div>
        </div>
        <div>
          <div className="settings__title">Class ID</div>
          <div className="settings__value">{authUser.classId}</div>
        </div>
        <div>
          <div className="settings__title">Student ID</div>
          <div className="settings__value">
            {generateUniqueNumber(authUser.uid)}
          </div>
        </div>
        <div style={{ width: 250, paddingTop: 12, display: "flex", gap: 12 }}>
          <Button
            type="outline"
            title="Edit Profile Info"
            onClick={() => setIsEditMode(true)}
          />
          <Button
            type="outline"
            title="Download Syllabus"
            onClick={() =>
              downloadClassSyllabusPDF(currentClassroomData, modules)
            }
          />
        </div>
      </div>
    </div>
  );
};

export function generateUniqueNumber(firebaseId) {
  let sum = 0;

  // Sum all character codes
  for (let i = 0; i < firebaseId.length; i++) {
    sum += firebaseId.charCodeAt(i);
  }

  // Multiply sum to make it longer or cut it down to exactly 9 digits
  let base = sum.toString();
  while (base.length < 9) {
    base += sum.toString(); // Append sum to itself to increase length
  }
  if (base.length > 9) {
    base = base.substring(0, 9); // Cut to make exactly 9 digits
  }

  return parseInt(base, 10);
}

export default compose(withFirebase, withAuthentication)(UserSettings);
