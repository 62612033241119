import { getToken } from "src/api/token";

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 255, g: 255, b: 255 };
};

export const _lookForSheet = async (title, _startSetup) => {
  const token = await getToken();
  console.log("sheetFunctions.js 15 | looking for sheet", token);

  const drive = `https://www.googleapis.com/drive/v3/files?q=name='${title}'&pageSize=1`;

  return fetch(drive, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then(async (info) => {
      console.log("sheetFunctions.js 11 | info from files", info, token, title);
      if (info.files.length === 0) {
        // No file, create file with that name
        // Creates new sheet
        // console.log("Nope, creating new...")
        console.log("Creating sheet");
        await _createSheet(title, _startSetup);
      } else {
        // console.log("Yes, sheet is here", info.files[0].id);
        localStorage.setItem(title, JSON.stringify(info.files[0].id));
        console.log("Sheet Info:", info);
        _startSetup(info.files[0].id);

        // saves sheet ID in storage
      }
    })
    .catch((err) => {
      console.log("sheetFunctions.js 40 | error creating sheet", err);
      return err.message;
    });
};

export const _createSheet = async (title, _startSetup) => {
  const token = await getToken();
  const sheets = "https://sheets.googleapis.com/v4/spreadsheets/";

  console.log("sheetFunctions.js 50 | token", token);

  return fetch(sheets, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      properties: {
        title: title,
      },
      sheets: [
        {
          properties: {
            sheetId: 0,
            gridProperties: {
              columnCount: 10,
              rowCount: 20,
            },
          },
        },
      ],
    }),
  })
    .then((response) => response.json())
    .then((info) => {
      // saves sheet ID in state
      console.log("Created spreadsheet", info);

      _startSetup(info.spreadsheetId);
      localStorage.setItem(
        "userFileIdSheet",
        JSON.stringify(info.spreadsheetId)
      );
    })
    .catch((err) => {
      console.log("sheetFunctions.js 82 | error creating sheet!!", err);
      throw new Error(err.message);
    });
};

export const updateSingleCellValueAndFormat = async (
  id,
  number,
  range,
  color
) => {
  let rangeObject = characterToRangeConverter(range);
  console.log("sheetFunctions.js 95 | range object");
  const token = await getToken();

  let colorOptions = {
    red: 127,
    green: 255,
    blue: 212,
  };

  if (color === "green") colorOptions.green = 1;
  if (color === "blue") colorOptions.blue = 1;
  if (color === "red") colorOptions.red = 1;

  let backgroundColor = hexToRgb(color);

  await fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${id}:batchUpdate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        requests: [
          {
            repeatCell: {
              range: rangeObject,
              cell: {
                userEnteredValue: {
                  numberValue: number,
                },
                userEnteredFormat: {
                  horizontalAlignment: "CENTER",
                  backgroundColor: {
                    red: backgroundColor.r / 255,
                    green: backgroundColor.g / 255,
                    blue: backgroundColor.b / 255,
                  },
                  textFormat: {
                    foregroundColor: {
                      blue: 0,
                      red: 0,
                      green: 0,
                    },
                    bold: true,
                  },
                  numberFormat: {
                    type: "CURRENCY",
                  },
                },
              },
              fields: "*",
            },
          },
        ],
      }),
    }
  );
};

// This is working for whatever reason
export const checkSingleCell = async (token, id, range) => {
  console.log("sheetFunctions.js 137 | checking single cell", token, id, range);
  return fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/${range}?valueRenderOption=FORMULA`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((info) => {
      return info;
    })
    .catch((error) => {
      console.log("sheetFunctions.js 152 | ", "ERROR", error);
    });
};

// Takes a string range 'A1' or 'A1:B2' returns a number range object
export const characterToRangeConverter = (range) => {
  let letter = range[0];
  let letterToNumber = letter.charCodeAt(0) - 65; //gives 0 for A, 1 for B
  let number = parseInt(range[1]);

  return {
    //number range object returned for google sheet
    startColumnIndex: letterToNumber,
    endColumnIndex: letterToNumber + 1,
    startRowIndex: number - 1,
    endRowIndex: number,
    sheetId: 0,
  };
};
