import React from "react";
import "./IntroModal.css";

import { withFirebase } from "../../../../../../Firebase";

const IntroModal = (props) => {
  const handleGameStart = () => {
    props.hideIntro();
    if (!process.env.NODE_ENV === "development") {
      props.firebase
        .userActivity()
        .add({
          action: "User playing Intro Game",
          game: "Intro to Budgeting",
          time: Date.now(),
        })
        .then(() => {
          console.log("IntroModal.js 13 | sent user intro data");
        })
        .catch((error) => {
          console.log("IntroModal.js 18 | ", "ERROR", error);
        });
    }
  };

  const { introModalVisible, name } = props;
  if (introModalVisible) {
    return (
      <div className="intro-modal-background">
        <div className="intro-modal-budgeting">
          <div className="intro-modal-contents">
            <h3>
              <strong>{props.name}</strong>
            </h3>
            <div className="intro-learning-objectives">
              {/* <h5>Learning Objectives</h5>
              <ul>
                <li>Understand Simple and Compount Interest</li>
                <li>Learn why investing early is so important.</li>
              </ul> */}
            </div>
            <button
              onClick={() => handleGameStart()}
              className="btn btn-success btn-block intro-button-start"
            >
              Start Tutorial
            </button>
            {/* <div className="coming-up-next"> */}
              {/* <h4>Coming up next:</h4>
              <p>
                After this introduction, you'll unlock a full Investing
                Simulator.
              </p> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default withFirebase(IntroModal);
