import { getToken } from "src/api/token";

//WORKING FUNCTION
export const _formatValue = async (token, sheetId, range) => {
  console.log("Format range", token);
  let myRange = characterToRangeConverter(range); //converts A1:B2 into what this parameter needs
  const repeatCell = {
    range: myRange,
    cell: {
      userEnteredFormat: {
        horizontalAlignment: "CENTER",
        numberFormat: {
          type: "CURRENCY",
        },
        backgroundColor: {
          red: 24.3,
          green: 24.3,
          blue: 24.3,
        },
        textFormat: {
          foregroundColor: {
            blue: 1,
            red: 1,
            green: 1,
          },
          bold: false,
        },
      },
    },
    fields: "userEnteredFormat(backgroundColor,textFormat,horizontalAlignment)",
  };

  try {
    const request = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}:batchUpdate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          requests: [
            {
              repeatCell,
            },
          ],
        }),
      }
    );

    return request;
  } catch (error) {
    console.log("sheetsFunctions.js.js 207 | error updating format", error);
  }
};

export const formatRangeColorAndBackground = async (
  sheetId,
  range,
  fontHex,
  backgroundHex,
  formatForNumbers
) => {
  let myRange = characterToRangeConverter(range);
  let fontColor = hexToRgb(fontHex);
  let backgroundColor = hexToRgb(backgroundHex);

  const body = {
    requests: [
      {
        repeatCell: {
          range: myRange,
          cell: {
            userEnteredFormat: {
              backgroundColor: {
                red: backgroundColor.r / 255,
                green: backgroundColor.g / 255,
                blue: backgroundColor.b / 255,
              },
              horizontalAlignment: "CENTER",
              textFormat: {
                foregroundColor: {
                  red: fontColor.r / 255,
                  green: fontColor.g / 255,
                  blue: fontColor.b / 255,
                },
                bold: true,
              },
              numberFormat: {
                type: formatForNumbers,
              },
            },
          },
          fields:
            "userEnteredFormat(backgroundColor,textFormat,horizontalAlignment,numberFormat)",
        },
      },
    ],
  };

  try {
    const token = await getToken();
    let formatUpdate = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}:batchUpdate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    );
    let data = await formatUpdate.json();
    return data;
  } catch (error) {
    console.log(
      "sheetsFunctions.js.js 263 | error updating wrong field color",
      error
    );
    // return error.message;
    throw new Error(error);
  }
};

export const simpleFormat = async (
  token,
  sheetId,
  range,
  backgroundColor,
  textColor
) => {
  let background = hexToRgb(backgroundColor);
  let text = hexToRgb(textColor);

  let cellRange = characterToRangeConverter(range);
  console.log(
    "formatFunctions.js 62 |  SIMPLE FORMAT: token, sheetId",
    token,
    range,
    sheetId,
    background,
    text
  );
  try {
    const request = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}:batchUpdate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          requests: [
            {
              repeatCell: {
                range: cellRange,
                cell: {
                  userEnteredFormat: {
                    backgroundColorStyle: {
                      red: 0,
                      green: 0,
                      blue: 0,
                    },
                    horizontalAlignment: "CENTER",
                    textFormat: {
                      foregroundColor: {
                        red: 1.0,
                        green: 1.0,
                        blue: 1.0,
                      },
                      fontSize: 10,
                      bold: false,
                    },
                  },
                },
                fields:
                  "userEnteredFormat(backgroundColor,textFormat,horizontalAlignment)",
              },
            },
          ],
        }),
      }
    );

    return request;
  } catch (error) {
    console.log(
      "formatFunctions.js 113 | error with request for simple format",
      error.message
    );
  }
};

const characterToRangeConverter = (range) => {
  console.log("range", range);
  if (!range) {
    range = "A1";
  }
  // console.log("Converting single range");
  let letter = range[0];
  let letterToNumber = letter.charCodeAt(0) - 65; //gives 0 for A, 1 for B
  let number = parseInt(range[1]);

  if (range.length < 5) {
    //checking for single range e.g A3

    let checkForSplit = range.split(":");
    // console.log("checkForSplit", checkForSplit);
    if (checkForSplit[0].length == 3) {
      // A11
      let firstLetter = checkForSplit[0][0];
      letterToNumber = firstLetter.charCodeAt(0) - 65;

      number = parseInt(checkForSplit[0].slice(1));
    }

    return {
      //number range object returned for google sheet
      startColumnIndex: letterToNumber,
      endColumnIndex: letterToNumber + 1,
      startRowIndex: number - 1,
      endRowIndex: number,
      sheetId: 0,
    };
  } else {
    //checking for multiple range e.g A1:B11
    // console.log("More than 3!", range);

    let splitRange = range.split(":");
    console.log(splitRange);

    let firstLetter = splitRange[0][0].toUpperCase();
    let colStart = firstLetter.charCodeAt(0) - 65;

    let secondLetter = splitRange[1][0].toUpperCase(); //gets the second first letter
    let colEnd = secondLetter.charCodeAt(0) - 65; //gives 0 for A, 1 for B

    let rowStart = parseInt(splitRange[0].slice(1));

    let rowEnd = parseInt(splitRange[1].slice(1));

    let secondNumber = range[4];

    return {
      startColumnIndex: colStart,
      endColumnIndex: colEnd,
      startRowIndex: rowStart,
      endRowIndex: rowEnd,
      sheetId: 0,
    };
  }
};

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const hexToRgbA = (hex) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
  throw new Error("Bad Hex");
};
