import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";

import App from "./components/App";
import Firebase, { FirebaseContext } from "./components/Firebase";

import { Provider } from "react-redux";
import store from "./store";

import "./index.css";

if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://74db3bfc16004bbbbec74bd822989296@o1377255.ingest.sentry.io/6687892",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

// New way to render the app using React 18
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </Provider>
);

serviceWorker.unregister();
