import React, { useContext } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { setClassroom } from "src/store/chats/chatActions";
import { Spinner } from "src/components/Spinner";
import { AuthUserContext } from "src/components/Session";

const ClassroomsList = () => {
  const dispatch = useDispatch();
  const authUser = useContext(AuthUserContext);

  const { currentClassroomId, currentChatroomLoading } = useSelector(
    (state) => state.chats
  );

  const classrooms = useSelector((state) => state.chats.classrooms);

  const handleClick = (classroomId) => {
    if (currentChatroomLoading) {
      return;
    } else {
      dispatch(setClassroom(classroomId));
    }
  };

  const filteredClassrooms = Object.entries(classrooms).filter(
    ([id, classInfo]) => {
      if (authUser.isAdmin) return true;
      if (authUser.isMentor && classInfo.teachers) {
        return Object.values(classInfo.teachers).some(
          (teacher) => teacher.email === authUser.email
        );
      }
      return false;
    }
  );

  const renderClassroom = (classroomId, className) => {
    const selectedClass =
      currentClassroomId === classroomId
        ? "classroomchat__preview__selected"
        : "classroomchat__preview";
    return (
      <div
        className={selectedClass}
        key={classroomId}
        onClick={() => handleClick(classroomId)}
      >
        {className}
        {currentChatroomLoading && currentClassroomId === classroomId && (
          <span className="chatroomsyncloading">
            <Spinner />
          </span>
        )}
      </div>
    );
  };

  if (!classrooms) return null;

  return (
    <div className="classroomchats">
      <div className="classroomchatsselected__title">Classrooms</div>
      {filteredClassrooms
        .sort((a, b) => (a[1].className > b[1].className ? 1 : -1))
        .map(([id, classInfo]) => renderClassroom(id, classInfo.className))}
    </div>
  );
};

export default ClassroomsList;
