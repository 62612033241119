export const openModalNotification = ({ title, icon, info }) => {
  console.log(
    "notificationsActions.js 1 | notification opening",
    title,
    icon,
    info
  );
  return { type: "notifications/open", payload: { title, icon, info } };
};
export const closeNotification = () => {
  return { type: "notifications/close" };
};
