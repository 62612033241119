import React from "react";
import { HorizontalBar } from "react-chartjs-2";

export const CompetencyChart = ({ competencies }) => {
  const competencyNames = [];
  const competencyPercentScores = [];

  Object.entries(competencies).forEach((competency) => {
    if (typeof competency[1] === "object") {
      const { totalValue, count } = competency[1];
      const competencyName = competency[0];
      const competencyPercentScore = ((totalValue / (count * 5)) * 100).toFixed(
        2
      );

      competencyNames.push(competencyName);
      competencyPercentScores.push(competencyPercentScore);
    }
  });

  const data = {
    labels: competencyNames,
    datasets: [
      {
        label: "Competency Scores",
        data: competencyPercentScores,
        backgroundColor: competencyPercentScores.map((value) => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const gradient = ctx.createLinearGradient(0, 0, 100, 0);
          gradient.addColorStop(0, "red");
          gradient.addColorStop(value / 100, "green");
          return gradient;
        }),
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            min: 0,
            max: 100,
          },
        },
      ],
    },
  };

  return <HorizontalBar data={data} options={options} />;
};
