import React from "react";
import "./SettingBudget.css";
import RangeInput from "./RangeInput";
import BudgetCategoryAdjust from "./BudgetCategoryAdjust";
import Button from "src/components/shared/Button";
import IncomeRange from "./IncomeRange";
import { toast } from "react-toastify";
import MoneySavedDecisionContainer from "./MoneySavedDecision";

export const BudgetAdjustment = ({
  state,
  budget,
  onAdjustBudget,
  budgetReady,
  adjustMonthToSaveChoice,
  onRestartGame,
}) => {
  const handleSubmit = () => {
    let allBudgetsHigherThanExpenses = true;
    let allBudgetsHigherThanCero = true;
    let totalBudgetLowerThanIncome = calculateTotalBudget() <= state.job.salary;
    Object.entries(state.expenses).forEach(([category]) => {
      if (state.budget[category] < state.expenses[category].cost) {
        allBudgetsHigherThanExpenses = false;
      }
    });

    Object.values(state.budget).forEach((value) => {
      if (value === 0) {
        allBudgetsHigherThanCero = false;
      }
    });

    const messages = [];

    if (!totalBudgetLowerThanIncome) {
      messages.push("⚠️ You're budgeting more than your income!");
    }

    if (!allBudgetsHigherThanCero) {
      messages.push("You should budget amounts higher than 0!");
    }

    if (!allBudgetsHigherThanExpenses) {
      messages.push(
        "⚠️ Your budgets are lower than your fixed expenses, they have to be equal or higher"
      );
    }

    if (!state.saveMonthToCheckingOrSavings && state.currentMonth > 1) {
      messages.push("Please select where you want your money to go");
    }

    if (messages.length === 0) {
      toast.success("Budget Ready");
      budgetReady();
    } else {
      toast.error(messages.join(" "));
    }
  };

  const calculateTotalBudget = () => {
    return Object.values(state.budget).reduce(
      (total, amount) => total + amount,
      0
    );
  };

  const handleExtraBalanceDecision = (type) => {
    console.log("BudgetAdjustment 57 | selected", type);
  };

  return (
    <div className="settingbudget">
      {state.currentMonth > 1 && (
        <div>
          <div className="finished-budgeting-month">
            Great job! You finished the month. Please adjust your budget to your
            latest expenses before moving on.
          </div>
        </div>
      )}

      <div className="title">
        <div className="setting-budget-for">Budget for each category</div>
      </div>

      {state.budget &&
        Object.entries(state.budget).map((category, index) => {
          const title = category[0];
          const amount = category[1];
          const prevAmount = state.expenses[title].cost;

          return (
            <BudgetCategoryAdjust
              key={index}
              title={title}
              amount={amount}
              budget={budget}
              state={state}
              onAdjustBudget={onAdjustBudget}
              prevAmount={100}
              totalBudget={calculateTotalBudget()}
            />
          );
        })}
      <IncomeRange budget={calculateTotalBudget()} income={state.job?.salary} onRestartGame={onRestartGame} />

      {state.currentMonth > 1 && (
        <MoneySavedDecisionContainer
          moneyLeftover={state.balance}
          adjustMonthToSaveChoice={adjustMonthToSaveChoice}
          state={state}
        />
      )}
      <Button title="Next" onClick={handleSubmit} />
    </div>
  );
};

export default BudgetAdjustment;
