import React from "react";
import "./index.css";

export const Icon = ({
  name = "email",
  width = 30,
  height = 30,
  onClick,
  hover = false,
  style,
}) => {
  const handleClick = () => {
    onClick && onClick();
  };
  return (
    <img
      onClick={() => handleClick()}
      src={require(`./svgs/${name}.svg`)}
      style={style && style}
      alt="icon"
      width={width}
      height={height}
      className={hover ? "icon-hover" : null}
    />
  );
};

export default Icon;
