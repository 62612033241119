import React from "react";
import "./BrowserWarning.css";
export const BrowserWarning = () => {
  return (
    <div className="finance-browser-warning">
      <div className="frame-parent">
        <div className="thank-you-parent">
          <div className="thank-you">WARNING</div>
          <img className="close-icon" alt="" src="/warning.png" />
        </div>
        <img className="check-svgrepo-com-1-icon" alt="" src="/warning.png" />
        <div className="you-have-confirmed-the-eventit-parent">
          <div className="you-have-confirmed-container">
            <p className="you-have-confirmed">
              You need to use <strong>Google Chrome</strong> in order to
              complete this activity. Please download Chrome and login with your
              account.
            </p>
            <p className="you-have-confirmed">
              Download here:{" "}
              <a href="https://www.google.com/chrome/" target="_blank">
                Google Chrome
              </a>
            </p>
            <br />
            <p className="you-have-confirmed">
              If you <strong>have a tablet provided by us</strong>, please
              disable S-Mode to be able to download Google Chrome. Instructions
              to disable can be found here:{" "}
              <a
                href="https://support.microsoft.com/en-us/windows/switching-out-of-s-mode-in-windows-4f56d9be-99ec-6983-119f-031bfb28a307"
                target="_blank"
              >
                Disable S-Mode
              </a>
              <br />
              You can also watch the following tutorial to disable S-Mode:
              <a
                href="https://www.youtube.com/watch?v=eBnVUH3cLCE"
                target="_blank"
              >
                Tutorial: Disable S-Mode
              </a>
            </p>
            <br />
            <p className="on-august-18">
              Reach out to Ignacio at ignacio@stoprecidivism.org or text at
              (719)-215-5795 if you're having issues with this module.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
