import React from "react";

const WelcomeModal = (props) => {
  const { _toggleScreen } = props;

  return (
    <div className="snowball-modal">
      <div className="modal-mid">
        <h3>
          <strong>Budgeting Game Objectives</strong>
        </h3>
        <ul>
          <li>
            Finish 3 months: You'll unlock new jobs{" "}
            <span role="img" aria-label="emoji">
              😉
            </span>
            .
          </li>
          <li>
            Be happy{" "}
            <span role="img" aria-label="emoji">
              🙂
            </span>{" "}
            : get to at least 15 happy points!
          </li>
          <li>
            Spend <strong>less</strong> than you earn .
          </li>
          <li>
            Choose your <strong>fixed expenses</strong> wisely.
          </li>
          <li>
            Choose your <strong>variable expenses</strong> wisely.
          </li>
        </ul>
      </div>
      <div className="modal-bottom">
        <button className="btn btn-primary btn-lg" onClick={_toggleScreen}>
          Start Playing!
        </button>
      </div>
      <div className="challenge-warning">
        <h4>
          Warning: Most People cannot beat this game on the first attempt.
        </h4>
      </div>
    </div>
  );
};

export default WelcomeModal;
