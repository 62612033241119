import React, { Component } from "react";
import "./InvestingContainer.css";
import {
  _updateColumnRange,
  _lookForSheet,
  _batchUpdateSingleValue,
  _updateInitialValues,
  _clearSheetValues,
  _updateValuesRange,
} from "../sheetsFunctions/sheetsFunctions";

import InvestingSpreadsheet from "../InvestingComponents/InvestingSpreadsheet/InvestingSpreadsheet";
import InvestingChoices from "../InvestingComponents/InvestingChoices/AssetChoices";
import Chart from "../InvestingComponents/Chart/Chart";

import { stocks, stocksLocal, simulationDates } from "../Data/stocksData";
import IntroModal from "../InvestingComponents/IntroModal/IntroModal";
import InvestingInfo from "../InvestingComponents/InvestingInfo/InvestingInfo";
import ProgressBar from "../InvestingComponents/ProgressBar/ProgressBar";
import ErrorModal from "../InvestingComponents/ErrorModal/ErrorModal";
import LoadingModal from "../InvestingComponents/Modals/LoadingModal/LoadingModal";
import InstructionsModal from "../InvestingComponents/Modals/InstructionsModal/InstructionsModal";
import GoalModal from "../InvestingComponents/Modals/GoalModal/GoalModal";
import SelectTimeModal from "../InvestingComponents/Modals/SelectTimeModal/SelectTimeModal";

import EndGameModal from "../InvestingComponents/Modals/EndGameModal/EndGameModal";
import { news } from "../Data/newsData";
import NewsModal from "../InvestingComponents/NewsModal/NewsModal";
import HighScores from "../InvestingComponents/HighScore/HighScore";

import { Helmet } from "react-helmet";
import { formatRangeColorAndBackground } from "../sheetsFunctions/formatFunctions";
import { openNotification } from "../../Debt/DebtComponents/DebtNotifications";

class InvestingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usingLocalData: true,
      timePeriod: 12, //starts in 1998, 12 periods is 1 year. 0 means 1998-02-27
      simulationPeriodsNumber: 60, //120 is 10 years, 12 is one year, 60 is 5 years
      endGame: false,
      timing: false,
      tickerFound: false,
      displayTimer: false,
      portfolioIndexNumber: 0,
      playerLevel: 0,
      stocksData: null,
      highscores: null,

      availableAssetsTest: stocksLocal,
      availableAssets: stocks,
      title: "Investing Portfolio Snowball 1.89",
      sheetId: null,
      cashValue: 1000,
      portfolio: [],
      portfolioValue: 0,
      currentPeriod: 0,
      currentRange: "A4",
      currentDate: simulationDates[0],

      newsTitle: null,
      newsMessage: null,
      newsRecommendation: null,

      time: 0,

      chartLabels: [],
      chartData: [],

      // Modal Handlers
      tutorialIsActive: false,
      introModalVisible: true,
      errorModalVisible: false,
      goalModalVisible: false,
      timeModalVisible: false,
      newsModalVisible: false,
      highscoreModalVisible: false,

      //Portfolio - Sheet info
      portfolioMonths: [
        "January",
        "Feburary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      portfolioValueOverTime: [],
      columnValues: [
        "Ticker",
        "# Amount",
        "Price Paid",
        "Current Price",
        "Current Amount",
        "Initial Amount",
        "Profit",
      ],
      netWorth: 0,
      errorType: "",
      transactionInProgress: false,
      winGame: false,
      winGoal: 2500,
      loading: false,
    };
  }

  _tutorialHandler = () => {
    this.setState({
      introModalVisible: true,
      tutorialIsActive: false,
    });
  };

  _getTickerData = async (ticker) => {
    const request = await fetch(
      `https://www.alphavantage.co/query?function=TIME_SERIES_MONTHLY_ADJUSTED&symbol=${ticker}&apikey=R3XR`
    );
    const data = await request.json();
    return data;
  };

  _getAllData = async () => {
    //Gets all stocks data
    const requests = stocks.map(async (stock) => {
      return fetch(
        `https://www.alphavantage.co/query?function=TIME_SERIES_MONTHLY_ADJUSTED&symbol=${stock.ticker}&apikey=R3XR`
      )
        .then((response) => response.json())
        .then((data) => {
          return data;
        });
    });
    return Promise.all(requests).then((data) => {
      return data;
    });
  };

  _updateInvestingLevel = async (level) => {
    // console.log(API_URL);
    console.log(
      "InvestingContainer.js 153 | updating investing level",
      level,
      this.props.API_URL
    );
    // This updates the current level in the database if the level is different than 0

    let value = this.state.cashValue + this.state.portfolioValue;

    let highScore =
      this.props.userData.investingHighScore < value
        ? value
        : this.props.userData.investingHighScore;
    const url = `${this.props.API_URL}/updateInvestingSaveFile`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        googleId: this.props.userData.googleId,
        investingLevel: level, //Updates with the current state
        investingHighScore: highScore,
      }),
    })
      .then(() => {
        console.log("InvestingContainer.js 177 | investing level updated");
      })
      .catch((error) => {
        console.log("InvestingContainer.js 179 | ", "ERROR", error);
      });
  };

  getStocksDataFromDb = async () => {
    try {
      console.log("InvestingContainer.js 188 | trying to get stocks data");
      const request = await fetch(`${this.props.API_URL}/getStocksData`);
      const data = await request.json();
      console.log("InvestingContainer.js 191 | got data", data);
      return data;
    } catch (error) {
      console.log(
        "InvestingContainer.js 192 | error getting stocks data",
        error
      );
    }
  };

  getHighscoresFromDb = async () => {
    const request = await fetch(`${this.props.API_URL}/highscores`);
    const data = await request.json();
    return data;
  };

  async componentDidMount() {
    console.log("InvestingContainer.js 200 | investing component mounted");
    if (this.props.userData) {
      this.setState(
        {
          playerLevel: this.props.userData.investingLevel,
          stocksData: this.props.stocksDataFromDb,
        },
        () => {
          if (this.state.playerLevel > 0) {
            this.setState({
              tutorialIsActive: false,
              introModalVisible: true,
            });
          }
        }
      );
    }

    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000); //hide loading
    // get ticker data
    if (localStorage.getItem("investingFileId")) {
      //checks if there's a sheetId in the session
      const sheetId = localStorage
        .getItem("investingFileId")
        .substr(1)
        .slice(0, -1);
      //there is one!
      this.setState({
        sheetId: sheetId,
      });
    } else {
      _lookForSheet(this.props.token, this.state.title, this._startSetup);
    }
    _lookForSheet(this.props.token, this.state.title, this._startSetup);
    if (!this.state.usingLocalData) {
      this._getAllData().then((data) => {
        data.forEach((asset) => {
          const entries = Object.entries(asset["Monthly Adjusted Time Series"]);
          const assetSymbol = asset["Meta Data"]["2. Symbol"];
          const tickerData = entries
            .map((entry) => {
              const price = entry[1]["5. adjusted close"];
              return parseInt(price);
            })
            .reverse();
          const dateKeys = Object.keys(asset["Monthly Adjusted Time Series"]);
          const datesData = entries
            .map((entry) => {
              const date = entry[0];
              return date;
            })
            .reverse();
          this.setState({
            availableAssets: this.state.availableAssets.map((el) =>
              el.ticker === assetSymbol
                ? Object.assign({}, el, { prices: tickerData })
                : el
            ),
          });
        });
      });
    }
  }

  _startSetup = (sheetId) => {
    this.setState(
      {
        sheetId: sheetId,
      },
      async () => {
        this._setInitialValues();
        this.getStocksDataFromDb().then((stocks) => {
          this.setState(
            {
              stocksData: stocks,
            },
            () => {
              console.log("InvestingContainer.js 219 | got data from db");
            }
          );
        });

        this.getHighscoresFromDb().then((scores) => {
          this.setState({
            highscores: scores,
          });
        });
      }
    );
  };

  _setInitialValues = async () => {
    try {
      let initialValuesUpdate = await _updateValuesRange(
        this.props.token,
        this.state.sheetId,
        "A3:G3",
        this.state.columnValues
      );
      let totalValuesUpdate = await _updateValuesRange(
        this.props.token,
        this.state.sheetId,
        "D12",
        ["Total"]
      );
      console.log(
        "InvestingContainer.js 292 | initial values update",
        initialValuesUpdate,
        totalValuesUpdate
      );
    } catch (error) {
      console.log("InvestingContainer.js 320 | error setting values", error);
    }
  };

  _startGame = async () => {
    const { token } = this.props;
    const { sheetId } = this.state;

    this.setState({ goalModalVisible: false, displayTimer: true }); //hides intro modal
    let resettingSheetValues = await _clearSheetValues(token, sheetId, "A4:G8"); //Clears specified range if sheet is in session

    // let newFormat = await _formatValue(token, sheetId, "A2:H3");
    let headerFormat = await formatRangeColorAndBackground(
      token,
      sheetId,
      "A2:H3",
      "#FFFFFF",
      "#3e3e3e"
    );
    let bodyFormat = await formatRangeColorAndBackground(
      token,
      sheetId,
      "A3:H11",
      "#FFFFFF",
      "#FFFFFF"
    );
    let totalsFormat = await formatRangeColorAndBackground(
      token,
      sheetId,
      "A11:H12",
      "#FFFFFF",
      "#3e3e3e"
    );
    console.log(
      "InvestingContainer.js 320 | updated new format",
      headerFormat,
      bodyFormat,
      totalsFormat
    );

    console.log(
      "InvestingContainer.js 315 | START GAME reset sheet values",
      resettingSheetValues
    );

    //Timer starts and updates stock values
    if (this.state.availableAssets || this.state.availableAssetsTest) {
      this._startLoop();
    }
  };

  _restartGame = () => {
    window.location.reload();
  };

  startTimer = () => {
    if (!this.state.timing) {
      this.setState(
        {
          timing: true,
        },
        () => {
          this.timeVar = setInterval(() => {
            this._startLoop();
            this.setState({
              time: this.state.time + 1,
            });
          }, 2700);
        }
      );
    }
  };

  stopTimer = () => {
    clearInterval(this.timeVar);
    this.setState({ timing: false });
  };

  _startLoop = () => {
    //MAIN GAME LOOP

    const { usingLocalData, sheetId } = this.state;
    const { token } = this.props;
    let periods; //this is what I need to fix and convert to the prices array thing

    const local = this.state.availableAssetsTest[0].prices;
    const realTime = this.state.availableAssets[0].prices;

    let simulationIndex = 0;

    const timePeriod = this.state.timePeriod; //Manipulates time that simulation starts at. 12 periods is 1 year || IMPORTANT

    if (usingLocalData) {
      periods = local;
    } else {
      periods = realTime; //this is what I need to fix and convert to the prices array thing
    }

    const time = 4000; //updates assets every interval

    if (!this.state.winGame && typeof this.state.currentDate !== undefined) {
      //need an index
      this.setState({
        //increases time period
        timePeriod: this.state.timePeriod + 1,
      });

      if (
        typeof this.state.currentDate !== "undefined" &&
        this.state.time <= this.state.simulationPeriodsNumber
      ) {
        const netWorth = this.state.cashValue + this.state.portfolioValue;

        //add Cash
        if (this.state.timePeriod % 20 === 0) {
          const addCash = this.state.cashValue + 200;
          this.setState(
            {
              cashValue: addCash,
            },
            () => {
              // this._displayErrorModal("savingsAdded")
              openNotification(
                "Added Cash!",
                "You have an extra $200 to invest."
              );
            }
          );
        }

        let portfolioTotalValue = 0;
        let dataToUpdateSheet = ["", "", "", "", "", "", ""];

        this.state.portfolio.forEach((asset, index) => {
          //this loop is used to update the current prices column
          const assets = this.state.stocksData;
          const foundTicker = assets.find((assetFound) => {
            return assetFound.ticker === asset.ticker;
          });

          let currentPrice = foundTicker.prices[simulationIndex + timePeriod];
          dataToUpdateSheet[index] = currentPrice; //updates array of all the prices that will be updated
          portfolioTotalValue += currentPrice * asset.amount;
        });

        _updateColumnRange(token, sheetId, "D4:D11", dataToUpdateSheet); //updates all current prices after previous foreach

        const currentDate = simulationDates[simulationIndex + timePeriod]; //sets current date
        const newChartData = [
          ...this.state.chartData,
          portfolioTotalValue + this.state.cashValue,
        ];
        const newChartLabels = [...this.state.chartLabels, currentDate];

        this.setState(
          {
            currentPeriod: simulationIndex + timePeriod,
            portfolioValue: portfolioTotalValue,
            currentDate: currentDate,
            transactionInProgress: false,
            chartData: newChartData,
            chartLabels: newChartLabels,
          },
          () => {
            simulationIndex++;
            // current date is compared to all the dates. if the current date matches, display news modal
            let datesMatch = false;
            let dateForModal;
            news.forEach((date) => {
              if (this.state.currentDate == date.date) {
                datesMatch = true;
                this.setState({
                  newsTitle: date.title,
                  newsMessage: date.description,
                  newsRecommendation: date.recommendation,
                  currentImage: date.img,
                });
              }
            });

            if (datesMatch) {
              this.setState({ newsModalVisible: true });
              this.stopTimer();
            } else {
              this.setState({ newsModalVisible: false });
            }
          }
        ); //increases period number
      } else {
        // Game ends, display end screen
        this.setState(
          {
            endGame: true,
          },
          () => {
            if (this.state.winGoal < this.state.netWorth) {
              this.setState(
                {
                  playerLevel: this.state.playerLevel + 1,
                },
                () => {
                  this._updateInvestingLevel(this.state.playerLevel);
                }
              );
            }
          }
        );
        this.stopTimer();
      }
    }
  };

  _buyAssetModal = (name, price, amount, ticker) => {
    amount = parseInt(amount);
    const { token } = this.props;
    const { sheetId } = this.state;
    const valueTraded = parseInt(amount) * price;
    let currentIndex;

    const foundAssetInPortfolio = this.state.portfolio.find((asset, index) => {
      //look for asset in portfolio
      return asset.asset === name;
    });

    const currentRange = this.state.portfolioIndexNumber + 4;

    if (foundAssetInPortfolio) {
      //asset IN portfolio. Increase amount by amount
      const updatedAssetAmount =
        foundAssetInPortfolio.amount + parseInt(amount);

      if (valueTraded < this.state.cashValue && valueTraded > 0) {
        // Cannot run out of cash, value has to be greater than 0
        if (price != foundAssetInPortfolio.pricePaid) {
          //If price is different, dollar cost average it

          const previousValue =
            foundAssetInPortfolio.amount * foundAssetInPortfolio.pricePaid;

          const newPurchase = price * amount; //now
          const newCashValue = this.state.cashValue - newPurchase;
          const newPrice =
            (previousValue + newPurchase) /
            (amount + foundAssetInPortfolio.amount); //Dollar cost average formula

          this.setState(
            {
              //function to avoid replacing object
              portfolio: this.state.portfolio.map((el) =>
                el.ticker === ticker
                  ? Object.assign({}, el, {
                      amount: amount + foundAssetInPortfolio.amount,
                      pricePaid: newPrice,
                    })
                  : el
              ),
              portfolioValue: this.state.portfolioValue + newPurchase,
              cashValue: newCashValue,
            },
            () => {
              _batchUpdateSingleValue(
                token,
                sheetId,
                `C${foundAssetInPortfolio.range + 4}`,
                newPrice
              );
              _batchUpdateSingleValue(
                token,
                sheetId,
                `B${foundAssetInPortfolio.range + 4}`,
                amount + foundAssetInPortfolio.amount
              );
            }
          );
        } else {
          this.setState(
            {
              portfolio: this.state.portfolio.map((el) =>
                el.asset === name
                  ? Object.assign({}, el, { amount: updatedAssetAmount })
                  : el
              ),
              portfolioValue: this.state.portfolioValue + valueTraded,
              cashValue: this.state.cashValue - valueTraded,
            },
            () => {
              // Update spreadsheet value
              const afterUpdateAsset = this.state.portfolio.find((asset) => {
                //look for asset in portfolio
                return asset.asset === name;
              });
              _batchUpdateSingleValue(
                token,
                sheetId,
                `B${currentRange - 1}`,
                afterUpdateAsset.amount
              );
            }
          );
        }
      } else {
        //too expensive
      }
    } else {
      //Asset NOT in portfolio. Add for the first time
      const addAsset = [
        ...this.state.portfolio, //Gets previous assets, adds new asset
        {
          asset: name,
          amount: parseInt(amount),
          pricePaid: price,
          ticker: ticker,
          range: this.state.portfolioIndexNumber,
        },
      ];

      this.setState(
        {
          portfolio: addAsset,
          portfolioIndexNumber: this.state.portfolioIndexNumber + 1,
          cashValue: this.state.cashValue - valueTraded,
          portfolioValue: this.state.portfolioValue + valueTraded,
        },
        () => {
          _updateInitialValues(
            this.props.token,
            this.state.sheetId,
            price,
            `A${currentRange}`,
            ticker, //
            `B${currentRange}`,
            amount, //Current amount
            `C${currentRange}`,
            `=D${currentRange} * B${currentRange}`, //Price Paid
            `E${currentRange}`,
            `=(D${currentRange} - C${currentRange}) * B${currentRange}`, //Current Amount
            `F${currentRange}`,
            `=B${currentRange}*C${currentRange}`,
            `F${currentRange}`, //Initial Amount
            `=E${currentRange} - F${currentRange}`,
            `G${currentRange}`, //Profit
            this.state.portfolioIndexNumber, //Portfolio Index Number
            `D${currentRange}` //Current Price format
          );
        }
      );
    }
  };

  _sellAssetModal = (name, price, amount, ticker) => {
    const { token } = this.props;
    const { sheetId } = this.state;
    const valueTraded = parseInt(amount) * price;

    const currentRange = this.state.portfolioIndexNumber + 4;

    const foundAssetInPortfolio = this.state.portfolio.find((asset) => {
      //look for asset in portfolio
      return asset.asset === name;
    });

    if (foundAssetInPortfolio) {
      if (amount <= foundAssetInPortfolio.amount) {
        //selling asset
        const updatedAssetAmount = foundAssetInPortfolio.amount - amount;
        this.setState(
          {
            portfolio: this.state.portfolio.map((el) =>
              el.ticker === ticker
                ? Object.assign({}, el, { amount: updatedAssetAmount })
                : el
            ),
            portfolioValue: this.state.portfolioValue - valueTraded,
            cashValue: this.state.cashValue + valueTraded,
          },
          () => {
            // update sheet with new info
            const afterUpdateAsset = this.state.portfolio.find((asset) => {
              //look for asset in portfolio
              return asset.asset === name;
            });
            _batchUpdateSingleValue(
              token,
              sheetId,
              `B${afterUpdateAsset.range + 4}`,
              afterUpdateAsset.amount
            );
          }
        );
      } else {
        // selling more than you have, error
      }
    } else {
    }
  };

  _displayErrorModal = (errorType) => {
    this.setState({
      errorModalVisible: true,
      errorType: errorType,
    });
  };

  // Hide or show modals functions
  _hideIntroModal = () => {
    this.setState({ introModalVisible: false, timeModalVisible: true });
  };
  _hideInstructionsModal = () => {
    this.setState({ instructionsModalVisible: false, goalModalVisible: true });
  };

  _chooseTime = (time) => {
    //goes to SelectTimeModal
    let timeToSet;
    if (time === 2007) {
      timeToSet = 100;
    } else if (time === 1999) {
      timeToSet = 12;
    } else if (time === 2010) {
      timeToSet = 140;
    } else if (time === 2018) {
      timeToSet = 250;
    } else if (time === 0) {
      timeToSet = 0;
      this.setState({
        simulationPeriodsNumber: 240,
      });
    } else {
      timeToSet = Math.floor(Math.random() * 200);
    }
    this.setState({
      timeModalVisible: false,
      timePeriod: timeToSet,
      goalModalVisible: true,
    });
  }; //selecting time frame

  closeErrorModal = () => {
    this.setState({ errorModalVisible: false, errorType: "" });
  }; //Closes Error Modal in state

  // Goal form submission
  _handleGoalSubmit = (event) => {
    this.setState({
      winGoal: event.target.value,
    });
  };

  _exitTutorial = () => {
    this.setState({
      tutorialIsActive: false,
      introModalVisible: true,
    });
  };

  closeNews = () => {
    this.startTimer();
    this.setState({
      newsModalVisible: false,
    });
  };

  _openHighscores = () => {
    this.setState({
      highscoreModalVisible: true,
    });
  };
  _closeHighscore = () => {
    this.setState({
      highscoreModalVisible: false,
    });
  };

  render() {
    const {
      introModalVisible,
      goalModalVisible,
      instructionsModalVisible,
      availableAssets,
      availableAssetsTest,
      tutorialIsActive,
      sheetId,
      currentPeriod,
      transactionInProgress,
      usingLocalData: usingLocalData,
    } = this.state;

    const errorModal = this.state.errorModalVisible ? (
      <ErrorModal
        message={this.state.errorType}
        display={this.state.errorModalVisible}
        closeModal={this.closeErrorModal}
      />
    ) : null;

    const timing =
      this.state.timing == false ? (
        <div className="timer-start">
          <button
            className="fa fa-play-circle fa-2x timer-start-button timer-pulse"
            onClick={this.startTimer}
          >
            {" "}
            Start
          </button>{" "}
        </div>
      ) : (
        <div className="timer-start">
          <button
            className="fa fa-stop-circle fa-2x timer-start-button timer-stop"
            onClick={this.stopTimer}
          >
            {" "}
            Stop
          </button>{" "}
        </div>
      );

    const assets = usingLocalData ? availableAssetsTest : availableAssets;

    return (
      <div className="investing-container">
        <Helmet>
          <title>Investing</title>
          <meta
            name="description"
            content="Learn to Invest in Stocks with this real game."
          />
        </Helmet>
        {/* <button onClick={() => this._updateInvestingLevel(1)}>
          Update Level
        </button> */}
        <HighScores
          highscores={this.state.highscores}
          highscoreModalVisible={this.state.highscoreModalVisible}
          userData={this.props.userData}
          _openHighscores={this._openHighscores}
          _closeHighscore={this._closeHighscore}
        />
        <LoadingModal loading={this.state.loading} />
        {errorModal}
        <SelectTimeModal
          _chooseTime={this._chooseTime}
          timeModalVisible={this.state.timeModalVisible}
          playerLevel={this.state.playerLevel}
        />
        <IntroModal
          introModalVisible={introModalVisible}
          _hideIntroModal={this._hideIntroModal}
          sheetId={sheetId}
        />
        <InstructionsModal
          instructionsModalVisible={instructionsModalVisible}
          _hideInstructionsModal={this._hideInstructionsModal}
        />
        <GoalModal
          _startGame={this._startGame}
          goalModalVisible={goalModalVisible}
          _handleGoalSubmit={this._handleGoalSubmit}
          _hideInstructionsModal={this._hideInstructionsModal}
          sheetId={sheetId}
        />
        <EndGameModal
          _updateInvestingLevel={this._updateInvestingLevel}
          playerLevel={this.state.playerLevel}
          _restartGame={this._restartGame}
          endGame={this.state.endGame}
          portfolio={this.state.portfolioValue}
          cash={this.state.cashValue}
          winGoal={this.state.winGoal}
        />

        {/* This is the maing container */}
        <div className="row-1">
          <div className="investing-choices">
            <InvestingChoices
              _buyAssetModal={this._buyAssetModal}
              _sellAssetModal={this._sellAssetModal}
              _buyAsset={this._buyAsset}
              stocksData={this.state.stocksData}
              portfolio={this.state.portfolio}
              tutorialIsActive={this.state.tutorialIsActive}
              currentDate={this.state.currentDate}
              assets={assets}
              currentPeriod={this.state.currentPeriod}
              transactionInProgress={transactionInProgress}
              cashValue={this.state.cashValue}
            />
          </div>
          <div className="chart-info">
            <div className="chart">
              <div className="time-until-end">
                <h5>
                  <strong>
                    Current Date: <br />{" "}
                  </strong>
                  {this.state.currentDate}
                </h5>
                {this.state.displayTimer ? timing : null}
                <h5>
                  <strong>
                    End Date: <br />{" "}
                  </strong>
                  {
                    simulationDates[
                      this.state.simulationPeriodsNumber +
                        this.state.timePeriod -
                        this.state.time
                    ]
                  }
                </h5>
              </div>
              <NewsModal
                closeNews={this.closeNews}
                newsModalVisible={this.state.newsModalVisible}
                title={this.state.newsTitle}
                message={this.state.newsMessage}
                newsRecommendation={this.state.newsRecommendation}
                date={this.state.currentDate}
                img={this.state.currentImage}
              />
              <ProgressBar
                progressPercentage={
                  (this.state.time / this.state.simulationPeriodsNumber) * 100
                }
              />
              <Chart
                tutorialIsActive={this.state.tutorialIsActive}
                chartTitle={"Net Worth (Investments + Cash)"}
                chartType={"Line"}
                chartLabels={this.state.chartLabels}
                chartData={this.state.chartData}
                stocksData={this.state.stocksData}
                cashValue={this.state.cashValue}
                responsive={true}
                aspectRatio={true}
                winGoal={this.state.winGoal}
              />
            </div>
          </div>
        </div>
        <div className="row-2">
          <div className="spreadsheet">
            <InvestingSpreadsheet sheetId={sheetId} />
          </div>
          <div className="investing-info">
            <InvestingInfo
              startTimer={this.startTimer}
              stopTimer={this.stopTimer}
              timing={this.state.timing}
              time={this.state.time}
              cashValue={this.state.cashValue}
              portfolioValue={this.state.portfolioValue}
              netWorth={this.state.netWorth}
              winGoal={this.state.winGoal}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default InvestingContainer;
