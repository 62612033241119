import React from "react";
import "./buttons.css";

const StartButton = (props) => {
  console.log("StartButton.js - 5: before return");
  return props.startButtonVisible ? (
    <button
      onClick={props._startGame}
      type="button"
      className={`btn btn-primary btn-lg start-button`}
    >
      Start
    </button>
  ) : null;
};

export default StartButton;
