import React from "react";
import "./VideoSection.css";
const VideoSection = () => {
  const videoUrl =
    "https://firebasestorage.googleapis.com/v0/b/red-platform.appspot.com/o/videos%2FLBC_RED%20Diversion%20Curriculum%20Sales%20Video_v3_compressed.mp4?alt=media&token=f05cf754-ea6e-43a7-a332-840eef57b3c5";
  return (
    <div className="video-section">
      <div className="header3">
        <div className="a-message-from">A message from our leaders</div>
        <div className="learn-more-about1">Learn more about us</div>
      </div>
      <video src={videoUrl} className="video" controls>
        <source />
      </video>
    </div>
  );
};

export default VideoSection;
