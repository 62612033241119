import React from "react";
import { Link } from "react-router-dom";
import Icon from "src/components/shared/Icon";

import "./index.css";

const ContinueModule = ({ currentModule }) => {
  if (currentModule[0]) {
    return (
      <div className="continuemodule__container">
        <Icon name="continue-module" width={70} height={70} />
        <div className="continuemodule__info">
          <div className="continuemodule__title">{currentModule[0].name}</div>
          <div className="continuemodule__description">
            Continue where you left off.
          </div>
        </div>
        <Link
          className="continuemodule__button"
          to={`/lesson/${currentModule[0].uid}`}
        >
          Continue
        </Link>
      </div>
    );
  } else {
    return null;
  }
};

export default ContinueModule;
