export const debts = [
//   {
//     title: "Car",
//     interest: 0.1,
//     amount: Math.floor(Math.random() * 3000),
//     minPayment: 100,
//   },
//   {
//     title: "College",
//     interest: 0.0429,
//     amount: 5000 + Math.floor(Math.random() * 1000),
//     minPayment: 200,
//   },
  {
    title: "Credit Card",
    interest: 0.90,
    amount: 1500,
    minPayment: 40,
  },
];
