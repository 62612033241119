import React from "react";
import { hexToRGBA } from "src/utils/utils";

const MonthTopHeader = ({ state, currentDecisionIndex }) => {
  const { happiness, balance, savings } = state;
  const income = state.job.salary;

  const level = state.level;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const calculateDate = (level, currentDecisionIndex) => {
    let month;
    const monthRange = 4; // 4 months in each quarter
    const dayStart = 1 + (currentDecisionIndex - 1) * 3;

    // Determine the quarter based on level
    month =
      months[(level - 1) * monthRange + Math.floor(Math.random() * monthRange)];

    return `${month} ${dayStart + Math.floor(Math.random() * 3)}, 2023`;
  };

  const dynamicDate = calculateDate(level, currentDecisionIndex);

  return (
    <div className="budget-indicators-container">
      <div className="budget-title">{state.currentDate}</div>
      <div className="budget-money-scores">
        <BudgetMoneyIndicator
          balance={balance}
          incomePositive={balance >= 0}
          income={income}
          title="Checkings"
        />
        <BudgetMoneyIndicator
          balance={savings}
          incomePositive={balance >= 0}
          income={5000}
          title="Savings"
        />
        <BudgetHappinessIndicator isHappy={happiness >= 15} width={happiness} />
      </div>
    </div>
  );
};

const BudgetMoneyIndicator = ({
  balance,
  incomePositive = true,
  income,
  title,
}) => {
  const color = incomePositive ? "#00BA88" : "#D94645";

  return (
    <div className="budgetmoneyindicator">
      <div className="budget-money-title">{title}</div>
      <div
        className="budget-money-indicator"
        style={{ backgroundColor: hexToRGBA(color, 0.25) }}
      >
        <div
          className="happiness"
          style={{
            width: `${
              balance >= 0 ? parseInt((balance / income) * 100) : "0"
            }%`,
            backgroundColor: color,
          }}
        />
        <div className="budget-money-type-parent">
          <div className="budget-money-type">$</div>
          <div className="budget-available-money">{balance}</div>
        </div>
      </div>
    </div>
  );
};

const BudgetHappinessIndicator = ({ width, isHappy }) => {
  const color = isHappy ? "#00BA88" : "#D94645";

  return (
    <div className="budgethappinessindicator">
      <div className="budget-happiness-title">Happiness</div>
      <div
        className="budget-money-indicator1"
        style={{ backgroundColor: hexToRGBA(color, 0.25) }}
      >
        <div
          className="happiness"
          style={{
            width: `${parseInt((width / 30) * 100)}%`,
            backgroundColor: hexToRGBA(color, 1),
          }}
        />
        <div className="totalamount1">
          <div className="budget-sad-emoji-icon">{isHappy ? "😀" : "😟"}</div>
          <div className="budget-percent-score">{width}</div>
        </div>
      </div>
    </div>
  );
};

export default MonthTopHeader;
