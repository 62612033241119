// BudgetingGame.js
import React, { useContext, useEffect } from "react";
import useGameLogic from "./gameLogic";
import BudgetingGamePickLifestyle from "./components/LifestyleSelection";
import { BudgetAdjustment } from "./components/BudgetAdjustment";
import BudgetingMonthSimulation from "./components/MonthSimulation";
import { ResultsOverview } from "./components/GameResult";
import { AdminInfo } from "./components/AdminInfo";
import { withFirebase } from "src/components/Firebase";
import { compose } from "recompose";
import { AuthUserContext } from "src/components/Session";

const BudgetingGame = ({ firebase, handleNext }) => {
  const {
    state,
    selectJob,
    selectExpense,
    submitExpenses,
    adjustBudget,
    makeDecision,
    resetGame,
    budgetReady,
    adjustMonthToSaveChoice,
    setCurrentLevel,
  } = useGameLogic();

  const authUser = useContext(AuthUserContext);

  const onRestartGame = () => {
    const levelUp = state.balance >= 0 && state.happiness >= 15;
    if (levelUp) {
      firebase.user(authUser.uid).update({
        snowball: { budgetingLevel: authUser.snowball.budgetingLevel + 1 },
      });
    }
    resetGame();
  };

  useEffect(() => {
    getAndSetCurrentLevel();
  }, [authUser]);

  const getAndSetCurrentLevel = async () => {
    const currentLevel = authUser.snowball?.budgetingLevel;
    if (currentLevel) {
      setCurrentLevel(currentLevel);
    } else {
      firebase.user(authUser.uid).update({ snowball: { budgetingLevel: 1 } });
      setCurrentLevel(1);
    }
  };

  console.log('gamestate', state.gameState)

  return (
    <div style={{ width: '100%' }}>
      {state.gameState === "jobSelection" && (
        <BudgetingGamePickLifestyle
          state={state}
          onSelectJob={selectJob}
          onSelectExpense={selectExpense}
          submitExpenses={submitExpenses}
        />
      )}
      {state.gameState === "budgetAdjustment" && (
        <BudgetAdjustment
          state={state}
          onAdjustBudget={adjustBudget}
          budgetReady={budgetReady}
          adjustMonthToSaveChoice={adjustMonthToSaveChoice}
          onRestartGame={onRestartGame}
        />
      )}
      {state.gameState === "monthSimulation" && (
        <BudgetingMonthSimulation state={state} makeDecision={makeDecision} />
      )}
      {state.gameState === "gameResult" && (
        <ResultsOverview
          state={state}
          onRestartGame={onRestartGame}
          handleNext={handleNext}
        />
      )}
    </div>
  );
};

export default compose(withFirebase)(BudgetingGame);
