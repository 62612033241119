import React from "react";
import "./EndGameModal.css";

const EndGameModal = (props) => {
  if (props.endGame) {
    console.log("endGame condition", props.endGame);
    const netWorth = props.portfolio + props.cash;

    const winLose =
      netWorth < props.winGoal ? (
        <div>
          <br />
          <h3 className="lost">You Lost!</h3>
          <p>
            You were{" "}
            <strong>
              $ {props.winGoal - (props.portfolio + props.cash)} below
            </strong>{" "}
            your set goals.
          </p>
        </div>
      ) : (
        <div>
          <br />
          <h1 className="win-text">You Won!</h1>
          <p>
            You made{" "}
            <strong>{props.portfolio + props.cash - props.winGoal}</strong> more
            than you planned.
          </p>
        </div>
      );
    return (
      <div className="endgame-modal">
        <div className="modal-contents">
          <h1>EndGame!</h1>
          <h3 className="results-compare">
            Portfolio Value:{" "}
            <strong>$ {Math.floor(props.portfolio + props.cash)}</strong>
          </h3>
          <h3 className="results-compare">
            Your Goal: <strong>$ {props.winGoal}</strong>
          </h3>

          {winLose}

          <button
            onClick={() => {
              props._restartGame();
            }}
          >
            Restart
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default EndGameModal;
