import React, {Component} from 'react';
import './ErrorModal.css';

class ErrorModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            messages: {
                correct: "Good Job!",
                insufficientFunds: "Not enough cash! Reduce the amount you're buying or sell assets to get cash.",
                tickerNotFound: "Ticker not valid. Please enter the correct ticker to buy the asset.",
                noTicker: "No ticker found. Please add the TICKER of the asset to the Ticker column, AND and amount to the amount column. (i.e : MSFT 10, then hit TRADE on Microsoft)",
                assetAdded: "Asset will be added shortly!",
                missingInfo: "Be sure to have BOTH a ticker and an amount in your spreadsheet",
                tooExpensive: "You dont have enough cash! Reduce the amount or sell some assets.",
                sameAsset: "You're buying the same thing! Change the amount.",
                updatedAsset: "Asset was updated in price and amount!",
                savingsAdded: "Added cash! Buy stocks by adding the ticker (MSFT, AAPL) to column A, then add the number of stocks you want to column B, and hit TRADE.",
                Win: "Congratulations! You won the game!",
                Selling: "Asset Sold!"
            },
            display: this.props.display || true
        }
    }

    closeModal = () =>{
        this.setState({
            display: false
        })
        this.props.closeModal();
    }  

    render(){
        const {messages, display} = this.state;

        const divStyle = {
            backgroundColor: 'lightgreen',
            zIndex: 1,
            position: 'absolute',
            top: '50vh',
            left: '80px',
            width: '50%',
            color: 'white',
            fontWeight: "bold",
            padding: '20px'
        };

        const buttonStyle = {
            marginTop: '0px',
            float: 'right'
        }
        
        if(display){
            return(
                <div className="error-modal" style={divStyle}>
                    <h3>{messages[this.props.message]}</h3>
                    <button style={buttonStyle} onClick={this.closeModal}>Close</button>
                </div>
            );
        } else{
            
            return null;
        }
        
    }
}

export default ErrorModal;