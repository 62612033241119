import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import renderHTML from "react-render-html";

import "./index.css";

class CarouselContainer extends Component {
  render() {
    const { item } = this.props;
    console.log("Carousel 10 | item", item);

    return (
      <div style={{ backgroundColor: "white" }}>
        <Carousel>
          {item &&
            item.slides &&
            item.slides.map((slide, slideIndex) => {
              return (
                <div key={slideIndex} className="carousel-slide-container">
                  {renderHTML(slide.value)}
                </div>
              );
            })}
        </Carousel>
      </div>
    );
  }
}

export default CarouselContainer;
