import moment from "moment";
import { getToken } from "./token";

export const getCalendarEvents = async (calendarId) => {
  try {
    const sessionToken = await getToken();
    let url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`;
    const request = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    const data = await request.json();
    if (data.error) {
      throw new Error(data.error.message);
    }

    return data;
  } catch (error) {
    return error.message;
  }
};

export const getCalendarEvent = async (calendarId, eventId) => {
  try {
    const sessionToken = await getToken();
    let url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`;
    const request = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    const data = await request.json();
    if (data.error) {
      throw new Error(data.error.message);
    }
    return data;
  } catch (error) {
    return error.message;
  }
};

export const getUserCalendarsList = async (calendarId) => {
  try {
    const token = await getToken();

    const request = await fetch(
      `https://www.googleapis.com/calendar/v3/users/me/calendarList/${calendarId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await request.json();
    return data;
  } catch (error) {
    return error.message;
  }
};

export const createNewCalendar = async (newCalendarName) => {
  const token = await getToken();

  try {
    const request = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          summary: newCalendarName,
          rules: {
            scope: {
              type: "default",
            },
          },
        }),
      }
    );

    const data = await request.json();
    return data;
  } catch (error) {
    return error.message;
  }
};

export const makeCalendarPublic = async (calendarId) => {
  const token = await getToken();

  try {
    const request = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/acl`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          role: "reader",
          scope: {
            type: "default",
          },
        }),
      }
    );

    const data = await request.json();
    if (!request.ok) {
      throw new Error(data.error.message || "Error setting calendar ACL");
    }
    return data;
  } catch (error) {
    return { error: error.message };
  }
};

export const createCalendarEventInvite = async (
  calendarId,
  inviteeEmail,
  mentorEmail,
  date,
  time,
  summary,
  location,
  description
) => {
  const token = await getToken();
  let dateString = new Date(`${date}T${time}-05:00`);
  console.log(
    "googleCalendar.js 139 | date string",
    moment(dateString).format()
  );

  try {
    const request = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          attendees: [
            {
              email: inviteeEmail,
            },
            {
              email: mentorEmail,
            },
          ],
          start: {
            dateTime: moment(dateString).format(),
            timeZone: "America/New_York",
          },
          end: {
            dateTime: moment(dateString).add("1", "hour").format(),
          },
          summary: summary,
          location: location,
          description: description,
          reminders: {
            useDefault: false,
            overrides: [
              // { method: "email", minutes: 60 },
              {
                method: "email",
                days: 1,
                minutes: 0,
              },
            ],
          },
        }),
        // visibility: "private",
        guestsCanSeeOtherGuests: false,
      }
    );

    const data = await request.json();
    return data;
  } catch (error) {
    console.log("googleCalendar.js 151 | error", error);
    return error.message;
  }
};

export const createGeneralCalendarEvent = async (
  calendarId,
  dateString,
  endDateString,
  name,
  location,
  description
) => {
  const token = await getToken();
  console.log(
    "googleCalendar.js 204 | create general calendar event",
    calendarId
  );

  try {
    const request = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          start: {
            dateTime: moment(dateString).format(),
          },
          end: {
            dateTime: moment(endDateString).format(),
          },
          summary: name,
          location: location,
          description: description,
          reminders: {
            useDefault: false,
            overrides: [
              // { method: "email", minutes: 120 },
              {
                method: "email",
                days: 1,
                minutes: 0,
              },
            ],
          },
        }),
      }
    );

    const data = await request.json();
    return data;
  } catch (error) {
    console.log("googleCalendar.js 194 | error", error.message);
    return error.message;
  }
};

export const editGeneralCalendarEvent = async (
  calendarId,
  googleCalendarEventId,
  dateString,
  endDateString,
  summary,
  location,
  description,
  notifyUsers
) => {
  const token = await getToken();

  try {
    const request = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${googleCalendarEventId}?sendUpdates=${notifyUsers ? "all" : "none"
      }`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          summary,
          start: {
            dateTime: dateString,
            timeZone: "America/New_York",
          },
          end: {
            dateTime: endDateString,
            timeZone: "America/New_York",
          },
          description: description,
          location: location,
          reminders: {
            useDefault: false,
            overrides: [
              // { method: "email", minutes: 120 },
              {
                method: "email",
                days: 1,
                minutes: 0,
              },
            ],
          },
        }),
      }
    );

    const data = await request.json();
    return data;
  } catch (error) {
    console.log("googleCalendar.js 194 | error", error.message);
    return error.message;
  }
};

export const insertExistingCalendarIntoUserCalendars = async (calendarId) => {
  try {
    const token = await getToken();
    const request = await fetch(
      `https://www.googleapis.com/calendar/v3/users/me/calendarList`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: calendarId }),
      }
    );
    return request;
  } catch (error) {
    throw new Error(error);
  }
};

export const checkForGoogleDriveAccess = async () => {
  try {
    const token = await getToken();
    const request = await fetch(`https://www.googleapis.com/drive/v3/about`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return request;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteCalendar = async (calendarId) => {
  try {
    const token = await getToken();

    const request = await fetch(
      `https://www.googleapis.com/calendar/v3/users/me/calendarList/${calendarId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await request.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteCalendarEvent = async (calendarId, eventId) => {
  try {
    const token = await getToken();

    const request = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await request.json();
    return data;
  } catch (error) {
    console.error("googleCalendar.js 102 | error", error);
    return error.message;
  }
};

export const createPrivateEvent = async (
  calendarId,
  date,
  time,
  summary,
  location,
  description
) => {
  let dateString = new Date(`${date}T${time}-05:00`);
  try {
    const token = await getToken();

    const request = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          start: {
            dateTime: dateString,
            timeZone: "America/New_York",
          },
          end: {
            dateTime: dateString,
            timeZone: "America/New_York",
          },
          summary: summary,
          location: location,
          description: description,
          reminders: {
            useDefault: false,
            overrides: [
              {
                method: "email",
                days: 1,
                minutes: 0,
              },
            ],
          },
          visibility: "private",
          guestsCanSeeOtherGuests: false,
        }),
      }
    );

    const data = await request.json();
    return data;
  } catch (error) {
    console.log("googleCalendar.js 194 | error", error);
    return error.message;
  }
};

export const addUserToCalendar = async (
  calendarId,
  userEmail,
  role = "reader"
) => {
  try {
    const token = await getToken();
    const data = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/acl`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          role: role,
          scope: {
            type: "user",
            value: userEmail,
          },
        }),
      }
    );

    const response = await data.json();
    console.log("googleCalendar.js 320 | response", response);
    console.log("googleCalendar.js 343 | token", token);
    return response;
  } catch (error) {
    console.log("googleCalendar.js 194 | error", error);
    throw new Error(error.message);
    // return error.message;
  }
};

export const addUserToCalendarAsAdmin = async (
  calendarId,
  userEmail,
  role = "writer"
) => {
  console.log("googleCalendar.js 359 | adding user to calendar as admin");
  try {
    const token = await getToken();
    const data = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/acl`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          role: role,
          scope: {
            type: "user",
            value: userEmail,
          },
        }),
      }
    );

    const result = await data.json();

    // const response = await data.json();
    return result;
  } catch (error) {
    console.log("googleCalendar.js 194 | error", error);
    return error.message;
  }
};

// Classrooms calendar manager

// If I'm a mentor and have a classroom
// Create event for users in that classroom

export const getCalendarUsers = async (calendarId) => {
  try {
    const token = await getToken();
    const data = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/acl`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log("googleCalendar.js 194 | error", error);
    return error.message;
  }
};
