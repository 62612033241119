import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentChatId } from "src/store/chats/chatActions";

export const UserChatCard = ({ student, chatId, isSelected, rooms }) => {
  const dispatch = useDispatch();

  const { currentClassRooms } = useSelector((state) => state.chats);

  const messageData = (currentClassRooms && currentClassRooms[chatId]) || {};
  const messagePreview = messageData.message
    ? `${
        messageData.message.length > 65
          ? `${messageData.message.slice(0, 65)}...`
          : messageData.message
      }`
    : "Click to send message";

  return (
    <div
      onClick={() => {
        dispatch(setCurrentChatId(chatId));
      }}
      className={
        isSelected
          ? "studentname__container__selected"
          : "studentname__container"
      }
    >
      <div className="messagepreview__content">
        <div className="studentname__title">{student[1]}</div>
        <div className="message__preview">
          {messagePreview ? messagePreview : "Click to send message"}
        </div>
      </div>
      <div className="messagepreview__datesent">
        {messageData ? moment(messageData.date).format("MM/DD") : ""}
      </div>
    </div>
  );
};
